import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { interviewRoundTypes } from 'src/app/app.constants';
import { FormMsService } from 'src/app/services/form-ms.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-create-template-dialog',
  templateUrl: './create-template-dialog.component.html',
  styleUrls: ['./create-template-dialog.component.scss'],
})
export class CreateTemplateDialogComponent implements OnInit {
  createTemplateForm: FormGroup;
  formTypes = interviewRoundTypes;

  isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private formMsService: FormMsService,
    private router: Router,
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<CreateTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.createTemplateForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
      formType: ['', [Validators.required]],
      formData: [''],
    });
  }

  saveTemplate = () => {
    this.isLoading = true;
    this.formMsService
      .createEvaluationTemplate(this.createTemplateForm.value)
      .subscribe({
        next: (res) => {
          this.sharedService.openSnackBar(
            'Template Created Successfully!',
            '',
            3000
          );
          this.isLoading = false;
          this.dialogRef.close();
          this.router.navigate(['evaluation-template', res.id]);
        },
        error: (err) => {
          this.isLoading = false;
          this.sharedService.openSnackBar(
            'Some error occurred. Please try again.',
            '',
            3000
          );
        },
      });
  };
}
