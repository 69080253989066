import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MailStatusActionRendererComponent } from 'src/app/modules/iwp-event-notifications/event-notifications-renderer/mail-status-renderer';
import { EventNotificationsService } from 'src/app/services/event-notifications.service';

@Component({
  selector: 'app-infy-event-notifications-mails-grid',
  templateUrl: './infy-event-notifications-mails-grid.component.html',
  styleUrls: ['./infy-event-notifications-mails-grid.component.scss'],
})
export class InfyEventNotificationsMailsGridComponent
  implements OnInit, OnChanges {
  @Input() selectedFile;
  @Input() statusSelected;

  constructor(private eventNotificationsService: EventNotificationsService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.applyFiltersToTable();
  }

  refreshAgGrid: Subject<void> = new Subject<void>();

  tableName: string = 'Mails Status';
  buttonText: string = '';
  buttonCondition: boolean = false;
  levels: number = 1;
  searchCondition: boolean = true;
  customRowHeight = 130;

  page: number = 0;
  size: number = 10;

  columnDefs: any = [
    // {
    //   headerName: 'Category',
    //   field: 'subCategory',
    //   hide: false,
    //   maxWidth: 150,
    // },
    // {
    //   headerName: 'Interview Id',
    //   field: 'id',
    //   hide: false,
    //   maxWidth: 150,
    // },
    {
      headerName: 'Candidate Email',
      field: 'userEmail',
      hide: false,
      minWidth: 200,
    },
    {
      headerName: 'Mail Category',
      hide: false,
      field: 'specType',
      cellRendererFramework: MailStatusActionRendererComponent,
      cellRendererParams: { type: 'category' },
      maxWidth: 130,
    },
    {
      headerName: 'Recipients Email',
      hide: false,
      field: 'recepientMail',
      cellRendererFramework: MailStatusActionRendererComponent,
      cellRendererParams: { type: 'email' },
      minWidth: 200,
    },

    {
      headerName: 'Mail status',
      field: 'status',
      hide: false,
      cellRendererFramework: MailStatusActionRendererComponent,
      cellRendererParams: { type: 'status' },
      // maxWidth: 150,
    },
    {
      headerName: 'Message',
      field: 'message',
      cellRendererFramework: MailStatusActionRendererComponent,
      cellRendererParams: { type: 'message' },
      hide: false,
      minWidth: 400,
    },
    {
      headerName: 'Action',
      field: 'actions',
      hide: false,
      cellRendererFramework: MailStatusActionRendererComponent,
      cellClass: 'cell-wrap-text',
      cellRendererParams: { type: 'resend' },
      autoHeight: true,
      minWidth: 200,
    },
  ];

  interface = (
    size: number,
    page: number,
    query?: string
  ): Observable<any[]> => {
    return this.eventNotificationsService.getBulkUploadMailDetails(
      this.selectedFile.id,
      page,
      size,
      this.statusSelected,
      query
    );
  };

  applyFiltersToTable = () => {
    this.refreshAgGrid.next();
  };
}
