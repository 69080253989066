<h1 mat-dialog-title>Add Candidate1</h1>
<div mat-dialog-content>
  <form class="container-fluid" [formGroup]="addCandidateForm">
    <div class="row">
      <div class="col-12">
        <mat-form-field  class="marT16 DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixIcon" floatLabel="never">
          <!-- <mat-label>Email</mat-label> -->
          <input matInput placeholder="Email" formControlName="email" />
          <!-- <span matSuffix class="pointer" (click)="searchCandidate()"
            ><i class="icon x-24 search-icon"> </i
          ></span> -->
          <div class="DSA_wb_mat-suffix pointer" (click)="searchCandidate()"><i aria-hidden="true" class="icon x-24 search-icon"> </i></div>
          <mat-error
            *ngIf="
              addCandidateForm.controls.email.dirty &&
              addCandidateForm.controls.email.hasError('pattern')
            "
            >Invalid Email Format</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mat-body text-center">
        {{ message }}
      </div>
    </div>
    <div class="row" *ngIf="showCandidateForm">
      <div class="col-lg-4 col-md-4">
        <mat-form-field appearance="fill" class="DSA_Wb_custom-form-field">
          <mat-label>Candidate Id</mat-label>
          <input matInput formControlName="candidateId" />
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-4">
        <mat-form-field appearance="fill" class="DSA_Wb_custom-form-field">
          <mat-label>Candidate Name</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
      </div>

      <div class="col-lg-4 col-md-4">
        <mat-form-field appearance="fill" class="DSA_Wb_custom-form-field">
          <mat-label>Candidate Source</mat-label>
          <mat-select formControlName="candidateSource">
            <mat-option
              *ngFor="let source of candidateSources"
              [value]="source.value"
              >{{ source.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-lg-4 col-md-4">
        <mat-form-field appearance="fill" class="DSA_Wb_custom-form-field">
          <mat-label>Country Code</mat-label>
          <input matInput formControlName="countryCode" />
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-4">
        <mat-form-field appearance="fill" class="DSA_Wb_custom-form-field">
          <mat-label>Phone</mat-label>
          <input matInput formControlName="phone" />
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-4">
        <mat-form-field appearance="fill" class="DSA_Wb_custom-form-field">
          <mat-label>Application Id</mat-label>
          <input matInput formControlName="applicationID" />
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-4">
        <mat-form-field appearance="fill" class="DSA_Wb_custom-form-field">
          <mat-label>Unit</mat-label>
          <input matInput formControlName="unit" />
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-4">
        <mat-form-field appearance="fill" class="DSA_Wb_custom-form-field">
          <mat-label>Sub Unit</mat-label>
          <input matInput formControlName="subUnit" />
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-4">
        <mat-form-field appearance="fill" class="DSA_Wb_custom-form-field">
          <mat-label>Technology</mat-label>
          <input matInput formControlName="technology" />
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <button mat-button (click)="onNoClick()" class="DSA_wb_miniSecondary-btn">
    Close
  </button>
  <button
    mat-button
    [disabled]="addCandidateForm.invalid"
    [mat-dialog-close]="addCandidateForm.value"
    cdkFocusInitial
    class="DSA_wb_miniPrimary-btn"
  >
    Add
  </button>
</div>
