import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddCandidateComponent } from './add-candidate/add-candidate.component';
import { AddJobsComponent } from './add-jobs/add-jobs.component';
import { AddPanelistsComponent } from './add-panelists/add-panelists.component';
import { AddRecruitersComponent } from './add-recruiters/add-recruiters.component';
import { GenerateReportComponent } from './generate-report/generate-report.component';
import { InfyCandidateInterviewRoundsComponent } from './infy-candidate-interview-rounds/infy-candidate-interview-rounds.component';
import { InfyCopyEventComponent } from './infy-copy-event/infy-copy-event.component';
import { InfyCreateEventComponent } from './infy-create-event/infy-create-event.component';
import { InfyDynamicDashboardComponent } from './infy-dynamic-dashboard/infy-dynamic-dashboard.component';
import { InfyEventRecruitersComponent } from './infy-event-recruiters/infy-event-recruiters.component';
import { InfyGlobalSearchComponent } from './infy-global-search/infy-global-search.component';
import { InfyInterviewListComponent } from './infy-interview-list/infy-interview-list.component';
import { InfyJobsComponent } from './infy-jobs/infy-jobs.component';
import { InfyMsauthComponent } from './infy-msauth/infy-msauth.component';
import { InfyPanelistsComponent } from './infy-panelists/infy-panelists.component';
import { InfyRecruiterDashboardComponent } from './infy-recruiter-dashboard/infy-recruiter-dashboard.component';
import { InfyScheduleListComponent } from './infy-schedule-list/infy-schedule-list.component';
import { InfySchedulePageComponent } from './infy-schedule-page/infy-schedule-page.component';
import { MailTemplateSelectionComponent } from './mail-template-selection/mail-template-selection.component';
import { PanelistUnavailabilityDialogComponent } from './panelist-unavailability-dialog/panelist-unavailability-dialog.component';
import { SettingsComponent } from './settings/settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GatewaySharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/angular-material.module';
import { IwpEventRoutingModule } from './iwp-event-routing.module';
import { DynamicFormFieldComponent } from './settings/dynamic-form-field/dynamic-form-field.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { RecruiterDashboardStateStorageService } from './infy-recruiter-dashboard/recruiter-dashboard-state-storage.service';
import { InterviewListActionRendererComponent } from './infy-interview-list/interview-list-renderer/interview-list-action-renderer.component';
import { RescheduleLinkRendererComponent } from './infy-candidate-interview-rounds/candidate-interview-rounds-renderer/reschedule-link-renderer.component';
import { InterviewRoundsActionRendererComponent } from './infy-candidate-interview-rounds/candidate-interview-rounds-renderer/candidate-interview-round-action-renderer/interview-rounds-action-renderer.component';
import { panelistActionRendererComponent } from './add-panelists/panelists-renderer/panelist-action-renderer';
import { JobActionRendererComponent } from './infy-jobs/jobs-action-renderer/job-action-renderer';
import { eventRecruiterActionRendererComponent } from './infy-event-recruiters/event-recruiter-action-renderer';
import { RecruiterDashboardRendererComponent } from './infy-recruiter-dashboard/recruiter-dashboard/recruiter-dashboard-action-renderer.component';
import { MeetingLinkActionRendererComponent } from './infy-candidate-interview-rounds/candidate-interview-rounds-renderer/meeting-link-action-renderer/meeting-link-action-renderer.component';
import { DisplayMeetingLinkComponent } from './infy-candidate-interview-rounds/candidate-interview-rounds-renderer/meeting-link-action-renderer/display-meeting-link.component';
import {
  BulkUploadDialogComponent,
  TemplatesWarningComponent,
} from './infy-jobs/bulk-upload-dialog/bulk-upload-dialog.component';
import { DynamicDashboardRendererComponent } from './infy-dynamic-dashboard/dynamic-dashboard-renderer/dynamic-dashboard-renderer.component';
import { CandidateIdDialogBoxComponent } from './infy-schedule-list/candidate-id-action-renderer/candidate-id-dialog-box/candidate-id-dialog-box.component';
import { CandidateIdActionRendererComponent } from './infy-schedule-list/candidate-id-action-renderer/candidate-id-action-renderer.component';

@NgModule({
  declarations: [
    AddCandidateComponent,
    AddJobsComponent,
    AddPanelistsComponent,
    AddRecruitersComponent,
    GenerateReportComponent,
    InfyCandidateInterviewRoundsComponent,
    InfyCopyEventComponent,
    InfyCreateEventComponent,
    InfyDynamicDashboardComponent,
    DynamicFormFieldComponent,
    InfyEventRecruitersComponent,
    InfyGlobalSearchComponent,
    InfyInterviewListComponent,
    InfyJobsComponent,
    InfyMsauthComponent,
    InfyPanelistsComponent,
    InfyRecruiterDashboardComponent,
    InfyScheduleListComponent,
    InfySchedulePageComponent,
    MailTemplateSelectionComponent,
    PanelistUnavailabilityDialogComponent,
    SettingsComponent,
    InterviewListActionRendererComponent,
    RescheduleLinkRendererComponent,
    InterviewRoundsActionRendererComponent,
    panelistActionRendererComponent,
    JobActionRendererComponent,
    eventRecruiterActionRendererComponent,
    RecruiterDashboardRendererComponent,
    MeetingLinkActionRendererComponent,
    DisplayMeetingLinkComponent,
    BulkUploadDialogComponent,
    TemplatesWarningComponent,
    DynamicDashboardRendererComponent,
    CandidateIdDialogBoxComponent,
    CandidateIdActionRendererComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GatewaySharedModule,
    MaterialModule,
    IwpEventRoutingModule,
    NgxDaterangepickerMd.forRoot(),
  ],
  providers: [RecruiterDashboardStateStorageService],
})
export class IwpEventModule {}
