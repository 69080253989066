<!-- <p>career-details works!</p> -->

<div *ngIf="spinner">
  <br />
  <img
    src="../../../assets/loader.gif"
    style="margin-left: 30%; width: 200px"
    alt=""
  />
</div>
<!-- *ngIf="candidateInfo" -->

 <div class="m-3 mr-5 mt-3"> 
  <div class="container-fluid ml-3 mr-3"
  >
  <form
  [formGroup]="careerInfoForm"
  style="font-size: medium"
  
>
    <mat-carousel
      color="accent"
      maxWidth="auto"
      maintainAspectRatio="true"
      slideHeight="100%"
      proportion="120"
      touchDrag="false"
      mouseDrag="false"
      [loop]="true"
      [hideArrows]="false"
      [hideIndicators]="false"
      [useKeyboard]="true"
      [useMouseWheel]="false"
      [autoplay]="false"
      orientation="ltr"
    >
    
    
      <mat-carousel-slide
        #matCarouselSlide
        class="container-fluid" overlayColor="#00000040" [hideOverlay]="true"
        formArrayName="employmentHistoryDTOs"
          *ngFor="let d of getDTOFormControls(); let i = index"
        >

        <div class="mt-2 mb-5">
          <!-- <div style="margin: 10px 0px 0px 0px" class="text-center">
            <h3>Career History</h3>
          </div> -->

          <ng-container
          
        >
        <!-- <h4 class="marTB16">Employment History</h4> -->
          <ng-container [formGroupName]="i">
            <mat-form-field
              class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
              floatLabel="never" style="width: 38vw;"
              
            >
              <input
                matInput
                type="number"
                min="0"
                placeholder="Total experience in years"
                formControlName="totalExperience"
              />
            </mat-form-field>

            <mat-form-field
              class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
              floatLabel="never"
              
            >
              <input
                matInput
                placeholder="Employer Name"
                formControlName="employerName"
              />
            </mat-form-field>

            <mat-form-field
              class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
              floatLabel="never"
              
            >
              <input
                matInput
                placeholder="Job Title"
                formControlName="jobTitle"
              />
            </mat-form-field>

            <!-- <mat-form-field
              class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
              floatLabel="never"
              
            >
              <input
                matInput
                placeholder="employmentType"
                formControlName="employmentType"
              />
            </mat-form-field> -->

            <mat-form-field
              class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
              floatLabel="never"
              
            >
            <mat-label>Employment Type</mat-label>
            <!-- <input
              matInput
              placeholder="Marital status"
              formControlName="maritalStatus"
            /> -->
            <mat-select formControlName="employmentType">
              <mat-option value="PERMANENT">PERMANENT</mat-option>
              <mat-option value="CONTRACT">CONTRACT</mat-option>
              <mat-option value="INTERNSHIP">INTERNSHIP</mat-option>
              <mat-option value="FREELANCE">FREELANCE</mat-option>
              <mat-option value="PROBATION">PROBATION</mat-option>
              <mat-option value="PART_TIME">PART TIME</mat-option>
            </mat-select>
          </mat-form-field>

            <!-- <mat-form-field
              class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
              floatLabel="never"
              
            >
              <input
                matInput
                placeholder="isCurrent"
                formControlName="isCurrent"
              />
              <mat-select [(ngModel)]="isCurrent">
                <mat-option value="YES">Yes</mat-option>
                <mat-option value="NO">No</mat-option>
                
              </mat-select>
            </mat-form-field> -->

            <mat-form-field
              class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
              floatLabel="never"
              
            >
              <input
                matInput
                placeholder="Category"
                formControlName="category"
              />
            </mat-form-field>

            <mat-form-field
              class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
              
            >
              <!-- <mat-label>End Date</mat-label> -->
              <input
                matInput
                [matDatepicker]="endDate"
                placeholder="FromDate"
                formControlName="fromDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="endDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>

            <mat-form-field
              class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
              
            >
              <!-- <mat-label>End Date</mat-label> -->
              <input
                matInput
                [matDatepicker]="picker"
                placeholder="ToDate"
                formControlName="toDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field
              class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
              floatLabel="never"
              
            >
              <input
                matInput
                placeholder="Job Description"
                formControlName="jobDescription"
              />
            </mat-form-field>

            <!-- <button
              class="DSA_wb_miniSecondary-btn marTB16"
              (click)="submitDetails()"
            >
              save
            </button> -->
          </ng-container>
        </ng-container>
      </div>
      </mat-carousel-slide>
    </mat-carousel>
  </form>
  <button
      class="DSA_wb_miniSecondary-btn marTB16"
      (click)="updateExtractedResume()"
      style="float: right;"
    >
      Update
    </button>
  </div>
</div>
