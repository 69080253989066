<!-- <header>
  <nav class="navbar" role="navigation">
    <div class="navbar-header page-scroll w-100">
      <a class="navbar-brand" href="https://www.infosys.org/" title="Go to Infosys Home" aria-label="Go to Infosys Home">
        <img src="/assets/images/landing-page/logo.svg" alt="Infosys" class="logo">
        <img src="/assets/images/landing-page/logo-mobile.svg" alt="Infosys" class="mobile-logo d-block d-md-none">
        <span>STEM Scholarship</span>
      </a>
      <div class="search-sec">
        <div class="search-bar">
          <input type="text" placeholder="Search" class="search-box">
        </div>
        <div class="icons">
          <a href="#" class="search-icon"> <img src="/assets/images/landing-page/search.svg" alt=""></a>
          <a href="#" class="close-icon"> <img src="/assets/images/landing-page/close.svg" alt=""></a>
        </div>
      </div>
      <div class="login-sec">
        <a
          href="javascript:void(0)"
          (click)="login()"
        >
          Login/Register
        </a>
      </div>
    </div>
  </nav>
</header> -->

<div class="poppins-font-family">
  <div class="top-header">
    <div class="container">
     <div class="row justify-content-between align-items-center">
      <div class="col-3"><div class="top-logo"><a href="https://wa.foundation/"><img src="assets/images/logo-wa-2.png"></a></div></div>
      <div class="col-9">
      <div class="right-box">
       <div class="top-logos"><p>SUPPORTED BY</p><img src="assets/images/knowledge-partner-logo.png"></div>
       <div class="top-menu" (click)="sidenav()">MENU <i class="fa-solid fa-bars fa-fw"></i></div></div>  
      </div>
     </div>
    </div>
   </div>
   
   <div class="header" id="sideNav">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="d-flex col-md-5 align-items-center justify-content-center pos-rel">
          <div class="mar16 mobile-hide"><div class="top-logo"><a href="https://wa.foundation/"><img src="assets/images/logo-wa-2.png"></a></div></div>
          <div class="mar16 mobile-hide" ><div class="header-logo"><a href="https://wa.foundation/tpic/index.php"><img src="assets/images/heder-logo.png" style="height:50px;"></a></div></div>
          <div class="mar16 mobile-hide">  
            <div class="top-logos"><p class="f-style">SUPPORTED BY</p><img src="assets/images/knowledge-partner-logo.png" style="height:50px;"></div>
          </div> 
        </div>
        <div class="col-md-7 marT8 pos-rel">
          <div class="menu">
            <ul class="marT8 marB8">
              <li><a href="https://wa.foundation/tpic/index.php">Home</a></li>
              <li (mouseover)="show()" (mouseleave)="onMouseLeave()"><a href="https://wa.foundation/tpic/about_us.php">
                <span class="d-flex">
                  <span>About</span>
                  <i class="icon x-24 down-arw-icon pos-down" style="color:black;"> </i>
                </span>
              </a>
              <ul id="show" class="responsive-show">
                <li><a href="https://wa.foundation/tpic/organising_commitee.php"><i><img src="assets/images/dropmenu-icon1.png" ></i>Organising Committee</a></li>
                <li><a href="https://wa.foundation/tpic/subject_matter_experts.php"><i><img src="assets/images/dropmenu-icon2.png" ></i>Subject Matter Experts</a></li>
                <!--<li><a href="jury_members.php"><i><img src="assets/images/dropmenu-icon3.png"></i>Jury Members</a></li>-->
              </ul>
            </li>
            <li><a href="https://wa.foundation/tpic/main_objectives.php">Main Objectives</a></li>
            <li><a href="https://wa.foundation/tpic/partners.php">Partners</a></li>
            <li class="last">
              <a
              href="javascript:void(0)"
              (click)="login()"
              >
              Login/Register
            </a>
          </li>
        </ul>
      </div>
    </div> 
  </div>
    </div>
  </div>
   
   <!--header-->
   <!--banner-->
   <div class="home-banner"><img src="assets/images/home-banner.png"></div>
   <div class="home-banner-title">Nationwide Hackathon On Parking</div>
   <!--banner-->
   
   <!--main-objective-->
   <div class="section grayback">
    <div class="container">
     <div class="globaltext">
      <div class="title1">Main Objectives</div>
     </div>
     
     <div class="section1 top">
      <div class="row">
      
       <div class="col-md-4">
        <div class="globaltext">
         <div class="icon"><img src="assets/images/parking-technology-icon.png"></div>
         <div class="pera">
          <h3>Parking Technology</h3>
         </div>
        </div>     
       </div>
   
       <div class="col-md-4">
        <div class="globaltext">
         <div class="icon"><img src="assets/images/innovation-icon.png"></div>
         <div class="pera">
          <h3>Parking Innovation</h3>
         </div>
        </div>     
       </div>
       
       <div class="col-md-4">
        <div class="globaltext">
         <div class="icon"><img src="assets/images/adoptin-icon.png"></div>
         <div class="pera">
          <h3>Parking Adoptin</h3>
         </div>
        </div>     
       </div>
   
   
      </div>
     </div>
     
     <div class="section1 top">
      <div class="globaltext">
       <div class="learnmore"><a href="https://wa.foundation/tpic/main_objectives.php">LEARN MORE</a></div>
      </div>
     </div>
     
    </div>
   </div>
   <!--main-objective-->
   
   <!--about start-->
   <div class="section">
    <div class="container">
     
     <div class="globaltext">
     <div class="title">About the Hackathon</div>
     </div>
   
     <div class="section1">
      <div class="globaltext large">
       <div class="pera">
        <p>Are you passionate about solving urban challenges and creating smarter cities? The Parking Innovation Challenge is a collaborative effort by WAFOUNDATION and partnered with TZBZ, Spain and MTA, Spain to address the pressing issue of parking in our cities. This hackathon provides a platform for bright minds, developers, and enthusiasts to come together and devise innovative solutions for a more efficient and sustainable parking future.</p>
       </div>
      </div>
     </div> 
      <div class="globaltext">
       <div class="learnmore"><a href="https://wa.foundation/tpic/about_us.php">LEARN MORE</a></div>
      </div>
     </div>
     
    </div>
   <!--about close-->
   
   <!--participate start-->
   <div class="section participate">
    <div class="container">
     <div class="row align-items-center">
     
      <div class="col-md-7">
       <div class="globaltext left large">
        <div class="title margin">Who Can Participate ?</div>
        <div class="pera"><p>Whether you're a seasoned coder, an urban planning enthusiast, a tech-savvy student, or a creative designer, this hackathon welcomes participants from all backgrounds. Your diverse skills and fresh perspectives are key to reshaping the future of parking.
   </p></div>  
       </div>
      </div>
      
      <div class="col-md-5">
       <div class="globaltext right">
        <div class="readmore"><a href="https://wa.foundation/tpic/brochure2024.pdf" target="_blank">Download Brochure<i class="fa-solid fa-arrow-right-long fa-fw"></i></a></div>
       </div>
      </div>
   
      
     </div>
    </div>
   </div>
   <!--participate close-->
   
   <!--partners start-->
   <div class="section">
    <div class="container">
     
     <div class="globaltext">
     <div class="title1">Partners</div>
     </div>
   
     <div class="section1 top">
      <div class="partners">
       <ul>
        <li><a href="https://www.nozyparker.com/" target="_blanck"><img src="assets/images/partner-icon1.png"></a></li>
        <li><a href="https://vinzglobal.com/" target="_blanck"><img src="assets/images/partner-icon2.png"></a></li>
        <li><a href="https://www.phillipcapital.in/" target="_blanck"><img src="assets/images/partner-icon3.png"></a></li>
        <li><a href="https://www.bvgindia.com/" target="_blanck"><img src="assets/images/partner-icon4.png"></a></li>
        <li><a href="https://www.skidata.com/home" target="_blanck"><img src="assets/images/partner-icon5.png"></a></li>
        <li><a href="https://www.youtube.com/@BuildIndiawithVaibhavDange" target="_blanck"><img src="assets/images/partner-icon6.png"></a></li>
       </ul>
      </div>
     </div>
   
     <div class="section1 top">
      <div class="globaltext">
       <div class="learnmore"><a href="https://wa.foundation/tpic/partners.php">LEARN MORE</a></div>
      </div>
     </div>
     
    </div>
   </div>

   <!-- sightconnect -->

   <div class="section participate">
    <div class="container">
     <div class="row align-items-center">
      <div class="col-md-3">
        <div class="globaltext left">
         <div class="readmore">
           <img src="assets/images/sightconnect.png" class="h-w-img">
         </div>
        </div>
       </div>
      <div class="col-md-6">
       <div class="globaltext left large">
        <div class="title margin">See Clearly, Live Brighter with SightConnect</div>
        <div class="pera"><p>
          Your Preventive Eye Care Companion
          SightConnect is your all-in-one eye care app, empowering you to take proactive steps toward maintaining optimal vision health. We believe in the importance of preventive eye care, and our app provides you with the tools and resources to stay on top of your eye health journey. For more details download the app by scanning the QR code
   </p></div>  
       </div>
      </div>
      
      <div class="col-md-3">
       <div class="globaltext right">
        <div class="readmore">
          <img src="assets/images/sc-wafoundation.png" class="h-w-img">
        </div>
       </div>
      </div>
   
      
     </div>
    </div>
   </div>
   
   <!--partners close-->
   
   <!--footer start-->
   <div class="section footer">
    <div class="container">
     <div class="row">
     
      <div class="col-md-5">
       <div class="footer-text">
        <h3><img src="assets/images/footer-logo.png"></h3>
        <p>We have a team of seasoned urban planners, environmentalists, engineers, philanthropists and changemakers who are working to achieve practical sustainably in cities blended with Circular Commerce, Smarter Spaces, and Happier Humans.
   </p>
        <p><a href="https://wa.foundation/about/" class="readmore white">
           <span class="d-flex">
              <span>Read More</span>
              <i class="icon x-24 next-icon pos-next" style="color:white;"> </i>
           </span>
        </a></p>
        <ul>
         <li><a href="https://www.facebook.com/people/WAFOUNDATION/100088199343369/" target="_blank" class=""><i class="icon x-24 icon_facebook" style="color:white;"></i></a></li>
         <li><a href="https://www.linkedin.com/company/wafoundation/?viewAsMember=true" target="_blank" class="">
          <i class="icon x-24 icon_linkedin"  style="color:white;"> </i>
         </a></li>
         <li><a href="https://www.instagram.com/" target="_blank" class="">
          <i class="icon x-24 icon_instagram"  style="color:white;"> </i>
         </a></li>
         <li><a href="https://twitter.com/foundation_wa" target="_blank" class="">
          <i class="icon x-24 icon_twitter"  style="color:white;"> </i>
         </a></li>
        </ul>
        </div>
      </div>
   
      <div class="col-md-3">
       <div class="footer-text">
        <h3>Contact Info</h3>
        <p>C - 701, Galore Park,<br />
        Bavdhan,<br />
   Near LMD Square,<br />
   Pune - 411 021 (MH)
        </p>
       </div>
      </div>
   
      <div class="col-md-4">
       <div class="footer-text">
        <h3>Update</h3>
        <div class="update">
         <div class="img"><img src="assets/images/update-image1.jpg"></div>
         <div class="text">
          <p>Summary of Sustainable Development Goals</p>
          <p><a href="https://wa.foundation/media-post/news/27" target="_blank" class="readmore white">Read more</a></p>
         </div>
        </div>
        <div class="update">
         <div class="img"><img src="assets/images/update-image2.jpg"></div>
         <div class="text">
          <p>Motor Vehicle Pollution in India</p>
          <p><a href="https://wa.foundation/media-post/news/26" target="_blank" class="readmore white">Read more</a></p>
         </div>
        </div>
        <div class="update">
         <div class="img"><img src="assets/images/update-image3.jpg"></div>
         <div class="text">
          <p>What is Urban Sustainability?</p>
          <p><a href="https://wa.foundation/media-post/news/25" target="_blank" class="readmore white">Read more</a></p>
         </div>
        </div>
       </div>
      </div>
      
     </div>
    </div>
   </div>
   <div class="copy">
    <div class="container">
     <p><a href="https://wa.foundation/policies/terms-of-service" target="_blank">Term Of Service</a><a href="https://wa.foundation/policies/privacy-policy" target="_blank">Privacy Policy</a></p>
     <p>Copyright 2023 WAFOUNDATION. All Right Reserved.</p>
   </div>
</div>
