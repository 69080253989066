<div
  class="
    w-100
    d-flex
    justify-content-sm-between justify-content-end
    align-items-center
    paginator-wrap
    flex-wrap
  "
>
  <ngb-pagination
    [collectionSize]="totalElements"
    [(page)]="ngbPaginationPage"
    [pageSize]="pageSize"
    [maxSize]="maxPageNoSize"
    [rotate]="true"
    [directionLinks]="false"
    (pageChange)="ngbPaginationChange($event)"
    class="DSA_wb_custom-pagination d-flex align-items-center ml-2 p-2"
  >
  </ngb-pagination>
  <mat-paginator
    [pageIndex]="page"
    [length]="totalElements"
    [pageSize]="pageSize"
    [pageSizeOptions]="[10, 25, 50, 100]"
    [showFirstLastButtons]="true"
    (page)="onPageChange($event)"
  >
  </mat-paginator>
</div>
