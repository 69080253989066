export enum AuthorityNameUI {
  ROLE_INTERVIEWER = 'Panellist',
  ROLE_SPOC = 'UnitSPOC',
  ROLE_SCHEDULER = 'Scheduler',
  ROLE_RECRUITER = 'Recruiter',
  ROLE_SUPPORT_EXECUTIVE = 'Support Executive',
  ROLE_PLATFORM_EXECUTIVE = 'Platform Executive',
  ROLE_EVENT_ADMIN = 'Event Admin',
  ROLE_SUPER_ADMIN = 'Platform Admin',
  ROLE_CANDIDATE = 'Candidate',
  ROLE_HIRING_MANAGER = 'Admin',
  ROLE_COLLEGE_ADMIN = 'College Admin',
  ROLE_VERIFIER = 'Panelist',
  ROLE_VERIFIER_ADMIN = 'Admin Verifier',
  ROLE_COORDINATOR = 'Coordinator',
  ROLE_INTERN = 'Intern',
  ROLE_MENTOR = 'Mentor',
  ROLE_RELATIONSHIP_MANAGER = 'Relationship Manager',
}
