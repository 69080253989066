import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InfyJobsStateStorageService {
  page = 0;
  size = 25;
  checkFlag: boolean[] = [];
  constructor() {}

  init = () => {
    this.page = 0;
    this.size = 25;
    this.checkFlag = [];
  };
}
