import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';

// material
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';

// util
import { MailTemplateRoute } from './mail-template.route';

// children
import { GrapesjsWrapperComponent } from './grapesjs-wrapper/grapesjs-wrapper.component';
import { MailTemplateComponent } from './mail-template.component';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MailTemplatePagination } from './mail-template-pagination/mail-template-pagination.component';

import { MatChipsModule } from '@angular/material/chips';
import { GatewaySharedModule } from '../shared/shared.module';
import { AddTemplateParamsComponent } from './add-template-params/add-template-params.component';
import { InfyManageMailTemplatesComponent } from './infy-manage-mail-templates/infy-manage-mail-templates.component';

const ENTITY_STATES = [...MailTemplateRoute];

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatCardModule,
    MatInputModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    TextFieldModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatIconModule,
    MatChipsModule,
    MatInputModule,
    MatPaginatorModule,

    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    HttpClientModule,
    RouterModule.forChild(ENTITY_STATES),
    MatSnackBarModule,
    HttpClientModule,
    GatewaySharedModule,
  ],

  declarations: [
    MailTemplateComponent,
    GrapesjsWrapperComponent,
    MailTemplatePagination,
    AddTemplateParamsComponent,
    InfyManageMailTemplatesComponent
  ],

  // entryComponents: [GrapesjsWrapperComponent, DialogBoxComponent, ConfirmDialogComponent],
  entryComponents: [MailTemplateComponent, GrapesjsWrapperComponent],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  //  exports: [DialogBoxComponent]
  exports: [MailTemplateComponent, GrapesjsWrapperComponent],
})
export class MailTemplateModule {}
