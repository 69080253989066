import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../services/account.service';
import { AuthorityName } from '../shared/model/enumerations/authority-name.model';
import { environment } from 'src/environments/environment';
import { KeycloakService } from 'keycloak-angular';
import * as jQuery  from 'jquery';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  sideShow:boolean=false;
  constructor(
    private router: Router,
    private accountService: AccountService,
    private keycloakService: KeycloakService
  ) {
    this.loadScript();
  }

  ngOnInit(): void {
    // navigate user only if logged in
    if (this.accountService.isAuthenticated()) {
      this.navigateUser();
    }
  }

  navigateUser() {
    if (localStorage.getItem('tenantId')) {
      this.navigateToUserHomePage();
    }
  }

  navigateToUserHomePage = () => {
    if (
      this.accountService.getCurrentRole() === AuthorityName.ROLE_HIRING_MANAGER
    ) {
      this.router.navigate(['scp/problem-statements']);
    } else if (
      this.accountService.getCurrentRole() === AuthorityName.ROLE_VERIFIER
    ) {
      const data = this.accountService.getAccountLocal();
      if (!data?.declarationFormAccepted) window.location.reload();
      this.router.navigate(['scp/candidates']);
    } else if (
      this.accountService.getCurrentRole() === AuthorityName.ROLE_CANDIDATE
    ) {
      const data = this.accountService.getAccountLocal();
      if (!data?.declarationFormAccepted) window.location.reload();
      this.router.navigate(['candidate/problem-statements']);
    } else if (
      this.accountService.getCurrentRole() &&
      [AuthorityName.ROLE_RECRUITER, AuthorityName.ROLE_SUPER_ADMIN].includes(
        this.accountService.getCurrentRole() as AuthorityName
      )
    ) {
      this.router.navigate(['scp/problem-statements']);
    } else if (
      this.accountService.getCurrentRole() &&
      this.accountService.getCurrentRole() === AuthorityName.ROLE_INTERVIEWER
    ) {
      this.router.navigate(['/interviewer/interviewer-dashboard']);
    } else if (
      this.accountService.getCurrentRole() &&
      this.accountService.getCurrentRole() === AuthorityName.ROLE_VERIFIER_ADMIN
    ) {
      this.router.navigate(['scp/verifier-admin']);
    } else if(
      this.accountService.getCurrentRole() && 
      this.accountService.getCurrentRole() === AuthorityName.ROLE_SUPPORT_EXECUTIVE
    ) {
      console.log('In Support Executive Role.');
      this.router.navigate(['scp/support-executive']);
    } else {
      this.router.navigate(['/']);
    }
  };

  login = () => {
    if (environment.production) {
      this.accountService.login();
    } else {
      this.keycloakService.login();
    }
  };

  loadScript() {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.type = 'text/javascript';
    script.src = '/assets/landing-page/js/script.js';
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }
  show(){
    document.getElementById('show').style.display = 'block';
  }
  onMouseLeave(){
    document.getElementById('show').style.display = 'none';
  }
  sidenav(){
    this.sideShow = !this.sideShow;
    if(this.sideShow){
      document.getElementById('sideNav').style.display = 'block';
    }else{
      document.getElementById('sideNav').style.display = 'none';
    }
  }
}
