<fieldset [disabled]="disabled">

  <div *ngIf="view==='TWO_COLUMNS';else oneColumn">
    <div class="row">
      <div class="col-6" style="display: flex;flex-direction: column;justify-content:center;">
        <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
            {{questionIndex+1}}.
          </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
            *
          </span></p>
        <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">

        <span *ngIf="!responsePage.sections[section].questions[questionIndex].isValid"
          style="color:red;font-size: 12px;">
          {{responsePage.sections[section].questions[questionIndex].validationMessage}}</span>
      </div>
      <div class="col-6">

        <mat-radio-group aria-label="Select an option" class="example-radio-group" (change)="inputChanged($event)">

          <mat-radio-button *ngFor="let option of options" [value]="option.value" class="example-radio-button"
            [checked]="responsePage.sections[section].questions[questionIndex].response.includes(option.value)">
            {{option.value}}
          </mat-radio-button>
          <mat-radio-button value="other" class="example-radio-button" *ngIf="includeOthers"
            [checked]="goalOption==='other'" (keydown)="keyUpOthers($event)">

            <input matInput placeholder="{{question.othersHint}}" [(ngModel)]="customGoalName"
              style="border-bottom: 1px solid black;width:180px" (keyup)="changeOther()">

          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>

  <ng-template #oneColumn>

    <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
        {{questionIndex+1}}.
      </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
        *
      </span></p>
    <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">

    <span *ngIf="!responsePage.sections[section].questions[questionIndex].isValid" style="color:red;font-size: 12px;">
      {{responsePage.sections[section].questions[questionIndex].validationMessage}}</span>
    <mat-radio-group aria-label="Select an option" class="example-radio-group" (change)="inputChanged($event)">

      <mat-radio-button *ngFor="let option of options" [value]="option.value" class="example-radio-button"
        [checked]="responsePage.sections[section].questions[questionIndex].response.includes(option.value)">
        {{option.value}}
      </mat-radio-button>
      <mat-radio-button value="other" class="example-radio-button" *ngIf="includeOthers"
        [checked]="goalOption==='other'" (keydown)="keyUpOthers($event)">

        <input matInput placeholder="{{question.othersHint}}" [(ngModel)]="customGoalName"
          style="border-bottom: 1px solid black;width:180px" (keyup)="changeOther()">

      </mat-radio-button>
    </mat-radio-group>
  </ng-template>


</fieldset>
