export const collegeCandidatesData = {
  content: [
    {
      id: 2451,
      opportunityId: 1,
      opportunityStatus: 'OPEN',
      opportunityCategory: 'SCHOLARSHIP',
      opportunitySubCategory: null,
      jobTitle: 'STEMfluencer',
      applicationStatus: 'SUBMITTED',
      applicantId: 6409,
      name: 'Sneha Dixit',
      email: 'snehadixit506@gmail.com',
      gender: null,
      dob: '2023-04-16',
      skills: [
        'css',
        'c++',
        'android',
        'bootstrap',
        'redis',
        'docker',
        'sql',
        'linux',
        'powershell',
        'mysql',
        'mongodb',
        'c ',
        'github',
        'python',
        'aws',
      ],
      domicile: null,
      requisition: null,
      careerDetails: null,
      educationDetails: [
        {
          id: 8005,
          degree: 'Class X',
          institute: 'NA',
          university: 'NA',
          specialization: null,
          yearOfPassing: '2020',
          educationType: 'SECONDARY',
          fromDate: null,
          toDate: null,
          duration: null,
          cgpa: 9,
          current: null,
        },
      ],
      projectDetails: null,
      actionType: 'create',
      tenantId: '3',
      tags: null,
    },
    {
      id: 1011,
      opportunityId: 1,
      opportunityStatus: null,
      opportunityCategory: 'SCHOLARSHIP',
      opportunitySubCategory: null,
      jobTitle: 'STEMfluencer',
      applicationStatus: 'SUBMITTED',
      applicantId: 3652,
      name: 'Niramaya Tanna  ',
      email: 'niramaytanna74@gmail.com',
      gender: null,
      dob: null,
      skills: [
        'css',
        'c++',
        'citrix xenapp',
        'android',
        'nodejs',
        'bootstrap',
        'ssl',
        'redis',
        'docker',
        'sql',
        'spring',
        'emc networker',
        'git',
        'java',
        'selenium',
        'restaurant manager',
        'linux',
        'powershell',
        'mysql',
        'mongodb',
        'c ',
        'netapp',
        'swift',
        'node js',
        'github',
        'python',
        'eclipse ide',
        'software development',
        'natural language processing',
        'solarwinds',
        'scratch',
        'open system',
        'javascript',
        'sql server',
        'excel',
        'marketing',
        'hypertext markup language html',
        'apache maven',
        'self',
        'bash',
        'aws',
      ],
      domicile: null,
      requisition: null,
      careerDetails: [],
      educationDetails: [],
      projectDetails: [],
      actionType: 'create',
      tenantId: '3',
      tags: null,
    },
    {
      id: 9651,
      opportunityId: 1,
      opportunityStatus: 'OPEN',
      opportunityCategory: 'SCHOLARSHIP',
      opportunitySubCategory: null,
      jobTitle: 'STEMfluencer',
      applicationStatus: 'SUBMITTED',
      applicantId: 11802,
      name: 'Manisha',
      email: 'manishamittal014@gmail.com',
      gender: null,
      dob: '2000-05-08',
      skills: [
        'scripting',
        'css',
        'windows',
        'latex',
        'prince',
        'python',
        'troubleshooting',
        'mysql',
        'linux',
        'bash',
        'linkedin',
        'network',
        'google',
        'spin',
        'spring',
        'technical',
        'c++',
        'unix',
        'java',
        'sqlite',
        'android',
        'compilers',
        'rest',
        'json',
        'calculus',
        'github',
        'self',
        'youtube',
        'git',
      ],
      domicile: null,
      requisition: null,
      careerDetails: null,
      educationDetails: null,
      projectDetails: null,
      actionType: 'CREATE',
      tenantId: '3',
      tags: null,
    },
    {
      id: 9652,
      opportunityId: 1,
      opportunityStatus: 'OPEN',
      opportunityCategory: 'SCHOLARSHIP',
      opportunitySubCategory: null,
      jobTitle: 'STEMfluencer',
      applicationStatus: 'WITHDRAWN',
      applicantId: 11803,
      name: 'Riha Sharma',
      email: 'rihasharma123@gmail.com',
      gender: null,
      dob: '2000-05-08',
      skills: [
        'scripting',
        'css',
        'windows',
        'latex',
        'prince',
        'python',
        'troubleshooting',
        'mysql',
        'linux',
        'bash',
        'linkedin',
        'network',
        'google',
        'spin',
        'spring',
        'technical',
        'c++',
        'unix',
        'java',
        'sqlite',
        'android',
        'compilers',
        'rest',
        'json',
        'calculus',
        'github',
        'self',
        'youtube',
        'git',
      ],
      domicile: null,
      requisition: null,
      careerDetails: null,
      educationDetails: null,
      projectDetails: null,
      actionType: 'CREATE',
      tenantId: '3',
      tags: null,
    },
  ],
  pageable: {
    sort: {
      sorted: false,
      unsorted: true,
      empty: true,
    },
    pageNumber: 0,
    pageSize: 10,
    offset: 0,
    paged: true,
    unpaged: false,
  },
  totalPages: 1,
  totalElements: 4,
  last: true,
  sort: {
    sorted: false,
    unsorted: true,
    empty: true,
  },
  numberOfElements: 4,
  first: true,
  size: 10,
  number: 0,
  empty: false,
};
