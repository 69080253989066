import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SharedService } from 'src/app/services/shared.service';
import { CandidateInfoService } from '../../services/candidate-info.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { CandidateProfileService } from '../candidate-profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DATE_FORMAT } from 'src/app/app.constants';
import { map, take } from 'rxjs/operators';
import { componentPath } from '../candidate-profile.component';

@Component({
  selector: 'app-team-details-new',
  templateUrl: './team-details.component.html',
  styleUrls: ['./team-details.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})
export class TeamDetailsComponent implements OnInit, AfterViewChecked {
  educationDetails;
  candidateInfoId: number = null;
  institueName = 'abc school';
  candidateInfo !: any;
  jobId!: string;
  applicationData !: any;
  eductaionalDocumentList: string[];
  passingYearArray = [];
  teamNames = [];
  valueType: string = 'SCHOOL_BOARD';
  schoolEducationBoard = [];
  teamForm: FormGroup;
  localStorageKey: string;
  locallyStoredData = null;
  unsavedFormData = null;
  autoSaveLocally: boolean = false;
  isLoading: boolean = true;
  showBoardDropdown: boolean = true;
  showBoardInput: boolean = false;
  disableEditing: boolean = false;
  educationDoc: boolean = false;
  changeIndex: number = 0;
  applicationStatus = null;
  maxLimitForTeam = 5;
  degreeList = [
    'B.Tech',
    'BE',
    'BCA',
    'MCA',
    'M.Tech'
  ];

  specialisation = [
    'Computer Science',
    'Electrical Engineering',
    'Electronics Engineering',
    'Electrical & Electronics Engineering',
    'Mechanical Engineering'
  ]
  selectedEducationTypeList = [];
  fileUploadedHashMap = {};
  applicationId !: any;
  constructor(
    private fb: FormBuilder,
    public sharedService: SharedService,
    private candidateInfoService: CandidateInfoService,
    private dialog: MatDialog,
    private candidateProfileService: CandidateProfileService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) { }
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.getTeamNames();
    this.getCandidateDetails();
    let year = new Date().getFullYear();
    for (let i = year; i <= year + 5; i++) this.passingYearArray.push(i.toString());
    this.teamForm.valueChanges
      .pipe(map((_) => this.teamForm.getRawValue()))
      .subscribe((changedValues) => {
        if (this.changeIndex < 1) {
          const storageItem = {
            updatedAt: new Date(),
            data: changedValues,
          };
          localStorage.setItem(
            this.localStorageKey,
            JSON.stringify(storageItem)
          );
          this.unsavedFormData = changedValues;
          this.autoSaveLocally = true;
          this.candidateProfileService.markComponentAsChanged();
          this.changeIndex++;
        } else {
          this.unsavedFormData = changedValues;
        }
      });
    this.getProblemId();
  }


  getCandidateDetails() {
    this.candidateInfoId = this.candidateProfileService.getCandidateInfoId();
    this.jobId = this.candidateProfileService.opportunityId;
    this.applicationId = this.candidateProfileService.applicationId
    this.candidateInfo = this.candidateProfileService.getCandidateProfile();
    this.institueName = this.candidateInfo.uiScholarshipDetailsDTO?.educationDetailsDTO?.institute
    this.initializeTeam();
  }

  getTeamNames(): void {
    this.candidateInfoService.getTeamNames().subscribe({
      next:(names) => {
        this.teamNames = names;
      },
      error: (err) => {
        this.sharedService.openErrorSnackBar(
          'Could not get teamNames, please try again later!',
          ''
        );
      }
    })
  }

  initializeTeam(): void {
    this.getAllDegree()
    this.teamForm = this.fb.group({
      id: [null],
      applicationsId: [this.applicationId],
      teamName: [null, Validators.required],
      teamMemberUiDTOS: this.fb.array([]),
    });
    if (this.applicationId) {
      this.candidateInfoService.getTeamDetails(this.applicationId).subscribe({
        next: (data) => {
          if (data) {
            for (let teamMate of data.teamMemberUiDTOS) {
              this.addTeamMember();
            }
            this.teamForm.patchValue(data);
          }
          this.isLoading = false;
        },
        error: (err) => {
          if(err?.status==404){
            this.isLoading = false;
          }else{
          this.isLoading = false;
          // this.sharedService.openErrorSnackBar(
          //   'Could not get the Team Details, please try again later!',
          //   ''
          // );
          }
        },
      });
    }
  }


  getProblemId(): void {
    const urlSegments = this.router.url.split('/');
    this.jobId = urlSegments[urlSegments.length - 2];
    this.candidateInfoId = this.candidateProfileService.getCandidateInfoId();
    if (this.jobId && this.candidateInfoId) {
      this.candidateInfoService.getApplicationByCandidateIdAndOpportunityId(this.candidateInfoId, parseInt(this.jobId)).subscribe({
        next: (res: any) => {
          this.applicationData = res;
          this.isLoading=false;
        },
        error: (err) => {
          this.isLoading=false;
          this.sharedService.openErrorSnackBar(
            'Could not get Application Details of Candidiate, please try again later!',
            ''
          );
        },
      });
    }
  }

  changeComponentStateInitially() {
    if (this.candidateProfileService.stepperSetupStatus) {
      this.candidateProfileService.markComponentAsChanged();
    } else {
      this.candidateProfileService.stepperSetupStatus$
        .pipe(take(1))
        .subscribe((status) => {
          this.candidateProfileService.markComponentAsChanged();
        });
    }
    this.unsavedFormData = this.teamForm.getRawValue();
    this.autoSaveLocally = true;
    this.changeIndex++;
  }


  get teamMembers(): FormArray {
    return this.teamForm.get('teamMemberUiDTOS') as FormArray;
  }

  newTeamMember = (): FormGroup => {
    return this.fb.group({
      id: [null],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, Validators.required],
      educationDetailDTO: this.fb.group({
        id: [null],
        degree: [null, Validators.required],
        institue: [this.institueName, Validators.required],
        yearOfPassing: [null, Validators.required],
        specialisation: [null, Validators.required],
        cgpa: [null],
        isCurrent: [true, Validators.required],
      })
    });
  };

  addTeamMember = () => {
    this.teamMembers.push(this.newTeamMember(), {
      emitEvent: false,
    });
  };

  onClickReset() {
    this.isLoading = true;
    this.teamMembers.value.forEach((educationDTO, index) => {
      const id = educationDTO.id;
      this.teamMembers.at(index).reset({ id: id });
    });
    this.selectedEducationTypeList = [];
    let initailValueObject = {};
    this.eductaionalDocumentList?.forEach((document) => {
      initailValueObject[document] = 'Attach Doc';
      this.fileUploadedHashMap[document] = false;
    });
    if (this.eductaionalDocumentList.length > 0)
      this.candidateProfileService.resetDocument$.next(
        this.eductaionalDocumentList
      );
    this.isLoading = false;
  }

  removeTeamMate(index: number) {
    const educationDetailsDTO = this.teamMembers
      .at(index)
      .getRawValue();
    if (educationDetailsDTO.firstName) {
      const dialogRef = this.dialog.open(ConfirmationComponent, {
        data: {
          text: `Are you sure you want to remove ${educationDetailsDTO.name ? educationDetailsDTO.name + ' ' : ''
            } from your team?`,
        },
        backdropClass: 'backdrop-background',
        autoFocus: false,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'Yes') {
          if (educationDetailsDTO.id) {
            this.candidateInfoService.removeTeammate(educationDetailsDTO.id).subscribe({
              next: (data) => {
                this.teamMembers.removeAt(index);
                this.sharedService.openSuccessSnackBar(
                  'Team Memebr Removed Successfully',
                  ''
                );
              },
              error: (err) => {
                this.sharedService.openErrorSnackBar(
                  'Could Not Remove Team Memebr, please try again later!',
                  ''
                );
              },
            });
          } else {
            this.teamMembers.removeAt(index);
            this.sharedService.openSuccessSnackBar(
              'Team Memebr Removed Successfully',
              ''
            );
          }
        }
      });
    } else {
      // else delete from UI state
      this.teamMembers.removeAt(index);
      this.selectedEducationTypeList = this.selectedEducationTypeList.filter(
        (obj) => obj.atIndex !== index
      );
      this.candidateProfileService.markComponentAsChanged();
    }
  }

  submitDetails() {
    const teamData = this.teamForm.value;
    this.candidateInfoService.submitTeamApplication(teamData).subscribe({
      next: (data) => {
        this.applicationStatus = 'SUBMITTED'
        this.disableEditing = true;
        this.router.navigate(['/candidate/problem-statements']);
        this.sharedService.openSuccessSnackBar(
          'Team Details Submitted Successfully',
          ''
        );
      },
      error: (err) => {
        this.sharedService.openErrorSnackBar(
          'Could not submit the Team, please try again later!',
          ''
        );
      },
    });
  }

  getAllDegree() {
    if (this.candidateProfileService.allDegreesList?.length) {
      this.degreeList = this.candidateProfileService.allDegreesList;
    } else {
      this.candidateInfoService.getDropdownValues(this.valueType).subscribe({
        next: (data) => {
          if(data.length) {
            this.degreeList = data;
          }
          this.candidateProfileService.allDegreesList = this.degreeList;
        },
        error: (err) => {
          this.candidateProfileService.allDegreesList = this.degreeList;
        },
      });
    }
  }
}
