import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService } from '../services/account.service';
import { AuthorityName } from '../shared/model/enumerations/authority-name.model';
import { CandidateProfileService } from '../modules/candidate/candidate-profile/candidate-profile.service';

@Injectable({
  providedIn: 'root'
})
export class CandidateGuardGuard implements CanActivate, CanActivateChild {
  constructor(
    private accountService: AccountService,
    private candidateProfileService: CandidateProfileService,
    private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (
        this.accountService.getCurrentRole() &&
        this.accountService
          .getCurrentRole() === AuthorityName.ROLE_CANDIDATE
      ) {
        if (
          route.url?.[0]?.path === 'candidate-profile' ||
          route.url?.[0]?.path === 'application'
        ) {
          return this.candidateProfileService.checkCandidateProfileState(route.paramMap?.get('jobid'));
        }
        else {
          return true;
        }
      } else {
        this.router.navigate(['/']);
        return false;
      }
  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let parentPath = 'candidate/';
    route.parent?.url?.forEach(pathObject => {
      parentPath += pathObject?.path + '/';
    });
    return this.candidateProfileService.canNavigate(
        route.routeConfig.path,
        parentPath,
        route.parent.paramMap?.get('jobid')
      );
  }
  
}
