<h1 mat-dialog-title class="DSA_wb_h4Header">
  <p style="color: #003865; font-weight: bold">Age Confirmation</p>
</h1>
<mat-dialog-content class="mat-typography">
  <p> By registering to the <strong>WA Foundation</strong> Hackathon Portal, you hereby agree that <strong> you are 18 years or above </strong>and hereby agree & consent to the data collection and processing (including transfer of data outside your jurisdiction) by WA Foundation in line with the Privacy Statement.</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div>
    <button
      mat-flat-button
      class="DSA_wb_miniPrimary-btn"
      [mat-dialog-close]="true"
    >
      <span class="pad8">Yes</span>
    </button>
    <button
      mat-flat-button
      class="DSA_wb_miniSecondary-btn"
      [mat-dialog-close]="false"
    >
      <span class="pad8">No</span>
    </button>
  </div>
</mat-dialog-actions>
