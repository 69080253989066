import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UsersListDashboardStateStorageService {

  page = 0;
  size = 25;
  searchQuery: string = '';

  // selectedJob: JobRole;

  init = () => {
    this.page = 0;
    this.size = 25;
    this.searchQuery = '';
    // this.selectedJob = null;
  };
  constructor() { }
}
