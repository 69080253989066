import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';
import {
  allDocumentListByCategory,
  allowedCharactersRegex,
} from '../app.constants';

import * as _ from 'lodash';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private progressBar$: BehaviorSubject<any>;
  private headers = new HttpHeaders().set('content-type', 'application/json');

  constructor(private http: HttpClient, private snackBar: MatSnackBar) {
    this.progressBar$ = new BehaviorSubject({
      on: false,
      mode: 'indeterminate',
      color: 'primary',
      error: false,
    });
  }

  progressBarTrigger(): Observable<boolean> {
    return this.progressBar$.asObservable();
  }

  progressOn(mode1 = 'query', color1 = 'primary') {
    this.progressBar$.next({
      on: true,
      mode: mode1,
      color: color1,
      error: false,
    });
  }

  progressOff(error1 = false) {
    this.progressBar$.next({
      on: false,
      mode: 'indeterminate',
      color: 'primary',
      error: error1,
    });
  }

  getData = (category) => {
    return this.http.get('assets/data-' + category + '.json', {
      responseType: 'json',
    });
  };

  openSnackBar(message: string, action: string, duration1 = 3000) {
    this.snackBar.open(message, action, {
      duration: duration1,
      panelClass: ['green-snackbar'],
    });
  }
  openWarningSnackBar(message: string, action: string, duration1 = 3000) {
    this.snackBar.open(message, action, {
      duration: duration1,
      panelClass: ['mat-toolbar', 'warning-snackbar'],
    });
  }

  toTitleCase = (s) => {
    return s.replace(/_/g, ' ').replace(/\w\S*/g, function (word) {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    });
  };

  removeUnderScores = (s) => {
    return s.replace(/_/g, ' ');
  };

  acceptPolicy = () => {
    return this.http.get(
      environment.profileServiceUrl + 'api/declaration-forms/user/accept',
      {
        headers: this.headers,
      }
    );
  };

  acceptPolicyNew = (body) => {
    return this.http.post(
      environment.profileServiceUrl + 'api/declaration-forms/user',
      body,
      {
        headers: this.headers,
      }
    );
  };

  validateSearchQuery = (query) => {
    const regexStr: RegExp = allowedCharactersRegex;
    return regexStr.test(query);
  };

  openSuccessSnackBar(message: string, action: string, duration1 = 3000) {
    this.snackBar.open(message, action, {
      duration: duration1,
      panelClass: ['mat-toolbar', 'success-snackbar'],
    });
  }

  openErrorSnackBar(message: string, action: string, duration1 = 3000) {
    this.snackBar.open(message, action, {
      duration: duration1,
      panelClass: ['mat-toolbar', 'error-snackbar'],
    });
  }

  getUrlSearchParamsFromObject = (obj: Object) => {
    let body = new URLSearchParams();
    if (obj && !_.isEmpty(obj)) {
      Object.keys(obj).forEach((key) => {
        obj[key]?.forEach((val) => {
          body.append(key, val);
        });
      });
      return body;
    }
    return null;
  };

  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 B';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };
  checkUploadedFiles(fileUploadedHashMap: any) {
    let notUploadedDoc = [];
    Object.entries(fileUploadedHashMap).forEach((doc) => {
      if (!doc[1]) {
        notUploadedDoc.push(
          allDocumentListByCategory[doc[0]]?.label.toUpperCase()
        );
      }
    });
    if (notUploadedDoc.length) {
      const display = notUploadedDoc.length > 1 ? 'files' : 'file';
      this.openErrorSnackBar(
        'Please upload ' +
          display +
          ' for ' +
          notUploadedDoc?.join(', ') +
          ' and try again.',
        ''
      );
    }
  }
}
