import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { EvaluationTemplateLinkRendererComponent } from 'src/app/modules/iwp-formio/evaluation-templates/evaluation-templates-list/evaluation-templates-list-renderer/evaluation-template-link-renderer.component';
import { EvaluationTemplateStatusRendererComponent } from 'src/app/modules/iwp-formio/evaluation-templates/evaluation-templates-list/evaluation-templates-list-renderer/evaluation-template-status-renderer.component';
import { FormMsService } from 'src/app/services/form-ms.service';
import { CreateTemplateDialogComponent } from '../create-template-dialog/create-template-dialog.component';
import { EvaluationTemplateListStateStorageService } from './evaluation-template-list-state-storage.service';

@Component({
  selector: 'app-evaluation-templates-list',
  templateUrl: './evaluation-templates-list.component.html',
  styleUrls: ['./evaluation-templates-list.component.scss'],
})
export class EvaluationTemplatesListComponent implements OnInit {
  page = 0;
  size = 10;
  refreshAgGrid: Subject<void> = new Subject<void>();
  queryTemplate: string = '';
  searchCondition: boolean = true;
  columnDefs = [
    {
      headerName: 'Template Id',
      field: 'id',
      hide: false,
      maxWidth: 100,
    },
    {
      headerName: 'Template Name',
      field: 'name',
      type: 'link',
      valueFormatter: this.linkFormatter,
      cellRendererFramework: EvaluationTemplateLinkRendererComponent,
      minWidth: 100,
      maxWidth: 300,
      required: true,
    },
    {
      headerName: 'Form Type',
      field: 'formType',
      hide: false,
      maxWidth: 150,
    },
    {
      headerName: 'Description',
      field: 'description',
      hide: false,
      minWidth: 200,
    },
    {
      headerName: 'Final',
      field: 'finalized',
      hide: false,
      maxWidth: 100,
      cellRendererFramework: EvaluationTemplateStatusRendererComponent,
    },
  ];

  constructor(
    private formsService: FormMsService,
    private dialog: MatDialog,
    private evalTemplateStateStorage: EvaluationTemplateListStateStorageService
  ) {}

  ngOnInit(): void {
    const { page, size, searchQuery } = this.evalTemplateStateStorage;

    if (page && size) {
      this.page = page;
      this.size = size;
    }
    if (searchQuery) {
      this.queryTemplate = searchQuery;
    }
  }

  interface = (size: number, page: number, query?: string): Observable<any> => {
    let filterOptions = {
      isFinalized: null,
      formType: null,
    };
    return this.formsService.getEvaluationTemplates(
      page,
      size,
      query,
      filterOptions
    );
  };

  linkFormatter(params: any) {
    if (!params.data) return '';
    return `/evaluation-template/${params.data.id}`;
  }

  onPageChange = (e) => {
    this.evalTemplateStateStorage.page = e.target.page;
    this.evalTemplateStateStorage.size = e.target.size;
    this.evalTemplateStateStorage.searchQuery = e.target.searchQuery;
  };

  createTemplate = () => {
    const dialog = this.dialog.open(CreateTemplateDialogComponent);
  };
}
