import {
  Directive,
  Output,
  Input,
  EventEmitter,
  HostBinding,
  HostListener,
} from '@angular/core';
@Directive({
  selector: '[appDragDropAttachment]',
})
export class DragDropAttachmentDirective {
  @Output() fileDropped = new EventEmitter<any>();
  constructor() {}

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    // this.fileOver = false;
  }

  @HostListener('drop', ['$event']) public onDrag(e: any) {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
      // console.log('you dropped a file');
    }
  }
}
