import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ScheduleService } from 'src/app/services/schedule.service';
import { ICellRendererParams } from 'ag-grid-community';
import { AccountService } from 'src/app/services/account.service';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
@Component({
  template: `<a
      class="DSA_wb_hyperLink-btn"
      *ngIf="this.interviewStatus === 'UPCOMING' 
            && accountService.hasFeatureAccess(FeatureName.INTERVIEW_RESCHEDULE, PermissionType.CREATE)"
      style="text-decoration: none;"
      href="javascript:void(0)"
      matTooltip="Reschedule Interview"
      matTooltipClass="DSA_tooltip"
      (click)="onClick()"
      >{{ value }}</a
    >
    <div *ngIf="this.interviewStatus !== 'UPCOMING' 
                || !accountService.hasFeatureAccess(FeatureName.INTERVIEW_RESCHEDULE, PermissionType.CREATE)">{{ value }}</div>`,
})
export class RescheduleLinkRendererComponent implements AgRendererComponent {
  value: any;
  link: any;
  rounds: any;

  params: ICellRendererParams;
  interviewStatus: any;
  FeatureName = FeatureName;
  PermissionType = PermissionType;

  constructor(
    private router: Router,
    private scheduleService: ScheduleService,
    public accountService: AccountService
  ) {}
  agInit(params: ICellRendererParams): void {
    this.value = params.value;
    if (!params.data) this.link = '';
    else {
      this.link = params.valueFormatted;
      this.rounds = params.data;
      this.interviewStatus = params.data.interviewStatus;
    }

    // console.warn(this.rounds);
    // console.warn(this.link);
    //accountservice se ki reschedule permission hai ki nhi
    // this.link = '';

  }
  onClick() {
    this.scheduleService.setSelectedInterviewSchedule(this.rounds);
    // console.log('rounds', this.rounds);
    this.router.navigate([this.link]);
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
