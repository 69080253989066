<div class="container-fluid layout">
  <div class="row">
    <div class="col-md-12">
      <div class="d-flex justify-content-between align-items-center">
        <h4>Evaluation Templates</h4>
        <div>
          <button
            (click)="createTemplate()"
            mat-flat-button
            class="DSA_wb_Icon-btn"
          >
            <span class="icon_btnCont">
              <i class="icon purple x-16 icon-add"></i>
            </span>
            Create Template
          </button>
        </div>
      </div>
    </div>
  </div>

  <data-grid
    [columnDefs]="columnDefs"
    [searchCondition]="searchCondition"
    [interface]="interface"
    [refreshAgGrid]="refreshAgGrid.asObservable()"
    [pageNumber]="page"
    [pageSize]="size"
    (pageChangeValue)="onPageChange($event)"
    [searchValue]="queryTemplate"
  ></data-grid>
</div>
