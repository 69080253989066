<div class="declaration" [formGroup]="declarations">
  <!-- For freshers -->
  <mat-checkbox
    *ngIf="experienceType === 'FRESHER'"
    class="DSA_Wb_custom-checkbox nomarLR"
    aria-label="checkbox"
    disableRipple="true"
    formControlName="fresherSnapDeclaration"
  >
    I acknowledge that the profile pic and the test snap match the candidate
    whom I am interviewing. I have also checked the ID proof and
    <strong class="font-italic">
      I have not taken any screenshot while candidate was presenting the ID card
      for verification during Interview process.
    </strong>
  </mat-checkbox>

  <!-- for lateral -->
  <!-- combined declaration -->
  <mat-checkbox
    *ngIf="experienceType === 'LATERAL'"
    class="DSA_Wb_custom-checkbox nomarLR"
    aria-label="checkbox"
    disableRipple="true"
    formControlName="lateralSnapDeclaration"
  >
    I acknowledge that the profile picture <strong>if available</strong>,
    matches the candidate whom I am interviewing. I have also checked the ID
    proof and
    <strong class="font-italic">
      I have not taken any screenshot while candidate was presenting the ID card
      for verification during Interview process.
    </strong>
  </mat-checkbox>

  <!-- separate declarations -->
  <!-- <mat-checkbox
    *ngIf="experienceType === 'LATERAL'"
    class="DSA_Wb_custom-checkbox nomarLR"
    aria-label="checkbox"
    disableRipple="true"
    formControlName="lateralSnapDeclaration1"
  >
    I acknowledge that the profile picture matches the candidate whom I am
    interviewing. I have also checked the ID proof.
  </mat-checkbox>
  <mat-checkbox
    *ngIf="experienceType === 'LATERAL'"
    class="DSA_Wb_custom-checkbox nomarLR"
    aria-label="checkbox"
    disableRipple="true"
    formControlName="lateralSnapDeclaration2"
  >
    I have checked the ID proof of the candidate. Profile pic is not available
  </mat-checkbox> -->

  <!-- for both -->
  <mat-checkbox
    class="DSA_Wb_custom-checkbox nomarLR marT8"
    aria-label="checkbox"
    disableRipple="true"
    formControlName="referralDeclaration"
  >
    I acknowledge that I/we do not know the candidate personally nor is the
    candidate referred by me/us.
  </mat-checkbox>
</div>
