/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/tslint/config */
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { Question } from 'src/app/shared/model/question.model';
import { Visibility } from 'src/app/shared/model/enumerations/visibility.model';
import { Theme, QuestionView } from 'src/app/shared/model/theme.model';
import { FileserverService } from 'src/app/survey-master/survey/edit-backgroud-color/fileserver.service';
import { parseZone } from 'moment';
import { OptionOrder } from 'src/app/shared/model/enumerations/option-order.model';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return control && control.invalid;
  }
}

@Component({
  selector: 'jhi-checkbox-preview',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxPreviewComponent implements OnInit, OnChanges {
  @Input()
  currPage: any;
  @Input()
  question: any;
  @Input()
  questionIndex: any;
  @Input()
  responsePage: any;
  @Input()
  section: any;
  @Input()
  preference: any;
  @Input()
  theme: Theme;
  @Input()
  disabled: Boolean = false;

  view = 'NORMAL';

  matcher = new MyErrorStateMatcher();
  options = [];

  control: FormControl;
  customGoalName = '';
  goalOption;
  includeOthers = false;
  containsOthers = false;
  selectedOptions: string[] = [];
  constructor(public fileserverService: FileserverService) {}

  ngOnInit() {
    this.control = new FormControl('');
    if (this.theme.questionView === QuestionView.TWO_COLUMNS) {
      this.view = 'TWO_COLUMNS';
    } else {
      this.view = 'NORMAL';
    }
    //
    this.options = [];
    this.question.type.options.forEach((val) =>
      this.options.push(Object.assign({}, val))
    );
    if (this.question.type.values.length > 0) {
      if (
        this.getValue(this.question, 'SHUFFLE_OPTIONS') === 'true' ||
        this.preference.optionOrder === OptionOrder.RANDOM
      ) {
        this.shuffle(this.options);
      }
      if (this.getValue(this.question, 'INCLUDE_OTHERS') === 'true') {
        this.includeOthers = true;
      }
    }
    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response != null &&
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response.length > 0
    ) {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response.forEach((element) => {
        if (element.includes('other::')) {
          this.containsOthers = true;

          this.goalOption = 'other';
          this.customGoalName = element.split('other::')[1];
          this.selectedOptions.push('other');
        } else {
          this.selectedOptions.push(element);
        }
      });
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response = [];
    }
  }

  ngOnChanges() {
    this.control = new FormControl('');
    if (this.theme.questionView === QuestionView.TWO_COLUMNS) {
      this.view = 'TWO_COLUMNS';
    } else {
      this.view = 'NORMAL';
    }

    //
    this.options = [];
    this.question.type.options.forEach((val) =>
      this.options.push(Object.assign({}, val))
    );
    if (this.question.type.values.length > 0) {
      if (
        this.getValue(this.question, 'SHUFFLE_OPTIONS') === 'true' ||
        this.preference.optionOrder === OptionOrder.RANDOM
      ) {
        this.shuffle(this.options);
      }
      if (this.getValue(this.question, 'INCLUDE_OTHERS') === 'true') {
        this.includeOthers = true;
      }
    }
    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response != null &&
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response.length > 0
    ) {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response.forEach((element) => {
        if (element.includes('other::')) {
          this.containsOthers = true;

          this.goalOption = 'other';
          this.customGoalName = element.split('other::')[1];
          this.selectedOptions.push('other');
        } else {
          this.selectedOptions.push(element);
        }
      });
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response = [];
    }
  }

  areArraysEqualSets(a1, a2) {
    const superSet: { [k: string]: any } = {};
    for (const i of a1) {
      const e = i + typeof i;
      superSet[e] = 1;
    }

    for (const i of a2) {
      const e = i + typeof i;
      if (!superSet[e]) {
        return false;
      }
      superSet[e] = 2;
    }

    for (const e in superSet) {
      if (superSet[e] === 1) {
        return false;
      }
    }
    return true;
  }

  shuffle(array) {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  changeOther() {
    if (this.customGoalName !== null && this.customGoalName === '') {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].isValid = false;
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].validationMessage = 'Please provide an input for other';
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].isValid = true;
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].validationMessage = '';
    }
    this.goalOption = 'other';
    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response === null ||
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response === undefined ||
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response?.length === 0
    ) {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response.push('other::' + this.customGoalName);
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response = this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response.map((item) => {
        if (item.includes('other::')) {
          item = 'other::' + this.customGoalName;
        }
        return item;
      });
    }
  }

  downloadImage(path: string) {
    return this.fileserverService.getFileURLbyPath(path);
  }

  inputChanged(event: any) {
    if (event.source._value === undefined) {
      // undefined event
    } else if (event.source._value.length === 0) {
      if (this.question.preference.isRequired === 1) {
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].isValid = false;
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].validationMessage = 'This field value is required';
      }
      if (event.option._value === 'other') {
        if (event.option._selected === false) {
          this.containsOthers = false;
          this.goalOption = '';
          this.customGoalName = null;
        }
      }
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response = [];
    } else if (event.option._value !== 'other') {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].isValid = true;
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].validationMessage = '';
      const responseArray = [];
      for (let option = 0; option < event.source._value.length; option++) {
        if (event.source._value[option] === 'other') {
          responseArray.push(
            event.source._value[option] + '::' + this.customGoalName
          );
        } else {
          responseArray.push(event.source._value[option]);
        }
      }
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response = responseArray;
    } else {
      if (event.option._selected === true) {
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].isValid = true;
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].validationMessage = '';
        this.containsOthers = true;
        this.goalOption = 'other';
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].response.push('other::' + this.customGoalName);
        if (this.customGoalName === null || this.customGoalName === '') {
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].isValid = false;
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].validationMessage = 'Please provide an input for other';
        } else {
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].isValid = true;
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].validationMessage = '';
        }
      } else {
        if (
          this.responsePage.sections[this.section].questions[this.questionIndex]
            .validationMessage === 'Please provide an input for other'
        ) {
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].isValid = true;
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].validationMessage = '';
        }
        this.containsOthers = false;
        this.goalOption = '';
        this.customGoalName = null;
        // const responsearray = this.responsePage.sections[this.section].questions[this.questionIndex].response;
        // const newResponseArray = responsearray.filter(item => !item.includes('other::'));
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].response = this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].response.filter((item) => !item.includes('other::'));
      }
    }

    this.logicPart();
  }

  logicPart() {
    if (this.question.logics !== null && this.question.logics.length > 0) {
      for (let i = 0; i < this.question.logics.length; i++) {
        let apply = false;
        let condition = 'END';
        for (let r = 0; r < this.question.logics[i].rules.length; r++) {
          let rapply = false;

          if (this.question.logics[i].rules[r].op === 'EQ') {
            if (
              this.responsePage.sections[this.section].questions[
                this.questionIndex
              ].response != null
            ) {
              if (
                this.question.logics[i].rules[r].value
                  .split(',')
                  .includes('other')
              ) {
                let myClonedArray = Object.assign(
                  [],
                  this.question.logics[i].rules[r].value.split(',')
                );
                const otherResponse = this.responsePage.sections[
                  this.section
                ].questions[this.questionIndex].response.find((item) =>
                  item.includes('other::')
                );

                myClonedArray = myClonedArray.map((item) => {
                  if (item === 'other') {
                    return otherResponse;
                  } else {
                    return item;
                  }
                });

                if (
                  this.areArraysEqualSets(
                    this.responsePage.sections[this.section].questions[
                      this.questionIndex
                    ].response,
                    myClonedArray
                  )
                ) {
                  rapply = true;
                }
              } else if (
                this.areArraysEqualSets(
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response,
                  this.question.logics[i].rules[r].value.split(',')
                )
              ) {
                rapply = true;
              } else {
                rapply = false;
              }
            }
          } else if (this.question.logics[i].rules[r].op === 'NEQ') {
            if (
              this.responsePage.sections[this.section].questions[
                this.questionIndex
              ].response != null
            ) {
              if (
                this.question.logics[i].rules[r].value
                  .split(',')
                  .includes('other')
              ) {
                let myClonedArray = Object.assign(
                  [],
                  this.question.logics[i].rules[r].value.split(',')
                );
                const otherResponse = this.responsePage.sections[
                  this.section
                ].questions[this.questionIndex].response.find((item) =>
                  item.includes('other::')
                );

                myClonedArray = myClonedArray.map((item) => {
                  if (item === 'other') {
                    return otherResponse;
                  } else {
                    return item;
                  }
                });

                if (
                  this.areArraysEqualSets(
                    this.responsePage.sections[this.section].questions[
                      this.questionIndex
                    ].response,
                    myClonedArray
                  )
                ) {
                  rapply = true;
                }
              } else if (
                !this.areArraysEqualSets(
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response,
                  this.question.logics[i].rules[r].value.split(',')
                )
              ) {
                rapply = true;
              } else {
                rapply = false;
              }
            }
          }

          if (r > 0) {
            if (condition === 'AND') {
              apply = apply && rapply;
            } else if (condition === 'OR') {
              apply = apply || rapply;
            }
          } else {
            apply = rapply;
          }
          condition = this.question.logics[i].rules[r].conjunction;
        }

        for (let a = 0; a < this.question.logics[i].actions.length; a++) {
          let foundIndex: number = null;

          const actionquestion = this.currPage.sections[
            this.section
          ].questions.find((ques, ind) => {
            if (
              ques.identifier === this.question.logics[i].actions[a].identifier
            ) {
              foundIndex = ind;
              return true;
            } else {
              return false;
            }
          });

          if (apply) {
            if (this.question.logics[i].actions[a].name === 'HIDE') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                this.responsePage.sections[this.section].questions[
                  foundIndex
                ].logicallyHidden = true;
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyHiddenBy.find(
                  (item) => item === this.question.id
                );
                if (exists === undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHiddenBy.push(this.question.id);
                }
              }
            } else if (this.question.logics[i].actions[a].name === 'SHOW') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyShownBy.find(
                  (item) => item === this.question.id
                );
                if (exists === undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyShownBy.push(this.question.id);
                }
                if (
                  this.responsePage.sections[this.section].questions[foundIndex]
                    .logicallyHiddenBy.length === 0
                ) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHidden = false;
                } else {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHidden = true;
                }
              }
            }
          } else {
            if (this.question.logics[i].actions[a].name === 'HIDE') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyHiddenBy.find(
                  (item) => item === this.question.id
                );
                if (exists !== undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHiddenBy = this.responsePage.sections[
                    this.section
                  ].questions[foundIndex].logicallyHiddenBy.filter(
                    (item) => item !== this.question.id
                  );
                }
                if (
                  this.responsePage.sections[this.section].questions[foundIndex]
                    .logicallyHiddenBy.length === 0
                ) {
                  if (
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyShownBy.length === 0
                  ) {
                    if (
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].defaultVisibile
                    ) {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = false;
                    } else {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = true;
                    }
                  } else {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = false;
                  }
                }
              }
            } else if (this.question.logics[i].actions[a].name === 'SHOW') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                this.responsePage.sections[this.section].questions[
                  foundIndex
                ].logicallyHidden = true;
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyShownBy.find(
                  (item) => item === this.question.id
                );
                if (exists !== undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyShownBy = this.responsePage.sections[
                    this.section
                  ].questions[foundIndex].logicallyShownBy.filter(
                    (item) => item !== this.question.id
                  );
                }
                if (
                  this.responsePage.sections[this.section].questions[foundIndex]
                    .logicallyShownBy.length === 0
                ) {
                  if (
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHiddenBy.length === 0
                  ) {
                    if (
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].defaultVisibile
                    ) {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = false;
                    } else {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = true;
                    }
                  } else {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = true;
                  }
                } else {
                  if (
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHiddenBy.length === 0
                  ) {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = false;
                  } else {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  keyUpOthers(event: any) {
    // event.preventDefault();
    event.stopPropagation();
  }

  getValidationValue(question: any, param: string): string {
    const object = question.type.validationValues.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }

  getValue(question: any, param: string): string {
    const object = question.type.values.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }
}
