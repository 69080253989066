import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, Subject, BehaviorSubject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportGenerationService {
  private headers = new HttpHeaders()
    .set('content-type', 'application/json');
  constructor(private http: HttpClient) {}


  generateReportForVerifierAdmin(
    opportunityId: number,
    status: string,
    startDate: string,
    endDate: string
  ): Observable<any> {
    let params = new HttpParams();
    const body = null;
    params = params.append('opportunityId', `${opportunityId}`);
    params = params.append('status', `${status}`);
    params = params.append('startDate', `${startDate}`);
    params = params.append('endDate', `${endDate}`);

    return this.http.get(environment.reportGenerationServiceUrl+'api/report/scholarships', {params});
  }

  generateReport = (jobOpeningId, eventId, startDate?, endDate?) => {
    let formBody = {
      jobOpeningId: [jobOpeningId],
      eventId: [eventId],
    };
    if (startDate) {
      formBody['fromDate'] = startDate;
    }
    if (endDate) {
      formBody['toDate'] = endDate;
    }
    return this.http.post(
      environment.reportGenerationServiceUrl +
        'api/report/job/generate-request',
      formBody,
      { headers: this.headers }
    );
  };

  generateReportForEvent = (eventIdList, startDate?, endDate?) => {
    let formBody = {
      // eventId: [eventId],
      eventId: eventIdList,
    };
    if (startDate) {
      formBody['fromDate'] = startDate;
    }
    if (endDate) {
      formBody['toDate'] = endDate;
    }
    return this.http.post(
      environment.reportGenerationServiceUrl +
        'api/report/job/generate-request',
      formBody,
      { headers: this.headers }
    );
  };

  reportStatus = (reqId) => {
    return this.http.get(
      environment.reportGenerationServiceUrl +
        'api/report-generation-requests/' +
        reqId +
        '/status',
      { headers: this.headers }
    );
  };

  reportGenerationRequest = (id) => {
    return this.http.get(
      environment.reportGenerationServiceUrl + 
      'api/report-generation-requests/' + 
      id
    );
  }
}
