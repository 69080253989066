import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EventNotificationsService } from 'src/app/services/event-notifications.service';

@Component({
  selector: 'app-infy-event-notifications-panelists-grid',
  templateUrl: './infy-event-notifications-panelists-grid.component.html',
  styleUrls: ['./infy-event-notifications-panelists-grid.component.scss'],
})
export class InfyEventNotificationsPanelistsGridComponent
  implements OnInit, OnChanges
{
  @Input() selectedFile;
  @Input() statusSelected;
  refreshAgGrid: Subject<void> = new Subject<void>();

  tableName: string = 'Panelists Status';
  buttonText: string = '';
  buttonCondition: boolean = false;
  levels: number = 1;
  searchCondition: boolean = true;
  customRowHeight = 100;

  page: number = 0;
  size: number = 10;

  constructor(private eventNotificationsService: EventNotificationsService) {}

  columnDefs: any = [
    {
      headerName: 'Category',
      field: 'subCategory',
      hide: false,
      maxWidth: 130,
    },
    {
      headerName: 'User Email',
      hide: false,
      field: 'userEmail',
      minWidth: 150,
    },
    {
      headerName: 'Status',
      hide: false,
      field: 'status',
      // minWidth: 200,
    },

    {
      headerName: 'Message',
      field: 'message',
      hide: false,
      minWidth: 400,
    },
  ];

  interface = (
    size: number,
    page: number,
    query?: string
  ): Observable<any[]> => {
    return this.eventNotificationsService.getBulkUploadFileDetails(
      this.selectedFile.id,
      page,
      size,
      this.statusSelected,
      query
    );
  };
  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    this.applyFiltersToTable();
  }
  applyFiltersToTable = () => {
    this.refreshAgGrid.next();
  };
}
