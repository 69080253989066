<fieldset [disabled]="disabled">

  <div *ngIf="view==='TWO_COLUMNS';else oneColumn">
    <div class="row">
      <div class="col-6" style="display: flex;flex-direction: column;justify-content:center;">
        <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
            {{questionIndex+1}}.
          </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
            *
          </span></p>
        <br>
        <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">

        <span *ngIf="!responsePage.sections[section].questions[questionIndex].isValid"
          style="color:red;font-size: 12px;">
          {{responsePage.sections[section].questions[questionIndex].validationMessage}}</span>
        <br>

      </div>
      <div class="col-6">
        <div class="center">

          <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
              <div style="display:flex;align-items: center;flex-direction: column;cursor:pointer"
                (click)="openFileSelector()">
                <mat-icon style="font-size:30px;cursor:pointer">cloud_upload</mat-icon><br>
                <span style="font-size: 20px;font-weight: bold;">Drop Files Here</span>
              </div>

            </ng-template>
          </ngx-file-drop>
          <br>
          <div class="upload-table">
            <table class="table" *ngIf="uploadedFiles.length>0">
              <thead>
                <tr>
                  <th>Uploaded Files:</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let file of uploadedFiles; let i=index">
                  <td>{{ file.name }} <mat-icon style="cursor: pointer;float: right;" (click)="removeFile(i)">cancel
                    </mat-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>

  <ng-template #oneColumn>
    <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
        {{questionIndex+1}}.
      </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
        *
      </span></p>
    <br>
    <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">

    <span *ngIf="!responsePage.sections[section].questions[questionIndex].isValid" style="color:red;font-size: 12px;">
      {{responsePage.sections[section].questions[questionIndex].validationMessage}}</span>
    <br>
    <div class="center">

      <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          <div style="display:flex;align-items: center;flex-direction: column;cursor:pointer"
            (click)="openFileSelector()">
            <mat-icon style="font-size:30px;cursor:pointer">cloud_upload</mat-icon><br>
            <span style="font-size: 20px;font-weight: bold;">Drop Files Here</span>
          </div>

        </ng-template>
      </ngx-file-drop>
      <br>
      <div class="upload-table">
        <table class="table" *ngIf="uploadedFiles.length>0">
          <thead>
            <tr>
              <th>Uploaded Files:</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let file of uploadedFiles; let i=index">
              <td>{{ file.name }} <mat-icon style="cursor: pointer;float: right;" (click)="removeFile(i)">cancel
                </mat-icon>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </ng-template>



</fieldset>
