import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { FileService } from 'src/app/services/file.service';
import { SharedService } from 'src/app/services/shared.service';
import { EventService } from 'src/app/services/event.service';
import { AccountService } from 'src/app/services/account.service';
import { AddPanelistsComponent } from 'src/app/modules/iwp-event/add-panelists/add-panelists.component';
import { panelistActionRendererComponent } from 'src/app/modules/iwp-event/add-panelists/panelists-renderer/panelist-action-renderer';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
export interface SelectedRole {
  panelistName: string;
  panelistEmail: string;
  panelistId: string;
}
@Component({
  selector: 'app-infy-panelists',
  templateUrl: './infy-panelists.component.html',
  styleUrls: ['./infy-panelists.component.scss'],
})
export class InfyPanelistsComponent implements OnInit {
  bulkuploadFile: any;
  bulkUploadSlots: any;
  totalElements = 0;
  page = 0;
  slotUploadFile: any;
  size = 10;
  loading = false;
  lateralDynamic: boolean = false;
  eventStatus: any = localStorage.getItem('eventStatus');
  refreshAgGrid: Subject<void> = new Subject<void>();

  columnDefs: any;

  tableName: string = 'Panelists';
  buttonText: string = 'Add Panelist(s)';
  levels: number = 1;
  actionCondition: boolean =
    this.accountService.hasFeatureAccess(
      FeatureName.EVENT_PANELIST,
      PermissionType.UPDATE
    ) && this.eventStatus !== 'CLOSED';
  searchCondition: boolean = true;
  FeatureName = FeatureName;
  PermissionType = PermissionType;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private fileService: FileService,
    private sharedService: SharedService,
    private eventService: EventService,
    public accountService: AccountService
  ) {
    this.setColumnDefs();
  }

  interface = (
    size: number,
    page: number,
    query?: string
  ): Observable<any[]> => {
    if (query) {
      query = query.split('@')[0];
      return this.eventService.getInterviewerListWithEventAndSearchParam(
        query,
        size,
        page
      );
    } else return this.eventService.getInterviewerListWithEvent(size, page);
  };

  setColumnDefs = () => {
    this.columnDefs = [
      {
        headerName: 'Panelist ID',
        field: 'panelistId',
        // type: 'required',
        hide: true,
      },
      {
        headerName: 'Name',
        field: 'panelistName',
        type: 'required',
        hide: false,
      },
      { headerName: 'Email', field: 'panelistEmail', hide: false },
      // { headerName: 'Event ID', field: 'eventId', hide: true },
      {
        headerName: 'Actions',
        field: 'actions',
        hide: false,
        cellRendererFramework: panelistActionRendererComponent,
        cellRendererParams: {
          condition: this.actionCondition,
          eventStatus: this.eventStatus,
          isLateralDynamic: this.lateralDynamic,
        },
      },
    ];
  };

  ngOnInit(): void {
    this.checkEventLateralDynamic();

    this.eventStatus = localStorage.getItem('eventStatus');
    if (this.eventStatus === null || !this.eventService.selectedEventId)
      this.router.navigate(['/dashboard']);
  }

  checkEventLateralDynamic = () => {
    this.loading = true;
    this.eventService.getEventById(this.eventService.selectedEventId).subscribe(
      (res: any) => {
        if (res?.eventType === 'LATERAL_DYNAMIC') {
          this.lateralDynamic = true;
          // refresh column def to pass latest lateralDynamic value in actions column
          this.setColumnDefs();
        }
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    );
  };

  bulkUpload = (files) => {
    this.bulkuploadFile = files[0];
  };

  bulkUploadPanelistSlot = (files) => {
    this.bulkUploadSlots = files[0];
  };

  uploadBulkCSV = () => {
    let formData = new FormData();
    const specData = {
      eventId: this.eventService.selectedEventId,
      tenantId: localStorage.getItem('tenantId'),
    };
    formData.append('file', this.bulkuploadFile);
    formData.append('specData', JSON.stringify(specData));
    this.bulkuploadFile = undefined;

    this.fileService.bulkUploadPanelist(formData).subscribe(
      (res) => {
        this.sharedService.openSnackBar('Uploaded Successfully', '', 3000);
        this.refreshAgGrid.next();
      },
      () => {
        this.sharedService.openSnackBar('Some Error Occured', '', 3000);
      }
    );
  };
  uploadSlotsCSV = () => {
    let formData = new FormData();
    const specData = {
      eventId: this.eventService.selectedEventId,
      tenantId: localStorage.getItem('tenantId'),
    };
    formData.append('file', this.bulkUploadSlots);
    formData.append('specData', JSON.stringify(specData));
    this.bulkUploadSlots = undefined;

    this.fileService.bulkUploadPanelistSlots(formData).subscribe(
      (res) => {
        this.sharedService.openSnackBar('Uploaded Successfully', '', 3000);
        this.refreshAgGrid.next();
      },
      () => {
        this.sharedService.openSnackBar('Some Error Occured', '', 3000);
      }
    );
  };

  openAddPanelistDialog() {
    const dialog = this.dialog.open(AddPanelistsComponent, {
      panelClass: 'add-panelists',
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });
    dialog.afterClosed().subscribe((value) => {
      if (value) this.addPanelistToEvent(value);
    });
  }

  addPanelistToEvent(selectedPanelist) {
    this.loading = true;
    this.eventService.addInterviewersToEvent([selectedPanelist.id]).subscribe(
      (res) => {
        this.refreshAgGrid.next();
        this.sharedService.openSnackBar(
          'Panelist added successfully',
          '',
          3000
        );
        this.loading = false;
      },
      (err) => {
        this.sharedService.openSnackBar('Some error occured', '', 3000);
        this.loading = false;
      }
    );
  }
}
