export const environment = {
  production: true,
  envName: 'production',
  keycloak: {
    // Url of the Identity Provider
    issuer: '/auth/',

    // Realm
    realm: 'wa',

    // The SPA's id.
    // The SPA is registerd with this id at the auth-serverß
    clientId: 'internal',
  },
  JOBMS_API: `/services/postms/api`,
  CALLMS_API: `/services/callms/api`,
  //CANDIDATEMS_API: `${SERVER_API_URL}/mp-service-backend/services/candidatems/api`,
  CANDIDATEMS_API: `/services/candidatems/api`,
  ASSIGNMENTMS_API: `/services/assignmentms/api`,
  WORKMS_API: `/workms/api`,
  POSTMS_API: `/services/postms/api`,
  UTILITYMS_API: `/services/utilityms/api`,
  FILEMS_API: `/services/filems/api`,
  SEARCHMS_API: `/services/searchms/api`,
  recommendationSystemUrl: '/services/recommendationms/',
  interviewServiceUrl: '/services/interviewms/',
  gatewayServiceUrl: '',
  meetingServiceUrl: '/services/meetingms/',
  profileServiceUrl: '/services/profilems/',
  formsMS: `/services/formms/`,
  notificationServiceUrl: '/services/templatems/',
  // supersetDashboardUrl:
  //   'https://interviews.infosysapps.com/analytics/login/?next=https://interviews.infosysapps.com/analytics/superset/dashboard/22/?standalone=true',

  interviewDashboardUrl:
    'https://interviews.infosysapps.com/analytics/login?next=https://interviews.infosysapps.com/analytics/superset/dashboard/1/?standalone=true',
  lateralDashboardUrl:
    'https://interviews.infosysapps.com/analytics/login?next=https://interviews.infosysapps.com/analytics/superset/dashboard/2/?standalone=true',
  scpVitalsDashboardUrl:
    'https://apply.infosys.org/services/analytics/superset/dashboard/vitals/?standalone=3&show_filters=0',
  scpInsightsDashboardUrl:
    'https://apply.infosys.org/services/analytics/superset/dashboard/insights/?standalone=3&show_filters=0',

  fileServiceUrl: '/services/filems/',
  feedbackServiceUrl: '/services/feedbackms/',
  eventNotificationServiceUrl: '/services/notificationms/',
  reportGenerationServiceUrl: '/services/report/',
  assistedScheduleServiceUrl: '/services/assistedschedule/',
  telemetryServiceUrl: '/services/telemetry/',
  msauthRedirectUrl:
    'https://login.microsoftonline.com/infosystechnologies.onmicrosoft.com/oauth2/v2.0/authorize?client_id=daa36581-0a25-4613-9e96-eab9ef57b961&response_type=code&redirect_uri=https%3A%2F%2Finterviews.infosysapps.com%2Fmsauth&response_mode=query&scope=offline_access%20profile%20openid%20email%20https%3A%2F%2Fgraph.microsoft.com%2FOnlineMeetings.ReadWrite%20https%3A%2F%2Fgraph.microsoft.com%2FUser.Read',
};
