import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
// import { initializer } from '../utils/app-init';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FileUploadModule } from 'ng2-file-upload';
import { AgGridModule } from 'ag-grid-angular';
import { HttpReqInterceptor } from './http-req.interceptor';
import { NgxPaginationModule } from 'ngx-pagination';
// import { BarRatingModule } from 'ngx-bar-rating';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { TranslateModule } from '@ngx-translate/core';

// Modules in app
import { AppRoutingModule } from './app-routing.module';
import { AppBootstrapModule } from './app-bootstrap.module';
import { AppPluginsModule } from './app-plugins.module';
import { MailTemplateModule } from './mail-template/mail-template.module';
import { SurveyDetailsModule } from './survey-master/survey-details/survey-details.module';
import { SurveymasterSharedLibsModule } from './shared/surveymaster-shared.module';
import { TakeSurveyModule } from 'src/app/survey-master/take-survey/take-survey.module';
import { GatewaySharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from './angular-material.module';
import { AvatarModule } from 'ngx-avatar';

import { CountdownModule } from 'ngx-countdown';
// Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header/header.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
// import { InfyInterviewerCandidateListComponent } from './infy-components/infy-interviewer-candidate-list/infy-interviewer-candidate-list.component';
// import { InfyEvaluationListComponent } from './infy-components/infy-evaluation-list/infy-evaluation-list.component';
import { ConfirmationComponent } from './shared/components/confirmation/confirmation.component';

import { InfyAssociateJobsComponent } from './shared/components/infy-associate-jobs/infy-associate-jobs.component';
import { AgGridComponent } from './shared/components/AgGrid/AgGrid.component';
import { linkRendererComponent } from './shared/components/ag-grid-renderer/linkRenderer.component';
import { RecruiterCandidateLinkRendererComponent } from './modules/iwp-event/infy-candidate-interview-rounds/candidate-interview-rounds-renderer/recruiter-candidate-list/recruiter-candidate-link-renderer.component';
import { RecruiterJobsLinkRendererComponent } from './modules/iwp-event/infy-jobs/jobs-action-renderer/recruiter-jobs-link-renderer.component';

import { actionRendererComponent } from './shared/components/ag-grid-renderer/actionRenderer.component';
// import { ExampleComponent } from './gridComponents/example/example.component';
import { AddInterviewsComponent } from './modules/iwp-event/infy-interview-list/add-interviews/add-interviews.component';

// Survey Master Components
import { PreferenceComponent } from 'src/app/survey-master/preference/preference.component';
import { SurveyCreateDialogContentsComponent } from './survey-master/survey/survey.create.dialog.component';
import { EditBackgroudColorDialogComponent } from './survey-master/survey/edit-backgroud-color/edit-backgroud-color.component';
import { ConfirmationDialogComponent } from './survey-master/confirmation-dialog/confirmation-dialog.dialog.component';
import { FontColorComponent } from './survey-master/survey/font-color/font.color.component';
import { SurveyDeleteDialogComponent } from './survey-master/survey/survey.delete.dialog.component';

// Services
import { JobService } from './services/job.service';
import { EventService } from './services/event.service';

// State Storage Services
import { FilterDialogComponent } from './shared/components/filter-dialog/filter-dialog.component';
import { FilterUiComponent } from './shared/components/filter-ui/filter-ui.component';
import { SidenavComponent } from './header/sidenav/sidenav.component';
import { SortRowsComponent } from './shared/components/sort-rows/sort-rows.component';
import { InfyGlobalSearchComponent } from './modules/iwp-event/infy-global-search/infy-global-search.component';
import { AutoSaveComponent } from './shared/components/auto-save/auto-save.component';
// import { DragDropAttachmentDirective } from './shared/util/drag-drop-attachment.directive';

import { NgxWebstorageModule } from 'ngx-webstorage';
import { UrlStateStorageService } from './url-state-storage.service';
import { HasAnyAuthorityDirective } from './shared/auth/has-any-authority.directive';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ToastrModule } from 'ngx-toastr';
import { CanAddList } from './shared/model/canaddlist.model';
import { TenantsListLandingPageComponent } from './tenants-list-landing-page/tenants-list-landing-page.component';
import { CookieModule } from 'ngx-cookie';
import { initializer } from 'src/utils/app-init';
import { IwpEventModule } from './modules/iwp-event/iwp-event.module';
import { IwpEventNotificationsModule } from './modules/iwp-event-notifications/iwp-event-notifications.module';
import { InfyRecruiterFeedbackModule } from './modules/infy-recruiter-feedback/infy-recruiter-feedback.module';
import { IwpFormioModule } from './modules/iwp-formio/iwp-formio.module';
import { IwpPlatformActionsModule } from './modules/iwp-platform-actions/iwp-platform-actions.module';
import { ArchiveModule } from './modules/archive/archive.module';

import { EventSetupComponent } from './infy-components/event-setup/event-setup.component';
import { MatStepperModule } from '@angular/material/stepper';
import { ArticlesComponent } from './modules/candidate/articles/articles.component';
// import { RecruiterDashboardLinkRendererComponent } from './gridComponents/custom-renderer/recruiter-dashboard/recruiterDashboardLinkRenderer.component';

import { FinalActionConfirmationDialogComponent } from './infy-components/infy-recruiter-feedback/final-action-confirmation-dialog/final-action-confirmation-dialog.component';
import { JobCardComponent } from './modules/candidate/view-all-jobs/job-card/job-card.component';
import { CandidateModule } from './modules/candidate/candidate.module';
import { RecommendationPopUpComponent } from './recommendation-pop-up/recommendation-pop-up.component';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {MatIconModule} from '@angular/material/icon'
import { CollegesListComponent } from './colleges-list/colleges-list.component';
import { FooterComponent } from './footer/footer.component';

import { ApmModule, ApmService, ApmErrorHandler } from '@elastic/apm-rum-angular';
import { environment } from 'src/environments/environment';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

const notifierDefaultOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'left',
      distance: 12,
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 2000,
    onClick: false,
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LandingPageComponent,
    CollegesListComponent,
    // InfyInterviewerCandidateListComponent,
    // InfyEvaluationListComponent,
    // PrivacyPolicyComponent,
    // InfyJobsAdminComponent,
    // InfyCreateJobComponent,
    // ConfirmationComponent,
    // InfySupersetDashboardComponent,
    PreferenceComponent,
    SurveyCreateDialogContentsComponent,
    EditBackgroudColorDialogComponent,
    SurveyDeleteDialogComponent,
    ConfirmationDialogComponent,
    FontColorComponent,

    // InfyAssociateJobsComponent,
    // CustomScrollDirective,

    AddInterviewsComponent,

    // ag-grid related
    // AgGridComponent,
    // linkRendererComponent,
    // actionRendererComponent,
    RecruiterCandidateLinkRendererComponent,
    RecruiterJobsLinkRendererComponent,
    // PanelistRendererComponent,
    // FilterDialogComponent,
    // FilterUiComponent,
    // InfyKeycloakAdminComponent,
    SidenavComponent,
    // MigrateCandidateDataComponent,
    // JobsAdminActionRendererComponent,
    // SortRowsComponent,

    HasAnyAuthorityDirective,
    // AutoSaveComponent,
    HasAnyAuthorityDirective,
    TenantsListLandingPageComponent,
    EventSetupComponent,
    ArticlesComponent,
    FinalActionConfirmationDialogComponent,
    RecommendationPopUpComponent,
    FooterComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    CountdownModule,

    AppRoutingModule,
    MatStepperModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MaterialModule,

    NgbModule,
    KeycloakAngularModule,
    NgxWebstorageModule.forRoot(),
    AvatarModule,

    // FormsModule,

    HttpClientModule,
    FileUploadModule,
    NgxPaginationModule,
    // BarRatingModule,
    AppBootstrapModule,
    AppPluginsModule,

    ClipboardModule,
    NgxChartsModule,
    NotifierModule.withConfig(notifierDefaultOptions),
    TranslateModule.forRoot(),
    TakeSurveyModule,
    SurveymasterSharedLibsModule,
    SurveyDetailsModule,
    GatewaySharedModule,

    // iwp-modules
    IwpEventModule,
    IwpEventNotificationsModule,
    IwpPlatformActionsModule,
    InfyRecruiterFeedbackModule,
    IwpFormioModule,
    MailTemplateModule,
    ArchiveModule, //contains components not being used- entire module can be removed

    NgCircleProgressModule.forRoot(),
    ToastrModule.forRoot(),
    CookieModule.forRoot(),
    CandidateModule,
    MatIconModule,

    //telemetry
    ApmModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [KeycloakService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpReqInterceptor, multi: true },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
    JobService,
    EventService,
    UrlStateStorageService,
    // AccountService,
    CanAddList,

    //telemetry
    ApmService,
    { provide: ErrorHandler, useClass: ApmErrorHandler }
  ],

  entryComponents: [
    // PrivacyPolicyComponent,
    // ConfirmationComponent,
  ],
  bootstrap: [AppComponent],
  exports: [MaterialModule],
})
export class AppModule {
  constructor(private apmService: ApmService) {
    // Agent API is exposed through this apm instance
    const apm = this.apmService.init({
      serviceName: 'scp-ui',
      serverUrl: environment.telemetryServiceUrl,
      environment: environment.envName,
      sendCredentials: true,
    })
  }
}
