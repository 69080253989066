<div class="container-fluid" style="height: 75vh">
  <div class="row h-100 w-100">
    <ngx-graph
      class="chart-container"
      [links]="links"
      [nodes]="nodes"
      [zoomToFit$]="zoomToFit$"
      [center$]="center$"
      [update$]="update$"
      layout="dagreCluster"
    >
      <ng-template #defsTemplate>
        <svg:marker
          id="arrow"
          viewBox="0 -5 10 10"
          refX="8"
          refY="0"
          markerWidth="4"
          markerHeight="4"
          orient="auto"
        >
          <svg:path d="M0,-5L10,0L0,5" class="arrow-head" />
        </svg:marker>
      </ng-template>

      <ng-template #clusterTemplate let-cluster>
        <svg:g
          class="node cluster"
          ngx-tooltip
          [tooltipPlacement]="'top'"
          [tooltipType]="'tooltip'"
          [tooltipTitle]="cluster.label"
        >
          <svg:rect
            rx="5"
            ry="5"
            [attr.width]="cluster.dimension.width"
            [attr.height]="cluster.dimension.height"
            [attr.fill]="cluster.data.color"
          />
        </svg:g>
      </ng-template>

      <ng-template #nodeTemplate let-node>
        <ng-template #tooltipTemplate let-model="model">
          <div class="area-tooltip-container" *ngIf="node.type === 'data'">
            <p
              class="mat-body mb-0"
              *ngIf="
                data[node.id]?.imgTitle && data[node.id]?.imgArr.length > 0
              "
            >
              <strong>{{ data[node.id]?.imgTitle }}</strong>
            </p>
            <img
              *ngFor="let imgSrc of data[node.id]?.imgArr"
              [src]="imgSrc"
              style="max-width: 200px; max-height: 150px; margin: 4px"
            />
            <p class="mat-body mb-0" *ngFor="let key of data[node.id]?.keys">
              <strong>{{ key }}</strong
              >: {{ data[node.id][key] }}
            </p>
          </div>
          <div class="area-tooltip-container" *ngIf="node.type === 'pic'">
            <p class="mat-body">{{ node.label }}</p>
          </div>
        </ng-template>
        <svg:g
          class="node"
          ngx-tooltip
          [tooltipPlacement]="'top'"
          [tooltipType]="'tooltip'"
          [tooltipTemplate]="tooltipTemplate"
          *ngIf="node.type === 'data'"
        >
          <svg:rect
            [attr.width]="node.dimension.width"
            [attr.height]="node.dimension.height"
            [attr.fill]="
              data[node.id]['Result'] === 'SELECTED'
                ? '#32CD32'
                : data[node.id]['Result'] === 'REJECTED' ||
                  data[node.id]['Result'] === 'No Show'
                ? '#FF0000'
                : '#8626C3'
            "
          />
          <svg:text
            alignment-baseline="central"
            [attr.x]="10"
            [attr.y]="node.dimension.height / 2"
            [attr.fill]="'white'"
          >
            {{ node.label }}
          </svg:text>
        </svg:g>
        <svg:g
          class="node"
          ngx-tooltip
          [tooltipPlacement]="'top'"
          [tooltipType]="'tooltip'"
          [tooltipTemplate]="tooltipTemplate"
          *ngIf="node.type === 'pic'"
        >
          <image [attr.href]="node.imgUrl" height="200" width="200"></image>
          <foreignObject width="200" height="28" y="-28">
            <p class="nodeLabel">{{ node.label }}</p>
          </foreignObject>
        </svg:g>
      </ng-template>

      <ng-template #linkTemplate let-link>
        <svg:g class="edge">
          <svg:path
            class="line"
            stroke-width="2"
            [attr.stroke]="link.color ? link.color : 'black'"
            *ngIf="link.type === 'no-arrow'"
          ></svg:path>
          <svg:path
            class="line"
            stroke-width="2"
            marker-end="url(#arrow)"
            *ngIf="link.type === 'arrow'"
          ></svg:path>
          <svg:text class="edge-label" text-anchor="middle">
            <textPath
              class="text-path"
              [attr.href]="'#' + link.id"
              [style.dominant-baseline]="link.dominantBaseline"
              startOffset="50%"
            >
              {{ link.label }}
            </textPath>
          </svg:text>
        </svg:g>
      </ng-template>
    </ngx-graph>
  </div>
</div>
