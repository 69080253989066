<span *ngFor="let item of items">{{ item.emailId }} &nbsp;</span>
<span *ngIf="showAddPanelistCondition">
  <a
    class="DSA_wb_hyperLink-btn"
    href="javascript:void(0);"
    (click)="showAddPanelistToInterviewDialog()"
  >
    Add Panelist(s)
  </a>
</span>
