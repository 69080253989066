import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { linkRendererComponent } from 'src/app/shared/components/ag-grid-renderer/linkRenderer.component';
import { FormMsService } from 'src/app/services/form-ms.service';
import { tap } from 'rxjs/operators';
import {
  JobEvaluationFormDTO,
  TemplateEvaluationFormDTO,
} from 'src/app/shared/model/form-ms.model';
import { actionRendererComponent } from 'src/app/shared/components/ag-grid-renderer/actionRenderer.component';
import { param } from 'jquery';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { EvaluationTemplateComponent } from 'src/app/modules/iwp-formio/evaluation-templates/evaluation-template/evaluation-template.component';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-infy-previous-evaluation-forms',
  templateUrl: './infy-previous-evaluation-forms.component.html',
  styleUrls: ['./infy-previous-evaluation-forms.component.scss'],
})
export class InfyPreviousEvaluationFormsComponent implements OnInit {
  @Input() jobRoleId: number;
  @Input() formType: string;

  page = 0;
  size = 10;
  refreshAgGrid: Subject<void> = new Subject<void>();
  searchCondition: boolean = true;
  isLoading: boolean = false;

  @Output()
  onAssociateTemplate: EventEmitter<JobEvaluationFormDTO> = new EventEmitter<JobEvaluationFormDTO>();

  associate = (params: any) => {
    const evForm: JobEvaluationFormDTO = params.data;
    const template: TemplateEvaluationFormDTO = evForm.templateEvaluationForm;
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        text: `Are you sure you want to associate <b>${template.name}</b> template with this job? `,
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Yes') {
        var evaluationForm: JobEvaluationFormDTO = {
          id: evForm.id,
          isActive: true,
          title: evForm.title,
          description: evForm.description,
        };

        this.isLoading = true;
        this.formsService.updateEvaluationForm(evaluationForm).subscribe({
          next: (res) => {
            this.sharedService.openSnackBar(
              'Template associated successfully',
              '',
              3000
            );
            this.onAssociateTemplate.emit(res);
            this.isLoading = false;
          },
          error: (err) => {
            this.sharedService.openSnackBar('Some error occurred', '', 3000);
            this.isLoading = false;
          },
        });
      }
    });
  };

  columnDefs = [
    {
      headerName: 'Form Id',
      field: 'id',
      hide: true,
      maxWidth: 100,
    },
    {
      headerName: 'Form Title',
      field: 'title',
      minWidth: 100,
      required: true,
    },
    {
      headerName: 'Form Type',
      field: 'formType',
      hide: false,
      maxWidth: 150,
    },
    {
      headerName: 'Description',
      field: 'description',
      hide: true,
      minWidth: 200,
    },
    {
      headerName: 'Version',
      field: 'version',
      hide: false,
      maxWidth: 100,
    },
    {
      headerName: 'Evaluation Template',
      field: 'templateEvaluationForm.name',
      type: 'link',
      valueFormatter: this.linkFormatter,
      cellRendererFramework: linkRendererComponent,
      hide: false,
      minWidth: 200,
    },
    {
      headerName: 'Action',
      field: 'templateEvaluationForm',
      hide: false,
      cellRendererFramework: actionRendererComponent,
      cellRendererParams: {
        icon: 'associate',
        condition: true,
        click: this.associate,
      },
    },
  ];

  constructor(
    private dialog: MatDialog,
    private sharedService: SharedService,
    private formsService: FormMsService
  ) {}

  ngOnInit(): void {}

  interface = (size: number, page: number, query?: string): Observable<any> => {
    let filterOptions = {
      jobRoleId: this.jobRoleId,
      formType: this.formType,
      isActive: false,
    };
    return this.formsService.getEvaluationForm(
      page,
      size,
      query,
      filterOptions
    );
  };

  linkFormatter(params: any) {
    if (!params.data) return '';
    return `/evaluation-template/${params.data.templateEvaluationForm.id}`;
  }
}
