import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { id } from '@swimlane/ngx-charts';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { RecruiterDashboardStateStorageService } from 'src/app/modules/iwp-event/infy-recruiter-dashboard/recruiter-dashboard-state-storage.service';
import { EventService } from 'src/app/services/event.service';
import { SharedService } from 'src/app/services/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
@Component({
  templateUrl: './recruiter-dashboard-action-renderer.component.html',
  styleUrls: ['./recruiter-dashboard-action-renderer.component.scss'],
})
export class RecruiterDashboardRendererComponent
  implements AgRendererComponent {
  params: any;
  locations: String;
  eventId: String;
  // link: any;
  requisitionId: any;
  // condition:any;

  constructor(
    private recruiterDashboardStateStorageService: RecruiterDashboardStateStorageService,
    private sharedService: SharedService,
    private eventService: EventService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  agInit(params: any): void {
    this.params = params;
    this.eventId = params.data?.id;
    if (params.data) {
      // console.log(params.data);
      if (params.type === 'location') {
        this.locations = params.data?.locations;
      }
      console.log(params.type);
      // params.node.setRowHeight(params.data?.panelists.length * 50);
    }
    this.requisitionId = this.router.url.split('/')[3];
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  checkboxToggle = (event, id) => {
    if (event.checked) {
      if (!this.recruiterDashboardStateStorageService.getCheckedStatus(id)) {
        this.recruiterDashboardStateStorageService.addCheckedEvent(id);
      }
    } else {
      this.recruiterDashboardStateStorageService.removeCheckedEvent(id);
    }
    // console.log(
    //   this.recruiterDashboardStateStorageService.getAllCheckedEvents()
    // );
  };

  getEventCheckedStatus = (id) => {
    return this.recruiterDashboardStateStorageService.getCheckedStatus(id);
  };

  associateEventByRequisition = () => {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        text: `Are you sure you want to associate this event with requisition? `,
      },
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Yes') {
        if (this.eventId == undefined) {
          console.log('undefined');
        }
        const data = {
          eventId: this.eventId,
          requisitionId: this.requisitionId,
        };
        console.log('hello');
        console.log(data);
        this.eventService.associateEventByRequisition(data).subscribe(
          (res) => {
            console.log('hello');
            this.sharedService.openSnackBar(
              'Event Successfully Integrated. Click on the event name to navigate to event page',
              '',
              3000
            );
          },
          () => {
            this.sharedService.openSnackBar(
              'Event Association Failed or might already be associated',
              '',
              3000
            );
          }
        );
      }
    });
  };
}
