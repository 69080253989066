<div class="w-100">
  <app-loader [ngClass]="{ 'w-100': loading }" [display]="loading"></app-loader>

  <div *ngIf="!loading && !errorFetchingEvalForm">
    <div
      class="container"
      style="justify-content: center; margin-top: 10px"
      *ngIf="
        surveyDefinition !== null &&
        pagedQuestions !== null &&
        (errorMessage === null || errorMessage === '') &&
        (thankYou === null || thankYou === '')
      "
      style="
        margin: 0px;
        padding: 0px;
        max-width: inherit;
        height: 100%;
        min-height: inherit;
      "
    >
      <mat-card
        [style.fontFamily]="currentTheme.fontFamily"
        [style.background]="
          currentTheme.background
            ? 'url(' + downloadImage(currentTheme.background.path) + ')'
            : ''
        "
        [style.backgroundColor]="currentTheme.bgcolor"
        [style.backgroundRepeat]="currentTheme.background ? 'no-repeat' : ''"
        [style.backgroundSize]="currentTheme.background ? 'cover' : ''"
        [style.color]="currentTheme.fontStyle"
        [style.opacity]="currentTheme.opacity"
        [style.fontWeight]="currentTheme.fontWeight"
        [style.fontSize]="currentTheme.fontSize"
        style="margin: 0px; width: inherit; height: 100%; min-height: inherit"
      >
        <div
          *ngIf="surveyDefinition.preference.showTitle === 1"
          style="
            display: flex;
            font-weight: 600;
            justify-content: center;
            font-size: 24px;
          "
        >
          <span>{{ surveyDefinition.title }}</span>
          <!-- <mat-card-title></mat-card-title> -->
        </div>
        <div
          style="display: flex; justify-content: center"
          class="page-details"
          *ngIf="surveyDefinition.preference.showTitle === 1"
        >
          <div
            class="mat-card-subtitle"
            style="justify-content: center"
            [style.color]="currentTheme.fontStyle"
            [style.opacity]="currentTheme.opacity"
            [style.fontWeight]="currentTheme.fontWeight"
          >
            <span>{{ surveyDefinition.description }}</span>
          </div>
        </div>

        <div
          *ngIf="
            surveyDefinition.preference.viewType === 'ALL_QUESTIONS' ||
              (surveyDefinition.preference.viewType === 'CUSTOM' &&
                !isMobile());
            else questionPerPage
          "
        >
          <div style="display: flex; justify-content: center; width: 100%">
            <div
              style="
                font-size: 18px;
                font-weight: bold;
                justify-content: center;
              "
              *ngIf="currPage.preference.showTitle === 1"
            >
              {{ currPage.title }}
            </div>
          </div>
          <div style="display: flex; justify-content: center; width: 100%">
            <div
              style="font-size: 12px; font-weight: normal; margin-top: 5px"
              *ngIf="currPage.preference.showTitle === 1"
            >
              {{ currPage.description }}
            </div>
          </div>
          <mat-card-content>
            <div
              class="sections"
              *ngIf="currPage != null"
              style="padding: 10px"
            >
              <div
                *ngFor="
                  let section of currPage.sections;
                  let sectionIndex = index
                "
              >
                <div
                  *ngFor="
                    let question of section.questions;
                    let questionIndex = index
                  "
                >
                  <!--{{question.text}} <span *ngIf="question.preference.isRequired">
              <mat-icon style="color: red;font-size: 7px;">star</mat-icon>
            </span> -->
                  <!-- <div>
              <mat-form-field>
                <mat-label for="id">{{question.type.identifier}}</mat-label>
                <input matInput type="text" id="id" name="id" readonly />
              </mat-form-field>
            </div> -->
                  <div
                    [id]="questionIndex"
                    name="dynamicFields"
                    style="display: flex; justify-content: center"
                  >
                    <!-- <img *ngIf="question.logo!==null"
          [src]="downloadImage(question.logo.path)" width="300px" height="350px"> -->
                    <div
                      *ngIf="
                        question.type.typeDefinition?.identifier ===
                        'Small_text'
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <mat-card
                        class="question-card"
                        [ngClass]="{ 'mat-elevation-z': true }"
                        [style.backgroundColor]="
                          question.theme === null
                            ? currentTheme.childTheme.bgcolor
                            : question.theme.bgcolor
                        "
                        [style.fontFamily]="
                          question.theme === null
                            ? currentTheme.childTheme.fontFamily
                            : question.theme.fontFamily
                        "
                        [style.color]="
                          question.theme === null
                            ? currentTheme.childTheme.fontStyle
                            : question.theme.fontStyle
                        "
                        [style.opacity]="
                          question.theme === null
                            ? currentTheme.childTheme.opacity
                            : question.theme.opacity
                        "
                        [style.fontWeight]="
                          question.theme === null
                            ? currentTheme.childTheme.fontWeight
                            : question.theme.fontWeight
                        "
                        [style.fontSize]="
                          question.theme === null
                            ? currentTheme.childTheme.fontSize
                            : question.theme.fontSize
                        "
                        [ngStyle]="{
                          'box-shadow':
                            question.theme === null
                              ? currentTheme.childTheme.shadow
                              : question.theme.shadow
                        }"
                        *ngIf="
                          !responses[currIndex].sections[sectionIndex]
                            .questions[questionIndex].logicallyHidden
                        "
                      >
                        <jhi-small-text-preview
                          [disabled]="disabled"
                          *ngIf="
                            !responses[currIndex].sections[sectionIndex]
                              .questions[questionIndex].logicallyHidden
                          "
                          [currPage]="currPage"
                          [question]="question"
                          [responsePage]="responses[currIndex]"
                          [questionIndex]="questionIndex"
                          [section]="sectionIndex"
                          [preference]="
                            question.preference !== null
                              ? question.preference
                              : currentPreference
                          "
                          [theme]="
                            question.theme !== null
                              ? question.theme
                              : currentTheme.childTheme
                          "
                        >
                        </jhi-small-text-preview>
                      </mat-card>
                    </div>
                    <div
                      *ngIf="
                        question.type.typeDefinition?.identifier === 'Long_text'
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <mat-card
                        class="question-card"
                        [ngClass]="{ 'mat-elevation-z': true }"
                        [style.backgroundColor]="
                          question.theme === null
                            ? currentTheme.childTheme.bgcolor
                            : question.theme.bgcolor
                        "
                        [style.fontFamily]="
                          question.theme === null
                            ? currentTheme.childTheme.fontFamily
                            : question.theme.fontFamily
                        "
                        [style.color]="
                          question.theme === null
                            ? currentTheme.childTheme.fontStyle
                            : question.theme.fontStyle
                        "
                        [style.opacity]="
                          question.theme === null
                            ? currentTheme.childTheme.opacity
                            : question.theme.opacity
                        "
                        [style.fontWeight]="
                          question.theme === null
                            ? currentTheme.childTheme.fontWeight
                            : question.theme.fontWeight
                        "
                        [style.fontSize]="
                          question.theme === null
                            ? currentTheme.childTheme.fontSize
                            : question.theme.fontSize
                        "
                        [ngStyle]="{
                          'box-shadow':
                            question.theme === null
                              ? currentTheme.childTheme.shadow
                              : question.theme.shadow
                        }"
                        *ngIf="
                          !responses[currIndex].sections[sectionIndex]
                            .questions[questionIndex].logicallyHidden
                        "
                      >
                        <jhi-long-text-preview
                          [disabled]="disabled"
                          *ngIf="
                            !responses[currIndex].sections[sectionIndex]
                              .questions[questionIndex].logicallyHidden
                          "
                          [currPage]="currPage"
                          [question]="question"
                          [responsePage]="responses[currIndex]"
                          [questionIndex]="questionIndex"
                          [section]="sectionIndex"
                          [preference]="
                            question.preference !== null
                              ? question.preference
                              : currentPreference
                          "
                          [theme]="
                            question.theme !== null
                              ? question.theme
                              : currentTheme.childTheme
                          "
                        >
                        </jhi-long-text-preview>
                      </mat-card>
                    </div>
                    <div
                      *ngIf="
                        question.type.typeDefinition?.identifier === 'Numeric'
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <mat-card
                        class="question-card"
                        [ngClass]="{ 'mat-elevation-z': true }"
                        [style.backgroundColor]="
                          question.theme === null
                            ? currentTheme.childTheme.bgcolor
                            : question.theme.bgcolor
                        "
                        [style.fontFamily]="
                          question.theme === null
                            ? currentTheme.childTheme.fontFamily
                            : question.theme.fontFamily
                        "
                        [style.color]="
                          question.theme === null
                            ? currentTheme.childTheme.fontStyle
                            : question.theme.fontStyle
                        "
                        [style.opacity]="
                          question.theme === null
                            ? currentTheme.childTheme.opacity
                            : question.theme.opacity
                        "
                        [style.fontWeight]="
                          question.theme === null
                            ? currentTheme.childTheme.fontWeight
                            : question.theme.fontWeight
                        "
                        [style.fontSize]="
                          question.theme === null
                            ? currentTheme.childTheme.fontSize
                            : question.theme.fontSize
                        "
                        [ngStyle]="{
                          'box-shadow':
                            question.theme === null
                              ? currentTheme.childTheme.shadow
                              : question.theme.shadow
                        }"
                        *ngIf="
                          !responses[currIndex].sections[sectionIndex]
                            .questions[questionIndex].logicallyHidden
                        "
                      >
                        <jhi-numeric-preview
                          [disabled]="disabled"
                          *ngIf="
                            !responses[currIndex].sections[sectionIndex]
                              .questions[questionIndex].logicallyHidden
                          "
                          [currPage]="currPage"
                          [question]="question"
                          [responsePage]="responses[currIndex]"
                          [questionIndex]="questionIndex"
                          [section]="sectionIndex"
                          [preference]="
                            question.preference !== null
                              ? question.preference
                              : currentPreference
                          "
                          [theme]="
                            question.theme !== null
                              ? question.theme
                              : currentTheme.childTheme
                          "
                        >
                        </jhi-numeric-preview>
                      </mat-card>
                    </div>
                    <div
                      *ngIf="
                        question.type.typeDefinition?.identifier === 'Comment'
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <mat-card
                        class="question-card"
                        [ngClass]="{ 'mat-elevation-z': true }"
                        [style.backgroundColor]="
                          question.theme === null
                            ? currentTheme.childTheme.bgcolor
                            : question.theme.bgcolor
                        "
                        [style.fontFamily]="
                          question.theme === null
                            ? currentTheme.childTheme.fontFamily
                            : question.theme.fontFamily
                        "
                        [style.color]="
                          question.theme === null
                            ? currentTheme.childTheme.fontStyle
                            : question.theme.fontStyle
                        "
                        [style.opacity]="
                          question.theme === null
                            ? currentTheme.childTheme.opacity
                            : question.theme.opacity
                        "
                        [style.fontWeight]="
                          question.theme === null
                            ? currentTheme.childTheme.fontWeight
                            : question.theme.fontWeight
                        "
                        [style.fontSize]="
                          question.theme === null
                            ? currentTheme.childTheme.fontSize
                            : question.theme.fontSize
                        "
                        [ngStyle]="{
                          'box-shadow':
                            question.theme === null
                              ? currentTheme.childTheme.shadow
                              : question.theme.shadow
                        }"
                        *ngIf="
                          !responses[currIndex].sections[sectionIndex]
                            .questions[questionIndex].logicallyHidden
                        "
                      >
                        <jhi-comment-preview
                          [disabled]="disabled"
                          *ngIf="
                            !responses[currIndex].sections[sectionIndex]
                              .questions[questionIndex].logicallyHidden
                          "
                          [currPage]="currPage"
                          [question]="question"
                          [responsePage]="responses[currIndex]"
                          [questionIndex]="questionIndex"
                          [section]="sectionIndex"
                          [preference]="
                            question.preference !== null
                              ? question.preference
                              : currentPreference
                          "
                          [theme]="
                            question.theme !== null
                              ? question.theme
                              : currentTheme.childTheme
                          "
                        >
                        </jhi-comment-preview>
                      </mat-card>
                    </div>
                    <div
                      *ngIf="
                        question.type.typeDefinition?.identifier === 'Ranking'
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <mat-card
                        class="question-card"
                        [ngClass]="{ 'mat-elevation-z': true }"
                        [style.backgroundColor]="
                          question.theme === null
                            ? currentTheme.childTheme.bgcolor
                            : question.theme.bgcolor
                        "
                        [style.fontFamily]="
                          question.theme === null
                            ? currentTheme.childTheme.fontFamily
                            : question.theme.fontFamily
                        "
                        [style.color]="
                          question.theme === null
                            ? currentTheme.childTheme.fontStyle
                            : question.theme.fontStyle
                        "
                        [style.opacity]="
                          question.theme === null
                            ? currentTheme.childTheme.opacity
                            : question.theme.opacity
                        "
                        [style.fontWeight]="
                          question.theme === null
                            ? currentTheme.childTheme.fontWeight
                            : question.theme.fontWeight
                        "
                        [style.fontSize]="
                          question.theme === null
                            ? currentTheme.childTheme.fontSize
                            : question.theme.fontSize
                        "
                        [ngStyle]="{
                          'box-shadow':
                            question.theme === null
                              ? currentTheme.childTheme.shadow
                              : question.theme.shadow
                        }"
                        *ngIf="
                          !responses[currIndex].sections[sectionIndex]
                            .questions[questionIndex].logicallyHidden
                        "
                      >
                        <jhi-ranking-preview
                          [disabled]="disabled"
                          *ngIf="
                            !responses[currIndex].sections[sectionIndex]
                              .questions[questionIndex].logicallyHidden
                          "
                          [currPage]="currPage"
                          [question]="question"
                          [responsePage]="responses[currIndex]"
                          [questionIndex]="questionIndex"
                          [section]="sectionIndex"
                          [preference]="
                            question.preference !== null
                              ? question.preference
                              : currentPreference
                          "
                          [theme]="
                            question.theme !== null
                              ? question.theme
                              : currentTheme.childTheme
                          "
                        >
                        </jhi-ranking-preview>
                      </mat-card>
                    </div>
                    <div
                      *ngIf="
                        question.type.typeDefinition?.identifier === 'Dropdown'
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <mat-card
                        class="question-card"
                        [ngClass]="{ 'mat-elevation-z': true }"
                        [style.backgroundColor]="
                          question.theme === null
                            ? currentTheme.childTheme.bgcolor
                            : question.theme.bgcolor
                        "
                        [style.fontFamily]="
                          question.theme === null
                            ? currentTheme.childTheme.fontFamily
                            : question.theme.fontFamily
                        "
                        [style.color]="
                          question.theme === null
                            ? currentTheme.childTheme.fontStyle
                            : question.theme.fontStyle
                        "
                        [style.opacity]="
                          question.theme === null
                            ? currentTheme.childTheme.opacity
                            : question.theme.opacity
                        "
                        [style.fontWeight]="
                          question.theme === null
                            ? currentTheme.childTheme.fontWeight
                            : question.theme.fontWeight
                        "
                        [style.fontSize]="
                          question.theme === null
                            ? currentTheme.childTheme.fontSize
                            : question.theme.fontSize
                        "
                        [ngStyle]="{
                          'box-shadow':
                            question.theme === null
                              ? currentTheme.childTheme.shadow
                              : question.theme.shadow
                        }"
                        *ngIf="
                          !responses[currIndex].sections[sectionIndex]
                            .questions[questionIndex].logicallyHidden
                        "
                      >
                        <jhi-dropdown-preview
                          [disabled]="disabled"
                          *ngIf="
                            !responses[currIndex].sections[sectionIndex]
                              .questions[questionIndex].logicallyHidden
                          "
                          [currPage]="currPage"
                          [question]="question"
                          [responsePage]="responses[currIndex]"
                          [questionIndex]="questionIndex"
                          [section]="sectionIndex"
                          [preference]="
                            question.preference !== null
                              ? question.preference
                              : currentPreference
                          "
                          [theme]="
                            question.theme !== null
                              ? question.theme
                              : currentTheme.childTheme
                          "
                        >
                        </jhi-dropdown-preview>
                      </mat-card>
                    </div>
                    <div
                      *ngIf="
                        question.type.typeDefinition?.identifier === 'Date_Time'
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <mat-card
                        class="question-card"
                        [ngClass]="{ 'mat-elevation-z': true }"
                        [style.backgroundColor]="
                          question.theme === null
                            ? currentTheme.childTheme.bgcolor
                            : question.theme.bgcolor
                        "
                        [style.fontFamily]="
                          question.theme === null
                            ? currentTheme.childTheme.fontFamily
                            : question.theme.fontFamily
                        "
                        [style.color]="
                          question.theme === null
                            ? currentTheme.childTheme.fontStyle
                            : question.theme.fontStyle
                        "
                        [style.opacity]="
                          question.theme === null
                            ? currentTheme.childTheme.opacity
                            : question.theme.opacity
                        "
                        [style.fontWeight]="
                          question.theme === null
                            ? currentTheme.childTheme.fontWeight
                            : question.theme.fontWeight
                        "
                        [style.fontSize]="
                          question.theme === null
                            ? currentTheme.childTheme.fontSize
                            : question.theme.fontSize
                        "
                        [ngStyle]="{
                          'box-shadow':
                            question.theme === null
                              ? currentTheme.childTheme.shadow
                              : question.theme.shadow
                        }"
                        *ngIf="
                          !responses[currIndex].sections[sectionIndex]
                            .questions[questionIndex].logicallyHidden
                        "
                      >
                        <jhi-date-time-preview
                          [disabled]="disabled"
                          *ngIf="
                            !responses[currIndex].sections[sectionIndex]
                              .questions[questionIndex].logicallyHidden
                          "
                          [currPage]="currPage"
                          [question]="question"
                          [responsePage]="responses[currIndex]"
                          [questionIndex]="questionIndex"
                          [section]="sectionIndex"
                          [preference]="
                            question.preference !== null
                              ? question.preference
                              : currentPreference
                          "
                          [theme]="
                            question.theme !== null
                              ? question.theme
                              : currentTheme.childTheme
                          "
                        >
                        </jhi-date-time-preview>
                      </mat-card>
                    </div>
                    <div
                      *ngIf="
                        question.type.typeDefinition?.identifier === 'Yes_No'
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <mat-card
                        class="question-card"
                        [ngClass]="{ 'mat-elevation-z': true }"
                        [style.backgroundColor]="
                          question.theme === null
                            ? currentTheme.childTheme.bgcolor
                            : question.theme.bgcolor
                        "
                        [style.fontFamily]="
                          question.theme === null
                            ? currentTheme.childTheme.fontFamily
                            : question.theme.fontFamily
                        "
                        [style.color]="
                          question.theme === null
                            ? currentTheme.childTheme.fontStyle
                            : question.theme.fontStyle
                        "
                        [style.opacity]="
                          question.theme === null
                            ? currentTheme.childTheme.opacity
                            : question.theme.opacity
                        "
                        [style.fontWeight]="
                          question.theme === null
                            ? currentTheme.childTheme.fontWeight
                            : question.theme.fontWeight
                        "
                        [style.fontSize]="
                          question.theme === null
                            ? currentTheme.childTheme.fontSize
                            : question.theme.fontSize
                        "
                        [ngStyle]="{
                          'box-shadow':
                            question.theme === null
                              ? currentTheme.childTheme.shadow
                              : question.theme.shadow
                        }"
                        *ngIf="
                          !responses[currIndex].sections[sectionIndex]
                            .questions[questionIndex].logicallyHidden
                        "
                      >
                        <jhi-yes-no-preview
                          [disabled]="disabled"
                          *ngIf="
                            !responses[currIndex].sections[sectionIndex]
                              .questions[questionIndex].logicallyHidden
                          "
                          [currPage]="currPage"
                          [question]="question"
                          [responsePage]="responses[currIndex]"
                          [questionIndex]="questionIndex"
                          [section]="sectionIndex"
                          [preference]="
                            question.preference !== null
                              ? question.preference
                              : currentPreference
                          "
                          [theme]="
                            question.theme !== null
                              ? question.theme
                              : currentTheme.childTheme
                          "
                        ></jhi-yes-no-preview>
                      </mat-card>
                    </div>
                    <div
                      *ngIf="
                        question.type.typeDefinition?.identifier === 'Rating'
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <mat-card
                        class="question-card"
                        [ngClass]="{ 'mat-elevation-z': true }"
                        [style.backgroundColor]="
                          question.theme === null
                            ? currentTheme.childTheme.bgcolor
                            : question.theme.bgcolor
                        "
                        [style.fontFamily]="
                          question.theme === null
                            ? currentTheme.childTheme.fontFamily
                            : question.theme.fontFamily
                        "
                        [style.color]="
                          question.theme === null
                            ? currentTheme.childTheme.fontStyle
                            : question.theme.fontStyle
                        "
                        [style.opacity]="
                          question.theme === null
                            ? currentTheme.childTheme.opacity
                            : question.theme.opacity
                        "
                        [style.fontWeight]="
                          question.theme === null
                            ? currentTheme.childTheme.fontWeight
                            : question.theme.fontWeight
                        "
                        [style.fontSize]="
                          question.theme === null
                            ? currentTheme.childTheme.fontSize
                            : question.theme.fontSize
                        "
                        [ngStyle]="{
                          'box-shadow':
                            question.theme === null
                              ? currentTheme.childTheme.shadow
                              : question.theme.shadow
                        }"
                        *ngIf="
                          !responses[currIndex].sections[sectionIndex]
                            .questions[questionIndex].logicallyHidden
                        "
                      >
                        <jhi-rating-preview
                          [disabled]="disabled"
                          *ngIf="
                            !responses[currIndex].sections[sectionIndex]
                              .questions[questionIndex].logicallyHidden
                          "
                          [currPage]="currPage"
                          [question]="question"
                          [responsePage]="responses[currIndex]"
                          [questionIndex]="questionIndex"
                          [section]="sectionIndex"
                          [preference]="
                            question.preference !== null
                              ? question.preference
                              : currentPreference
                          "
                          [theme]="
                            question.theme !== null
                              ? question.theme
                              : currentTheme.childTheme
                          "
                        >
                        </jhi-rating-preview>
                      </mat-card>
                    </div>
                    <div
                      *ngIf="
                        question.type.typeDefinition?.identifier === 'Checkbox'
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <mat-card
                        class="question-card"
                        [ngClass]="{ 'mat-elevation-z': true }"
                        [style.backgroundColor]="
                          question.theme === null
                            ? currentTheme.childTheme.bgcolor
                            : question.theme.bgcolor
                        "
                        [style.fontFamily]="
                          question.theme === null
                            ? currentTheme.childTheme.fontFamily
                            : question.theme.fontFamily
                        "
                        [style.color]="
                          question.theme === null
                            ? currentTheme.childTheme.fontStyle
                            : question.theme.fontStyle
                        "
                        [style.opacity]="
                          question.theme === null
                            ? currentTheme.childTheme.opacity
                            : question.theme.opacity
                        "
                        [style.fontWeight]="
                          question.theme === null
                            ? currentTheme.childTheme.fontWeight
                            : question.theme.fontWeight
                        "
                        [style.fontSize]="
                          question.theme === null
                            ? currentTheme.childTheme.fontSize
                            : question.theme.fontSize
                        "
                        [ngStyle]="{
                          'box-shadow':
                            question.theme === null
                              ? currentTheme.childTheme.shadow
                              : question.theme.shadow
                        }"
                        *ngIf="
                          !responses[currIndex].sections[sectionIndex]
                            .questions[questionIndex].logicallyHidden
                        "
                      >
                        <jhi-checkbox-preview
                          [disabled]="disabled"
                          *ngIf="
                            !responses[currIndex].sections[sectionIndex]
                              .questions[questionIndex].logicallyHidden
                          "
                          [currPage]="currPage"
                          [question]="question"
                          [responsePage]="responses[currIndex]"
                          [questionIndex]="questionIndex"
                          [section]="sectionIndex"
                          [preference]="
                            question.preference !== null
                              ? question.preference
                              : currentPreference
                          "
                          [theme]="
                            question.theme !== null
                              ? question.theme
                              : currentTheme.childTheme
                          "
                        >
                        </jhi-checkbox-preview>
                      </mat-card>
                    </div>
                    <div
                      *ngIf="
                        question.type.typeDefinition?.identifier === 'Radio'
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <mat-card
                        class="question-card"
                        [ngClass]="{ 'mat-elevation-z': true }"
                        [style.backgroundColor]="
                          question.theme === null
                            ? currentTheme.childTheme.bgcolor
                            : question.theme.bgcolor
                        "
                        [style.fontFamily]="
                          question.theme === null
                            ? currentTheme.childTheme.fontFamily
                            : question.theme.fontFamily
                        "
                        [style.color]="
                          question.theme === null
                            ? currentTheme.childTheme.fontStyle
                            : question.theme.fontStyle
                        "
                        [style.opacity]="
                          question.theme === null
                            ? currentTheme.childTheme.opacity
                            : question.theme.opacity
                        "
                        [style.fontWeight]="
                          question.theme === null
                            ? currentTheme.childTheme.fontWeight
                            : question.theme.fontWeight
                        "
                        [style.fontSize]="
                          question.theme === null
                            ? currentTheme.childTheme.fontSize
                            : question.theme.fontSize
                        "
                        [ngStyle]="{
                          'box-shadow':
                            question.theme === null
                              ? currentTheme.childTheme.shadow
                              : question.theme.shadow
                        }"
                        *ngIf="
                          !responses[currIndex].sections[sectionIndex]
                            .questions[questionIndex].logicallyHidden
                        "
                      >
                        <jhi-radio-buttons-preview
                          [disabled]="disabled"
                          *ngIf="
                            !responses[currIndex].sections[sectionIndex]
                              .questions[questionIndex].logicallyHidden
                          "
                          [currPage]="currPage"
                          [question]="question"
                          [responsePage]="responses[currIndex]"
                          [questionIndex]="questionIndex"
                          [section]="sectionIndex"
                          [preference]="
                            question.preference !== null
                              ? question.preference
                              : currentPreference
                          "
                          [theme]="
                            question.theme !== null
                              ? question.theme
                              : currentTheme.childTheme
                          "
                        >
                        </jhi-radio-buttons-preview>
                      </mat-card>
                    </div>
                    <div
                      *ngIf="
                        question.type.typeDefinition?.identifier === 'Slider'
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <mat-card
                        class="question-card"
                        [ngClass]="{ 'mat-elevation-z': true }"
                        [style.backgroundColor]="
                          question.theme === null
                            ? currentTheme.childTheme.bgcolor
                            : question.theme.bgcolor
                        "
                        [style.fontFamily]="
                          question.theme === null
                            ? currentTheme.childTheme.fontFamily
                            : question.theme.fontFamily
                        "
                        [style.color]="
                          question.theme === null
                            ? currentTheme.childTheme.fontStyle
                            : question.theme.fontStyle
                        "
                        [style.opacity]="
                          question.theme === null
                            ? currentTheme.childTheme.opacity
                            : question.theme.opacity
                        "
                        [style.fontWeight]="
                          question.theme === null
                            ? currentTheme.childTheme.fontWeight
                            : question.theme.fontWeight
                        "
                        [style.fontSize]="
                          question.theme === null
                            ? currentTheme.childTheme.fontSize
                            : question.theme.fontSize
                        "
                        [ngStyle]="{
                          'box-shadow':
                            question.theme === null
                              ? currentTheme.childTheme.shadow
                              : question.theme.shadow
                        }"
                        *ngIf="
                          !responses[currIndex].sections[sectionIndex]
                            .questions[questionIndex].logicallyHidden
                        "
                      >
                        <jhi-slider-preview
                          [disabled]="disabled"
                          *ngIf="
                            !responses[currIndex].sections[sectionIndex]
                              .questions[questionIndex].logicallyHidden
                          "
                          [currPage]="currPage"
                          [question]="question"
                          [responsePage]="responses[currIndex]"
                          [questionIndex]="questionIndex"
                          [section]="sectionIndex"
                          [preference]="
                            question.preference !== null
                              ? question.preference
                              : currentPreference
                          "
                          [theme]="
                            question.theme !== null
                              ? question.theme
                              : currentTheme.childTheme
                          "
                        ></jhi-slider-preview>
                      </mat-card>
                    </div>
                    <div
                      *ngIf="
                        question.type.typeDefinition?.identifier ===
                        'Number_rating'
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <mat-card
                        class="question-card"
                        [ngClass]="{ 'mat-elevation-z': true }"
                        [style.backgroundColor]="
                          question.theme === null
                            ? currentTheme.childTheme.bgcolor
                            : question.theme.bgcolor
                        "
                        [style.fontFamily]="
                          question.theme === null
                            ? currentTheme.childTheme.fontFamily
                            : question.theme.fontFamily
                        "
                        [style.color]="
                          question.theme === null
                            ? currentTheme.childTheme.fontStyle
                            : question.theme.fontStyle
                        "
                        [style.opacity]="
                          question.theme === null
                            ? currentTheme.childTheme.opacity
                            : question.theme.opacity
                        "
                        [style.fontWeight]="
                          question.theme === null
                            ? currentTheme.childTheme.fontWeight
                            : question.theme.fontWeight
                        "
                        [style.fontSize]="
                          question.theme === null
                            ? currentTheme.childTheme.fontSize
                            : question.theme.fontSize
                        "
                        [ngStyle]="{
                          'box-shadow':
                            question.theme === null
                              ? currentTheme.childTheme.shadow
                              : question.theme.shadow
                        }"
                        *ngIf="
                          !responses[currIndex].sections[sectionIndex]
                            .questions[questionIndex].logicallyHidden
                        "
                      >
                        <jhi-number-rating-preview
                          [disabled]="disabled"
                          *ngIf="
                            !responses[currIndex].sections[sectionIndex]
                              .questions[questionIndex].logicallyHidden
                          "
                          [currPage]="currPage"
                          [question]="question"
                          [responsePage]="responses[currIndex]"
                          [questionIndex]="questionIndex"
                          [section]="sectionIndex"
                          [preference]="
                            question.preference !== null
                              ? question.preference
                              : currentPreference
                          "
                          [theme]="
                            question.theme !== null
                              ? question.theme
                              : currentTheme.childTheme
                          "
                        ></jhi-number-rating-preview>
                      </mat-card>
                    </div>
                    <div
                      *ngIf="
                        question.type.typeDefinition?.identifier ===
                        'HTML_block'
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <mat-card
                        class="question-card"
                        [ngClass]="{ 'mat-elevation-z': true }"
                        [style.backgroundColor]="
                          question.theme === null
                            ? currentTheme.childTheme.bgcolor
                            : question.theme.bgcolor
                        "
                        [style.fontFamily]="
                          question.theme === null
                            ? currentTheme.childTheme.fontFamily
                            : question.theme.fontFamily
                        "
                        [style.color]="
                          question.theme === null
                            ? currentTheme.childTheme.fontStyle
                            : question.theme.fontStyle
                        "
                        [style.opacity]="
                          question.theme === null
                            ? currentTheme.childTheme.opacity
                            : question.theme.opacity
                        "
                        [style.fontWeight]="
                          question.theme === null
                            ? currentTheme.childTheme.fontWeight
                            : question.theme.fontWeight
                        "
                        [style.fontSize]="
                          question.theme === null
                            ? currentTheme.childTheme.fontSize
                            : question.theme.fontSize
                        "
                        [ngStyle]="{
                          'box-shadow':
                            question.theme === null
                              ? currentTheme.childTheme.shadow
                              : question.theme.shadow
                        }"
                        *ngIf="
                          !responses[currIndex].sections[sectionIndex]
                            .questions[questionIndex].logicallyHidden
                        "
                      >
                        <jhi-html-block-preview
                          [disabled]="disabled"
                          *ngIf="
                            !responses[currIndex].sections[sectionIndex]
                              .questions[questionIndex].logicallyHidden
                          "
                          [currPage]="currPage"
                          [question]="question"
                          [responsePage]="responses[currIndex]"
                          [questionIndex]="questionIndex"
                          [section]="sectionIndex"
                          [preference]="
                            question.preference !== null
                              ? question.preference
                              : currentPreference
                          "
                          [theme]="
                            question.theme !== null
                              ? question.theme
                              : currentTheme.childTheme
                          "
                        >
                        </jhi-html-block-preview>
                      </mat-card>
                    </div>
                    <div
                      *ngIf="
                        question.type.typeDefinition?.identifier ===
                        'File_upload'
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <mat-card
                        class="question-card"
                        [ngClass]="{ 'mat-elevation-z': true }"
                        [style.backgroundColor]="
                          question.theme === null
                            ? currentTheme.childTheme.bgcolor
                            : question.theme.bgcolor
                        "
                        [style.fontFamily]="
                          question.theme === null
                            ? currentTheme.childTheme.fontFamily
                            : question.theme.fontFamily
                        "
                        [style.color]="
                          question.theme === null
                            ? currentTheme.childTheme.fontStyle
                            : question.theme.fontStyle
                        "
                        [style.opacity]="
                          question.theme === null
                            ? currentTheme.childTheme.opacity
                            : question.theme.opacity
                        "
                        [style.fontWeight]="
                          question.theme === null
                            ? currentTheme.childTheme.fontWeight
                            : question.theme.fontWeight
                        "
                        [style.fontSize]="
                          question.theme === null
                            ? currentTheme.childTheme.fontSize
                            : question.theme.fontSize
                        "
                        [ngStyle]="{
                          'box-shadow':
                            question.theme === null
                              ? currentTheme.childTheme.shadow
                              : question.theme.shadow
                        }"
                        *ngIf="
                          !responses[currIndex].sections[sectionIndex]
                            .questions[questionIndex].logicallyHidden
                        "
                      >
                        <jhi-file-upload-preview
                          [disabled]="disabled"
                          *ngIf="
                            !responses[currIndex].sections[sectionIndex]
                              .questions[questionIndex].logicallyHidden
                          "
                          [currPage]="currPage"
                          [question]="question"
                          [responsePage]="responses[currIndex]"
                          [questionIndex]="questionIndex"
                          [section]="sectionIndex"
                          [preference]="
                            question.preference !== null
                              ? question.preference
                              : currentPreference
                          "
                          [theme]="
                            question.theme !== null
                              ? question.theme
                              : currentTheme.childTheme
                          "
                        ></jhi-file-upload-preview>
                      </mat-card>
                    </div>
                    <div
                      *ngIf="
                        question.type.typeDefinition?.identifier ===
                        'Photo_upload'
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <mat-card
                        class="question-card"
                        [ngClass]="{ 'mat-elevation-z': true }"
                        [style.backgroundColor]="
                          question.theme === null
                            ? currentTheme.childTheme.bgcolor
                            : question.theme.bgcolor
                        "
                        [style.fontFamily]="
                          question.theme === null
                            ? currentTheme.childTheme.fontFamily
                            : question.theme.fontFamily
                        "
                        [style.color]="
                          question.theme === null
                            ? currentTheme.childTheme.fontStyle
                            : question.theme.fontStyle
                        "
                        [style.opacity]="
                          question.theme === null
                            ? currentTheme.childTheme.opacity
                            : question.theme.opacity
                        "
                        [style.fontWeight]="
                          question.theme === null
                            ? currentTheme.childTheme.fontWeight
                            : question.theme.fontWeight
                        "
                        [style.fontSize]="
                          question.theme === null
                            ? currentTheme.childTheme.fontSize
                            : question.theme.fontSize
                        "
                        [ngStyle]="{
                          'box-shadow':
                            question.theme === null
                              ? currentTheme.childTheme.shadow
                              : question.theme.shadow
                        }"
                        *ngIf="
                          !responses[currIndex].sections[sectionIndex]
                            .questions[questionIndex].logicallyHidden
                        "
                      >
                        <jhi-photo-upload-preview
                          [disabled]="disabled"
                          *ngIf="
                            !responses[currIndex].sections[sectionIndex]
                              .questions[questionIndex].logicallyHidden
                          "
                          [currPage]="currPage"
                          [question]="question"
                          [responsePage]="responses[currIndex]"
                          [questionIndex]="questionIndex"
                          [section]="sectionIndex"
                          [preference]="
                            question.preference !== null
                              ? question.preference
                              : currentPreference
                          "
                          [theme]="
                            question.theme !== null
                              ? question.theme
                              : currentTheme.childTheme
                          "
                        ></jhi-photo-upload-preview>
                      </mat-card>
                    </div>
                    <div
                      *ngIf="
                        question.type.typeDefinition?.identifier ===
                        'Contact_Info'
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <mat-card
                        class="question-card"
                        [ngClass]="{ 'mat-elevation-z': true }"
                        [style.backgroundColor]="
                          question.theme === null
                            ? currentTheme.childTheme.bgcolor
                            : question.theme.bgcolor
                        "
                        [style.fontFamily]="
                          question.theme === null
                            ? currentTheme.childTheme.fontFamily
                            : question.theme.fontFamily
                        "
                        [style.color]="
                          question.theme === null
                            ? currentTheme.childTheme.fontStyle
                            : question.theme.fontStyle
                        "
                        [style.opacity]="
                          question.theme === null
                            ? currentTheme.childTheme.opacity
                            : question.theme.opacity
                        "
                        [style.fontWeight]="
                          question.theme === null
                            ? currentTheme.childTheme.fontWeight
                            : question.theme.fontWeight
                        "
                        [style.fontSize]="
                          question.theme === null
                            ? currentTheme.childTheme.fontSize
                            : question.theme.fontSize
                        "
                        [ngStyle]="{
                          'box-shadow':
                            question.theme === null
                              ? currentTheme.childTheme.shadow
                              : question.theme.shadow
                        }"
                        *ngIf="
                          !responses[currIndex].sections[sectionIndex]
                            .questions[questionIndex].logicallyHidden
                        "
                      >
                        <jhi-contact-info-preview
                          [disabled]="disabled"
                          *ngIf="
                            !responses[currIndex].sections[sectionIndex]
                              .questions[questionIndex].logicallyHidden
                          "
                          [currPage]="currPage"
                          [question]="question"
                          [responsePage]="responses[currIndex]"
                          [questionIndex]="questionIndex"
                          [section]="sectionIndex"
                          [preference]="
                            question.preference !== null
                              ? question.preference
                              : currentPreference
                          "
                          [theme]="
                            question.theme !== null
                              ? question.theme
                              : currentTheme.childTheme
                          "
                        >
                        </jhi-contact-info-preview>
                      </mat-card>
                    </div>
                    <div
                      *ngIf="
                        question.type.typeDefinition?.identifier ===
                        'Multi_Question'
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <mat-card
                        class="question-card"
                        [ngClass]="{ 'mat-elevation-z': true }"
                        [style.backgroundColor]="
                          question.theme === null
                            ? currentTheme.childTheme.bgcolor
                            : question.theme.bgcolor
                        "
                        [style.fontFamily]="
                          question.theme === null
                            ? currentTheme.childTheme.fontFamily
                            : question.theme.fontFamily
                        "
                        [style.color]="
                          question.theme === null
                            ? currentTheme.childTheme.fontStyle
                            : question.theme.fontStyle
                        "
                        [style.opacity]="
                          question.theme === null
                            ? currentTheme.childTheme.opacity
                            : question.theme.opacity
                        "
                        [style.fontWeight]="
                          question.theme === null
                            ? currentTheme.childTheme.fontWeight
                            : question.theme.fontWeight
                        "
                        [style.fontSize]="
                          question.theme === null
                            ? currentTheme.childTheme.fontSize
                            : question.theme.fontSize
                        "
                        [ngStyle]="{
                          'box-shadow':
                            question.theme === null
                              ? currentTheme.childTheme.shadow
                              : question.theme.shadow
                        }"
                        *ngIf="
                          !responses[currIndex].sections[sectionIndex]
                            .questions[questionIndex].logicallyHidden
                        "
                      >
                        <jhi-multi-question-preview
                          [disabled]="disabled"
                          *ngIf="
                            !responses[currIndex].sections[sectionIndex]
                              .questions[questionIndex].logicallyHidden
                          "
                          [currPage]="currPage"
                          [question]="question"
                          [responsePage]="responses[currIndex]"
                          [questionIndex]="questionIndex"
                          [section]="sectionIndex"
                          [preference]="
                            question.preference !== null
                              ? question.preference
                              : currentPreference
                          "
                          [theme]="
                            question.theme !== null
                              ? question.theme
                              : currentTheme.childTheme
                          "
                          [surveyDefinition]="surveyDefinition"
                          [childQuestions]="question.childQuestions"
                        >
                        </jhi-multi-question-preview>
                      </mat-card>
                    </div>
                    <div
                      *ngIf="
                        question.type.typeDefinition?.identifier ===
                        'Multi_textbox'
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <mat-card
                        class="question-card"
                        [ngClass]="{ 'mat-elevation-z': true }"
                        [style.backgroundColor]="
                          question.theme === null
                            ? currentTheme.childTheme.bgcolor
                            : question.theme.bgcolor
                        "
                        [style.fontFamily]="
                          question.theme === null
                            ? currentTheme.childTheme.fontFamily
                            : question.theme.fontFamily
                        "
                        [style.color]="
                          question.theme === null
                            ? currentTheme.childTheme.fontStyle
                            : question.theme.fontStyle
                        "
                        [style.opacity]="
                          question.theme === null
                            ? currentTheme.childTheme.opacity
                            : question.theme.opacity
                        "
                        [style.fontWeight]="
                          question.theme === null
                            ? currentTheme.childTheme.fontWeight
                            : question.theme.fontWeight
                        "
                        [style.fontSize]="
                          question.theme === null
                            ? currentTheme.childTheme.fontSize
                            : question.theme.fontSize
                        "
                        [ngStyle]="{
                          'box-shadow':
                            question.theme === null
                              ? currentTheme.childTheme.shadow
                              : question.theme.shadow
                        }"
                        *ngIf="
                          !responses[currIndex].sections[sectionIndex]
                            .questions[questionIndex].logicallyHidden
                        "
                      >
                        <jhi-multi-textbox-preview
                          [disabled]="disabled"
                          *ngIf="
                            !responses[currIndex].sections[sectionIndex]
                              .questions[questionIndex].logicallyHidden
                          "
                          [currPage]="currPage"
                          [question]="question"
                          [responsePage]="responses[currIndex]"
                          [questionIndex]="questionIndex"
                          [section]="sectionIndex"
                          [preference]="
                            question.preference !== null
                              ? question.preference
                              : currentPreference
                          "
                          [theme]="
                            question.theme !== null
                              ? question.theme
                              : currentTheme.childTheme
                          "
                        >
                        </jhi-multi-textbox-preview>
                      </mat-card>
                    </div>
                    <div
                      *ngIf="
                        question.type.typeDefinition?.identifier === 'Map_Input'
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <mat-card
                        class="question-card"
                        [ngClass]="{ 'mat-elevation-z': true }"
                        [style.backgroundColor]="
                          question.theme === null
                            ? currentTheme.childTheme.bgcolor
                            : question.theme.bgcolor
                        "
                        [style.fontFamily]="
                          question.theme === null
                            ? currentTheme.childTheme.fontFamily
                            : question.theme.fontFamily
                        "
                        [style.color]="
                          question.theme === null
                            ? currentTheme.childTheme.fontStyle
                            : question.theme.fontStyle
                        "
                        [style.opacity]="
                          question.theme === null
                            ? currentTheme.childTheme.opacity
                            : question.theme.opacity
                        "
                        [style.fontWeight]="
                          question.theme === null
                            ? currentTheme.childTheme.fontWeight
                            : question.theme.fontWeight
                        "
                        [style.fontSize]="
                          question.theme === null
                            ? currentTheme.childTheme.fontSize
                            : question.theme.fontSize
                        "
                        [ngStyle]="{
                          'box-shadow':
                            question.theme === null
                              ? currentTheme.childTheme.shadow
                              : question.theme.shadow
                        }"
                        *ngIf="
                          !responses[currIndex].sections[sectionIndex]
                            .questions[questionIndex].logicallyHidden
                        "
                      >
                        <jhi-map-input-preview
                          [disabled]="disabled"
                          *ngIf="
                            !responses[currIndex].sections[sectionIndex]
                              .questions[questionIndex].logicallyHidden
                          "
                          [currPage]="currPage"
                          [question]="question"
                          [responsePage]="responses[currIndex]"
                          [questionIndex]="questionIndex"
                          [section]="sectionIndex"
                          [preference]="
                            question.preference !== null
                              ? question.preference
                              : currentPreference
                          "
                          [theme]="
                            question.theme !== null
                              ? question.theme
                              : currentTheme.childTheme
                          "
                        ></jhi-map-input-preview>
                      </mat-card>
                    </div>
                    <div
                      *ngIf="
                        question.type.typeDefinition?.identifier ===
                        'Video_Upload'
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        width: 100%;
                      "
                    >
                      <mat-card
                        class="question-card"
                        [ngClass]="{ 'mat-elevation-z': true }"
                        [style.backgroundColor]="
                          question.theme === null
                            ? currentTheme.childTheme.bgcolor
                            : question.theme.bgcolor
                        "
                        [style.fontFamily]="
                          question.theme === null
                            ? currentTheme.childTheme.fontFamily
                            : question.theme.fontFamily
                        "
                        [style.color]="
                          question.theme === null
                            ? currentTheme.childTheme.fontStyle
                            : question.theme.fontStyle
                        "
                        [style.opacity]="
                          question.theme === null
                            ? currentTheme.childTheme.opacity
                            : question.theme.opacity
                        "
                        [style.fontWeight]="
                          question.theme === null
                            ? currentTheme.childTheme.fontWeight
                            : question.theme.fontWeight
                        "
                        [style.fontSize]="
                          question.theme === null
                            ? currentTheme.childTheme.fontSize
                            : question.theme.fontSize
                        "
                        [ngStyle]="{
                          'box-shadow':
                            question.theme === null
                              ? currentTheme.childTheme.shadow
                              : question.theme.shadow
                        }"
                        *ngIf="
                          !responses[currIndex].sections[sectionIndex]
                            .questions[questionIndex].logicallyHidden
                        "
                      >
                        <jhi-video-upload-preview
                          [disabled]="disabled"
                          *ngIf="
                            !responses[currIndex].sections[sectionIndex]
                              .questions[questionIndex].logicallyHidden
                          "
                          [currPage]="currPage"
                          [question]="question"
                          [responsePage]="responses[currIndex]"
                          [questionIndex]="questionIndex"
                          [section]="sectionIndex"
                          [preference]="
                            question.preference !== null
                              ? question.preference
                              : currentPreference
                          "
                          [theme]="
                            question.theme !== null
                              ? question.theme
                              : currentTheme.childTheme
                          "
                          [surveyDefinition]="surveyDefinition"
                        ></jhi-video-upload-preview>
                      </mat-card>
                    </div>
                  </div>
                </div>
              </div>

              <div style="height: 20px"></div>
              <div
                *ngIf="!isPageValid"
                style="
                  color: red;
                  background: #ffffff47;
                  padding: 12px;
                  border-radius: 20px;
                "
              >
                <p style="font-weight: bold">Errors:</p>
                <ul>
                  <li *ngFor="let pageError of PageErrors">
                    {{ pageError.question.text }} - {{ pageError.error }}
                  </li>
                </ul>
              </div>
            </div>
          </mat-card-content>
          <!-- next page, previous page -->
          <div class="page-btns" mat-dialog-actions>
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: center;
              "
            >
              <div class="next-btn" *ngIf="displayPrevious">
                <button
                  mat-button
                  mat-raised-button
                  class="DSA_secondary-btn"
                  style="background-color: white"
                  (click)="moveToPreviousPage()"
                >
                  Previous Page
                </button>
              </div>
              <!-- <div style="width: 5px"></div> -->
              <div class="next-btn" *ngIf="displayNext">
                <button
                  mat-button
                  mat-raised-button
                  class="DSA_secondary-btn"
                  style="background-color: white"
                  jhiTranslate="surveymasterApp.page.nextPage"
                  (click)="moveToNextPage()"
                >
                  Next Page
                </button>
              </div>
            </div>
          </div>

          <!-- delete and add attachment -->
          <div
            class="d-flex flex-column justify-content-center align-items-center w-100"
            *ngIf="
              platformId != 'OFFLINE' && !disabled && displaySubmit && !loading
            "
          >
            <!-- option to delete uploaded snapshot if interview is sent back to panel -->
            <div class="delete-snapshot-wrap">
              <app-infy-delete-snapshots
              *ngIf="sentBackToPanelist"
              [interviewId]="interviewId"
              (noUploadedImages)="handleNoImagesChange($event)"
              ></app-infy-delete-snapshots>
            </div>
            <!-- attach snapshot -->
            <mat-card
              class="question-card padB16"
              [style.backgroundColor]="currentTheme.childTheme.bgcolor"
              [style.fontFamily]="currentTheme.childTheme.fontFamily"
              [style.color]="currentTheme.childTheme.fontStyle"
              [style.opacity]="currentTheme.childTheme.opacity"
              [style.fontWeight]="currentTheme.childTheme.fontWeight"
              [style.fontSize]="currentTheme.childTheme.fontSize"
              [ngStyle]="{
                'box-shadow': currentTheme.childTheme.shadow
              }"
            >
              <app-attach-files
                [files]="files"
                (fileChange)="handleFileChange($event)"
              ></app-attach-files>
            </mat-card>
          </div>
          <!-- result dropdown -->
          <div class="page-btns" mat-dialog-actions>
            <div
              *ngIf="displaySubmit && !loading && !disabled"
              class="d-flex justify-content-center w-100"
            >
              <mat-card
                class="question-card nopadB"
                [style.backgroundColor]="currentTheme.childTheme.bgcolor"
                [style.fontFamily]="currentTheme.childTheme.fontFamily"
                [style.color]="currentTheme.childTheme.fontStyle"
                [style.opacity]="currentTheme.childTheme.opacity"
                [style.fontWeight]="currentTheme.childTheme.fontWeight"
                [style.fontSize]="currentTheme.childTheme.fontSize"
                [ngStyle]="{
                  'box-shadow': currentTheme.childTheme.shadow
                }"
              >
                <mat-form-field
                  appearance="fill"
                  class="DSA_Wb_custom-form-field"
                >
                  <mat-label>Result</mat-label>
                  <mat-select
                    name="result"
                    [(ngModel)]="finalSelection"
                    (selectionChange)="initialiseResult()"
                  >
                    <mat-option
                      *ngFor="let result of finalResult"
                      [value]="result.code"
                    >
                      {{ result.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- Rejection reason for HR Round -->
                <mat-form-field
                  appearance="fill"
                  class="DSA_Wb_custom-form-field"
                  *ngIf="
                    candidateSource == 'INTAP' &&
                    jobType == 'HR' &&
                    ['REJECTED', 'ON_HOLD'].includes(finalSelection)
                  "
                >
                  <mat-label>Reason</mat-label>
                  <mat-select name="reason" [(ngModel)]="remarks">
                    <mat-option
                      *ngFor="let reason of resultReason"
                      [value]="reason.key"
                    >
                      {{ reason.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- Rejection reason for tech/mgmt round -->
                <mat-form-field
                  appearance="outline"
                  class="DSA_Wb_custom-form-field"
                  *ngIf="
                    candidateSource == 'INTAP' &&
                    jobType !== 'HR' &&
                    ['REJECTED'].includes(finalSelection)
                  "
                >
                  <mat-label>Rejection Reason (Optional)</mat-label>
                  <mat-select name="reason" [(ngModel)]="remarks">
                    <mat-option>None</mat-option>
                    <mat-option
                      *ngFor="let reason of candidateRejectionReasonTech"
                      [value]="reason"
                    >
                      {{ reason }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </mat-card>
            </div>
            <div
              class="d-flex flex-column justify-content-center align-items-center w-100"
              *ngIf="displaySubmit && !loading && !disabled"
            >
              <mat-card class="question-card declaration-card">
                <mat-card-content>
                  <app-panelist-declaration
                    [isChecked]="isDeclarationAccepted"
                    [experienceType]="experienceType"
                    (onDeclarationChange)="handleDeclarationChange($event)"
                  ></app-panelist-declaration>
                </mat-card-content>
              </mat-card>
              <div class="submit-btn marT16 d-flex justify-content-center">
                <button
                  mat-button
                  class="DSA_primary-btn"
                  [disabled]="
                    !finalSelection ||
                    !isDeclarationAccepted ||
                    (candidateSource == 'INTAP' &&
                      jobType == 'HR' &&
                      ['REJECTED', 'ON_HOLD'].includes(finalSelection) &&
                      remarks == '')
                  "
                  jhiTranslate="surveymasterApp.page.submitPage"
                  (click)="submitPage()"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div style="height: 40px"></div>
        </div>
        <ng-template #questionPerPage>
          <div style="display: flex; justify-content: center; width: 100%">
            <div
              style="
                font-size: 18px;
                font-weight: bold;
                justify-content: center;
              "
              *ngIf="currPage.preference.showTitle === 1"
            >
              {{ currPage.title }}
            </div>
          </div>
          <div style="display: flex; justify-content: center; width: 100%">
            <div
              style="font-size: 12px; font-weight: normal; margin-top: 5px"
              *ngIf="currPage.preference.showTitle === 1"
            >
              {{ currPage.description }}
            </div>
          </div>
          <div style="height: 10px"></div>

          <div style="height: 10px"></div>
          <mat-card-content>
            <div
              name="dynamicFields"
              *ngIf="selectedQuestion !== null"
              style="display: flex; justify-content: center; width: 100%"
            >
              <!-- <img *ngIf="question.logo!==null"
          [src]="downloadImage(question.logo.path)" width="300px" height="350px"> -->
              <div
                *ngIf="
                  selectedQuestion.type.typeDefinition?.identifier ===
                  'Small_text'
                "
                style="display: flex; justify-content: center; width: 100%"
              >
                <mat-card
                  class="question-card"
                  [ngClass]="{ 'mat-elevation-z': true }"
                  [style.backgroundColor]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.bgcolor
                      : selectedQuestion.theme.bgcolor
                  "
                  [style.fontFamily]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontFamily
                      : selectedQuestion.theme.fontFamily
                  "
                  [style.color]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontStyle
                      : selectedQuestion.theme.fontStyle
                  "
                  [style.opacity]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.opacity
                      : selectedQuestion.theme.opacity
                  "
                  [style.fontWeight]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontWeight
                      : selectedQuestion.theme.fontWeight
                  "
                  [style.fontSize]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontSize
                      : selectedQuestion.theme.fontSize
                  "
                  [ngStyle]="{
                    'box-shadow':
                      selectedQuestion.theme === null
                        ? currentTheme.childTheme.shadow
                        : selectedQuestion.theme.shadow
                  }"
                >
                  <jhi-small-text-preview
                    [disabled]="disabled"
                    *ngIf="
                      !responses[currIndex].sections[currentSectionIndex]
                        .questions[currentQuestionIndex].logicallyHidden
                    "
                    [currPage]="currPage"
                    [question]="selectedQuestion"
                    [responsePage]="responses[currIndex]"
                    [questionIndex]="currentQuestionIndex"
                    [section]="currentSectionIndex"
                    [preference]="
                      selectedQuestion.preference !== null
                        ? selectedQuestion.preference
                        : currentPreference
                    "
                    [theme]="
                      selectedQuestion.theme !== null
                        ? selectedQuestion.theme
                        : currentTheme.childTheme.childTheme
                    "
                  >
                  </jhi-small-text-preview>
                </mat-card>
              </div>
              <div
                *ngIf="
                  selectedQuestion.type.typeDefinition?.identifier ===
                  'Long_text'
                "
                style="display: flex; justify-content: center; width: 100%"
              >
                <mat-card
                  class="question-card"
                  [ngClass]="{ 'mat-elevation-z': true }"
                  [style.backgroundColor]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.bgcolor
                      : selectedQuestion.theme.bgcolor
                  "
                  [style.fontFamily]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontFamily
                      : selectedQuestion.theme.fontFamily
                  "
                  [style.color]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontStyle
                      : selectedQuestion.theme.fontStyle
                  "
                  [style.opacity]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.opacity
                      : selectedQuestion.theme.opacity
                  "
                  [style.fontWeight]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontWeight
                      : selectedQuestion.theme.fontWeight
                  "
                  [style.fontSize]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontSize
                      : selectedQuestion.theme.fontSize
                  "
                  [ngStyle]="{
                    'box-shadow':
                      selectedQuestion.theme === null
                        ? currentTheme.childTheme.shadow
                        : selectedQuestion.theme.shadow
                  }"
                >
                  <jhi-long-text-preview
                    [disabled]="disabled"
                    *ngIf="
                      !responses[currIndex].sections[currentSectionIndex]
                        .questions[currentQuestionIndex].logicallyHidden
                    "
                    [currPage]="currPage"
                    [question]="selectedQuestion"
                    [responsePage]="responses[currIndex]"
                    [questionIndex]="currentQuestionIndex"
                    [section]="currentSectionIndex"
                    [preference]="
                      selectedQuestion.preference !== null
                        ? selectedQuestion.preference
                        : currentPreference
                    "
                    [theme]="
                      selectedQuestion.theme !== null
                        ? selectedQuestion.theme
                        : currentTheme.childTheme
                    "
                  >
                  </jhi-long-text-preview>
                </mat-card>
              </div>
              <div
                *ngIf="
                  selectedQuestion.type.typeDefinition?.identifier === 'Numeric'
                "
                style="display: flex; justify-content: center; width: 100%"
              >
                <mat-card
                  class="question-card"
                  [ngClass]="{ 'mat-elevation-z': true }"
                  [style.backgroundColor]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.bgcolor
                      : selectedQuestion.theme.bgcolor
                  "
                  [style.fontFamily]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontFamily
                      : selectedQuestion.theme.fontFamily
                  "
                  [style.color]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontStyle
                      : selectedQuestion.theme.fontStyle
                  "
                  [style.opacity]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.opacity
                      : selectedQuestion.theme.opacity
                  "
                  [style.fontWeight]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontWeight
                      : selectedQuestion.theme.fontWeight
                  "
                  [style.fontSize]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontSize
                      : selectedQuestion.theme.fontSize
                  "
                  [ngStyle]="{
                    'box-shadow':
                      selectedQuestion.theme === null
                        ? currentTheme.childTheme.shadow
                        : selectedQuestion.theme.shadow
                  }"
                >
                  <jhi-numeric-preview
                    [disabled]="disabled"
                    *ngIf="
                      !responses[currIndex].sections[currentSectionIndex]
                        .questions[currentQuestionIndex].logicallyHidden
                    "
                    [currPage]="currPage"
                    [question]="selectedQuestion"
                    [responsePage]="responses[currIndex]"
                    [questionIndex]="currentQuestionIndex"
                    [section]="currentSectionIndex"
                    [preference]="
                      selectedQuestion.preference !== null
                        ? selectedQuestion.preference
                        : currentPreference
                    "
                    [theme]="
                      selectedQuestion.theme !== null
                        ? selectedQuestion.theme
                        : currentTheme.childTheme
                    "
                  >
                  </jhi-numeric-preview>
                </mat-card>
              </div>
              <div
                *ngIf="
                  selectedQuestion.type.typeDefinition?.identifier === 'Comment'
                "
                style="display: flex; justify-content: center; width: 100%"
              >
                <mat-card
                  class="question-card"
                  [ngClass]="{ 'mat-elevation-z': true }"
                  [style.backgroundColor]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.bgcolor
                      : selectedQuestion.theme.bgcolor
                  "
                  [style.fontFamily]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontFamily
                      : selectedQuestion.theme.fontFamily
                  "
                  [style.color]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontStyle
                      : selectedQuestion.theme.fontStyle
                  "
                  [style.opacity]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.opacity
                      : selectedQuestion.theme.opacity
                  "
                  [style.fontWeight]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontWeight
                      : selectedQuestion.theme.fontWeight
                  "
                  [style.fontSize]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontSize
                      : selectedQuestion.theme.fontSize
                  "
                  [ngStyle]="{
                    'box-shadow':
                      selectedQuestion.theme === null
                        ? currentTheme.childTheme.shadow
                        : selectedQuestion.theme.shadow
                  }"
                >
                  <jhi-comment-preview
                    [disabled]="disabled"
                    *ngIf="
                      !responses[currIndex].sections[currentSectionIndex]
                        .questions[currentQuestionIndex].logicallyHidden
                    "
                    [currPage]="currPage"
                    [question]="selectedQuestion"
                    [responsePage]="responses[currIndex]"
                    [questionIndex]="currentQuestionIndex"
                    [section]="currentSectionIndex"
                    [preference]="
                      selectedQuestion.preference !== null
                        ? selectedQuestion.preference
                        : currentPreference
                    "
                    [theme]="
                      selectedQuestion.theme !== null
                        ? selectedQuestion.theme
                        : currentTheme.childTheme
                    "
                  >
                  </jhi-comment-preview>
                </mat-card>
              </div>
              <div
                *ngIf="
                  selectedQuestion.type.typeDefinition?.identifier === 'Ranking'
                "
                style="display: flex; justify-content: center; width: 100%"
              >
                <mat-card
                  class="question-card"
                  [ngClass]="{ 'mat-elevation-z': true }"
                  [style.backgroundColor]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.bgcolor
                      : selectedQuestion.theme.bgcolor
                  "
                  [style.fontFamily]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontFamily
                      : selectedQuestion.theme.fontFamily
                  "
                  [style.color]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontStyle
                      : selectedQuestion.theme.fontStyle
                  "
                  [style.opacity]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.opacity
                      : selectedQuestion.theme.opacity
                  "
                  [style.fontWeight]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontWeight
                      : selectedQuestion.theme.fontWeight
                  "
                  [style.fontSize]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontSize
                      : selectedQuestion.theme.fontSize
                  "
                  [ngStyle]="{
                    'box-shadow':
                      selectedQuestion.theme === null
                        ? currentTheme.childTheme.shadow
                        : selectedQuestion.theme.shadow
                  }"
                >
                  <jhi-ranking-preview
                    [disabled]="disabled"
                    *ngIf="
                      !responses[currIndex].sections[currentSectionIndex]
                        .questions[currentQuestionIndex].logicallyHidden
                    "
                    [currPage]="currPage"
                    [question]="selectedQuestion"
                    [responsePage]="responses[currIndex]"
                    [questionIndex]="currentQuestionIndex"
                    [section]="currentSectionIndex"
                    [preference]="
                      selectedQuestion.preference !== null
                        ? selectedQuestion.preference
                        : currentPreference
                    "
                    [theme]="
                      selectedQuestion.theme !== null
                        ? selectedQuestion.theme
                        : currentTheme.childTheme
                    "
                  >
                  </jhi-ranking-preview>
                </mat-card>
              </div>
              <div
                *ngIf="
                  selectedQuestion.type.typeDefinition?.identifier ===
                  'Dropdown'
                "
                style="display: flex; justify-content: center; width: 100%"
              >
                <mat-card
                  class="question-card"
                  [ngClass]="{ 'mat-elevation-z': true }"
                  [style.backgroundColor]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.bgcolor
                      : selectedQuestion.theme.bgcolor
                  "
                  [style.fontFamily]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontFamily
                      : selectedQuestion.theme.fontFamily
                  "
                  [style.color]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontStyle
                      : selectedQuestion.theme.fontStyle
                  "
                  [style.opacity]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.opacity
                      : selectedQuestion.theme.opacity
                  "
                  [style.fontWeight]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontWeight
                      : selectedQuestion.theme.fontWeight
                  "
                  [style.fontSize]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontSize
                      : selectedQuestion.theme.fontSize
                  "
                  [ngStyle]="{
                    'box-shadow':
                      selectedQuestion.theme === null
                        ? currentTheme.childTheme.shadow
                        : selectedQuestion.theme.shadow
                  }"
                >
                  <jhi-dropdown-preview
                    [disabled]="disabled"
                    *ngIf="
                      !responses[currIndex].sections[currentSectionIndex]
                        .questions[currentQuestionIndex].logicallyHidden
                    "
                    [currPage]="currPage"
                    [question]="selectedQuestion"
                    [responsePage]="responses[currIndex]"
                    [questionIndex]="currentQuestionIndex"
                    [section]="currentSectionIndex"
                    [preference]="
                      selectedQuestion.preference !== null
                        ? selectedQuestion.preference
                        : currentPreference
                    "
                    [theme]="
                      selectedQuestion.theme !== null
                        ? selectedQuestion.theme
                        : currentTheme.childTheme
                    "
                  >
                  </jhi-dropdown-preview>
                </mat-card>
              </div>
              <div
                *ngIf="
                  selectedQuestion.type.typeDefinition?.identifier ===
                  'Date_Time'
                "
                style="display: flex; justify-content: center; width: 100%"
              >
                <mat-card
                  class="question-card"
                  [ngClass]="{ 'mat-elevation-z': true }"
                  [style.backgroundColor]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.bgcolor
                      : selectedQuestion.theme.bgcolor
                  "
                  [style.fontFamily]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontFamily
                      : selectedQuestion.theme.fontFamily
                  "
                  [style.color]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontStyle
                      : selectedQuestion.theme.fontStyle
                  "
                  [style.opacity]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.opacity
                      : selectedQuestion.theme.opacity
                  "
                  [style.fontWeight]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontWeight
                      : selectedQuestion.theme.fontWeight
                  "
                  [style.fontSize]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontSize
                      : selectedQuestion.theme.fontSize
                  "
                  [ngStyle]="{
                    'box-shadow':
                      selectedQuestion.theme === null
                        ? currentTheme.childTheme.shadow
                        : selectedQuestion.theme.shadow
                  }"
                >
                  <jhi-date-time-preview
                    [disabled]="disabled"
                    *ngIf="
                      !responses[currIndex].sections[currentSectionIndex]
                        .questions[currentQuestionIndex].logicallyHidden
                    "
                    [currPage]="currPage"
                    [question]="selectedQuestion"
                    [responsePage]="responses[currIndex]"
                    [questionIndex]="currentQuestionIndex"
                    [section]="currentSectionIndex"
                    [preference]="
                      selectedQuestion.preference !== null
                        ? selectedQuestion.preference
                        : currentPreference
                    "
                    [theme]="
                      selectedQuestion.theme !== null
                        ? selectedQuestion.theme
                        : currentTheme.childTheme
                    "
                  >
                  </jhi-date-time-preview>
                </mat-card>
              </div>
              <div
                *ngIf="
                  selectedQuestion.type.typeDefinition?.identifier === 'Yes_No'
                "
                style="display: flex; justify-content: center; width: 100%"
              >
                <mat-card
                  class="question-card"
                  [ngClass]="{ 'mat-elevation-z': true }"
                  [style.backgroundColor]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.bgcolor
                      : selectedQuestion.theme.bgcolor
                  "
                  [style.fontFamily]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontFamily
                      : selectedQuestion.theme.fontFamily
                  "
                  [style.color]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontStyle
                      : selectedQuestion.theme.fontStyle
                  "
                  [style.opacity]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.opacity
                      : selectedQuestion.theme.opacity
                  "
                  [style.fontWeight]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontWeight
                      : selectedQuestion.theme.fontWeight
                  "
                  [style.fontSize]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontSize
                      : selectedQuestion.theme.fontSize
                  "
                  [ngStyle]="{
                    'box-shadow':
                      selectedQuestion.theme === null
                        ? currentTheme.childTheme.shadow
                        : selectedQuestion.theme.shadow
                  }"
                >
                  <jhi-yes-no-preview
                    [disabled]="disabled"
                    *ngIf="
                      !responses[currIndex].sections[currentSectionIndex]
                        .questions[currentQuestionIndex].logicallyHidden
                    "
                    [currPage]="currPage"
                    [question]="selectedQuestion"
                    [responsePage]="responses[currIndex]"
                    [questionIndex]="currentQuestionIndex"
                    [section]="currentSectionIndex"
                    [preference]="
                      selectedQuestion.preference !== null
                        ? selectedQuestion.preference
                        : currentPreference
                    "
                    [theme]="
                      selectedQuestion.theme !== null
                        ? selectedQuestion.theme
                        : currentTheme.childTheme
                    "
                  >
                  </jhi-yes-no-preview>
                </mat-card>
              </div>
              <div
                *ngIf="
                  selectedQuestion.type.typeDefinition?.identifier === 'Rating'
                "
                style="display: flex; justify-content: center; width: 100%"
              >
                <mat-card
                  class="question-card"
                  [ngClass]="{ 'mat-elevation-z': true }"
                  [style.backgroundColor]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.bgcolor
                      : selectedQuestion.theme.bgcolor
                  "
                  [style.fontFamily]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontFamily
                      : selectedQuestion.theme.fontFamily
                  "
                  [style.color]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontStyle
                      : selectedQuestion.theme.fontStyle
                  "
                  [style.opacity]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.opacity
                      : selectedQuestion.theme.opacity
                  "
                  [style.fontWeight]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontWeight
                      : selectedQuestion.theme.fontWeight
                  "
                  [style.fontSize]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontSize
                      : selectedQuestion.theme.fontSize
                  "
                  [ngStyle]="{
                    'box-shadow':
                      selectedQuestion.theme === null
                        ? currentTheme.childTheme.shadow
                        : selectedQuestion.theme.shadow
                  }"
                >
                  <jhi-rating-preview
                    [disabled]="disabled"
                    *ngIf="
                      !responses[currIndex].sections[currentSectionIndex]
                        .questions[currentQuestionIndex].logicallyHidden
                    "
                    [currPage]="currPage"
                    [question]="selectedQuestion"
                    [responsePage]="responses[currIndex]"
                    [questionIndex]="currentQuestionIndex"
                    [section]="currentSectionIndex"
                    [preference]="
                      selectedQuestion.preference !== null
                        ? selectedQuestion.preference
                        : currentPreference
                    "
                    [theme]="
                      selectedQuestion.theme !== null
                        ? selectedQuestion.theme
                        : currentTheme.childTheme
                    "
                  >
                  </jhi-rating-preview>
                </mat-card>
              </div>
              <div
                *ngIf="
                  selectedQuestion.type.typeDefinition?.identifier ===
                  'Checkbox'
                "
                style="display: flex; justify-content: center; width: 100%"
              >
                <mat-card
                  class="question-card"
                  [ngClass]="{ 'mat-elevation-z': true }"
                  [style.backgroundColor]="
                    selectedQuestion.theme === null
                      ? currentTheme.bgcolor
                      : selectedQuestion.theme.bgcolor
                  "
                  [style.fontFamily]="
                    selectedQuestion.theme === null
                      ? currentTheme.fontFamily
                      : selectedQuestion.theme.fontFamily
                  "
                  [style.color]="
                    selectedQuestion.theme === null
                      ? currentTheme.fontStyle
                      : selectedQuestion.theme.fontStyle
                  "
                  [style.opacity]="
                    selectedQuestion.theme === null
                      ? currentTheme.opacity
                      : selectedQuestion.theme.opacity
                  "
                  [style.fontWeight]="
                    selectedQuestion.theme === null
                      ? currentTheme.fontWeight
                      : selectedQuestion.theme.fontWeight
                  "
                  [style.fontSize]="
                    selectedQuestion.theme === null
                      ? currentTheme.fontSize
                      : selectedQuestion.theme.fontSize
                  "
                  [ngStyle]="{
                    'box-shadow':
                      selectedQuestion.theme === null
                        ? currentTheme.childTheme.shadow
                        : selectedQuestion.theme.shadow
                  }"
                >
                  <jhi-checkbox-preview
                    [disabled]="disabled"
                    *ngIf="
                      !responses[currIndex].sections[currentSectionIndex]
                        .questions[currentQuestionIndex].logicallyHidden
                    "
                    [currPage]="currPage"
                    [question]="selectedQuestion"
                    [responsePage]="responses[currIndex]"
                    [questionIndex]="currentQuestionIndex"
                    [section]="currentSectionIndex"
                    [preference]="
                      selectedQuestion.preference !== null
                        ? selectedQuestion.preference
                        : currentPreference
                    "
                    [theme]="
                      selectedQuestion.theme !== null
                        ? selectedQuestion.theme
                        : currentTheme.childTheme
                    "
                  >
                  </jhi-checkbox-preview>
                </mat-card>
              </div>
              <div
                *ngIf="
                  selectedQuestion.type.typeDefinition?.identifier === 'Radio'
                "
                style="display: flex; justify-content: center; width: 100%"
              >
                <mat-card
                  class="question-card"
                  [ngClass]="{ 'mat-elevation-z': true }"
                  [style.backgroundColor]="
                    selectedQuestion.theme === null
                      ? currentTheme.bgcolor
                      : selectedQuestion.theme.bgcolor
                  "
                  [style.fontFamily]="
                    selectedQuestion.theme === null
                      ? currentTheme.fontFamily
                      : selectedQuestion.theme.fontFamily
                  "
                  [style.color]="
                    selectedQuestion.theme === null
                      ? currentTheme.fontStyle
                      : selectedQuestion.theme.fontStyle
                  "
                  [style.opacity]="
                    selectedQuestion.theme === null
                      ? currentTheme.opacity
                      : selectedQuestion.theme.opacity
                  "
                  [style.fontWeight]="
                    selectedQuestion.theme === null
                      ? currentTheme.fontWeight
                      : selectedQuestion.theme.fontWeight
                  "
                  [style.fontSize]="
                    selectedQuestion.theme === null
                      ? currentTheme.fontSize
                      : selectedQuestion.theme.fontSize
                  "
                  [ngStyle]="{
                    'box-shadow':
                      selectedQuestion.theme === null
                        ? currentTheme.childTheme.shadow
                        : selectedQuestion.theme.shadow
                  }"
                >
                  <jhi-radio-buttons-preview
                    [disabled]="disabled"
                    *ngIf="
                      !responses[currIndex].sections[currentSectionIndex]
                        .questions[currentQuestionIndex].logicallyHidden
                    "
                    [currPage]="currPage"
                    [question]="selectedQuestion"
                    [responsePage]="responses[currIndex]"
                    [questionIndex]="currentQuestionIndex"
                    [section]="currentSectionIndex"
                    [preference]="
                      selectedQuestion.preference !== null
                        ? selectedQuestion.preference
                        : currentPreference
                    "
                    [theme]="
                      selectedQuestion.theme !== null
                        ? selectedQuestion.theme
                        : currentTheme.childTheme
                    "
                  >
                  </jhi-radio-buttons-preview>
                </mat-card>
              </div>
              <div
                *ngIf="
                  selectedQuestion.type.typeDefinition?.identifier === 'Slider'
                "
                style="display: flex; justify-content: center; width: 100%"
              >
                <mat-card
                  class="question-card"
                  [ngClass]="{ 'mat-elevation-z': true }"
                  [style.backgroundColor]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.bgcolor
                      : selectedQuestion.theme.bgcolor
                  "
                  [style.fontFamily]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontFamily
                      : selectedQuestion.theme.fontFamily
                  "
                  [style.color]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontStyle
                      : selectedQuestion.theme.fontStyle
                  "
                  [style.opacity]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.opacity
                      : selectedQuestion.theme.opacity
                  "
                  [style.fontWeight]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontWeight
                      : selectedQuestion.theme.fontWeight
                  "
                  [style.fontSize]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontSize
                      : selectedQuestion.theme.fontSize
                  "
                  [ngStyle]="{
                    'box-shadow':
                      selectedQuestion.theme === null
                        ? currentTheme.childTheme.shadow
                        : selectedQuestion.theme.shadow
                  }"
                >
                  <jhi-slider-preview
                    [disabled]="disabled"
                    *ngIf="
                      !responses[currIndex].sections[currentSectionIndex]
                        .questions[currentQuestionIndex].logicallyHidden
                    "
                    [currPage]="currPage"
                    [question]="selectedQuestion"
                    [responsePage]="responses[currIndex]"
                    [questionIndex]="currentQuestionIndex"
                    [section]="currentSectionIndex"
                    [preference]="
                      selectedQuestion.preference !== null
                        ? selectedQuestion.preference
                        : currentPreference
                    "
                    [theme]="
                      selectedQuestion.theme !== null
                        ? selectedQuestion.theme
                        : currentTheme.childTheme
                    "
                  >
                  </jhi-slider-preview>
                </mat-card>
              </div>
              <div
                *ngIf="
                  selectedQuestion.type.typeDefinition?.identifier ===
                  'Number_rating'
                "
                style="display: flex; justify-content: center; width: 100%"
              >
                <mat-card
                  class="question-card"
                  [ngClass]="{ 'mat-elevation-z': true }"
                  [style.backgroundColor]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.bgcolor
                      : selectedQuestion.theme.bgcolor
                  "
                  [style.fontFamily]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontFamily
                      : selectedQuestion.theme.fontFamily
                  "
                  [style.color]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontStyle
                      : selectedQuestion.theme.fontStyle
                  "
                  [style.opacity]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.opacity
                      : selectedQuestion.theme.opacity
                  "
                  [style.fontWeight]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontWeight
                      : selectedQuestion.theme.fontWeight
                  "
                  [style.fontSize]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontSize
                      : selectedQuestion.theme.fontSize
                  "
                  [ngStyle]="{
                    'box-shadow':
                      selectedQuestion.theme === null
                        ? currentTheme.childTheme.shadow
                        : selectedQuestion.theme.shadow
                  }"
                >
                  <jhi-number-rating-preview
                    [disabled]="disabled"
                    *ngIf="
                      !responses[currIndex].sections[currentSectionIndex]
                        .questions[currentQuestionIndex].logicallyHidden
                    "
                    [currPage]="currPage"
                    [question]="selectedQuestion"
                    [responsePage]="responses[currIndex]"
                    [questionIndex]="currentQuestionIndex"
                    [section]="currentSectionIndex"
                    [preference]="
                      selectedQuestion.preference !== null
                        ? selectedQuestion.preference
                        : currentPreference
                    "
                    [theme]="
                      selectedQuestion.theme !== null
                        ? selectedQuestion.theme
                        : currentTheme.childTheme
                    "
                  >
                  </jhi-number-rating-preview>
                </mat-card>
              </div>
              <div
                *ngIf="
                  selectedQuestion.type.typeDefinition?.identifier ===
                  'HTML_block'
                "
                style="display: flex; justify-content: center; width: 100%"
              >
                <mat-card
                  class="question-card"
                  [ngClass]="{ 'mat-elevation-z': true }"
                  [style.backgroundColor]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.bgcolor
                      : selectedQuestion.theme.bgcolor
                  "
                  [style.fontFamily]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontFamily
                      : selectedQuestion.theme.fontFamily
                  "
                  [style.color]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontStyle
                      : selectedQuestion.theme.fontStyle
                  "
                  [style.opacity]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.opacity
                      : selectedQuestion.theme.opacity
                  "
                  [style.fontWeight]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontWeight
                      : selectedQuestion.theme.fontWeight
                  "
                  [style.fontSize]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontSize
                      : selectedQuestion.theme.fontSize
                  "
                  [ngStyle]="{
                    'box-shadow':
                      selectedQuestion.theme === null
                        ? currentTheme.childTheme.shadow
                        : selectedQuestion.theme.shadow
                  }"
                >
                  <jhi-html-block-preview
                    [disabled]="disabled"
                    *ngIf="
                      !responses[currIndex].sections[currentSectionIndex]
                        .questions[currentQuestionIndex].logicallyHidden
                    "
                    [currPage]="currPage"
                    [question]="selectedQuestion"
                    [responsePage]="responses[currIndex]"
                    [questionIndex]="currentQuestionIndex"
                    [section]="currentSectionIndex"
                    [preference]="
                      selectedQuestion.preference !== null
                        ? selectedQuestion.preference
                        : currentPreference
                    "
                    [theme]="
                      selectedQuestion.theme !== null
                        ? selectedQuestion.theme
                        : currentTheme.childTheme
                    "
                  >
                  </jhi-html-block-preview>
                </mat-card>
              </div>
              <div
                *ngIf="
                  selectedQuestion.type.typeDefinition?.identifier ===
                  'File_upload'
                "
                style="display: flex; justify-content: center; width: 100%"
              >
                <mat-card
                  class="question-card"
                  [ngClass]="{ 'mat-elevation-z': true }"
                  [style.backgroundColor]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.bgcolor
                      : selectedQuestion.theme.bgcolor
                  "
                  [style.fontFamily]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontFamily
                      : selectedQuestion.theme.fontFamily
                  "
                  [style.color]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontStyle
                      : selectedQuestion.theme.fontStyle
                  "
                  [style.opacity]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.opacity
                      : selectedQuestion.theme.opacity
                  "
                  [style.fontWeight]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontWeight
                      : selectedQuestion.theme.fontWeight
                  "
                  [style.fontSize]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontSize
                      : selectedQuestion.theme.fontSize
                  "
                  [ngStyle]="{
                    'box-shadow':
                      selectedQuestion.theme === null
                        ? currentTheme.childTheme.shadow
                        : selectedQuestion.theme.shadow
                  }"
                  *ngIf="
                    !responses[currIndex].sections[currentSectionIndex]
                      .questions[currentQuestionIndex].logicallyHidden
                  "
                >
                  <jhi-file-upload-preview
                    [disabled]="disabled"
                    *ngIf="
                      !responses[currIndex].sections[currentSectionIndex]
                        .questions[currentQuestionIndex].logicallyHidden
                    "
                    [currPage]="currPage"
                    [question]="selectedQuestion"
                    [responsePage]="responses[currIndex]"
                    [questionIndex]="currentQuestionIndex"
                    [section]="currentSectionIndex"
                    [preference]="
                      selectedQuestion.preference !== null
                        ? selectedQuestion.preference
                        : currentPreference
                    "
                    [theme]="
                      selectedQuestion.theme !== null
                        ? selectedQuestion.theme
                        : currentTheme.childTheme
                    "
                  >
                  </jhi-file-upload-preview>
                </mat-card>
              </div>
              <div
                *ngIf="
                  selectedQuestion.type.typeDefinition?.identifier ===
                  'Photo_upload'
                "
                style="display: flex; justify-content: center; width: 100%"
              >
                <mat-card
                  class="question-card"
                  [ngClass]="{ 'mat-elevation-z': true }"
                  [style.backgroundColor]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.bgcolor
                      : selectedQuestion.theme.bgcolor
                  "
                  [style.fontFamily]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontFamily
                      : selectedQuestion.theme.fontFamily
                  "
                  [style.color]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontStyle
                      : selectedQuestion.theme.fontStyle
                  "
                  [style.opacity]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.opacity
                      : selectedQuestion.theme.opacity
                  "
                  [style.fontWeight]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontWeight
                      : selectedQuestion.theme.fontWeight
                  "
                  [style.fontSize]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontSize
                      : selectedQuestion.theme.fontSize
                  "
                  [ngStyle]="{
                    'box-shadow':
                      selectedQuestion.theme === null
                        ? currentTheme.childTheme.shadow
                        : selectedQuestion.theme.shadow
                  }"
                  *ngIf="
                    !responses[currIndex].sections[currentSectionIndex]
                      .questions[currentQuestionIndex].logicallyHidden
                  "
                >
                  <jhi-photo-upload-preview
                    [disabled]="disabled"
                    *ngIf="
                      !responses[currIndex].sections[currentSectionIndex]
                        .questions[currentQuestionIndex].logicallyHidden
                    "
                    [currPage]="currPage"
                    [question]="selectedQuestion"
                    [responsePage]="responses[currIndex]"
                    [questionIndex]="currentQuestionIndex"
                    [section]="currentSectionIndex"
                    [preference]="
                      selectedQuestion.preference !== null
                        ? selectedQuestion.preference
                        : currentPreference
                    "
                    [theme]="
                      selectedQuestion.theme !== null
                        ? selectedQuestion.theme
                        : currentTheme.childTheme
                    "
                  >
                  </jhi-photo-upload-preview>
                </mat-card>
              </div>
              <div
                *ngIf="
                  selectedQuestion.type.typeDefinition?.identifier ===
                  'Contact_info'
                "
                style="display: flex; justify-content: center; width: 100%"
              >
                <mat-card
                  class="question-card"
                  [ngClass]="{ 'mat-elevation-z': true }"
                  [style.backgroundColor]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.bgcolor
                      : selectedQuestion.theme.bgcolor
                  "
                  [style.fontFamily]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontFamily
                      : selectedQuestion.theme.fontFamily
                  "
                  [style.color]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontStyle
                      : selectedQuestion.theme.fontStyle
                  "
                  [style.opacity]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.opacity
                      : selectedQuestion.theme.opacity
                  "
                  [style.fontWeight]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontWeight
                      : selectedQuestion.theme.fontWeight
                  "
                  [style.fontSize]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontSize
                      : selectedQuestion.theme.fontSize
                  "
                  [ngStyle]="{
                    'box-shadow':
                      selectedQuestion.theme === null
                        ? currentTheme.childTheme.shadow
                        : selectedQuestion.theme.shadow
                  }"
                  *ngIf="
                    !responses[currIndex].sections[currentSectionIndex]
                      .questions[currentQuestionIndex].logicallyHidden
                  "
                >
                  <jhi-contact-info-preview
                    [disabled]="disabled"
                    *ngIf="
                      !responses[currIndex].sections[currentSectionIndex]
                        .questions[currentQuestionIndex].logicallyHidden
                    "
                    [currPage]="currPage"
                    [question]="selectedQuestion"
                    [responsePage]="responses[currIndex]"
                    [questionIndex]="currentQuestionIndex"
                    [section]="currentSectionIndex"
                    [preference]="
                      selectedQuestion.preference !== null
                        ? selectedQuestion.preference
                        : currentPreference
                    "
                    [theme]="
                      selectedQuestion.theme !== null
                        ? selectedQuestion.theme
                        : currentTheme.childTheme
                    "
                  >
                  </jhi-contact-info-preview>
                </mat-card>
              </div>
              <div
                *ngIf="
                  selectedQuestion.type.typeDefinition?.identifier ===
                  'Multi_Question'
                "
                style="display: flex; justify-content: center; width: 100%"
              >
                <mat-card
                  class="question-card"
                  [ngClass]="{ 'mat-elevation-z': true }"
                  [style.backgroundColor]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.bgcolor
                      : selectedQuestion.theme.bgcolor
                  "
                  [style.fontFamily]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontFamily
                      : selectedQuestion.theme.fontFamily
                  "
                  [style.color]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontStyle
                      : selectedQuestion.theme.fontStyle
                  "
                  [style.opacity]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.opacity
                      : selectedQuestion.theme.opacity
                  "
                  [style.fontWeight]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontWeight
                      : selectedQuestion.theme.fontWeight
                  "
                  [style.fontSize]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontSize
                      : selectedQuestion.theme.fontSize
                  "
                  [ngStyle]="{
                    'box-shadow':
                      selectedQuestion.theme === null
                        ? currentTheme.childTheme.shadow
                        : selectedQuestion.theme.shadow
                  }"
                  *ngIf="
                    !responses[currIndex].sections[currentSectionIndex]
                      .questions[currentQuestionIndex].logicallyHidden
                  "
                >
                  <jhi-multi-question-preview
                    [disabled]="disabled"
                    *ngIf="
                      !responses[currIndex].sections[currentSectionIndex]
                        .questions[currentQuestionIndex].logicallyHidden
                    "
                    [currPage]="currPage"
                    [question]="selectedQuestion"
                    [responsePage]="responses[currIndex]"
                    [questionIndex]="currentQuestionIndex"
                    [section]="currentSectionIndex"
                    [preference]="
                      selectedQuestion.preference !== null
                        ? selectedQuestion.preference
                        : currentPreference
                    "
                    [theme]="
                      selectedQuestion.theme !== null
                        ? selectedQuestion.theme
                        : currentTheme.childTheme
                    "
                    [surveyDefinition]="surveyDefinition"
                    [childQuestions]="question.childQuestions"
                  >
                  </jhi-multi-question-preview>
                </mat-card>
              </div>
              <div
                *ngIf="
                  selectedQuestion.type.typeDefinition?.identifier ===
                  'Multi_textbox'
                "
                style="display: flex; justify-content: center; width: 100%"
              >
                <mat-card
                  class="question-card"
                  [ngClass]="{ 'mat-elevation-z': true }"
                  [style.backgroundColor]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.bgcolor
                      : selectedQuestion.theme.bgcolor
                  "
                  [style.fontFamily]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontFamily
                      : selectedQuestion.theme.fontFamily
                  "
                  [style.color]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontStyle
                      : selectedQuestion.theme.fontStyle
                  "
                  [style.opacity]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.opacity
                      : selectedQuestion.theme.opacity
                  "
                  [style.fontWeight]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontWeight
                      : selectedQuestion.theme.fontWeight
                  "
                  [style.fontSize]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontSize
                      : selectedQuestion.theme.fontSize
                  "
                  [ngStyle]="{
                    'box-shadow':
                      selectedQuestion.theme === null
                        ? currentTheme.childTheme.shadow
                        : selectedQuestion.theme.shadow
                  }"
                  *ngIf="
                    !responses[currIndex].sections[currentSectionIndex]
                      .questions[currentQuestionIndex].logicallyHidden
                  "
                >
                  <jhi-multi-textbox-preview
                    [disabled]="disabled"
                    *ngIf="
                      !responses[currIndex].sections[currentSectionIndex]
                        .questions[currentQuestionIndex].logicallyHidden
                    "
                    [currPage]="currPage"
                    [question]="selectedQuestion"
                    [responsePage]="responses[currIndex]"
                    [questionIndex]="currentQuestionIndex"
                    [section]="currentSectionIndex"
                    [preference]="
                      selectedQuestion.preference !== null
                        ? selectedQuestion.preference
                        : currentPreference
                    "
                    [theme]="
                      selectedQuestion.theme !== null
                        ? selectedQuestion.theme
                        : currentTheme.childTheme
                    "
                  >
                  </jhi-multi-textbox-preview>
                </mat-card>
              </div>
              <div
                *ngIf="
                  selectedQuestion.type.typeDefinition?.identifier ===
                  'Map_Input'
                "
                style="display: flex; justify-content: center; width: 100%"
              >
                <mat-card
                  class="question-card"
                  [ngClass]="{ 'mat-elevation-z': true }"
                  [style.backgroundColor]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.bgcolor
                      : selectedQuestion.theme.bgcolor
                  "
                  [style.fontFamily]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontFamily
                      : selectedQuestion.theme.fontFamily
                  "
                  [style.color]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontStyle
                      : selectedQuestion.theme.fontStyle
                  "
                  [style.opacity]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.opacity
                      : selectedQuestion.theme.opacity
                  "
                  [style.fontWeight]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontWeight
                      : selectedQuestion.theme.fontWeight
                  "
                  [style.fontSize]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontSize
                      : selectedQuestion.theme.fontSize
                  "
                  [ngStyle]="{
                    'box-shadow':
                      selectedQuestion.theme === null
                        ? currentTheme.childTheme.shadow
                        : selectedQuestion.theme.shadow
                  }"
                  *ngIf="
                    !responses[currIndex].sections[currentSectionIndex]
                      .questions[currentQuestionIndex].logicallyHidden
                  "
                >
                  <jhi-map-input-preview
                    [disabled]="disabled"
                    *ngIf="
                      !responses[currIndex].sections[currentSectionIndex]
                        .questions[currentQuestionIndex].logicallyHidden
                    "
                    [currPage]="currPage"
                    [question]="selectedQuestion"
                    [responsePage]="responses[currIndex]"
                    [questionIndex]="currentQuestionIndex"
                    [section]="currentSectionIndex"
                    [preference]="
                      selectedQuestion.preference !== null
                        ? selectedQuestion.preference
                        : currentPreference
                    "
                    [theme]="
                      selectedQuestion.theme !== null
                        ? selectedQuestion.theme
                        : currentTheme.childTheme
                    "
                  >
                  </jhi-map-input-preview>
                </mat-card>
              </div>
              <div
                *ngIf="
                  selectedQuestion.type.typeDefinition?.identifier ===
                  'Video_Upload'
                "
                style="display: flex; justify-content: center; width: 100%"
              >
                <mat-card
                  class="question-card"
                  [ngClass]="{ 'mat-elevation-z': true }"
                  [style.backgroundColor]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.bgcolor
                      : selectedQuestion.theme.bgcolor
                  "
                  [style.fontFamily]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontFamily
                      : selectedQuestion.theme.fontFamily
                  "
                  [style.color]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontStyle
                      : selectedQuestion.theme.fontStyle
                  "
                  [style.opacity]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.opacity
                      : selectedQuestion.theme.opacity
                  "
                  [style.fontWeight]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontWeight
                      : selectedQuestion.theme.fontWeight
                  "
                  [style.fontSize]="
                    selectedQuestion.theme === null
                      ? currentTheme.childTheme.fontSize
                      : selectedQuestion.theme.fontSize
                  "
                  [ngStyle]="{
                    'box-shadow':
                      selectedQuestion.theme === null
                        ? currentTheme.childTheme.shadow
                        : selectedQuestion.theme.shadow
                  }"
                  *ngIf="
                    !responses[currIndex].sections[currentSectionIndex]
                      .questions[currentQuestionIndex].logicallyHidden
                  "
                >
                  <jhi-video-upload-preview
                    [disabled]="disabled"
                    *ngIf="
                      !responses[currIndex].sections[currentSectionIndex]
                        .questions[currentQuestionIndex].logicallyHidden
                    "
                    [currPage]="currPage"
                    [question]="selectedQuestion"
                    [responsePage]="responses[currIndex]"
                    [questionIndex]="currentQuestionIndex"
                    [section]="currentSectionIndex"
                    [preference]="
                      selectedQuestion.preference !== null
                        ? selectedQuestion.preference
                        : currentPreference
                    "
                    [theme]="
                      selectedQuestion.theme !== null
                        ? selectedQuestion.theme
                        : currentTheme.childTheme
                    "
                    [surveyDefinition]="surveyDefinition"
                  >
                  </jhi-video-upload-preview>
                </mat-card>
              </div>
            </div>

            <div *ngIf="!isPageValid" style="color: red">
              <p style="font-weight: bold">Errors:</p>
              <ul>
                <li *ngFor="let pageError of PageErrors">
                  {{ pageError.question.text }} - {{ pageError.error }}
                </li>
              </ul>
            </div>
            <div style="height: 20px"></div>
          </mat-card-content>
          <div
            class="page-btns"
            mat-dialog-actions
            *ngIf="selectedQuestion !== null"
          >
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: center;
              "
            >
              <div class="next-btn" *ngIf="displayPrevious">
                <button
                  mat-button
                  mat-raised-button
                  class="DSA_secondary-btn"
                  style="background-color: white"
                  (click)="moveToPreviousQuestion()"
                >
                  Previous question
                </button>
              </div>
              <div style="width: 5px"></div>
              <div class="next-btn" *ngIf="displayNext">
                <button
                  mat-button
                  mat-raised-button
                  class="DSA_secondary-btn"
                  style="background-color: white"
                  (click)="moveToNextQuestion()"
                >
                  Next question
                </button>
              </div>
              <div class="submit-btn" *ngIf="displaySubmit && !disabled">
                <button
                  mat-button
                  class="DSA_primary-btn"
                  (click)="submitQuestion()"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>

          <div style="height: 20px"></div>
        </ng-template>
      </mat-card>
    </div>
    <div
      *ngIf="errorMessage !== null && errorMessage !== ''"
      style="width: 100%; margin-top: 10px"
    >
      <mat-card class="error-section-card">
        <div [ngSwitch]="errorMessage" style="width: 100%; height: 100%">
          <div *ngSwitchCase="'Max_Access'" class="container max-exceeded">
            <div class="row" style="padding-bottom: 20px">
              <div class="col-12 text-center">
                <span class="error-heading"
                  >Uh Oh! The Link is already used maximum number of
                  times.</span
                >
              </div>
            </div>
            <div class="row">
              <span class="col-12"
                >Please contact the author of this survey for further
                assistance. Thank you for visiting!</span
              >
            </div>
            <div class="row">
              <div class="poweredby-logo">
                <span>Powered by</span>
                <img
                  style="max-width: 100%"
                  src="/assets/rsz_survey_master_logo.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div *ngSwitchCase="'Expired'" class="container expired">
            <div class="row" style="padding-bottom: 20px">
              <div class="col-12 text-center">
                <span class="error-heading"
                  >Uh Oh! The Link is already Closed.</span
                >
              </div>
            </div>
            <div class="row">
              <span class="col-12"
                >Please contact the author of this survey for further
                assistance. Thank you for visiting!</span
              >
            </div>
            <div class="row">
              <div class="poweredby-logo">
                <span>Powered by</span>
                <img
                  style="max-width: 100%"
                  src="/assets/rsz_survey_master_logo.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div *ngSwitchCase="'Not_Started'" class="container not-started">
            <div class="row" style="padding-bottom: 20px">
              <div class="col-12 text-center">
                <span class="error-heading">Hey! You are early!.</span>
              </div>
            </div>
            <div class="row">
              <span class="col-12"
                >The survey has not started yet. Please come back once the
                survey is started!</span
              >
            </div>
            <div class="row">
              <div class="poweredby-logo">
                <span>Powered by</span>
                <img
                  style="max-width: 100%"
                  src="/assets/rsz_survey_master_logo.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div *ngSwitchCase="'Other'" class="other-error container-fluid">
            <div class="row" style="padding-bottom: 20px">
              <div class="col-12 text-center">
                <span class="error-heading">Uh Oh! Something went wrong!</span>
              </div>
            </div>
            <div class="row">
              <span class="col-12"
                >We could not find the survey. Please check the link or contact
                the survey author.Thank you for visiting!</span
              >
            </div>
            <div class="row">
              <div class="poweredby-logo">
                <span>Powered by</span>
                <img
                  style="max-width: 100%"
                  src="/assets/rsz_survey_master_logo.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <div *ngIf="thankYou !== null && thankYou !== ''" style="width: 100%">
      <div *ngIf="surveyDefinition.surveyType !== 'POLL'">
        <mat-card
          class="thankyou-section-card"
          *ngIf="currentTemplate.templateString === ''; else templateValue"
        >
          <div class="container-fluid thank-you-message">
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-4 col-height pl-0">
                <img
                  style="position: absolute; left: -50px; bottom: 0px"
                  class="h-50 w-100"
                  src="/assets/bg_2.svg"
                />
                <img class="h-100 w-100" src="/assets/frontImage.svg" />
              </div>
              <div class="col-md-4"></div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12 text-center thank-you-text">
                <span>Thank you for taking the survey!</span>
              </div>
            </div>
            <div class="row">
              <div class="poweredby-logo">
                <span>Powered by</span>
                <img
                  style="max-width: 100%"
                  src="/assets/rsz_survey_master_logo.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </mat-card>
        <ng-template #templateValue>
          <mat-card class="thankyou-section-card">
            <iframe
              sandbox="allow-same-origin allow-forms allow-scripts"
              [srcdoc]="getHTMLValue()"
              seamless="seamless"
              seamless
              width="100%"
              height="100%"
            ></iframe>
            <!-- <div  [innerHTML]="getHTMLValue()"></div> -->
          </mat-card>
        </ng-template>
      </div>

      <div *ngIf="surveyDefinition.surveyType === 'POLL'" style="height: 100%">
        <mat-card
          class="thankyou-section-card-poll"
          [style.fontFamily]="currentTheme.fontFamily"
          [style.background]="
            currentTheme.background
              ? 'url(' + downloadImage(currentTheme.background.path) + ')'
              : ''
          "
          [style.backgroundColor]="currentTheme.bgcolor"
          [style.backgroundRepeat]="currentTheme.background ? 'no-repeat' : ''"
          [style.backgroundSize]="currentTheme.background ? 'cover' : ''"
          [style.color]="currentTheme.fontStyle"
          [style.opacity]="currentTheme.opacity"
          [style.fontWeight]="currentTheme.fontWeight"
          [style.fontSize]="currentTheme.fontSize"
          style="margin: 0px; width: inherit; height: 100%; min-height: inherit"
        >
          <div style="height: 10px"></div>

          <div
            *ngIf="surveyDefinition.preference.showTitle === 1"
            style="
              display: flex;
              font-weight: 600;
              justify-content: center;
              font-size: 24px;
            "
          >
            <span>{{ surveyDefinition.title }}</span>
            <!-- <mat-card-title></mat-card-title> -->
          </div>
          <div style="height: 20px"></div>

          <div
            *ngFor="let answerquestion of answerquestions; let pri = index"
            style="width: 70%; margin: 5px"
          >
            <div style="text-align: center">
              <b>{{ pri + 1 }}. {{ answerquestion.question.text }}</b>
            </div>
            <br />
            <div style="text-align: center">
              <b>Your Resposne: </b>
              {{ responseMap.get(answerquestion.question.id)?.answer.join() }}
            </div>
            <br />
            <div
              style="display: block"
              [style.width]="isMobile() ? '90%' : '60%'"
              [style.marginLeft]="isMobile() ? '0px' : '15%'"
            >
              <canvas
                baseChart
                [datasets]="answerquestion.data"
                [labels]="answerquestion.labels"
                [options]="{
                  scaleShowVerticalLines: false,
                  responsive: true,
                  scales: {
                    xAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          steps: 10,
                          stepValue: 10,
                          max: 100,
                          min: 0
                        }
                      }
                    ]
                  }
                }"
                [legend]="true"
                [chartType]="'horizontalBar'"
                [colors]="[{ backgroundColor: 'gray' }]"
              >
              </canvas>
            </div>
            <!-- <jhi-bar-chart [ChartData]="answerquestion.data" [ChartLabels]="answerquestion.labels"
      style="width:inherit;"
        [ChartOptions]="{ scaleShowVerticalLines: false,
                  responsive: true,
                  scales: {
                        xAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              steps : 10,
                              stepValue : 10,
                              max : 100,
                              min: 0
                            }
                          }
                        ]
                      } }"
        [ChartLegend]="true" [ChartType]="'horizontalBar'">
      </jhi-bar-chart> -->
            <!-- <div *ngFor="let pollResult of pollResults;let pri = index">
        <div>
          {{pri+1}}. {{questionsMap.get(pollResult.questionId).text}}
        </div>
        <div *ngFor="let polloption of pollResult.polls;let poi = index">
          {{polloption.option}} = {{polloption.percent}}
        </div>
      </div> -->
          </div>
        </mat-card>
      </div>
    </div>
  </div>

  <app-error-message
    [display]="!loading && errorFetchingEvalForm"
    [errorText]="
      isSurveyNotFound
        ? 'Evaluation form not found'
        : 'Uh-oh! Request timed out. Please try again after sometime.'
    "
  >
  </app-error-message>
</div>
