import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactDetails } from 'src/app/shared/model/Profile/contactDetails-model';
import { SharedService } from 'src/app/services/shared.service';
import { CandidateInfoService } from '../../../candidate/services/candidate-info.service';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
})
export class ContactDetailsComponent implements OnInit {
  // candidateInfo: ICandidateInfo;
  spinner: boolean = false;
  message: string;
  // @Input() extractedResume: IExtractedResume;
  @Input() contactDetails: ContactDetails;
  @Output() newItemEvent = new EventEmitter<ContactDetails>();

  updateExtractedResume() {
    this.candidateInfoService
      .UpdateContactDeatailsOfProfile(this.contactDetails)
      .subscribe({
        next: (res: any) => {
          this.sharedService.openSuccessSnackBar(
            'data updated successfully',
            ''
          );
        },
        error: (err: any) => {
          this.sharedService.openErrorSnackBar(
            'data update failed. Please try again',
            ''
          );
        },
      });
    this.newItemEvent.emit(this.contactDetails);
  }
  constructor(
    protected candidateInfoService: CandidateInfoService,
    private snackBar: MatSnackBar,
    public sharedService: SharedService
  ) {}

  ngOnInit(): void {}
  save() {
    this.spinner = true;
    console.log(this.contactDetails);
    this.candidateInfoService
      .UpdateContactDeatailsOfProfile(this.contactDetails)
      .subscribe(
        (response) => {
          console.log('value updated');
          this.message = 'value updated';
          this.spinner = false;
        },
        (err: HttpErrorResponse) => {
          // const dialogRef = this.dialog.open(DialogboxComponent, {
          //   data: { message: "Some Error Occured While Saving", error: "true" },
          // });
          console.log('updation failed');
          this.message = 'updation failed';
          this.spinner = false;
        }
      );
    this.snackBar.open(this.message, 'Dismiss');
  }
}
