import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';
import { MailTemplateService } from './mail-template.service';
import {
  NotificationTemplate,
  TemplateAttachment,
  TemplateType,
} from './mail-template.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationComponent } from '../shared/components/confirmation/confirmation.component';
import { MailTemplateStateStorageService } from './mail-template-state-storage.service';
import { MailTemplateListService } from './mail-template-list.service';
import { AccountService } from '../services/account.service';
import { FeatureName } from '../shared/model/enumerations/feature-name.model';
import { PermissionType } from '../shared/model/enumerations/permission-type.model';
@Component({
  selector: 'jhi-create-action',
  templateUrl: './mail-template.component.html',
  styleUrls: ['./mail-template.component.scss'],
  providers: [MailTemplateService],
})
export class MailTemplateComponent implements OnInit {
  currTemplate: NotificationTemplate;
  templates: NotificationTemplate[];

  page = 0;
  size = 10;
  totalElements = 0;
  queryMailTemplate = '';
  loading = false;

  shouldCreateTemplate: boolean;
  templateType: string;
  metadataRowVals: { label; icon };
  templateDashboardBtn = {
    icon: 'menu',
    label: 'Create Template',
  };
  createTemplateBtn = {
    icon: 'add',
    label: 'Existing Templates',
  };

  FeatureName = FeatureName;
  PermissionType = PermissionType;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private templateService: MailTemplateService,
    private snackBar: MatSnackBar,
    private mailTemplateStateStorageService: MailTemplateStateStorageService,
    private mailTemplateListService: MailTemplateListService,
    public accountService: AccountService
  ) {}
  openDialog = (data: any): void => {
    this.snackBar.open(
      'Title: ' +
        data.title +
        ' Message: ' +
        data.message +
        ' Type: ' +
        data.type,
      '',
      { duration: 3000, panelClass: ['purple-snackbar'] }
    );
  };

  ngOnInit() {
    this.shouldCreateTemplate = false;
    this.mailTemplateListService.init();
    if (
      this.mailTemplateStateStorageService.page &&
      this.mailTemplateStateStorageService.size
    ) {
      this.page = this.mailTemplateStateStorageService.page;
      this.size = this.mailTemplateStateStorageService.size;
    }
    this.metadataRowVals = this.createTemplateBtn;
    this.getAllTemplates();
  }
  pageChange = (e) => {
    this.size = e.pageSize;
    this.page = e.pageIndex;
    this.getAllTemplates();
  };

  getAllTemplates() {
    this.loading = true;
    this.templateService.getAllMailTemplates(this.page, this.size).subscribe(
      (data: any) => {
        this.templates = data.content;
        this.totalElements = data.totalElements;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  // search = (e) => {
  //   this.queryMailTemplate = e.target.value;
  //   if (e.keyCode === 13) {
  //     this.page = 0;
  //     // this.recruiterDashboardStateStorageService.queryEventName =
  //     //   this.queryEventName;
  //     // this.recruiterDashboardStateStorageService.page = this.page;
  //     // this.recruiterDashboardStateStorageService.size = this.size;
  //     // this.refreshTable();
  //   }
  // };

  enableTemplateCreation(templateType: string, template) {
    if (templateType != null) {
      this.templateType = templateType;
    } else {
      if (template !== undefined) {
        this.templateType =
          template.templateType === TemplateType.HTML ? 'HTML' : 'SMS';
      }
    }
    this.metadataRowVals = this.templateDashboardBtn;
    this.currTemplate = template;
    this.templateService.setCurrentTemplate(this.currTemplate);
    this.shouldCreateTemplate = true;
  }

  returnToTemplateHome() {
    this.getAllTemplates();
    this.metadataRowVals = this.createTemplateBtn;
    this.shouldCreateTemplate = false;
  }

  toggleTemplateCreation(templateType: string, template) {
    if (this.shouldCreateTemplate === false) {
      this.templateType = templateType;
      this.metadataRowVals = this.templateDashboardBtn;
      if (template !== undefined) {
        this.currTemplate = template;
      }
    } else {
      this.getAllTemplates();
      this.metadataRowVals = this.createTemplateBtn;
    }
    this.shouldCreateTemplate = !this.shouldCreateTemplate;
  }

  deleteTemplate(template) {
    const condirmRef = this.dialog.open(ConfirmationComponent, {
      data: {
        text: 'Are you sure to delete this Template?',
      },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });

    condirmRef.afterClosed().subscribe((val) => {
      if (val === 'Yes') {
        this.templateService.deleteMailTemplateById(template.id).subscribe(
          (success) => {
            this.openDialog({
              type: 'success',
              message: 'Template deleted successfully',
              title: 'Success',
            });
            this.getAllTemplates();
          },
          (error) => {
            this.openDialog({
              type: 'error',
              message:
                'There is some problem while deleteing template. Please try after sometime',
              title: 'Error',
            });
          }
        );
      }
    });
  }
}
