import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CandidateProfileService } from '../candidate-profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SharedService } from 'src/app/services/shared.service';
import { CandidateInfoService } from '../../services/candidate-info.service';
import { allDocumentListByCategory } from 'src/app/app.constants';
import { take } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';
import { IAccountType } from 'src/app/shared/model/account.model';

@Component({
  selector: 'app-tablist-template',
  templateUrl: './tablist-template.component.html',
  styleUrls: ['./tablist-template.component.scss'],
})
export class TablistTemplateComponent implements OnInit,OnDestroy {
  @Output() applicantIdSent = new EventEmitter();
  @Input() documentList = null;
  @Input() maxFamilyAnnualIncome = null;
  @Input() opportunityId: number =null;
  locationSubscription: any;
  url: any;
  currentIndex = 0;
  activeTabIndex = 0;
  tabs = [];
  isLoadingAll: boolean = false;
  isCandidateInfoPresent: boolean = null;
  errorPage = false;
  candidateInfoId: number = null;
  allDocumentListByCategory = allDocumentListByCategory;
  documentListByCategory = {
    Personal: [],
    Educational: [],
    Scholarship: [],
    Contact: [],
  };
  userAccDetails: IAccountType;
  constructor(
    private candidateProfileService: CandidateProfileService,
    private route: Router,
    private location: Location,
    private router: ActivatedRoute,
    private sharedService:SharedService,
    private candidateInfoService:CandidateInfoService,
    private accountService: AccountService,
  ) {
    if (
      !this.candidateProfileService.applicationId ||
      !this.candidateProfileService.opportunityId
    ) {
      this.route.navigate(['/candidate/problem-statements']);
    }
    this.tabs.push({ title: `team-details` });
    this.tabs.push({ title: 'synopsis' });
  }

  ngOnInit(): void {
    this.isLoadingAll=true;
    this.opportunityId= this.router.snapshot.params['jobid'];
    this.locationSubscription = this.location.subscribe((event) => {
      this.url = event.url;
      this.url = this.url.split('activeTab=')[1];
      if (this.url != undefined) {
        this.url = this.url.replace(/_/g, ' ');
        this.currentIndex = this.tabs.findIndex(
          (element) => element.title === this.url
        );
        this.activeTabIndex = this.currentIndex;
      }
    });

    if (this.activeTabIndex === 0) {
      let currentTitle = this.tabs[0].title;
      this.addParam(this.router.url, currentTitle);
    }

    this.documentList?.forEach((doc) => {
      let curDoc = this.allDocumentListByCategory[doc];
      this.documentListByCategory?.[curDoc?.category]?.push(doc);
    });

    this.candidateProfileService.documentListByCategory =
      this.documentListByCategory;
    this.candidateProfileService.opportunityId = this.opportunityId;
    this.candidateProfileService.maxFamilyAnnualIncome =
      this.maxFamilyAnnualIncome;
    
    this.userAccDetails = this.accountService.getAccountLocal();

    this.getCandidateInfo();

  }

  ngOnDestroy(): void {
    this.candidateProfileService.resetValues();
  }

  postApplication(){
    this.candidateInfoService
      .tpostApplicationByOpportunityId(
        this.opportunityId
      )
      .subscribe({
        next: (res: any) => {
          this.candidateInfoId = res?.applicantId;
          this.applicantIdSent.emit(this.candidateInfoId);
          sessionStorage.setItem('seekerId', String(this.candidateInfoId));
          this.setApplicationInfo(res);
          this.isLoadingAll = false;
        },
        error: (err) => {
          if (err?.status === 400) {
            this.candidateProfileService.applicationDetailsFetched = true;
            this.candidateProfileService.uploadedDocument$.next([]);
            this.isLoadingAll=false;
            this.sharedService.openErrorSnackBar('Something went wrong try again later...!','');
          } 
          else {
            this.errorPage = true; 
            this.isLoadingAll = false;
          }
        },
    });
  }
  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.activeTabIndex = tabChangeEvent.index;
    this.addParam(this.router.url, this.tabs[tabChangeEvent.index].title);
  }
  addParam(url, type) {
    type = type.replace(/\s+/g, '_');
    this.route.navigate([], {
      relativeTo: url,
      queryParams: { activeTab: type },
    });
  }

  public replace(content: string) {
    return content?.replace(/_/g, ' ');
  }

  setApplicationInfo(response) {
    let uploadedDoc = [];
    this.candidateProfileService.applicationStatus = response?.status;
    this.candidateProfileService.applicationModifiedDate = Date.parse(
      response?.lastModifiedDate
    );
    this.candidateProfileService.applicationId = response?.id;
    localStorage.removeItem('applicationId');
    localStorage.setItem(
      'applicationId',
      JSON.stringify(this.candidateProfileService.applicationId)
    );
    response.applicationFiles?.forEach((doc) => {
      if (!uploadedDoc[doc?.category]) {
        uploadedDoc[doc.category] = [];
      }
      if (uploadedDoc[doc?.category]) {
        uploadedDoc[doc.category].push(doc);
      }
    });
    this.candidateProfileService.uploadedDoc = uploadedDoc;
    this.candidateProfileService.applicationDetailsFetched = true;
    this.candidateProfileService.uploadedDocument$.next(uploadedDoc);
    if (response?.status === 'SENT_BACK_TO_CANDIDATE') {
      this.sharedService.openSnackBar(
        'You need to upload sent back documents and re-submit application at the end.',
        '',
        3500
      );
    }
  }


  getApplicationInfo() {
    console.log(this.candidateProfileService.applicationAPIResponseFromViewALL)
    if (this.opportunityId) {
      if (this.candidateProfileService.applicationAPIResponseFromViewALL) {
        this.setApplicationInfo(this.candidateProfileService.applicationAPIResponseFromViewALL);
      }
      else if (this.candidateProfileService.applicationAPIResponseFromViewALL === null) {
        this.candidateProfileService.applicationDetailsFetched = true;
        this.candidateProfileService.uploadedDocument$.next([]);
      }
      else {
        this.candidateInfoService
          .tpostApplicationByOpportunityId(
            this.opportunityId
          )
          .subscribe({
            next: (res: any) => {
              this.setApplicationInfo(res);
              this.isLoadingAll = false;
            },
            error: (err) => { 
              if (err?.status === 404) { //TOdo status=400
                this.candidateProfileService.applicationDetailsFetched = true;
                this.candidateProfileService.uploadedDocument$.next([]);
                this.isLoadingAll =false;
                this.sharedService.openErrorSnackBar('Something went wrong try again later...!','');
              } 
              else {
                this.errorPage = true; 
                this.isLoadingAll = false;
              }
            },
        });
      }
    }
  }


  getCandidateInfo() {
    this.errorPage = false;
    this.candidateInfoId = this.candidateProfileService.getCandidateInfoId();
    if (this.candidateProfileService.candidateDetailsFromViewALL) {
      if (!this.candidateInfoId) {
        this.isCandidateInfoPresent = false;
        this.candidateProfileService.applicationDetailsFetched = true;
        this.candidateProfileService.uploadedDocument$.next([]);
        this.candidateProfileService.candidateInfoId$
          .pipe(take(1))
          .subscribe((candidateInfoId) => {
            this.applicantIdSent.emit(candidateInfoId);
            sessionStorage.setItem('seekerId', String(candidateInfoId));
        });
      }
      else {
        this.isCandidateInfoPresent = true;
        this.applicantIdSent.emit(this.candidateInfoId);
        this.candidateInfoService.fpostApplicationByOpportunityId(this.candidateProfileService.opportunityId).subscribe({
          next: (res)=>{
              if(res){
                 this.setApplicationInfo(res);
                 this.isLoadingAll=false;
              }else{
                 this.getApplicationInfo();
              }
          },
          error: (err)=>{
              this.sharedService.openErrorSnackBar('Something went wrong try again later...!','');
              this.route.navigate([
                '/candidate/problem-statements',
              ]);
            }
        })
      }
    }
    else {
      this.candidateInfoService.fpostApplicationByOpportunityId(this.candidateProfileService.opportunityId).subscribe({
        next: (res)=>{
            if(res){
               this.setApplicationInfo(res);
               this.isLoadingAll=false;
            }else{
              this.postApplication();
            }
        },
        error: (err)=>{
            this.sharedService.openErrorSnackBar('Something went wrong try again later...!','');
            this.route.navigate([
              '/candidate/problem-statements',
            ]);
        }
      })
    }
  }
}
