import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TakeSurveyComponent } from './take-survey.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SurveyDetailsModule } from '../../survey-master/survey-details/survey-details.module';
import { ChartsModule } from 'ng2-charts';
import { SurveymasterSharedLibsModule } from '../../shared/surveymaster-shared.module';
// import { DragDropAttachmentDirective } from 'src/app/shared/util/drag-drop-attachment.directive';
import { GatewaySharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    TakeSurveyComponent,
    // SmallTextPreviewComponent,
    // LongTextPreviewComponent,
    // CommentPreviewComponent,
    // NumericPreviewComponent,
    // DropDownPreviewComponent,
    // CheckboxPreviewComponent,
    // RatingPreviewComponent,
    // RankingPreviewComponent,
    // DateTimereviewComponent,
    // SliderPreviewComponent,
    // YesNoPreviewComponent,
    // NumberRatingPreviewComponent,
    // HTMLBlockPreviewComponent,
    // RadioButtonsPreviewComponent,
    // FileUploadPreviewComponent,
    // PhotoUploadPreviewComponent
  ],
  imports: [
    CommonModule,
    SurveymasterSharedLibsModule,
    ScrollingModule,
    NgxFileDropModule,
    ImageCropperModule,
    ChartsModule,
    SurveyDetailsModule,
    GatewaySharedModule
  ],

  entryComponents: [
    TakeSurveyComponent,
    // SmallTextPreviewComponent,
    // LongTextPreviewComponent,
    // CommentPreviewComponent,
    // NumericPreviewComponent,
    // DropDownPreviewComponent,
    // CheckboxPreviewComponent,
    // RatingPreviewComponent,
    // RankingPreviewComponent,
    // DateTimereviewComponent,
    // SliderPreviewComponent,
    // YesNoPreviewComponent,
    // NumberRatingPreviewComponent,
    // HTMLBlockPreviewComponent,
    // RadioButtonsPreviewComponent,
    // FileUploadPreviewComponent,
    // PhotoUploadPreviewComponent
  ],
  exports: [TakeSurveyComponent],
})
export class TakeSurveyModule {}
