export interface IEvent {
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  id: number;
  name: string;
  locations: string;
  fromDate: string;
  toDate: string;
  eventStatus: string;
  eventType: EventTypeEnum;
}

export enum EventTypeEnum {
  FRESHER = 'FRESHER',
  LATERAL = 'LATERAL',
  IJP = 'IJP',
  LATERAL_DYNAMIC = 'LATERAL_DYNAMIC',
  LATERAL_FACE_TO_FACE = 'LATERAL_FACE_TO_FACE',
  FRESHER_FACE_TO_FACE = 'FRESHER_FACE_TO_FACE',
}
