import { NgModule } from '@angular/core';
import { GatewaySharedLibsModule } from './shared-libs.module';
import { AlertComponent } from './components/alert/alert.component';
import { AlertErrorComponent } from './components/alert/alert-error.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { MaterialModule } from 'src/app/angular-material.module';
import { LoaderComponent } from './components/loader/loader.component';
import { DragDropAttachmentDirective } from './util/drag-drop-attachment.directive';
import { PanelistDeclarationComponent } from './components/panelist-declaration/panelist-declaration.component';
import { AttachFilesComponent } from './components/attach-files/attach-files.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { actionRendererComponent } from './components/ag-grid-renderer/actionRenderer.component';
import { linkRendererComponent } from './components/ag-grid-renderer/linkRenderer.component';
import { PanelistRendererComponent } from './components/ag-grid-renderer/panelist-renderer/panelist-renderer.component';
import { AgGridComponent } from './components/AgGrid/AgGrid.component';
import { AutoSaveComponent } from './components/auto-save/auto-save.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { FilterDialogComponent } from './components/filter-dialog/filter-dialog.component';
import { FilterUiComponent } from './components/filter-ui/filter-ui.component';
import { InfyAssociateJobsComponent } from './components/infy-associate-jobs/infy-associate-jobs.component';
import { SortRowsComponent } from './components/sort-rows/sort-rows.component';
import { AgGridModule } from 'ag-grid-angular';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AppPluginsModule } from '../app-plugins.module';
import { EventPanelistSelectInputComponent } from './components/event-panelist-select-input/event-panelist-select-input.component';
import { AddPanelistToInterviewDialogComponent } from './components/add-panelist-to-interview-dialog/add-panelist-to-interview-dialog.component';
import { NullDataPipe } from './pipes/null-data.pipe';
import { EndDatePipe } from './pipes/end-date.pipe';
import { FetchDataPipe } from './pipes';
import { SharedService } from '../services/shared.service';
import { ChipInputComponent } from './components/chip-input/chip-input.component';
import { JoditAngularModule } from 'jodit-angular';
import { InfyDeleteSnapshotsComponent } from '../infy-components/infy-delete-snapshots/infy-delete-snapshots.component';
import { InfyJoditEditorComponent } from './components/infy-jodit-editor/infy-jodit-editor.component';
import { AttachResumeComponent } from './components/attach-resume/attach-resume.component';
import { AppBannerComponent } from './components/app-banner/app-banner.component';
import { FilterBtnComponent } from './components/filter-ui/filter-btn/filter-btn.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { VerifyScholarshipDisbursedComponent } from './components/verify-scholarship-disbursed/verify-scholarship-disbursed.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    GatewaySharedLibsModule,
    MaterialModule,
    AppPluginsModule,
    AgGridModule,
    MatButtonModule,
    MatDialogModule,
    JoditAngularModule,
    NgxDaterangepickerMd.forRoot(),
  ],
  declarations: [
    FetchDataPipe,
    InfyJoditEditorComponent,
    EndDatePipe,
    NullDataPipe,
    actionRendererComponent,
    linkRendererComponent,
    PanelistRendererComponent,
    ChipInputComponent,
    AgGridComponent,
    AttachFilesComponent,
    AutoSaveComponent,
    ConfirmationComponent,
    VerifyScholarshipDisbursedComponent,
    FilterDialogComponent,
    FilterUiComponent,
    FilterBtnComponent,
    InfyAssociateJobsComponent,
    SortRowsComponent,
    AlertComponent,
    AlertErrorComponent,
    PaginationComponent,
    DragDropAttachmentDirective,
    LoaderComponent,
    PanelistDeclarationComponent,
    AttachFilesComponent,
    ErrorMessageComponent,
    EventPanelistSelectInputComponent,
    AddPanelistToInterviewDialogComponent,
    InfyDeleteSnapshotsComponent,
    AttachResumeComponent,
    AppBannerComponent
  ],
  providers: [SharedService],
  exports: [
    GatewaySharedLibsModule,
    MaterialModule,
    actionRendererComponent,
    linkRendererComponent,
    PanelistRendererComponent,
    AppPluginsModule,
    AgGridComponent,
    AttachFilesComponent,
    AutoSaveComponent,
    ConfirmationComponent,
    VerifyScholarshipDisbursedComponent,
    FilterDialogComponent,
    FilterUiComponent,
    FilterBtnComponent,
    ChipInputComponent,
    InfyAssociateJobsComponent,
    SortRowsComponent,
    AlertComponent,
    AlertErrorComponent,
    PaginationComponent,
    DragDropAttachmentDirective,
    LoaderComponent,
    PanelistDeclarationComponent,
    AttachFilesComponent,
    ErrorMessageComponent,
    EventPanelistSelectInputComponent,
    AddPanelistToInterviewDialogComponent,
    FetchDataPipe,
    EndDatePipe,
    NullDataPipe,

    InfyDeleteSnapshotsComponent,
    InfyJoditEditorComponent,
    AttachResumeComponent,
    AppBannerComponent
  ],
})
export class GatewaySharedModule {}
