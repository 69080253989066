import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { CandidateInfoService } from '../services/candidate-info.service';
import { AccountService } from 'src/app/services/account.service';
import { ResumeUploadService } from '../services/resume-upload.service';
import { SharedService } from 'src/app/services/shared.service';
import { NumberFilter } from 'ag-grid-community';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-apply-job',
  templateUrl: './apply-job.component.html',
  styleUrls: ['./apply-job.component.scss'],
})
export class ApplyJobComponent implements OnInit {
  expandProfileAccordion = true;
  expandDocsAccordion = false;
  uploadFiles = [];
  uploadFilesLOR = [];
  uploadFilesSOP = [];
  currentJobId: Number;
  applicationId: Number;
  spinner = false;
  candidateInfoId: string = '';
  uploadDocCount = 0;
  uploadFailedDocuments = [];
  apiFailed = false;
  constructor(
    private candidateJobsService: CandidateInfoService,
    private userDetailService: AccountService,
    private route: ActivatedRoute,
    private router: Router,
    private resumeUploadService: ResumeUploadService,
    private sharedService: SharedService,
    public _location: Location,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.currentJobId = parseInt(this.route.snapshot.paramMap.get('jobid'));
    this.route.snapshot.paramMap.get('job');
    let userId = this.userDetailService.getUserId();
    if (!userId) this.apiFailed = true;
    this.spinner = true;
    this.checkIfUserProfileExists(userId);
  }

  /** UTILITY FUNCTIONS **/
  handleFileChange = (files: Array<File>) => {
    this.uploadFiles = files;
  };

  handleFileChangeLOR = (files: Array<File>) => {
    this.uploadFilesLOR = files;
  };

  handleFileChangeSOP = (files: Array<File>) => {
    this.uploadFilesSOP = files;
  };

  submitApplication = () => {
    this.candidateJobsService
      .postCandidateJobApplication(this.candidateInfoId, this.currentJobId)
      .subscribe({
        next: (res: any) => {
          this.expandDocsAccordion = true;
          this.expandProfileAccordion = false;
          this.applicationId = res.id;

          this.sharedService.openSuccessSnackBar(
            'Applied for Problem Statement successfully. Now you can proceed with Document upload, if any or Go Back',
            ''
          );
          //applied to the job, doc upload is optional
          // this._location.back();
        },
        error: (err: any) => {
          this.sharedService.openErrorSnackBar(
            'Error occured. Please try again',
            ''
          );
        },
      });
  };

  checkIfUserProfileExists = (userId: string) => {
    this.resumeUploadService.checkForexistingResumes(userId).subscribe({
      next: (res: any) => {
        this.spinner = false;
        if (res == null) {
          this.sharedService.openErrorSnackBar(
            'Please complete your profile first to continue applying for Problem Statements.',
            ''
          );
          this.router.navigate(['candidate/upload-resume']);
        } else if (res.status != 'VALIDATED') {
          this.sharedService.openErrorSnackBar(
            'Please validate your Profile first to continue applying for Problem Statements',
            ''
          );
          this.router.navigate(
            ['candidate/candidate-profile'],
            { state : {checkForLocalStepState : true}}
            );
        } else {
          this.candidateInfoId = res.id;
          this.checkIfUserHasAlreadyAppliedForJob(
            this.candidateInfoId,
            this.currentJobId
          );
        }
      },
      error: (err: any) => {
        this.sharedService.openErrorSnackBar(
          'Some error occured. Please try again',
          ''
        );
        this.spinner = false;
      },
    });
  };

  checkIfUserHasAlreadyAppliedForJob = (candidateInfoId, jobId) => {
    this.candidateJobsService
      .getJobApplicationsOfAUser(candidateInfoId, jobId)
      .subscribe({
        next: (res: any) => {
          this.sharedService.openErrorSnackBar(
            'Your application for this Problem Statement already exists in our system.',
            ''
          );
          this.applicationId = res.id;
          this.expandDocsAccordion = true;
          this.expandProfileAccordion = false;
          // this._location.back();
        },
      });
  };

  uploadUserFiles() {
    if (
      this.uploadFiles.length +
        this.uploadFilesSOP.length +
        this.uploadFilesLOR.length ==
      0
    ) {
      this.sharedService.openErrorSnackBar(
        'Please select a file to upload',
        '',
        3000
      );
      return;
    }

    this.spinner = true;
    if (this.uploadFiles.length > 0) {
      this.uploadDocCount++;
      this.uploadSingleFile(this.uploadFiles, 'RP_RESUME_OTHERS');
    }
    if (this.uploadFilesSOP.length > 0) {
      this.uploadDocCount++;
      this.uploadSingleFile(this.uploadFilesSOP, 'RP_SOP');
    }
    if (this.uploadFilesLOR.length > 0) {
      this.uploadDocCount++;
      this.uploadSingleFile(this.uploadFilesLOR, 'RP_RECOMMENDATION_LETTER');
    }
  }

  uploadSingleFile = (fileToUpload: any[], docType: string) => {
    const specData = {
      jobApplicationId: this.applicationId,
      source: 'CANDIDATE',
    };

    let formData = new FormData();
    formData.append('specData', JSON.stringify(specData));
    fileToUpload.forEach((file) => {
      formData.append('file', file);
    });

    this.resumeUploadService.upload(formData, docType).subscribe({
      next: (res: any) => {},
      error: (err: any) => {
        this.uploadFailedDocuments.push(docType);
      },
      complete: () => {
        this.uploadDocCount--;
        if (this.uploadDocCount == 0) {
          this.spinner = false;
          if (!this.uploadFailedDocuments.includes('RP_SOP'))
            this.uploadFilesSOP = [];
          if (!this.uploadFailedDocuments.includes('RP_RECOMMENDATION_LETTER'))
            this.uploadFilesLOR = [];
          if (!this.uploadFailedDocuments.includes('RP_RESUME_OTHERS'))
            this.uploadFiles = [];

          if (this.uploadFailedDocuments.length == 0) {
            this.sharedService.openSuccessSnackBar('Successfully Uploaded Additional files!', '');
            this._location.back();
          } else {
            let msg = this.uploadFailedDocuments
              .map((value) => value.substring(3))
              .join(', ');
            this.sharedService.openErrorSnackBar(
              `${msg} upload failed, Please try again!`,
              ''
            );
          }
        }
      },
    });
  };
}