import { Injectable } from '@angular/core';
import { Observable, forkJoin, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoutesService {
  private reStructureRoutes$: BehaviorSubject<boolean>;

  constructor() {
    this.reStructureRoutes$ = new BehaviorSubject(false);
  }

  reStructureRoutesTrigger(): Observable<boolean> {
    return this.reStructureRoutes$.asObservable();
  }

  reStructureRoutesOn(flag) {
    this.reStructureRoutes$.next(flag);
  }
}
