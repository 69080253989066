import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { DesignComponent } from 'src/app/survey-master/survey-design/design.component';

import { SurveyDetailsRoutingModule } from './survey-details-routing.module';
import { SurveyDetailSectionComponent } from 'src/app/survey-master/survey/survey.detail.section.component';
import { SurveyResponsesComponent } from 'src/app/survey-master/survey-responses/survey.responses.component';
import { PageCreateDialogComponent } from 'src/app/survey-master/survey-design/survey-pages/page.create.dialog.component';
import { PageDeleteDialogContentsComponent } from 'src/app/survey-master/survey-design/survey-pages/page.delete.dialog.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { QuestionDeleteDialogContentsComponent } from 'src/app/survey-master/survey-design/survey-questions/survey-questions.delete.dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSidenavModule } from '@angular/material/sidenav';
import { UploadFileComponent } from 'src/app/survey-master/upload-file/upload-file.dialog.component';
import { QuestionsListComponent } from 'src/app/survey-master/survey-design/survey-questions/questions-list/questions-list.component';
import { SmallTextPreviewComponent } from 'src/app/survey-master/survey-preview/questtion-types/small-text/small-text.component';
import { DropDownPreviewComponent } from 'src/app/survey-master/survey-preview/questtion-types/dropdown/dropdown.component';
import { LongTextPreviewComponent } from 'src/app/survey-master/survey-preview/questtion-types/long-text/long-text.component';
import { CommentPreviewComponent } from 'src/app/survey-master/survey-preview/questtion-types/comment/comment.component';
import { NumericPreviewComponent } from 'src/app/survey-master/survey-preview/questtion-types/numeric/numeric.component';
import { RadioButtonsPreviewComponent } from 'src/app/survey-master/survey-preview/questtion-types/radio-buttons/radio-buttons.component';
import { CheckboxPreviewComponent } from 'src/app/survey-master/survey-preview/questtion-types/checkbox/checkbox.component';
import { RatingPreviewComponent } from 'src/app/survey-master/survey-preview/questtion-types/rating/rating.component';
import { RankingPreviewComponent } from 'src/app/survey-master/survey-preview/questtion-types/ranking/ranking.component';
import { DateTimePreviewComponent } from 'src/app/survey-master/survey-preview/questtion-types/date-time/date-time.component';
import { SliderPreviewComponent } from 'src/app/survey-master/survey-preview/questtion-types/slider/slider.component';
import { YesNoPreviewComponent } from 'src/app/survey-master/survey-preview/questtion-types/yes-no/yes-no.component';
import { NumberRatingPreviewComponent } from 'src/app/survey-master/survey-preview/questtion-types/number-rating/number-rating.component';
import { HTMLBlockPreviewComponent } from 'src/app/survey-master/survey-preview/questtion-types/html-block/html-block.component';
import { ContactInfoPreviewComponent } from 'src/app/survey-master/survey-preview/questtion-types/contact-info/contact-info.component';
import { MultiTextBoxPreviewComponent } from 'src/app/survey-master/survey-preview/questtion-types/multi-textbox/multi-textbox.component';
import { MapInputPreviewComponent } from 'src/app/survey-master/survey-preview/questtion-types/map-input/map-input.component';
import { VideoUploadPreviewComponent } from 'src/app/survey-master/survey-preview/questtion-types/video-upload/video-upload.component';
import { ClipboardModule } from 'ngx-clipboard';
import { FileUploadPreviewComponent } from 'src/app/survey-master/survey-preview/questtion-types/file-upload/file-upload.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { PhotoUploadPreviewComponent } from 'src/app/survey-master/survey-preview/questtion-types/photo-upload/photo-upload.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ThemeDeleteDialogComponent } from 'src/app/survey-master/survey/edit-backgroud-color/theme-delete-dialog.component';
import { AttachmentDeleteDialogComponent } from 'src/app/survey-master/survey/edit-backgroud-color/attachment-delete-dialog.component';
import { SurveyNavigationComponent } from './survey-navigation/survey-navigation.component';
import { SurveyHeadingComponent } from 'src/app/survey-master/survey/survey-heading/survey-heading.component';
import { QuestionsActionsComponent } from 'src/app/survey-master/survey-design/question-actions/question-actions.component';
import { RespondantDetailsComponent } from 'src/app/survey-master/survey-responses/respondant-details/respondant-details.component';
import { ChartsModule } from 'ng2-charts';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SurveyResponsesService } from 'src/app/survey-master/survey-responses/survey-responses.service';
import { PageThemesDialog } from 'src/app/survey-master/survey-design/themes/page-themes/page-themes.dialog';
import { QuestionThemesDialog } from 'src/app/survey-master/survey-design/themes/question-themes/question-themes.dialog';
import { SurveymasterSharedLibsModule } from 'src/app/shared/surveymaster-shared.module';
import { SurveyInitViewComponent } from '../survey/survey-init-view/survey-init-view.component';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MultiQuestionPreviewComponent } from '../survey-preview/questtion-types/multi-question/multi-question.component';
import { CreateMultiQuestionDialog } from '../survey-design/multi-question/multi-question.dialog';
// import { SafePipe } from 'src/app/app.module';

@NgModule({
  declarations: [
    QuestionsListComponent,
    SurveyInitViewComponent,
    DesignComponent,
    SurveyDetailSectionComponent,
    SurveyResponsesComponent,
    PageCreateDialogComponent,
    PageDeleteDialogContentsComponent,
    QuestionDeleteDialogContentsComponent,
    UploadFileComponent,
    SmallTextPreviewComponent,
    LongTextPreviewComponent,
    CommentPreviewComponent,
    NumericPreviewComponent,
    DropDownPreviewComponent,
    CheckboxPreviewComponent,
    RatingPreviewComponent,
    RankingPreviewComponent,
    DateTimePreviewComponent,
    SliderPreviewComponent,
    YesNoPreviewComponent,
    NumberRatingPreviewComponent,
    HTMLBlockPreviewComponent,
    RadioButtonsPreviewComponent,
    FileUploadPreviewComponent,
    PhotoUploadPreviewComponent,
    ContactInfoPreviewComponent,
    MultiTextBoxPreviewComponent,
    MapInputPreviewComponent,
    VideoUploadPreviewComponent,
    ThemeDeleteDialogComponent,
    AttachmentDeleteDialogComponent,
    MultiQuestionPreviewComponent,
    // SafePipe,
    SurveyNavigationComponent,
    SurveyHeadingComponent,
    QuestionsActionsComponent,
    RespondantDetailsComponent,
    PageThemesDialog,
    QuestionThemesDialog,
    CreateMultiQuestionDialog,
    // PollsComponent
    // SurveyResponsesService
  ],
  providers: [DatePipe, TranslateService],
  imports: [
    CommonModule,
    SurveyDetailsRoutingModule,
    SurveymasterSharedLibsModule,
    DragDropModule,
    MatSidenavModule,
    ClipboardModule,
    NgxFileDropModule,
    ImageCropperModule,
    ChartsModule,
    NgxMatSelectSearchModule,
    TranslateModule.forChild(),
  ],

  entryComponents: [
    PageCreateDialogComponent,
    PageDeleteDialogContentsComponent,
    QuestionDeleteDialogContentsComponent,
    SmallTextPreviewComponent,
    DropDownPreviewComponent,
    LongTextPreviewComponent,
    CommentPreviewComponent,
    NumericPreviewComponent,
    RadioButtonsPreviewComponent,
    CheckboxPreviewComponent,
    RatingPreviewComponent,
    RankingPreviewComponent,
    DateTimePreviewComponent,
    SliderPreviewComponent,
    YesNoPreviewComponent,
    NumberRatingPreviewComponent,
    HTMLBlockPreviewComponent,
    FileUploadPreviewComponent,
    PhotoUploadPreviewComponent,
    ContactInfoPreviewComponent,
    MultiTextBoxPreviewComponent,
    MapInputPreviewComponent,
    VideoUploadPreviewComponent,
    UploadFileComponent,
    SurveyNavigationComponent,
    SurveyHeadingComponent,
    QuestionsActionsComponent,
    RespondantDetailsComponent,
    MultiQuestionPreviewComponent,
    PageThemesDialog,
    QuestionThemesDialog,
    CreateMultiQuestionDialog,
  ],

  exports: [
    SmallTextPreviewComponent,
    DropDownPreviewComponent,
    LongTextPreviewComponent,
    CommentPreviewComponent,
    NumericPreviewComponent,
    RadioButtonsPreviewComponent,
    CheckboxPreviewComponent,
    RatingPreviewComponent,
    RankingPreviewComponent,
    DateTimePreviewComponent,
    SliderPreviewComponent,
    YesNoPreviewComponent,
    NumberRatingPreviewComponent,
    HTMLBlockPreviewComponent,
    FileUploadPreviewComponent,
    PhotoUploadPreviewComponent,
    UploadFileComponent,
    ContactInfoPreviewComponent,
    MultiTextBoxPreviewComponent,
    MapInputPreviewComponent,
    VideoUploadPreviewComponent,
    MultiQuestionPreviewComponent,
    // SurveyResponsesService
  ],
})
export class SurveyDetailsModule {}
