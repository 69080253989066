<fieldset [disabled]="disabled">
  <div *ngIf="view==='TWO_COLUMNS';else oneColumn">
    <div class="row">
      <div class="col-6" style="display: flex;flex-direction: column;justify-content:center;">
        <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
            {{questionIndex+1}}.
          </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
            *
          </span></p>
        <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">

      </div>
      <div class="col-6">
        <mat-form-field appearance="fill" style="width:95%" *ngFor="let option of question.type.options;let oi = index">
          <!-- <mat-label attr.for="{{question.id}}">{{question.hint.text}}</mat-label> -->
          <input matInput [placeholder]="option.value"
            [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[oi]"
            [required]="question.preference.isRequired===1" (change)="inputChanged(oi)" (focusout)="inputChanged(oi)" />

        </mat-form-field>
        <span *ngIf="!responsePage.sections[section].questions[questionIndex].isValid"
          style="color:red;font-size: 12px;">
          {{responsePage.sections[section].questions[questionIndex].validationMessage}}</span>


      </div>
    </div>
  </div>

  <ng-template #oneColumn>

    <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
        {{questionIndex+1}}.
      </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
        *
      </span></p>
    <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">
    <mat-form-field style="width:95%" *ngFor="let option of question.type.options;let oi = index">
      <!-- <mat-label attr.for="{{question.id}}">{{question.hint.text}}</mat-label> -->
      <input matInput [placeholder]="option.value"
        [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[oi]"
        [required]="question.preference.isRequired===1" (change)="inputChanged(oi)" (focusout)="inputChanged(oi)" />

    </mat-form-field>
    <span *ngIf="!responsePage.sections[section].questions[questionIndex].isValid" style="color:red;font-size: 12px;">
      {{responsePage.sections[section].questions[questionIndex].validationMessage}}</span>

  </ng-template>

</fieldset>
