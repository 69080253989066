<fieldset [disabled]="disabled">
  <div *ngIf="view === 'TWO_COLUMNS'; else oneColumn">
    <div class="row">
      <div
        class="col-6"
        style="display: flex; flex-direction: column; justify-content: center"
      >
        <p>
          <span
            *ngIf="
              responsePage.sections[section].questions[questionIndex]
                .showNumbers
            "
          >
            {{ questionIndex + 1 }}.
          </span>
          {{ question.text
          }}<span
            *ngIf="question.preference.isRequired === 1"
            style="margin-left: 2px; color: red"
          >
            *
          </span>
        </p>
        <br />
        <br />
        <img
          *ngIf="question.logo !== null"
          [src]="downloadImage(question.logo.path)"
          class="question-image"
        />
      </div>
      <div class="col-6">
        <div *ngIf="isNumeric; else nonNumeric">
          <div class="row" style="margin-top: 10px; margin-bottom: 10px">
            <div class="col-sm-6 text-small">
              {{ minValue }}
            </div>

            <div class="col-sm-6 pull-right text-small text-right">
              {{ maxValue }}
            </div>
          </div>

          <mat-slider
            #slider
            [disabled]="disabled"
            style="width: 95%"
            color="accent"
            [max]="maxValue"
            [min]="minValue"
            [step]="stepValue"
            [tickInterval]="'autoTicks'"
            (change)="inputChanged($event)"
            [(ngModel)]="
              responsePage.sections[section].questions[questionIndex]
                .response[0]
            "
          >
          </mat-slider>
        </div>

        <ng-template #nonNumeric>
          <table
            style="border: none; width: 80%"
            *ngIf="!mobileQuery.matches; else MobileTable"
          >
            <tr>
              <td
                *ngFor="let x of question.type.options; let xi = index"
                [ngStyle]="{
                  'text-align':
                    xi == 0
                      ? 'left'
                      : xi == question.type.options.length - 1
                      ? 'right'
                      : 'center'
                }"
              >
                {{ x.value }}
              </td>
            </tr>
          </table>
          <ng-template #MobileTable>
            <table style="border: none; width: 80%">
              <tr *ngFor="let x of question.type.options; let xi = index">
                <td style="text-align: center">{{ xi + 1 }}</td>
                <td style="text-align: center">
                  {{ x.value }}
                </td>
              </tr>
            </table>
          </ng-template>
          <mat-slider
            #slider
            [disabled]="disabled"
            style="width: 80%"
            color="accent"
            [max]="maxValue"
            [value]="
              question.type.options.indexOf(
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0]
              )
            "
            [min]="minValue"
            [step]="stepValue"
            [tickInterval]="'autoTicks'"
            (change)="inputChanged($event)"
          >
          </mat-slider>
        </ng-template>
      </div>
    </div>
  </div>

  <ng-template #oneColumn>
    <p>
      <span
        *ngIf="
          responsePage.sections[section].questions[questionIndex].showNumbers
        "
      >
        {{ questionIndex + 1 }}.
      </span>
      {{ question.text
      }}<span
        *ngIf="question.preference.isRequired === 1"
        style="margin-left: 2px; color: red"
      >
        *
      </span>
    </p>
    <br />
    <br />
    <img
      *ngIf="question.logo !== null"
      [src]="downloadImage(question.logo.path)"
      class="question-image"
    />
    <div *ngIf="isNumeric; else nonNumeric">
      <div class="row" style="margin-top: 10px; margin-bottom: 10px">
        <div class="col-sm-6 text-small">
          {{ minValue }}
        </div>

        <div class="col-sm-6 pull-right text-small text-right">
          {{ maxValue }}
        </div>
      </div>

      <mat-slider
        #slider
        [disabled]="disabled"
        style="width: 95%"
        color="accent"
        [max]="maxValue"
        [min]="minValue"
        [step]="stepValue"
        [tickInterval]="'autoTicks'"
        (change)="inputChanged($event)"
        [(ngModel)]="
          responsePage.sections[section].questions[questionIndex].response[0]
        "
      >
      </mat-slider>
    </div>

    <ng-template #nonNumeric>
      <table
        style="border: none; width: 80%"
        *ngIf="!mobileQuery.matches; else MobileTable"
      >
        <tr>
          <td
            *ngFor="let x of question.type.options; let xi = index"
            [ngStyle]="{
              'text-align':
                xi == 0
                  ? 'left'
                  : xi == question.type.options.length - 1
                  ? 'right'
                  : 'center'
            }"
          >
            {{ x.value }}
          </td>
        </tr>
      </table>
      <ng-template #MobileTable>
        <table style="border: none; width: 80%">
          <tr *ngFor="let x of question.type.options; let xi = index">
            <td style="text-align: center">{{ xi + 1 }}</td>
            <td style="text-align: center">
              {{ x.value }}
            </td>
          </tr>
        </table>
      </ng-template>
      <mat-slider
        #slider
        [disabled]="disabled"
        style="width: 80%"
        color="accent"
        [max]="maxValue"
        [value]="
          question.type.options.indexOf(
            this.responsePage.sections[this.section].questions[
              this.questionIndex
            ].response[0]
          )
        "
        [min]="minValue"
        [step]="stepValue"
        [tickInterval]="'autoTicks'"
        (change)="inputChanged($event)"
      >
      </mat-slider>
    </ng-template>
  </ng-template>
</fieldset>
