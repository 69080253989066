import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, Subject, BehaviorSubject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private http: HttpClient) {}

  private headers = new HttpHeaders()
    .set('content-type', 'application/json');

  sendRecruiterFeedback = (data: any): Observable<any[]> => {
    return this.http.post<any[]>(
      environment.interviewServiceUrl + 'api/recruiter/feedback',
      data,
      { headers: this.headers }
    );
  };
  postFeedback = (data: any) => {
    return this.http.post(
      `${environment.interviewServiceUrl}api/feedback/interviewer`,
      data,
      { headers: this.headers }
    ) as Observable<any>;
  };
  getNoShowData = (cirId) => {
    return this.http.get<any[]>(
      environment.interviewServiceUrl +
        `api/interviews/candidate-interview-round/${cirId}/status/noshow`,
      { headers: this.headers }
    );
  };
}
