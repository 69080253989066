import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AgRendererComponent } from 'ag-grid-angular';
import { InterviewListStateStorageService } from 'src/app/modules/iwp-event/infy-interview-list/interview-list-state-storage.service';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { AccountService } from 'src/app/services/account.service';
import { CandidateService } from 'src/app/services/candidate.service';
import { EventService } from 'src/app/services/event.service';
import { InterviewService } from 'src/app/services/interview.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  template: `<span *ngIf="params.condition && icon">
    <i
      class="icon x-24 {{ icon }}"
      [matTooltip]="toolTipText"
      matTooltipClass="DSA_tooltip"
      role="button"
      (click)="onClick()"
    ></i>
  </span>`,
})
export class InterviewListActionRendererComponent
  implements AgRendererComponent {
  params: any;
  icon: any;
  toolTipText: string;
  gridApi: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private interviewListStateStorage: InterviewListStateStorageService,
    private sharedService: SharedService,

    private eventService: EventService,
    private candidateService: CandidateService,
    public accountService: AccountService,
    private interviewService: InterviewService
  ) {}

  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;
    if (
      params.icon === 'delete' &&
      params.data?.interviewStatus === 'UPCOMING'
    ) {
      this.icon = 'delete-sm-icon';
      this.toolTipText = 'Cancel Interview';
    } else if (
      params.icon === 'schedule'
      // &&
      // params.data?.interviewStatus !== 'CANCELLED'
    ) {
      this.icon = 'calendar-icon';
      if (params.metaData?.eventStatus === 'CLOSED')
        this.toolTipText = 'View Scheduled Interviews';
      else this.toolTipText = 'Schedule Interview';
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  async onClick() {
    if (this.params.icon === 'delete') {
      // this.params.cancelInterview(this.params.data.id);
      // this.params.context.componentParent.refreshTable();
      this.cancelInterview(this.params.data.id);
    } else if (this.params.icon === 'schedule') {
      let data = this.params.data;
      let candidateDetailsObj = {
        candidateId: data.candidate.candidateId
          ? data.candidate.candidateId
          : '',
        candidateSourceId: data.candidate.candidateSourceId,
        name: data.candidate.name ? data.candidate.name : '',
        email: data.candidate.email ? data.candidate.email : '',
        phone: data.candidate.phone ? data.candidate.phone : '',
        frozen: data.frozen,
        cirId: data.candidate.candidateInterviewRoundId
          ? data.candidate.candidateInterviewRoundId
          : '',
        applicationId: data.candidateApplicationDetails
          ? data.candidateApplicationDetails.applicationId
            ? data.candidateApplicationDetails.applicationId
            : ''
          : '',
        technology: data.candidateApplicationDetails
          ? data.candidateApplicationDetails.technology
            ? data.candidateApplicationDetails.technology
            : ''
          : '',
        unit: data.candidateApplicationDetails
          ? data.candidateApplicationDetails.unit
            ? data.candidateApplicationDetails.unit
            : ''
          : '',
        subUnit: data.candidateApplicationDetails
          ? data.candidateApplicationDetails.subUnit
            ? data.candidateApplicationDetails.subUnit
            : ''
          : '',
        jobId: data.jobOpening.id ? data.jobOpening.id : '',
      };
      this.candidateService.setSelectedCandidate(candidateDetailsObj);
      this.router.navigate(['/candidate-interview-rounds']);
    }
  }

  cancelInterview = (id) => {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        text:
          "Are you sure you want to cancel this interview?<br/> On <strong class='font-red'>Yes</strong>, the interview will be cancelled",
      },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Yes') {
        this.params.context.componentParent.showLoadingOverlay();
        this.interviewService.getCancelledInterviewDetails(id).subscribe(
          () => {
            this.sharedService.openSnackBar('Interview Cancelled', '', 3000);
            this.params.context.componentParent.refreshTable();
          },
          () => {
            this.params.context.componentParent.setGridUI();
            this.sharedService.openSnackBar(
              'Interview Cancellation Failed',
              '',
              3000
            );
          }
        );
      }
    });
  };
}
