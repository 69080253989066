import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { RoutesService } from './routes.service';
import { IEvent } from '../shared/model/event.model';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  valid: boolean;
  profile: any;
  validEvent: boolean;
  selectedEventId: any;
  eventSetupFlags: any = {};

  private selectedEvent: IEvent;

  constructor(private http: HttpClient, private routesService: RoutesService) {}

  private headers = new HttpHeaders().set('content-type', 'application/json');

  setSelectedEvent = (eventDetails: IEvent): void => {
    this.selectedEvent = eventDetails;
  };

  getSelectedEvent = (): IEvent => {
    return this.selectedEvent;
  };
  setEventFlags=(eventSetupFlags)=>{
this.eventSetupFlags=eventSetupFlags;
  }
getEventFlags=()=>{
  return this.eventSetupFlags;
}
  addRecrutersToEvent = (data) => {
    return this.http.post(
      environment.interviewServiceUrl +
        'api/events/' +
        this.selectedEventId +
        '/coordinator/add',
      data,
      { headers: this.headers }
    );
  };

  updateRecrutersOfEvent = (data) => {
    return this.http.put(
      environment.interviewServiceUrl + 'api/event-coordinators',
      data,
      { headers: this.headers }
    );
  };

  deleteRecrutersOfEvent = (data) => {
    return this.http.delete(
      environment.interviewServiceUrl + 'api/event-coordinators/' + data.id,
      { headers: this.headers }
    );
  };

  deleteInterviewersOfEvent = (data) => {
    return this.http.delete(
      environment.interviewServiceUrl + 'api/event-panelists/' + data.id,
      { headers: this.headers }
    );
  };

  addInterviewersToEvent = (data) => {
    return this.http.post(
      environment.interviewServiceUrl +
        'api/events/' +
        this.selectedEventId +
        '/panelist/add',
      data,
      { headers: this.headers }
    );
  };

  getEventsbyRequisition = (requisitionId): Observable<any> => {
    console.log('get associated');
    return this.http.get<any>(
      environment.interviewServiceUrl +
        `api/event-requisitions/event/${requisitionId}`,
      { headers: this.headers }
    );
  };

  associateEventByRequisition = (data) => {
    return this.http.post(
      environment.interviewServiceUrl + 'api/event-requisitions',
      data,
      { headers: this.headers }
    );
  };
  // ! service file -- maybe used in unused components
  // getInterviewerListWithEvent() {
  //   let params = new HttpParams();
  //   params = params.append('size', `${20000}`);

  //   const opts = {
  //     headers: this.headers,
  //     params,
  //   };
  //   return this.http.get<any[]>(
  //     environment.interviewServiceUrl +
  //       'api/events/' +
  //       this.selectedJobEvent.id +
  //       '/panelists',
  //     opts
  //   );
  // }

  // ! from recruiter.service file
  getInterviewerListWithEvent = (size, page, eventId?) => {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);
    let selectedEventId;
    if (eventId) selectedEventId = eventId;
    else selectedEventId = this.selectedEventId;

    const opts = {
      headers: this.headers,
      params,
    };
    return this.http.get<any[]>(
      environment.interviewServiceUrl +
        'api/events/' +
        selectedEventId +
        '/panelists',
      opts
    );
  };

  // // Added For Interviewer status
  getInterviewersStatusWithEvent = (size, page, eventId?) => {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);
    let selectedEventId;
    if (eventId) selectedEventId = eventId;
    else selectedEventId = this.selectedEventId;

    const opts = {
      headers: this.headers,
      params,
    };
    return this.http.get<any[]>(
      environment.assistedScheduleServiceUrl +
        'api/dynamicDashboard/panelistStatus?' +
        //selectedEventId +
        //'eventId=469901',
        'eventId=' +
        eventId,
      opts
    );
  };

  getCandidatesStatusWithEvent = (size, page, eventId?) => {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);
    let selectedEventId;
    if (eventId) selectedEventId = eventId;
    else selectedEventId = this.selectedEventId;

    const opts = {
      headers: this.headers,
      params,
    };
    return this.http.get<any[]>(
      environment.assistedScheduleServiceUrl +
        'api/dynamicDashboard/candidates?' +
        //selectedEventId +
        //'eventId=469901',
        'eventId=' +
        eventId,
      opts
    );
  };
  // // Status ends here

  getInterviewerListWithEventAndSearchParam = (
    searchQuery,
    size,
    page,
    eventId?
  ) => {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);

    const selectedEventId = eventId ? eventId : this.selectedEventId;

    const opts = {
      headers: this.headers,
      params,
    };
    return this.http.get<any[]>(
      environment.interviewServiceUrl +
        'api/events/' +
        selectedEventId +
        '/panelists/' +
        searchQuery,
      opts
    );
  };

  getEventSetupFlags = () => {
    return this.http.get<any[]>(
      environment.interviewServiceUrl +
      'api/eventSetupTag/' +
        this.selectedEventId,
      { headers: this.headers }
    );
  };

  updateEventSetupFlag = (flags) => {
    console.log(flags);
    return this.http.put(
      environment.interviewServiceUrl + 'api/eventSetupTag',
      flags,
      { headers: this.headers }
    );
  };
  // setEventSetupFlags=()=>{

  //   return this.http.put(environment.interviewServiceUrl+'eventSetupTag'+this.selectedEventId,{headers: this.headers});

  //   }
  getRecruitersListWithEventAndSearchParam = (searchQuery, size, page) => {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);

    const opts = {
      headers: this.headers,
      params,
    };

    return this.http.get<any[]>(
      environment.interviewServiceUrl +
        'api/events/' +
        this.selectedEventId +
        '/coordinators/' +
        searchQuery,
      opts
    );
  };

  getEventById = (id) => {
    return this.http.get<any[]>(
      environment.interviewServiceUrl + 'api/events/' + id,
      { headers: this.headers }
    );
  };

  createEvent = (data) => {
    return this.http.post(
      environment.interviewServiceUrl + 'api/events',
      data,
      { headers: this.headers }
    );
  };

  updateEvent = (data) => {
    return this.http.put(environment.interviewServiceUrl + 'api/events', data, {
      headers: this.headers,
    });
  };

  closeEvent = (id) => {
    return this.http.post(
      environment.interviewServiceUrl + 'api/events/closure/' + id,
      '',
      { headers: this.headers }
    );
  };

  duplicateEvent = (data) => {
    return this.http.post(
      environment.interviewServiceUrl + 'api/events/duplicate',
      data,
      {
        headers: this.headers,
      }
    );
  };

  getAllEvents = (size, page, eventName?) => {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);
    params = params.append('sort', `createdDate,desc`);
    if (eventName) {
      params = params.append('eventName', `${eventName}`);
    }
    return this.http.get<any[]>(
      environment.interviewServiceUrl + 'api/events',
      { headers: this.headers, params }
    );
  };

  getEvents = (size?, page?, eventName?, requisitionId?, condition?) => {

    if (condition == 'associated-events') {
      return this.http.get<any>(
        environment.interviewServiceUrl +
          `api/event-requisitions/event/${requisitionId}`,
        { headers: this.headers }
      );
    } else {
      let params = new HttpParams();
      params = params.append('size', `${size}`);
      params = params.append('page', `${page}`);
      params = params.append('sort', `createdDate,desc`);
      if (eventName) {
        params = params.append('eventName', `${eventName}`);
      }
      return this.http.get<any[]>(
        environment.interviewServiceUrl + 'api/events',
        { headers: this.headers, params }
      );
    }
  };

  getInterviewsForEvent = (
    page,
    size,
    query,
    filters?,
    sort?: any
  ): Observable<any[]> => {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);
    if (query) {
      params = params.append('search', `${query}`);
    }
    if (sort) {
      params = params.append('sort', `${sort}`);
    }
    const opts = {
      headers: this.headers,
      params,
    };
    return this.http.post<any[]>(
      `${environment.gatewayServiceUrl}api/interviews/event/${this.selectedEventId}`,
      { filters: filters },
      opts
    );
  };

  // ? GET Api call
  // getInterviewsForEvent = (page, size, query, filters?): Observable<any[]> => {
  //   let params = new HttpParams();

  //   if (query) {
  //     params = params.append('search', `${query}`);
  //     params = params.append('size', `${size}`);
  //     params = params.append('page', `${page}`);
  //   } else {
  //     params = params.append('size', `${size}`);
  //     params = params.append('page', `${page}`);
  //   }
  //   const opts = {
  //     headers: this.headers,
  //     params,
  //   };
  //   return this.http.get<any[]>(
  //     `${environment.gatewayServiceUrl}api/interviews/event/${this.selectedEventId}`,
  //     opts
  //   );
  // };

  getInterviewsFilterCriteriaForEvent() {
    return this.http.get(
      environment.gatewayServiceUrl +
        'api/interviews/search-aggregations/event/' +
        this.selectedEventId,
      { headers: this.headers }
    );
  }

  getDynamicScheduleStatus = (eventId: number) => {
    let params = new HttpParams();
    params = params.append('eventId', `${eventId}`);

    const opts = {
      headers: this.headers,
      params,
    };

    return this.http.get(
      `${environment.assistedScheduleServiceUrl}api/dynamicDashboard`,
      opts
    );
  };

  setEventCreateAction = (data) => {
    this.validEvent = data === 'New';
  };
}
