<div class="upload-resume-container">
  <h1 class="DSA_wb_h1Header">Upload Resume</h1>
  <span class="DSA_wb_caption web-GreyLight">
    Your profile will be auto created with the extracted data. This is a one
    time process.
  </span>
  <input
    #fileUpload
    type="file"
    [multiple]="false"
    (change)="onFileSelected($event)"
    [accept]="allowedFileTypes.extensions.join(',')"
    hidden
  />

  <div class="d-flex marT40">
    <div class="file-name">{{ attachedFile?.name }}</div>
    <button class="secondary-btn browse-btn" (click)="onBrowseFilesClick()">
      Browse
    </button>
    <button
      class="black-btn upload-btn"
      (click)="uploadResume()"
      [disabled]="!attachedFile"
    >
      Upload
    </button>
  </div>
  <span class="DSA_wb_caption web-SecondaryRed">{{ fileErrorMessage }}</span>
</div>
