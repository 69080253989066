<div class="marB8 marL16">
  <mat-radio-group
    [formControl]="formDisplayType"
    (change)="handleDisplayTypeChange($event)"
    class="DSA_Wb_custom-radio"
  >
    <mat-radio-button
      *ngFor="let option of displayFormTypes"
      [value]="option.value"
      disableRipple="true"
    >
      {{ option.displayValue }}
    </mat-radio-button>
  </mat-radio-group>
</div>
<form-builder
  [form]="formJSON"
  (change)="createdFormOnChange($event)"
></form-builder>
