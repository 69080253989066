import { AuthorityName } from './enumerations/authority-name.model';
export class CanAddList {
    data = {
        ROLE_SUPER_ADMIN : [
            AuthorityName.ROLE_EVENT_ADMIN,
            AuthorityName.ROLE_PLATFORM_EXECUTIVE,
            AuthorityName.ROLE_RECRUITER,
            AuthorityName.ROLE_SCHEDULER,
            AuthorityName.ROLE_SPOC,
            AuthorityName.ROLE_SUPER_ADMIN,
            AuthorityName.ROLE_SUPPORT_EXECUTIVE
        ],
        ROLE_EVENT_ADMIN : [
            AuthorityName.ROLE_EVENT_ADMIN,
            AuthorityName.ROLE_PLATFORM_EXECUTIVE,
            AuthorityName.ROLE_RECRUITER,
            AuthorityName.ROLE_SCHEDULER,
            AuthorityName.ROLE_SPOC,
            AuthorityName.ROLE_SUPPORT_EXECUTIVE
        ],
        ROLE_RECRUITER : [
            AuthorityName.ROLE_SCHEDULER,
            AuthorityName.ROLE_SPOC,
            AuthorityName.ROLE_SUPPORT_EXECUTIVE
        ],
        ROLE_PLATFORM_EXECUTIVE : [
            AuthorityName.ROLE_PLATFORM_EXECUTIVE,
            AuthorityName.ROLE_RECRUITER,
            AuthorityName.ROLE_SCHEDULER,
            AuthorityName.ROLE_SPOC,
            AuthorityName.ROLE_SUPPORT_EXECUTIVE
        ]


    }
}