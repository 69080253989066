<div *ngIf="filterState" class="marB16">
  <div class="filter-criteria">
    <ng-container #chipList *ngFor="let filter of filterByColumnsAll">
      <mat-chip
        *ngFor="let filterBy of filterState[filter?.key]"
        [removable]="'true'"
        (removed)="removeFilter(filter?.key, filterBy)"
        class="DSA_wb_chipStyle-Normal marR16 marB8 filter-key"
        [ngClass]="filter?.key"
        [matTooltip]="filter?.value"
        matTooltipClass="DSA_tooltip"
        disableRipple
      >
        {{ filterBy.split("_").join(" ") }}
        <a
          href="javascript:void();"
          matChipRemove
          class="icon x-16 icon-close-mini DSA_wb_chipIconRight marT8"
        ></a>
      </mat-chip>
    </ng-container>
  </div>
</div>
