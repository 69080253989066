<h1 mat-dialog-title>Bulk Upload</h1>
<app-loader [display]="isLoading"></app-loader>
<ng-container *ngIf="!isLoading && !isError">
  <ng-container *ngIf="showStepper; else showNoStepper">
    <div mat-dialog-content>
      <mat-horizontal-stepper #stepper labelPosition="right">
        <mat-step label="Mail Templates">
          <p class="font-bold">Mail Templates</p>
          <div *ngIf="addedTemplateCategories.length > 0" class="marTB16">
            <p>The following mail templates are added to the event:</p>
            <div class="marTB8">
              <ul class="templateCategoryList padLR32">
                <li *ngFor="let category of addedTemplateCategories">
                  {{ category.split("_").join(" ") }}
                </li>
              </ul>
            </div>
          </div>
          <templates-warning></templates-warning>
          <p>Are you sure you want to proceed?</p>
          <div class="d-flex marT16 justify-content-end">
            <button
              mat-button
              class="DSA_wb_miniSecondary-btn"
              (click)="closeDialog()"
            >
              Cancel
            </button>
            <button
              mat-button
              class="DSA_wb_miniPrimary-btn marL8"
              matStepperNext
            >
              Yes
            </button>
          </div>
        </mat-step>
        <mat-step label="Confirmation">
          <p class="font-bold">Confirmation</p>
          <p class="marT16 marB32">
            Will any interview be scheduled using
            <strong class="font-red">TEAMS</strong>?
          </p>
          <div class="d-flex marT16 justify-content-between">
            <button
              mat-button
              class="DSA_wb_miniSecondary-btn"
              matStepperPrevious
            >
              Back
            </button>
            <div>
              <button
                mat-button
                class="DSA_wb_miniSecondary-btn"
                [mat-dialog-close]="'No'"
              >
                No
              </button>
              <button
                mat-button
                class="DSA_wb_miniPrimary-btn marL8"
                [mat-dialog-close]="'Yes'"
              >
                Yes
              </button>
            </div>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </ng-container>
  <ng-template #showNoStepper>
    <!-- show no templates added warning -->
    <div
      *ngIf="addedTemplateCategories.length === 0; else showTeamsConfirmation"
      class="marTB16"
    >
      <p>
        <strong class="font-red">
          No mail templates are added to the event.
        </strong>
      </p>
     <templates-warning></templates-warning>
      <div mat-dialog-actions class="d-flex justify-content-end">
        <button
          mat-button
          (click)="closeDialog()"
          class="DSA_wb_miniSecondary-btn"
        >
          Close
        </button>
      </div>
    </div>
    <ng-template #showTeamsConfirmation>
      <div mat-dialog-content>
        <p class="marTB16">
          Will any interview be scheduled using
          <strong class="font-red">TEAMS</strong>?
        </p>
      </div>
      <div mat-dialog-actions class="d-flex justify-content-end">
        <button
          mat-button
          [mat-dialog-close]="'No'"
          class="DSA_wb_miniSecondary-btn"
        >
          No
        </button>
        <button
          mat-button
          [mat-dialog-close]="'Yes'"
          class="DSA_wb_miniPrimary-btn"
        >
          Yes
        </button>
      </div>
    </ng-template>
  </ng-template>
</ng-container>

<!-- if some error occurred -->
<ng-container *ngIf="!isLoading && isError">
  <div mat-dialog-content>
    <p>Some error occurred. Please try later.</p>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-end">
    <button mat-button (click)="closeDialog()" class="DSA_wb_miniSecondary-btn">
      Close
    </button>
  </div>
</ng-container>
