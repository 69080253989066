import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CreateEventStateStorageService } from 'src/app/modules/iwp-event/infy-create-event/create-event-state-storage.service';
import { CandidateService } from 'src/app/services/candidate.service';
import { EventService } from 'src/app/services/event.service';
import { InterviewService } from 'src/app/services/interview.service';
import { MsteamsService } from 'src/app/services/msteams.service';
import { SharedService } from 'src/app/services/shared.service';
import { InterviewPlatformEnum } from 'src/app/shared/model/interview-platform.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-meeting-link-action-renderer',
  templateUrl: './meeting-link-action-renderer.component.html',
  styleUrls: ['./meeting-link-action-renderer.component.scss'],
})
export class MeetingLinkActionRendererComponent implements AgRendererComponent {
  params: ICellRendererParams;
  meetingPlatform: InterviewPlatformEnum;
  meetingDetails: string | { interviewers: Array<any>; candidate: any };
  loading: boolean = false;
  linkNotPresent: boolean = false;
  isUpcoming: boolean = false;
  constructor(
    private router: Router,
    private interviewService: InterviewService,
    private sharedService: SharedService,
    private msteamsService: MsteamsService,
    private eventService: EventService,
    private candidateService: CandidateService,
    private createEventStateStorage: CreateEventStateStorageService
  ) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.isUpcoming = params.data?.interviewStatus === 'UPCOMING';
    this.meetingPlatform = params.data?.source.title;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  getMeetingLink() {
    this.loading = true;
    this.interviewService
      .getInterviewLink(this.params.data.id)
      .subscribe((res) => {
        if (res.length > 0) {
          const meetingData = res[0];
          this.checkPlatformAndSetState(meetingData);
        }
        this.loading = false;
      });
  }

  setEnablexMeetingLinkObj(meetingData: any) {
    // multiple panelists will have different enablex link, candidate will have different link
    let meetingDataObj = {
      interviewers: [],
      candidate: null,
    };
    meetingData?.forEach((element) => {
      if (element.meetingUrl) {
        if (element.participantType === 'INTERVIEWER') {
          meetingDataObj.interviewers.push(element);
        } else if (element.participantType === 'CANDIDATE') {
          meetingDataObj.candidate = element;
        }
      }
    });
    return meetingDataObj;
  }

  checkPlatformAndSetState = (meetingData) => {
    if (meetingData.participants[0].meetingUrl) {
      if (this.meetingPlatform === 'TEAMS') {
        this.meetingDetails = meetingData.participants[0].meetingUrl;
      } else if (this.meetingPlatform === 'ENABLEX') {
        this.meetingDetails = this.setEnablexMeetingLinkObj(
          meetingData.participants
        );
      }
    } else {
      this.linkNotPresent = true;
      this.sharedService.openSnackBar(
        'Meeting Link Not found. Please click on Regenerate Meeting Link',
        '',
        3000
      );
    }
  };

  checkPlatformAndGenerateLink = () => {
    if (this.meetingPlatform === 'ENABLEX') {
      this.regenerateMeetingLink();
    } else if (this.meetingPlatform === 'TEAMS') {
      this.msteamsService.checkAuthorizationRequired().subscribe(
        (res) => {
          // if authorization is required
          if (res.requiresAuthorization) {
            let redirect_uri = environment.msauthRedirectUrl;
            let sessionStorageObj = {
              redirectTo: this.router.url,
              eventId: this.eventService.selectedEventId,
              candidate: this.candidateService.getSelectedCandidate(),
              activeTabLink: this.createEventStateStorage.activeTabLink,
              interviewIdToRegenerateLink: this.params.data.id,
            };
            window.sessionStorage.setItem(
              'appState',
              JSON.stringify(sessionStorageObj)
            );
            window.open(redirect_uri, '_self');
          } else {
            // if auth not required, proceed as usual
            this.regenerateMeetingLink();
          }
        },
        (err) => {
          this.sharedService.openSnackBar('Some error occurred', '', 3000);
        }
      );
    }
  };

  regenerateMeetingLink() {
    this.loading = true;
    let interviewId = this.params.data.id;
    this.interviewService.regenerateInterviewLink(interviewId).subscribe(
      (res) => {
        if (res[0]) this.checkPlatformAndSetState(res[0]);
        this.sharedService.openSnackBar(
          'Link Regenerated Successfully',
          '',
          3000
        );
        this.linkNotPresent = false;
        this.loading = false;
      },
      (err) => {
        this.sharedService.openSnackBar('Some error occured', '', 3000);
        this.loading = false;
      }
    );
  }
}
