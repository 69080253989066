<h1 mat-dialog-title>Eligibility</h1>
<div mat-dialog-content>

<div class="row">
    <div class="col-6" style="height: 100px; width: 100px;">
        <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel" floatLabel="never">
            <p>Are you Above 18 years?</p>
            <mat-select disableOptionCentering  panelClass="DSA_wb-custom-select-panel" class="DSA_wb-custom-select">
                <mat-option value="UG">Yes</mat-option>
              <mat-option value="PG">No</mat-option>
            </mat-select>
            
        </mat-form-field>

    </div>

    <div class="col-6" style="height: 100px;width: 100px;">
        <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel" floatLabel="never">
            <p>Is your family income less than 5 Lakh</p>
            <mat-select disableOptionCentering  panelClass="DSA_wb-custom-select-panel" class="DSA_wb-custom-select">
                <mat-option value="UG">Yes</mat-option>
              <mat-option value="PG">No</mat-option>
            </mat-select>
            
        </mat-form-field>
        
    </div>

</div>
</div>
<div mat-dialog-actions>
  <button mat-button class="DSA_wb_miniPrimary-btn marR24" mat-dialog-close cdkFocusInitial>Proceed</button>
  <button mat-button class="DSA_wb_miniSecondary-btn" mat-dialog-close>Cancel</button>
</div>