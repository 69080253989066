import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormioComponent, FormioOptions } from 'angular-formio';
import { forkJoin, Observable } from 'rxjs';
import {
  candidateRejectionReasonTech,
  formIOPreviewOptions,
} from 'src/app/app.constants';
import { FileService } from 'src/app/services/file.service';
import { FormMsService } from 'src/app/services/form-ms.service';
import { SharedService } from 'src/app/services/shared.service';
import { TimerService } from 'src/app/services/timer.service';
import { onHoldReasonList, rejectionReasonList } from 'src/app/app.constants';
import {
  FeedbackStatus,
  EvaluationFeedback,
  JobEvaluationFormDTO,
} from 'src/app/shared/model/form-ms.model';
import { ConfirmationComponent } from '../../../shared/components/confirmation/confirmation.component';
import { ToastrService } from 'ngx-toastr';
import { ExperienceType } from 'src/app/shared/model/job-role.model';
import { InterviewPlatformEnum } from 'src/app/shared/model/interview-platform.model';
import { MpJobService } from 'src/app/services/mp-job.service';
import { FinalActionConfirmationDialogComponent } from 'src/app/infy-components/infy-recruiter-feedback/final-action-confirmation-dialog/final-action-confirmation-dialog.component';
import { ApplicationStatus } from 'src/app/shared/model/job-applicants.model';

@Component({
  selector: 'app-infy-formio-evaluation',
  templateUrl: './infy-formio-evaluation.component.html',
  styleUrls: ['./infy-formio-evaluation.component.scss'],
})
export class InfyFormioEvaluationComponent implements OnInit, OnChanges {
  @Input() interviewId: number;
  @Input() readOnly: boolean = false;
  @Input() jobId: number;
  @Input() jobTitle?: string = '';
  @Input() formType: string;
  @Input() experienceType: ExperienceType = 'LATERAL';
  @Input() cirId: number;
  @Input() templateId: number;
  @Input() platformId: InterviewPlatformEnum;
  @Input() timerInterview: any;
  @Input() candidateSource: string;
  @Input() sentBackToPanelist = false;
  @Input() applicationStatus: string;
  @Input() feedback: string = 'Feedback not submitted.';
  @Input() showActions: boolean = true;
  @Input() showTitle: boolean = true;
  @Input() evaluationData: { data: any };
  rejectionReasonList = rejectionReasonList;
  onHoldReasonList = onHoldReasonList;
  candidateRejectionReasonTech = candidateRejectionReasonTech;

  evaluationForm: JobEvaluationFormDTO;
  isLoading: boolean = false;

  options: FormioOptions = formIOPreviewOptions;

  isVisible: boolean = false;
  statusData = [
    'VERIFIER_APPROVED',
    'UNDERTAKING_REQUESTED',
    'PROVISIONAL_APPROVED',
    'UNDER_VERIFICATION',
  ];
  isFinalPage: boolean = false;
  isFormIOResponseValid: boolean = false;
  resultReason: any = [];
  savedOrPrevFeedbackJSON: { data: any } = { data: null }; //to auto-fill response (if present) in form

  files: Array<File> = [];
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;

  finalResultOptions: any[] = [
    { id: 1, viewValue: 'SELECTED', code: 'SELECTED' },
    { id: 2, viewValue: 'REJECTED', code: 'REJECTED' },
    { id: 3, viewValue: 'ON HOLD', code: 'ON_HOLD' },
  ];

  storageKey: any;
  autoSave = false;

  scholarshipAmount: number;

  evaluationFeedbackForm: FormGroup;

  isFirstLoad: boolean = true;
  isFeedbackNotPresent: boolean = false;

  formIOInstance;
  noUploadedImages = false;

  @Output() jobIdChange = new EventEmitter<number>();
  @Output() sendScholarshipAmount = new EventEmitter<number>();

  constructor(
    private formMsService: FormMsService,
    private timerService: TimerService,
    private dialog: MatDialog,
    private fileService: FileService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private jobService: MpJobService,
    private sharedService: SharedService
  ) {
    this.options = {
      fileService: this.fileService,
    };
  }

  ngOnInit(): void {
    this.noUploadedImages = false;

    this.statusData.forEach((element) => {
      if (element === this.applicationStatus) {
        this.isVisible = true;
      }
    });

    this.evaluationFeedbackForm = this.formBuilder.group({
      id: [],
      interactionId: [this.interviewId],
      interactionGroupingType: ['APPLICATION'],
      interviewId: [this.interviewId],
      cirId: [this.cirId],
      feedbackData: [],
      panelistAcceptance: [false],
      evaluatorAcceptance: [false, [Validators.required]],

      finalResult: [null],
      evaluationForm: this.formBuilder.group({
        id: [],
      }),
      feedbackStatus: [],
      rejectionReason: [null],
      verifiedAmount: new FormControl(this.scholarshipAmount, [
        Validators.max(100000),
      ]),
    });

    console.log(this.interviewId + ' ' + this.jobId + ' ' + this.readOnly);
    // this.readOnly=true;
    this.evaluationFeedbackForm.controls.finalResult.valueChanges.subscribe(
      (result: any) => {
        this.handleResultChange(result);
      }
    );

    if (this.readOnly) {
      // for previous feedbacks
      this.getFinalFeedbackByInterviewId();
    } else {
      // for upcoming/sent back to panelist interviews
      this.getLatestSavedFeedback();
    }
  }

  // get amount(){
  //   return this.evaluationFeedbackForm.get('verifiedAmount');
  // }

  handleResultChange = (result: any) => {
    this.evaluationFeedbackForm.controls.rejectionReason.setValue(null);

    if (result == 'REJECTED') {
      this.resultReason = this.rejectionReasonList;
    } else if (result == 'ON_HOLD') {
      this.resultReason = this.onHoldReasonList;
    }
  };

  getLocallySavedFeedback = (
    storageKey
  ): { updatedAt: string; data: EvaluationFeedback } => {
    const locallySavedFeedback = localStorage.getItem(storageKey);
    if (locallySavedFeedback) return JSON.parse(locallySavedFeedback);
    else return null;
  };

  getLatestSavedFeedback = () => {
    this.isLoading = true;
    this.formMsService.getFeedbackByInterviewId(this.interviewId).subscribe({
      next: async (savedFeedback: EvaluationFeedback) => {
        // if feedback exists in DB
        if (savedFeedback) {
          console.log(savedFeedback);
          // console.log(savedFeedback.evaluationForm?.jobRoleId);
          console.log(savedFeedback.evaluationForm?.evaluationEventId);
          // get evaluation form for the job for which feedback was saved
          const evalForm = await this.getLatestEvaluationFormForJob(
            // savedFeedback.evaluationForm.jobRoleId
            savedFeedback.evaluationForm.evaluationEventId
          );
          console.log(evalForm);
          //if currently selected job id is not same as the job for which feedback is saved
          if (savedFeedback.evaluationForm.evaluationEventId !== this.jobId) {
            this.jobId = savedFeedback.evaluationForm.evaluationEventId;
            console.log(this.jobId);
            // change job in parent component to show this saved feedback to user
            this.jobIdChange.emit(
              savedFeedback.evaluationForm.evaluationEventId
            );
            // this.toastr.show(
            //   `Showing previously saved feedback for <strong>'${evalForm.title}'</strong>. If you wish to submit feedback for another job role, please select it from the dropdown above.`,
            //   '',
            //   {
            //     timeOut: 10000,
            //     positionClass: 'toast-bottom-center',
            //     toastClass: 'ngx-toastr toast-custom',
            //     enableHtml: true,
            //   }
            // );
          }
          // if feedback is saved partially, set state and auto-fill form
          if (savedFeedback.feedbackStatus === 'PARTIAL') {
            console.log(this.storageKey);
            const locallySavedFeedbackForJob = this.getLocallySavedFeedback(
              this.storageKey
            );
            console.log(locallySavedFeedbackForJob);
            // if feedback present in local storage and is latest feedback (recent than last modified date in DB)
            if (
              locallySavedFeedbackForJob &&
              new Date(locallySavedFeedbackForJob?.updatedAt) >
                new Date(savedFeedback.lastModifiedDate)
            ) {
              console.log('hiii');
              // set state using localstorage data
              const feedback: EvaluationFeedback =
                locallySavedFeedbackForJob.data;
              this.savedOrPrevFeedbackJSON = feedback.feedbackData;
              this.evaluationFeedbackForm.patchValue({
                ...feedback,
                evaluationForm: { id: evalForm.id },
              });
            } else {
              // set state using api data
              this.evaluationFeedbackForm.patchValue({
                ...savedFeedback,
                evaluationForm: { id: evalForm.id },
              });
              this.savedOrPrevFeedbackJSON = savedFeedback.feedbackData;
            }
            this.autoSave = true;
          } else {
            /* if feedback was submitted previously (send back to panelist case)
            - store feedback in application state
            - create new record in DB for new (PARTIAL) feedback 
            */
            this.evaluationFeedbackForm.patchValue({
              interactionId: savedFeedback.interactionId,
              cirId: savedFeedback.cirId,
              feedbackData: savedFeedback.feedbackData,
              panelistAcceptance: savedFeedback.panelistAcceptance,
              finalResult: savedFeedback.finalResult,
              rejectionReason: savedFeedback.rejectionReason,
              evaluationForm: { id: evalForm.id },
            });
            this.savedOrPrevFeedbackJSON = savedFeedback.feedbackData;
            this.createFeedback('PARTIAL');
          }
        } else {
          // if feedback doesn't exist, create a record in DB with current job evaluation form
          await this.getLatestEvaluationFormForJob(this.jobId);
          this.evaluationFeedbackForm
            .get('evaluationForm.id')
            .setValue(this.evaluationForm.id);
          this.createFeedback('PARTIAL');
        }

        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
      },
    });
  };

  // for previous/readonly feedbacks
  getFinalFeedbackByInterviewId = () => {
    console.log(this.interviewId);
    this.isLoading = true;
    const body = {
      feedbackStatus: 'FINAL',
      interactionGroupingId: this.cirId,
      formType: this.formType ? this.formType : 'TECHNICAL',
    };
    this.formMsService
      .getFeedbackByInterviewId(this.interviewId, body)
      .subscribe({
        next: (res: EvaluationFeedback) => {
          /* if feedback is present */
          if (res) {
            this.evaluationForm = res.evaluationForm;
            this.savedOrPrevFeedbackJSON = res.feedbackData;

            // console.log(this.savedOrPrevFeedbackJSON.data.scholarshipAmount);
            // this.scholarshipAmount = this.savedOrPrevFeedbackJSON.data.scholarshipAmount;
            // this.sendScholarshipAmount.emit(this.scholarshipAmount);
          } else {
            this.isFeedbackNotPresent = true;
          }
          this.isLoading = false;
          console.log(this.isLoading);
        },
        error: (err) => {
          this.isLoading = false;
        },
      });
  };

  getLatestEvaluationFormForJob = (jobId): Promise<JobEvaluationFormDTO> => {
    return new Promise((resolve, reject) => {
      this.isLoading = true;
      this.isFinalPage = false;
      this.evaluationForm = null;

      this.formMsService
        .getEvaluationForm(0, 1, '', {
          formType: this.formType,
          evaluationEventId: jobId,
          isActive: true,
          formData: true,
        })
        .subscribe({
          next: (res: any) => {
            console.log(res);
            if (res.content.length > 0) {
              // store evaluation form to display in UI
              this.evaluationForm = res.content[0];
              // change evaluation form id in feedback form
              this.evaluationFeedbackForm.controls.evaluationForm.patchValue(
                this.evaluationForm
              );

              /* check if current page is final - to show submit section
              - if type is 'form' - single page
              - if type is 'wizard' (multi-page) but contains one page only
              */
              const evaluationTemplateJSON =
                this.evaluationForm.templateEvaluationForm.formData;
              if (
                evaluationTemplateJSON?.display === 'form' ||
                (evaluationTemplateJSON?.display === 'wizard' &&
                  evaluationTemplateJSON?.components?.length === 1)
              ) {
                this.isFinalPage = true;
              }

              resolve(res.content[0]);
            }
            this.isLoading = false;
          },
          error: (err) => {
            this.toastr.show('Some error occurred. Please try again', '', {
              timeOut: 3000,
              positionClass: 'toast-bottom-center',
              toastClass: 'ngx-toastr toast-error',
            });
            this.isLoading = false;
            reject();
          },
        });
    });
  };

  async ngOnChanges(changes: SimpleChanges) {
    this.storageKey = `${this.interviewId}_${this.jobId}`;

    /* if job is changed for second time
     - first time job change is handled in ngOnInit
    */
    if (!changes?.jobId?.isFirstChange()) {
      /* check if currently stored evaluation form is of a separate job ID
        - to avoid fetching evaluation form again when it's present already (in case of saved feedback for another job, jobId emitted from formio after fetching eval form)
      */
      if (this.evaluationForm?.evaluationEventId !== this.jobId) {
        // fetch evaluation form for currently selected job ID
        await this.getLatestEvaluationFormForJob(this.jobId);

        const locallySavedFeedbackForJob = this.getLocallySavedFeedback(
          this.storageKey
        );
        if (locallySavedFeedbackForJob) {
          const feedback: EvaluationFeedback = locallySavedFeedbackForJob.data;
          this.savedOrPrevFeedbackJSON = feedback.feedbackData;
          this.evaluationFeedbackForm.controls.feedbackData.patchValue(
            this.savedOrPrevFeedbackJSON
          );
        } else {
          this.savedOrPrevFeedbackJSON = null;
          this.evaluationFeedbackForm.controls.feedbackData.patchValue(null);
        }
      }
    }
  }

  onFormReady = (e: FormioComponent) => {
    // save formio instance to check form validity on first load
    if (!this.readOnly) this.formIOInstance = e.formio;
    // console.log(this.savedOrPrevFeedbackJSON, this.evaluationData);
    if (
      this.formType === 'VERIFICATION' &&
      !this.savedOrPrevFeedbackJSON?.data?.projectTitle &&
      !this.savedOrPrevFeedbackJSON?.data?.category &&
      !this.savedOrPrevFeedbackJSON?.data?.teamName &&
      !this.savedOrPrevFeedbackJSON?.data?.evaluator
    ) {
      this.savedOrPrevFeedbackJSON = this.evaluationData;
    }
  };

  onFeedbackChange(event) {
    if (event.data) {
      // on first load, check form validity
      if (!event.isModified) {
        this.isFormIOResponseValid = this.formIOInstance
          ? this.formIOInstance.checkValidity(event.data)
          : false;
      } else {
        // check validity once form is modified
        this.evaluationFeedbackForm.controls.feedbackData.setValue({
          data: event.data,
        });
        this.savedOrPrevFeedbackJSON = { data: event.data };
        this.isFormIOResponseValid = event.isValid;
      }
    }
  }

  handleFileChange = (files: Array<File>) => {
    this.files = files;
  };

  handleNoImagesChange = (noUploadedImages: boolean) => {
    // Sents True flag for  No images
    this.noUploadedImages = noUploadedImages;
  };
  handleDeclarationChange = (isAccepted: boolean) => {
    this.evaluationFeedbackForm.controls.panelistAcceptance.setValue(
      isAccepted
    );
  };

  validateFiles = (): boolean => {
    if (['WEBEX', 'ENABLEX', 'TEAMS'].includes(this.platformId)) {
      if (this.files.length == 0) {
        // if not sent back to panel (UPCOMING) or sent back and uploaded images are also deleted
        if (this.noUploadedImages || !this.sentBackToPanelist) {
          this.toastr.show('Please Upload Snapshots', '', {
            timeOut: 3000,
            positionClass: 'toast-bottom-center',
            toastClass: 'ngx-toastr toast-error',
          });
          return false;
        }
      }
    }
    return true;
  };

  submitFeedback = () => {
    if (this.validateFiles()) {
      const dialogRef = this.dialog.open(ConfirmationComponent, {
        data: {
          title: `Submit Feedback Confirmation`,
          text: this.jobTitle
            ? `Are you sure you want to submit the feedback for <b>${this.jobTitle}</b> role for the candidate?`
            : `Are you sure you want to submit the feedback?`,
        },
        autoFocus: false,
        backdropClass: 'backdrop-background',
        maxWidth: '550px',
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res === 'Yes') {
          this.isLoading = true;
          if (this.files.length > 0) {
            let formData = new FormData();
            const specData = {
              interviewId: this.interviewId,
              tenantId: localStorage.getItem('tenantId'),
            };
            formData.append('specData', JSON.stringify(specData));
            this.files.forEach((file) => {
              formData.append('files', file);
            });
            this.fileService.uploadSnapshotBulk(formData).subscribe({
              next: () => {
                this.submitFinalFeedback();
              },
              error: () => {
                this.isLoading = false;
                this.toastr.show('Snapshot upload failed!', '', {
                  timeOut: 3000,
                  positionClass: 'toast-bottom-center',
                  toastClass: 'ngx-toastr toast-error',
                });
              },
            });
          } else {
            // for OFFLINE, TELEPHONIC cases
            this.submitFinalFeedback();
          }
        }
      });
    }
  };

  submitFinalFeedback = () => {
    // update if it is saved partially previously
    if (this.evaluationFeedbackForm.controls.id.value) {
      this.updateFeedback('FINAL');
    } else {
      // create feedback
      this.createFeedback('FINAL');
    }
  };

  // to create feedback initially
  async createFeedback(feedbackStatus: FeedbackStatus) {
    const payload: EvaluationFeedback = {
      ...this.evaluationFeedbackForm.getRawValue(),
      feedbackStatus,
    };
    return new Promise((resolve, reject) => {
      this.formMsService.createEvaluationFeedback(payload).subscribe({
        next: (res: EvaluationFeedback) => {
          if (feedbackStatus === 'FINAL') {
            this.clearSavedFeedbacksFromLocalStorage();
            this.autoSave = false;
            this.timerService.endDynamicEventTimer(this.timerInterview);
            this.sharedService.openSuccessSnackBar(
              'Feedback submitted successfully!',
              ''
            );
            this.isLoading = false;
          } else if (feedbackStatus === 'PARTIAL') {
            this.autoSave = true;
            this.evaluationFeedbackForm.patchValue(res);
          }
          resolve(res);
        },
        error: (err) => {
          this.sharedService.openErrorSnackBar(
            err?.error?.title
              ? err?.error?.title
              : 'Some error occurred. Feedback save failed',
            ''
          );
          this.isLoading = false;
          reject();
        },
      });
    });
  }

  updateFeedback = (feedbackStatus: FeedbackStatus) => {
    const payload: EvaluationFeedback = {
      ...this.evaluationFeedbackForm.getRawValue(),
      feedbackStatus,
    };
    return new Promise((resolve, reject) => {
      this.formMsService.updateEvaluationFeedback(payload).subscribe({
        next: (res) => {
          this.evaluationFeedbackForm.patchValue(res);
          if (feedbackStatus === 'FINAL') {
            this.clearSavedFeedbacksFromLocalStorage();
            this.autoSave = false;
            this.timerService.endDynamicEventTimer(this.timerInterview);
            this.sharedService.openSuccessSnackBar(
              'Feedback submitted successfully!',
              ''
            );

            this.isLoading = false;
          }
          resolve(res);
        },
        error: (err) => {
          this.sharedService.openErrorSnackBar(
            err?.error?.title
              ? err?.error?.title
              : 'Some error occurred. Feedback save failed',
            ''
          );
          this.isLoading = false;
          reject();
        },
      });
    });
  };

  clearSavedFeedbacksFromLocalStorage = () => {
    let feedbackKeys = [];
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).startsWith(`${this.interviewId}_`)) {
        feedbackKeys.push(localStorage.key(i));
      }
    }
    for (let key of feedbackKeys) {
      localStorage.removeItem(key);
    }
  };

  autoSaveInDB = (storeItem: EvaluationFeedback): Observable<any> => {
    // console.log(storeItem);
    return this.formMsService.updateEvaluationFeedback(storeItem);
  };

  onAutoSaveInDB = () => {
    // show toaster to indicate partial save of data in DB
    // toaster used because timer in dynamic events uses snackbar, only one snackbar visible at a time
    this.toastr.show('Feedback saved', '', {
      timeOut: 3000,
      positionClass: 'toast-bottom-center',
      toastClass: 'ngx-toastr toast-success',
    });
  };

  handleNextClick = (e) => {
    // check if current page is the last page
    const formData = this.evaluationForm.templateEvaluationForm.formData;
    if (formData?.display === 'wizard') {
      if (formData.components?.length - 1 === e.page) {
        this.isFinalPage = true;
      }
    }
  };

  handlePrevClick = () => {
    this.isFinalPage = false;
  };

  async submitFeedbackAndMarkApplicationStatus(applicationStatusReqBody) {
    /* if feedback status is final, form is readonly, and non-editable at backend too. 
      Save only when status is partial. */
    if (
      this.evaluationFeedbackForm.controls.feedbackStatus.value === 'PARTIAL'
    ) {
      if (this.evaluationFeedbackForm.controls.id.value) {
        await this.updateFeedback('FINAL');
      } else {
        // create feedback
        await this.createFeedback('FINAL');
      }
    }
    this.updateJobApplicationStatus(applicationStatusReqBody);
  }

  handleFinalActionClick = (applicationStatus: ApplicationStatus) => {
    const resultText =
      applicationStatus === 'VERIFIER_APPROVED' ? 'approve' : 'reject';
    let message = `Are you sure you want to <strong class='font-red'>${resultText}</strong> this application?`;
    const dialog = this.dialog.open(FinalActionConfirmationDialogComponent, {
      data: {
        messageToShow: message,
        finalAction: applicationStatus,
      },
      backdropClass: 'backdrop-background',
      autoFocus: false,
      minWidth: 500,
    });

    dialog
      .afterClosed()
      .subscribe((response: { takeAction: boolean; remarks: string }) => {
        if (response.takeAction) {
          const reqBody = {
            applicationStatus: applicationStatus,
            applicationSubStatus: null,
            actionComments: response.remarks,
            actionPayload: {
              ...this.evaluationFeedbackForm.getRawValue(),
              feedbackStatus: 'FINAL',
            },
          };
          // saving feedback to formms using postms action api 'actionPayload'
          this.updateJobApplicationStatus(reqBody);
        }
      });
  };

  private updateJobApplicationStatus(reqBody: any) {
    this.isLoading = true;
    this.jobService
      .updateJobApplicationStatus(this.interviewId, reqBody)
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          this.readOnly = true;
          this.sharedService.openSuccessSnackBar(
            'Application verified successfully.',
            ''
          );
        },
        error: (err) => {
          this.isLoading = false;
          this.sharedService.openErrorSnackBar(
            err?.error?.title
              ? 'Status change failed. ' + err.error.title.replace(/_/g, ' ')
              : 'Something went wrong',
            ''
          );
        },
      });
  }

  //  updateScholarshipAmount = (updateItems:EvaluationFeedback)=>{
  //      console.log(updateItems);
  //      console.log(this.evaluationForm);
  //      console.log(this.savedOrPrevFeedbackJSON);
  // }
}
