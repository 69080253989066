import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private headers = new HttpHeaders().set('content-type', 'application/json');
    // .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
   

  endPoint: string = environment.interviewServiceUrl;

  constructor(private http: HttpClient) {}

  getSettings = (category, categoryId) => {
    return this.http.get(
      this.endPoint + `api/iwp-settings/${category}/${categoryId}`,
      { headers: this.headers }
    );
  };
  setSettings = (formData) => {
    return this.http.post(this.endPoint + `api/iwp-settings/save`, formData, {
      headers: this.headers,
    });
  };
}
// for api call
// this.endPoint + 'api/iwp-settings/{category}/{categoryId}';
