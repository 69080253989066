<h1 mat-dialog-title>Create Evaluation Template</h1>
<div mat-dialog-content>
  <form [formGroup]="createTemplateForm">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <mat-label>Template Name</mat-label>
          <input matInput type="text" formControlName="name" required />
          <mat-error>Required*</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <mat-label>Form Type</mat-label>
          <mat-select formControlName="formType" required>
            <mat-option *ngFor="let formType of formTypes" [value]="formType">
              {{ formType }}
            </mat-option>
          </mat-select>
          <mat-error>Required*</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12 marT8">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <mat-label>Template Description</mat-label>
          <input matInput type="text" formControlName="description" />
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <button mat-button [mat-dialog-close]="'No'" class="DSA_wb_miniSecondary-btn">
    Cancel
  </button>
  <button
    mat-button
    (click)="saveTemplate()"
    class="DSA_wb_miniPrimary-btn"
    [disabled]="createTemplateForm.invalid || isLoading"
  >
    {{ isLoading ? "Creating..." : "Create" }}
  </button>
</div>
