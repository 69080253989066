import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { CandidateInfoService } from '../services/candidate-info.service';
import { IAccountType } from 'src/app/shared/model/account.model';
import { SharedService } from 'src/app/services/shared.service';
import { MatStepper } from '@angular/material/stepper';
import { CandidateProfileService } from './candidate-profile.service';
import { Router } from '@angular/router';
import { allDocumentListByCategory } from 'src/app/app.constants';
import { take } from 'rxjs/operators';

// *ComponentPath Enum containing child component path name which makes them accessible by string
//  and index both.
export enum componentPath {
  'personal-info',
  'education-info',
  'guardian-details',
  'scholarship-details',
}
@Component({
  selector: 'app-candidate-profile',
  templateUrl: './candidate-profile.component.html',
  styleUrls: ['./candidate-profile.component.scss'],
})
export class CandidateProfileComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  @Output() applicantIdSent = new EventEmitter();
  @Input() documentList = null;
  @Input() opportunityId = null;
  @Input() maxFamilyAnnualIncome = null;
  @Input() checkForLocalStepState?: boolean = false;
  @ViewChild('stepper') private myStepper: MatStepper;

  userAccDetails: IAccountType;
  stepChangeFlag: boolean = true;
  routeChangeFlag: boolean = false;
  stepperSetUpFLag: boolean = true;

  isLoadingAll: boolean = false;
  isLoadingSteps: boolean = true;
  isCandidateInfoPresent: boolean = null;
  errorPage = false;
  candidateInfoId: number = null;

  allDocumentListByCategory = allDocumentListByCategory;
  documentListByCategory = {
    Personal: [],
    Educational: [],
    Scholarship: [],
    Contact: [],
  };
  constructor(
    private accountService: AccountService,
    private candidateInfoService: CandidateInfoService,
    private sharedService: SharedService,
    private candidateProfileService: CandidateProfileService,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {
    if (this.router.url.includes('candidate-profile')) {
      const state = this.router.getCurrentNavigation()?.extras?.state;
      this.checkForLocalStepState = state ? state['checkForLocalStepState'] : false;
    }
  }
  ngOnDestroy(): void {
    this.candidateProfileService.resetValues();
  }
  ngAfterViewChecked(): void {
    // On AfterViewChecked, update stepper based on local state and store stepper in service for
    // further manipulation by child components.
    if (this.myStepper && this.stepperSetUpFLag) {
      this.candidateProfileService.stepper = this.myStepper;
      //We will show loading till stepper is setup. 
      this.isLoadingAll = true;
      if(this.opportunityId) {
        //If Applying for scholarship, check if application details fetched,
        if (!this.candidateProfileService.applicationDetailsFetched) {
          //If not fetched, wait for it to be fetched. till then show loading. and set stepperSetupFlag 
          //to false.
          this.stepperSetUpFLag = false;
          this.candidateProfileService.uploadedDocument$
          .pipe(take(1))
          .subscribe((docList) => {
            //Once application details fetched, set stepperSetupFlag to true and  loading to false to
            // trigger afterViewChecked and now it will go to else block and setStepper will be called.
            this.stepperSetUpFLag = true;
            this.isLoadingAll = false;
          })
        } 
        else {
          //setStepper will be called and it will setUp stepper and then set loading to false.
          this.setStepper();
        }
      }
      else {
        this.setStepper();
      }
    }
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.documentList?.forEach((doc) => {
      let curDoc = this.allDocumentListByCategory[doc];
      this.documentListByCategory?.[curDoc?.category]?.push(doc);
    });

    this.candidateProfileService.documentListByCategory =
      this.documentListByCategory;
    this.candidateProfileService.opportunityId = this.opportunityId;
    this.candidateProfileService.maxFamilyAnnualIncome =
      this.maxFamilyAnnualIncome;
    
    this.userAccDetails = this.accountService.getAccountLocal();

    this.getCandidateInfo();
  }

  getCandidateInfo() {
    this.errorPage = false;
    this.candidateInfoId = this.candidateProfileService.getCandidateInfoId();
    if (this.candidateProfileService.candidateDetailsFromViewALL) {
      if (!this.candidateInfoId) {
        this.isCandidateInfoPresent = false;
        this.candidateProfileService.applicationDetailsFetched = true;
        this.candidateProfileService.uploadedDocument$.next([]);
        this.candidateProfileService.candidateInfoId$
          .pipe(take(1))
          .subscribe((candidateInfoId) => {
            this.applicantIdSent.emit(candidateInfoId);
            sessionStorage.setItem('seekerId', String(candidateInfoId));
        });
      }
      else {
        this.isCandidateInfoPresent = true;
        this.applicantIdSent.emit(this.candidateInfoId);
        this.getApplicationInfo();
      }
      this.isLoadingSteps = false;
    }
    else {
      // this.getCandidateInfoUsingAPI();
      this.postApplication();
    }
  }

  // getCandidateInfoUsingAPI() {
  //   this.candidateInfoService.getCandidateProfileUIDTOForCandidate().subscribe({
  //     next: (response: any) => {
  //       if (response) {
  //         this.isCandidateInfoPresent = true;
  //         this.candidateInfoId = response?.candidateInfoId;
  //         this.applicantIdSent.emit(this.candidateInfoId);
  //         sessionStorage.setItem('seekerId', String(this.candidateInfoId));
  //         // Sending data to service to be accessed by child routes.
  //         this.candidateProfileService.setCandidateProfile(response);
  //         this.getApplicationInfo();
  //       } else {
  //         this.isCandidateInfoPresent = false;
  //         if (this.opportunityId) {
  //           this.candidateProfileService.applicationDetailsFetched = true;
  //           this.candidateProfileService.uploadedDocument$.next([]);
  //         }
  //         this.candidateProfileService.candidateInfoId$
  //           .pipe(take(1))
  //           .subscribe((candidateInfoId) => {
  //             this.applicantIdSent.emit(candidateInfoId);
  //             sessionStorage.setItem('seekerId', String(candidateInfoId));
  //         });
  //       }
  //       this.isLoadingSteps = false;
  //     },
  //     error: (err) => {
  //       if (err?.status === 404) {
  //         this.isCandidateInfoPresent = false;
  //         if (this.opportunityId) {
  //           this.candidateProfileService.applicationDetailsFetched = true;
  //           this.candidateProfileService.uploadedDocument$.next([]);
  //         }
  //         this.candidateProfileService.setCandidateInfoId(null);
  //         this.candidateProfileService.candidateInfoId$
  //           .pipe(take(1))
  //           .subscribe((candidateInfoId) => {
  //             this.applicantIdSent.emit(candidateInfoId);
  //             sessionStorage.setItem('seekerId', String(candidateInfoId));
  //         });
  //         this.isLoadingSteps = false;
  //       } else {
  //         this.errorPage = true;
  //         this.isLoadingAll = false;
  //         this.isLoadingSteps = false;
  //       }
  //     },
  //   });
  // }

  getApplicationInfo() {
    if (this.opportunityId) {
      if (this.candidateProfileService.applicationAPIResponseFromViewALL) {
        this.setApplicationInfo(this.candidateProfileService.applicationAPIResponseFromViewALL);
      }
      else if (this.candidateProfileService.applicationAPIResponseFromViewALL === null) {
        this.candidateProfileService.applicationDetailsFetched = true;
        this.candidateProfileService.uploadedDocument$.next([]);
      }
      else {
        this.candidateInfoService
          .postApplicationByOpportunityId(
            this.opportunityId
          )
          .subscribe({
            next: (res: any) => {
              this.setApplicationInfo(res);
            },
            error: (err) => { 
              if (err?.status === 404) { //TOdo status=400
                this.candidateProfileService.applicationDetailsFetched = true;
                this.candidateProfileService.uploadedDocument$.next([]);
              } 
              else {
                this.errorPage = true; 
                this.isLoadingAll = false;
              }
            },
        });
      }
    }
  }


  postApplication(){
    this.candidateInfoService
      .postApplicationByOpportunityId(
        this.opportunityId
      )
      .subscribe({
        next: (res: any) => {
          this.candidateInfoId = res?.applicantId;
          this.applicantIdSent.emit(this.candidateInfoId);
          sessionStorage.setItem('seekerId', String(this.candidateInfoId));
          this.setApplicationInfo(res);
          this.isLoadingSteps = false;
        },
        error: (err) => {
          if (err?.status === 400) {
            this.candidateProfileService.applicationDetailsFetched = true;
            this.candidateProfileService.uploadedDocument$.next([]);
            this.isLoadingSteps = false;
          } 
          else {
            this.errorPage = true; 
            this.isLoadingAll = false;
          }
        },
    });
  }

  setApplicationInfo(response) {
    let uploadedDoc = [];
    this.candidateProfileService.applicationStatus = response?.status;
    this.candidateProfileService.applicationModifiedDate =
      Date.parse(response?.lastModifiedDate);
    this.candidateProfileService.applicationId = response?.id;
    response.applicationFiles?.forEach((doc) => {
      if (!uploadedDoc[doc?.category]) {
        uploadedDoc[doc.category] = [];
      }
      if (uploadedDoc[doc?.category]) {
        uploadedDoc[doc.category].push(doc);
      }
    });
    this.candidateProfileService.uploadedDoc = uploadedDoc;
    this.candidateProfileService.applicationDetailsFetched = true;
    this.candidateProfileService.uploadedDocument$.next(
      uploadedDoc
    );
    if (response?.status === 'SENT_BACK_TO_CANDIDATE') {
      this.sharedService.openSnackBar(
        'You need to upload sent back documents and re-submit application at the end.',
        '',
        3500
      );
    }
  }

  // On AfterViewChecked, update stepper based on session data.
  setStepper() {
    //We will get the state of stepper from session storage and update stepper accordingly.
    const key = this.opportunityId ? 'Job' + this.opportunityId : 'candidateProfile';
    let flagMapArray = JSON.parse(
        sessionStorage.getItem(this.candidateProfileService.candidateProfileStateKey)
      );
    let currentFlagMap = flagMapArray[key] ;

    if (
      this.opportunityId &&
      this.candidateProfileService.applicationDetailsFetched
    ) {
      currentFlagMap = 
        this.checkForLatestAndUpdateJobFlagMap(currentFlagMap);
      flagMapArray[key] = currentFlagMap;
      sessionStorage.setItem(
        this.candidateProfileService.candidateProfileStateKey,
        JSON.stringify(flagMapArray)
      );
    }
    //We will update stepper if there is any status for first step in stepper state other than null.
    if (currentFlagMap.stepperState[0]) {
      this.updateStepperStateInView(currentFlagMap.stepperState);

      //*checkForLocalStepState will be true when we are navigating to application stepper or 
      //  candidate profile.
      //*And it will be false when we are navigating to particular step from outside of
      //  candidate profile and application stepper.
      this.stepChangeFlag = false;
      if (
        this.checkForLocalStepState &&
        currentFlagMap.lastModifiedStep
        ) {
          //In this we will navigate to last modified step if there is any.
        this.myStepper.selectedIndex = currentFlagMap.lastModifiedStep;
      }
      else {
        this.myStepper.selectedIndex = 
          componentPath[this.candidateProfileService.currentPath];
      }
      this.navigateToStep(this.myStepper.selectedIndex);
      this.stepChangeFlag = true;
    }
    this.myStepper.selected.state = this.myStepper.selected.completed
      ? 'edit'
      : 'tick';
    this.candidateProfileService.stepperSetupStatus = true;
    this.candidateProfileService.stepperSetupStatus$.next(true);
    this.checkForLocalStepState = false;
    this.stepperSetUpFLag = false;
    this.isLoadingAll = false;
  }

  checkForLatestAndUpdateJobFlagMap(currentFlagMap) {
    if (
      currentFlagMap.lastModifiedDate
    ) {
      const lastModifiedDate = currentFlagMap.lastModifiedDate;
      if (
        this.candidateProfileService.applicationModifiedDate - lastModifiedDate > 0
      ) {
        if (this.candidateProfileService.checkUnrestrictedNavigation()) {
          currentFlagMap = {
            'stepperState' : [
                'completed',
                'completed',
                'completed',
                'completed',
              ],
            'lastModifiedDate' : this.candidateProfileService.applicationModifiedDate,
            'lastModifiedStep' : null,
          };
        }
        else {
          currentFlagMap = {
            'stepperState': [
                null,
                null,
                null,
                null,
              ],
            'lastModifiedDate' : this.candidateProfileService.applicationModifiedDate,
            'lastModifiedStep' : null,
          };
        }
      }
    }
    else if (this.candidateProfileService.checkUnrestrictedNavigation()) {
      currentFlagMap.stepperState = [
          'completed',
          'completed',
          'completed',
          'completed',
        ];
      currentFlagMap.lastModifiedDate = this.candidateProfileService.applicationModifiedDate;
    }
    else {
      currentFlagMap.lastModifiedDate = this.candidateProfileService.applicationModifiedDate;
    }
    return currentFlagMap;
  }

  updateStepperStateInView(stepperState: string[]) {
    let changedPathArray = [];
    for (
      let index = 0;
      stepperState[index] && index < 4;
      index++
    ) {
      if (stepperState[index] === 'changed') {
        changedPathArray.push(index);
      }
      this.stepChangeFlag = false;
      // this.myStepper.selectedIndex = index;
      // this.myStepper.selected.completed = true;
    }

    if (changedPathArray.length > 0) {
      for (
        let index = changedPathArray.length - 1;
        index >= 0;
        index--
      ) {
        this.stepChangeFlag = false;
        this.myStepper.selectedIndex = changedPathArray[index];
        this.myStepper.selected.completed = false;
      }
    }
  }


  navigateToStep(step: number) {
    if (step !== componentPath[this.candidateProfileService.currentPath]) {
      let route = this.router.url.split('/');
      this.routeChangeFlag = false;
      if (componentPath[route[route.length - 1]] === undefined) {
        route.push(componentPath[step]);
      }
      else {
        route[route.length-1] = componentPath[step];
      }
      this.router.navigate([route.join('/')]);
    }
  }

  // Flags will prevent double routing.

  // On selectionChange event of stepper, navigate to intended component.
  stepChange(newStep) {
    if (this.stepChangeFlag === true) {
      this.routeChangeFlag = false;
      this.navigateToStep(newStep.selectedIndex);
    }
    this.stepChangeFlag = true;
  }

  // On acivate event of router-outlet, every route change will trigger it to update stepper
  routeChanged(stepper: MatStepper) {
    const route = this.router.url.split('/');
    // To store current path and parent path in service.
    this.candidateProfileService.currentPath = route.pop();
    this.candidateProfileService.parentPath = route.join('/');
    if (this.routeChangeFlag) {
      this.stepChangeFlag = false;
      stepper.selectedIndex =
        componentPath[this.candidateProfileService.currentPath];
    }
    if (this.candidateProfileService.stepperSetupStatus) {
      stepper.selected.state = stepper.selected.completed ? 'edit' : 'tick';
    }
    this.routeChangeFlag = true;
  }

}
