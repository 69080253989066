<div class="container-fluid layout">
  <app-loader [display]="isLoading"></app-loader>
  <div *ngIf="!isLoading">
    <div class="d-flex justify-content-between flex-wrap marB8">
      <div class="d-flex align-items-center">
        <div class="marLR8" role="button">
          <i class="icon x-24 back-icon" (click)="goBack()"> </i>
        </div>
        <h1 class="DSA_wb_h1Header">Search results for "{{ searchQuery }}"</h1>
      </div>
      <div class="d-flex align-items-center justify-content-end ">
        <span>Show results for:</span>
        <mat-form-field          
          class="marL8 DSA_Wb_custom-form-field DSA_form_nofloatLabel"
        >
          <mat-label>Interview Status</mat-label>
          <mat-select
            [formControl]="interviewStatusFilter"
            class="DSA_wb-custom-select"
            disableOptionCentering  panelClass="DSA_wb-custom-select-panel"
          >
            <mat-option
              *ngFor="let interviewStatus of interviewStatuses"
              [value]="interviewStatus.value"
            >
              {{ interviewStatus.displayValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="row marTB8" *ngIf="searchResults?.length !== 0">
      <div
        class="col-lg-4 col-md-6 col-12 pad8"
        *ngFor="let item of searchResults"
      >
        <mat-card class="DSA_wb_card-widget h-100">
          <mat-card-header>
            <mat-card-title>
              <h2 class="DSA_wb_h2Header DSA_wb_text-1line card-heading">
                {{ item.candidateName }}
              </h2>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content
            class="DSA_wb_mainBodyTxt web-GreyLight DSA_wb_text-4line secondaryInfo"
          >
            <div class="d-flex flex-wrap marB8">
              <span
                matTooltip="Candidate ID"
                matTooltipClass="DSA_tooltip"
                class="DSA_wb_chipStyle DSA_wb_greychip marR8"
              >
                {{
                  item.candidateSourceId
                    ? item.candidateSourceId
                    : item.candidateId
                }}
              </span>
              <span
                matTooltip="Candidate Email"
                matTooltipClass="DSA_tooltip"
                class="DSA_wb_chipStyle DSA_wb_greychip"
              >
                {{ item.candidateEmail }}
              </span>
            </div>
            <p>
              Event:
              <a
                class="DSA_wb_hyperLink-btn marTB8 underline"
                href="javascript:void(0);"
                (click)="goToEventPage(item)"
                matTooltip="Go to event"
                matTooltipClass="DSA_tooltip"
              >
                <b>{{ item.eventName }}</b>
              </a>
            </p>
            <p>Event Type: {{ item.eventType }}</p>
            <p>Job Title: {{ item.jobTitle }}</p>
            <p>Round Title: {{ item.title }}</p>
            <p>Interview Start Time: {{ item.startDate | date: "short" }}</p>
            <p>Interview Status: {{ item.interviewStatus }}</p>
            <p>
              Final Result:
              <span
                class="DSA_wb_chipStyle"
                [ngClass]="getChipClassByResult(item.result)"
              >
                {{ item.result ? item.result : "N/A" }}
              </span>
            </p>
          </mat-card-content>
          <mat-card-actions>
            <!-- <button
              mat-button
              class="DSA_wb_text-btn"
              (click)="goToEventPage(item)"
            >
              Go to event
            </button> -->
            <button
              mat-button
              class="DSA_wb_text-btn"
              (click)="goToFeedbackPage(item)"
            >
              Go to feedback page
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
      <div class="col-12 padLR8">
        <app-pagination
          [totalElements]="totalElements"
          [page]="page"
          [pageSize]="size"
          (pageChange)="pageChange($event)"
        ></app-pagination>
      </div>
    </div>
    <div
      *ngIf="searchResults?.length === 0"
      class="marT32 padTB16 padLR24 d-flex flex-column align-items-center"
    >
      <img src="assets/images/no_data_found.svg" alt="" class="no-data-img" />
      <h4 class="marT16">No records found</h4>
    </div>
  </div>
</div>
