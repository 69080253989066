<div class="customCard marT40 ng-star-inserted font-secondary">
  <div
    class="myclass1"
    role="button"
    [matTooltip]="
      accountService.getCurrentRole() == 'ROLE_HIRING_MANAGER'
        ? 'View Applicants'
        : 'View Problem Statement'
    "
    matTooltipClass="DSA_tooltip"
    (click)="redirectToNextPage(job.id)"
  >
    <div class="bgclass">
      <div class="row nomarLR" style="height: 76px">
        <div class="col-md-7 col-sm-7" style="padding-left: 0px">
          <div class="positionjobtitl1e">
            <span
              class="jobTitle hotJobsPost medium-screen-padding"
              style="
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                touch-action: none;
                user-select: none;
                -webkit-user-drag: none;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
              "
              id="PostingTitles7"
              aria-describedby="cdk-describedby-message-3"
              cdk-describedby-host
            >
              {{ job.title }}
            </span>
          </div>
        </div>
        <div
          class="col-md-4 col-sm-4 col-xs-8 mobile-width-8"
          style="
            text-align: right;
            display: flex;
            justify-content: flex-end;
            padding-top: 10px;
          "
        >
          <div class="social-icons" *ngIf="showSocialIcons">
            <a href="#">
              <img
                class="social-icons-pad"
                width="24"
                src="assets\images\facebook.png"
              />
            </a>
            <a href="#">
              <img
                class="social-icons-pad"
                width="24"
                src="assets\images\twitter.png"
              />
            </a>
          </div>
          <!-- <i
            (click)="toggleSocialIcons()"
            style="font-size: 30"
            class="fa fa-share"
          ></i> -->
          <!-- <i
            *ngIf="accountService.getCurrentRole() == 'ROLE_HIRING_MANAGER'"
            (click)="toggleSocialIcons()"
            style="padding-left: 5px; font-size: 30px"
            class="fa fa-edit fa-disabled"
            matTooltip="Edit Scholarship Details"
            matTooltipClass="DSA_tooltip"
          ></i> -->
        </div>
        <!-- <div
          class="col-md-1 col-sm-1 col-xs-4 mobile-width-4"
          style="padding-left: 5px; padding-top: 5px"
        >
          <i (click)="copyToClipboard(job.id)">
            <img width="30" src="assets/images/icon-copy.svg" />
          </i>
        </div> -->
        <span class="hotJob_SpanDiv positionjobtitle CompanyNameSpan">
          <span
            *ngIf="job.location"
            class="span_line mar0"
            style="display: inline-block"
          >
            <img class="customImgPng cus" src="assets/images/location.png" alt="location" />
            <span class="secondaryText marL10" name="JobLoc">
              {{ job.location }}
            </span>
          </span>
          <span
            [ngClass]="job.location ? 'marL20' : ''"
            class="span_line mar0"
            style="display: inline-block"
          >
            <img class="cus customImgPng" src="assets/images/deadline1.png" alt="icon" />
            <span class="secondaryText marL10" name="JobLoc">
              {{ getDateSimplified(job.endDate) }}
            </span>
          </span>
        </span>
      </div>
    </div>

    <div class="row nomarLR marT19 left">
      <span class="responsibilities">About Problem Statement</span>
    </div>

    <div class="row nomarLR marT19 left">
      <span
        class="secondaryText marL10 pretext CustomWidthResonsibilities"
        name="jobRoles"
        style="margin-left: 3px; text-align: justify"
        [innerHTML]="job.about"
      >
      </span>
    </div>

    <div class="row nomarLR marT16 left">
      <span class="responsibilities small-screen-mar">Tags:</span>
      <div class="tags">
        <mat-chip-list>
          <mat-chip
            *ngFor="let tag of job.tags; let i = index"
            [class.hidden]="i >= showLimit"
          >
            {{ tag }}
          </mat-chip>
          <mat-chip *ngIf="job.tags?.length > showLimit"
            >+{{ job.tags?.length - showLimit }}</mat-chip
          >
        </mat-chip-list>
      </div>
    </div>

    <div class="row marR16 marT16 d-flex" style="justify-content: end">
      <button (click)="viewJobdetails(job.id)" class="customButton">
        <span class="buttonText" name="View">
          {{
            accountService.getCurrentRole() == "ROLE_HIRING_MANAGER"
              ? "VIEW"
              : "VIEW AND APPLY"
          }}
        </span>
      </button>
      <!-- <button
        *ngIf="accountService.getCurrentRole() == 'ROLE_HIRING_MANAGER'"
        class="customButton marL8"
      >
        <span class="buttonText" name="Edit">EDIT</span>
      </button> -->
      <!-- <button
        (click)="handleAssociateForm(job)"
        *ngIf="accountService.getCurrentRole() == 'ROLE_HIRING_MANAGER'"
        class="customButton marL8"
      >
        <span class="buttonText" name="View">ASSOCIATE QUESTIONS</span>
      </button> -->
    </div>
  </div>
</div>
