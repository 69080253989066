import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RecruiterService {
  constructor(private http: HttpClient) {}

  private headers = new HttpHeaders()
    .set('content-type', 'application/json');

  getAllRecruters = () => {
    let params = new HttpParams();
    params = params.append('size', `${20000}`);

    const opts = {
      headers: this.headers,
      params,
    };
    return this.http.get<any[]>(
      environment.profileServiceUrl + 'api/recruiters',
      opts
    );
  };

  getAllCoordinators = () => {
    let params = new HttpParams();
    params = params.append('size', `${20000}`);

    const opts = {
      headers: this.headers,
      params,
    };
    return this.http.get<any[]>(
      environment.profileServiceUrl + 'api/coordinators',
      opts
    );
  }

  // ! exactly same function in service.service and recruiter.service
  // getAllRecruters = () => {
  //   let params = new HttpParams();
  //   params = params.append('size', `${20000}`);

  //   const opts = {
  //     headers: this.headers,
  //     params,
  //   };
  //   return this.http.get<any[]>(
  //     environment.profileServiceUrl + 'api/recruiters',
  //     opts
  //   );
  // }

  getRecruiterStats = () => {
    return this.http.get(
      environment.interviewServiceUrl + 'api/recruiter/dashboard/metrics',
      {
        headers: this.headers,
      }
    );
  };

  getRecruiterDashboard = (size, page) => {
    return this.http.get<any>(
      environment.interviewServiceUrl + 'api/recruiter/dashboard',
      { headers: this.headers, params: { size, page }, observe: 'response' }
    );
  };

  getRecrutersListWithEventId = (id, size, page) => {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);

    const opts = {
      headers: this.headers,
      params,
    };
    return this.http.get<any[]>(
      environment.interviewServiceUrl + 'api/events/' + id + '/coordinators',
      opts
    );
  };

  // ! exactly same function in service.service and recruiter.service
  // getRecrutersListWithEventId = (id) => {
  //   let params = new HttpParams();
  //   params = params.append('size', `${20000}`);

  //   const opts = {
  //     headers: this.headers,
  //     params,
  //   };
  //   return this.http.get<any[]>(
  //     environment.interviewServiceUrl + 'api/events/' + id + '/coordinators',
  //     opts
  //   );
  // }

  sendForPanelistReview = (interviewId, remarks?) => {
    return this.http.post(
      environment.interviewServiceUrl +
        'api/interviews/' +
        interviewId +
        '/actions/panellist-review',
      remarks,
      { headers: this.headers }
    );
  };

  setPanelistAttendance = (attendanceArray) => {
    return this.http.post(
      `${environment.interviewServiceUrl}api/interviewer/attendance`,
      attendanceArray,
      { headers: this.headers }
    );
  };
}
