<div *ngIf="params.type === 'location'">
  <span *ngFor="let location of locations?.split(',')">
    <mat-chip
      class="DSA_wb_chipStyle-Normal DSA_choiseChip marL8"
      disableRipple="true"
      >{{ location }}</mat-chip
    >
  </span>
</div>
<span
  ><i
    *ngIf="params.type == 'icon-add' && params.condition"
    class="icon x-24 icon-add"
    role="button"
    (click)="associateEventByRequisition()"
    matTooltip="Associate event to Requisition"
    matTooltipClass="DSA_tooltip"
  ></i>
</span>
<div *ngIf="params.type === 'checkbox'">
  <mat-checkbox
    class="DSA_Wb_custom-checkbox"
    (change)="checkboxToggle($event, eventId)"
    aria-label="checkbox"
    disableRipple="true"
    [checked]="getEventCheckedStatus(eventId)"
  ></mat-checkbox>
</div>
