import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  EvaluationFeedback,
  JobEvaluationFormDTO,
  TemplateEvaluationFormDTO,
} from '../shared/model/form-ms.model';

@Injectable({
  providedIn: 'root',
})
export class FormMsService {
  private headers = new HttpHeaders()
    // .set('Authorization', 'Bearer ' + localStorage.getItem('token'))
    .set('content-type', 'application/json');
  constructor(private http: HttpClient) {}

  getEvaluationTemplates = (
    page: number,
    size: number,
    query?: string,
    filterOptions?: {
      isFinalized: boolean;
      formType: string;
    }
  ): Observable<any> => {
    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('size', size.toString());
    if (query) params = params.append('searchQuery', query);
    if (filterOptions?.isFinalized)
      params = params.append('isFinalized', String(filterOptions.isFinalized));
    if (filterOptions?.formType)
      params = params.append('formType', filterOptions.formType);
    return this.http.get(
      `${environment.formsMS}api/template-evaluation-forms`,
      { headers: this.headers, params: params }
    );
  };

  getTemplateById = (templateId: number): Observable<any> => {
    return this.http.get(
      `${environment.formsMS}api/template-evaluation-forms/${templateId}`,
      { headers: this.headers }
    );
  };
  getAdminTemplate = (): Observable<any> => {
    return this.http.get(
      `${environment.formsMS}api/evaluation-forms?formType=CREATE_OPPORTUNITY&isActive=true&formData=true`,
      { headers: this.headers }
    );
  };
  getApplicationForm = (): Observable<any> => {
    return this.http.get<any>(
      `${environment.formsMS}api/evaluation-forms?formType=ONBOARDING&isActive=true&formData=true`
    );
  };
  getTemplates = (): Observable<any> => {
    return this.http.get(
      `${environment.formsMS}api/template-evaluation-forms`,
      { headers: this.headers }
    );
  };

  createEvaluationTemplate = (
    payload: TemplateEvaluationFormDTO
  ): Observable<any> => {
    return this.http.post(
      `${environment.formsMS}api/template-evaluation-forms`,
      payload,
      { headers: this.headers }
    );
  };

  updateEvaluationTemplate = (
    data: TemplateEvaluationFormDTO
  ): Observable<any> => {
    return this.http.put(
      `${environment.formsMS}api/template-evaluation-forms/${data.id}`,
      data,
      { headers: this.headers }
    );
  };

  getEvaluationForm = (
    page: number,
    size: number,
    query?: string,
    filterOptions?: {
      formType?: string;
      evaluationEventId?: number;
      isActive?: boolean;
      formData?: boolean;
    }
  ): Observable<any> => {
    let params = new HttpParams();
    params = params.append('page', page.toString());
    params = params.append('size', size.toString());
    if (query) params = params.append('searchQuery', query);
    if (filterOptions?.formType)
      params = params.append('formType', filterOptions?.formType);
    if (filterOptions?.isActive !== undefined)
      params = params.append('isActive', `${filterOptions?.isActive}`);
    if (filterOptions?.evaluationEventId)
      params = params.append('evaluationEventId', `${filterOptions?.evaluationEventId}`);
    if (filterOptions?.formData)
      params = params.append('formData', `${filterOptions?.formData}`);
    return this.http.get(`${environment.formsMS}api/evaluation-forms`, {
      headers: this.headers,
      params: params,
    });
  };

  updateEvaluationForm = (payload: JobEvaluationFormDTO) => {
    return this.http.put(
      `${environment.formsMS}api/evaluation-forms/${payload.id}`,
      payload,
      { headers: this.headers }
    );
  };

  associateEvaluationFormToJob = (
    payload: JobEvaluationFormDTO
  ): Observable<any> => {
    return this.http.post(
      `${environment.formsMS}api/evaluation-forms`,
      payload,
      { headers: this.headers }
    );
  };

  createEvaluationFeedback = (payload: EvaluationFeedback): Observable<any> => {
    return this.http.post(
      `${environment.formsMS}api/evaluation-feedbacks`,
      payload,
      { headers: this.headers }
    );
  };

  updateEvaluationFeedback = (payload: EvaluationFeedback): Observable<any> => {
    payload.interactionGroupingType='APPLICATION';
    payload.interactionGroupingId=payload.interactionId;
    payload.cirId=payload.interactionGroupingId;
    console.log(payload.id);
    console.log(payload.interactionGroupingId);
    console.log(payload.interactionId);
    return this.http.put(
      `${environment.formsMS}api/evaluation-feedbacks/${payload.id}`,
      payload,
      { headers: this.headers }
    );
  };

  getFeedbackByInterviewId = (
    interviewId: number,
    filterOptions?: {
      feedbackStatus?,
      formType?,
      interactionGroupingId?,
    }
  ): Observable<any> => {
    let params = new HttpParams();
    if (filterOptions?.feedbackStatus) {
      params = params.append('feedbackStatusList', filterOptions?.feedbackStatus);
    }
    if(filterOptions?.formType){
      params = params.append('formType',filterOptions?.formType);
    }
    if(filterOptions?.interactionGroupingId){
      params = params.append('interactionGroupingId',filterOptions?.interactionGroupingId);
    }
    // /evaluation-feedbacks-by-applicationId/{applicationId}
    return this.http.get(
      `${environment.formsMS}api/evaluation-feedbacks-by-interviewId/${interviewId}`,
      { headers: this.headers, params }
    );
  };
}
