import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import { FormMsService } from 'src/app/services/form-ms.service';
import { SharedService } from 'src/app/services/shared.service';
import {
  JobEvaluationFormDTO,
  TemplateEvaluationFormDTO,
} from 'src/app/shared/model/form-ms.model';

@Component({
  selector: 'app-infy-job-formio-page',
  templateUrl: './infy-job-formio-page.component.html',
  styleUrls: ['./infy-job-formio-page.component.scss'],
})
export class InfyJobFormioPageComponent implements OnInit {
  interviewType = ['TECHNICAL', 'HR', 'DESIGN', 'MANAGEMENT','SYNOPSIS','VERIFICATION','ONBOARDING'];
  interviewRoundType: FormControl = new FormControl(this.interviewType[0]);

  jobRoleId: number;

  isFetchingTemplate: boolean = false;
  isUpdatingTemplate: boolean = false;
  activeTemplate: JobEvaluationFormDTO;
  inactiveTemplates: JobEvaluationFormDTO[] = [];
  createdEvaluationForm: FormGroup;

  isAssociateTemplateVisible: boolean = false;

  @ViewChild('prevFormsPanel') prevFormsPanel: MatExpansionPanel;
  @ViewChild('activeFormPanel') activeFormPanel: MatExpansionPanel;

  constructor(
    private formsService: FormMsService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.jobRoleId = this.activatedRoute.parent.snapshot.params.jobId;

    this.interviewRoundType.valueChanges.subscribe((val) => {
      if (this.isAssociateTemplateVisible)
        this.isAssociateTemplateVisible = false;
    });

    this.createdEvaluationForm = this.formBuilder.group({
      id: [],
      jobRoleId: ['', [Validators.required]],
      formType: ['', [Validators.required]],
      title: ['', [Validators.required]],
      description: [''],
      templateEvaluationForm: this.formBuilder.group({
        name: [{ value: '', disabled: true }],
        formData: [''],
      }),
      version: [{ value: '', disabled: true }],
    });
    this.getJobEvaluationForm();
  }

  getJobEvaluationForm = () => {
    this.isFetchingTemplate = true;
    this.formsService
      .getEvaluationForm(0, 2000, '', {
        formType: this.interviewRoundType.value,
        evaluationEventId: this.jobRoleId,
      })
      .subscribe({
        next: (res) => {
          this.activeTemplate = res.content.find((evForm) => evForm.isActive);
          this.inactiveTemplates = res.content.filter(
            (evForm) => !evForm.isActive
          );

          if (this.activeTemplate) {
            // to get form json of active template
            this.formsService
              .getTemplateById(this.activeTemplate.templateEvaluationForm.id)
              .subscribe({
                next: (res: TemplateEvaluationFormDTO) => {
                  this.activeTemplate.templateEvaluationForm.formData =
                    res.formData;
                  this.createdEvaluationForm.patchValue(this.activeTemplate);
                  this.isFetchingTemplate = false;
                },
                error: (err) => {
                  this.sharedService.openSnackBar(
                    'Error in fetching form',
                    '',
                    3000
                  );
                  this.isFetchingTemplate = false;
                },
              });
          } else {
            this.isFetchingTemplate = false;
          }
        },
        error: (err) => {
          this.sharedService.openSnackBar(
            'Some error occurred in fetching template',
            '',
            3000
          );
          this.isFetchingTemplate = false;
        },
      });
  };

  handleInterviewCategorySelect = (e) => {
    this.prevFormsPanel.close();
    this.activeFormPanel.open();
    this.getJobEvaluationForm();
  };

  updateEvaluationForm = () => {
    this.isUpdatingTemplate = true;
    const payload = {
      id: this.createdEvaluationForm.value.id,
      title: this.createdEvaluationForm.value.title,
      description: this.createdEvaluationForm.value.description,
    };
    this.formsService.updateEvaluationForm(payload).subscribe({
      next: (res) => {
        this.sharedService.openSnackBar('Updated successfully', '', 3000);
        this.isUpdatingTemplate = false;
      },
      error: (err) => {
        this.sharedService.openSnackBar(
          'Update Failed. Please try again later',
          '',
          3000
        );
        this.isUpdatingTemplate = false;
      },
    });
  };

  displayAssociateTemplateBlock = () => {
    this.isAssociateTemplateVisible = true;
  };

  handleAssociateTemplate = (associatedTemplate: JobEvaluationFormDTO) => {
    this.getJobEvaluationForm();
    this.createdEvaluationForm.patchValue(this.activeTemplate);
    this.isAssociateTemplateVisible = false;
    this.prevFormsPanel.close();
    this.activeFormPanel.open();
  };
}
