<div
  class="container-fluid nopadLR"
  *ngIf="
    (applicationStatus === 'SUBMITTED' || applicationStatus === 'SENT_BACK_TO_CANDIDATE') &&
      (applicationSubStatus === 'SYNOPSIS_REQUESTED'||  applicationSubStatus === 'SYNOPSIS_SUBMITTED');
    else showref
  "
>
  <auto-save
    *ngIf=" applicationSubStatus === 'SYNOPSIS_REQUESTED'"
    [storageKey]="localStorageKey"
    [storeItem]="basicDetailsApplicationForm"
    [localTick]="10"
    [dbTick]="60"
    [autoSave]="true"
    [saveInDB]="autoSaveInDB"
    (onDBSave)="onAutoSaveInDB()"
    [candidate]="true"
  ></auto-save>
  <div *ngIf="!formRenderingError; else formRenderingErrorRef">
    <app-loader [display]="isFormLoading"></app-loader>
    <formio
      *ngIf="!isFormLoading"
      [form]="basicForm?.formData"
      (ready)="onFormReady($event)"
      [submission]="submission ? submission : { data: null }"
      (change)="createdFormOnChange($event)"
      [refresh]="refreshEmitter"
      [options]="options"
      #formio
    ></formio>
    <p *ngIf="!this.basicDetailsApplicationForm['isValid']" class="red marB4">
      Fields marked with * are Mandatory
    </p>
    <div
      class="d-flex align-items-center marT8"
    >
      <button
        mat-button
        (click)="handleResetClick()"
        class="DSA_wb_secondary-btn text-uppercase customButton marR8"
        [disabled]="resetDisabled"
      >
        Reset
      </button>
      <button
        mat-button
        class="DSA_wb_primary-btn text-uppercase customButton marL8"
        [disabled]="!submitting"
        (click)="handleSubmit()"
      >
        {{ displaySubmit ? "Submitting..." : applicationSubStatus === 'SYNOPSIS_SUBMITTED' ? 'UPDATE':'SUBMIT'}}
      </button>
    </div>
  </div>
  <ng-template #formRenderingErrorRef>
    <app-error-message [errorText]="formRenderingError"></app-error-message>
  </ng-template>
</div>

<ng-template #showref>
  <div *ngIf="!isFormLoading" class="overflow">
    <app-error-message
      [errorText]="'Please Complete the Team Details Step'"
    ></app-error-message>
  </div>
</ng-template>
