// import { GatewaySharedModule } from './../../shared/shared.module';

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MaterialModule } from '../../angular-material.module';
// import { GatewaySharedModule } from 'src/app/shared/shared.module';

// import { GatewaySharedModule } from "../../shared/shared.module";
// import { AppMaterialModule } from "../app-material.module";
import { CoreModule } from 'keycloak-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IwpPlatformActionsRoutingModule } from './iwp-platform-actions-routing.module';
import { InfyCreateJobComponent } from './infy-create-job/infy-create-job.component';
import { JobsAdminActionRendererComponent } from './infy-jobs-admin/jobs-admin-action-renderer/jobs-admin-action-renderer.component';
import { InfyJobsAdminComponent } from './infy-jobs-admin/infy-jobs-admin.component';
import { InfyKeycloakAdminComponent } from './infy-keycloak-admin/infy-keycloak-admin.component';
import { InfySupersetDashboardComponent } from './infy-superset-dashboard/infy-superset-dashboard.component';
import { MigrateCandidateDataComponent } from './migrate-candidate-data/migrate-candidate-data.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CustomScrollDirective } from './privacy-policy/custom-scroll.directive';
import { JobsAdminDashboardStateStorageService } from './infy-jobs-admin/jobs-admin-dashboard-state-storage.service';
// import { MaterialModule } from 'src/app/angular-material.module';
// import { GatewaySharedModule } from 'src/app/shared/shared.module';
import { GatewaySharedModule } from '../../shared/shared.module';
import { InfyJobEvaluationFormComponent } from './infy-job-evaluation-form/infy-job-evaluation-form.component';
import { AgeConfirmationComponent } from './age-confirmation/age-confirmation.component';
@NgModule({
  imports: [
    // IwpPlatformActionsRoutingModule,
    GatewaySharedModule,

    MaterialModule,
    CarouselModule,
    CoreModule,
    NgbModule,
    RouterModule
  ],
  declarations: [
    InfyCreateJobComponent,
    JobsAdminActionRendererComponent,
    InfyJobsAdminComponent,
    InfyJobEvaluationFormComponent,
    InfyKeycloakAdminComponent,
    InfySupersetDashboardComponent,
    MigrateCandidateDataComponent,
    PrivacyPolicyComponent,
    CustomScrollDirective,
    AgeConfirmationComponent,
  ],
  providers: [JobsAdminDashboardStateStorageService],
})
export class IwpPlatformActionsModule {}
