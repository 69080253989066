import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MailTemplate } from './mail-template.model';
import { placeholders } from './placeholders';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MailTemplateService {
  currTemplate;
  constructor(private http: HttpClient) {}
  private headers = new HttpHeaders()
    .set('content-type', 'application/json');

  getAllMailTemplates(page, size) {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);

    const opts = {
      headers: this.headers,
      params,
    };
    return this.http.get(
      environment.notificationServiceUrl + 'api/notification-templates',
      opts
    );
  }
  setCurrentTemplate(template) {
    this.currTemplate = template;
  }

  getCurrentTemplate() {
    return this.currTemplate;
  }

  getAllMailTemplatesById(id: string) {
    return this.http.get('notificationTemplateApi' + `/${id}`);
  }

  deleteMailTemplateById(id: string): Observable<any> {
    return <Observable<any>>(
      this.http.delete(
        environment.notificationServiceUrl +
          'api/notification-templates' +
          `/${id}`,
        { headers: this.headers }
      )
    );
  }

  saveMailTemplate(mailTemplate: MailTemplate) {
    return this.http.post('notificationTemplateApi', mailTemplate);
  }

  getAllTemplateCategories(): Observable<any> {
    return <Observable<any>>this.http.get('templateCategoriesApi', {
      params: {
        status: 'A',
        page: '0',
        size: '1000',
      },
    });
  }

  getTemplateCategoriesById(id) {
    return this.http.get('templateCategoriesApi' + `/${id}`);
  }

  getTemplateSubCategoriesById(id): Observable<any> {
    return <Observable<any>>this.http.get(
      'templateSubCategoriesApi' + `/${id}`,
      {
        params: { page: '0', size: '1000' },
      }
    );
  }

  getAllTemplateSubCategories(id: any): Observable<any> {
    return <Observable<any>>this.http.get('templateSubCategoriesApi', {
      params: {
        templateCategoryId: id,
        status: 'A',
        page: '0',
        size: '1000',
      },
    });
  }

  getAvailablePlaceholders() {
    return placeholders;
  }
}
