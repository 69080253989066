import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChildren,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MailTemplateSelectionComponent } from '../mail-template-selection/mail-template-selection.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InfyJobsStateStorageService } from './infy-jobs-state-storage.service';
import { ConfirmationComponent } from '../../../shared/components/confirmation/confirmation.component';
import { JobService } from 'src/app/services/job.service';
import { FileService } from 'src/app/services/file.service';
import { SharedService } from 'src/app/services/shared.service';
import { EventService } from 'src/app/services/event.service';
import { AccountService } from 'src/app/services/account.service';
import { MsteamsService } from 'src/app/services/msteams.service';
import { environment } from 'src/environments/environment';
// import { InfyAssociateJobsComponent } from '../infy-associate-jobs/infy-associate-jobs.component';
import { linkRendererComponent } from 'src/app/shared/components/ag-grid-renderer/linkRenderer.component';
import { AddJobsComponent } from '../add-jobs/add-jobs.component';
import { Observable, of, Subject } from 'rxjs';
import { RecruiterJobsLinkRendererComponent } from 'src/app/modules/iwp-event/infy-jobs/jobs-action-renderer/recruiter-jobs-link-renderer.component';
import { JobActionRendererComponent } from 'src/app/modules/iwp-event/infy-jobs/jobs-action-renderer/job-action-renderer';
import { BulkUploadDialogComponent } from './bulk-upload-dialog/bulk-upload-dialog.component';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';

export interface role {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-infy-jobs',
  templateUrl: './infy-jobs.component.html',
  styleUrls: ['./infy-jobs.component.scss'],
})
export class InfyJobsComponent implements OnInit, AfterViewInit {
  users: any;
  tableData = <any[]>[];
  tablePageNumber = 0;
  pageSize = 25;
  totalElements = 0;
  eventId: number = this.eventService.selectedEventId;
  refreshAgGrid: Subject<void> = new Subject<void>();

  displayColumn = <string[]>[];

  jobList = <any[]>[];
  loading: boolean = false;
  jobRoles: any;
  job: any;
  selectedRole: any;
  newJob: boolean = false;
  checkFlag = <boolean[]>[];

  fileTypeArray = ['FRESHER', 'MBA', 'LATERAL'];

  bulkUploadForm: FormGroup;
  eventStatus: any;

  bulkuploadFile;
  recruiterLink: any;

  columnDefs: any;
  @ViewChildren('bulkUploadElement') bulkUploadInput;
  FeatureName = FeatureName;
  PermissionType = PermissionType;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private Router: ActivatedRoute,
    private infyJobsStateStorageService: InfyJobsStateStorageService,
    private jobService: JobService,
    private fileService: FileService,
    private sharedService: SharedService,
    private eventService: EventService,
    public accountService: AccountService,
    private msteamsService: MsteamsService
  ) {
    this.bulkUploadForm = this.fb.group({
      fileType: ['FRESHER', [Validators.required]],
    });
  }

  tableName: string = 'Jobs';
  buttonText: string = 'Add Job(s)';
  buttonComponent: any = AddJobsComponent;
  levels: number = 1;
  buttonCondition: boolean = this.accountService.hasFeatureAccess(
    FeatureName.EVENT_ASSOCIATE_JOBS,
    PermissionType.READ
  );
  searchCondition: boolean = false;

  setColumnDefs() {
    this.columnDefs = [
      {
        headerName: 'ID',
        field: 'jobRoleId',
        type: 'required',
        hide: false,
        maxWidth: 150,
      },
      {
        headerName: 'Title',
        field: 'title',
        type: 'required',
        hide: false,
        valueFormatter: this.linkFormatter,
        cellRendererFramework: RecruiterJobsLinkRendererComponent,
        minWidth: 300,
      },
      { headerName: 'Domain', field: 'domain', hide: false },
      { headerName: 'Category', field: 'category', hide: false },
      {
        headerName: 'Description',
        field: 'description',
        hide: false,
        minWidth: 200,
      },
      { headerName: 'Tags', field: 'tags', hide: true },
      {
        headerName: 'Actions',
        hide: false,
        required: true,
        cellRendererFramework: JobActionRendererComponent,
        cellRendererParams: {
          icon: 'delete',
          condition: this.buttonCondition && this.eventStatus !== 'CLOSED',
        },
        maxWidth: 80,
      },
    ];
  }

  linkFormatter(params: any) {
    if (!params.data) return '';
    return '/create-event/' + params.data.eventId.toString() + '/schedule';
  }

  ngOnInit() {
    this.eventStatus = localStorage.getItem('eventStatus');
    this.setColumnDefs();

    if (
      this.infyJobsStateStorageService.page &&
      this.infyJobsStateStorageService.size
    ) {
      this.pageSize = this.infyJobsStateStorageService.size;
      this.tablePageNumber = this.infyJobsStateStorageService.page;
    }

    this.eventId = this.eventService.selectedEventId;
    this.recruiterLink =
      '/create-event/' + this.eventId.toString() + '/schedule';
    if (!this.eventId || this.eventStatus === null)
      this.router.navigate(['/dashboard']);

    // await this.refreshTable();
    // if (this.infyJobsStateStorageService.checkFlag.length > 0) {
    //   this.checkFlag = this.infyJobsStateStorageService.checkFlag;
    // }

    this.displayColumn = [
      'Job ID',
      'Job Role',
      'Job Title',
      'Domain',
      'Category',
      'Job Description',
      // 'Actions',
    ];
    // this.eventId = parseInt(this.Router.snapshot.params.id);

    // this.service.getJobRoles().subscribe((res) => {
    //   this.jobRoles = res;
    //   this.selectedRole = this.jobRoles[0];
    //
    // });
  }

  ngAfterViewInit() {
    // this.bulkUploadInput.changes.subscribe((queryList) => {
    // });
    let appState = JSON.parse(window.sessionStorage.getItem('appState'));
    if (
      appState &&
      appState.authStatus === 'AUTHENTICATED' &&
      appState.openFileUpload === 'true'
    ) {
      // queryList.first.nativeElement.click();
      this.sharedService.openSnackBar(
        `Please click 'Create Multiple Interviews' to upload the bulk upload sheet`,
        '',
        3000
      );
      window.sessionStorage.removeItem('appState');
    }
  }

  interface = (size: number, page: number): Observable<any[]> => {
    return this.jobService.getAllJobsByEvent(this.eventId, page, size);
  };

  onPageChange = (e) => {
    this.infyJobsStateStorageService.page = e.target.page;
    this.infyJobsStateStorageService.size = e.target.size;
  };

  bulkUpload = (files) => {
    this.bulkuploadFile = files[0];
  };

  uploadBulkCSV = () => {
    let formData = new FormData();
    const specData = {
      eventId: this.eventId,
      tenantId: localStorage.getItem('tenantId'),
    };
    formData.append('specData', JSON.stringify(specData));
    formData.append('file', this.bulkuploadFile);

    this.bulkuploadFile = undefined;
    this.fileService.bulkUpload(formData).subscribe(
      (res) => {
        this.sharedService.openSnackBar(
          'Sheet Uploaded. Bulk upload status can be checked using the bell icon above.',
          '',
          3000
        );
      },
      () => {
        this.sharedService.openSnackBar('Some Error Occured', '', 3000);
      }
    );
  };

  confirmMSTeamsIncludedOrNot = () => {
    const dialogRef = this.dialog.open(BulkUploadDialogComponent, {
      data: {
        eventId: this.eventId,
      },
      backdropClass: 'backdrop-background',
      autoFocus: false,
      minWidth: 400,
      maxWidth: 500,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Yes') {
        //check if authorization is required or not
        this.msteamsService.checkAuthorizationRequired().subscribe((res) => {
          // if yes
          if (res.requiresAuthorization) {
            let redirect_uri = environment.msauthRedirectUrl;
            let sessionStorageObj = {
              redirectTo: this.router.url,
              openFileUpload: 'true',
            };
            window.sessionStorage.setItem(
              'appState',
              JSON.stringify(sessionStorageObj)
            );
            window.open(redirect_uri, '_self');
          } else {
            // if no
            this.bulkUploadInput.first.nativeElement.click();
          }
        });
      } else if (result === 'No') {
        this.bulkUploadInput.first.nativeElement.click();
      }
    });
  };

  openAddJobDialog() {
    const dialog = this.dialog.open(AddJobsComponent, {
      panelClass: 'add-jobs',
      backdropClass: 'backdrop-background',
    });
    dialog.afterClosed().subscribe((value) => {
      if (value) this.addJobToEvent(value);
    });
  }

  addJobToEvent(job) {
    this.jobService
      .createJob({
        tags: job.tags.toString(),
        jobRoleId: job.id,
        eventId: this.eventId,
      })
      .subscribe(
        (res) => {
          this.refreshAgGrid.next();
          this.sharedService.openSnackBar('Job added successfully', '', 3000);
        },
        (err) => {
          this.sharedService.openSnackBar('Job addition failed', '', 3000);
        }
      );
  }
}
