<div class="banner" [style.background-color]="bgColor">

    <div class="row">
        <h1 class="title small-screen-marginL" [style.color]="titleColor">{{ title }}</h1>
    
        <!-- <div class="share-icon marL16" (click)="toggleSocialIcons()">
            <i class="fa fa-share"></i>
        </div>
        <div class="social-icons" *ngIf="showSocialIcons">
            <a href="#"><img width="24" src="assets\images\facebook.png"/></a>
            <a href="#"><img width="24" src="assets\images\twitter.png"></a>
        </div> -->
    </div>
      
    <div class="location-organization" *ngIf="displaySubHeader == true">
        <i class="icon x-24 relocation-icon"></i> <span class="location font-oswald" [style.color]="titleColor"> {{ location }}</span>
        <i class="icon x-24 icon_WFH"></i><span class="organization font-oswald" [style.color]="titleColor">{{ organization }}</span>
    </div>
  </div>
  
  