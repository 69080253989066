
<div class="container-fluid ">
  <div class="col-12 marT32">
    <h3 class="ds_h3Inner">Submit Scholarship</h3>
    <div class="row">
        <div class="d-flex justify-content-center align-items-center m-8">
          <div class=" DSA_panel col-lg-6 col-md-6">
            <mat-form-field
              class="DSA_Wb_custom-form-field DSA_form_nofloatLabel"
              floatLabel="never"
            >
              <mat-label>Name</mat-label>
              <input matInput placeholder="First Name" value="" />
            </mat-form-field>
      
            <mat-form-field
              class="DSA_Wb_custom-form-field DSA_form_nofloatLabel"
              floatLabel="never"
            >
              <mat-label>Email</mat-label>
              <input matInput placeholder="Email" value="" />
            </mat-form-field>

            <mat-form-field
              class="DSA_Wb_custom-form-field DSA_form_nofloatLabel"
              floatLabel="never"
            >
              <mat-label>Organisation Name</mat-label>
              <input matInput placeholder="Organisation Name" value="" />
            </mat-form-field>
            <mat-form-field
              class="DSA_Wb_custom-form-field DSA_form_nofloatLabel"
              floatLabel="never"
            >
              <mat-label>Phone Number</mat-label>
              <input matInput placeholder="Phone Number" value="" />
            </mat-form-field>

            <button mat-flat-button  class="mar8 DSA_wb_primary-btn">Submit</button> 
          </div>
        </div>
      </div>
    </div>  
</div>

