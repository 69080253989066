/* eslint-disable no-console */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/tslint/config */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IApplication } from 'src/app/shared/model/application.model';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { NotifierService } from 'angular-notifier';
import { FileserverService } from '../edit-backgroud-color/fileserver.service';
import { ImageEditService } from 'src/app/survey-master/survey-details/image-edit.service';
import { MediaObserver } from '@angular/flex-layout';
import { WEBSITE_URL } from 'src/app/app.constants';

@Component({
  selector: 'jhi-survey-heading',
  templateUrl: './survey-heading.component.html',
  styleUrls: ['./survey-heading.component.scss'],
})
export class SurveyHeadingComponent implements OnInit {
  folder: IApplication;
  surveyDefinition: any;
  website_url = WEBSITE_URL;
  private readonly notifier: NotifierService;
  constructor(
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    notifierService: NotifierService,
    public fileserverService: FileserverService,
    public imageService: ImageEditService,
    private mediaObserver: MediaObserver
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ application }) => {
      this.folder = application;
      this.activatedRoute.data.subscribe(({ surveyDefinition }) => {
        this.surveyDefinition = surveyDefinition;
      });
    });
  }

  downloadImage(path: string) {
    return this.fileserverService.getFileURLbyPath(path);
  }

  isMobile(): boolean {
    return (
      this.mediaObserver.isActive('xs') || this.mediaObserver.isActive('sm')
    );
  }
}
