import { SurveyDesignService } from './../survey-design/design.service';
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/tslint/config */
import { NgModule } from '@angular/core';
import { Routes, RouterModule, RouterStateSnapshot } from '@angular/router';

import { map as mapLodash } from 'lodash';
import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
// // import { UserRouteAccessService } from 'src/app/core/auth/user-route-access-service'; //have a look for interview
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SurveyDefinition } from 'src/app/shared/model/survey-definition.model';
import { SurveyService as SurveyDefinitionService } from '../survey/survey.service';

import { ISurveyDefinition } from 'src/app/shared/model/survey-definition.model';
import { SurveyResponsesComponent } from '../survey-responses/survey.responses.component';

@Injectable({ providedIn: 'root' })
export class SurveyDefinitionResolve implements Resolve<ISurveyDefinition> {
  constructor(private service: SurveyDefinitionService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ISurveyDefinition> {
    const id = route.params['id'];
    if (id) {
      return this.service
        .find(id)
        .pipe(
          map(
            (surveyDefinition: HttpResponse<SurveyDefinition>) =>
              surveyDefinition.body
          )
        );
    }
    return of(new SurveyDefinition());
  }
}

// @Injectable({ providedIn: 'root' })
// export class PostSubmitTemplateResolve implements Resolve<any> {
//   constructor(private service: TemplateManagerService, private surveyService: SurveyDefinitionService) {}

//   resolve(route: ActivatedRouteSnapshot): Observable<any> {
//     const id = route.params['id'];
//
//     if (id) {
//
//       return this.surveyService.find(id).pipe(
//         map((surveyDefinition: HttpResponse<SurveyDefinition>) => {
//           const postSubmitId = surveyDefinition.body.postSubmitViewTemplateId;
//
//           return this.service.find(postSubmitId).pipe(
//             map((postSubmitTemplate: any) => {
//
//               return postSubmitTemplate.body ? postSubmitTemplate.body : null;
//             })
//           );
//           // subscribe((res)=>console.log('res' , JSON.stringify(res.body)),
//           // (res)=>
//         })
//       );
//       // this.service.find(id).pipe(map((surveyDefinition: HttpResponse<SurveyDefinition>) => surveyDefinition.body));
//     }
//     return null;
//   }
// }

@Injectable({ providedIn: 'root' })
export class PagedQuestionsResolve implements Resolve<any> {
  constructor(private service: SurveyDesignService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const id = route.params['id'];
    if (id && id !== 'new') {
      return this.service.getPagedQuestions(id).pipe(
        filter((response: HttpResponse<any>) => response.ok),
        map((survey: HttpResponse<any>) => {
          const pagedQuestions = survey.body;
          return pagedQuestions;
          // return mapLodash(pagedQuestions, pagedQuestion => {
          //   pagedQuestion.sections = mapLodash(pagedQuestion.sections, section => {
          //     if (section.options != null) {
          //       section.options = JSON.parse(section.options);
          //     } else {
          //       question.options = {};
          //     }
          //     if (question.logic != null) {
          //       question.logic = JSON.parse(question.logic);
          //     } else {
          //       question.logic = {};
          //     }
          //     return question;
          //   });
          //   return pagedQuestion;
          // });
        })
      );
    }
    return of([]);
  }
}

const routes: Routes = [
  {
    path: ':id',
    redirectTo: ':id/design',
  },
  {
    path: ':id/responses',
    component: SurveyResponsesComponent,
    resolve: {
      surveyDefinition: SurveyDefinitionResolve,
      pagedQuestions: PagedQuestionsResolve,
    },
    // // canActivate: [UserRouteAccessService], //look into it for interview
    data: {
      tabId: 'responses',
      authorities: ['ROLE_USER'],
      pageTitle: 'surveymasterApp.surveyDefinition.responses',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],

  exports: [RouterModule],
})
export class SurveyDetailsRoutingModule {}
