import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-visualization',
  templateUrl: './visualization.component.html',
  styleUrls: ['./visualization.component.scss'],
})
export class VisualizationComponent implements OnInit {
  @Input('links')
  links = [];
  @Input('data')
  data = {};
  @Input('nodes')
  nodes = [];
  @Input('center$')
  center$: Subject<boolean> = new Subject();
  @Input('zoomToFit$')
  zoomToFit$: Subject<boolean> = new Subject();
  @Input('update$')
  update$: Subject<boolean> = new Subject();
  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.fitGraph();
      this.centerGraph();
    }, 100);
  }
  centerGraph = () => {
    this.center$.next(true);
  };
  fitGraph = () => {
    this.zoomToFit$.next(true);
  };
}
