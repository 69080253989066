<h2 mat-dialog-title>Create Event</h2>
<mat-dialog-content>
  <form>
    <div class="row">
      <div class="d-flex justify-content-start align-items-center mb-3">
        <div class="col-lg-6 col-md-6">
          <mat-form-field class="DSA_Wb_custom-form-field">
            <mat-label>Event Name</mat-label>
            <input
              matInput
              placeholder="Event Name"
              formControlName="eventName"
            />
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6">
          <mat-form-field
            class="DSA_Wb_custom-form-field DSA_form_nofloatLabel"
            floatLabel="never"
          >
            <mat-label>Event Type</mat-label>
            <mat-select
              formControlName="eventType"
              disableOptionCentering
              panelClass="DSA_wb-custom-select-panel"
              class="DSA_wb-custom-select"
            >
              <mat-option
                *ngFor="let option of eventTypeArray"
                [value]="option"
              >
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6">
          <mat-form-field
            class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixIcon"
            floatLabel="never"
          >
            <input
              matInput
              [matDatepicker]="picker"
              placeholder="From Date"
              (click)="picker.open()"
              formControlName="fromDate"
            />
            <mat-datepicker-toggle panelclass="DSA_wb_dateClass" [for]="picker">
              <i class="icon x-24 calendar-icon" matDatepickerToggleIcon></i>
            </mat-datepicker-toggle>
            <mat-datepicker
              panelclass="DSA_wb_dateClass"
              #picker
            ></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6">
          <mat-form-field
            class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixIcon"
            floatLabel="never"
          >
            <input
              matInput
              [matDatepicker]="picker1"
              placeholder="To Date"
              (click)="picker1.open()"
              formControlName="toDate"
            />
            <mat-datepicker-toggle matSuffix [for]="picker1">
              <i class="icon x-24 calendar-icon" matDatepickerToggleIcon></i>
            </mat-datepicker-toggle>
            <mat-datepicker
              panelclass="DSA_wb_dateClass"
              #picker1
            ></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6">
          <mat-form-field class="DSA_Wb_custom-form-field">
            <mat-label>Location</mat-label>
            <mat-chip-list #chipList>
              <mat-chip
                class="DSA_wb_chipStyle-Normal DSA_wb_chip-input marR16"
                disableRipple="true"
                *ngFor="let location of locationList"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(location)"
              >
                {{ location.name }}
                <i
                  matChipRemove
                  class="icon x-16 icon-close-mini DSA_wb_chipIconRight"
                  *ngIf="removable"
                ></i>
              </mat-chip>
              <input
                placeholder="New location..."
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)"
              />
            </mat-chip-list>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-flat-button
    mat-dialog-close="true"
    (click)="(onSubmit)"
    class="DSA_wb_primary-btn"
  >
    Create
  </button>
  <button mat-flat-button mat-dialog-close="false" class="DSA_wb_secondary-btn">
    Cancel
  </button>
</mat-dialog-actions>

<!--style="display:none" (change)="function()"-->
