import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EventNotificationsService {
  private headers = new HttpHeaders()
    .set('content-type', 'application/json');
  constructor(private http: HttpClient) {}

  getAllFilesInEvent = (
    eventId: string,
    selectedNotification: any
  ): Observable<any[]> => {
    let params = new HttpParams();
    params = params.append('specType', 'FILE');
    console.log(selectedNotification);
    if (selectedNotification !== 'PANELISTS') {
      params = params.append('subCategory', 'INTERVIEW');
    } else {
      params = params.append('subCategory', 'PANELIST');
    }
    const opts = {
      headers: this.headers,
      params,
    };

    return this.http.get<any[]>(
      environment.eventNotificationServiceUrl +
        `api/file-details/event/${eventId}`,
      opts
    );
  };

  getBulkUploadFileDetails = (
    fileId,
    page,
    size,
    status?,
    email?
  ): Observable<any[]> => {
    let params = new HttpParams();
    params = params.append('specType', 'FILE');

    if (status != 'ALL') {
      params = params.append('status', status);
    }
    if (email != '') {
      params = params.append('email', email);
    }

    params = params.append('page', `${page}`);
    params = params.append('size', `${size}`);
    const opts = {
      headers: this.headers,
      params,
    };

    return this.http.get<any[]>(
      environment.eventNotificationServiceUrl +
        'api/notification-details/' +
        fileId,
      opts
    );
  };
  getBulkUploadMailDetails = (
    fileId,
    page,
    size,
    status?,
    email?
  ): Observable<any[]> => {
    let params = new HttpParams();

    if (status != 'ALL') {
      params = params.append('status', status);
    }
    if (email != '') {
      params = params.append('email', email);
    }

    params = params.append('page', `${page}`);
    params = params.append('size', `${size}`);
    const opts = {
      headers: this.headers,
      params,
    };

    return this.http.get<any[]>(
      environment.eventNotificationServiceUrl +
        'api/mail-notification-details/' +
        fileId,
      opts
    );
  };
}
