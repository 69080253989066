<div *ngFor="let mail of recipients">
  <span
    *ngIf="type === 'status'"
    role="button"
    [matTooltip]="mail.userEmail"
    matTooltipClass="DSA_tooltip"
  >
    {{ mail.status }}</span
  >
  <span
    *ngIf="type === 'message'"
    role="button"
    [matTooltip]="mail.userEmail"
    matTooltipClass="DSA_tooltip"
  >
    {{ mail.status === 'FAILURE' && !mail.message.includes('Mail Template not
    added') ? 'Mail not sent. Please resend mail.' : mail.message}}
  </span>
  <span *ngIf="type === 'email'"> {{ mail.userEmail }}</span>
  <span *ngIf="type === 'category'"> {{ mail.specType }}</span>
</div>

<span *ngIf="type==='resend'">
  <a
    class="DSA_wb_hyperLink-btn"
    role="button"
    (click)="resendEmail(interviewId, 'candidate')"
    matTooltip="Resend Email - Candidate"
    matTooltipClass="DSA_tooltip"
    >Re-Send Email - Candidate</a
  ><br />
  <a
    class="DSA_wb_hyperLink-btn"
    role="button"
    (click)="resendEmail(interviewId, 'interviewer')"
    matTooltip="Resend Email - Interviewer"
    matTooltipClass="DSA_tooltip"
    >Re-Send Email - Interviewer</a
  >
</span>
