import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';
import { ExtractedResume } from 'src/app/shared/model/extracted-resume.model';
import { Job } from 'src/app/shared/model/Job-model';
import { JobApplication } from 'src/app/shared/model/jobApplication-model';
import { Address } from 'src/app/shared/model/Profile/address-model';
import {
  CareerDetails,
  employmentDTO,
} from 'src/app/shared/model/Profile/careerDetails-model';
import { ContactDetails } from 'src/app/shared/model/Profile/contactDetails-model';
import {
  EducationDetails,
  _educationDTO,
} from 'src/app/shared/model/Profile/educationDetails-model';
import { PersonalInfo } from 'src/app/shared/model/Profile/personalInfo-model';
import { Profile } from 'src/app/shared/model/Profile/profile-model';
import {
  ProjectDetails,
  projectDTO,
} from 'src/app/shared/model/Profile/projectDetails-model';
import { Work } from 'src/app/shared/model/Work/work-model';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';
import { allJobs as allJobsDummy, jobOpeningsDummy, onejobDummy } from './jobs';
import { ICandidateProfileUIDTO } from 'src/app/shared/model/candidate-profile-uidto.model';

@Injectable({
  providedIn: 'root',
})
export class CandidateInfoService {
  private headers = new HttpHeaders().set('content-type', 'application/json');
  // .set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));

  private blankHeaders = new HttpHeaders().set('content-type', 'text/plain');
  // .set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));

  headersFileUpload = new HttpHeaders();
  // .set(
  //   'Authorization',
  //   'Bearer ' + localStorage.getItem('access_token')
  // );

  // console.log(headers);
  RESUME_URL = '';
  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private userDetailService: AccountService
  ) {
    // console.log(this.headers);
  }

  public getJob(id: string) {
    // return of(onejobDummy);

    let body = new URLSearchParams();
    //body.set('id', id);
    const urlEncodedHeader = new HttpHeaders()
      .set('content-type', 'application/x-www-form-urlencoded')
      .set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));

    const opts = {
      headers: urlEncodedHeader,
    };
    return this.http.get(
      environment.JOBMS_API + '/public/job-openings/' + id,
      // body.toString(),
      //opts
      // { headers: { skip: 'true' } }
    );
  }

  public getJobByID(id: string) {
    // return of(onejobDummy);
    return this.http.get(
      environment.POSTMS_API + '/opportunities/' + id,
      // body.toString(),
      //opts
      // { headers: { skip: 'true' } }
    );
  }

  public getJobApplicationFiles(applicationId: string) {
    // return of(onejobDummy);

    const urlEncodedHeader = new HttpHeaders()
      .set('content-type', 'application/x-www-form-urlencoded')
      .set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));

    const opts = {
      headers: urlEncodedHeader,
    };

    return this.http.get(
      environment.JOBMS_API +
        '/application-files/applicationId/' +
        applicationId,
      opts
    );
  }

  public getCollegeList(
    page: number,
    size: number,
    query?: string,
    category?: string
    // filters?,
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);

    if (category) {
      params = params.append('subcategory', `${category}`);
    }

    if (query) {
      params = params.append('name', `${query}`);
      return this.http.get<any>(
        environment.UTILITYMS_API + '/public/_search/colleges',
        {
          headers: { skip: 'true' },
          params,
        }
      );
    }
    return this.http.get<any>(environment.UTILITYMS_API + '/public/colleges', {
      // headers: { skip: 'true' },
      params,
    });
  }

  getJobOpeningsFilters = () => {
    // return of(jobOpeningsDummy);
    return this.http.get(
      environment.SEARCHMS_API + '/posts/search-aggregations'
      // { headers: { skip: 'true' } }
    );
  };
  getApplicationsFilters = () => {
    // return of(jobOpeningsDummy);
    return this.http.get(
      environment.SEARCHMS_API + '/job-applications/search-aggregations',
      { headers: this.headers }
    );
  };

  //This is an open api
  public getAllJobs(
    page: number,
    size: number,
    query: string,
    opportunitySelected: string,
    filters?
  ): Observable<any> {
    // return of(allJobsDummy);
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);
    params = params.append('category', `${opportunitySelected}`);
    if (query) {
      params = params.append('query', `${query}`);
    }
    const temp = new HttpHeaders()
      .set('content-type', 'application/x-www-form-urlencoded')
      .set('skip', 'true');
    //.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));

    const body = this.sharedService.getUrlSearchParamsFromObject(filters);

    return this.http.get<any>(
      environment.POSTMS_API + '/opportunities/candidate',  //TODO 
      {
        params: params,
      }
      // body
      // {
      //   headers: temp,
      //   params,
      // }
    );
  }

  public getAllJobsForManager(
    size: number,
    page: number,
    query: string,
    opportunitySelected: string,
    filters?
  ): Observable<any> {
    // return of(allJobsDummy);
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);
    params = params.append('category', `${opportunitySelected}`);
    if (query) {
      params = params.append('s', `${query}`);
    }
    const temp = new HttpHeaders().set(
      'content-type',
      'application/x-www-form-urlencoded'
    );

    //.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));

    const body = this.sharedService.getUrlSearchParamsFromObject(filters);

    return this.http.get<any>(
      environment.POSTMS_API + '/opportunities/category',
      {
        params: params,
      }
      // body
      // {
      //   headers: temp,
      //   params,
      // }
    );
  }

  public getRecentJobs(
    page: number,
    size: number,
    query: string,
    filters?
  ): Observable<any> {
    // return of(allJobsDummy);
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);
    if (query) {
      params = params.append('s', `${query}`);
    }
    const temp = new HttpHeaders()
      .set('content-type', 'application/x-www-form-urlencoded')
      .set('skip', 'true');
    //.set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));

    const body = this.sharedService.getUrlSearchParamsFromObject(filters);

    // return this.http.get<any>(
    //   environment.SEARCHMS_API + '/public/recent-jobs',
    //   {
    //     headers: temp,
    //     params,
    //   }
    // );
    return this.http.get<any>(
      environment.SEARCHMS_API + '/public/recent-posts',
      {
        headers: temp,
        params,
      }
    );
  }

  public postProposal(
    jobApplication: JobApplication
  ): Observable<JobApplication> {
    return this.http.post<JobApplication>(
      environment.JOBMS_API + '/job-applications',
      jobApplication,
      {
        headers: this.headers,
      }
    );
  }

  public getAllSkillsAndClientName(): Observable<any> {
    return this.http.get<any>(environment.JOBMS_API + '/jobs', {
      headers: this.headers,
    });
  }

  // public filterJobByClientNameAndSkill(jobFilter: JobFilter): Observable<JobFilter> {
  //   return this.http.post<JobFilter>(environment.JOBMS_API + "/jobs/",
  //     jobFilter,
  //     {
  //       'headers': this.headers
  //     });
  // }

  public getApplicationOfJob(
    seekerId: string,
    jobOpeningId: string
  ): Observable<JobApplication> {
    const temp = new HttpHeaders()
      .set('content-type', 'application/x-www-form-urlencoded')
      .set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));

    return this.http.get<JobApplication>(
      environment.JOBMS_API +
      `/job-applications/seeker/${seekerId}/job/${jobOpeningId}`,
      {
        headers: temp,
      }
    );
  }

  public getAllCandidateApplications(
    seekerId: string,
    page: number,
    size: number,
    query: string,
    filters?
  ): Observable<any> {
    // return of(allJobsDummy);
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);
    if (query) {
      params = params.append('s', `${query}`);
    }
    const temp = new HttpHeaders().set(
      'content-type',
      'application/x-www-form-urlencoded'
    );
    // .set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));

    const body = this.sharedService.getUrlSearchParamsFromObject(filters);

    return this.http.get<any>(
      environment.JOBMS_API + '/applications/candidate/' + seekerId,
      //body,
      {
        headers: temp,
        params,
      }
    );
  }

  public getOpportunities(
    page: number,
    size: number,
    query: string,
    filters?,
    subCategory?: string,
    status?: string,

  ): Observable<any> {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);
    if (query) {
      params = params.append('s', `${query}`);
    }
    const body = this.sharedService.getUrlSearchParamsFromObject(filters);
    const urlEncodedHeader = new HttpHeaders()
      .set('content-type', 'application/x-www-form-urlencoded')
      .set('tenantId', '5');
    // return this.http.post<any>(
    //   environment.SEARCHMS_API + '/posts/search',
    //   body,
    //   {
    //     headers: urlEncodedHeader ,
    //     params,
    //   }
    // );
    return this.http.get<any>(environment.POSTMS_API + '/opportunities');
  }

  public getApplications(id: string): Observable<JobApplication> {
    return this.http.get<JobApplication>(
      environment.JOBMS_API + '/job-applications/' + id,
      {
        headers: this.headers,
      }
    );
  }

  updateJobApplication = (applicationId, updateForm): Observable<any> => {
    // /api/job-applications/creator/action/{applicationId}
    let params = new HttpParams();
    return this.http.put<any>(
      environment.JOBMS_API + `/applications/action/${applicationId}`,
      updateForm,
      {
        headers: this.headers,
        params: params,
      }
    );
  };

  sendMail(body: any): Observable<any> {
    let a = environment.UTILITYMS_API +'/emails/send'    
    let b= 0

    return this.http.post<any>(
      environment.UTILITYMS_API + '/emails/send',
      body,
      {
        headers: this.headers,
      }
    );
  }

  public cancelApplications(applicationId: string): Observable<any> {
    const temp = new HttpHeaders()
      .set('content-type', 'application/x-www-form-urlencoded')
      .set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    let body = {};
    return this.http.post<any>(
      environment.JOBMS_API +
      '/job-applications/seeker/withdraw/' +
      applicationId,
      body,
      {
        headers: this.headers,
      }
    );
  }

  public updateApplicationOfJob(
    jobApplication: JobApplication
  ): Observable<JobApplication> {
    return this.http.put<JobApplication>(
      environment.JOBMS_API + '/job-applications',
      jobApplication,
      {
        headers: this.headers,
      }
    );
  }

  getInstituteDetails = (): Observable<any> => {
    return this.http.get<any>(environment.UTILITYMS_API + '/colleges', {
      headers: this.headers,
    });
  };

  getDropdownValues = (valueType: string): Observable<any> => {
    let params = new HttpParams();
    params = params.append('valueType', `${valueType}`);
    return this.http.get<any>(
      environment.UTILITYMS_API + '/public/form-values/value',
      { params: params }
    );
  };

  getPostalDetailsByPostalCode = (postalCode): Observable<any> => {
    return this.http.get<any>(
      environment.UTILITYMS_API + '/pinCode/' + postalCode,
      {
        headers: this.headers,
      }
    );
  };

  getBankDetailsByPersonalDetailsId(
    personalDetailsId: number
  ): Observable<Profile> {
    return this.http.get<Profile>(
      environment.CANDIDATEMS_API +
        '/bank-details/personal-details/' +
        personalDetailsId,
      {
        headers: this.headers,
      }
    );
  }

  createBankDetails = (bankDetails) => {
    return this.http.post<any>(
      environment.CANDIDATEMS_API + '/bank-details',
      bankDetails,
      { headers: this.headers }
    );
  };

  updateBankDetails(bankDetails): Observable<any> {
    return this.http.put<any>(
      environment.CANDIDATEMS_API + '/bank-details/' + bankDetails.id,
      bankDetails,
      { headers: this.headers }
    );
  }
  deleteFinancialDetails(id: number): Observable<any> {
    return this.http.delete<any>(
      environment.CANDIDATEMS_API + '/financial-details/' + id,
      { headers: this.headers }
    );
  }
  updateFinancialDetails(financialDetailsDTOList): Observable<any> {
    return this.http.put<any>(
      environment.CANDIDATEMS_API +
        '/financial-details/' +
        financialDetailsDTOList.id,
      financialDetailsDTOList,
      { headers: this.headers }
    );
  }
  public getCandidateInfo(id: any): Observable<Profile> {
    //console.log(id);
    let params = new HttpParams();
    // id = 'er234-fef3243-e3r434';

    let role = this.userDetailService.getAccountLocal()['authorities'];
    if (role.includes('ROLE_CANDIDATE')) {
      // id = '5b1e4f59-3509-4a00-9d82-3e50900bc747';
      // params = params.append('userId', id);
      // console.log(id);
      // params = params.append("source", "CANDIDATE");
      // params = params.append("comment", "CANDIDATE ");
      const opts = {
        headers: this.headers,
        params,
      };
      return this.http.get<Profile>(
        environment.CANDIDATEMS_API + '/candidate-infos/user',
        opts
      );
    } else {
      // id = 'er234-fef3243-e3r434';
      console.log('candidate resume hiring manager');
      return this.http.get<Profile>(
        environment.CANDIDATEMS_API + '/candidate-infos/' + id.toString(),
        { headers: this.headers }
      );
    }
  }

  updateCandidateInfo(
    id: any,
    info: any //ExtractedResume
  ): Observable<Profile> {
    let params = new HttpParams();
    if (id) params = params.append('userId', id);
    const opts = {
      headers: this.headers,
      params,
    };
    return this.http.put<Profile>(
      environment.CANDIDATEMS_API + '/candidate-infos/' + info.id,
      info,
      opts
    );
  }
  patchUpdateCandidateInfo(info: any): Observable<Profile> {
    return this.http.patch<Profile>(
      environment.CANDIDATEMS_API + '/candidate-infos/' + info.id,
      info,
      { headers: this.headers }
    );
  }

  getCandidateProfileUIDTOForCandidate = (): Observable<any> => {
    return this.http.get<Profile>(
      environment.CANDIDATEMS_API + '/candidate-details',
      { headers: this.headers }
    );
  };

  patchUpdateCandidateProfileUIDTO = (
    candidateInfoId: number,
    payload: ICandidateProfileUIDTO
  ) => {
    return this.http.patch<Profile>(
      environment.CANDIDATEMS_API + '/candidate-details/' + candidateInfoId,
      payload,
      { headers: this.headers }
    );
  };
  createCandidateProfileForFirstTime = (payload: any) => {
    return this.http.post<Profile>(
      environment.CANDIDATEMS_API + '/candidate-details',
      payload,
      { headers: this.headers }
    );
  };

  getCandidateProfileUIDTOForProgramManager = (
    candidateInfoId: any
  ): Observable<any> => {
    return this.http.get<any>(
      environment.CANDIDATEMS_API + '/candidate-details/' + candidateInfoId,
      { headers: this.headers }
    );
  };


  getCandidateProfileUIDTO = (
    candidateInfoId: any
  ): Observable<any> => {
    return this.http.get<any>(
      environment.CANDIDATEMS_API + '/candidate-infos/' + candidateInfoId,
      { headers: this.headers }
    );
  };

  public updateCandidateInfoProfile(
    info: ExtractedResume
  ): Observable<Profile> {
    let params = new HttpParams();
    //params = params.append("userId", id);
    params = params.append('source', 'CANDIDATE');
    params = params.append('comment', 'CANDIDATE  Update Info');
    const opts = {
      headers: this.headers,
      params,
    };
    return this.http.put<Profile>(
      environment.CANDIDATEMS_API + '/candidate-infos/' + info.id,
      info,
      opts
    );
  }
  /// Get api functions for profile

  public getEducationDeatailsOfProfile(id: any): Observable<EducationDetails> {
    return this.http.get<EducationDetails>(
      environment.CANDIDATEMS_API + '/education-details/' + id,
      {
        headers: this.headers,
      }
    );
  }

  public getCareerDeatailsOfProfile(id: any): Observable<CareerDetails> {
    return this.http.get<CareerDetails>(
      environment.CANDIDATEMS_API + '/career-details/' + id,
      {
        headers: this.headers,
      }
    );
  }

  public getContactDeatailsOfProfile(id: any): Observable<ContactDetails> {
    return this.http.get<ContactDetails>(
      environment.CANDIDATEMS_API + '/contact-details/' + id,
      {
        headers: this.headers,
      }
    );
  }

  public getProjectDeatailsOfProfile(id: any): Observable<ProjectDetails> {
    return this.http.get<ProjectDetails>(
      environment.CANDIDATEMS_API + '/project-details/' + id,
      {
        headers: this.headers,
      }
    );
  }

  public getPersonalDeatailsOfProfile(id: any): Observable<PersonalInfo> {
    return this.http.get<PersonalInfo>(
      environment.CANDIDATEMS_API + '/personal-details/' + id,
      {
        headers: this.headers,
      }
    );
  }

  // update api functions for candidate profile

  public UpdatePersonalDeatailsOfProfile(
    personalinfo: PersonalInfo
  ): Observable<PersonalInfo> {
    let formData = personalinfo;
    let candidate_id = localStorage.getItem('candidate-id');
    //console.log(personalinfo);
    if (personalinfo.id) {
      return this.http.put<PersonalInfo>(
        environment.CANDIDATEMS_API + '/personal-details/' + personalinfo.id,
        formData,
        {
          headers: this.headers,
        }
      );
    }
    let params = new HttpParams();

    params = params.append('candidateInfoId', candidate_id);

    const opts = {
      headers: this.headers,
      params,
    };

    return this.http.post<PersonalInfo>(
      environment.CANDIDATEMS_API + '/personal-details',
      formData,
      opts
    );
  }

  public UpdateProjectDeatailsOfProfile(
    projectDetails: ProjectDetails
  ): Observable<ProjectDetails> {
    let candidate_id = localStorage.getItem('candidate-id');
    let formData = projectDetails;
    if (projectDetails.id)
      return this.http.put<ProjectDetails>(
        environment.CANDIDATEMS_API + '/project-details/' + projectDetails.id,
        formData,
        { headers: this.headers }
      );

    let params = new HttpParams();

    params = params.append('candidateInfoId', candidate_id);

    const opts = {
      headers: this.headers,
      params,
    };

    return this.http.post<ProjectDetails>(
      environment.CANDIDATEMS_API + '/project-details',
      formData,
      opts
    );
  }

  public UpdateContactDeatailsOfProfile(
    contactDetails: ContactDetails
  ): Observable<ContactDetails> {
    let candidate_id = localStorage.getItem('candidate-id');
    let formData = contactDetails;
    if (contactDetails?.id)
      return this.http.put<ContactDetails>(
        environment.CANDIDATEMS_API + '/contact-details/' + contactDetails.id,
        formData,
        {
          headers: this.headers,
        }
      );
    let params = new HttpParams();

    params = params.append('candidateInfoId', candidate_id);

    const opts = {
      headers: this.headers,
      params,
    };

    return this.http.post<ContactDetails>(
      environment.CANDIDATEMS_API + '/contact-details',
      formData,
      opts
    );
  }

  public UpdateCareerDeatailsOfProfile(
    careerDetails: CareerDetails
  ): Observable<CareerDetails> {
    let formData = careerDetails;
    let candidate_id = localStorage.getItem('candidate-id');
    if (careerDetails.id)
      return this.http.put<CareerDetails>(
        environment.CANDIDATEMS_API + '/career-details/' + careerDetails.id,
        formData,
        {
          headers: this.headers,
        }
      );

    let params = new HttpParams();
    params = params.append('candidateInfoId', candidate_id);

    const opts = {
      headers: this.headers,
      params,
    };

    return this.http.post<CareerDetails>(
      environment.CANDIDATEMS_API + '/career-details',
      formData,
      opts
    );
  }

  patchUpdatePersonalDetails = (
    personalDetails: PersonalInfo
  ): Observable<PersonalInfo> => {
    return this.http.patch<PersonalInfo>(
      environment.CANDIDATEMS_API + '/personal-details/' + personalDetails.id,
      personalDetails,
      { headers: this.headers }
    );
  };

  createContactDetailsByCandidateInfo = (candidateInfoId, contactDetails) => {
    let params = new HttpParams();
    params = params.append('candidateInfoId', candidateInfoId);

    return this.http.post<ContactDetails>(
      environment.CANDIDATEMS_API + '/contact-details',
      contactDetails,
      { headers: this.headers, params }
    );
  };

  updateContactDetailsById = (contactDetails): Observable<any> => {
    return this.http.put<ContactDetails>(
      environment.CANDIDATEMS_API + '/contact-details/' + contactDetails.id,
      contactDetails,
      { headers: this.headers }
    );
  };

  deleteContactDetailsById = (id) => {
    return this.http.delete<any>(
      environment.CANDIDATEMS_API + '/contact-details/' + id,
      { headers: this.headers }
    );
  };

  createEducationDetailsByCandidateInfoId = (
    candidateInfoId,
    educationDetails
  ): Observable<any> => {
    let params = new HttpParams();
    params = params.append('candidateInfoId', candidateInfoId);

    return this.http.post<EducationDetails>(
      environment.CANDIDATEMS_API + '/education-details',
      educationDetails,
      { headers: this.headers, params }
    );
  };

  updateEducationDetailsInCandidateInfo = (
    educationDetails
  ): Observable<any> => {
    return this.http.put<EducationDetails>(
      environment.CANDIDATEMS_API + '/education-details/' + educationDetails.id,
      educationDetails,
      { headers: this.headers }
    );
  };

  deleteEducationDetailsById = (id) => {
    return this.http.delete<any>(
      environment.CANDIDATEMS_API + '/education-details/' + id,
      { headers: this.headers }
    );
  };

  public UpdateEducationDeatailsOfProfile(
    educationDetails: EducationDetails
  ): Observable<EducationDetails> {
    let formData = educationDetails;
    let candidate_id = localStorage.getItem('candidate-id');
    if (educationDetails.id)
      return this.http.put<EducationDetails>(
        environment.CANDIDATEMS_API +
        '/education-details/' +
        educationDetails.id,
        formData,
        {
          headers: this.headers,
        }
      );

    let params = new HttpParams();
    params = params.append('candidateInfoId', candidate_id);

    const opts = {
      headers: this.headers,
      params,
    };

    return this.http.post<EducationDetails>(
      environment.CANDIDATEMS_API + '/education-details',
      formData,
      opts
    );
  }

  // update api functions for candidate profile Ends here............

  // Adding data to History DTO Functions
  public AddEducationDeatailsOfProfile(
    educationDetails: _educationDTO,
    educationDetailsId: number
  ): Observable<_educationDTO> {
    let formData = educationDetails;
    let candidate_id = localStorage.getItem('candidate-id');
    let params = new HttpParams();
    params = params.append('id', educationDetailsId);

    const opts = {
      headers: this.headers,
      params,
    };
    if (educationDetailsId)
      return this.http.post<_educationDTO>(
        environment.CANDIDATEMS_API + '/education-histories',
        formData,
        opts
      );
  }

  public AddProjectDeatailsOfProfile(
    projectDetails: projectDTO,
    projectDetailsId: number
  ): Observable<projectDTO> {
    let candidate_id = localStorage.getItem('candidate-id');
    let formData = projectDetails;
    let params = new HttpParams();
    params = params.append('id', projectDetailsId);

    const opts = {
      headers: this.headers,
      params,
    };
    if (projectDetailsId)
      return this.http.post<projectDTO>(
        environment.CANDIDATEMS_API + '/project-histories',
        formData,
        opts
      );
  }
  public AddCareerDeatailsOfProfile(
    employmentDetails: employmentDTO,
    careerDetailsId: number
  ): Observable<employmentDTO> {
    let formData = employmentDetails;
    let candidate_id = localStorage.getItem('candidate-id');
    let params = new HttpParams();
    params = params.append('id', careerDetailsId);
    const opts1 = {
      headers: this.headers,
      params,
    };
    if (careerDetailsId)
      return this.http.post<CareerDetails>(
        environment.CANDIDATEMS_API + '/employment-histories',
        formData,
        opts1
      );
  }

  ///////////////////////////////////////

  public getAllWorksOfParticipant(participantId: string): Observable<Work[]> {
    return this.http.get<Work[]>(
      environment.WORKMS_API + '/works/seeker/' + participantId,
      {
        headers: this.headers,
      }
    );
  }

  public getAllMilestones(workId: any): Observable<Work> {
    return this.http.get<Work>(environment.WORKMS_API + '/works/' + workId, {
      headers: this.headers,
    });
  }

  public getWork(id: string): Observable<Work> {
    return this.http.get<Work>(environment.WORKMS_API + '/works/' + id, {
      headers: this.headers,
    });
  }

  public verifyScholarshipDisbursed(transactionID: string,id: string): Observable<any> {
      return this.http.post<any>(environment.JOBMS_API + `/scholarship-disbursements/verify/${id}?passKey=${transactionID}`, {
        headers: this.headers,
      })
  }

  public updateDelivery(work: Work): Observable<Work> {
    return this.http.put<Work>(environment.WORKMS_API + '/works', work, {
      headers: this.headers,
    });
  }

  public updateProfileAddress(address: Address): Observable<Address> {
    return this.http.put<Address>(
      environment.CANDIDATEMS_API + '/addresses',
      address,
      {
        headers: this.headers,
      }
    );
  }

  public updateProfileContact(
    contact: ContactDetails
  ): Observable<ContactDetails> {
    return this.http.put<ContactDetails>(
      environment.CANDIDATEMS_API + '/contact-details',
      contact,
      {
        headers: this.headers,
      }
    );
  }

  public updateProfilePersonalInfo(
    info: PersonalInfo
  ): Observable<PersonalInfo> {
    return this.http.put<PersonalInfo>(
      environment.CANDIDATEMS_API + '/personal-infos',
      info,
      {
        headers: this.headers,
      }
    );
  }

  public updateProfileInfo(info: Profile): Observable<Profile> {
    return this.http.put<Profile>(
      environment.CANDIDATEMS_API + '/profiles',
      info,
      {
        headers: this.headers,
      }
    );
  }

  public postDocument(document: any, docType: string): Observable<String> {
    let param = new HttpParams();
    param = param.append('doc_type', docType);
    return this.http.post<String>(
      environment.FILEMS_API + '/file/upload',
      document,
      { params: param, headers: this.headersFileUpload }
    );
  }

  abc = map((response): any => {
    // eaders.headers.get('x-total-count')
    return response;
  });

  public getDocument(fileId: any): Observable<any> {
    return this.http.get<any>(
      environment.FILEMS_API + '/file/download/' + fileId,
      {
        observe: 'response',
        responseType: 'blob' as 'json',
        headers: this.headers,
      }
    );
    //.pipe(this.abc);
  }

  public getResumeDetail(id: any): Observable<any> {
    return this.http.get<any>(
      environment.CANDIDATEMS_API + '/profiles-complete/' + id,
      {
        headers: this.headers,
      }
    );
  }

  public postCandidateJobApplication(
    candidateInfoId,
    jobApplicationId
  ): Observable<any> {
    let jobApplicationObject = {};
    jobApplicationObject = {
      applicantId: Number(candidateInfoId),
      status: 'INTERMITTENT',
      opportunity: {
        id: Number(jobApplicationId),
      },
    };
    // console.log(jobApplicationObject);
    return this.http.post<any>(
      environment.POSTMS_API + '/applications',
      jobApplicationObject,
      {
        headers: this.headers,
      }
    );
  }

  public patchCandidateJobApplication(
    id,
    status: string = 'SUBMITTED'
  ): Observable<any> {
    let jobApplicationObject = {};

    jobApplicationObject = {
      id: Number(id),
      status: status,
    };
    console.log(jobApplicationObject);
    return this.http.patch<any>(
      environment.POSTMS_API + '/applications/' + id,
      jobApplicationObject,
      {
        headers: this.headers,
      }
    );
  }

  public submitTeamApplication(teamData: any): Observable<any> {
    return this.http.post<any>(
      environment.POSTMS_API + '/applications/' + teamData.applicationsId + '/team',
      teamData,
      {
        headers: this.headers,
      }
    );
  }

  public getTeamDetails(applicationId: any): Observable<any> {
    return this.http.get<any>(
      environment.formsMS + '/applications/' + applicationId + '/team',
      {
        headers: this.headers,
      }
    );
  }

  public getTeamNames(): Observable<any> {
    return this.http.get<any>(
      environment.POSTMS_API + '/teams/names',
      {
        headers: this.headers,
      }
    );
  }

  public getBasicDetailsForm(id: number): Observable<any> {
    return this.http.get<any>(
      environment.formsMS + '/api/evaluation-forms?evaluationEventId=' + id + '&formType=ONBOARDING&isActive=true&formData=true',
      {
        headers: this.headers,
      }
    );
  }

  public getApplicationLimit(key : string): Observable<any> {
    return this.http.get<any>(
      environment.UTILITYMS_API + '/setting/application?key=' + key,
      {
        headers: this.headers,
      }
    );
  }

  public getSynopsisDetailsForm(id: number): Observable<any> {
    return this.http.get<any>(
      environment.formsMS + '/api/evaluation-forms?evaluationEventId=' + id + '&formType=SYNOPSIS&isActive=true&formData=true',
      {
        headers: this.headers,
      }
    );
  }


  public getTeamDetailsForm(id: number,applicantId:number): Observable<any> {
    return this.http.get<any>(
      environment.formsMS + 'api/evaluation-feedback/OPPORTUNITY/' + id+'?interactionId='+applicantId,
      {
        headers: this.headers,
      }
    );
  }
  public getSynopsisForm(id: number,applicantId:number): Observable<any> {
    return this.http.get<any>(
      environment.formsMS + 'api/evaluation-feedback/SYNOPSIS/' + id +'?interactionId='+applicantId,
      {
        headers: this.headers,
      }
    );
  }

  public submitTeamDetails(data: any) {
    return this.http.post<any>(
      environment.formsMS + 'api/evaluation-feedbacks',
      data, {
      headers: this.headers,
    }
    )
  }

  public patchTeamDetails(data: any) {
    return this.http.put<any>(
      environment.formsMS + 'api/evaluation-feedbacks',
      data, {
      headers: this.headers,
    }
    )
  }
  public updateTeamDetails(data: any,id:any) {
    return this.http.put<any>(
      environment.formsMS + 'api/evaluation-feedbacks/'+id,
      data, {
      headers: this.headers,
    }
    )
  }

  public removeTeammate(teamMateId: any): Observable<any> {
    return this.http.delete<any>(
      environment.POSTMS_API + '/team-members/' + teamMateId,
      {
        headers: this.headers,
      }
    );
  }

  public createApplicationAndCandidate(opportunityId: any) {
    return this.http.post<any>(
      environment.POSTMS_API + '/applications/opportunity/' + opportunityId,
      {
        status: 'INTERMITTENT',
      },

      {
        headers: this.headers,
      }
    );
  }
  // applicationId = new Subject<any>();
  // setApplicationId(applicationId: any) {
  //   this.applicationId.next(applicationId);
  // }
  public getApplicationByCandidateIdAndOpportunityId(
    applicantId: number,
    opportunityId: number
  ) {
    // if(applicantId && opportunityId){
    return this.http.get<any>(
      environment.POSTMS_API +
      '/applications/' +
      applicantId +
      '/opportunity/' +
      opportunityId,

      {
        headers: this.headers,
      }
    );
    // }
  }
  public getJobApplicationsOfAUser(
    candidateInfoId,
    jobApplicationId: Number
  ): Observable<any> {
    return this.http.get<any>(
      environment.JOBMS_API +
      `/job-applications/seeker/${candidateInfoId}/job/${jobApplicationId}`,
      {
        headers: this.headers,
      }
    );
  }

  public validateResume(candidateInfoId, jobApplicationId): Observable<any> {
    let param = new HttpParams();
    param = param.append('candidateInfoId', candidateInfoId);
    let role = this.userDetailService.getAccountLocal()['authorities'];
    if (role.includes('ROLE_CANDIDATE')) {
      param = param.append('source', 'CANDIDATE');
    } else {
      param = param.append('source', 'HIRING_MANAGER');
      param = param.append('jobApplicationId', jobApplicationId);
      // param=param.append('jobApplicationId', );
    }
    return this.http.put<any>(
      environment.CANDIDATEMS_API + '/candidate-infos/validate',
      {},
      {
        headers: this.blankHeaders,
        params: param,
      }
    );
  }

  public getFileStatus(userId: any): Observable<any> {
    let param = new HttpParams();
    // param = param.append('userId', userId);
    return this.http.get<any>(environment.CANDIDATEMS_API + '/failed-files', {
      headers: this.headers,
      params: param,
    });
  }

  public getIssuedDocumentsList(applicationId: any): Observable<any> {
    return this.http.get<any>(
      environment.POSTMS_API + `/issued-documents/application/${applicationId}`
    );
  }

  public acceptProvisioalOfferLetter(
    documentId: any,
    reqBody
  ): Observable<any> {
    return this.http.post<any>(
      environment.POSTMS_API +
      `/issued-documents/${documentId}/declaration-acceptance`,
      reqBody,
      {
        headers: this.headers,
      }
    );
  }

  public fileDownload(documentId: any): Observable<any> {
    return this.http.get<any>(
      environment.POSTMS_API + `/issued-documents/sync/${documentId}`
    );
  }

  public postApplicationByOpportunityId(
    opportunityId: number
  ) {
    // if(applicantId && opportunityId){
    return this.http.post<any>(
      environment.POSTMS_API +
        '/applications/' +
        'opportunity/' +
        opportunityId,

      {
        headers: this.headers,
      }
    );
    // }
  }

  public getApplicationByOpportunityId(
    opportunityId: number
  ) {
    // if(applicantId && opportunityId){
    return this.http.get<any>(
      environment.POSTMS_API +
        '/applications/' +
        'opportunity/' +
        opportunityId,

      {
        headers: this.headers,
      }
    );
    // }
  }
  public checkTeamName(name:string,id,body:any){
    return this.http.put<any>(environment.POSTMS_API+'/teams/validate-team-name?teamName='+name+'&applicationId='+id,
    body,
    {
      headers: this.headers
    }
    )
  }

  public generateDocument(applicationId){
    return this.http.get<any>(environment.POSTMS_API+'/applications/issued-document/'+applicationId,
    {
      headers: this.headers
    }
    )
  }

  public getFileId(id:any){
    return this.http.get<any>(environment.POSTMS_API+'/issued-document/latest-application/'+id,
    {
      headers: this.headers
    }
    )
  }

  public getApplicationStatus(id: any): Observable<any> {
    return this.http.get<any>(
      environment.POSTMS_API + '/applications/' + id,
      {
        headers: this.headers,
      }
    );
  }
   
  public changeApplicationStatus(id: any,body): Observable<any> {
    return this.http.put<any>(
      environment.POSTMS_API + '/applications/action/' + id,
      body,
      {
        headers: this.headers,
      }
    );
  }

  public fpostApplicationByOpportunityId(
    opportunityId: number
  ) {
    // if(applicantId && opportunityId){
    return this.http.post<any>(
      environment.POSTMS_API +
        '/applications/' +
        'opportunity/' +
        opportunityId+'?createIfMissing=false&preFillWithLatest=false',

      {
        headers: this.headers,
      }
    );
    // }
  }
  public tpostApplicationByOpportunityId(
    opportunityId: number
  ) {
    // if(applicantId && opportunityId){
    return this.http.post<any>(
      environment.POSTMS_API +
        '/applications/' +
        'opportunity/' +
        opportunityId+'?createIfMissing=true&preFillWithLatest=true',

      {
        headers: this.headers,
      }
    );
    // }
  }

  public isPartofTeam() {
    return this.http.get(
      environment.POSTMS_API + '/applications/my-team-detail',
      {
        headers: this.headers,
      }
    );
  }

}
