/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/tslint/config */
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { Visibility } from 'src/app/shared/model/enumerations/visibility.model';
import { FileserverService } from 'src/app/survey-master/survey/edit-backgroud-color/fileserver.service';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import {
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from 'ngx-image-cropper';
import { base64ToFile } from 'ngx-image-cropper';
import { Theme, QuestionView } from 'src/app/shared/model/theme.model';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return control && control.invalid;
  }
}

@Component({
  selector: 'jhi-photo-upload-preview',
  templateUrl: './photo-upload.component.html',
  styleUrls: ['./photo-upload.component.scss'],
})
export class PhotoUploadPreviewComponent implements OnInit, OnChanges {
  @Input()
  currPage: any;
  @Input()
  question: any;
  @Input()
  questionIndex: any;
  @Input()
  responsePage: any;
  @Input()
  section: any;
  @Input()
  preference: any;
  @Input()
  theme: Theme;

  view = 'NORMAL';
  @Input()
  disabled: Boolean = false;
  @Input()
  nextElement: HTMLElement;

  matcher = new MyErrorStateMatcher();
  private readonly notifier: NotifierService;
  control: FormControl;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  fileName: string = null;
  fullFile: File;
  useSquare: boolean;

  constructor(
    public fileserverService: FileserverService,
    notifierService: NotifierService
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.control = new FormControl('');
    if (this.theme.questionView === QuestionView.TWO_COLUMNS) {
      this.view = 'TWO_COLUMNS';
    } else {
      this.view = 'NORMAL';
    }
    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response != null &&
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response.length > 0
    ) {
      // already some response present
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response = [];
    }

    this.useSquare = this.getValue(this.question, 'USE_SQUARE') === 'true';
    // this.responsePage.sections[this.section].questions[this.questionIndex].response.push('');
  }

  ngOnChanges() {
    this.control = new FormControl('');
    if (this.theme.questionView === QuestionView.TWO_COLUMNS) {
      this.view = 'TWO_COLUMNS';
    } else {
      this.view = 'NORMAL';
    }
    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response != null &&
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response.length > 0
    ) {
      // already some response present
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response = [];
    }

    this.useSquare = this.getValue(this.question, 'USE_SQUARE') === 'true';
  }

  downloadImage(path: string) {
    return this.fileserverService.getFileURLbyPath(path);
  }

  private getNewFile(file: File, fileName: string): File {
    const blob = file.slice(0, file.size, file.type);
    return new File([blob], fileName, { type: file.type });
  }

  inputChanged() {}

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;

    this.fileName = event.target.files[0].name;
    this.responsePage.sections[this.section].questions[
      this.questionIndex
    ].isValid = true;
    this.responsePage.sections[this.section].questions[
      this.questionIndex
    ].validationMessage = '';
  }

  save() {
    this.notifier.notify('info', 'Uploading....');
    const file = this.fullFile;
    const fileName =
      Date.now().toString(36) +
      Math.random().toString(36).substr(2, 5) +
      '.' +
      this.fileName.split('.').pop();
    const newFile = this.getNewFile(file, fileName);
    this.fileserverService
      .saveFile(newFile, this.currPage.surveyId + '_' + this.question.id)
      .subscribe(
        (res: any) => {
          if (
            this.responsePage.sections[this.section].questions[
              this.questionIndex
            ].response.length === 0
          ) {
            this.responsePage.sections[this.section].questions[
              this.questionIndex
            ].response.push(
              this.currPage.surveyId + '_' + this.question.id + '/' + fileName
            );
          } else {
            this.responsePage.sections[this.section].questions[
              this.questionIndex
            ].response[0] =
              this.currPage.surveyId + '_' + this.question.id + '/' + fileName;
          }
          // this.uploadedFiles.push(file);
          this.notifier.notify('success', file.name + ' Uploaded!');
          if (this.nextElement != null) {
            this.nextElement.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
            setTimeout(() => {
              if (this.nextElement.getElementsByTagName('input') != null)
                this.nextElement.getElementsByTagName('input')[0].focus();
            }, 400);
          }
        },
        (res: HttpErrorResponse) => {
          this.notifier.notify('error', file.name + ' could not be uploaded!');
        }
      );
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    const fileToReturn = base64ToFile(event.base64);
    const file = new File([fileToReturn], this.fileName, {
      type: fileToReturn.type,
    });
    this.fullFile = file;
  }

  // base64ToFile(data, filename) {

  //   const arr = data.split(',');
  //   const mime = arr[0].match(/:(.*?);/)[1];
  //   const bstr = atob(arr[1]);
  //   let n = bstr.length;
  //   let u8arr = new Uint8Array(n);

  //   while(n--){
  //       u8arr[n] = bstr.charCodeAt(n);
  //   }

  //   return new File([u8arr], filename, { type: mime });
  // }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {}

  loadImageFailed() {
    this.responsePage.sections[this.section].questions[
      this.questionIndex
    ].isValid = false;
    this.responsePage.sections[this.section].questions[
      this.questionIndex
    ].validationMessage = 'Please upload a jpeg or png file';
    this.fileName = null;
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation,
    };
  }
  // logicPart() {
  //   if (this.question.logics !== null && this.question.logics.length > 0) {
  //     for (let i = 0; i < this.question.logics.length; i++) {
  //       let apply = false;
  //       let condition = 'END';
  //       for (let r = 0; r < this.question.logics[i].rules.length; r++) {
  //         let rapply = false;

  //         if (this.question.logics[i].rules[r].op === 'EQ') {
  //           if (
  //             this.responsePage.sections[this.section].questions[this.questionIndex].response[0] === this.question.logics[i].rules[r].value
  //           ) {
  //             rapply = true;
  //           }
  //         } else if (this.question.logics[i].rules[r].op === 'NEQ') {
  //           if (
  //             this.responsePage.sections[this.section].questions[this.questionIndex].response[0] !== this.question.logics[i].rules[r].value
  //           ) {
  //             rapply = true;
  //           }
  //         } else if (this.question.logics[i].rules[r].op === 'CONTAINS') {
  //           if (
  //             this.responsePage.sections[this.section].questions[this.questionIndex].response[0].includes(
  //               this.question.logics[i].rules[r].value
  //             )
  //           ) {
  //             rapply = true;
  //           }
  //         }

  //         if (r > 0) {
  //           if (condition === 'AND') {
  //             apply = apply && rapply;
  //           } else if (condition === 'OR') {
  //             apply = apply || rapply;
  //           }
  //         } else {
  //           apply = rapply;
  //         }
  //         condition = this.question.logics[i].rules[r].conjunction;
  //       }

  //       for (let a = 0; a < this.question.logics[i].actions.length; a++) {
  //         let foundIndex: number = null;
  //

  //         const actionquestion = this.currPage.sections[this.section].questions.find((ques, ind) => {
  //           if (ques.identifier === this.question.logics[i].actions[a].identifier) {
  //             foundIndex = ind;
  //             return true;
  //           } else {
  //             return false;
  //           }
  //         });
  //

  //         if (apply) {
  //           if (this.question.logics[i].actions[a].name === 'HIDE') {
  //             if (actionquestion === undefined || actionquestion === null) {
  //               //nothing to hide show
  //             } else {
  //               this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = true;
  //               const exists = this.responsePage.sections[this.section].questions[foundIndex].logicallyHiddenBy.find(
  //                 item => item === this.question.id
  //               );
  //               if (exists === undefined) {
  //                 this.responsePage.sections[this.section].questions[foundIndex].logicallyHiddenBy.push(this.question.id);
  //               }
  //             }
  //           } else if (this.question.logics[i].actions[a].name === 'SHOW') {
  //             if (actionquestion === undefined || actionquestion === null) {
  //               //nothing to hide show
  //             } else {
  //               const exists = this.responsePage.sections[this.section].questions[foundIndex].logicallyShownBy.find(
  //                 item => item === this.question.id
  //               );
  //               if (exists === undefined) {
  //                 this.responsePage.sections[this.section].questions[foundIndex].logicallyShownBy.push(this.question.id);
  //               }
  //               if (this.responsePage.sections[this.section].questions[foundIndex].logicallyHiddenBy.length === 0) {
  //                 this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = false;
  //               } else {
  //                 this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = true;
  //               }
  //             }
  //           }
  //         } else {
  //           if (this.question.logics[i].actions[a].name === 'HIDE') {
  //             if (actionquestion === undefined || actionquestion === null) {
  //               //nothing to hide show
  //             } else {
  //               const exists = this.responsePage.sections[this.section].questions[foundIndex].logicallyHiddenBy.find(
  //                 item => item === this.question.id
  //               );
  //               if (exists !== undefined) {
  //
  //                 this.responsePage.sections[this.section].questions[foundIndex].logicallyHiddenBy = this.responsePage.sections[
  //                   this.section
  //                 ].questions[foundIndex].logicallyHiddenBy.filter(item => item !== this.question.id);
  //               }
  //               if (this.responsePage.sections[this.section].questions[foundIndex].logicallyHiddenBy.length === 0) {
  //                 if (this.responsePage.sections[this.section].questions[foundIndex].logicallyShownBy.length === 0) {
  //
  //                   if (this.responsePage.sections[this.section].questions[foundIndex].defaultVisibile) {
  //
  //                     this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = false;
  //                   } else {
  //                     this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = true;
  //                   }
  //                 } else {
  //                   this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = false;
  //                 }
  //               }
  //             }
  //           } else if (this.question.logics[i].actions[a].name === 'SHOW') {
  //
  //             if (actionquestion === undefined || actionquestion === null) {
  //               //nothing to hide show
  //             } else {
  //               this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = true;
  //               const exists = this.responsePage.sections[this.section].questions[foundIndex].logicallyShownBy.find(
  //                 item => item === this.question.id
  //               );
  //               if (exists !== undefined) {
  //                 this.responsePage.sections[this.section].questions[foundIndex].logicallyShownBy = this.responsePage.sections[
  //                   this.section
  //                 ].questions[foundIndex].logicallyShownBy.filter(item => item !== this.question.id);
  //               }
  //               if (this.responsePage.sections[this.section].questions[foundIndex].logicallyShownBy.length === 0) {
  //                 if (this.responsePage.sections[this.section].questions[foundIndex].logicallyHiddenBy.length === 0) {
  //                   if (this.responsePage.sections[this.section].questions[foundIndex].defaultVisibile) {
  //                     this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = false;
  //                   } else {
  //                     this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = true;
  //                   }
  //
  //                 } else {
  //                   this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = true;
  //                 }
  //               } else {
  //                 if (this.responsePage.sections[this.section].questions[foundIndex].logicallyHiddenBy.length === 0) {
  //                   this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = false;
  //                 } else {
  //                   this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = true;
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  getValidationValue(question: any, param: string): string {
    const object = question.type.validationValues.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }

  getValue(question: any, param: string): string {
    const object = question.type.values.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }
}
