import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RecruiterDashboardStateStorageService {
  page = 0;
  size = 10;
  queryEventName = '';

  checkedEventsSet = new Set();

  constructor() {}

  addCheckedEvent = (id) => {
    this.checkedEventsSet.add(id);
  };

  removeCheckedEvent = (id) => {
    this.checkedEventsSet.delete(id);
  };

  getCheckedStatus = (id) => {
    return this.checkedEventsSet.has(id);
  };

  getAllCheckedEvents = () => {
    return Array.from(this.checkedEventsSet);
  };

  clearAllCheckedEvents = () => {
    this.checkedEventsSet.clear();
  };

  getCheckedEventsSize = () => {
    return this.checkedEventsSet.size;
  };
}
