<fieldset [disabled]="disabled">
  <div *ngIf="view === 'TWO_COLUMNS'; else oneColumn">
    <div class="row">
      <div
        class="col-6"
        style="display: flex; flex-direction: column; justify-content: center"
      >
        <p>
          <span
            *ngIf="
              responsePage.sections[section].questions[questionIndex]
                .showNumbers
            "
          >
            {{ questionIndex + 1 }}.
          </span>
          {{ question.text
          }}<span
            *ngIf="question.preference.isRequired === 1"
            style="margin-left: 2px; color: red"
          >
            *
          </span>
        </p>
        <img
          *ngIf="question.logo !== null"
          [src]="downloadImage(question.logo.path)"
          class="question-image"
        />
      </div>
      <div class="col-6">
        <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel">
          <mat-label attr.for="{{ question.id }}"
            >{{ question.hint.text }}
          </mat-label>
          <mat-select
            [id]="question.id"
            [disabled]="disabled"
            [required]="question.preference.isRequired === 1"
            [formControl]="control"
            (selectionChange)="inputChanged($event)"
            [(ngModel)]="
              responsePage.sections[section].questions[questionIndex]
                .response[0]
            "
            disableOptionCentering  panelClass="DSA_wb-custom-select-panel" class="DSA_wb-custom-select"
          >
            <mat-option
              *ngFor="let option of question.type.options"
              [value]="option.value"
            >
              {{ option.value }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              (control.dirty || control.touched) &&
              !responsePage.sections[section].questions[questionIndex].isValid
            "
          >
            {{
              responsePage.sections[section].questions[questionIndex]
                .validationMessage
            }}</mat-error
          >
        </mat-form-field>
      </div>
    </div>
  </div>
  <ng-template #oneColumn>
    <p>
      <span
        *ngIf="
          responsePage.sections[section].questions[questionIndex].showNumbers
        "
      >
        {{ questionIndex + 1 }}.
      </span>
      {{ question.text
      }}<span
        *ngIf="question.preference.isRequired === 1"
        style="margin-left: 2px; color: red"
      >
        *
      </span>
    </p>
    <img
      *ngIf="question.logo !== null"
      [src]="downloadImage(question.logo.path)"
      class="question-image"
    />
    <mat-form-field>
      <mat-label attr.for="{{ question.id }}"
        >{{ question.hint.text }}
      </mat-label>
      <mat-select
        [id]="question.id"
        [disabled]="disabled"
        [required]="question.preference.isRequired === 1"
        [formControl]="control"
        (selectionChange)="inputChanged($event)"
        [(ngModel)]="
          responsePage.sections[section].questions[questionIndex].response[0]
        "
      >
        <mat-option
          *ngFor="let option of question.type.options"
          [value]="option.value"
        >
          {{ option.value }}
        </mat-option>
      </mat-select>
      <mat-error
        *ngIf="
          (control.dirty || control.touched) &&
          !responsePage.sections[section].questions[questionIndex].isValid
        "
      >
        {{
          responsePage.sections[section].questions[questionIndex]
            .validationMessage
        }}</mat-error
      >
    </mat-form-field>
  </ng-template>
</fieldset>
