<div class="container-fluid pb-5 marT32">
  <div class="mt-4">
    <span class="DSA_breadcrumb mt-5 mb-4 ml-2">
      <a>Extracted resumes</a>
      <a
        style="
          font-size: medium;
          font-weight: bold;
          text-transform: capitalize !important;
        "
        >{{ extractedResume?.firstName || "candidate" }}</a
      >
    </span>
    <!-- <span
      class="pull-right"
      style="margin: 0px !important; margin-right: 0 !important"
    >
      <a
        ><i class="material-icons arrow-button" (click)="showPrevious()"
          >keyboard_arrow_left</i
        ></a
      >

      <a>
        <i class="material-icons arrow-button" (click)="showNext()"
          >keyboard_arrow_right</i
        ></a
      >
    </span> -->
  </div>

  <div *ngIf="spinner; else NoSpinner">
    <app-loader [display]="spinner"></app-loader>
  </div>
  <ng-template #NoSpinner>
    <div class="row mt-4" *ngIf="true">
      <div class="col-md-6" *ngIf="true">
        <div class="card-header pt-3" style="font-size: large">
          Original Resume
        </div>

        <div>
          <app-loader [display]="spinner2"></app-loader>

          <pdf-viewer
            style="
              display: block;
              overflow-x: hidden !important;
              height: 945px !important;
            "
            [render-text]="true"
            [render-text-mode]="2"
            [src]="doc"
            [fit-to-page]="true"
            [original-size]="false"
            (error)="onPdfError($event)"
            (text-layer-rendered)="pdfLoaded($event)"
            (after-load-complete)="afterLoadComplete($event)"
          ></pdf-viewer>
          <!-- <pdf-viewer [src]="doc" [render-text]="true" style="display: block;"></pdf-viewer> -->
        </div>
      </div>

      <div class="col-md-6" style="padding-left: 0px !important">
        <div class="card-header" style="font-size: large">Extracted Data</div>
        <div
          mat-stretch-tabs
          class="DSA_wb_customTab DSA_navTabWhite DSA_wb_Secondary mat-elevation-z4"
          style="max-width: 700px; overflow-x: hidden"
        >
          <mat-tab-group disableRipple>
            <mat-tab class="fixed-width" label="Basic">
              <ng-template mat-tab-label> Basic </ng-template>
              <ng-template matTabContent>
                <div class="m-3 mr-5" *ngIf="true">
                  <div class="container-fluid mr-3">
                    <div class="mb-3 pt-3">
                      <mat-form-field
                        class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
                      >
                        <mat-label>First Name</mat-label>
                        <input
                          matInput
                          [(ngModel)]="
                            extractedResume && extractedResume.firstName
                          "
                        />
                      </mat-form-field>

                      <mat-form-field
                        class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
                      >
                        <mat-label>Middle Name</mat-label>
                        <input
                          matInput
                          [(ngModel)]="
                            extractedResume && extractedResume.middleName
                          "
                        />
                      </mat-form-field>

                      <mat-form-field
                        class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
                      >
                        <mat-label>Last Name</mat-label>
                        <input
                          matInput
                          [(ngModel)]="
                            extractedResume && extractedResume.lastName
                          "
                        />
                      </mat-form-field>

                      <div>
                        <mat-form-field
                          class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
                        >
                          <mat-label>Email</mat-label>
                          <input
                            matInput
                            [(ngModel)]="
                              extractedResume && extractedResume.email
                            "
                          />
                          <span
                            *ngIf="true"
                            class="pull-right w-20 notif-hover"
                            (click)="sendNotification()"
                            id="savebtn"
                          >
                          </span>
                        </mat-form-field>
                      </div>
                      <mat-form-field
                        class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
                      >
                        <mat-label>Phone</mat-label>
                        <input
                          matInput
                          [(ngModel)]="extractedResume && extractedResume.phone"
                        />
                      </mat-form-field>

                      <mat-form-field
                        class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
                      >
                        <mat-label>Skills</mat-label>
                        <textarea
                          matInput
                          placeholder="Enter a new skill"
                          (keydown.enter)="add($event)"
                          [(ngModel)]="newSkill"
                        ></textarea>
                        <div
                          style="
                            max-height: 150px;
                            overflow-y: scroll;
                            overflow-x: hidden;
                            height: fit-content;
                          "
                        >
                          <mat-chip-list class="chip-list">
                            <mat-chip
                              style="
                                font-size: 14px !important;
                                height: 32px !important;
                              "
                              *ngFor="let s of skillList"
                              class="pb-5 m-2 DSA_wb_chipStyle-Normal DSA_wb_chip-input"
                              disableRipple="true"
                            >
                              <a
                                class="icon x-16 icon-close-mini DSA_wb_chipIconRight"
                                (click)="removeChip(s)"
                                aria-label="remove chip"
                              ></a>
                              {{ s }}
                            </mat-chip>
                          </mat-chip-list>
                        </div>
                      </mat-form-field>
                    </div>
                  </div>
                  <button
                    class="DSA_wb_miniSecondary-btn marTB16"
                    (click)="updateExtractedResume()"
                    style="float: right"
                  >
                    Update
                  </button>
                </div>
              </ng-template>
            </mat-tab>
            <mat-tab label="Contact">
              <ng-template mat-tab-label> Contact </ng-template>
              <app-contact-details
                [contactDetails]="extractedResume?.contactDetails"
                (newItemEvent)="updatedContact($event)"
              ></app-contact-details>
            </mat-tab>
            <mat-tab label="Personal">
              <ng-template mat-tab-label> Personal </ng-template>
              <app-personal-details
                [personalDetails]="extractedResume?.personalDetails"
                (newItemEvent)="updatedPersonal($event)"
              ></app-personal-details>
            </mat-tab>

            <mat-tab label="Educational">
              <ng-template mat-tab-label> Educational </ng-template>
              <app-education-details
                [educationDetails]="extractedResume?.educationDetails"
                (newItemEvent)="updatedEducational($event)"
              >
              </app-education-details>
            </mat-tab>

            <mat-tab label="Career">
              <ng-template mat-tab-label> Career </ng-template>
              <app-career-details
                [careerDetails]="extractedResume?.careerDetails"
                (newItemEvent)="updatedCareer($event)"
              >
              </app-career-details>
            </mat-tab>

            <mat-tab label="Project">
              <ng-template mat-tab-label> Project </ng-template>
              <app-project-details
                [projectDetails]="extractedResume?.projectDetails"
                (newItemEvent)="updatedProject($event)"
              >
              </app-project-details>
            </mat-tab>

            <!--<mat-tab label="Contact">
              <ng-template mat-tab-label> Contact </ng-template>
              <app-official-details
                [candidateApplicationDetails]="
                  candidateInfo.candidateApplicationDetails
                "
                [applicationSource]="candidateInfo.applicationSource"
                [hiringCompany]="candidateInfo.hiringCompany"
                [requisition]="candidateInfo.requisition"
                [candidateApplicationDetails]="
                  candidateInfo.candidateApplicationDetails
                "
              >
              </app-official-details>
              
            </mat-tab> -->
          </mat-tab-group>
        </div>
        <div class="d-flex justify-content-end marT16">
          <button
            mat-flat-button
            class="DSA_wb_secondary-btn"
            (click)="validateProfile()"
          >
            Validate Profile Data
          </button>
        </div>
      </div>
    </div>

    <!-- <div class="row mt-5" *ngIf="!spinner">
      <div class="offset-md-6 col-md-6 text-center">
        <div *ngIf="spinner">
          <br />
          <img
            src="../../../assets/loader.gif"
            style="margin-left: 40%; width: 200px"
            alt=""
          />
        </div>
        <div class="offset-md-2">
          <button
            mat-raised-button
            class="DSA_wb_primary-btn col-md-5 mr-3"
            [disabled]="disableSave"
            (click)="save()"
            id="savebtn"
          >
            Update
          </button>
        </div>
      </div>
    </div> -->
    <!-- <div class="row" *ngIf="!spinner"></div> -->
  </ng-template>
</div>
