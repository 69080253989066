import { Component, OnInit } from '@angular/core';
import { ResumeUploadService } from '../services/resume-upload.service';
import { AccountService } from 'src/app/services/account.service';
import { KeycloakService } from 'keycloak-angular';
import { SharedService } from 'src/app/services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CandidateInfoService } from '../services/candidate-info.service';
import { ViewChild } from '@angular/core';
import { CandidateProfileComponent } from '../candidate-profile/candidate-profile.component';
import { MpJobService } from 'src/app/services/mp-job.service';
// import {MatIconModule} from '@angular/material/icon'

@Component({
  selector: 'app-application-stepper',
  templateUrl: './application-stepper.component.html',
  styleUrls: ['./application-stepper.component.scss'],
})
export class ApplicationStepperComponent implements OnInit  {
  @ViewChild(CandidateProfileComponent)
  candidateProfileComponent: CandidateProfileComponent;
  checkForLocalStepState: boolean = false;
  isProfileValidated: boolean = false;

  constructor(
    private accountService: AccountService,
    private sharedService: SharedService,
    private router: Router,
    private route: ActivatedRoute,
    private candidateInfoService: CandidateInfoService,
    private jobService: MpJobService
  ) {
    const state = this.router.getCurrentNavigation()?.extras?.state;
		this.checkForLocalStepState = state ? state['checkForLocalStepState'] : false;
  }
  stepper_page_names = ['Review Your Profile', 'Upload Documents'];
  activeIndex = 0;
  spinner = false;
  documentRequired = []; //default docs required
  documentRequiredUI = [];
  maxFamilyAnnualIncome = null;
  applicationId: any;
  spinnerChild = false;
  applicantId = null;
  documentListDataLoaded:boolean=false;
  jobId=null;
  ngOnInit(): void {
    this.jobId = this.route.snapshot.paramMap.get('jobid');
    this.candidateInfoService.getJobByID(this.jobId).subscribe((response:any) => {
      this.documentRequired = response.documentRequired;
      this.documentListDataLoaded = true;
      this.maxFamilyAnnualIncome = response.maxAnnualFamilyIncome;
      const obj: any = response;
      if (obj.documentRequired != null && obj.documentRequired.length > 0) {
        // this.documentRequired = obj.documentRequired;
        this.documentRequiredUI = obj.documentRequired.map((docType) => {
          return docType
            .toLowerCase()
            .split('_')
            .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
            .join(' ');
        });
        // console.log("Doc required",this.documentRequired, " ui", this.documentRequiredUI);
      }
    });
    let userId = this.accountService.getUserId();
    if (userId == null || userId.length == 0) {
      //redirect to login page
      // this.keycloakService.login();
      userId = this.accountService.getUserId();
    }

    // this.resumeUploadService.checkForexistingResumes(userId).subscribe({
    //   next: (res: any) => {
    //     this.spinner = false;
    //     if (res != null) {
    //       this.activeIndex = 1;
    //     } else {
    //       this.activeIndex = 0;
    //     }
    //   },
    //   error: (err: any) => {
    //     this.sharedService.openErrorSnackBar(
    //       'Error occured. Please try again',
    //       ''
    //     );
    //     this.spinner = false;
    //   },
    // });
  }

  // onResumeUploadComplete = () => {
  //   console.log('resume upload was complete');
  //   this.activeIndex = 1;
  // };

  appyToScholarshipAndGoToNextStep = () => {
    //let candidateInfoId = localStorage.getItem('candidate-id'); //this is candidate info id
    let jobId = this.route.snapshot.paramMap.get('jobid');
    this.candidateInfoService
      .postCandidateJobApplication(this.applicantId, jobId)
      .subscribe({
        next: (res: any) => {
          this.applicationId = res.id;

          this.sharedService.openSuccessSnackBar('Saved Successfully', '');
          //applied to the job, doc upload is optional
          // this._location.back();
          this.activeIndex = this.activeIndex + 1;
        },
        error: (err: any) => {
          this.sharedService.openErrorSnackBar(
            'Ensure your profile is validated before applying.',
            ''
          );
        },
      });
  };

  handleOnProfileValidate = () => {
    this.isProfileValidated = true;
  };

  handelApplicantId = (id) => {
    this.applicantId = id;
  }

  handleBackClick = () => {
    this.activeIndex = this.activeIndex - 1;
  };

  finalSubmit = () => {
    //TODO
    const formValue = {
      applicationStatus: "SUBMITTED",
    };
    this.spinnerChild = true
    this.candidateInfoService.patchCandidateJobApplication(this.applicationId, "SUBMITTED").subscribe({
      next: (res) => {
        this.sharedService.openSuccessSnackBar(
          'Applied successfully',
          ''
        );
        this.spinnerChild = false;
      },
      error: (err) => {
        this.sharedService.openErrorSnackBar(
          'Some error occurred, please try again later',
          ''
        );
        this.spinnerChild = false
      },
    });
  }
}
