import { Component, OnInit } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';
//import { IExtractedResume } from 'src/app/Shared/Models/talent-api/extracted-resume.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { extractedResumes } from './sample_resume';
import { ExtractedResumeService } from './extracted-resume.service';
import { ExtractedResume } from 'src/app/shared/model/extracted-resume.model';
import { AccountService } from 'src/app/services/account.service';
import { SharedService } from 'src/app/services/shared.service';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { CandidateInfoService } from '../../../candidate/services/candidate-info.service';

@Component({
  selector: 'app-extracted-resume-detail',
  templateUrl: './extracted-resume-detail.component.html',
  styleUrls: ['./extracted-resume-detail.component.scss'],
})
export class ExtractedResumeDetailComponent implements OnInit {
  spinner = true;
  spinner2 = true;
  disableSave = false;
  offlineToggle: boolean;
  interviewLocation: string;
  doc: any;
  allLocations = [];
  skillList: string[];
  extractedResume: ExtractedResume;
  candidateInfo;
  resumeurl: string;
  candidateInfoId: any;
  jobApplicationId: any = '';

  constructor(
    private http: HttpClient,
    //protected extractedResumeService: ExtractedResumeService,
    protected extractedResumeServices: CandidateInfoService,
    private accountService: AccountService,
    public sharedService: SharedService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.spinner = true;
    this.candidateInfoId = parseInt(this.activatedRoute.snapshot.params.id);
    let role = this.accountService.getAccountLocal()['authorities'];
    if (role.includes('ROLE_HIRING_MANAGER')) {
      this.jobApplicationId = parseInt(
        this.activatedRoute.snapshot.params.jobApplicationId
      );
    }
    this.getResume();
  }

  requisitionId = '452';
  fileId: any;
  getResume() {
    console.log('in resume funct');
    this.extractedResumeServices
      .getCandidateInfo(this.candidateInfoId)
      .subscribe({
        next: (response: any) => {
          this.extractedResume = response;
          this.spinner = false;
          this.fileId = response?.fileInfoDTOs[0]?.fileId;
          console.log(response?.fileInfoDTOs);
          console.log(this.fileId);
          this.loadSinglePdf();
          localStorage.setItem('candidate-id', String(this.extractedResume.id));
          console.log(this.extractedResume);
        },
        error: (err: any) => {
          this.sharedService.openErrorSnackBar(
            'Extraction of Resume is processing, Please wait',
            ''
          );
        },
      });
  }

  setvaluesOfEach(response) {}

  updatedPersonal(e: any) {
    this.extractedResume.personalDetails = e;
    console.log(e);
  }

  updatedEducational(e: any) {
    this.extractedResume.educationDetails = e;
    console.log(e);
  }
  updatedCareer(e: any) {
    this.extractedResume.careerDetails = e;
    console.log(e);
  }
  updatedProject(e: any) {
    this.extractedResume.projectDetails = e;
    console.log(e);
  }
  updatedContact(e: any) {
    this.extractedResume.contactDetails = e;
    console.log(e);
  }

  validateProfile = () => {
    // TODO: pass different source to validate for hiring manager in below api
    this.extractedResumeServices
      .validateResume(this.extractedResume.id, this.jobApplicationId)
      .subscribe({
        next: (response) => {
          this.sharedService.openSuccessSnackBar('Successfully Validated', '');
        },
        error: (error) => {
          console.log('Error', error);
          this.sharedService.openErrorSnackBar(
            'Something Went Wrong! Please try again!',
            ''
          );
        },
      });
  };
  updateExtractedResume() {
    this.save();
  }
  save() {
    this.extractedResumeServices
      .updateCandidateInfo(this.candidateInfoId, this.extractedResume)
      .subscribe({
        next: (res: any) => {
          this.sharedService.openSuccessSnackBar(
            ' data updated successfully',
            ''
          );

          // to do stepper.next();
        },
        error: (err: any) => {
          this.sharedService.openErrorSnackBar(
            ' data update failed. Please try again',
            ''
          );
        },
      });
  }
  getPaginationDetails() {}
  showNext() {}
  showPrevious() {}
  sendNotification() {}
  pdfLoaded(pdf) {
    let name, college, degree, employers, dob, email, experience, phone, skills;

    if (this.extractedResume.skills) {
      skills = this.extractedResume.skills.join(' ').toLowerCase();
    }
  }

  afterLoadComplete(pdfData: any) {
    this.spinner2 = false;
  }
  onPdfError(errorData: any) {}
  loadSinglePdf() {
    console.log('hello1 pdf');
    this.skillList = this.extractedResume?.skills;
    console.log('Testing Skills From Extracted resume');
    console.log(this.skillList);
    this.extractedResumeServices
      .getDocument(this.fileId)
      .subscribe((response) => {
        let blob: any = new Blob([response.body], {
          type: 'application/octet-stream; charset=utf-8',
        });
        this.doc = window.URL.createObjectURL(blob);
        this.spinner2 = false;;
        let temp = response.headers.get('content-disposition');
        if(temp)
        {temp = temp.split('filename=')[1];
        temp = temp.split(';')[0];
        let temp1 = temp.substring(1, temp.length - 1);}
      });
  }
  loadDetailInfo() {}
  removeChip(s: string) {
    this.skillList = this.skillList.filter((skill) => skill !== s);
    this.extractedResume.skills = this.skillList; //.join(",");
  }
  newSkill : string;
  add(e){
    console.log(this.newSkill);
    this.skillList.unshift(this.newSkill);
    this.newSkill="";
  }
}
