<div class="survey-detail-section">
  <div style="margin:3px;margin-left:10px">
    <jhi-survey-navigation [noMobile]="false" [isDesignValidationRequired]='false' (designValidate)="null">
    </jhi-survey-navigation>
  </div>
  <div>
    <div class="card-container" mat-elevation-z0>

      <img *ngIf="surveyDefinition.logo !== null"
        [src]="imageService.getUpscaleUrl(website_url+downloadImage(surveyDefinition.logo.path),'80x80')"
        style="min-height: 75px;min-width: 75px;" />
      <div class="card-container" style="margin-right: 30px;text-align: left;min-height: 100%;">
        <button *ngIf="surveyDefinition.logo == null" [matTooltip]="'Edit Logo'" mat-icon-button style="float: left;">
          <mat-icon style="color:forestgreen">eco</mat-icon>
        </button>

        <div class="card-container-column"
          style="flex-direction: column; min-height: 60px;min-width:240px;max-width:280px; overflow-x: hidden;">
          <span class="heading" [matTooltip]="surveyDefinition?.title">{{surveyDefinition.title}}</span>
          <span class="sub-heading"
            [matTooltip]="surveyDefinition?.description? surveyDefinition.description : 'No Description Available'">
            {{surveyDefinition.description? surveyDefinition.description : 'No Description Available'}}
          </span>
        </div>
      </div>


    </div>
  </div>
</div>