import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataTransferService {
  constructor() {}

  data: any;
  subStatusData: any[];

  tagsData: any[] = [];
  activeVerifierData: any;
}
