/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/tslint/config */
import { Component, OnInit, Input } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { Visibility } from 'src/app/shared/model/enumerations/visibility.model';
import { FileserverService } from 'src/app/survey-master/survey/edit-backgroud-color/fileserver.service';
import { Theme, QuestionView } from 'src/app/shared/model/theme.model';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return control && control.invalid;
  }
}

@Component({
  selector: 'jhi-html-block-preview',
  templateUrl: './html-block.component.html',
  styleUrls: ['./html-block.component.scss'],
})
export class HTMLBlockPreviewComponent implements OnInit {
  @Input()
  currPage: any;
  @Input()
  question: any;
  @Input()
  questionIndex: any;
  @Input()
  responsePage: any;
  @Input()
  section: any;
  @Input()
  preference: any;
  @Input()
  theme: Theme;

  view = 'NORMAL';
  @Input()
  disabled: Boolean = false;

  matcher = new MyErrorStateMatcher();

  control: FormControl;
  htmlValue: string;

  constructor(public fileserverService: FileserverService) {}

  ngOnInit() {
    //
    //
    this.control = new FormControl('');
    this.responsePage.sections[this.section].questions[
      this.questionIndex
    ].response = [];
    this.htmlValue = this.getValue(this.question, 'HTML_VALUE');
    // this.responsePage.sections[this.section].questions[this.questionIndex].response.push('');
  }

  downloadImage(path: string) {
    return this.fileserverService.getFileURLbyPath(path);
  }

  inputChanged() {}

  // logicPart() {
  //   if (this.question.logics !== null && this.question.logics.length > 0) {
  //     for (let i = 0; i < this.question.logics.length; i++) {
  //       let apply = false;
  //       let condition = 'END';
  //       for (let r = 0; r < this.question.logics[i].rules.length; r++) {
  //         let rapply = false;

  //         if (this.question.logics[i].rules[r].op === 'EQ') {
  //           if (
  //             this.responsePage.sections[this.section].questions[this.questionIndex].response[0] === this.question.logics[i].rules[r].value
  //           ) {
  //             rapply = true;
  //           }
  //         } else if (this.question.logics[i].rules[r].op === 'NEQ') {
  //           if (
  //             this.responsePage.sections[this.section].questions[this.questionIndex].response[0] !== this.question.logics[i].rules[r].value
  //           ) {
  //             rapply = true;
  //           }
  //         } else if (this.question.logics[i].rules[r].op === 'CONTAINS') {
  //           if (
  //             this.responsePage.sections[this.section].questions[this.questionIndex].response[0].includes(
  //               this.question.logics[i].rules[r].value
  //             )
  //           ) {
  //             rapply = true;
  //           }
  //         }

  //         if (r > 0) {
  //           if (condition === 'AND') {
  //             apply = apply && rapply;
  //           } else if (condition === 'OR') {
  //             apply = apply || rapply;
  //           }
  //         } else {
  //           apply = rapply;
  //         }
  //         condition = this.question.logics[i].rules[r].conjunction;
  //       }

  //       for (let a = 0; a < this.question.logics[i].actions.length; a++) {
  //         let foundIndex: number = null;
  //

  //         const actionquestion = this.currPage.sections[this.section].questions.find((ques, ind) => {
  //           if (ques.identifier === this.question.logics[i].actions[a].identifier) {
  //             foundIndex = ind;
  //             return true;
  //           } else {
  //             return false;
  //           }
  //         });
  //

  //         if (apply) {
  //           if (this.question.logics[i].actions[a].name === 'HIDE') {
  //             if (actionquestion === undefined || actionquestion === null) {
  //               //nothing to hide show
  //             } else {
  //               this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = true;
  //               const exists = this.responsePage.sections[this.section].questions[foundIndex].logicallyHiddenBy.find(
  //                 item => item === this.question.id
  //               );
  //               if (exists === undefined) {
  //                 this.responsePage.sections[this.section].questions[foundIndex].logicallyHiddenBy.push(this.question.id);
  //               }
  //             }
  //           } else if (this.question.logics[i].actions[a].name === 'SHOW') {
  //             if (actionquestion === undefined || actionquestion === null) {
  //               //nothing to hide show
  //             } else {
  //               const exists = this.responsePage.sections[this.section].questions[foundIndex].logicallyShownBy.find(
  //                 item => item === this.question.id
  //               );
  //               if (exists === undefined) {
  //                 this.responsePage.sections[this.section].questions[foundIndex].logicallyShownBy.push(this.question.id);
  //               }
  //               if (this.responsePage.sections[this.section].questions[foundIndex].logicallyHiddenBy.length === 0) {
  //                 this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = false;
  //               } else {
  //                 this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = true;
  //               }
  //             }
  //           }
  //         } else {
  //           if (this.question.logics[i].actions[a].name === 'HIDE') {
  //             if (actionquestion === undefined || actionquestion === null) {
  //               //nothing to hide show
  //             } else {
  //               const exists = this.responsePage.sections[this.section].questions[foundIndex].logicallyHiddenBy.find(
  //                 item => item === this.question.id
  //               );
  //               if (exists !== undefined) {
  //
  //                 this.responsePage.sections[this.section].questions[foundIndex].logicallyHiddenBy = this.responsePage.sections[
  //                   this.section
  //                 ].questions[foundIndex].logicallyHiddenBy.filter(item => item !== this.question.id);
  //               }
  //               if (this.responsePage.sections[this.section].questions[foundIndex].logicallyHiddenBy.length === 0) {
  //                 if (this.responsePage.sections[this.section].questions[foundIndex].logicallyShownBy.length === 0) {
  //
  //                   if (this.responsePage.sections[this.section].questions[foundIndex].defaultVisibile) {
  //
  //                     this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = false;
  //                   } else {
  //                     this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = true;
  //                   }
  //                 } else {
  //                   this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = false;
  //                 }
  //               }
  //             }
  //           } else if (this.question.logics[i].actions[a].name === 'SHOW') {
  //
  //             if (actionquestion === undefined || actionquestion === null) {
  //               //nothing to hide show
  //             } else {
  //               this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = true;
  //               const exists = this.responsePage.sections[this.section].questions[foundIndex].logicallyShownBy.find(
  //                 item => item === this.question.id
  //               );
  //               if (exists !== undefined) {
  //                 this.responsePage.sections[this.section].questions[foundIndex].logicallyShownBy = this.responsePage.sections[
  //                   this.section
  //                 ].questions[foundIndex].logicallyShownBy.filter(item => item !== this.question.id);
  //               }
  //               if (this.responsePage.sections[this.section].questions[foundIndex].logicallyShownBy.length === 0) {
  //                 if (this.responsePage.sections[this.section].questions[foundIndex].logicallyHiddenBy.length === 0) {
  //                   if (this.responsePage.sections[this.section].questions[foundIndex].defaultVisibile) {
  //                     this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = false;
  //                   } else {
  //                     this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = true;
  //                   }
  //
  //                 } else {
  //                   this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = true;
  //                 }
  //               } else {
  //                 if (this.responsePage.sections[this.section].questions[foundIndex].logicallyHiddenBy.length === 0) {
  //                   this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = false;
  //                 } else {
  //                   this.responsePage.sections[this.section].questions[foundIndex].logicallyHidden = true;
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  getValidationValue(question: any, param: string): string {
    const object = question.type.validationValues.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }

  getValue(question: any, param: string): string {
    const object = question.type.values.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }
}
