/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/tslint/config */
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { FileserverService } from 'src/app/survey-master/survey/edit-backgroud-color/fileserver.service';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';

import View from 'ol/View';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import { Feature } from 'ol';
import Style from 'ol/style/Style';
import Point from 'ol/geom/Point';
import { transformExtent } from 'ol/proj';
import Icon from 'ol/style/Icon';
import { fromLonLat } from 'ol/proj.js';
import { Theme, QuestionView } from 'src/app/shared/model/theme.model';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return control && control.invalid;
  }
}
// declare let ol: any;

@Component({
  selector: 'jhi-map-input-preview',
  templateUrl: './map-input.component.html',
  styleUrls: ['./map-input.component.scss'],
})
export class MapInputPreviewComponent implements OnInit {
  @Input()
  currPage: any;
  @Input()
  question: any;
  @Input()
  questionIndex: any;
  @Input()
  responsePage: any;
  @Input()
  section: any;
  @Input()
  preference: any;
  @Input()
  theme: Theme;
  @Input()
  disabled: Boolean = false;

  view = 'NORMAL';

  latitude = 18.5204;
  longitude = 73.8567;

  map: Map;
  source: VectorSource;
  // layer: VectorLayer;

  matcher = new MyErrorStateMatcher();

  control: FormControl;

  constructor(public fileserverService: FileserverService) {}

  ngOnInit() {
    this.control = new FormControl('');
    if (this.theme.questionView === QuestionView.TWO_COLUMNS) {
      this.view = 'TWO_COLUMNS';
    } else {
      this.view = 'NORMAL';
    }
    this.source = new VectorSource();
    const markerStyle = new Style({
      ///** @type {olx.style.IconOptions} */
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        src: 'assets/icons/blue_icons/icon_tree.png',
      }),
    });
    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response != null &&
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response.length > 0
    ) {
      // already some response present
      this.latitude = Number(
        this.responsePage.sections[this.section].questions[this.questionIndex]
          .response[1].answer
      );
      this.longitude = Number(
        this.responsePage.sections[this.section].questions[this.questionIndex]
          .response[0].answer
      );
      this.map = null;
      this.map = new Map({
        target: 'map',
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
          // new VectorLayer({
          //   source : this.source,
          //   style:markerStyle
          // })
        ],
        view: new View({
          projection: 'EPSG:4326',
          center: [this.longitude, this.latitude],
          zoom: 6,
        }),
      });
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response = [];
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const longitude = position.coords.longitude;
          const latitude = position.coords.latitude;

          this.latitude = latitude;
          this.longitude = longitude;
          const answerlat: any = {};
          answerlat.answer = this.latitude;
          answerlat.answerDetail = 'Latitude';
          const answerlong: any = {};
          answerlong.answer = this.longitude;
          answerlong.answerDetail = 'Longitude';
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].response.push(answerlong);
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].response.push(answerlat);
          this.map = null;
          this.map = new Map({
            target: 'map',
            layers: [
              new TileLayer({
                source: new OSM(),
              }),
              // new VectorLayer({
              //   source : this.source,
              //   style:markerStyle
              // })
            ],

            view: new View({
              projection: 'EPSG:4326',
              center: [this.longitude, this.latitude],
              zoom: 6,
            }),
          });
        },
        () => {
          const answerlat: any = {};
          answerlat.answer = this.latitude;
          answerlat.answerDetail = 'Latitude';
          const answerlong: any = {};
          answerlong.answer = this.longitude;
          answerlong.answerDetail = 'Longitude';
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].response.push(answerlong);
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].response.push(answerlat);
          this.map = null;
          this.map = new Map({
            target: 'map',
            layers: [
              new TileLayer({
                source: new OSM(),
              }),
              // new VectorLayer({
              //   source : this.source,
              //   style:markerStyle
              // })
            ],

            view: new View({
              projection: 'EPSG:4326',
              center: [this.longitude, this.latitude],
              zoom: 6,
            }),
          });
        }
      );
    }
  }

  getCoord(event: any) {
    const coordinate = this.map.getEventCoordinate(event);

    this.latitude = coordinate[1];
    this.longitude = coordinate[0];
    // geometry: new Point(transformExtent([this.longitude, this.latitude], 'EPSG:4326',
    //   'EPSG:3857')),
    // const feature = new Feature({
    //   geometry: new Point(fromLonLat([this.longitude, this.latitude]))
    // });

    // this.source = new VectorSource({
    //   features: [feature]
    // });

    // const markerStyle = new Style({
    //   ///** @type {olx.style.IconOptions} */
    //   image: new Icon({
    //     color: '#8959A8',
    //     crossOrigin: 'anonymous',
    //     imgSize: [20, 20],
    //     src: 'https://cdn2.iconfinder.com/data/icons/tsgk-cctld-territories-icons-flags-pack-v---borderless/16/uri.png'
    //   })
    // });
    // this.map.addLayer(
    //   new VectorLayer({
    //     source: this.source
    //     // style:markerStyle
    //   })
    // );
    //

    const mapLayers: any = this.map.getLayers().getArray();
    //
    if (mapLayers.length > 1) {
      for (let i = 0; i < mapLayers.length; i++) {
        if (mapLayers[i].ol_uid !== '4') {
          //
          this.map.removeLayer(mapLayers[i]);
          //
          break;
        }
      }
    }

    const markerVectorSource = new VectorSource({});
    const markerVectorlayer = new VectorLayer({ source: markerVectorSource });
    //
    this.map.addLayer(markerVectorlayer);
    const marker = new Feature({
      geometry: new Point([this.longitude, this.latitude]),
    });

    markerVectorSource.addFeature(marker);
    this.responsePage.sections[this.section].questions[
      this.questionIndex
    ].response[0].answer = String(coordinate[0]);
    this.responsePage.sections[this.section].questions[
      this.questionIndex
    ].response[1].answer = String(coordinate[1]);
    //
  }
  // not used
  logicPart() {
    if (this.question.logics !== null && this.question.logics.length > 0) {
      for (let i = 0; i < this.question.logics.length; i++) {
        let apply = false;
        let condition = 'END';
        for (let r = 0; r < this.question.logics[i].rules.length; r++) {
          let rapply = false;

          if (this.question.logics[i].rules[r].op === 'EQ') {
            if (
              this.responsePage.sections[this.section].questions[
                this.questionIndex
              ].response[0].toLowerCase() ===
              this.question.logics[i].rules[r].value.toLowerCase()
            ) {
              rapply = true;
            }
          } else if (this.question.logics[i].rules[r].op === 'NEQ') {
            if (
              this.responsePage.sections[this.section].questions[
                this.questionIndex
              ].response[0].toLowerCase() !==
              this.question.logics[i].rules[r].value.toLowerCase()
            ) {
              rapply = true;
            }
          } else if (this.question.logics[i].rules[r].op === 'CONTAINS') {
            if (
              this.responsePage.sections[this.section].questions[
                this.questionIndex
              ].response[0]
                .toLowerCase()
                .includes(this.question.logics[i].rules[r].value.toLowerCase())
            ) {
              rapply = true;
            }
          }

          if (r > 0) {
            if (condition === 'AND') {
              apply = apply && rapply;
            } else if (condition === 'OR') {
              apply = apply || rapply;
            }
          } else {
            apply = rapply;
          }
          condition = this.question.logics[i].rules[r].conjunction;
        }

        for (let a = 0; a < this.question.logics[i].actions.length; a++) {
          let foundIndex: number = null;

          const actionquestion = this.currPage.sections[
            this.section
          ].questions.find((ques, ind) => {
            if (
              ques.identifier === this.question.logics[i].actions[a].identifier
            ) {
              foundIndex = ind;
              return true;
            } else {
              return false;
            }
          });

          if (apply) {
            if (this.question.logics[i].actions[a].name === 'HIDE') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                this.responsePage.sections[this.section].questions[
                  foundIndex
                ].logicallyHidden = true;
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyHiddenBy.find(
                  (item) => item === this.question.id
                );
                if (exists === undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHiddenBy.push(this.question.id);
                }
              }
            } else if (this.question.logics[i].actions[a].name === 'SHOW') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyShownBy.find(
                  (item) => item === this.question.id
                );
                if (exists === undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyShownBy.push(this.question.id);
                }
                if (
                  this.responsePage.sections[this.section].questions[foundIndex]
                    .logicallyHiddenBy.length === 0
                ) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHidden = false;
                } else {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHidden = true;
                }
              }
            }
          } else {
            if (this.question.logics[i].actions[a].name === 'HIDE') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyHiddenBy.find(
                  (item) => item === this.question.id
                );
                if (exists !== undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHiddenBy = this.responsePage.sections[
                    this.section
                  ].questions[foundIndex].logicallyHiddenBy.filter(
                    (item) => item !== this.question.id
                  );
                }
                if (
                  this.responsePage.sections[this.section].questions[foundIndex]
                    .logicallyHiddenBy.length === 0
                ) {
                  if (
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyShownBy.length === 0
                  ) {
                    if (
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].defaultVisibile
                    ) {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = false;
                    } else {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = true;
                    }
                  } else {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = false;
                  }
                }
              }
            } else if (this.question.logics[i].actions[a].name === 'SHOW') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                this.responsePage.sections[this.section].questions[
                  foundIndex
                ].logicallyHidden = true;
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyShownBy.find(
                  (item) => item === this.question.id
                );
                if (exists !== undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyShownBy = this.responsePage.sections[
                    this.section
                  ].questions[foundIndex].logicallyShownBy.filter(
                    (item) => item !== this.question.id
                  );
                }
                if (
                  this.responsePage.sections[this.section].questions[foundIndex]
                    .logicallyShownBy.length === 0
                ) {
                  if (
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHiddenBy.length === 0
                  ) {
                    if (
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].defaultVisibile
                    ) {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = false;
                    } else {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = true;
                    }
                  } else {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = true;
                  }
                } else {
                  if (
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHiddenBy.length === 0
                  ) {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = false;
                  } else {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  downloadImage(path: string) {
    return this.fileserverService.getFileURLbyPath(path);
  }

  // not needed
  inputChanged() {}

  getValidationValue(question: any, param: string): string {
    const object = question.type.validationValues.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }

  getValue(question: any, param: string): string {
    const object = question.type.values.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }
}
