import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateEventComponent } from './create-event/create-event.component';
import { CreateCriteriaDialogComponent } from './create-criteria-dialog/create-criteria-dialog.component';
import { DialogBoxComponent } from './dialog-box/dialog-box.component';
import { InfyRecruiterCandidateListComponent } from './infy-recruiter-candidate-list/infy-recruiter-candidate-list.component';
import { JobCandidatesComponent } from './job-candidates/job-candidates.component';
import { MailSettingsComponent } from './mail-settings/mail-settings.component';
import { SettingsInputComponent } from './settings-input/settings-input.component';
import { RecruiterCandidateListStateStorageService } from './infy-recruiter-candidate-list/recruiter-candidate-list-state-storage.service';
import { GatewaySharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/angular-material.module';
import { CandidateResumeComponent } from './candidate/candidate-resume/candidate-resume.component';
import { CandidateHomeComponent } from './candidate/candidate-home/candidate-home.component';
import { ExtractedResumeModule } from '../extracted-resume/extracted-resume.module';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    CreateEventComponent,
    CreateCriteriaDialogComponent,
    DialogBoxComponent,
    InfyRecruiterCandidateListComponent,
    JobCandidatesComponent,
    MailSettingsComponent,
    SettingsInputComponent,
    CandidateResumeComponent,
    CandidateHomeComponent,
  ],
  imports: [
    CommonModule,
    GatewaySharedModule,
    MaterialModule,
    ExtractedResumeModule,
    NgxSpinnerModule,
  ],
  providers: [RecruiterCandidateListStateStorageService],
})
export class ArchiveModule {}
