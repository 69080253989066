import { Injectable } from '@angular/core';
import { collegeCandidatesData } from './college-candidates-dummy-data';

@Injectable({
  providedIn: 'root',
})
export class CollegeCandidatesStateStorageService {
  checkedCandidatesSet = new Set();

  private collegeCandidatesData = collegeCandidatesData;

  constructor() {}

  addCheckedCandidate = (id) => {
    this.checkedCandidatesSet.add(id);
  };

  removeCheckedCandidate = (id) => {
    this.checkedCandidatesSet.delete(id);
  };

  getCheckedStatus = (id) => {
    return this.checkedCandidatesSet.has(id);
  };

  getAllCheckedCandidates = () => {
    return Array.from(this.checkedCandidatesSet);
  };

  clearAllCheckedEvents = () => {
    this.checkedCandidatesSet.clear();
  };

  getCheckedCandidatesSize = () => {
    return this.checkedCandidatesSet.size;
  };

  // TODO: to be removed later after api integration
  getCollegeCandidatesData = () => {
    return this.collegeCandidatesData;
  };

  changeApplicationStatusOfSelectedCandidates = (status) => {
    const newCandidates = [];
    this.collegeCandidatesData.content.forEach((candidate) => {
      if (this.checkedCandidatesSet.has(candidate.id)) {
        candidate.applicationStatus = status;
      }
      newCandidates.push(candidate);
    });
    return newCandidates;
  };
}
