import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EventNotificationsService } from 'src/app/services/event-notifications.service';

@Component({
  selector: 'app-infy-event-notifications-interviews-grid',
  templateUrl: './infy-event-notifications-interviews-grid.component.html',
  styleUrls: ['./infy-event-notifications-interviews-grid.component.scss'],
})
export class InfyEventNotificationsInterviewsGridComponent
  implements OnInit, OnChanges
{
  @Input() selectedFile;
  @Input() statusSelected;
  constructor(private eventNotificationsService: EventNotificationsService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.applyFiltersToTable();
  }

  refreshAgGrid: Subject<void> = new Subject<void>();

  tableName: string = 'Interviews Status';
  buttonText: string = '';
  buttonCondition: boolean = false;
  levels: number = 1;
  searchCondition: boolean = true;
  // customRowHeight = 100;

  page: number = 0;
  size: number = 10;

  columnDefs: any = [
    // {
    //   headerName: 'Category',
    //   field: 'subCategory',
    //   hide: false,
    //   maxWidth: 150,
    // },
    {
      headerName: 'Candidate Email',
      field: 'userEmail',
      hide: false,
    },
    {
      headerName: 'Status',
      field: 'status',
      hide: false,
      maxWidth: 150,
    },
    {
      headerName: 'Message',
      field: 'message',
      hide: false,
      minWidth: 400,
    },
  ];

  interface = (
    size: number,
    page: number,
    query?: string
  ): Observable<any[]> => {
    return this.eventNotificationsService.getBulkUploadFileDetails(
      this.selectedFile.id,
      page,
      size,
      this.statusSelected,
      query
    );
  };

  applyFiltersToTable = () => {
    this.refreshAgGrid.next();
  };
}
