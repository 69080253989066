<div class="row">
  <div class="col-md-6 col-12">
    <mat-form-field class="DSA_Wb_custom-form-field">
      <input
        matInput
        placeholder="Reason to send back to panelist"
        [ngModel]="remarks"
        (ngModelChange)="handleRemarksChange($event)"
        [disabled]="disabled || eventStatus === 'CLOSED' || !accountService.hasFeatureAccess(FeatureName.SEND_BACK_TO_PANELIST, PermissionType.CREATE)"
        required
      />
    </mat-form-field>
  </div>
  <div class="col-md-6 d-flex align-items-end buttonWrap">
    <button
      mat-flat-button
      (click)="handleSubmit($event)"
      class="DSA_wb_primary-btn"
      [disabled]="disabled || eventStatus === 'CLOSED' || !accountService.hasFeatureAccess(FeatureName.SEND_BACK_TO_PANELIST, PermissionType.CREATE)"
    >
      {{
        disabled
          ? "Already sent back to panelist for Review"
          : "Send Back To Panelist For Review"
      }}
    </button>
  </div>
</div>
