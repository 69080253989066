<div class="marT32 DSA_wb_customTab DSA_navTabWhite DSA_wb_Secondary">
  <mat-tab-group
    (selectedTabChange)="changeDashboard($event)"
    [selectedIndex]="selectedIndex"
    disableRipple
    animationDuration="0s"
  >
    <div *ngFor="let dashboard of dashboards">
      <mat-tab>
        <ng-template mat-tab-label>
          {{ dashboard.name | titlecase }}
        </ng-template>
        <div class="padTB2">
          <iframe
            class="frame"
            seamless
            frameBorder="0"
            *ngIf="url"
            [src]="url"
          >
          </iframe>
        </div>
      </mat-tab>
    </div>
  </mat-tab-group>
</div>
