import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { EventService } from 'src/app/services/event.service';
import { SharedService } from 'src/app/services/shared.service';
import { JobService } from 'src/app/services/job.service';
import { InfyAssociateJobsComponent } from 'src/app/shared/components/infy-associate-jobs/infy-associate-jobs.component';

@Component({
  template: `<span
    ><i
      *ngIf="params.condition && showAssociation"
      class="icon x-24 icon-add"
      role="button"
      (click)="associateJobs()"
      matTooltip="Associate event level scholarships"
      matTooltipClass="DSA_tooltip"
    ></i>
    <i
      *ngIf="params.condition && showDelete"
      class="icon x-24 delete-sm-icon"
      role="button"
      (click)="deleteJobFromEvent()"
    ></i>
  </span>`,
})
export class JobActionRendererComponent implements AgRendererComponent {
  params: any;
  icon: any;
  eventId: any;
  showAssociation: boolean = false;
  showDelete: boolean = false;
  constructor(
    private dialog: MatDialog,
    private eventService: EventService,
    private sharedService: SharedService,
    private jobService: JobService
  ) {}

  agInit(params: any): void {
    this.params = params;
    this.eventId = this.eventService.selectedEventId;

    if (this.params.data?.jobType === 'OTHERS') {
      this.showAssociation = true;
    }

    if (
      params.icon === 'delete' &&
      params.data?.isDeletable &&
      params.condition
    ) {
      this.showDelete = true;
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  associateJobs() {
    let job = this.params.data;
    const dialogRef = this.dialog.open(InfyAssociateJobsComponent, {
      disableClose: false,
      data: { jobs: job, eventId: this.eventId, associationType: 'EVENT' },
    });
  }

  deleteJobFromEvent = () => {
    let job = this.params.data;
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        text: `Are you sure you want to delete <b>${job.title}</b> job from this event?`,
      },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Yes') {
        this.params.context.componentParent.showLoadingOverlay();
        this.jobService.deleteJobInEvent(job.id, this.eventId).subscribe(
          (res) => {
            this.params.context.componentParent.refreshTable();
            this.sharedService.openSnackBar(
              'Job Deleted Successfully',
              '',
              3000
            );
          },
          (err) => {
            this.params.context.componentParent.setGridUI();
            this.sharedService.openSnackBar('Job Deletion Failed', '', 3000);
          }
        );
      }
    });
  };
}
