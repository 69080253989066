<app-banner title="My Applications" [displaySubHeader]="false"> </app-banner>

<div class="container-fluid marT32">
  <div class="col-12">
    <div class="row mt-2 heading-section">
      <div class="col-md-11 mx-auto">
        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <!-- <h1 class="DSA_wb_h1Header web-GreyDarkest">Applications</h1> -->
          </div>
          <div class="col-4"></div>
          <div class="col-md-4 col-sm-8 align-self-end">
            <mat-form-field class="DSA_Wb_custom-form-field">
              <input
                matInput
                placeholder="Search Problem Statements"
                [(ngModel)]="searchedText"
                (keydown.enter)="searchJob(searchedText)"
              />
              <div matSuffix class="d-flex">
                <span *ngIf="searchedText" class="clear-icon">
                  <i
                    class="icon x-24 icon-rejected"
                    (click)="clearSearch()"
                    matTooltip="Clear Search"
                    matTooltipClass="DSA_tooltip"
                  >
                  </i>
                </span>
                <span>
                  <i
                    class="icon x-24 search-icon"
                    role="button"
                    (click)="searchJob($event)"
                    matTooltip="Search"
                    matTooltipClass="DSA_tooltip"
                  >
                  </i>
                </span>
              </div>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row marT8">
    <div class="col-12 small-screen-nomar padT8">
      <app-loader [display]="isLoading"></app-loader>
      <ng-container *ngIf="!isLoading">
        <ng-container *ngIf="jobs?.length > 0; else showError">
          <div class="row justify-content-center">
            <div *ngFor="let job of jobs; let i = index" class="col-md-11">
              <app-application-card
                [job]="job"
                (cancelJob)="cancelJob($event)"
              ></app-application-card>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-11">
              <app-pagination
                [totalElements]="totalElements"
                [page]="page"
                [pageSize]="pageSize"
                (pageChange)="handlePageChange($event)"
              ></app-pagination>
            </div>
          </div>
        </ng-container>

        <ng-template #showError>
          <app-error-message
            [errorText]="
              isError ? 'Error occurred. Please try again.' : 'No records found'
            "
          ></app-error-message>
        </ng-template>
      </ng-container>
    </div>

    <ng-template #secondDialog>
      <h2 matDialogTitle>Cancel Application</h2>
      <mat-dialog-content>
        <p>Do you want to withdraw the application?</p>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button
          class="DSA_wb_miniSecondary-btn marR8"
          mat-flat-button
          matDialogClose
        >
          Cancel
        </button>
        <button
          class="DSA_wb_miniPrimary-btn"
          mat-flat-button
          (click)="cancelApplication()"
          mat-dialog-close
        >
          Confirm
        </button>
      </mat-dialog-actions>
    </ng-template>
  </div>
</div>
