export enum FinalResultEnum {
  PENDING = 'PENDING',
  SELECTED = 'SELECTED',
  REJECTED = 'REJECTED',
  REFER_FOR_POST = 'REFER_FOR_POST',
  ON_HOLD = 'ON_HOLD',
  NO_SHOW = 'NO_SHOW',
  SELECTED_FOR_ANOTHER_JOB = 'SELECTED_FOR_ANOTHER_JOB',
  TO_BE_REINTERVIEWED = 'TO_BE_REINTERVIEWED',
  REVIEWED = 'REVIEWED',
  REJECTION_UNIT_LEVEL = 'REJECTION_UNIT_LEVEL',
  REJECTION_INFY_LEVEL = 'REJECTION_INFY_LEVEL',
}

export enum FinalResultUI {
  PENDING = 'Pending',
  SELECTED = 'Selected',
  REJECTED = 'Rejected',
  REFER_FOR_POST = 'Referred for another post',
  ON_HOLD = 'On Hold',
  NO_SHOW = 'No Show',
  SELECTED_FOR_ANOTHER_JOB = 'Selected for another job',
  TO_BE_REINTERVIEWED = 'To be re-interviewed',
  REVIEWED = 'Reviewed',
  REJECTION_UNIT_LEVEL = 'Rejected at Unit level',
  REJECTION_INFY_LEVEL = 'Rejected at Infosys Level',
}