<div *ngIf="job" class="d-flex">
  <span *ngIf = "accountService.hasFeatureAccess(FeatureName.JOBS, PermissionType.UPDATE)">
    <i
      class="icon x-24 edit-icon"
      (click)="editJob()"
      role="button"
      matTooltip="Edit"
      matTooltipClass="DSA_tooltip"
      aria-label="Button that displays a tooltip when focused or hovered over"
    >
    </i>
  </span>
  <span class="marL16">
    <i
      *ngIf="(job.type === null || job.type === 'OTHERS') && accountService.hasFeatureAccess(FeatureName.ASSOCIATE_JOBS, PermissionType.READ)"
      class="icon x-24 icon-add"
      role="button"
      (click)="associateJobs()"
      matTooltip="Associate Jobs"
      matTooltipClass="DSA_tooltip"
      aria-label="Button that displays a tooltip when focused or hovered over"
    >
    </i>
  </span>
  <span class="marL16">
    <a
      *ngIf="(job.type === null || job.type === 'OTHERS') && accountService.hasFeatureAccess(FeatureName.EVALUATION_TEMPLATES, PermissionType.CREATE)"
      role="button"
      href="javascript:void(0);"
      (click)="handleDesignEvalClick()"
      class="DSA_wb_hyperLink-btn"
    >
      Design Evaluation Sheet
    </a>
  </span>
</div>
