import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  jobApplicantsDummy,
  jobApplicantsFilterDummy,
} from '../modules/hiring-manager/requisition-details/job-applicants/applicants';
import { MpJobRoleDTO } from '../shared/model/mp-job-role.model';
import { SharedService } from './shared.service';
import { AuthorityName } from '../shared/model/enumerations/authority-name.model';
interface ApplicationInfoResponse {
  applicationFiles?: any[];
}
@Injectable({
  providedIn: 'root',
})
export class MpJobService {
  headers = new HttpHeaders().set('content-type', 'application/json');
  // .set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));

  constructor(private http: HttpClient, private sharedService: SharedService) {}

  getJobRoles(page: number, size: number, query: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);

    if (query) params = params.append('query', `${query}`);

    return this.http.get<MpJobRoleDTO>(
      environment.JOBMS_API + '/job-roles/creator',
      {
        headers: this.headers,
        params,
      }
    );
  }

  getApplicationHistory(applicationId): Observable<any> {
    return this.http.get<any>(
      environment.POSTMS_API + '/action-logs/application/' + applicationId,
      {
        headers: this.headers,
      }
    );
  }
  getTeamDetails(applicationId): Observable<any> {
    return this.http.get<any>(
      environment.POSTMS_API+'/applications/'+applicationId+'/team',
      {
        headers: this.headers,
      }
    );
  }

  callLogHistory(assignmentId: string | number): Observable<any> {
    return this.http.get<any>(
      environment.CALLMS_API + '/public/call-records/assignment/' + assignmentId
    );
  }

  supportExecutiveCallLogHistory(
    applicationId: string | number
  ): Observable<any> {
    return this.http.get<any>(
      environment.CALLMS_API +
        `/public/call-records/application/${applicationId}`
    );
  }

  getCallStatus(callUri: string | number): Observable<any> {
    return this.http.get<any>(
      environment.CALLMS_API + '/call-status?callUri=' + callUri
    );
  }
  connectCall(
    applicationId: number,
    assignmentId: number | string,
    email: string,
    _SpecType = AuthorityName.ROLE_VERIFIER,
    phoneOption?: boolean
  ): Observable<any> {
    // check phoneOption is true then call with alternativeContact: true
    // else call with alternativeContact: false
    return this.http.post<any>(
      environment.CALLMS_API + '/call-request',
      {
        To: applicationId,
        From: email,
        CustomField: assignmentId,
        SpecType: _SpecType,
        alternativePhone: phoneOption ? true : false,
      },
      {
        headers: this.headers,
      }
    );
  }
  connectCallWithoutAssignmentId(
    applicationId: number,
    applicantId: number | string,
    email: string,
    _SpecType: string,
    alternativePhone_?: boolean
  ): Observable<any> {
    return this.http.post<any>(
      environment.CALLMS_API + '/call-request',
      {
        To: applicantId,
        From: email,
        CustomField: applicationId,
        CallRecordGroupingType: 'APPLICATION',
        SpecType: _SpecType,
        alternativePhone: alternativePhone_ ? true : false,
      },
      {
        headers: this.headers,
      }
    );
  }

  getAsssignmentID(email: string) {
    let params = new HttpParams();
    params = params.append('email', `${email}`);

    const urlEncodedHeader = new HttpHeaders().set(
      'content-type',
      'application/x-www-form-urlencoded'
    );
    // https://apply.infosys.org/services/assignmentms/api/assignments/person?emailId=verifier@scp.infosys.com
    return this.http.post<any>(
      environment.ASSIGNMENTMS_API + '/assignments/person',
      {
        headers: urlEncodedHeader,
        params,
      }
    );
  }

  getApplicationInfo(id): Observable<any> {
    return this.http.get<any>(environment.POSTMS_API + '/applications/' + id, {
      headers: this.headers,
    });
  }
  createJobRole = (jobRole: MpJobRoleDTO): Observable<MpJobRoleDTO> => {
    return this.http.post<MpJobRoleDTO>(
      environment.JOBMS_API + '/job-roles',
      jobRole,
      {
        headers: this.headers,
      }
    );
  };

  updateJobRole = (jobRole: MpJobRoleDTO): Observable<MpJobRoleDTO> => {
    return this.http.put<MpJobRoleDTO>(
      environment.JOBMS_API + `/job-roles/${jobRole.id}`,
      jobRole,
      {
        headers: this.headers,
      }
    );
  };

  getJobApplicantsFilters = (opportunityId?: number, jobOpeningId?: number) => {
    // return of(jobApplicantsFilterDummy);
    let params = new HttpParams();

    if (opportunityId) params = params.append('opportunityId', opportunityId);
    if (jobOpeningId) params = params.append('jobOpeningId', jobOpeningId);
    return this.http.get(
      environment.SEARCHMS_API + '/applications/search-aggregations',
      { headers: this.headers, params }
    );
  };
  getCollegeApplicantsFilters = (
    opportunityId?: number,
    jobOpeningId?: number
  ) => {
    // return of(jobApplicantsFilterDummy);
    let params = new HttpParams();

    if (opportunityId) params = params.append('opportunityId', opportunityId);
    if (jobOpeningId) params = params.append('jobOpeningId', jobOpeningId);
    return this.http.get(
      environment.SEARCHMS_API + '/applications/search-aggregations',
      { headers: this.headers, params }
    );
  };

  updateJobApplication = (updateForm): Observable<any> => {
    // /api/job-applications/creator/action/{applicationId}
    console.log("updating status")
    return this.http.put<any>(
      environment.JOBMS_API + `/applications/action`,
      updateForm,
      {
        headers: this.headers,
      }
    );
  };

  getDisbursedAmount = (applicationID): Observable<any[]>=>{
          return this.http.get<any[]>(
            environment.JOBMS_API + `/scholarship-disbursements/application/${applicationID}`,{
              headers: this.headers,
            }
          )
  }

  updateDisbursedAmount = (disbursementId,updatedDisbursedAmount): Observable<any> => {

    const message = `The amount has been updated to ${updatedDisbursedAmount}`;
    
    const JSONdata = {
      id:disbursementId,
      transactionAmount:updatedDisbursedAmount,
      comments:message,
      transactionStatus:'AUTHORIZED'
    }
        return this.http.patch<any>(
          environment.JOBMS_API + `/scholarship-disbursements/${disbursementId}`,
          JSONdata,
          {
            headers: this.headers,
          }
        )
  }

  updateJobApplicationStatus = (applicantId, updateForm): Observable<any> => {
    // /api/job-applications/creator/action/{applicationId}
    return this.http.put<any>(
      environment.JOBMS_API + `/applications/action/${applicantId}`,
      updateForm,
      {
        headers: this.headers,
      }
    );
  };
  getJobApplicants = (
    page: number,
    size: number,
    query: string,
    filters?
  ): Observable<any> => {
    // return of(jobApplicantsDummy);

    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);
    if (query) {
      console.log('query');
      params = params.append('s', `${query}`);
    }
    const urlEncodedHeader = new HttpHeaders().set(
      'content-type',
      'application/x-www-form-urlencoded'
    );
    // .set('Authorization', 'Bearer ' + localStorage.getItem('access_token'));

    const body = this.sharedService.getUrlSearchParamsFromObject(filters);

    return this.http.post<any[]>(
      `${environment.SEARCHMS_API}/applications/search`,
      body,
      {
        headers: urlEncodedHeader,
        params,
      }
    );
  };

  getJobApplicantsScreen = (
    opportunityId,
    page: number,
    size: number,
    query: string,
    filters?
  ): Observable<any> => {
    // return of(jobApplicantsDummy);

    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);
    if (query) {
      params = params.append('s', `${query}`);
    }
    delete filters['opportunityCategory']
    const body = this.sharedService.getUrlSearchParamsFromObject(filters);
    const urlEncodedHeader = new HttpHeaders()
      .set('content-type', 'application/x-www-form-urlencoded')
      // .set('tenantId', '5');
    return this.http.post<any[]>(
      `${environment.SEARCHMS_API}/applications/search`,
      body,
      {
        headers: urlEncodedHeader,
        params,
      }
    );
    // return this.http.get<any>(environment.POSTMS_API+'/applications/opportunity/'+opportunityId,
    //       {
    //     headers: urlEncodedHeader,
    //     params,
    //   }
    // );
  };

  getScreenedApplications = (formData) => {
    let params = new HttpParams();
    // params = params.append("requisitionId", requisitionId);
    return this.http.post<any[]>(
      `${environment.SEARCHMS_API}/applications/search`,
      formData,
      {
        headers: this.headers,
        params,
      }
    );
  };

  getAssociatedJobs = (jobRoleId) => {
    return this.http.get<any[]>(
      environment.JOBMS_API + 'api/associated-jobs/job-role/' + jobRoleId,
      { headers: this.headers }
    );
  };

  getAssociatedJobsForEvent = (eventId, jobRoleId) => {
    return this.http.get<any>(
      `${environment.JOBMS_API}api/events/${eventId}/jobroles/${jobRoleId}/associatedjobroles`,
      { headers: this.headers }
    );
  };
  deleteAssociatedJobs = (jobs) => {
    return this.http.put<any>(
      environment.JOBMS_API + 'api/associated-jobs/remove-jobs',
      jobs,
      { headers: this.headers }
    );
  };

  deleteAssociatedJobsForEvent = (eventId, jobRoleId, jobs) => {
    return this.http.post<any>(
      `${environment.JOBMS_API}api/events/${eventId}/jobroles/${jobRoleId}/associatedjobroles/removeassociation`,
      jobs,
      { headers: this.headers }
    );
  };

  addAssociatedJobsForEvent = (eventId, jobRoleId, jobs) => {
    return this.http.post<any>(
      `${environment.JOBMS_API}api/events/${eventId}/jobroles/${jobRoleId}/associatedjobroles`,
      jobs,
      { headers: this.headers }
    );
  };

  addAssociatedJobs = (jobs) => {
    return this.http.post<any>(
      environment.JOBMS_API + 'api/associated-jobs/add-jobs',
      jobs,
      { headers: this.headers }
    );
  };

  getJobRolesFromInterviewMS = (page, size, jobTitle?) => {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);
    params = params.append('sort', `title,asc`);

    if (jobTitle) {
      params = params.append('jobTitle', `${jobTitle}`);
    }

    const opts = {
      headers: this.headers,
      params,
    };
    return this.http.get<any[]>(
      'https://interviews-alpha.infosysapps.com/services/interviewms/api/job-roles',
      opts
    );
  };

  getDocumentsForReview = (id?: number) => {
    // return of(jobApplicantsFilterDummy);
    const urlEncodedHeader = new HttpHeaders().set(
      'content-type',
      'application/json'
    );
    // .set('X-TENANT-ID', '3')
    // .set('skip', 'true');

    return this.http.get(
      environment.POSTMS_API + '/applications/docs-review/' + id,
      { headers: urlEncodedHeader }
    );
  };

  updateReviewStatus = (id: number, applicationFileStatus, actionComments) => {
    // return of(jobApplicantsFilterDummy);
    const urlEncodedHeader = new HttpHeaders().set(
      'content-type',
      'application/json'
    );
    // .set('X-TENANT-ID', '3')
    // .set('skip', 'true');

    var obj = {
      applicationFileStatus: applicationFileStatus,
      actionComments: actionComments,
    };

    return this.http.put<any>(
      environment.JOBMS_API + `/application-files/action/${id}`,
      obj,
      {
        headers: this.headers,
      }
    );
  };
}
