<div class="container-fluid layout">
  <div class="clearfix DSA_mainCtr ctr_main" id="mainCtr">
    <div class="container-fluid">
      <div class="DSA_rightMainContent nomarL_mb" id="mainrightCnt">
        <div class="DSA_innerContentWrapper">
          <div class="row">
            <div class="DSA_panel w-100 h-100 text-center pad32">
              <div
                class="
                  DSA_panelBody
                  padTB16
                  padLR24
                  d-flex
                  flex-column
                  justify-content-center
                "
              >
                <div *ngIf="error">
                  <h4 class="my-4">
                    There was some error while authentication, Please
                    authenticate before scheduling interview on MS Teams
                  </h4>
                  <div class="web-SecondaryRed text-center">
                    <p>Error: {{ error }}</p>
                    <p>Error description: {{ errorDescription }}</p>
                  </div>
                  <button
                    mat-flat-button
                    class="DSA_wb_primary-btn my-4"
                    routerLink="/dashboard"
                  >
                    Go to Dashboard
                  </button>
                </div>
                <div *ngIf="code && !error">
                  <h4>
                    Please wait ... Do not close the tab or press back button
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
