<div class="d-flex">
    <span >
      <i
        class="icon x-24 edit-icon"
        role="button"
        matTooltip="Edit"
        matTooltipClass="DSA_tooltip"
        aria-label="Button that displays a tooltip when focused or hovered over"
      >
      </i>
    </span>
    
  </div>
  