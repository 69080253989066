import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileService } from 'src/app/services/file.service';
import { MailTemplateService } from 'src/app/services/mail-template.service';
import * as fileSaver from 'file-saver';
import { SharedService } from 'src/app/services/shared.service';
import { AccountService } from 'src/app/services/account.service';
import { AuthorityName } from 'src/app/shared/model/enumerations/authority-name.model';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PrivacyPolicyComponent implements OnInit {
  isPolicyAccepted: boolean = false;
  isSubscribed = false;
  content;
  isLoading = false;
  isDownloading = false;
  isDownloaded = false;
  privacyCategory = '';
  currentRole = '';
  authorityName = AuthorityName;
  constructor(
    private _accountService: AccountService,
    private _sharedService: SharedService,
    private _mailTemplateService: MailTemplateService,
    private _fileService: FileService,
    public dialogRef: MatDialogRef<PrivacyPolicyComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.currentRole = this._accountService.getCurrentRole();
    this.privacyCategory = this.getPrivacyCat(this.currentRole);
    this.getPrivacyPolicy(this.privacyCategory);
  }

  getPrivacyCat = (currentRole: string): string => {
    let category = '';
    switch (currentRole) {
      case AuthorityName.ROLE_CANDIDATE:
        category = 'PRIVACY_CANDIDATE_UNDER18';
        if (this.data?.is18Plus) category = 'PRIVACY_CANDIDATE';
        break;

      case AuthorityName.ROLE_VERIFIER:
        category = 'PRIVACY_VERIFIER';
        break;

      case AuthorityName.ROLE_VERIFIER_ADMIN:
        category = 'PRIVACY_VERIFIER';
        break;

      case AuthorityName.ROLE_COLLEGE_ADMIN:
        // category = 'PRIVACY_COLLEGE_ADMIN';
        category = 'PRIVACY_VERIFIER';
        break;

      case AuthorityName.ROLE_HIRING_MANAGER:
        // category = 'PRIVACY_HIRING_MANAGER';
        category = 'PRIVACY_VERIFIER';
        break;

      case AuthorityName.ROLE_USER:
        // category = 'PRIVACY_USER';
        category = 'PRIVACY_VERIFIER';
        break;

      case AuthorityName.ROLE_SUPER_ADMIN:
        // category = 'PRIVACY_SUPER_ADMIN';
        category = 'PRIVACY_VERIFIER';
        break;

      case AuthorityName.ROLE_EVENT_ADMIN:
        // category = 'PRIVACY_EVENT_ADMIN';
        category = 'PRIVACY_VERIFIER';
        break;

      case AuthorityName.ROLE_PLATFORM_EXECUTIVE:
        // category = 'PRIVACY_PLATFORM_EXECUTIVE';
        category = 'PRIVACY_VERIFIER';
        break;

      case AuthorityName.ROLE_SUPPORT_EXECUTIVE:
        // category = 'PRIVACY_SUPPORT_EXECUTIVE';
        category = 'PRIVACY_VERIFIER';
        break;

      case AuthorityName.ROLE_RECRUITER:
        // category = 'PRIVACY_RECRUITER';
        category = 'PRIVACY_VERIFIER';
        break;

      case AuthorityName.ROLE_SCHEDULER:
        // category = 'PRIVACY_SCHEDULER';
        category = 'PRIVACY_VERIFIER';
        break;

      case AuthorityName.ROLE_SPOC:
        // category = 'PRIVACY_SPOC';
        category = 'PRIVACY_VERIFIER';
        break;

      case AuthorityName.ROLE_INTERVIEWER:
        // category = 'PRIVACY_INTERVIEWER';
        category = 'PRIVACY_VERIFIER';
        break;

      default:
        category = 'PRIVACY_VERIFIER';
        break;
    }
    return category;
  };

  disableProceedPrivacyBtn = () => {
    if (this.checkScrolled())
      return 'Please scroll to the end of the agreement and accept.';
    if (!this.isPolicyAccepted) return 'Please accept the policy.';
    if (this._accountService.getCurrentRole() == AuthorityName.ROLE_CANDIDATE) {
      if (!this.data?.is18Plus) {
        if (!this.isDownloaded) return 'Please download the file to proceed.';
      }
    }
    return '';
  };

  checkScrolled = () => {
    const i = document.getElementById('privacyPolicyContent');

    const y = document.getElementById('proceedBtn');

    //The height to scroll to:
    const x = i.scrollHeight - i.offsetHeight - 1;

    if (i.scrollTop >= x) return false;
    return true;
    // else if (!y.hasAttribute('disabled')) y.setAttribute('disabled', true);
  };

  getPrivacyPolicy = (category: string) => {
    this.isLoading = true;
    this._mailTemplateService.getTemplatesByCategory(category, 1, 0).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.content = res?.content[0];
      },
      error: (err) => {
        this.isLoading = false;
        console.log(err);
      },
    });
  };

  downloadFile() {
    this.isDownloading = true;
    this._fileService
      .getFile(this.content?.templateAttachments?.[0]?.attachmentFileId)
      .subscribe({
        next: (res) => {
          this.isDownloading = false;

          let resumeFileName =
            this.content?.templateAttachments?.[0]?.attachmentFileName;
          let blob: any = new Blob([res.body], {
            type: 'application/octet-stream; charset=utf-8',
          });
          if (resumeFileName.split('.')[1] === 'pdf') {
            const blobFile = new File([blob], resumeFileName, {
              type: 'application/pdf',
            });
            let fileURL = URL.createObjectURL(blobFile);
            window.open(fileURL);
          } else {
            fileSaver.saveAs(blob, resumeFileName);
          }
          this.isDownloaded = true;
        },
        error: (err) => {
          this.isDownloading = false;
          console.log('Error occured, Unable to get the file details', err);
          this._sharedService.openErrorSnackBar(
            'Something Went Wrong! Please try again!',
            ''
          );
        },
      });
  }

  handleProceedClick = () => {
    const reqBody = {
      accepted: true,
      declarationFormType: this.privacyCategory,
      templateId: this.content?.id,
      subscribed: this.isSubscribed,
    };
    this.isLoading = true;
    this._sharedService.acceptPolicyNew(reqBody).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.dialogRef.close();
      },
      error: (err) => {
        this.isLoading = false;
        console.log('Error occured, Unable to get the file details', err);
        this._sharedService.openErrorSnackBar(
          'Something Went Wrong! Please try again!',
          ''
        );
      },
    });
  };
}
