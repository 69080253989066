<fieldset [disabled]="disabled">


  <div *ngIf="view==='TWO_COLUMNS';else oneColumn">
    <div class="row">
      <div class="col-6" style="display: flex;flex-direction: column;justify-content:center;">
        <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
            {{questionIndex+1}}.
          </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
            *
          </span></p>
        <br>
        <br>
        <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">

      </div>
      <div class="col-6">
        <div *ngIf="yesIcon==='toggle_on';else notYesNo">
          <span class="text-small">{{ noText }}</span>
          <mat-slide-toggle [checked]="responseChecked" (change)="inputChanged($event)">
          </mat-slide-toggle>


          <span class="text-small">{{ yesText }}</span>

        </div>
        <ng-template #notYesNo>
          <div class="row">
            <div class="col-slide">
              <mat-icon (mouseover)="hover.negative = true" (mouseleave)="hover.negative = false" class="slide-icon"
                [ngClass]="getClassSlide('negative')" (click)="inputChanged({checked: false})" style="cursor: pointer;"
                title="{{noText}}">
                {{noIcon}}</mat-icon>
            </div>
            <div class="col-slide">
              <mat-icon (mouseover)="hover.positive = true" (mouseleave)="hover.positive = false" class="slide-icon"
                [ngClass]="getClassSlide('positive')" (click)="inputChanged({checked: true})" style="cursor: pointer;"
                title="{{yesText}}">
                {{yesIcon}}</mat-icon>
            </div>
          </div>
        </ng-template>

        <div style="height: 20px;"></div>

      </div>
    </div>
  </div>

  <ng-template #oneColumn>


    <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
        {{questionIndex+1}}.
      </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
        *
      </span></p>
    <br>
    <br>
    <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">
    <div *ngIf="yesIcon==='toggle_on';else notYesNo">
      <span class="text-small">{{ noText }}</span>
      <mat-slide-toggle [checked]="responseChecked" (change)="inputChanged($event)">
      </mat-slide-toggle>


      <span class="text-small">{{ yesText }}</span>

    </div>
    <ng-template #notYesNo>
      <div class="row">
        <div class="col-slide">
          <mat-icon (mouseover)="hover.negative = true" (mouseleave)="hover.negative = false" class="slide-icon"
            [ngClass]="getClassSlide('negative')" (click)="inputChanged({checked: false})" style="cursor: pointer;"
            title="{{noText}}">
            {{noIcon}}</mat-icon>
        </div>
        <div class="col-slide">
          <mat-icon (mouseover)="hover.positive = true" (mouseleave)="hover.positive = false" class="slide-icon"
            [ngClass]="getClassSlide('positive')" (click)="inputChanged({checked: true})" style="cursor: pointer;"
            title="{{yesText}}">
            {{yesIcon}}</mat-icon>
        </div>
      </div>
    </ng-template>

    <div style="height: 20px;"></div>
  </ng-template>


</fieldset>
