<div class="d-flex justify-content-end">
  <a
    (click)="openFilterDialog()"
    role="button"
    [matTooltip]="filterDisplayText"
    matTooltipClass="DSA_tooltip"
    class="filter-icon-wrap"
  >
    <i
      class="icon x-22 filter-icon"
      [ngClass]="{ purple: showResetFilters() }"
      aria-label="filter"
    >
    </i>
  </a>
  <button
    class="DSA_wb_text-btn clear-filter-btn"
    (click)="resetFilters()"
    *ngIf="showResetFilters()"
  >
    Clear filters
  </button>
</div>
