<div class="marT32">
  <!-- <div class="row marB16">
    <div class="col-12 d-flex justify-content-end">
      <button
        class="DSA_wb_text-btn"
        (click)="resetFilters()"
        *ngIf="showResetFilters()"
      >
        Clear filters
      </button>
      <button
        mat-flat-button
        class="DSA_wb_Icon-btn marL16"
        (click)="openFilterDialog()"
      >
        <span class="icon_btnCont">
          <i class="icon x-24 filter-icon" aria-label="filter"> </i>
        </span>
        Filter Interviews
      </button>
    </div>
  </div>
  <div *ngIf="filterState !== null" class="row marB16">
    <div class="col-12 filter-criteria">
      <ng-container #chipList *ngFor="let filter of filterByColumns">
        <mat-chip
          *ngFor="let filterBy of filterState[filter.key]"
          [removable]="'true'"
          (removed)="removeFilter(filter.key, filterBy)"
          class="DSA_wb_chipStyle-Normal marR16 marB8"
          [ngClass]="filter.key"
          [matTooltip]="filter.value"
          matTooltipClass="DSA_tooltip"
        >
          {{ filterBy.split("_").join(" ") }}
          <a
            href="javascript:void();"
            matChipRemove
            class="icon x-16 icon-close-mini DSA_wb_chipIconRight marT8"
          ></a>
        </mat-chip>
      </ng-container>
    </div>
  </div> -->
  <app-filter-btn
    *ngIf="
      accountService.hasFeatureAccess(
        FeatureName.FILTER_INTERVIEWS,
        PermissionType.READ
      )
    "
    filterDisplayText="Filter Interviews"
    [filterState]="filterState"
    [filterByColumnsAll]="filterByColumnsAll"
    [filterCriteriaGetApi]="filterCriteriaGetApi"
    (refreshTableEvent)="refreshTableData($event)"
  ></app-filter-btn>
  <app-filter-ui
    *ngIf="
      accountService.hasFeatureAccess(
        FeatureName.FILTER_INTERVIEWS,
        PermissionType.READ
      )
    "
    [filterState]="filterState"
    [filterByColumnsAll]="filterByColumnsAll"
    (refreshTableEvent)="refreshTableData($event)"
  ></app-filter-ui>
  <div class="relative">
    <app-sort-rows (onSortClick)="sortInterviews($event)"></app-sort-rows>
    <a
      href="javascript:void(0);"
      class="padLR8 refresh-btn"
      (click)="refreshAgGrid.next()"
      matTooltip="Refresh"
      matTooltipClass="DSA_tooltip"
    >
      <span class="material-icons-outlined">refresh</span>
    </a>
    <data-grid
      [columnDefs]="columnDefs"
      [tableName]="tableName"
      [buttonText]="buttonText"
      [buttonComponent]="buttonComponent"
      [levels]="levels"
      [buttonCondition]="buttonCondition"
      [searchCondition]="searchCondition"
      [interface]="interface"
      [pageNumber]="page"
      [pageSize]="size"
      (pageChangeValue)="onPageChange($event)"
      [refreshAgGrid]="refreshAgGrid.asObservable()"
      [searchValue]="searchQuery"
    ></data-grid>
  </div>
</div>
