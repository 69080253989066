import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  template: `<span
    *ngIf="params.data"
    class="DSA_wb_chipStyle"
    [ngClass]="chipClass"
    >{{ params.data.finalized ? 'YES' : 'NO' }}
  </span>`,
})
export class EvaluationTemplateStatusRendererComponent
  implements AgRendererComponent {
  params: any;
  gridApi: any;

  chipClass = 'DSA_wb_greychip';

  constructor() {}

  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;
    if (params.data) {
      if (params.data.finalized) {
        this.chipClass = 'DSA_wb_closedchip';
      } else {
        this.chipClass = 'DSA_wb_openchip';
      }
    }
  }

  refresh(params: any): boolean {
    return false;
  }
}
