<app-loader [display]="!isLoading" [textToShow]="messageOnLoading"></app-loader>
<div class="container-fluid marT48 marB32 nopadLR" *ngIf="isLoading">
  <div class="borderFixed">
    <mat-card>
      <h1 mat-dialog-title class="DSA_wb_h1Header" align="center">
        <p style="color: #003865; font-weight: bold">
          PROVISIONAL UNDERTAKING FORM
        </p>
      </h1>
      <mat-dialog-content
        id="privacyPolicyContent"
        class="mat-typography positionFixed marT8"
      >
        <ng-container>
          <div [innerHtml]="content?.templateString"></div>
          <form class="marT8" [formGroup]="candidateFinancialInfoForm">
            <div class="row marTB8">
              <div class="justify-content-start align-items-center">
                <h3
                  class="DSA_wb_h3Header web-GreyDarkest DSA_wb_text-1line"
                  style="font-weight: bolder; margin-bottom: 4px !important"
                >
                  Bank Details
                </h3>
                <div>
                  <p
                    *ngIf="enableEdit"
                    class="d-flex align-items-center DSA_wb_caption italic"
                  >
                    <i class="icon x-16 icon_color_info blue nomarL"> </i
                    >Account should be of the student ONLY.
                  </p>
                </div>
              </div>

              <div
                class="col-12 nopadLR"
                style="margin-left: 0 !important; margin-right: 0 !important"
              >
                <div>
                  <!-- <div class="row ">
                  <div
                    class="col-12 d-flex justify-content-end align-items-center"
                  >
                  <a
                      href="javascript:void(0);"
                      class="whiteIcon-Circle contact-sub-icon"
                      matTooltip="Remove Bank Detail"
                      matTooltipClass="DSA_tooltip"
                      (click)="removeBankAccount(i)"
                    >
                      <i class="icon x-16 close-icon"> </i>
                    </a>
    
    
                  </div>
                </div> -->

                  <div class="row">
                    <div class="col-md-4" style="padding-left: 0px !important">
                      <mat-form-field class="DSA_Wb_custom-form-field">
                        <input
                          matInput
                          placeholder="ACCOUNT HOLDER NAME"
                          formControlName="payeeName"
                        />
                        <mat-error
                          *ngIf="
                            candidateFinancialInfoForm
                              .get('payeeName')
                              .hasError('required')
                          "
                        >
                          Please enter Account Holder Name.
                          <!-- This is required. -->
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field class="DSA_Wb_custom-form-field">
                        <mat-label>PAYMENT METHOD</mat-label>
                        <mat-select
                          formControlName="paymentMethod"
                          (selectionChange)="onPaymentMethodChange($event)"
                        >
                          <mat-option
                            *ngFor="let paymentMethod of ['BANK_ACCOUNT']"
                            [value]="paymentMethod"
                          >
                            {{ paymentMethod.split("_").join(" ") }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4 justify-content-between">
                      <mat-form-field floatLabel="always" appearance="none">
                        <mat-label>PRIMARY ACCOUNT</mat-label>
                        <mat-slide-toggle
                          class="DSA_Wb_custom-slideToggle marL16"
                          color="primary"
                          formControlName="isPrimary"
                          (change)="setAsPrimaryAccount(i, $event)"
                        ></mat-slide-toggle>
                        <textarea matInput hidden></textarea>
                      </mat-form-field>
                    </div>
                  </div>

                  <!-- <div class="col-md-4">
                    <mat-form-field class="DSA_Wb_custom-form-field">
                      <mat-label>Bank Account Type</mat-label>
                      <mat-select formControlName="bankAccountType">
                        <mat-option
                          *ngFor="let accountType of backAccountTypeArray"
                          [value]="accountType"
                          >{{ accountType }}</mat-option
                        >
                      </mat-select>
                    </mat-form-field>
                  </div> -->

                  <div formGroupName="candidateFinancialInfoForm">
                    <div
                      *ngIf="
                        candidateFinancialInfoForm.get('paymentMethod').value ==
                        'BANK_ACCOUNT'
                      "
                      class="row d-flex justify-content align-items-center"
                    >
                      <div
                        class="col-md-4"
                        style="padding-left: 0px !important"
                      >
                        <mat-form-field class="DSA_Wb_custom-form-field">
                          <input
                            matInput
                            placeholder="ACCOUNT NUMBER"
                            [formControl]="
                              candidateFinancialInfoForm.get(
                                'accountDetails.number'
                              )
                            "
                          />
                          <mat-error
                            *ngIf="
                              candidateFinancialInfoForm
                                .get('accountDetails.number')
                                .hasError('required')
                            "
                          >
                            Please enter Account Number.
                            <!-- This is required. -->
                          </mat-error>
                          <mat-error
                            *ngIf="
                              candidateFinancialInfoForm
                                .get('accountDetails.number')
                                .hasError('minlength')
                            "
                          >
                            Account Number Should be 10 Digit
                          </mat-error>
                          <mat-error
                            *ngIf="
                              candidateFinancialInfoForm
                                .get('accountDetails.number')
                                .hasError('pattern')
                            "
                          >
                            Provide a valid account number
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-4">
                        <mat-form-field class="DSA_Wb_custom-form-field">
                          <input
                            matInput
                            placeholder="IFSC CODE"
                            [formControl]="
                              candidateFinancialInfoForm.get(
                                'accountDetails.IFSC'
                              )
                            "
                          />
                          <mat-error
                            *ngIf="
                              candidateFinancialInfoForm
                                .get('accountDetails.IFSC')
                                .hasError('required')
                            "
                          >
                            Please enter IFSC Code.
                            <!-- This is required. -->
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <!--  <div
                    *ngIf="
                      candidateFinancialInfoForm.get('paymentMethod').value ==
                      'UPI'
                    "
                    class="row d-flex justify-content align-items-center"
                  >
                    <div class="col-md-4">
                      <mat-form-field class="DSA_Wb_custom-form-field">
                        <input
                          matInput
                          placeholder="UPI ID"
                          formControlName="upiId"
                        />
                        <mat-error
                          *ngIf="
                            candidateFinancialInfoForm
                              .get('accountDetails.upiId')
                              .hasError('required')
                          "
                        >
                          Please enter UPI Id.
                        </mat-error>
                        <mat-error
                          *ngIf="
                            candidateFinancialInfoForm
                              .get('accountDetails.upiId')
                              .hasError('pattern')
                          "
                        >
                          Provide a valid UPI Id
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div> -->
                  </div>
                  <p
                    *ngIf="enableEdit"
                    class="
                      padLR16
                      d-flex
                      align-items-center
                      DSA_wb_caption
                      italic
                    "
                  >
                    <i class="icon x-16 icon_color_info blue nomarL marR4"> </i
                    >UPI has been discontinued. Please update your bank account
                    details.
                  </p>
                </div>
              </div>
            </div>
          </form>
          <div *ngIf="this.enableEdit">
            <div
              class="d-flex justify-content-between align-items-center marT32"
            >
              <h3
                class="DSA_wb_h3Header web-GreyDarkest DSA_wb_text-1line"
                style="font-weight: bolder"
              >
                Upload Documents
              </h3>
            </div>
            <div
              class="col-md-4"
              style="padding-left: 0 !important"
              *ngIf="true"
            >
              <mat-form-field class="DSA_Wb_custom-form-field">
                <mat-label>BANK PROOF</mat-label>
                <mat-select
                  [formControl]="bankProofControl"
                  (selectionChange)="onBankProofSelect($event)"
                >
                  <mat-option
                    *ngFor="let bankProof of bankProofType"
                    [value]="bankProof"
                  >
                    {{ bankProof.split("_").join(" ") }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="bankProofControl.hasError('required')">
                  Please select a Bank Proof.
                  <!-- This is required. -->
                </mat-error>
              </mat-form-field>
            </div>
            <div [formGroup]="scholarshipDocumentForm">
              <div
                class="col-md-4"
                style="padding-left: 0 !important"
                *ngFor="let doc of scholarshipDocumentList"
              >
                <app-single-file-upload
                  [doc_type]="doc"
                  [controlName]="doc"
                  (isFileUploaded)="handleFileUpload($event)"
                  [applicantId]="candidateInfoId"
                  [bankValidation]="true"
                  [bankProofJobId]="opportunityId"
                ></app-single-file-upload>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-start align-items-center">
            <mat-checkbox
              class="DSA_Wb_custom-checkbox nomarLR"
              aria-label="checkbox"
              disableRipple="true"
              [(ngModel)]="isProvisionalAccepted"
            >
              Accept
            </mat-checkbox>
          </div>
        </ng-container>
      </mat-dialog-content>

      <mat-dialog-actions align="start">
        <div matTooltipClass="DSA_tooltip" class="marT8">
          <!--  Add two buttons one for submit and one for withdrawl-->
          <button
            mat-flat-button
            class="DSA_wb_primary-btn"
            id="proceedBtn"
            [disabled]="!isProvisionalAccepted || (enableEdit && !fileUploaded)"
            (click)="handleProceedClick()"
          >
            <span class="pad8">Submit</span>
          </button>
          <button
            mat-flat-button
            class="DSA_wb_primary-btn marL16"
            id="withdrawlBtn"
            (click)="handleWithDrawClick()"
          >
            <span>Withdraw</span>
          </button>
        </div>
      </mat-dialog-actions>
    </mat-card>
  </div>
</div>
