<h1 mat-dialog-title>{{ data.title ? data.title : "Confirmation" }}</h1>
<div mat-dialog-content>
  <p class="DSA_wb_caption web-GreyLight">Infosys Foundation has disbursed an amount of &#x20b9;{{data.transactionAmount}} on {{data.transactionDate}}.<br> Please enter your Transaction ID(Case-Sensitive) as mentioned in your Bank records.</p>
</div>

<div class="inpt_tID">
    <label for="t_ID">
        Transaction ID:
    </label>
    <input class="mat-form-field mrgin-left" type="text" id="t_ID" [(ngModel)]="transactionID" placeholder="Enter Transaction ID">

</div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <!-- <button mat-button [mat-dialog-close]="'No'" class="DSA_wb_miniSecondary-btn">
    No
  </button> -->
  <button mat-button class="DSA_wb_miniPrimary-btn" [mat-dialog-close]="transactionID">
    Confirm
  </button>
</div>
