import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RoutesService } from './routes.service';
import * as _ from 'lodash';
import { CollegeCandidatesStateStorageService } from '../modules/hiring-manager/college-candidates/college-candidates-state-storage.service';

@Injectable({
  providedIn: 'root',
})
export class CandidateService {
  selectedCandidate: any;
  currentCandidate: any;

  constructor(
    private http: HttpClient,
    private routesService: RoutesService,
    private collegeCandidateStateStorage: CollegeCandidatesStateStorageService
  ) { }

  private headers = new HttpHeaders().set('content-type', 'application/json');

  abc = map((response): any => {
    // eaders.headers.get('x-total-count')
    return response;
  });

  getCandidateDetails = (candidateId, interviewId) => {
    return this.http.get(
      environment.interviewServiceUrl +
      'api/candidate/' +
      candidateId +
      '/interview/' +
      interviewId,
      {
        headers: this.headers,
      }
    );
  };

  getCandidateByApplicantId = (applicantId) => {
    const id = applicantId;
    return this.http.get(environment.CANDIDATEMS_API + '/candidate-details/' + id);
  }

  getCandidate = (query) => {
    const opts = {
      headers: this.headers,
    };
    return this.http.get<any[]>(
      environment.profileServiceUrl + `api/candidates/search/${query}`,
      opts
    );
  };

  getCandidatesByName = (searchString, page, size) => {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);
    return this.http.post(
      `${environment.gatewayServiceUrl}api/interviewer/interviews/search/${searchString}`,
      { filters: null },
      { headers: this.headers, params }
    ) as Observable<any>;
  };

  getCandidatesByCollege = (applicationStatus): Observable<any> => {
    const collegeCandidatesData =
      this.collegeCandidateStateStorage.getCollegeCandidatesData();
    if (applicationStatus === 'ALL') return of(collegeCandidatesData);
    else {
      const data = collegeCandidatesData.content.filter(
        (_) => _.applicationStatus === applicationStatus
      );
      const responseDTO = _.cloneDeep(collegeCandidatesData);
      responseDTO.content = data;
      responseDTO.totalElements = data?.length;
      responseDTO.numberOfElements = data?.length;
      return of(responseDTO);
    }
  };

  changeApplicationStatusOfSelectedCandidates = (id, status) => {
    let idStatus:any = {  "applicationStatus": status,"actionComments": "comment2" };
    let params = new HttpParams();
        params = params.append(id,  idStatus);
    return this.http.put(
      `${environment.POSTMS_API}/applications/action{id}`,
      { headers: this.headers }
    ) as Observable<any>;
    //return of(this.collegeCandidateStateStorage.changeApplicationStatusOfSelectedCandidates(status));
  };
  
  changeApplicationStatusOfmultipleSelectedCandidates = (applicationActions?: any): Observable<any> => {
    const opts = {
      headers: this.headers,}
      return this.http.put<any>(
        `${environment.POSTMS_API}/applications/action`,
        applicationActions,
      { headers: this.headers })
    }
  //! repeated in recruiter service
  getCandidateById = (id): Observable<any> => {
    const opts = {
      headers: this.headers,
    };
    return this.http.get(
      `${environment.profileServiceUrl}api/candidates/${id}`,
      opts
    );
  };

  //! repeated in recruiter service
  getAllCandidates = (size?: number): Observable<any[]> => {
    let params = new HttpParams();
    params = params.append('size', `${size || 20000}`);

    const opts = {
      headers: this.headers,
      params,
    };
    return this.http.get<any[]>(
      environment.profileServiceUrl + 'api/candidates',
      opts
    );
  };
  //! recruiter service has another implementation of
  //! setSelectedCandidate that doesnt restructureRoute
  setSelectedCandidate = (data) => {
    this.selectedCandidate = data;
    // this.routesService.reStructureRoutesOn(true);
  };

  getSelectedCandidate = () => {
    return this.selectedCandidate;
  };

  getNoShowCandidateDetails = (id: string) => {
    return this.http.get(
      `${environment.interviewServiceUrl}api/interviews/${id}/actions/noshow`,
      { headers: this.headers }
    ) as Observable<any>;
  };

  postCandidateResume = (file: any, id: any): Observable<any> => {
    return this.http.post<any>(
      environment.profileServiceUrl + 'api/candidates/' + id + '/resume',
      file,
      {
        headers: this.headers,
        observe: 'response',
        responseType: 'blob' as 'json',
      }
    );
  };

  getCandidateResume = (candidateId: any, interviewId): Observable<any> => {
    return this.http
      .get<any>(
        environment.interviewServiceUrl +
        'api/candidate/' +
        candidateId +
        '/interview/' +
        interviewId +
        '/resume',
        {
          headers: this.headers,
          observe: 'response',
          responseType: 'blob' as 'json',
        }
      )
      .pipe(this.abc);
  };

  getCandidatePhoto = (candidateId: any, interviewId): Observable<any> => {
    return this.http
      .get<any>(
        environment.interviewServiceUrl +
        'api/candidate/' +
        candidateId +
        '/interview/' +
        interviewId +
        '/profile-picture',
        {
          headers: this.headers,
          observe: 'response',
          responseType: 'blob' as 'json',
        }
      )
      .pipe(this.abc);
  };
  //! repeated in recruiter service
  freezeCandidateDetails = (id) => {
    return this.http.get<any>(
      environment.interviewServiceUrl +
      'api/candidate-interview-rounds/' +
      id +
      '/actions/freeze',
      { headers: this.headers }
    );
  };

  //! repeated in recruiter service
  unFreezeCandidateDetails = (id) => {
    return this.http.get<any>(
      environment.interviewServiceUrl +
      'api/candidate-interview-rounds/' +
      id +
      '/actions/unfreeze',
      { headers: this.headers }
    );
  };

  getJobCandidates = (
    id,
    page,
    size,
    frozen,
    interviewResult,
    query
  ): Observable<any[]> => {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);
    const opts = {
      headers: this.headers,
      params,
    };
    let body = {
      jobOpeningId: id,
    };
    if (frozen) {
      if (frozen == 'true') {
        body['frozen'] = true;
      } else {
        body['frozen'] = false;
      }
    }
    if (interviewResult) {
      body['interviewResult'] = interviewResult;
    }
    if (query) {
      body['query'] = query;
    }
    return this.http.post<any[]>(
      `${environment.gatewayServiceUrl}api/candidate-interview-rounds/job-opening`,
      body,
      opts
    );
  };

  setCurrentCandidate = (candidate) => {
    this.currentCandidate = candidate;
  };

  getCurrentCandidate = () => {
    return this.currentCandidate;
  };

  getRounds = (page, size): Observable<any> => {
    let params = new HttpParams();
    params = params.append('page', `${page}`);
    params = params.append('size', `${size}`);
    return this.http.get<any[]>(
      environment.interviewServiceUrl +
      `api/interviews/candidate-interview-rounds/${this.selectedCandidate.cirId}`,
      { headers: this.headers, params }
    );
  };

  transferResumeFromIntap = (applicationId, candidateId): Observable<any> => {
    return this.http.get<any>(
      environment.interviewServiceUrl +
      'api/transfer-intap-document/' +
      `${candidateId}/${applicationId}`,
      { headers: this.headers }
    );
  };
  getCandidateJobsInEvent = (eventId, candidateId): Observable<any[]> => {
    return this.http.get<any[]>(
      environment.interviewServiceUrl +
      'api/candidate-interview-rounds/event/' +
      eventId +
      `/candidate/${candidateId}`,
      { headers: this.headers }
    );
  };

  createCirIdForCandidateInJob = (data: {
    candidateId: number;
    jobOpeningId: number;
  }): Observable<any> => {
    return this.http.post(
      environment.interviewServiceUrl + 'api/create-candidate-interview-round',
      data,
      { headers: this.headers }
    );
  };

  getCandidateApplicationId = (cirId): Observable<any> => {
    return this.http.get<any>(
      environment.interviewServiceUrl +
      'api/candidate-application-details/cirId/' +
      cirId,
      { headers: this.headers }
    );
  };

  updateCandidateApplicationId = (data): Observable<any> => {
    return this.http.put<any>(
      environment.interviewServiceUrl +
      'api/candidate-application-details/elastic',
      data,
      { headers: this.headers }
    );
  };
}
