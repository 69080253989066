import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-filter-ui',
  templateUrl: './filter-ui.component.html',
  styleUrls: ['./filter-ui.component.scss'],
})
export class FilterUiComponent implements OnInit {
  @Input() filterState = null;
  @Input() filterByColumnsAll: any;

  @Output() refreshTableEvent = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  removeFilter(filterKey, filterBy) {
    let updatedFilters = this.filterState[filterKey].filter(
      (_) => _ !== filterBy
    );
    if (updatedFilters.length > 0) this.filterState[filterKey] = updatedFilters;
    else delete this.filterState[filterKey];
    this.refreshTableEvent.emit(this.filterState);
  }
}
