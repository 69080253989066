import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CandidateInfoService } from '../modules/candidate/services/candidate-info.service';

@Component({
  selector: 'app-colleges-list',
  templateUrl: './colleges-list.component.html',
  styleUrls: ['./colleges-list.component.scss'],
})
export class CollegesListComponent implements OnInit {
  columnDefs: any = [
    
    {
      headerName: 'College Name',
      field: 'name',
      hide: false,
      minWidth: 100,
    },
    {
      headerName: 'City',
      field: 'addresses',
      hide: false,
      minWidth: 50,
      valueFormatter: this.cityFormatter,
    },
    {
      headerName: 'State',
      field: 'addresses',
      hide: false,
      valueFormatter: this.stateFormatter,
    },
    {
      headerName: 'Category',
      field: 'subCategory',
      hide: false,
      minWidth: 50,
    },
  ];
  refreshAgGrid: Subject<void> = new Subject<void>();
  levels: number = 1;
  page: number = 0;
  pageSize: number = 25;
  searchedText: string = '';
  searchCategory: string = '';
  constructor(private candidateJobsService: CandidateInfoService) {}

  ngOnInit(): void {}

  interface = (
    size: number,
    page: number,
    query?: string
  ): Observable<any[]> => {
    return this.candidateJobsService.getCollegeList(
      page,
      size,
      query,
      this.searchCategory
    );
  };

  onSearchOptionChange(event: any) {
    const selectedOption = event.value;
    this.searchCategory = selectedOption;
    this.refreshAgGrid.next();
  }

  cityFormatter(params: any) {
    if (!params.data) return;
    //console.log(params);
    return params.data?.addresses[0]?.city;
  }
  stateFormatter(params: any) {
    if (!params.data) return;
    //console.log(params);
    return params.data?.addresses[0]?.state;
  }
}
