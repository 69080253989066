<mat-card class="font-secondary DSA_wb_card-widget applicant-card marB16">
  <mat-card-content>
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center ml-auto">
        <span class="DSA_wb_mainBodyTxt2 web-GreyLight DSA_wb_text-1line marL8">
          {{ job.createdDate | date: "yyyy-MM-dd" }}
        </span>
      </div>
    </div>

    <div class="row">
      <div
        *ngIf="!applicationDetails"
        class="col-md-10 col-sm-12 d-flex align-items-center"
      >
        <div class="titleContainer marR16">
          <a
            class="jobTitle"
            matTooltip="View Problem Statement Details"
            matTooltipClass="DSA_tooltip"
            [routerLink]="[
              '/candidate/problem-statements',
              job.opportunity.id
            ]"
            [state]="{ checkForLocalStepState: true }"
          >
            {{ job?.opportunity?.title }}
          </a>
        </div>

        <mat-chip
          id="stat"
          class="DSA_wb_chipStyle"
          [ngClass]="getApplicationStatusBadgeClass(job.status)"
        >
          {{ job.status?.split("_").join(" ")}}
        </mat-chip>
      </div>

      <div class="col-12 d-flex align-items-center marT16">
        <strong style="white-space: nowrap">Location: </strong>
        <span class="DSA_wb_mainBodyTxt2 web-GreyLight DSA_wb_text-1line marL8">
          {{ job?.opportunity?.location }}
        </span>
      </div>


      <div class="col-12 d-flex align-items-center marT16">
        <strong style="white-space: nowrap">Application ID: </strong>
        <span class="DSA_wb_mainBodyTxt2 web-GreyLight DSA_wb_text-1line marL8">
          {{ job?.id }}
        </span>
      </div>

      <div class="col-12 d-flex align-items-center marT16">
        <strong style="white-space: nowrap">Description: </strong>
        <span
          [innerHTML]="job?.opportunity?.about"
          class="
            textcustomwidth
            DSA_wb_mainBodyTxt2
            web-GreyLight
            DSA_wb_text-1line
            marL8
          "
        >
        </span>
      </div>
    </div>
    <div class="col-12 d-flex align-items-center">
      <!-- <div class=" marT16">
      <strong>Skills: </strong>
    </div> -->
    </div>
    <mat-chip-list class="col-12 d-flex align-items-center">
      <mat-chip class="marTB16" *ngFor="let s of job?.opportunity?.tags">
        <span>{{ s }}</span>
      </mat-chip>
    </mat-chip-list>

    <div class="col-md-12" *ngIf="displayAttachment">
      <mat-accordion>
        <mat-expansion-panel class="my-panel">
          <mat-expansion-panel-header>
            <i class="marR8 fa fa-paperclip" aria-hidden="true"></i>
            <strong>Attachments (If any)</strong>
          </mat-expansion-panel-header>
          <div class="row justify-content-start">
            <div
              *ngFor="
                let doc of getLatestUniqueCategories(job?.applicationFiles)
              "
              class="col-md-6"
            >
              {{ doc?.category?.split("_").join(" ") }}
              <span class="marL8">
                <a
                  (click)="downloadFileCloud(doc.fileId, doc.category)"
                  href="javascript:void(0)"
                >
                  <i class="fa fa-cloud-download" aria-hidden="true"></i>
                </a>
              </span>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <mat-card-actions align="end">
      <div *ngIf="job.status === 'PROVISIONAL_APPROVED'">
        <button
          *ngIf="!applicationDetails"
          mat-button
          matTooltip="Download Provisional Offer Letter"
          class="DSA_wb_primary-btn text-uppercase customButton marR8"
          (click)="downloadProvisionalOfferLetter()"
          matTooltipClass="DSA_tooltip"
        >
          Download Provisional
        </button>
      </div>
      <div *ngIf="job.status === 'UNDERTAKING_REQUESTED'">
        <button
          *ngIf="!applicationDetails"
          mat-button
          matTolltip="Accept Provisional Offer Letter"
          class="DSA_wb_primary-btn text-uppercase customButton marR8"
          (click)="acceptProvisionalOfferLetter()"
          matTooltipClass="DSA_tooltip"
        >
          Provisional Undertaking
        </button>
      </div>
      <div
        *ngIf="
          job.status !== 'PROVISIONAL_APPROVED' &&
          job.status !== 'UNDERTAKING_REQUESTED' &&
          job.status !== 'SCHOLARSHIP_DISBURSED' &&
          job.status !== 'SCHOLARSHIP_RECEIVED'
        "
      >
        <button
          *ngIf="!applicationDetails && job.status==='INTERMITTENT' || job.status ==='SENT_BACK_TO_CANDIDATE'"
          [disabled]="
            job.status === 'WITHDRAWN' ||
            job.status === 'ACCEPTED' ||
            job.status === 'REJECTED'
          "
          mat-button
          matTooltip="Modify Application"
          class="DSA_wb_primary-btn text-uppercase customButton marR8"
          (click)="applicationResubmit(job.opportunity.id,job.status,job.subStatus,job?.id)"
          matTooltipClass="DSA_tooltip"
        >
          MODIFY
        </button>

        <button
        *ngIf="!applicationDetails && job.status==='SUBMITTED'"
        [disabled]="
          job.status === 'WITHDRAWN' ||
          job.status === 'ACCEPTED' ||
          job.status === 'REJECTED'
        "
        mat-button
        matTooltip="View Application"
        class="DSA_wb_primary-btn text-uppercase customButton marR8"
        (click)="applicationResubmit(job.opportunity.id,job.status,job.subStatus,job?.id)"
        matTooltipClass="DSA_tooltip"
      >
        {{'VIEW' }}
      </button>

        <button
          [disabled]="
            job.status === 'WITHDRAWN' ||
            job.status === 'ACCEPTED' ||
            job.status === 'REJECTED'
          "
          mat-button
          class="DSA_wb_secondary-btn text-uppercase customButton"
          matTooltip="Cancel Application"
          (click)="withdraw(job.id)"
          matTooltipClass="DSA_tooltip"
        >
          Withdraw
        </button>
      </div>
      <div *ngIf="job.status === 'SCHOLARSHIP_DISBURSED'">
        <button
          mat-button
          matTooltip="Confirm Scholarship Disbursement"
          class="DSA_wb_primary-btn text-uppercase customButton marR8"
          (click)="verifyScholarshipDisbursed()"
          matTooltipClass="DSA_tooltip"
        >
          Confirm
        </button>
      </div>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
