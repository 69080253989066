<div class="delete-page">
    <form class="form" name="deletePageForm" role="form" novalidate>        
        <div class="delete-container">
            <div class="title-box">
                <h2 mat-dialog-title >{{titleData}}</h2>
            </div>
            <div class="cancel-box">
                <button mat-icon-button class="material-icons close-btn" (click)="close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>      
        </div>
        <div>
            <p id="jhi-delete-application-heading" [innerHTML] = "messageData"></p>
        </div>
        <mat-divider class="my-3"></mat-divider>
        <div mat-dialog-actions autofocus>
            <button mat-button class="DSA_primary-btn" (click)="confirm()">                  
                <span>{{yesText}}</span>                    
            </button>
            <button mat-button class="DSA_secondary-btn" autofocus (click)="cancelClick()">
                <span>{{noText}}</span>
            </button>
        </div>
    </form>
</div>