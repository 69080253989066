import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AddPanelistToInterviewDialogComponent } from 'src/app/shared/components/add-panelist-to-interview-dialog/add-panelist-to-interview-dialog.component';
import { AccountService } from 'src/app/services/account.service';
import { SharedService } from 'src/app/services/shared.service';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
import { EventTypeEnum, IEvent } from 'src/app/shared/model/event.model';

@Component({
  selector: 'app-panelist-renderer',
  templateUrl: './panelist-renderer.component.html',
  styleUrls: ['./panelist-renderer.component.scss'],
})
export class PanelistRendererComponent implements AgRendererComponent {
  eventDetails: IEvent;

  items = [];
  currentRowData;

  FeatureName = FeatureName;
  PermissionType = PermissionType;

  showAddPanelistCondition: boolean = false;

  constructor(
    private _dialog: MatDialog,
    private accountService: AccountService,
    private sharedService: SharedService
  ) {}

  agInit(params: ICellRendererParams | any): void {
    if (params.data) {
      this.currentRowData = params.data;
      this.items = params.data.panelists;

      if (params.eventDetails) {
        this.eventDetails = params.eventDetails;
        this.showAddPanelistCondition =
          this.currentRowData.interviewStatus === 'UPCOMING' &&
          this.eventDetails.eventType === EventTypeEnum.LATERAL_FACE_TO_FACE &&
          (!this.items || this.items?.length === 0) &&
          this.accountService.hasFeatureAccess(
            FeatureName.INTERVIEW_SCHEDULE,
            PermissionType.CREATE
          );
      }
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  showAddPanelistToInterviewDialog = () => {
    const dialogRef = this._dialog.open(AddPanelistToInterviewDialogComponent, {
      data: {
        eventId: this.eventDetails.id,
        interviewId: this.currentRowData.id,
      },
      minWidth: '500px',
    });

    dialogRef.afterClosed().subscribe((val) => {
      if (val) {
        this.sharedService.openSnackBar(
          'Panelist added successfully. Please refresh to view updated records',
          ''
        );
      }
    });
  };
}
