import { Interview } from './../../shared/model/interview.model';
import { InterviewStatus } from 'src/app/shared/model/enumerations/interview-status.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import * as fileSaver from 'file-saver';
import { ConfirmationComponent } from '../../shared/components/confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import { JobService } from 'src/app/services/job.service';
import { EvaluationService } from 'src/app/services/evaluation.service';
import { FileService } from 'src/app/services/file.service';
import { CandidateService } from 'src/app/services/candidate.service';
import { SharedService } from 'src/app/services/shared.service';
import { FeedbackService } from 'src/app/services/feedback.service';
import { FaceRecognitionService } from 'src/app/services/face-recognition.service';
import { InterviewRoundsService } from 'src/app/services/interview-rounds.service';
import { EventService } from 'src/app/services/event.service';
import { RecruiterService } from 'src/app/services/recruiter.service';
import { AccountService } from 'src/app/services/account.service';
import { RoundType } from 'src/app/shared/model/enumerations/round-type.model';
import { ExperienceType, JobRole } from 'src/app/shared/model/job-role.model';
import {
  FinalResultEnum,
  FinalResultUI,
} from 'src/app/shared/model/enumerations/final-result.model';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
import {
  SubStatusEnum,
  SubStatusUI,
} from 'src/app/shared/model/enumerations/substatus.model';
import { FinalActionConfirmationDialogComponent } from 'src/app/infy-components/infy-recruiter-feedback/final-action-confirmation-dialog/final-action-confirmation-dialog.component';

@Component({
  selector: 'app-infy-recruiter-feedback',
  templateUrl: './infy-recruiter-feedback.component.html',
  styleUrls: ['./infy-recruiter-feedback.component.scss'],
})
export class InfyRecruiterFeedbackComponent implements OnInit {
  loading: boolean = false;
  feedbackLoading: boolean = false;

  hrCompleted: boolean = false;

  interviewId;
  candidateId;
  applicationId;
  candidateSource;
  evaluationData;
  candidateData;
  recruiterResult;

  FinalResultEnum = FinalResultEnum;

  subStatus = null;
  subStatusArray = [
    {
      value: SubStatusEnum.TECHNICAL_PENDING,
      label: SubStatusUI.TECHNICAL_PENDING,
      description: 'Select when technical interview is pending',
      alertMessage: '',
    },
    {
      value: SubStatusEnum.TECHNICAL_COMPLETED,
      label: SubStatusUI.TECHNICAL_COMPLETED,
      description:
        'Select when technical interview is completed and no further interviews are planned to be conducted on Interview Platform',
      alertMessage:
        'Substatus <strong class="font-red">cannot be modified</strong> once "Technical Completed" is selected. If HR interview is planned to be conducted on this platform, select HR Pending instead.',
    },
    {
      value: SubStatusEnum.HR_PENDING,
      label: SubStatusUI.HR_PENDING,
      description:
        'Select when HR interview is planned to be conducted on Interview Platform',
      alertMessage:
        'Substatus <strong class="font-red">cannot be changed to Technical Pending/Technical Completed</strong> once "HR Pending" is selected',
    },
    {
      value: SubStatusEnum.HR_COMPLETED,
      label: SubStatusUI.HR_COMPLETED,
      description:
        'Select when HR interview is completed and no further interviews are planned on Interview Platform',
      alertMessage:
        'Substatus <strong class="font-red">cannot be modified</strong> once "HR Completed" is selected.',
    },
  ];

  eventStatus: any;

  faceValid = true;
  questionsData: any = [];
  upcomingInterviews: Array<any> = [];
  noShowInterviews: Array<any> = [];
  // feedbackPendingInterviews: any = [];
  evaluationFormData = [];
  personalRemarksOptions: any = [
    'Speech',
    'Willing to Relocate',
    'Physical Disability',
    'Mother Tongue Influence',
  ];

  dataSource = [];
  ELEMENT_DATA = [];
  remarks = '';
  interviewSnapshots = [];
  customizableForm = [];
  personalRemarks: any = [
    'Has inclination to work in different industry/ pursue higher studies',
    'Has significant **speech impediment e.g. Stammering, Lisp etc.',
    'Has significant **mother tongue influence',
    'Is differently abled',
  ];
  evaluationDetailList = [];

  personalTitle: string = 'Additional Remarks';
  personalDescription: string = ' ';
  personalComment: string;

  evaluationDataForm = [];
  workExperienceSEandNEG = {};

  photoVerificationOptions = ['College ID Card', 'Government Issued ID Card'];
  photoVerificationValue = [];
  ppValues = [];
  jobOpening: any;
  showActions = false;
  interviewData: any = {};
  selectedForAnotherJob = false;
  profileImage;
  disableButton = false;
  resumeFileName = '';
  resumeBlob;

  applicationFormFileName = '';
  applicationFormBlob;

  iapAssessmentImage;

  nodes = [];
  links = [];
  data = {};
  center$: Subject<boolean> = new Subject();
  zoomToFit$: Subject<boolean> = new Subject();
  update$: Subject<boolean> = new Subject();

  faceRecognitionNodes = [];
  faceRecognitionLinks = [];
  faceRecognitionData = {};

  faceData;
  showFaceMatching = false;
  faceMatchingColor: String = '#ADFF2F';
  recruiterFaceMatchingFeedback = [];
  profilePicAvailable = false;
  assessmentPicAvailable = false;

  defaultJob;
  jobDropDown: any[] = [];
  jobRoleSelectedFor;
  currentCandidate;
  currentJob: JobRole;
  jobRoleId;
  eventType;

  eventId;

  sendBacktoPanelistStatus: Map<string, boolean> = new Map<string, boolean>();
  sendBackToPanelistRemark: Map<string, string> = new Map<string, string>();

  FeatureName = FeatureName;
  PermissionType = PermissionType;

  isDeclarationAccepted: boolean = false;

  experienceType: ExperienceType;
  declaration = {
    fresher:
      'I acknowledge that I have checked all the 3 pictures and it matches with the candidate who has been interviewed.',
    lateral:
      'I acknowledge that I have checked both the profile and the interview pictures and it matches with the candidate who has/is been/being interviewed',
  };

  substatusDisableReason = {};
  finalStatusDisableReason = {};

  isUserOnSamePage: boolean = false;
  // ! don't change value of this variable, integrated with intap for rejection reason (RAIL/RAUL)
  rejectionReasonTextToAppendInRemarks: string = ', Reason for Rejection: ';
  rejectionReasonSelectedByRecruiter: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public _location: Location,
    private sanitizer: DomSanitizer,
    private router: Router,
    private dialog: MatDialog,
    private jobService: JobService,
    private evaluationService: EvaluationService,
    private fileService: FileService,
    private candidateService: CandidateService,
    private sharedService: SharedService,
    private feedbackService: FeedbackService,
    private faceRecognitionService: FaceRecognitionService,
    private interviewRoundsService: InterviewRoundsService,
    private eventService: EventService,
    private recruiterService: RecruiterService,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.feedbackLoading = true;
    this.eventStatus = localStorage.getItem('eventStatus');
    if (this.eventStatus === null) {
      this.router.navigate(['/dashboard']);
    }

    this.eventId = localStorage.getItem('eventId');

    if (!this.eventId) {
      this._location.back();
    }

    this.eventService.getEventById(this.eventId).subscribe((res: any) => {
      this.eventType = res.eventType;
    });

    this.activatedRoute.params.subscribe(
      (params) => {
        if (params['id']) {
          this.interviewId = params['id'];
          this.jobRoleId = params['jobRoleId'];

          this.currentCandidate = this.candidateService.getCurrentCandidate();
          // console.log(this.currentCandidate?.jobRoleId);
          // this.jobRoleSelectedFor = this.currentCandidate.jobRoleId;

          this.jobService
            .getJobRoleById(this.jobRoleId)
            .subscribe(async (res: JobRole) => {
              this.currentJob = res;
              this.experienceType = this.currentJob.experienceType;

              await this.jobService
                // .getAssociatedJobs(this.jobRoleId)
                .getAssociatedJobsForEvent(this.eventId, this.jobRoleId)
                .subscribe((res) => {
                  this.jobDropDown = res.associatedJobRoles;
                  // this.jobDropDown = res;
                  this.jobDropDown.push(this.currentJob);
                });
            });

          this.evaluationService
            .getAllCandidateEvaluations(this.interviewId)
            .subscribe(
              async (res) => {
                this.feedbackLoading = false;
                this.evaluationData = res;
                this.getAllInterviews(this.interviewId);

                let refers = 0;
                this.evaluationData.forEach((element) => {
                  /*
                   * IF ROUNDTYPE is HR and STATUS is COMPLETED for any feedback,
                   * display 'REJECT AT INFY LEVEL' button,
                   * else display 'REJECT AT UNIT LEVEL' button
                   */
                  if (
                    element?.interview?.roundType === RoundType.HR &&
                    element?.interview?.interviewStatus ===
                      InterviewStatus.COMPLETED
                  ) {
                    this.hrCompleted = true;
                  }

                  this.jobOpening = element.interview.jobOpening.eventType;
                  console.log('Event Type', this.jobOpening);
                  if (element.result == 'REFER_FOR_POST') {
                    refers = refers + 1;
                  }
                });
                if (
                  this.evaluationData.length > 0 &&
                  this.evaluationData.length == refers
                ) {
                  this.showActions = false;
                } else {
                  this.showActions = true;
                }

                this.nodes[0] = {
                  id: 'candidate-id',
                  label: '',
                  type: 'data',
                };
                this.evaluationData.reverse();
                await this.setData(this.evaluationData);
                this.evaluationData.map((feedback) => {
                  // storing work experience state for SE and NEG job type
                  if (['SE', 'NEG'].includes(feedback.jobtype)) {
                    let workexperience = feedback.ratings.find(
                      (item) => item.title === 'Work Experience'
                    );
                    if (workexperience) {
                      this.workExperienceSEandNEG[feedback.interview.title] =
                        workexperience.remarks;
                    }
                  }
                });

                this.evaluationData.forEach((data, index) => {
                  this.data[data.id.toString()]['imgTitle'] =
                    'Interview Snapshots';
                  this.data[data.id.toString()]['imgArr'] = [];
                  this.interviewSnapshots[index] = [];
                  this.getAllEvaluations(
                    data?.interview?.jobOpening?.id,
                    index
                  );
                  data?.interview?.interviewFileMappings?.forEach((file) => {
                    this.fileService.getFile(file.fileId).subscribe((res) => {
                      this.data[data.id.toString()]['imgArr'].push(
                        this.sanitizer.bypassSecurityTrustUrl(
                          window.URL.createObjectURL(res.body)
                        )
                      );
                      this.interviewSnapshots[index].push({
                        url: this.sanitizer.bypassSecurityTrustUrl(
                          window.URL.createObjectURL(res.body)
                        ),
                        blob: res.body,
                      });

                      this.graphUpdate();
                    });
                  });
                });
                if (params['canId']) {
                  this.candidateId = params['canId'];
                  this.getDocuments();
                }
              },
              (err) => {
                this.feedbackLoading = false;
              }
            );
        }
      },
      () => {
        this.loading = false;
      }
    );
  }

  checkSubstatusDisabled = (substatus: SubStatusEnum) => {
    const selectedFinalAction = this.interviewData?.result;
    const selectedSubStatus = this.interviewData?.subStatus;

    if (
      !this.accountService.hasFeatureAccess(
        FeatureName.FEEDBACK_SUBSTATUS,
        PermissionType.CREATE
      )
    ) {
      Object.keys(SubStatusEnum).forEach((key) => {
        this.substatusDisableReason[key] =
          'You do not have access to this feature';
      });
      return true;
    }

    if (this.eventStatus === 'CLOSED') {
      Object.keys(SubStatusEnum).forEach((key) => {
        this.substatusDisableReason[key] =
          'No action can be taken as event is CLOSED';
      });
      return true;
    }

    // if (
    //   [
    //     FinalResultEnum.SELECTED,
    //     FinalResultEnum.REJECTED,
    //     FinalResultEnum.REJECTION_UNIT_LEVEL,
    //     FinalResultEnum.REJECTION_INFY_LEVEL,
    //   ].includes(selectedFinalAction)
    // ) {
    //   // if final result is selected/rejected
    //   Object.keys(SubStatusEnum).forEach((key) => {
    //     this.substatusDisableReason[
    //       key
    //     ] = `Substatus cannot be updated as candidate is ${FinalResultUI[selectedFinalAction]}`;
    //   });
    //   return true;
    // }

    switch (selectedSubStatus) {
      case SubStatusEnum.TECHNICAL_PENDING:
        // do not disable any substatus
        return false;

      case SubStatusEnum.TECHNICAL_COMPLETED:
        Object.keys(SubStatusEnum).forEach((key) => {
          this.substatusDisableReason[
            key
          ] = `Substatus cannot be updated because currently selected substatus (${SubStatusUI[selectedSubStatus]}) is considered as final`;
        });
        // disable all
        return true;

      case SubStatusEnum.HR_PENDING:
        if (
          [
            SubStatusEnum.TECHNICAL_PENDING,
            SubStatusEnum.TECHNICAL_COMPLETED,
          ].includes(substatus)
        ) {
          const substatusDisableReason =
            'cannot be selected once HR PENDING is selected as substatus';
          this.substatusDisableReason[SubStatusEnum.TECHNICAL_PENDING] = `${
            SubStatusUI[SubStatusEnum.TECHNICAL_PENDING]
          } ${substatusDisableReason}`;
          this.substatusDisableReason[SubStatusEnum.TECHNICAL_COMPLETED] = `${
            SubStatusUI[SubStatusEnum.TECHNICAL_COMPLETED]
          } ${substatusDisableReason}`;
          return true;
        }
        return false;

      case SubStatusEnum.HR_COMPLETED:
        Object.keys(SubStatusEnum).forEach((key) => {
          this.substatusDisableReason[
            key
          ] = `Substatus cannot be updated because currently selected substatus ${SubStatusUI[selectedSubStatus]} is considered as final`;
        });
        return true;

      default:
        return false;
    }
  };

  isFresherAndRejected = () => {
    if (this.evaluationData && this.evaluationData.length > 0) {
      const latestInterviewFeedback =
        this.evaluationData[this.evaluationData.length - 1];
      if (
        latestInterviewFeedback.interview.jobOpening.jobType !== 'OTHERS' &&
        latestInterviewFeedback.interview.jobOpening.eventType === 'FRESHER' &&
        latestInterviewFeedback.result === 'REJECTED'
      ) {
        return true;
      }
    }
    return false;
  };

  isFinalActionDisabled = (finalAction: FinalResultEnum) => {
    const selectedFinalAction = this.interviewData?.result;
    const selectedSubStatus = this.interviewData?.subStatus;
    const selectRejectFinalStatuses = [
      FinalResultEnum.SELECTED,
      FinalResultEnum.REJECTED,
      FinalResultEnum.REJECTION_UNIT_LEVEL,
      FinalResultEnum.REJECTION_INFY_LEVEL,
    ];

    if (
      !this.accountService.hasFeatureAccess(
        FeatureName.CLOSE_CANDIDATURE,
        PermissionType.CREATE
      )
    ) {
      Object.keys(FinalResultEnum).forEach((key) => {
        this.finalStatusDisableReason[key] =
          'You do not have access to this feature';
      });
      return true;
    }

    if (this.eventStatus === 'CLOSED') {
      Object.keys(FinalResultEnum).forEach((key) => {
        this.finalStatusDisableReason[key] =
          'No action can be taken as event is CLOSED';
      });
      return true;
    }

    // disable select final action button if candidate is fresher and is rejected in recent interview
    if (
      finalAction === FinalResultEnum.SELECTED &&
      this.isFresherAndRejected()
    ) {
      this.finalStatusDisableReason[FinalResultEnum.SELECTED] =
        'Candidate is not eligible to be selected as they were rejected by the panel in the recent interview';
      return true;
    }

    if (selectRejectFinalStatuses.includes(finalAction)) {
      // if substatus is TECHNICAL_PENDING or HR_PENDING, disable select/reject final action
      if (
        this.candidateSource === 'INTAP' &&
        [SubStatusEnum.TECHNICAL_PENDING, SubStatusEnum.HR_PENDING].includes(
          selectedSubStatus
        )
      ) {
        selectRejectFinalStatuses.forEach((key) => {
          this.finalStatusDisableReason[
            key
          ] = `Candidate cannot be ${FinalResultUI[key]} as the sub-status is ${SubStatusUI[selectedSubStatus]}`;
        });
        return true;
      }

      // disable select/reject if any interview is sent back
      for (let isSentBack of this.sendBacktoPanelistStatus.values()) {
        if (isSentBack) {
          selectRejectFinalStatuses.forEach((key) => {
            this.finalStatusDisableReason[
              key
            ] = `Candidate cannot be ${FinalResultUI[key]} as one or more interviews are in SENT FOR PANELIST REVIEW status.`;
          });
          return true;
        }
      }
    }

    // allow change in final status as long as user is on same page after making change in final status
    if (this.isUserOnSamePage) return false;

    // If selected final result is SELECTED/REJECTED, don't allow change in final status
    if (selectRejectFinalStatuses.includes(selectedFinalAction)) {
      Object.keys(FinalResultEnum).forEach((key) => {
        this.finalStatusDisableReason[
          key
        ] = `Final result cannot be changed once candidate is ${FinalResultUI[selectedFinalAction]}`;
      });
      return true;
    }
    return false;
  };

  getDocuments = () => {
    this.candidateService
      .getCandidateById(this.candidateId)
      .subscribe(async (res) => {
        this.candidateData = res;
        //experiment
        this.candidateSource = res.candidateSource;
        this.nodes[0] = {
          id: 'candidate-id',
          label: this.candidateData.id,
          type: 'data',
        };
        this.data['candidate-id'] = {
          keys: ['Candidate Id', 'Name', 'Phone Number', 'Email'],
          'Candidate Id': this.candidateData.id,
          Name: this.candidateData.name,
          'Phone Number': this.candidateData.phone,
          Email: this.candidateData.email,
        };
        this.graphUpdate();
        let files = this.candidateData?.candidateFileMappings?.filter(
          (file) => file['fileCategory'] === 'PROFILE_PIC'
        );

        if (files?.length > 0 && files[0]?.fileId) {
          let profileData = await this.fileService
            .getFile(files[0]?.fileId)
            .toPromise();

          this.profileImage = {
            url: this.sanitizer.bypassSecurityTrustUrl(
              window.URL.createObjectURL(profileData.body)
            ),
            blob: profileData.body,
          };

          this.data['candidate-id']['imgTitle'] = 'Profile Picture';
          this.data['candidate-id']['imgArr'] = [this.profileImage.url];
        }

        //resume
        files = this.candidateData?.candidateFileMappings?.filter(
          (file) => file['fileCategory'] === 'RESUME'
        );
        if (files?.length > 0 && files[0]?.fileId) {
          this.fileService.getFile(files[0]?.fileId).subscribe((res: any) => {
            let temp = res.headers
              .get('content-disposition')
              .split('filename=')[1]
              .split(';')[0];
            let temp1 = temp.substring(1, temp.length - 1);
            this.resumeFileName = temp1;
            let blob: any = new Blob([res.body], {
              type: 'application/octet-stream; charset=utf-8',
            });
            this.resumeBlob = blob;
          });
        } else {
          //experiment
          // console.log("resume not found");
        }

        //applicationForm
        files = this.candidateData?.candidateFileMappings?.filter(
          (file) => file['fileCategory'] === 'CANDIDATE_APPLICATION_FORM'
        );
        if (files?.length > 0 && files[0]?.fileId) {
          this.fileService.getFile(files[0]?.fileId).subscribe((res: any) => {
            let temp = res.headers
              .get('content-disposition')
              .split('filename=')[1]
              .split(';')[0];
            let temp1 = temp.substring(1, temp.length - 1);
            this.applicationFormFileName = temp1;
            let blob: any = new Blob([res.body], {
              type: 'application/octet-stream; charset=utf-8',
            });
            this.applicationFormBlob = blob;
          });
        }

        files = this.candidateData?.candidateFileMappings?.filter(
          (file) => file['fileCategory'] === 'IAP_ASSESSMENT_SNAP'
        );
        if (files?.length > 0 && files[0]?.fileId) {
          let iapAssessmentData = await this.fileService
            .getFile(files[0]?.fileId)
            .toPromise();

          this.iapAssessmentImage = {
            url: this.sanitizer.bypassSecurityTrustUrl(
              window.URL.createObjectURL(iapAssessmentData.body)
            ),
            blob: iapAssessmentData.body,
          };
        }
        this.interviewRoundsService
          .getCandidateRound(this.interviewId)
          .subscribe(async (res: any) => {
            await this.setFaceRecognitionData();

            this.interviewData = res;
            this.isDeclarationAccepted =
              this.interviewData?.recruiterDeclaration;
            //experiment
            if (this.candidateSource == 'INTAP') {
              this.applicationId =
                res.candidateApplicationDetails?.applicationId;
              this.subStatus = this.interviewData?.subStatus
                ? this.interviewData?.subStatus
                : null;
              console.log(this.subStatus);
            }
            this.jobRoleSelectedFor = this.interviewData.roleSelectedFor
              ? this.interviewData.roleSelectedFor
              : parseInt(this.jobRoleId);

            this.setRemarksState(this.interviewData?.recruiterComments);

            if (
              this.interviewData?.result === 'SELECTED_FOR_ANOTHER_JOB' ||
              this.interviewData?.result === 'PENDING' ||
              this.interviewData?.result === 'REFER_FOR_POST'
            ) {
              this.recruiterResult = this.interviewData?.result;
            }

            if (this.interviewData?.result === 'SELECTED_FOR_ANOTHER_JOB') {
              this.selectedForAnotherJob = true;
            }
            if (this.interviewData?.result) {
              this.links.push({
                id: 'link-hr-review',
                source: this.nodes[this.nodes.length - 1].id,
                target: 'hr-review',
                label: 'Review',
                type: 'arrow',
              });
              this.nodes.push({
                id: 'hr-review',
                label: 'HR Review',
                type: 'data',
              });

              this.data['hr-review'] = {
                keys: ['Comments', 'Result'],
                Result: this.interviewData?.result,
                Comments: this.interviewData?.recruiterComments,
              };
            }
            this.feedbackService
              .getNoShowData(this.interviewId)
              .subscribe((res: any) => {
                let noShowData = res;
                if (noShowData.length > 0) {
                  this.jobOpening = noShowData[0].jobOpening.eventType;
                }

                noShowData.forEach((ele) => {
                  this.nodes.push({
                    id: ele.id.toString() + 'noShow',
                    label: ele.title,
                    type: 'data',
                    startTime: ele.interviewSchedule.startDate,
                  });
                  this.links.push({
                    id: 'link' + ele.id.toString() + 'noShow',
                    source: 'candidate-id',
                    target: ele.id.toString() + 'noShow',
                    label: 'Interview',
                    type: 'arrow',
                  });
                  this.data[ele.id.toString() + 'noShow'] = {
                    keys: ['Result'],
                    Result: 'No Show',
                  };
                });
                this.graphUpdate();
                this.loading = false;
              });
          });
      });
  };

  markInterviewAsNoShow = (interviewId) => {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        text: "Are you sure you want to mark this Interview as 'No Show'?<br/> On <strong class='font-red'>Yes</strong>, the profile will be auto-submitted.",
      },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Yes') {
        this.loading = true;
        this.candidateService.getNoShowCandidateDetails(interviewId).subscribe(
          (res) => {
            const noShowInterviewIndex = this.upcomingInterviews?.findIndex(
              (_) => _.id == interviewId
            );
            if (noShowInterviewIndex !== -1) {
              const noShowInterview = this.upcomingInterviews?.splice(
                noShowInterviewIndex,
                1
              );
              if (noShowInterview?.length > 0)
                this.noShowInterviews?.push({
                  ...noShowInterview[0],
                  interviewStatus: InterviewStatus.NO_SHOW,
                });
            }

            this.sharedService.openSnackBar(
              'Interview Marked as No Show',
              '',
              3000
            );
            this.loading = false;
          },
          (err) => {
            this.sharedService.openSnackBar(
              'Some error occured. Please try again',
              '',
              3000
            );
            this.loading = false;
          }
        );
      }
    });
  };

  setRemarksState = (remarks: string) => {
    this.rejectionReasonSelectedByRecruiter = '';
    const rejectionReasonIndex = remarks?.indexOf(
      this.rejectionReasonTextToAppendInRemarks
    );
    if (rejectionReasonIndex && rejectionReasonIndex > 0) {
      this.remarks = remarks.substring(0, rejectionReasonIndex);
      this.rejectionReasonSelectedByRecruiter = remarks
        .substring(rejectionReasonIndex)
        ?.split(this.rejectionReasonTextToAppendInRemarks)[1];
    } else {
      this.remarks = remarks;
    }
  };

  getAllInterviews = (id) => {
    this.interviewRoundsService.getCandidateInterviewRounds(id).subscribe(
      (res) => {
        res.content.forEach((interview) => {
          if (interview.interviewStatus === 'SENT_FOR_PANELLIST_REVIEW') {
            this.sendBacktoPanelistStatus.set(interview.id, true);
            this.sendBackToPanelistRemark.set(interview.id, interview.remarks);
          } else {
            this.sendBacktoPanelistStatus.set(interview.id, false);
          }
          if (
            interview.interviewStatus === 'UPCOMING' ||
            interview.interviewStatus === 'FEEDBACK_PENDING'
          ) {
            this.upcomingInterviews.push(interview);
          } else if (interview.interviewStatus === 'NO_SHOW') {
            this.noShowInterviews.push(interview);
          }
        });
      },
      () => {}
    );
  };
  graphUpdate = () => {
    this.update$.next(true);
  };
  panelistReview = (event, interviewId) => {
    if (interviewId && this.sendBackToPanelistRemark.get(interviewId)) {
      const dialogRef = this.dialog.open(ConfirmationComponent, {
        data: {
          text: 'Are you sure you want to send back to panelist for review?',
        },
        backdropClass: 'backdrop-background',
        autoFocus: false,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'Yes') {
          this.loading = true;
          this.recruiterService
            .sendForPanelistReview(
              interviewId,
              this.sendBackToPanelistRemark.get(interviewId)
            )
            .subscribe(
              (res) => {
                this.sendBacktoPanelistStatus.set(interviewId, true);
                this.loading = false;
                this.sharedService.openSnackBar(
                  'Sent Back Successfully',
                  '',
                  3000
                );
              },
              () => {
                this.loading = false;
                this.sharedService.openSnackBar('Sent Back Failed', '', 3000);
              }
            );
        }
      });
    } else {
      this.sharedService.openSnackBar(
        'Please give remarks before sending back to panelist',
        '',
        3000
      );
    }
  };
  saveImage = (img) => {
    fileSaver.saveAs(img.blob, 'Image.jpg');
  };

  openResume = () => {
    if (
      this.resumeFileName.split('.')[
        this.resumeFileName.split('.').length - 1
      ] === 'pdf'
    ) {
      const blobFile = new File([this.resumeBlob], 'resume.pdf', {
        type: 'application/pdf',
      });
      let fileURL = URL.createObjectURL(blobFile);
      window.open(fileURL);
    } else {
      fileSaver.saveAs(this.resumeBlob, this.resumeFileName);
    }
  };

  openApplicationForm = () => {
    if (
      this.applicationFormFileName.split('.')[
        this.applicationFormFileName.split('.').length - 1
      ] === 'pdf'
    ) {
      const blobFile = new File(
        [this.applicationFormBlob],
        'applicationform.pdf',
        {
          type: 'application/pdf',
        }
      );
      let fileURL = URL.createObjectURL(blobFile);
      window.open(fileURL);
    } else {
      fileSaver.saveAs(this.applicationFormBlob, this.applicationFormFileName);
    }
  };
  faceValidationColor() {
    if (this.faceValid) {
      return { 'background-color': '#197832' };
    } else {
      return { 'background-color': 'red' };
    }
  }

  pullResumeFromIntap = () => {
    this.disableButton = true;
    if (this.applicationId) {
      this.candidateService
        .transferResumeFromIntap(this.applicationId, this.candidateId)
        .subscribe((res: any) => {});

      this.sharedService.openSnackBar(
        'Please refresh the page in some time to view the resume',
        '',
        4000
      );
    } else {
      this.sharedService.openSnackBar(
        "Application Id not present, resume couldn't be fetched",
        '',
        3000
      );
    }
  };
  setData = async (data) => {
    let tempData: any = {};
    var index: number = 0;

    for (let objectOrder of data) {
      this.links.push({
        id: 'link' + objectOrder.id.toString(),
        source: this.nodes[this.nodes.length - 1].id,
        target: objectOrder.id.toString(),
        label: 'Interview',
        type: 'arrow',
      });
      this.nodes.push({
        id: objectOrder.id.toString(),
        label: objectOrder.interview.title,
        type: 'data',
        startTime: objectOrder.interview.interviewSchedule.startDate,
      });
      this.data[objectOrder.id.toString()] = {
        keys: ['Panelist', 'Comments', 'Result'],
        Panelist: objectOrder.interviewerName,
        Comments: objectOrder.comments,
        Result: objectOrder.result,
      };
      try {
        this.questionsData[index] = await this.sharedService
          .getData(objectOrder.jobtype)
          .toPromise();
      } catch (e) {}
      let form = {};
      let evaluationForm = {};
      if (this.questionsData[index]) {
        this.questionsData[index].forEach((data) => {
          data.questions.forEach((question) => {
            form[data.category + ' ' + question.questionNumber] = ['', []];
          });
        });
      }
      this.ppValues = [];
      objectOrder.ratings.forEach((rating) => {
        tempData.criteria = rating.title;
        tempData.rating = rating.rating;
        tempData.remark = rating.remarks;
        tempData.maxRating = rating.maxRating;
        tempData.criteriaType = rating.criteriaType;
        tempData.ratingRemarks = rating.ratingRemarks;
        form[rating.title + ' Score'] = [rating.rating, []];

        this.ELEMENT_DATA.push(tempData);
        tempData = <any>{};
        if (
          rating.title.trim() === 'Suggested level of candidate' ||
          rating.title.trim() === 'PP Training Waive off'
        ) {
          this.ppValues.push(rating);
        }

        if (rating.criteriaType === 'ID_VERIFICATION') {
          this.photoVerificationValue.push(rating.remarks);
        }
        if (
          (objectOrder.jobtype === 'SE' ||
            objectOrder.jobtype === 'PEGA' ||
            objectOrder.jobtype === 'NEG' ||
            objectOrder.jobtype === 'SE_CAMPUS' ||
            objectOrder.jobtype === 'OE_CAMPUS') &&
          rating.ratingRemarks?.length > 0
        ) {
          if (rating.title !== 'MISCELLANEOUS COMMENTS') {
            this.data[objectOrder.id.toString()].keys.push(rating.title);
            this.data[objectOrder.id.toString()][rating.title] = rating.rating;
          }
        }
        if (
          (objectOrder.jobtype === 'SE' ||
            objectOrder.jobtype === 'PEGA' ||
            objectOrder.jobtype === 'NEG' ||
            objectOrder.jobtype === 'SE_CAMPUS' ||
            objectOrder.jobtype === 'OE_CAMPUS') &&
          rating.criteriaType === 'POS_NGT' &&
          rating.ratingRemarks?.length > 0
        ) {
          rating.ratingRemarks.forEach((rate) => {
            form[rate.title] = [[rate.value], []];
          });
        } else {
          evaluationForm[rating.title] = rating.remarks;
          evaluationForm[rating.title + ' Score'] = rating.rating;
          evaluationForm[rating.title + ' maxScore'] = rating.maxRating;

          if (rating.rating) {
            this.data[objectOrder.id.toString()].keys.push(rating.title);
            this.data[objectOrder.id.toString()][rating.title] = rating.rating;
          }
        }
        // }
      });
      this.customizableForm.push(this.formBuilder.group(form));
      this.evaluationDataForm.push(evaluationForm);

      this.dataSource.push(this.ELEMENT_DATA);
      this.ELEMENT_DATA = [];

      index = index + 1;
    }
  };

  handleDeclarationChange = (e) => {
    this.isDeclarationAccepted = e.checked;
  };

  getFinalActionSuccessMessage = (finalAction: FinalResultEnum) => {
    switch (finalAction) {
      case 'SELECTED':
        return 'Selected Successfully';
      case 'REJECTED':
        return 'Rejected Successfully';
      case 'TO_BE_REINTERVIEWED':
        return 'To be Reinterviewed';
      case 'ON_HOLD':
        return 'On Hold Successfully';
      case 'NO_SHOW':
        return 'Updated Successfully';
      case 'REVIEWED':
        return 'Reviewed Successfully';
      default:
        return 'Updated Successfully';
    }
  };

  handleFinalActionClick = (
    finalAction: FinalResultEnum,
    rejectionReason?: string
  ) => {
    if (!this.remarks) {
      this.sharedService.openSnackBar('Please give remarks', 'OK');
      return;
    }
    // job role is selected on final action except 'REVIEWED'
    if (finalAction !== 'REVIEWED' && this.jobRoleSelectedFor === '') {
      this.sharedService.openSnackBar(
        'Please select a job from the dropdown for which candidate feedback is being provided',
        '',
        3000
      );
      return;
    }

    if (
      finalAction !== 'REVIEWED' &&
      this.candidateSource == 'INTAP' &&
      !this.subStatus
    ) {
      this.sharedService.openSnackBar('Please select a Substatus', '', 3000);
      return;
    }

    if (!this.isDeclarationAccepted) {
      this.sharedService.openSnackBar(
        'Please accept the declaration before proceeding',
        '',
        3000
      );
      return;
    }

    this.loading = true;
    let sendFeedback = this.createFeedbackObj(finalAction, rejectionReason);

    this.feedbackService.sendRecruiterFeedback(sendFeedback).subscribe(
      (response) => {
        // TODO: check if sendRecruiterFeedback api can return the same result as getCandidateRound
        this.interviewRoundsService
          .getCandidateRound(this.interviewId)
          .subscribe({
            next: (res: any) => {
              this.interviewData = res;
              this.setRemarksState(this.interviewData.recruiterComments);
              this.loading = false;
            },
            error: (err) => {
              this.loading = false;
            },
          });
        // this.loading = false;
        this.isUserOnSamePage = true;
        this.sharedService.openSnackBar(
          this.getFinalActionSuccessMessage(finalAction),
          'OK'
        );
        // this._location.back();
      },
      (error) => {
        this.loading = false;
        this.sharedService.openSnackBar(
          'Please try again, some error occured',
          'OK'
        );
      }
    );
  };

  confirmSelectOrRejectDialog = (result: FinalResultEnum) => {
    let jobForFinalAction = this.jobDropDown.find(
      (job) => job.id === this.jobRoleSelectedFor
    );
    const resultText = result === 'SELECTED' ? 'select' : 'reject';
    let message = `Are you sure you want to <strong class='font-red'>${resultText}</strong> this candidate?`;
    if (jobForFinalAction) {
      message = `Are you sure you want to <strong class='font-red'>${resultText}</strong> this candidate for job <strong>${jobForFinalAction.title}</strong>?`;
    }
    const dialog = this.dialog.open(FinalActionConfirmationDialogComponent, {
      data: {
        jobForFinalAction: jobForFinalAction,
        finalResult: result,
        rejectionReasonSelectedByRecruiter:
          this.rejectionReasonSelectedByRecruiter,
      },
      backdropClass: 'backdrop-background',
      autoFocus: false,
      minWidth: 500,
    });

    dialog
      .afterClosed()
      .subscribe(
        (response: { takeAction: boolean; selectedReason: string }) => {
          if (response.takeAction) {
            this.handleFinalActionClick(result, response.selectedReason);
          }
        }
      );
  };

  markSubStatus = (substatus: SubStatusEnum) => {
    if (this.remarks) {
      // show confirmation dialog for all substatus except tech pending
      if (substatus !== SubStatusEnum.TECHNICAL_PENDING) {
        const selectedSubstatus = this.subStatusArray.find(
          (_) => _.value === substatus
        );
        const dialogRef = this.dialog.open(ConfirmationComponent, {
          data: {
            text: `Are you sure you want to mark the sub-status as <strong>${selectedSubstatus?.label}</strong>?<br />
            <p class="DSA_wb_caption">Please note: ${selectedSubstatus?.alertMessage}</p>`,
          },
          backdropClass: 'backdrop-background',
          autoFocus: false,
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'Yes') {
            this.saveSubstatusInDB(substatus);
          }
        });
      } else {
        this.saveSubstatusInDB(substatus);
      }
    } else {
      if (!this.remarks) {
        this.sharedService.openSnackBar('Please give remarks', 'OK');
      }
    }
  };

  saveSubstatusInDB = (substatus: SubStatusEnum) => {
    this.loading = true;
    this.subStatus = substatus;
    let sendFeedback = this.createSubstatusObj(substatus);

    this.feedbackService.sendRecruiterFeedback(sendFeedback).subscribe(
      (response: any) => {
        this.interviewRoundsService
          .getCandidateRound(this.interviewId)
          .subscribe(
            async (res: any) => {
              this.interviewData = res;
              this.loading = false;
              //experiment
            },
            (err) => {
              this.loading = false;
            }
          );

        this.sharedService.openSnackBar('Substatus marked Successfully', 'OK');
      },
      (error) => {
        this.loading = false;
        this.sharedService.openSnackBar(
          'Please try again, some error occured',
          'OK'
        );
      }
    );
  };

  createSubstatusObj = (value) => {
    // if (this.jobRoleSelectedFor !== '') {
    let sendFeedback = <any>{};
    sendFeedback.id = this.interviewId;
    sendFeedback.name = this.evaluationData[0]?.interviewerName
      ? this.evaluationData[0]?.interviewerName
      : null;
    sendFeedback.description = null;
    sendFeedback.category = null;
    sendFeedback.candidateId = this.interviewData?.candidateId;
    sendFeedback.recruiterComments = this.remarks;
    sendFeedback.subStatus = value ? value : null;
    sendFeedback.result = null;
    sendFeedback.jobOpeningId = this.interviewData?.jobOpeningId;
    sendFeedback.roleSelectedFor = this.jobRoleSelectedFor;
    return sendFeedback;
    // } else {
    //   this.sharedService.openSnackBar(
    //     'Please select a job from the dropdown for which candidate feedback is being provided',
    //     '',
    //     3000
    //   );
    // }
  };

  createFeedbackObj = (result: FinalResultEnum, rejectionReason?: string) => {
    // if (this.jobRoleSelectedFor !== '') {
    let sendFeedback = <any>{};
    sendFeedback.id = this.interviewId;
    sendFeedback.name = this.evaluationData[0]?.interviewerName
      ? this.evaluationData[0]?.interviewerName
      : null;
    sendFeedback.description = null;
    sendFeedback.category = null;
    sendFeedback.candidateId = this.interviewData?.candidateId;
    sendFeedback.recruiterComments =
      ['REJECTION_INFY_LEVEL', 'REJECTION_UNIT_LEVEL'].includes(result) &&
      rejectionReason
        ? `${this.remarks}${this.rejectionReasonTextToAppendInRemarks}${rejectionReason}`
        : this.remarks;
    if (this.candidateSource == 'INTAP') {
      sendFeedback.subStatus = this.subStatus ? this.subStatus : null;
    }
    sendFeedback.result = result;
    sendFeedback.jobOpeningId = this.interviewData?.jobOpeningId;
    sendFeedback.roleSelectedFor = this.jobRoleSelectedFor;
    sendFeedback.recruiterDeclaration = this.isDeclarationAccepted;
    return sendFeedback;
    // } else {
    //   this.sharedService.openSnackBar(
    //     'Please select a job from the dropdown for which candidate feedback is being provided',
    //     '',
    //     3000
    //   );
    // }
  };

  getAllEvaluations(id: string, index) {
    this.evaluationService.getAllEvaluations(id).subscribe((response) => {
      this.setEvaluationValue(response, index);
    });
  }

  setEvaluationValue(responseList: any, index) {
    this.evaluationDetailList[index] = [];
    let tempData = <any>{};
    for (let response of responseList) {
      tempData.description = response.description;
      tempData.id = response.id;
      tempData.jobOpeningId = response.jobOpeningId;
      tempData.name = response.name;
      tempData.weightage = response.weightage;
      tempData.remark = response.remark;
      tempData.rating = Number(response.rating);
      this.evaluationDetailList[index].push(tempData);
      tempData = <any>{};
    }
  }

  changeDropDownValue() {}

  radioChange(e, index) {
    this.recruiterFaceMatchingFeedback[index].recruiterFeedback = e.value;
  }

  submitFaceRecognitionFeedback() {
    this.faceRecognitionService
      .setFaceRecognitionFeedback(this.recruiterFaceMatchingFeedback)
      .subscribe(() => {
        this.sharedService.openSnackBar(
          'Feedback Saved Successfully',
          '',
          3000
        );
      });
  }

  setFaceRecognitionData = async () => {
    this.faceData = await this.faceRecognitionService
      .getFaceRecognitionData(this.interviewId)
      .toPromise();

    if (
      this.faceData?.profilePicFileId &&
      this.faceData?.faceValidationReports?.length > 0
    ) {
      this.showFaceMatching = true;
      this.profilePicAvailable = true;

      let profileUrl =
        this.profileImage.url.changingThisBreaksApplicationSecurity;
      this.faceRecognitionNodes.push({
        id: 'profile-picture',
        label: 'Profile Picture',
        imgUrl: profileUrl,
        type: 'pic',
      });
      for (let matchData of this.faceData?.faceValidationReports) {
        if (matchData.valType === 'PROFILE_TO_INTERVIEW_SNAP') {
          let faceRecognitionData = {
            id: matchData.id.toString(),
            label: matchData.interviewName,
            type: 'pic',
            recruiterFeedback: matchData.recruiterFeedback,
          };
          let faceRecognitionLinks = {
            id: 'link' + matchData.id.toString(),
            source: 'profile-picture',
            target: matchData.id.toString(),
            label: matchData.matchPercentage.toString().substring(0, 4) + '%',
            type: 'no-arrow',
          };
          this.recruiterFaceMatchingFeedback.push({
            id: matchData.id,
            recruiterFeedback: matchData.recruiterFeedback,
            comparisonType: matchData.valType,
            interviewTitle: matchData.interviewName,
          });
          if (
            matchData.status === 'MATCH' &&
            matchData.cropTargetFileId !== 'NA'
          ) {
            this.faceValid = true;
            faceRecognitionData['imgUrl'] = window.URL.createObjectURL(
              (
                await this.fileService
                  .getFile(matchData.cropTargetFileId)
                  .toPromise()
              ).body
            );
            faceRecognitionLinks['color'] = 'green';
          } else if (
            matchData.status === 'NO_MATCH' &&
            matchData.valFieldId !== 'NA'
          ) {
            this.faceValid = false;

            faceRecognitionData['imgUrl'] = window.URL.createObjectURL(
              (await this.fileService.getFile(matchData.valFieldId).toPromise())
                .body
            );
            faceRecognitionLinks['color'] = 'red';
          }
          this.faceRecognitionNodes.push(faceRecognitionData);
          this.faceRecognitionLinks.push(faceRecognitionLinks);
        }
      }

      // if ASSESSMENT SNAP is present
      if (this.faceData?.iapAssessmentSnapFileId) {
        this.assessmentPicAvailable = true;
        let iapPic =
          this.iapAssessmentImage.url.changingThisBreaksApplicationSecurity;
        this.faceRecognitionNodes.push({
          id: 'iap-picture',
          label: 'Assessment Picture',
          imgUrl: iapPic,
          type: 'pic',
        });
        for (let matchData of this.faceData?.faceValidationReports) {
          if (matchData.valType === 'IAP_ASSESSMENT_SNAP_TO_INTERVIEW_SNAP') {
            let faceRecognitionData = {
              id: matchData.id.toString(),
              label: matchData.interviewName,
              type: 'pic',
              recruiterFeedback: matchData.recruiterFeedback,
            };
            let faceRecognitionLinks = {
              id: 'link' + matchData.id.toString(),
              source: 'iap-picture',
              target: matchData.id.toString(),
              label: matchData.matchPercentage.toString().substring(0, 4) + '%',
              type: 'no-arrow',
            };
            this.recruiterFaceMatchingFeedback.push({
              id: matchData.id,
              recruiterFeedback: matchData.recruiterFeedback,
              comparisonType: matchData.valType,
              interviewTitle: matchData.interviewName,
            });
            if (
              matchData.status === 'MATCH' &&
              matchData.cropTargetFileId !== 'NA'
            ) {
              this.faceValid = true;
              faceRecognitionData['imgUrl'] = window.URL.createObjectURL(
                (
                  await this.fileService
                    .getFile(matchData.cropTargetFileId)
                    .toPromise()
                ).body
              );
              faceRecognitionLinks['color'] = 'green';
            } else if (
              matchData.status === 'NO_MATCH' &&
              matchData.valFieldId !== 'NA'
            ) {
              this.faceValid = false;
              faceRecognitionData['imgUrl'] = window.URL.createObjectURL(
                (
                  await this.fileService
                    .getFile(matchData.valFieldId)
                    .toPromise()
                ).body
              );
              faceRecognitionLinks['color'] = 'red';
            }
            this.faceRecognitionNodes.push(faceRecognitionData);
            this.faceRecognitionLinks.push(faceRecognitionLinks);
          }
        }
      }
    } else {
      this.faceValid = false;
    }
  };
}
