import { IFeature } from './feature.model';

export interface IAuthority {
    authority? : string,
    features? : IFeature[]
}

export class Authority implements IAuthority {
  constructor(
  ) {}

  authority? : string;
  features? : IFeature[];

  setAuthorityName = (authority) =>{
    this.authority = authority;
  }

  setFeatures = (features : IFeature[]) => {
    this.features = features;
  }

}
