import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CandidateInfoService } from '../../services/candidate-info.service';
import { Location } from '@angular/common';
import { SharedService } from 'src/app/services/shared.service';
import { ResumeUploadService } from '../../services/resume-upload.service';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-view-application-details',
  templateUrl: './view-application-details.component.html',
  styleUrls: ['./view-application-details.component.scss'],
})
export class ViewApplicationDetailsComponent implements OnInit {
  job;
  showDetailsPage: number = 0;
  uploadFiles = [];
  uploadFilesLOR = [];
  uploadFilesSOP = [];
  expandProfileAccordion = true;
  expandDocsAccordion = true;
  uploadButton = false;
  jobId: any;
  uploadDocCount = 0;
  uploadFailedDocuments = [];
  applicationId;
  files: any;
  docBlob;
  resumeData;
  sopData;
  lorData;
  uploadDisable = false;

  @ViewChild('secondDialog', { static: true }) secondDialog: TemplateRef<any>;

  constructor(
    private fb: FormBuilder,
    private service: CandidateInfoService,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public _location: Location,
    private sharedService: SharedService,
    private fileService: ResumeUploadService //private fileService: FileService,
  ) {}

  ngOnInit(): void {
    this.spinnerService.show();
    let seekerId = this.route.snapshot.paramMap.get('applicantId');
    let jobId = this.route.snapshot.paramMap.get('jobid');
    // this.applicationId=jobId;
    this.service.getApplicationOfJob(seekerId, jobId).subscribe((response) => {//
      const obj: any = response;
      if (obj) {
        this.showDetailsPage = 1;
        this.job = obj; //[0];
        this.applicationId = obj.id;
        this.getJobFiles();
        var now = new Date();
        var exp_date = new Date(obj?.opportunity.endDate);
        if (now > exp_date) this.uploadDisable = true;
        //console.log(obj);
      } else {
        this.showDetailsPage = 2; //not working, tried using this in ui
      }
      this.spinnerService.hide();
    });
  }
  uploadUserFiles() {
    if (
      this.uploadFiles.length +
        this.uploadFilesSOP.length +
        this.uploadFilesLOR.length ==
      0
    ) {
      this.sharedService.openErrorSnackBar(
        'Please select a file to upload',
        ''
      );
      return;
    }
    this.showDetailsPage = 0;
    if (this.uploadFiles.length > 0) {
      this.uploadDocCount++;
      this.uploadSingleFile(this.uploadFiles, 'RP_RESUME_OTHERS');
    }
    if (this.uploadFilesSOP.length > 0) {
      this.uploadDocCount++;
      this.uploadSingleFile(this.uploadFilesSOP, 'RP_SOP');
    }
    if (this.uploadFilesLOR.length > 0) {
      this.uploadDocCount++;
      this.uploadSingleFile(this.uploadFilesLOR, 'RP_RECOMMENDATION_LETTER');
    }
  }

  uploadSingleFile = (fileToUpload: any[], docType: string) => {
    const specData = {
      jobApplicationId: this.applicationId,
      source: 'CANDIDATE',
    };

    let formData = new FormData();
    formData.append('specData', JSON.stringify(specData));
    fileToUpload.forEach((file) => {
      formData.append('file', file);
    });

    this.fileService.upload(formData, docType).subscribe({
      next: (res: any) => {},
      error: (err: any) => {
        this.uploadFailedDocuments.push(docType);//
      },
      complete: () => {
        this.uploadDocCount--;
        if (this.uploadDocCount == 0) {
          this.showDetailsPage = 1;
          if (!this.uploadFailedDocuments.includes('RP_SOP'))
            this.uploadFilesSOP = [];
          if (!this.uploadFailedDocuments.includes('RP_RECOMMENDATION_LETTER'))
            this.uploadFilesLOR = [];
          if (!this.uploadFailedDocuments.includes('RP_RESUME_OTHERS'))
            this.uploadFiles = [];

          if (this.uploadFailedDocuments.length == 0) {
            this.sharedService.openSuccessSnackBar(
              'Successfully uploaded all the files!',
              ''
            );
          } else {
            let msg = this.uploadFailedDocuments
              .map((value) => value.substring(3))
              .join(', ');
            this.sharedService.openErrorSnackBar(
              `${msg} upload failed, Please try again!`,
              ''
            );
          }
        }
      },
    });
  };

  /** UTILITY FUNCTIONS **/
  handleFileChange = (files: Array<File>) => {
    this.uploadFiles = files;
  };

  handleFileChangeLOR = (files: Array<File>) => {
    this.uploadFilesLOR = files;
  };

  handleFileChangeSOP = (files: Array<File>) => {
    this.uploadFilesSOP = files;
  };

  cancelApplication() {
    // console.log("Confirmation");
    this.spinnerService.show();
    this.service.cancelApplications(this.jobId).subscribe({
      next: (res) => {
        this.spinnerService.hide();
        this.sharedService.openErrorSnackBar(
          'application has been successfully withdrawn',
          ''
        );
        //this.isLoading = false;
        //console.log(res);
      },
      error: (err) => {
        //this.isLoading = false;
        this.spinnerService.hide();
        this.sharedService.openErrorSnackBar(
          'Error occurred, please try again',
          ''
        );
      },
    });
  }
  cancelJob = (jobId, job) => {
    this.jobId = jobId;
    this.dialog.open(this.secondDialog);
  };

  getJobFiles() {
    this.service.getJobApplicationFiles(this.applicationId).subscribe({
      next: (res) => {
        //console.log(res);
        this.files = res;
        this.checkingFiletypes();
        // this.files = res
        //  this.checkingFiletypes();
      },
      error: (err) => {
        console.log('Error occured, Application Files not recieved');
        this.sharedService.openErrorSnackBar(
          'Something Went Wrong! Please try again!',
          ''
        );
      },
    });
  }

  checkingFiletypes() {
    for (let i = 0; i < this.files.length; i++) {
      if (
        this.files[i].category == 'RESUME' ||
        this.files[i].category == 'RP_RESUME_OTHERS'
      )
        this.resumeData = this.files[i];
      else if (this.files[i].category == 'RP_SOP') {
        this.sopData = this.files[i];
      } else if (this.files[i].category == 'RP_RECOMMENDATION_LETTER') {
        this.lorData = this.files[i];
      }
    }
  }
  // Move these changes when the app.files is working  fine.
  downloadFiles(docType: String) {
    // To do: change this based response
    let fileId = this.resumeData?.fileId;
    if (docType == 'SOP') fileId = this.sopData?.fileId;
    else if (docType == 'LOR') fileId = this.lorData?.fileId;

    if (this.files?.length > 0 && fileId) {
      this.fileService.getFile(fileId).subscribe({
        next: (res) => {
          // Getting file name for dev-env
          this.fileService.getFileDetails(fileId).subscribe({
            next: (fileDetailsRes) => {
              let resumeFileName = fileDetailsRes.body['sourceName'];
              let blob: any = new Blob([res.body], {
                type: 'application/octet-stream; charset=utf-8',
              });
              this.docBlob = blob;
              //const filename=resumeFileName.split('.')[0]; //var fileName = 'test.pdf';
              if (resumeFileName.split('.')[1] === 'pdf') {
                const blobFile = new File([this.docBlob], resumeFileName, {
                  type: 'application/pdf',
                });
                let fileURL = URL.createObjectURL(blobFile);
                window.open(fileURL);
              } else {
                fileSaver.saveAs(this.docBlob, resumeFileName);
              }
            },
            error: (err) => {
              console.log('Error occured, Unable to get the file details');
              this.sharedService.openErrorSnackBar(
                'Something Went Wrong! Please try again!',
                ''
              );
            },
          });
          // let temp = res.headers
          //   .get("content-disposition")
          //   .split("filename=")[1]
          //   .split(";")[0];

          //   if (
          //   // this.files[i].split('.')[
          //   //   this.files[i].split('.').length - 1
          //   // ] === 'pdf'
          // ) {
          //   const filename=this.files[i].split('.')[0];
          //   const blobFile = new File([this.docBlob], `${filename}.pdf`, {
          //     type: 'application/pdf',
          //   });
          //   let fileURL = URL.createObjectURL(blobFile);
          //   window.open(fileURL);
          // } else {
          //   fileSaver.saveAs(this.docBlob, this.files[i]);
          // }
        },
        error: (err) => {
          console.log('Error occured, Download failed');
          this.sharedService.openErrorSnackBar(
            'Something Went Wrong! Please try again!',
            ''
          );
        },
      });
    }
  }
}
