/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/tslint/config */
import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnChanges,
} from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { Visibility } from 'src/app/shared/model/enumerations/visibility.model';
import { FileserverService } from 'src/app/survey-master/survey/edit-backgroud-color/fileserver.service';
import { IPreference } from 'src/app/shared/model/preference.model';
import { OptionOrder } from 'src/app/shared/model/enumerations/option-order.model';
import { Theme, QuestionView } from 'src/app/shared/model/theme.model';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return control && control.invalid;
  }
}

@Component({
  selector: 'jhi-radio-buttons-preview',
  templateUrl: './radio-buttons.component.html',
  styleUrls: ['./radio-buttons.component.scss'],
})
export class RadioButtonsPreviewComponent implements OnInit, OnChanges {
  @Input()
  currPage: any;
  @Input()
  question: any;
  @Input()
  questionIndex: any;
  @Input()
  responsePage: any;
  @Input()
  section: any;
  @Input()
  preference: any;
  @Input()
  theme: Theme;

  view = 'NORMAL';
  @Input()
  disabled: Boolean = false;
  @Input()
  nextElement: HTMLElement;
  matcher = new MyErrorStateMatcher();
  options = [];

  control: FormControl;
  customGoalName = '';
  goalOption;
  includeOthers = false;

  constructor(
    public fileserverService: FileserverService,
    private detectRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.control = new FormControl('');
    if (this.theme.questionView === QuestionView.TWO_COLUMNS) {
      this.view = 'TWO_COLUMNS';
    } else {
      this.view = 'NORMAL';
    }

    this.options = [];
    this.question.type.options.forEach((val) =>
      this.options.push(Object.assign({}, val))
    );
    if (this.question.type.values.length > 0) {
      if (
        this.getValue(this.question, 'SHUFFLE_OPTIONS') === 'true' ||
        this.preference.optionOrder === OptionOrder.RANDOM
      ) {
        this.shuffle(this.options);
      }
      if (this.getValue(this.question, 'INCLUDE_OTHERS') === 'true') {
        this.includeOthers = true;
      }
    }
    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response != null &&
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response.length > 0
    ) {
      // already some response present
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response.forEach((element) => {
        if (element.includes('other::')) {
          this.goalOption = 'other';
          this.customGoalName = element.split('other::')[1];
        }
      });
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response = [];
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response.push('');
    }
  }

  ngOnChanges() {
    this.control = new FormControl('');
    if (this.theme.questionView === QuestionView.TWO_COLUMNS) {
      this.view = 'TWO_COLUMNS';
    } else {
      this.view = 'NORMAL';
    }

    this.options = [];
    this.question.type.options.forEach((val) =>
      this.options.push(Object.assign({}, val))
    );
    if (this.question.type.values.length > 0) {
      if (
        this.getValue(this.question, 'SHUFFLE_OPTIONS') === 'true' ||
        this.preference.optionOrder === OptionOrder.RANDOM
      ) {
        this.shuffle(this.options);
      }
      if (this.getValue(this.question, 'INCLUDE_OTHERS') === 'true') {
        this.includeOthers = true;
      }
    }
    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response != null &&
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response.length > 0
    ) {
      // already some response present
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response.forEach((element) => {
        if (element.includes('other::')) {
          this.goalOption = 'other';
          this.customGoalName = element.split('other::')[1];
        }
      });
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response = [];
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response.push('');
    }
  }

  shuffle(array) {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  changeOther() {
    if (this.customGoalName !== null && this.customGoalName === '') {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].isValid = false;
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].validationMessage = 'Please provide an input for other';
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].isValid = true;
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].validationMessage = '';
      if (this.nextElement != null) {
        this.nextElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        setTimeout(() => {
          if (this.nextElement.getElementsByTagName('input') != null)
            this.nextElement.getElementsByTagName('input')[0].focus();
        }, 400);
      }
    }
    this.goalOption = 'other';
    this.responsePage.sections[this.section].questions[
      this.questionIndex
    ].response[0] = 'other::' + this.customGoalName;

    this.detectRef.detectChanges();
  }

  downloadImage(path: string) {
    return this.fileserverService.getFileURLbyPath(path);
  }

  keyUpOthers(event: any) {
    // event.preventDefault();
    event.stopPropagation();
  }

  inputChanged(event: any) {
    if (event.value === undefined) {
      // undefined event
    } else if (event.value !== 'other') {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response[0] = event.value;
      this.goalOption = '';
      this.customGoalName = null;
      if (
        this.question.preference.isRequired === 1 &&
        (this.responsePage.sections[this.section].questions[this.questionIndex]
          .response[0] === '' ||
          this.responsePage.sections[this.section].questions[this.questionIndex]
            .response === null ||
          this.responsePage.sections[this.section].questions[this.questionIndex]
            .response === undefined)
      ) {
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].isValid = false;
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].validationMessage = 'This field value is required';
      } else {
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].isValid = true;
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].validationMessage = '';
        if (this.nextElement != null) {
          this.nextElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          setTimeout(() => {
            if (this.nextElement.getElementsByTagName('input') != null)
              this.nextElement.getElementsByTagName('input')[0].focus();
          }, 400);
        }
      }
    } else {
      this.goalOption = 'other';

      if (this.customGoalName === null || this.customGoalName === '') {
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].isValid = false;
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].validationMessage = 'Please provide an input for other';
      } else {
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].isValid = true;
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].validationMessage = '';
        if (this.nextElement != null) {
          this.nextElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          setTimeout(() => {
            if (this.nextElement.getElementsByTagName('input') != null)
              this.nextElement.getElementsByTagName('input')[0].focus();
          }, 400);
        }
      }
    }
    this.detectRef.detectChanges();

    this.logicPart();
  }

  logicPart() {
    if (this.question.logics !== null && this.question.logics.length > 0) {
      for (let i = 0; i < this.question.logics.length; i++) {
        let apply = false;
        let condition = 'END';
        for (let r = 0; r < this.question.logics[i].rules.length; r++) {
          let rapply = false;

          if (this.question.logics[i].rules[r].op === 'EQ') {
            if (this.question.logics[i].rules[r].value === 'other') {
              if (
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0].includes('other')
              ) {
                rapply = true;
              }
            } else if (
              this.responsePage.sections[this.section].questions[
                this.questionIndex
              ].response[0] === this.question.logics[i].rules[r].value
            ) {
              rapply = true;
            } else {
              rapply = false;
            }
          } else if (this.question.logics[i].rules[r].op === 'NEQ') {
            if (this.question.logics[i].rules[r].value === 'other') {
              if (
                !this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0].includes('other')
              ) {
                rapply = true;
              }
            } else if (
              this.responsePage.sections[this.section].questions[
                this.questionIndex
              ].response[0] !== this.question.logics[i].rules[r].value
            ) {
              rapply = true;
            }
          }

          if (r > 0) {
            if (condition === 'AND') {
              apply = apply && rapply;
            } else if (condition === 'OR') {
              apply = apply || rapply;
            }
          } else {
            apply = rapply;
          }
          condition = this.question.logics[i].rules[r].conjunction;
        }

        for (let a = 0; a < this.question.logics[i].actions.length; a++) {
          let foundIndex: number = null;

          const actionquestion = this.currPage.sections[
            this.section
          ].questions.find((ques, ind) => {
            if (
              ques.identifier === this.question.logics[i].actions[a].identifier
            ) {
              foundIndex = ind;
              return true;
            } else {
              return false;
            }
          });

          if (apply) {
            if (this.question.logics[i].actions[a].name === 'HIDE') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                this.responsePage.sections[this.section].questions[
                  foundIndex
                ].logicallyHidden = true;
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyHiddenBy.find(
                  (item) => item === this.question.id
                );
                if (exists === undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHiddenBy.push(this.question.id);
                }
              }
            } else if (this.question.logics[i].actions[a].name === 'SHOW') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyShownBy.find(
                  (item) => item === this.question.id
                );
                if (exists === undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyShownBy.push(this.question.id);
                }
                if (
                  this.responsePage.sections[this.section].questions[foundIndex]
                    .logicallyHiddenBy.length === 0
                ) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHidden = false;
                } else {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHidden = true;
                }
              }
            }
          } else {
            if (this.question.logics[i].actions[a].name === 'HIDE') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyHiddenBy.find(
                  (item) => item === this.question.id
                );
                if (exists !== undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHiddenBy = this.responsePage.sections[
                    this.section
                  ].questions[foundIndex].logicallyHiddenBy.filter(
                    (item) => item !== this.question.id
                  );
                }
                if (
                  this.responsePage.sections[this.section].questions[foundIndex]
                    .logicallyHiddenBy.length === 0
                ) {
                  if (
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyShownBy.length === 0
                  ) {
                    if (
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].defaultVisibile
                    ) {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = false;
                    } else {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = true;
                    }
                  } else {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = false;
                  }
                }
              }
            } else if (this.question.logics[i].actions[a].name === 'SHOW') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                this.responsePage.sections[this.section].questions[
                  foundIndex
                ].logicallyHidden = true;
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyShownBy.find(
                  (item) => item === this.question.id
                );
                if (exists !== undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyShownBy = this.responsePage.sections[
                    this.section
                  ].questions[foundIndex].logicallyShownBy.filter(
                    (item) => item !== this.question.id
                  );
                }
                if (
                  this.responsePage.sections[this.section].questions[foundIndex]
                    .logicallyShownBy.length === 0
                ) {
                  if (
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHiddenBy.length === 0
                  ) {
                    if (
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].defaultVisibile
                    ) {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = false;
                    } else {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = true;
                    }
                  } else {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = true;
                  }
                } else {
                  if (
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHiddenBy.length === 0
                  ) {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = false;
                  } else {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  getValidationValue(question: any, param: string): string {
    const object = question.type.validationValues.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }

  getValue(question: any, param: string): string {
    const object = question.type.values.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }
}
