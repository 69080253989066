<fieldset [disabled]="disabled">

  <div *ngIf="view==='TWO_COLUMNS';else oneColumn">
    <div class="row">
      <div class="col-6" style="display: flex;flex-direction: column;justify-content:center;">
        <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
            {{questionIndex+1}}.
          </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
            *
          </span></p>
        <br>
        <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">

      </div>
      <div class="col-6">
        <span *ngIf="!responsePage.sections[section].questions[questionIndex].isValid"
          style="color:red;font-size: 12px;">
          {{responsePage.sections[section].questions[questionIndex].validationMessage}}</span>
        <br>
        <br>
        <input type="file" (change)="fileChangeEvent($event)" accept="image/*" />
        <br />
        <br />
        <div *ngIf="fileName!==null">
          <button mat-icon-button style="cursor: pointer;margin:2px" (click)="rotateLeft()"
            [matTooltip]="'Rotate Left'">
            <mat-icon>rotate_left</mat-icon>
          </button>
          <button mat-icon-button style="cursor: pointer;margin:2px" (click)="rotateRight()"
            [matTooltip]="'Rotate Right'">
            <mat-icon>rotate_right</mat-icon>
          </button>
          <button mat-icon-button style="cursor: pointer;margin:2px" (click)="flipHorizontal()"
            [matTooltip]="'Flip Horizontal'">
            <mat-icon>swap_horiz</mat-icon>
          </button>
          <button mat-icon-button style="cursor: pointer;margin:2px" (click)="flipVertical()"
            [matTooltip]="'Flip Vertical'">
            <mat-icon>swap_vert</mat-icon>
          </button>
          <button mat-icon-button style="cursor: pointer;margin:2px" (click)="zoomOut()" [matTooltip]="'Zoom Out'">
            <mat-icon>zoom_out</mat-icon>
          </button>
          <button mat-icon-button style="cursor: pointer;margin:2px" (click)="zoomIn()" [matTooltip]="'Zoom In'">
            <mat-icon>zoom_in</mat-icon>
          </button>
          <br />
          <br />
          <!-- <button
          (click)="toggleContainWithinAspectRatio()">{{containWithinAspectRatio?'Fill Aspect Ratio':'Contain Within Aspect Ratio'}}</button> -->


          <button mat-icon-button style="cursor: pointer;margin:2px" (click)="resetImage()"
            [matTooltip]="'Reset Image'">
            <mat-icon>refresh</mat-icon>
          </button>
          <!-- <input [(ngModel)]="rotation" placeholder="Rotation" type="number" (keyup)="updateRotation()" />  -->

          <button mat-icon-button style="cursor: pointer;margin:2px" (click)="save()" [matTooltip]="'Save Image'">
            <mat-icon>save</mat-icon>
          </button>

          <div *ngIf="!useSquare; else SquareImage">
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false"
              [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="4 / 3" [resizeToWidth]="256"
              [cropperMinWidth]="128" [roundCropper]="false" [canvasRotation]="canvasRotation" [transform]="transform"
              [alignImage]="'left'" [style.display]="showCropper ? null : 'none'" format="png"
              (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
              (loadImageFailed)="loadImageFailed()"></image-cropper>
          </div>
          <ng-template #SquareImage>
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
              [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="4 / 4" [resizeToWidth]="256"
              [cropperMinWidth]="128" [roundCropper]="false" [canvasRotation]="canvasRotation" [transform]="transform"
              [alignImage]="'left'" [style.display]="showCropper ? null : 'none'" format="png"
              (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
              (loadImageFailed)="loadImageFailed()"></image-cropper>
          </ng-template>
          <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" />


        </div>

        <img *ngIf="fileName===null && this.responsePage.sections[this.section].questions[this.questionIndex].response != null &&
      this.responsePage.sections[this.section].questions[this.questionIndex].response.length > 0"
          [src]="downloadImage(this.responsePage.sections[this.section].questions[this.questionIndex].response[0])"
          class="question-image" [style.border]="'1px solid black'" />

      </div>
    </div>
  </div>

  <ng-template #oneColumn>

    <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
        {{questionIndex+1}}.
      </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
        *
      </span></p>
    <br>
    <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">

    <span *ngIf="!responsePage.sections[section].questions[questionIndex].isValid" style="color:red;font-size: 12px;">
      {{responsePage.sections[section].questions[questionIndex].validationMessage}}</span>
    <br>
    <br>
    <input type="file" (change)="fileChangeEvent($event)" accept="image/*" />
    <br />
    <br />
    <div *ngIf="fileName!==null">
      <button mat-icon-button style="cursor: pointer;margin:2px" (click)="rotateLeft()" [matTooltip]="'Rotate Left'">
        <mat-icon>rotate_left</mat-icon>
      </button>
      <button mat-icon-button style="cursor: pointer;margin:2px" (click)="rotateRight()" [matTooltip]="'Rotate Right'">
        <mat-icon>rotate_right</mat-icon>
      </button>
      <button mat-icon-button style="cursor: pointer;margin:2px" (click)="flipHorizontal()"
        [matTooltip]="'Flip Horizontal'">
        <mat-icon>swap_horiz</mat-icon>
      </button>
      <button mat-icon-button style="cursor: pointer;margin:2px" (click)="flipVertical()"
        [matTooltip]="'Flip Vertical'">
        <mat-icon>swap_vert</mat-icon>
      </button>
      <button mat-icon-button style="cursor: pointer;margin:2px" (click)="zoomOut()" [matTooltip]="'Zoom Out'">
        <mat-icon>zoom_out</mat-icon>
      </button>
      <button mat-icon-button style="cursor: pointer;margin:2px" (click)="zoomIn()" [matTooltip]="'Zoom In'">
        <mat-icon>zoom_in</mat-icon>
      </button>
      <br />
      <br />
      <!-- <button
    (click)="toggleContainWithinAspectRatio()">{{containWithinAspectRatio?'Fill Aspect Ratio':'Contain Within Aspect Ratio'}}</button> -->


      <button mat-icon-button style="cursor: pointer;margin:2px" (click)="resetImage()" [matTooltip]="'Reset Image'">
        <mat-icon>refresh</mat-icon>
      </button>
      <!-- <input [(ngModel)]="rotation" placeholder="Rotation" type="number" (keyup)="updateRotation()" />  -->

      <button mat-icon-button style="cursor: pointer;margin:2px" (click)="save()" [matTooltip]="'Save Image'">
        <mat-icon>save</mat-icon>
      </button>

      <div *ngIf="!useSquare; else SquareImage">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false"
          [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="4 / 3" [resizeToWidth]="256"
          [cropperMinWidth]="128" [roundCropper]="false" [canvasRotation]="canvasRotation" [transform]="transform"
          [alignImage]="'left'" [style.display]="showCropper ? null : 'none'" format="png"
          (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
          (loadImageFailed)="loadImageFailed()"></image-cropper>
      </div>
      <ng-template #SquareImage>
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
          [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="4 / 4" [resizeToWidth]="256"
          [cropperMinWidth]="128" [roundCropper]="false" [canvasRotation]="canvasRotation" [transform]="transform"
          [alignImage]="'left'" [style.display]="showCropper ? null : 'none'" format="png"
          (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
          (loadImageFailed)="loadImageFailed()"></image-cropper>
      </ng-template>
      <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" />


    </div>

    <img *ngIf="fileName===null && this.responsePage.sections[this.section].questions[this.questionIndex].response != null &&
this.responsePage.sections[this.section].questions[this.questionIndex].response.length > 0"
      [src]="downloadImage(this.responsePage.sections[this.section].questions[this.questionIndex].response[0])"
      class="question-image" [style.border]="'1px solid black'" />

  </ng-template>


</fieldset>
