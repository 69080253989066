import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';
import { CandidateProfileService } from 'src/app/modules/candidate/candidate-profile/candidate-profile.service';

@Component({
  selector: 'auto-save',
  template: '{{autoSave ? start(): stop()}}',
})
export class AutoSaveComponent implements OnInit, OnDestroy {
  @Input() storageKey!: string
  @Input() storeItem!: any
  @Input() localTick: number = 2
  @Input() dbTick: number = 60
  @Input() autoSave: boolean = true
  @Input() candidate:boolean=false;
  @Input() saveInDB?: (storeItem: any) => Observable<any>

  @Output() onDBSave = new EventEmitter();

  constructor(private candidateProfileService: CandidateProfileService) { }

  localAutoUpdate: Subscription = undefined
  dbAutoUpdate: Subscription = undefined
  unsavedDbChanges: Observable<any> = undefined

  ngOnInit(): void {}

  ngOnDestroy(): void {
      this.stop();
  }

  start(): void {
    if(!this.localAutoUpdate) {
      this.localAutoUpdate = interval(this.localTick * 1000).subscribe(() => {
        const storedItem: string = localStorage.getItem(this.storageKey);
        const storedItemData = JSON.parse(storedItem)?.data;

        const toBeStored = {
          updatedAt: new Date(),
          data: this.storeItem
        }
        const toBeStoredItem: string = JSON.stringify(toBeStored);
        
        if(
          this.storageKey && 
          this.storeItem && 
          (JSON.stringify(storedItemData) !== JSON.stringify(toBeStored.data))
        ) {
          localStorage.setItem(this.storageKey, toBeStoredItem)
          if (typeof this.saveInDB === 'function') {
            this.unsavedDbChanges = this.saveInDB(JSON.parse(toBeStoredItem)?.data)
          }
        }
      })
    }

    if(!this.dbAutoUpdate) {
      this.dbAutoUpdate = interval(this.dbTick * 1000).subscribe(() => {
        if(this.unsavedDbChanges) this.unsavedDbChanges.subscribe((res) => {
          if(this.candidate){
             this.candidateProfileService.setPartialId(res?.id);
          }
          this.onDBSave.emit();
          this.unsavedDbChanges = undefined
        })
      })
    }
  }

  stop(): void {
    if(this.localAutoUpdate) {
      this.localAutoUpdate.unsubscribe()
      this.localAutoUpdate = undefined
    }
    if(this.dbAutoUpdate) {
      this.dbAutoUpdate.unsubscribe()
      this.dbAutoUpdate = undefined
    }
  }

}
