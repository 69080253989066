<!-- <app-loader [display]="spinner" [textToShow]="'Uploading.... Please Wait'"></app-loader> -->

<div *ngIf="!spinner">
  <app-banner title="My Application" [displaySubHeader]="false"> </app-banner>
  <div class="container-fluid nopadLR">
    <div class="col-md-12 nopadLR">
      <!-- <mat-stepper
        [linear]="isLinear"
        labelPosition="bottom"
        #stepper
        [selectedIndex]="activeIndex"
        class="w-100"
      >
        <mat-step
          [editable]="true"
          *ngFor="let page_name of stepper_page_names; let ind = index"
          label="{{ page_name }}"
          [state]="ind < activeIndex ? 'done' : 'number'"
        >
          <div *ngIf="ind == 0"> -->
      <div>
        <!-- <app-candidate-profile
          *ngIf="documentListDataLoaded"
          (applicantIdSent)="handelApplicantId($event, applicantId)"
          [documentList]="documentRequired"
          [opportunityId]="jobId"
          [maxFamilyAnnualIncome]="maxFamilyAnnualIncome"
          [checkForLocalStepState]="checkForLocalStepState"
        ></app-candidate-profile> -->

        <app-tablist-template 
        *ngIf="documentListDataLoaded"
        (applicantIdSent)="handelApplicantId($event, applicantId)"
        [documentList]="documentRequired"
        [opportunityId]="jobId"
        [maxFamilyAnnualIncome]="maxFamilyAnnualIncome" 
        >
        </app-tablist-template>

        <!-- <div class="row justify-content-center">
          <button
            type="button"
            class="DSA_wb_primary-btn text-uppercase"
            *ngIf="isProfileValidated && applicantId"
            (click)="appyToScholarshipAndGoToNextStep()"
          >
            Save & Continue
          </button>
        </div> -->
      </div>
      <!-- <div *ngIf="ind == 1">
            <app-loader
              [display]="spinnerChild"
              [textToShow]="'Submitting.... Please Wait'"
            ></app-loader>
            <div *ngIf="!spinnerChild">
              <div class="row marB32 justify-content-center">
                <div
                  *ngFor="
                    let doc_name of documentRequiredUI;
                    let doc_ind = index
                  "
                  class="col-md-6"
                >
                  <app-single-file-upload
                    [doc_type_ui]="doc_name"
                    [doc_type]="documentRequired[doc_ind]"
                    [applicationId]="applicationId"
                  ></app-single-file-upload>
                </div>
              </div>
            </div>

            <div
              *ngIf="!spinnerChild"
              class="
                marT32
                marR32
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <button
                mat-raised-button
                matStepperPrevious
                type="button"
                *ngIf="ind > 0"
                class="DSA_wb_secondary-btn"
                (click)="handleBackClick()"
              >
                Back
              </button>
              <button
                (click)="finalSubmit()"
                mat-raised-button
                class="DSA_wb_primary-btn marL8"
              >
                Submit Application&nbsp;
              </button>
            </div>
          </div> -->
      <!-- </mat-step> -->
      <!-- </mat-stepper> -->
    </div>
  </div>
</div>
