import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { EventService } from 'src/app/services/event.service';
import { SharedService } from 'src/app/services/shared.service';
import { PanelistUnavailabilityDialogComponent } from 'src/app/modules/iwp-event/panelist-unavailability-dialog/panelist-unavailability-dialog.component';
import { AccountService } from 'src/app/services/account.service';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
@Component({
  template: `<span *ngIf="params.condition" class="d-flex">
    <i
      class="icon x-24 icon-time"
      role="button"
      *ngIf="isLateralDynamic"
      (click)="handleUnavailabilityClick()"
      matTooltip="Mark Unavailability"
      matTooltipClass="DSA_tooltip"
      aria-label="Mark Panelist Unavailability"
    ></i>
    <i
      *ngIf="
        this.accountService.hasFeatureAccess(
          FeatureName.EVENT_PANELIST,
          PermissionType.DELETE
        )
      "
      class="icon x-24 delete-sm-icon marL8"
      role="button"
      (click)="onDeleteClick()"
      matTooltip="Delete Panelist"
      matTooltipClass="DSA_tooltip"
      aria-label="Delete Panelist"
    ></i
  ></span>`,
})
export class panelistActionRendererComponent implements AgRendererComponent {
  params: any;
  isLateralDynamic: boolean = false;

  FeatureName = FeatureName;
  PermissionType = PermissionType;

  constructor(
    private dialog: MatDialog,
    private eventService: EventService,
    private sharedService: SharedService,
    public accountService: AccountService
  ) {}

  agInit(params: any): void {
    this.params = params;

    this.isLateralDynamic = params.isLateralDynamic;
  }

  refresh(params: any): boolean {
    return false;
  }

  onDeleteClick() {
    this.deletePanelist(this.params.data);
  }

  deletePanelist = (panelist) => {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        text: `Are you sure you want to remove <b>${panelist.panelistEmail}</b>?`,
      },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Yes') {
        this.params.context.componentParent.showLoadingOverlay();
        this.eventService.deleteInterviewersOfEvent(panelist).subscribe(
          (res) => {
            this.params.context.componentParent.refreshTable();
            this.sharedService.openSnackBar(
              'Panelist deleted successfully',
              '',
              3000
            );
          },
          (err) => {
            this.params.context.componentParent.setGridUI();
            this.sharedService.openSnackBar(
              'Panelist Deletion Failed',
              '',
              3000
            );
          }
        );
      }
    });
  };

  handleUnavailabilityClick = () => {
    const dialogRef = this.dialog.open(PanelistUnavailabilityDialogComponent, {
      data: {
        eventId: this.eventService.selectedEventId,
        userMailId: this.params.data.panelistEmail,
      },
      backdropClass: 'backdrop-background',
      autoFocus: false,
      minWidth: 500,
      disableClose: true,
    });
  };
}
