<div class="page-height">
  <header
    class="DSA_Header clearfix ctr_header landing-page-header header"
    id="scrollHeader"
  >
    <div class="d-flex align-items-center h-100 container-fluid">
      <div class="left-section d-flex align-items-center">
        <a
          routerLink="/"
          href="javascript:void(0)"
          class="marL16 float-left logo margin-right-2"
        >
          <img class="edit-h-w" [src]="'assets/images/logo-wa.png'" />
        </a>
      </div>
      <div class="login-sec">
        <a href="javascript:void(0)" (click)="login()"> Login/Register </a>
      </div>
    </div>
  </header>
  <div class="content">
    <div class="container">
            <p style="margin-bottom:0cm;text-align:center;line-height:normal;"><strong><u><span style='font-family:"Times New Roman",serif;'>PRIVACY &amp; DATA PROTECTION</span></u></strong></p>
            <p style="margin-bottom:0cm;text-align:justify;line-height:normal;"><span style='font-family:"Times New Roman",serif;color:black;'>WA Foundation</span><span style='font-family:"Times New Roman",serif;color:black;'>, its subsidiaries, associates and affiliated companies (collectively referred to as &ldquo;WA Foundation&rdquo;, &ldquo;us&rdquo;, &ldquo;we&rdquo;) are committed to process your personal data as per the laws of your jurisdiction. We hereby notify you the following information about&nbsp;</span><span style='font-size:16px;font-family:"Times New Roman",serif;'>the processing of personal data-&nbsp;</span></p>
            <p style="margin-bottom:0cm;text-align:justify;line-height:110%;"><strong><span style='font-family:"Times New Roman",serif;color:black;'>Personal data elements collected/processed</span></strong><strong><span style='font-family:"Times New Roman",serif;'>:&nbsp;</span></strong></p>
            <ul style="list-style-type: disc;">
                <li><strong><span style='font-family:"Times New Roman",serif;color:black;'>You and your team members data such as&nbsp;</span></strong><span style='font-family:"Times New Roman",serif;'>First Name, Last Name, Middle Name, Email ID, Phone Number, Alternate Phone number (optional number for contacting you in case the other number is unreachable), Honorifics, College Name &amp; its address details, Academic details such as Institute, Degree, Course, Specialization, Semester, year of Passing, College ID proof, Hackathon Submissions, Status of submissions.</span></li>
            </ul>
            <p style="margin-bottom:0cm;text-align:justify;"><strong><span style='font-family:"Times New Roman",serif;'>Purpose of Processing:&nbsp;</span></strong><span style='font-family:"Times New Roman",serif;'>We&nbsp;</span><span style='font-family:"Times New Roman",serif;'>process the Personal Data provided by you for the below purposes such as:</span></p>
            <ul style="list-style-type: disc;margin-left:8px;">
                <li><span style='line-height:110%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>To enable you to apply and register yourself and your team members (as provided by you) for WA foundation hackathon program.</span></li>
                <li><span style='line-height:110%;font-family:"Times New Roman",serif;font-size:16px;'>To identify and shortlist eligible participants for the hackathon program.</span></li>
                <li><span style='font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:16px;'>For internal management of the program and sharing updates between authorized users.</span></li>
                <li><span style='font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:16px;'>To enable you to contact and be contacted by the Mentors under WA Foundation, as applicable, in case of any mentoring required, communication for the hackathon event.</span></li>
                <li><span style='font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:16px;'>For sharing of you and your team members data with our authorized WA Foundation Mentoring/ Panelist for evaluation, shortlisting etc. (where applicable based on your evaluation status)</span></li>
                <li><span style='font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:16px;'>For receiving reminder, communication, related to the hackathon event.</span></li>
                <li><span style='font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:16px;'>For audits and reporting purposes.&nbsp;</span></li>
            </ul>
            <p style="margin-bottom:0cm;text-align:justify;line-height:110%;"><strong><span style='font-family:"Times New Roman",serif;'>Data Recipients/ Accessible to:&nbsp;</span></strong><span style='line-height:110%;font-family:"Times New Roman",serif;'>The personal data shared by you (including your team members personal data) will be accessible to authorized WA Foundation personnels such as Mentors/ Panelists, representatives from WA Foundation, internal/external auditors, Government authorities where applicable, and our authorized service provider Infosys as applicable.</span></p>
            <p style="margin-bottom:0cm;text-align:justify;line-height:110%;"><strong><span style='font-family:"Times New Roman",serif;'>Data Transfer &amp; Storage:<span style="color:black;background:white;">&nbsp;&nbsp;</span></span></strong><span style='font-family:"Times New Roman",serif;'>The personal data shared by you will get stored on</span><span style='font-family:"Times New Roman",serif;color:#333333;'>&nbsp;our internal servers,&nbsp;</span><span style='font-family:"Times New Roman",serif;color:#333333;'>our authorized service provider&rsquo;s server in India</span><span style='font-family:"Times New Roman",serif;'>.</span></p>
            <p style="text-align:justify;"><strong><span style='font-family:"Times New Roman",serif;'>Data Security:&nbsp;</span></strong><span style='font-family:"Times New Roman",serif;'>WA Foundation adopts reasonable and appropriate security practices and procedures including administrative, physical security, and technical controls in order to safeguard your Personal Data.</span></p>
            <p style="margin-bottom:0cm;text-align:justify;"><strong><span style='font-family:"Times New Roman",serif;'>Data Retention:&nbsp;</span></strong><span style='font-family:"Times New Roman",serif;'>Personal Data that is no longer required to be retained as per legal and business requirements will be disposed in a secure manner.</span></p>
            <p style="margin:0cm;text-align:justify;vertical-align:baseline;"><strong><span style="font-size:15px;">Data subject rights: </span></strong><span style="font-size:15px;"> You are entitled at any time to access and rectify your personal information. In case of any requests, issues, concerns or queries you may reach o</span><span style="font-size:15px;">ut to WA Foundation Office by sending an email to&nbsp;</span><a href="mailto:tpic@wa.foundation"><em><span style="text-decoration:none;">tpic@wa.foundation.</span></em></a><span style="font-size:15px;">&nbsp;</span></p>
            <p style="margin-bottom:0cm;text-align:justify;"><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
            <p style="text-align:justify;"><strong><span style='font-family:"Times New Roman",serif;'>Right to Withdrawal:</span></strong><span style='font-family:"Times New Roman",serif;'>&nbsp;Where we process your Personal Data based on your consent, you may withdraw your consent to the processing as per applicable DP laws at any time for the future. This will not affect the lawfulness of any processing operation before your withdrawal. To withdraw your consent, you may send an email&nbsp;</span><em><span style='font-family:"Times New Roman",serif;'>to&nbsp;</span></em><a href="mailto:tpic@wa.foundation"><em><span style='font-family:"Times New Roman",serif;text-decoration:none;'></span></em><span style="color:windowtext;text-decoration:none;">&nbsp;</span><em><span style='font-family:"Times New Roman",serif;text-decoration:none;'>tpic@wa.foundation.</span></em></a><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
            <p style="margin-bottom:0cm;text-align:justify;"><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
            <p><strong><span style='font-size:16px;font-family:"Times New Roman",serif;'>You hereby acknowledge</span></strong><span style='font-size:16px;font-family:"Times New Roman",serif;'>&nbsp;<strong>and understand</strong></span><strong><span style='font-size:15px;font-family:"Times New Roman",serif;'>,that your personal data may be collected and processed in the above-mentioned manner and hereby consent to the same for the above-mentioned purposes. You also acknowledge that any personal data (of your team members other than yourself) shared by you are 18 years or older and is only after taking appropriate consent from them. You also acknowledge that you will not share any personal data (including sensitive personal data) which is not required for the above-mentioned purposes.&nbsp;</span></strong></p>
            <div id="_com_3" language="JavaScript"><br></div>
    </div>
  </div>
  <footer class="DSA_footer ctr_footer" id="mainfooter">
    <div class="container-fluid small-screen-font">
      Copyright 2023 WAFOUNDATION. All Right Reserved.
    </div>
  </footer>
</div>
