import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MailTemplateService } from 'src/app/services/mail-template.service';

@Component({
  selector: 'app-bulk-upload-dialog',
  templateUrl: './bulk-upload-dialog.component.html',
  styleUrls: ['./bulk-upload-dialog.component.scss'],
})
export class BulkUploadDialogComponent implements OnInit {
  addedTemplateCategories = [];
  showStepper: boolean = false;

  isLoading: boolean = false;
  isError: boolean = false;

  constructor(
    private appMailTemplateService: MailTemplateService,
    public dialogRef: MatDialogRef<BulkUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.appMailTemplateService
      .getMailTemplatesByEvent(this.data.eventId)
      .subscribe(
        (res) => {
          this.addedTemplateCategories = res.map(
            (template) => template.templateCategoryName
          );
          if (
            this.addedTemplateCategories.length > 0 &&
            !this.checkRequiredMailTemplatesAdded()
          ) {
            // if required mail templates are not added
            this.showStepper = true;
          }
          this.isLoading = false;
        },
        (err) => {
          console.log(err);
          this.isError = true;
        }
      );
  }

  checkRequiredMailTemplatesAdded = () => {
    const mailTemplateCategories = [
      'CANDIDATE_SCHEDULE',
      'PANELLIST_SCHEDULE',
      'CANDIDATE_RESCHEDULE',
      'PANELLIST_RESCHEDULE',
    ];
    for (const category of mailTemplateCategories) {
      if (!this.addedTemplateCategories.includes(category)) {
        return false;
      }
    }
    return true;
  };

  closeDialog = () => {
    this.dialogRef.close();
  };
}

@Component({
  selector: 'templates-warning',
  template: `
    <p>
      Before scheduling interviews,
      <b> please ensure all necessary mail templates are added. </b>
      To know more about mail templates,
      <a
        [href]="mailTemplateVideoUrl"
        target="_blank"
        class="DSA_wb_hyperLink-btn font-16"
      >
        click here
      </a>
    </p>
  `,
})
export class TemplatesWarningComponent {
  mailTemplateVideoUrl =
    'https://infosystechnologies-my.sharepoint.com/:v:/g/personal/manisha26_ad_infosys_com/EXd9oN3oioVJmiy-iEtquTYBKPRRWIGgkj3OwArBu7FRQA?e=flCJeZ';
}
