import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { JobsAdminDashboardStateStorageService } from 'src/app/modules/iwp-platform-actions/infy-jobs-admin/jobs-admin-dashboard-state-storage.service';
import { AccountService } from 'src/app/services/account.service';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-scholarship-card-v1',
  templateUrl: './scholarship-card-v1.component.html',
  styleUrls: ['./scholarship-card-v1.component.scss'],
})
export class ScholarshipCardV1Component implements OnInit {
  @Input() job: any;
  @Input() isLoggedIn = true;
  showSocialIcons: boolean = false;
  showLimit = 3;
  
  constructor(
    private keycloakService: KeycloakService,
    private router: Router,
    public accountService: AccountService,
    private jobsAdminStateStorageService: JobsAdminDashboardStateStorageService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
  }

  toggleSocialIcons() {
    this.showSocialIcons = !this.showSocialIcons;
  }

  viewJobdetails = (jobId: number) => {
    if(!this.isLoggedIn)
   { if (environment.production) {
      this.accountService.login();
    } else {
      this.keycloakService.login();
    }}
    this.router.navigate(['/candidate/problem-statements', jobId]);
  };

  redirectToNextPage = (jobId) => {
    if (this.accountService.getCurrentRole() == 'ROLE_HIRING_MANAGER') {
      this.router.navigate(['scp/applicants/', jobId]);
    } else {
      this.viewJobdetails(jobId);
    }
  };
  handleAssociateForm = (job) => {
    this.jobsAdminStateStorageService.selectedJob = job;
    this.router.navigate(['/job-evaluation-form/' + job.id]);
  };

  getDateSimplified = (dateString:string) =>{
    let date = new Date(dateString);
    return date.toLocaleDateString('en-GB', { 
      day: '2-digit', 
      month: '2-digit', 
      year: 'numeric' 
    }).replace(/\//g, '-')
  }

  copyToClipboard(id: string) {
    let text = `${window.location.href.split('/').slice(0,-1).join('/')}/problem-statements/${id}`;
    navigator.clipboard.writeText(text)
      .then(() => {
        this.sharedService.openSuccessSnackBar(`Copied to clipboard: ${text}`,'');
      })
      .catch((err) => {
        this.sharedService.openErrorSnackBar(`Error copying to clipboard: ${err}`, '');
      });
  }
  
}
