import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ExtractedResumeDetailComponent } from './components/extracted-resume-detail/extracted-resume-detail.component';

const routes: Routes = [
  {
    path: 'view/:id/:jobApplicationId',
    component: ExtractedResumeDetailComponent,
  },
];

export const routing = RouterModule.forChild(routes);
@NgModule({
  declarations: [],
  imports: [CommonModule, routing],
  exports: [RouterModule],
})
export class ExtractedResumeRoutingModule {}
