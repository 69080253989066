<div class="container-fluid marT48 marB32 nopadLR">
  <app-loader [display]="isLoading"></app-loader>
  <auto-save *ngIf="localStorageKey && !disableEditing" [storageKey]="localStorageKey" [storeItem]="unsavedFormData"
    [localTick]="10" [dbTick]="86400" [autoSave]="autoSaveLocally"></auto-save>
  <ng-container *ngIf="!isLoading">
    <div [formGroup]="teamForm">
      <div class="row">
        <div class="col-md-4" >
          <mat-form-field class="DSA_Wb_custom-form-field">
            <mat-label>Team Name</mat-label>
            <input matInput placeholder="" formControlName="teamName" value="" />
            <mat-error *ngIf="teamForm.controls.teamName.invalid">
              Please Enter Name Of The Team Member.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-4 offset-md-4" >
          <mat-form-field class="DSA_Wb_custom-form-field" [ngClass]="disableEditing ? 'desabled' : ''">
            <mat-label>Institue</mat-label>
            <input matInput placeholder="" [ngModel]="institueName" [value]="institueName" [disabled]="true"/>
          </mat-form-field>
        </div>
      </div>
      <div class="row" formArrayName="teamMemberUiDTOS">
        <div class="col-12" *ngFor="
            let educationDetailsItem of teamMembers.controls;
            let i = index
          ">
          <div [formGroupName]="i" class="container-box-shadow marTB16">
            <div class="
                padLR16
                d-flex
                justify-content-between
                align-items-center
                marB8
              ">
              <h3 class="DSA_wb_h3Header web-GreyDarkest DSA_wb_text-1line"></h3>
              <div *ngIf="!disableEditing && applicationStatus !== 'SUBMITTED' && teamMembers.controls.length !== 1">
                <a href="javascript:void(0);" class="whiteIcon-Circle contact-sub-icon"
                  matTooltip="Remove this teammate." matTooltipClass="DSA_tooltip"
                  (click)="removeTeamMate(i)">
                  <i class="icon x-16 close-icon"> </i>
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4" >
                <mat-form-field class="DSA_Wb_custom-form-field">
                  <mat-label>First Name</mat-label>
                  <input matInput placeholder="" formControlName="firstName" value="" />
                  <mat-error *ngIf="educationDetailsItem.controls.firstName.invalid">
                    Please Enter First Name Of The Team Member.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4" >
                <mat-form-field class="DSA_Wb_custom-form-field">
                  <mat-label>Last Name</mat-label>
                  <input matInput placeholder="" formControlName="lastName" value="" />
                  <mat-error *ngIf="educationDetailsItem.controls.lastName.invalid">
                    Please Enter Last Name Of The Team Member.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4" >
                <mat-form-field class="DSA_Wb_custom-form-field">
                  <mat-label>Email</mat-label>
                  <input matInput placeholder="" formControlName="email" value="" />
                  <mat-error *ngIf="educationDetailsItem.controls.email.invalid">
                    Please Enter Email Of Team Member.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="education-details" [formGroupName]="'educationDetailDTO'">
              <div class="row">
                <div class="col-md-4">
                  <mat-form-field class="DSA_Wb_custom-form-field">
                    <mat-label>Degree</mat-label>
                    <mat-select formControlName="degree">
                      <mat-option *ngFor="let type of degreeList" [value]="type">
                        {{ type }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="teamForm.get('teamMemberUiDTOS').controls[i].get('educationDetailDTO').get('degree').invalid">
                      Please Select Degree.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4" >
                  <mat-form-field class="DSA_Wb_custom-form-field">
                    <mat-label>Specialisation</mat-label>
                    <mat-select formControlName="specialisation">
                      <mat-option *ngFor="let type of specialisation" [value]="type">
                        {{ type }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="teamForm.get('teamMemberUiDTOS').controls[i].get('educationDetailDTO').get('specialisation').invalid">
                      Please Select Specialisation.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="DSA_Wb_custom-form-field">
                    <mat-label>CGPA/PERCENTAGE</mat-label>
                    <input matInput type="number" placeholder="xx.xx" formControlName="cgpa" />
                    <mat-error *ngIf="teamForm.get('teamMemberUiDTOS').controls[i].get('educationDetailDTO').get('cgpa').hasError('required')">
                      Please Enter CGPA/Percentage Of Team Member.
                    </mat-error>
                    <mat-error *ngIf="teamForm.get('teamMemberUiDTOS').controls[i].get('educationDetailDTO').get('cgpa').hasError('max')">
                      Please Enter Valid CGPA/Percentage.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <mat-form-field class="DSA_Wb_custom-form-field">
                    <mat-label>YEAR OF PASSING</mat-label>
                    <mat-select formControlName="yearOfPassing">
                      <mat-option *ngFor="let year of passingYearArray" [value]="year">{{ year }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="teamForm.get('teamMemberUiDTOS').controls[i].get('educationDetailDTO').get('yearOfPassing').invalid">
                      Please Select Year Of Passing.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 marT8" *ngIf="!disableEditing && applicationStatus !== 'SUBMITTED' && teamMembers.controls.length < maxLimitForTeam">
        <button mat-flat-button type="button" class="DSA_wb_Icon-btn marT8" (click)="addTeamMember()">
          <span class="icon_btnCont">
            <i class="icon x-16 icon-add"></i>
          </span>
          Add Team Member
        </button>
      </div>
    </div>
    <div class="d-flex align-items-center marT32">
      <button mat-button class="DSA_wb_primary-btn text-uppercase customButton marL8" (click)="submitDetails()">
        Submit
      </button>
      <button mat-button *ngIf="!disableEditing" (click)="onClickReset()"
        class="DSA_wb_secondary-btn text-uppercase customButton marL16 marR8">
        Reset
      </button>
    </div>
  </ng-container>
</div>