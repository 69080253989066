<div *ngIf="spinner">
  <br />
  <img
    src="../../../assets/loader.gif"
    style="margin-left: 30%; width: 200px"
    alt="Spinner"
  />
</div>
<div class="m-3 mr-5">
  <div class="container-fluid ml-3 mr-3">
    <div class="mb-3 pt-3">
      <div class="row">
        <mat-form-field
          class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
        >
          <mat-label class="color-name">Gender</mat-label>
          <input
            matInput
            [(ngModel)]="personalDetails && personalDetails.gender"
          />
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field
          class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
        >
          <mat-label class="color-dob">DOB</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [(ngModel)]="personalDetails && personalDetails.dob"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      
        </div>
      <div class="row">
        <mat-form-field
          class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
        >
          <mat-label class="color-name">Achievements</mat-label>
          <input
            matInput
            [(ngModel)]="personalDetails && personalDetails.achievements"
          />
        </mat-form-field>
      </div>

      <div class="row">
          <!-- <mat-form-field class="DSA_Wb_custom-form-field">
            <mat-label class="color-name">MaritalStatus </mat-label>
            <input
              matInput
              [(ngModel)]="personalDetails && personalDetails.maritalStatus"
            />
          </mat-form-field> -->

          <mat-form-field
        class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
        
      >
        <mat-label>Marital status</mat-label>
        <!-- <input
          matInput
          placeholder="Marital status"
          formControlName="maritalStatus"
        /> -->
        <mat-select [(ngModel)]="personalDetails && personalDetails.maritalStatus">
          <mat-option value="MARRIED">MARRIED</mat-option>
          <mat-option value="UNMARRIED">UNMARRIED</mat-option>
          <mat-option value="DIVORCED">DIVORCED</mat-option>
          <mat-option value="WIDOWED">WIDOWED</mat-option>
        </mat-select>
      </mat-form-field>
       

        
      </div>
      <div class="row">
        <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt">
          <mat-label class="color-name">Nationality </mat-label>
          <input
            matInput
            [(ngModel)]="personalDetails && personalDetails.nationality"
          />
        </mat-form-field>
      </div>

      <div class="row">
        
          <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt">
            <mat-label class="color-name">Religion </mat-label>
            <input
              matInput
              [(ngModel)]="personalDetails && personalDetails.religion"
            />
          </mat-form-field>
      </div>
        <div class="row">
          <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt">
            <mat-label class="color-name">Hobby </mat-label>
            <input
              matInput
              [(ngModel)]="personalDetails && personalDetails.hobby"
            />
          </mat-form-field>
        </div>
     

      <div class="row">
        
          <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt">
            <mat-label class="color-name">Spouse Employer </mat-label>
            <input
              matInput
              [(ngModel)]="personalDetails && personalDetails.spouseEmployer"
            />
          </mat-form-field>
        </div>
        

      <div class="row">
        <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt">
          <mat-label class="color-name">Credit History </mat-label>
          <input
            matInput
            [(ngModel)]="personalDetails && personalDetails.creditHistory"
          />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt">
          <mat-label class="color-name">Orientation </mat-label>
          <input
            matInput
            [(ngModel)]="personalDetails && personalDetails.orientation"
          />
        </mat-form-field>
      </div>

      <div class="row">
        <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt">
          <mat-label class="color-name">Criminal Record </mat-label>
          <input
            matInput
            [(ngModel)]="personalDetails && personalDetails.criminalRecord"
          />
        </mat-form-field>
      </div>

    
    </div>
    
  </div>

  <button
      class="DSA_wb_miniSecondary-btn "
      (click)="updateExtractedResume()"
      style="float: right; margin-right: 10px !important;margin-bottom: 10px !important;"
    >
      Update
    </button>
</div>
