export enum FeatureName {
EVENT = 'EVENT',
ANALYTICS = 'ANALYTICS',
JOBS = 'JOBS',
ASSOCIATE_JOBS = 'ASSOCIATE_JOBS',
EVENT_ASSOCIATE_JOBS = 'EVENT_ASSOCIATE_JOBS',
MAIL_TEMPLATES = 'MAIL_TEMPLATES',
USER_ACCESS_MANAGEMENT = 'USER_ACCESS_MANAGEMENT',
DATA_MIGRATION = 'DATA_MIGRATION',
GLOBAL_SEARCH = 'GLOBAL_SEARCH',
BULK_UPLOAD_STATUS = 'BULK_UPLOAD_STATUS',
EVENT_TEMPLATE_MAPPING = 'EVENT_TEMPLATE_MAPPING',
EVENT_SETTINGS = 'EVENT_SETTINGS',
CLOSE_EVENT = 'CLOSE_EVENT',
GENERATE_REPORT = 'GENERATE_REPORT',
BULK_UPLOAD_INTERVIEWS = 'BULK_UPLOAD_INTERVIEWS',
BULK_UPLOAD_PANELISTS = 'BULK_UPLOAD_PANELISTS',
BULK_UPLOAD_PANELISTS_SLOT = 'BULK_UPLOAD_PANELISTS_SLOT',
EVENT_JOBS = 'EVENT_JOBS',
EVENT_PANELIST = 'EVENT_PANELIST',
EVENT_RECRUITER = 'EVENT_RECRUITER',
INTERVIEW_SCHEDULE = 'INTERVIEW_SCHEDULE',
INTERVIEW_RESCHEDULE = 'INTERVIEW_RESCHEDULE',
RESEND_EMAIL = 'RESEND_EMAIL',
RESEND_WHATSAPP_INVITE = 'RESEND_WHATSAPP_INVITE',
INTERVIEW_CANCELLATION = 'INTERVIEW_CANCELLATION',
FILTER_INTERVIEWS = 'FILTER_INTERVIEWS',
SEARCH_INTERVIEWS = 'SEARCH_INTERVIEWS',
MEETING_LINK = 'MEETING_LINK',
FEEDBACK = 'FEEDBACK',
REVIEW_FEEDBACK = 'REVIEW_FEEDBACK',
CLOSE_CANDIDATURE = 'CLOSE_CANDIDATURE',
VIEW_RESUME = 'VIEW_RESUME',
VIEW_PHOTOS = 'VIEW_PHOTOS',
VIEW_ALL_EVENTS = 'VIEW_ALL_EVENTS',
GENERATE_BULK_REPORTS = 'GENERATE_BULK_REPORTS',
SEND_BACK_TO_PANELIST = 'SEND_BACK_TO_PANELIST',
EVALUATION_TEMPLATES = 'EVALUATION_TEMPLATES',
INTERVIEW_EDIT = 'INTERVIEW_EDIT',
DYNAMIC_DASHBOARD = 'DYNAMIC_DASHBOARD',
UPDATE_APPLICATION_DETAILS = 'UPDATE_APPLICATION_DETAILS',
FEEDBACK_SUBSTATUS = 'FEEDBACK_SUBSTATUS'
}