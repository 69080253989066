/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/tslint/config */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'jhi-respondant-details',
  templateUrl: './respondant-details.component.html',
  styleUrls: ['./respondant-details.component.scss'],
})
export class RespondantDetailsComponent implements OnInit {
  @Input()
  respondant: any;

  cardMouseOverIndex = -1;

  constructor() {}

  ngOnInit() {
    if (this.respondant === null) {
      this.respondant = {};
      this.respondant.firstName = null;
      this.respondant.lastName = null;
      this.respondant.email = null;
    }
  }

  cardMouseOver(index) {
    this.cardMouseOverIndex = index;
  }
}
