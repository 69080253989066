import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventService } from 'src/app/services/event.service';
import { SharedService } from 'src/app/services/shared.service';
import { AccountService } from 'src/app/services/account.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

export interface Location {
  name: string;
}

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss'],
})
export class CreateEventComponent implements OnInit {
  constructor(
    private sharedService: SharedService,
    private eventService: EventService,
    public accountService: AccountService,
    private navRouter: Router
  ) {}

  eventTypeArray = ['FRESHER', 'LATERAL', 'IJP'];
  locationList: Location[] = [];
  createEvent: FormGroup;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  addOnBlur = true;
  removable = true;
  selectable = true;

  ngOnInit(): void {}

  addEvent() {
    let locationString: string = '';
    for (let location of this.locationList) {
      //locationString = locationString + ',' + location.name.toString();
    }
    locationString = locationString.slice(1);
    const data = {
      fromDate: this.formatDate(this.createEvent.controls.fromDate.value),
      toDate: this.formatDate(this.createEvent.controls.toDate.value),
      locations: locationString,
      name: this.createEvent.controls.eventName.value,
      eventType: this.createEvent.controls.eventType.value,
    };
    if (data.name != '' && data.locations != '') {
      this.eventService.createEvent(data).subscribe((res) => {
        this.sharedService.openSnackBar('Event Created Sucessfully', 'Ok');
        this.navRouter.navigate(['/dashboard']);
      });
    } else {
      if (data.name === '') {
        this.sharedService.openSnackBar('Event name cannot be empty', 'Ok');
      } else if (data.locations === '') {
        this.sharedService.openSnackBar('Location cannot be empty', 'Ok');
      }
    }
  }

  formatDate(date): string {
    const _date = new Date(date);
    const day = _date.getDate();

    const month = _date.getMonth() + 1;
    const year = _date.getFullYear();

    let formattedDate =
      year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);
    return formattedDate;
  }

  onSubmit() {}

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our Location
    if ((value || '').trim()) {
      this.locationList.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(location: Location): void {
    const index = this.locationList.indexOf(location);

    if (index >= 0) {
      this.locationList.splice(index, 1);
    }
  }
}
