<div class="padB32 single_file_upload_container web-GreyLight">
  <div *ngIf="spinner" class="label">
    {{ allDocumentListByCategory?.[doc_type]?.label | uppercase }}*
  </div>
  <div
    *ngIf="spinner"
    class="row marT16 justify-content-center align-items-center"
  >
    <mat-spinner [diameter]="24" class="mat-spinner-color"></mat-spinner>
  </div>
  <div
    *ngIf="!spinner"
    class="justify-content-start align-items-center marB12"
    [formGroup]="form"
  >
    <mat-form-field
      class="DSA_Wb_custom-form-field marT8"
      floatPlaceholder="never"
      appearance="legacy"
    >
      <input
        [hidden]="true"
        (change)="onFileSelected($event)"
        type="file"
        [accept]="allowedFileTypes?.extensions?.join(',')"
        (cancel)="onFileCancel()"
        #fileSelect
      />
      <input
        readonly
        matInput
        [formControlName]="controlName"
        [(ngModel)]="fileName"
        [placeholder]=" (allDocumentListByCategory?.[doc_type]?.label | uppercase) "
        (click)="onFileInputClick(); fileSelect.click()"
      />
      <mat-error *ngIf="this.form.get(controlName)?.hasError('errorMessage')">
        {{ this.form.get(controlName).errors.errorMessage }}
      </mat-error>

      <mat-hint>
        {{
          this.consolidatedDocumentType
            ? "Upload expenses for coverage (tuition fee, hostel, books, stationery
      etc)."
            : ""
        }}
        Max upload file size: {{ individualFileMaxSizeInMB }} MB (upload only
        <ng-container
          class="marB16"
          *ngFor="
            let extension of allowedFileTypes?.extensions;
            let last = last
          "
        >
          {{ extension }}{{ last ? "" : ", " }}
        </ng-container>
        file types)
      </mat-hint>
      <div
        *ngIf="uploadedDocumentStatus"
        [ngStyle]="{
          '-webkit-text-fill-color':
            uploadedDocumentStatus === 'SENT_BACK' ? '#df9926' : '#00b28f'
        }"
        matSuffix
      >
        {{
          uploadedDocumentStatus === "PENDING" ||
          uploadedDocumentStatus === "UPLOADING"
            ? "Uploaded"
            : (uploadedDocumentStatus.replace("_", " ") | titlecase)
        }}
      </div>
    </mat-form-field>

    <div class="padT8 marT8">
      <mat-chip-list>
        <mat-chip
          *ngFor="let file of allUploadedDocumentListByCategory; let i = index"
          (click)="documentChipSelectionChange(file)"
          class="DSA_wb_chipStyle-Normal DSA_wb_chip-input"
          [matTooltip]="
            uploadedDocumentStatus === 'SENT_BACK' &&
            selectedChipFileId !== file.id
              ? 'View comment'
              : ''
          "
          matTooltipClass="DSA_tooltip tool-tip"
          [ngClass]="{
            'sent-back': uploadedDocumentStatus === 'SENT_BACK',
            'selected-chip-sentback':
              selectedChipFileId === file.id &&
              uploadedDocumentStatus === 'SENT_BACK',
            'selected-chip':
              selectedChipFileId === file.id &&
              uploadedDocumentStatus !== 'SENT_BACK' &&
              isFileEditable
          }"
        >
          {{ file.fileName }}
          <a
            href="javascript:void(0);"
            *ngIf="
              (file.applicationFileStatus === 'PENDING' ||
                file.applicationFileStatus === 'UPLOADING' ||
                bankValidation) &&
              isFileEditable
            "
            class="icon x-16 icon-close-mini DSA_wb_chipIconRight"
            (click)="removeFile(file)"
            aria-label="remove chip"
          ></a>
        </mat-chip>
      </mat-chip-list>
    </div>

    <div>
      <mat-form-field
        class="DSA_Wb_custom-form-field padT8"
        *ngIf="
          sentBackComment &&
          !(
            uploadedDocumentStatus === 'PENDING' ||
            uploadedDocumentStatus === 'UPLOADING'
          )
        "
      >
        <mat-label>Reviewer Comments</mat-label>
        <textarea matInput disabled>{{ this.sentBackComment }}</textarea>
      </mat-form-field>
    </div>
  </div>
</div>
