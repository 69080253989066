<ngx-spinner name="refreshSpinner" bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="timer"
  [fullScreen]="true">
  <p class="spinner-loader-text">Refreshing...</p>
</ngx-spinner>
<div [hidden]="popupRespondentWindow.hidden" style="
    border-radius: 4px;
    background: white;
    padding: 12px;
    height: max-content;
    width: 324px;
    position: absolute;
    z-index: 10;
    box-shadow: rgb(226, 226, 226) 0px 0px 20px;
  " [style.left.px]="popupRespondentWindow.left" [style.top.px]="popupRespondentWindow.top">
  <jhi-respondant-details style="height: 200px; width: auto" [respondant]="popupRespondent">
  </jhi-respondant-details>
</div>
<div (click)="documentClick()">
  <div class="col-12 text-center px-0">
    <!-- <jhi-survey-navigation [noMobile]="true"
  [isDesignValidationRequired]='false' (designValidate) = "null"
  ></jhi-survey-navigation> -->
    <jhi-survey-heading class="survey-heading-section"> </jhi-survey-heading>
  </div>
  <div class="col-12">
    <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel">
      <mat-label>Select Survey Version </mat-label>
      <mat-select 
      disableOptionCentering  panelClass="DSA_wb-custom-select-panel" class="DSA_wb-custom-select"
      (selectionChange)="selectPublishedSurvey($event)" [(ngModel)]="currentPublishedSurveyId">
        <mat-option *ngFor="let publishedSurvey of publishedSurveys; let psi = index" [value]="publishedSurvey.id">
          Publish V{{ psi + 1 }} <span *ngIf="psi === 0">(Latest)</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-icon-button (click)="refresh()" matTooltip="Refresh">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
  <div class="col-12" *ngIf="currentSurvey !== null">
    <span class="div-insights">Insights</span>
  </div>
  <div class="col-12" [ngStyle]="getCss()">
    <!-- Total Response Card -->
    <div class="col-md-2 box-entity-desk shadow-sm p-3 mb-2 bg-white rounded"
      style="color: black; padding: 0px !important">
      <div class="col-12 box-header-desk" style="font-weight: bold; padding-top: 5px">
        Total Responses
      </div>
      <div class="col-12 box-description-desk">
        <div class="div-insights" style="font-size: 3rem">
          {{ totalLength || 0 }}
        </div>
      </div>
    </div>
    <!-- Completion Rate -->
    <div class="col-md-2 box-entity-desk shadow-sm p-3 mb-2 bg-white rounded"
      style="color: black; padding: 0px !important">
      <div class="col-12 box-header-desk" style="font-weight: bold; padding-top: 5px">
        Completion Rate
      </div>
      <div class="col-12 box-description-desk">
        <div class="div-insights" style="font-size: 3rem">100 %</div>
      </div>
    </div>
    <div class="col-md-2 box-entity-desk shadow-sm p-3 mb-2 bg-white rounded"
      style="color: black; padding: 0px !important">
      <div class="col-12 box-header-desk" style="font-weight: bold; padding-top: 5px">
        Export Responses
      </div>
      <div class="col-12 box-description-desk" style="overflow: hidden">
        <div class="div-insights" style="font-size: 3rem">
          <button mat-stroked-button class="full-width button-style-desk button-dark" (click)="downloadResponses()">
            Export as Excel
          </button>
        </div>
        <!-- <div class="button-padding">

            </div> -->
        <!-- <div class="button-padding">
              <button
                mat-stroked-button
                class="full-width button-style-desk button-primary"
                style="margin-top: 2%;"
                (click)="exportDataAsCsv()"
              >
                Export as CSV
              </button>
            </div> -->
      </div>
    </div>
    <div class="col-md-2 box-entity-desk shadow-sm p-3 mb-2 bg-white rounded"
      style="color: black; padding: 0px !important">
      <div class="col-12 box-header-desk" style="font-weight: bold; padding-top: 5px">
        Delete Responses
      </div>
      <div class="col-12 box-description-desk" style="overflow: hidden">
        <div class="div-insights" style="font-size: 3rem">
          <button mat-stroked-button class="full-width button-style-desk button-dark" (click)="deleteResponses()">
            Delete All Responses
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <span class="div-insights">Responses</span>
  </div>
  <div>
    <div style="margin-top: 30px; margin-left: 30px" [style.marginLeft]="isMobile() ? '10px' : '30px'"
      [style.width]="isMobile() ? '95%' : '70%'">
      <div style="margin: 10px" *ngFor="let response of surveyResponses; let responseIndex = index">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                response {{ currPaginationNumber * 5 + (responseIndex + 1) }}
              </mat-panel-title>
              <mat-panel-description style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                ">
                {{ getRespondent(response)
                }}<span *ngIf="isRespondent(response)">
                  <button mat-icon-button (click)="showRespondentDetails($event, response)">
                    <mat-icon fontSet="material-icons-outlined" style="cursor: pointer">
                      info
                    </mat-icon>
                  </button>
                </span>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-grid-list cols="1" rowHeight="40px">
              <div *ngFor="
                  let question of response.questionResponses;
                  let qrIndex = index
                ">
                <mat-grid-tile>
                  <div style="width: 100%; font-weight: bold">
                    {{ qrIndex + 1 }}.
                    {{ getQuestionText(question.questionId) }}
                  </div>
                </mat-grid-tile>
                <div *ngIf="getQuestionType(question.questionId) === 'File_upload'">
                  <mat-grid-tile>
                    <div style="width: 100%">
                      <div [innerHTML]="getFilesforDisplay(question)"></div>
                    </div>
                  </mat-grid-tile>
                </div>
                <div *ngIf="
                    getQuestionType(question.questionId) === 'Photo_upload'
                  ">
                  <mat-grid-tile rowspan="3">
                    <div style="width: 100%">
                      <img [src]="getPhotoforDisplay(question)" />
                    </div>
                  </mat-grid-tile>
                </div>
                <div *ngIf="getQuestionType(question.questionId) === 'Map_Input'">
                  <mat-grid-tile>
                    <div style="width: 100%">
                      <a [href]="getMapLink(question)" matTooltip="open Map" target="_blank">Open Location Map</a>
                    </div>
                  </mat-grid-tile>
                </div>
                <div *ngIf="
                    getQuestionType(question.questionId) === 'Multi_textbox'
                  ">
                  <mat-grid-tile [rowspan]="
                      question.answer.length % 2 === 0
                        ? (question.answer.length + 2) / 2
                        : (question.answer.length + 3) / 2
                    ">
                    <div style="width: 100%">
                      <table style="width: 100%">
                        <tr *ngFor="
                            let questionOption of getQuestionOptions(question);
                            let qoi = index
                          ">
                          <td style="font-weight: 100; padding: 5px; width: 50%">
                            {{ questionOption.value }}
                          </td>
                          <td>{{ question.answer[qoi] }}</td>
                        </tr>
                      </table>
                    </div>
                  </mat-grid-tile>
                </div>
                <div *ngIf="
                    getQuestionType(question.questionId) === 'Contact_Info'
                  ">
                  <mat-grid-tile rowspan="7">
                    <div style="width: 100%">
                      <table style="width: 100%">
                        <tr *ngFor="
                            let contactOption of getContactOptions(question);
                            let qoi = index
                          ">
                          <td style="font-weight: 100; padding: 5px; width: 50%">
                            {{ contactOption.label }}
                          </td>
                          <td>{{ contactOption.value }}</td>
                        </tr>
                      </table>
                    </div>
                  </mat-grid-tile>
                </div>
                <div *ngIf="getQuestionType(question.questionId) === 'Ranking'">
                  <mat-grid-tile [rowspan]="
                      question.answer.length % 2 === 0
                        ? question.answer.length / 2
                        : (question.answer.length - 1) / 2
                    ">
                    <div style="width: 100%; height: 100%">
                      <table style="width: 100%">
                        <tr *ngFor="
                            let questionOption of getQuestionOptions(question);
                            let qoi = index
                          ">
                          <td style="font-weight: 100; padding: 5px; width: 50%">
                            No. {{ qoi + 1 }}
                          </td>
                          <td>{{ question.answer[qoi] }}</td>
                        </tr>
                      </table>
                    </div>
                  </mat-grid-tile>
                </div>
                <div *ngIf="
                    getQuestionType(question.questionId) !== 'File_upload' &&
                    getQuestionType(question.questionId) !== 'Photo_upload' &&
                    getQuestionType(question.questionId) !== 'Map_Input' &&
                    getQuestionType(question.questionId) !== 'Multi_textbox' &&
                    getQuestionType(question.questionId) !== 'Contact_Info' &&
                    getQuestionType(question.questionId) !== 'Ranking'
                  ">
                  <mat-grid-tile>
                    <div style="width: 100%">
                      {{ question.answer.join() }}
                    </div>
                  </mat-grid-tile>
                </div>
              </div>
            </mat-grid-list>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <mat-paginator [length]="totalLength" *ngIf="surveyResponses.length > 0" [pageSize]="5" [pageSizeOptions]="[5]"
      (page)="pageChange($event)" style="margin-bottom: 40px">
      <div style="height: 40px"></div>
      <div style="height: 40px"></div>
    </mat-paginator>
  </div>
</div>