import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export interface DateFormat {
  totalScore?: number;
  [prop: string]: string | number | string[] | number[];
}

export interface SheetDetails {
  pageName: string;
  data: DateFormat[];
}

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';

  constructor() {}

  public exportExcel(SheetDetails: SheetDetails[], fileName: string): void {
    const sheets = {};
    const sheetnames = [];
    SheetDetails.forEach(element => {
      // const data = [];
      sheets[element.pageName] = XLSX.utils.json_to_sheet(element.data);
      sheetnames.push(element.pageName);
    });
    // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: sheets, SheetNames: sheetnames };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }
}
