import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SharedService } from './shared.service';
import * as fileSaver from 'file-saver';
import { ResumeUploadService } from '../modules/candidate/services/resume-upload.service';
import { CandidateProfileService } from '../modules/candidate/candidate-profile/candidate-profile.service';
@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private http: HttpClient, public sharedService: SharedService,private fileService: ResumeUploadService,private candidateProfileService: CandidateProfileService,) {}

  // private header = new HttpHeaders().set(
  //   'Authorization',
  //   'Bearer ' + localStorage.getItem('token')
  // );

  // ! not used
  docBlob;
  saveFile = (blobContent: Blob, fileName: string) => {
    const blob = new Blob([blobContent], { type: 'application/octet-stream' });
    // saveAs(blob, fileName);
  };

  getFile = (fileId) => {
    return this.http.get(
      environment.fileServiceUrl + 'api/file/download/' + fileId,
      {
        // headers: this.header,
        observe: 'response',
        responseType: 'blob',
      }
    );
  };

  uploadSnapshot = (formData) => {
    return this.http.post(
      environment.fileServiceUrl + 'api/file/upload/?doc_type=INTERVIEW_SNAP',
      formData
      // {
      //   headers: this.header,
      // }
    );
  };

  uploadSnapshotBulk = (formData) => {
    return this.http.post(
      environment.fileServiceUrl +
        'api/file/bulkUpload/?doc_type=INTERVIEW_SNAP',
      formData
      // {
      //   headers: this.header,
      // }
    );
  };

  getSnapshot = (fileId) => {
    return this.http.get(
      environment.interviewServiceUrl +
        'api/interview-snapshots/' +
        fileId +
        '/file',
      {
        // headers: this.header,
        responseType: 'blob',
      }
    );
  };

  bulkUpload = (data) => {
    return this.http.post(
      environment.fileServiceUrl +
        'api/file/upload/?doc_type=BULK_UPLOAD_INTERVIEW',
      data
    );
  };

  bulkUploadPanelist = (data) => {
    return this.http.post(
      environment.fileServiceUrl +
        'api/file/upload/?doc_type=BULK_UPLOAD_PANELIST',
      data
    );
  };

  bulkUploadDataMigration = (data) => {
    return this.http.post(
      environment.fileServiceUrl +
        'api/file/upload/?doc_type=BULK_UPLOAD_CANDIDATE_DATA',
      data
    );
  };

  bulkUploadPanelistSlots = (data) => {
    return this.http.post(
      environment.fileServiceUrl +
        'api/file/upload/?doc_type=BULK_UPLOAD_PANELIST_SLOT',
      data
    );
  };

  bulkUploadUserWithRoles = (data) => {
    return this.http.post(
      environment.fileServiceUrl +
        'api/file/upload/?doc_type=BULK_UPLOAD_USER_ROLE_INFO',
      data
    );
  };
  async uploadFile(storage, file, fileName, dir, evt, url, options) {
    let formData = new FormData();
    let FileData = {
      name: file?.name,
      size: file?.size,
      type: file?.type,
      fileId:null
    };
    let applicationId;
    console.log(localStorage.getItem('applicationId'))
    if(localStorage.getItem('applicationId')!==null){
      applicationId=parseInt(localStorage.getItem('applicationId'));
    }else{
        applicationId =this.candidateProfileService.applicationId;
    }
    if(!applicationId){
      return;
    }

    const specData = {
      applicationId: applicationId,
      comment: '',
      tenantId: parseInt(localStorage.getItem('tenantId')),
      fileCategory: url
    };
    formData.append('specData', JSON.stringify(specData));
    formData.append('file', file);

    let dataUrl =
      environment.POSTMS_API +
      '/application-files/application/' + applicationId;
    try {
      let result= await this.http.post(dataUrl, formData).toPromise();
      FileData.fileId=result['id'];
      this.sharedService.openSuccessSnackBar('Uploaded Successfully', '');
      return FileData;
    } catch (error) {
      this.sharedService.openErrorSnackBar('Upload Failed', '');
    }
  }
  async downloadFile(fileInfo, options?) {
    let dataUrl= environment.POSTMS_API +'/application-files/'+fileInfo.fileId;
    let result = await this.http.get(dataUrl).toPromise();
    // fileId
    this.fileService.getFile(result['fileId']).subscribe({
      next: (res) => {
        const fileName =result['fileId'] + '.pdf';
        let blob: any = new Blob([res.body], {
          type: 'application/octet-stream; charset=utf-8',
        });
        this.docBlob = blob;
        const fileNameValues = fileName.split('.');
        if (fileNameValues[fileNameValues.length-1] === 'pdf' || fileNameValues[fileNameValues.length-1] === 'PDF') {
          const blobFile = new File([this.docBlob], fileName, {
            type: 'application/pdf',
          });
          let fileURL = URL.createObjectURL(blobFile);
          window.open(fileURL);
        } else {
          fileSaver.saveAs(this.docBlob, fileName);
        }
      },
      error: (err) => {
        this.sharedService.openErrorSnackBar(
          'Error occured. Please try again',
          ''
        );
      },
    });
  
  }
  async deleteFile(fileInfo) {
    //do something
    let dataUrl= environment.POSTMS_API +'/application-files/'+fileInfo.fileId;
    try{
      let result = await this.http.delete(dataUrl).toPromise();
    } catch(error){

   }
  }

  bulkUploadTransactionDetails = (data) => {

    
  //   let d = this.http.post(
  //     environment.fileServiceUrl +
  //       'api/file/upload/?doc_type=BULK_UPLOAD_SCHOLARSHIP_TRANSACTION',
  //     data
  //   );
  //  console.log( d.subscribe((res)=>{console.log(res)}));
      return this.http.post(
      environment.fileServiceUrl +
        'api/file/upload/?doc_type=BULK_UPLOAD_SCHOLARSHIP_TRANSACTION',
      data
    );
  }
}
