import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormControl,
  Validators,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs/operators';
import * as moment from 'moment';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ISelectedCandidate } from 'src/app/shared/model/candidate.model';
import { SharedService } from 'src/app/services/shared.service';
import { CandidateService } from 'src/app/services/candidate.service';
import { EventService } from 'src/app/services/event.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import { JobService } from 'src/app/services/job.service';
import { InterviewService } from 'src/app/services/interview.service';
import { MsteamsService } from 'src/app/services/msteams.service';
import { environment } from '../../../../environments/environment';
import { IEventPanelistToAdd } from 'src/app/shared/model/event-panelist.model';
import { InterviewPlatformEnum } from 'src/app/shared/model/interview-platform.model';
import { EventTypeEnum } from 'src/app/shared/model/event.model';
import { InterviewerService } from 'src/app/services/interviewer.service';

interface CandidateJobsInEvent {
  jobId: number;
  cirId: number;
  frozen: boolean;
  applicationId: string;
  subUnit: string;
  technology: string;
  unit: string;
}

export interface slotDTO {
  startTime: Date;
  endTime: Date;
}
export interface panelistSlotDTO {
  interviewerId: number;
  emailId: string;
  slotDTO: slotDTO[];
}

@Component({
  selector: 'app-infy-schedule-page',
  templateUrl: './infy-schedule-page.component.html',
  styleUrls: ['./infy-schedule-page.component.scss'],
})
export class InfySchedulePageComponent implements OnInit, OnDestroy {
  @ViewChild('interviewerInput') interviewerInput: ElementRef<HTMLInputElement>;
  interviewPlatforms = Object.values(InterviewPlatformEnum);
  // selectable = true;
  // removable = true;
  panelistAvailabilityLoader: any = false;
  searchControl = new FormControl();
  panelistSlotDTO: panelistSlotDTO[] = [];
  // filteredInterviewers: Observable<any[]>;
  interviewerArray: IEventPanelistToAdd[] = [];
  slotTableArray: any = [];
  time: NgbTimeStruct = { hour: 13, minute: 30, second: 0 };
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  myControl = new FormControl('');
  fromTime: NgbTimeStruct = { hour: 13, minute: 30, second: 0 };
  toTime: NgbTimeStruct = { hour: 14, minute: 0, second: 0 };
  selectedJob: any;
  documentFormGroup: FormGroup;
  interviewerFormGroup: FormGroup;
  selectedEvent: any;
  loader = false;
  error = false;

  dateTimeRangePickerOptions = {
    timePicker: true,
    timePicker24Hour: true,
    timePickerSeconds: false,
    // same as minute gap
    timePickerIncrement: 15,

    showCancel: true,
    showClearButton: true,
    showDropdowns: true,
    autoApply: true,
    closeOnAutoApply: false,
    linkedCalendars: true,

    locale: {
      format: 'YYYY-MM-DDTHH:mm:ss.SSSSZ',
      displayFormat: 'DD MMMM YYYY HH:mm',
      separator: ' To ',
      cancelLabel: 'Cancel',
      applyLabel: 'Okay',
    },
    localeOffline: {
      format: 'MM/DD/YYYY',
      displayFormat: 'MM/DD/YYYY',
      cancelLabel: 'Cancel',
      applyLabel: 'Okay',
    },

    // minDate: moment(new Date()),
    showWeekNumbers: true,
    opens: 'center',
    drops: 'up',
  };
  showPlatformURL = false;
  // interviewersList = [];
  selectedCandidate: ISelectedCandidate;
  selectedInterviewScheduel: any;

  candidateId: any;
  candidateInterviewRoundId: number;
  candidateDetail: any;
  eventId: any;
  jobsInEvent: any = [];
  candidateJobsInEvent: CandidateJobsInEvent[];

  editMode: boolean;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private candidateService: CandidateService,
    private eventService: EventService,
    private scheduleService: ScheduleService,
    private jobService: JobService,
    private interviewService: InterviewService,
    private msteamsService: MsteamsService,
    private interviewerService: InterviewerService
  ) {
    // this.filteredInterviewers = this.searchControl.valueChanges.pipe(
    //   // tslint:disable-next-line: deprecation
    //   startWith(null),
    //   map((interview: string | null) =>
    //     interview ? this._filter(interview) : this.interviewersList.slice()
    //   )
    // );
  }
  ngOnDestroy(): void {
    this.interviewService.setEditInterviewMode(false);
  }

  ngOnInit(): void {
    this.loader = true;

    let appState = JSON.parse(window.sessionStorage.getItem('appState'));
    if (appState && appState.authStatus === 'AUTHENTICATED') {
      if (appState.redirectTo === this.router.url) {
        let candidateDetails = appState.candidateDetails;
        this.eventService.selectedEventId = appState.eventId;
        let formData = appState.formData;
        if (appState.scheduleType === 'schedule') {
          window.sessionStorage.removeItem('appState');
          this.scheduleInterview(formData, candidateDetails);
        } else if (appState.scheduleType === 'reschedule') {
          window.sessionStorage.removeItem('appState');
          this.reScheduleInterview(formData, candidateDetails);
        }
      }
    } else {
      this.selectedCandidate = this.candidateService.getSelectedCandidate();
      if (!this.selectedCandidate) this.router.navigate(['/dashboard']);
      this.candidateId = this.selectedCandidate.candidateId;
      this.candidateInterviewRoundId = this.selectedCandidate.cirId;
      // this.candidateId = this.candidateService.selectedCandidate.candidateId;
      this.eventId = this.eventService.selectedEventId;

      this.selectedInterviewScheduel = this.scheduleService.getSelectedInterviewSchedule();

      if (
        this.selectedInterviewScheduel &&
        this.interviewService.getEditInterviewMode()
      ) {
        this.editMode = true;
      }
      this.selectedJob = this.jobService.getSelectedJob();
      const getAllJobsByEvent = this.jobService.getAllJobsByEvent(
        this.eventId,
        0,
        100
      );
      const candidateJobsInEvent = this.candidateService.getCandidateJobsInEvent(
        this.eventId,
        this.candidateId
      );
      forkJoin([
        // getEventById,
        // getEventPanelists,
        getAllJobsByEvent,
        candidateJobsInEvent,
      ]).subscribe(
        (res: any) => {
          // this.selectedEvent = res[0];
          // this.interviewersList = res[1].content;
          this.jobsInEvent = res[0].content?.map((job) => {
            return { id: job.id, title: job.title };
          });
          this.candidateJobsInEvent = res[1]?.map((job) => {
            return {
              jobId: job.jobOpeningId,
              cirId: job.id,
              frozen: job.frozen,
              applicationId: job.candidateApplicationDetails?.applicationId,
              unit: job.candidateApplicationDetails?.unit,
              subUnit: job.candidateApplicationDetails?.subUnit,
              technology: job.candidateApplicationDetails?.technology,
            };
          });
          this.loader = false;
        },
        (err) => {
          this.error = true;
          this.loader = false;
        }
      );

      this.createDocumentFormGroup();
      this.setFormInitialValues();

      if (this.selectedInterviewScheduel?.source.title) {
        this.modifyPlatform(this.selectedInterviewScheduel?.source.title);
      }

      if (this.selectedInterviewScheduel?.panelists) {
        this.interviewerArray = this.selectedInterviewScheduel.panelists.map(
          (a) => {
            const object = {
              panelistId: a.interviewerId,
              panelistEmail: a.emailId,
            };
            return object;
          }
        );
      } else {
        this.interviewerArray = [];
      }

      this.platformIdChangeSubscription();
      this.jobOpeningIdChangeSubscription();
    }
  }

  handlePanelistChange = (panelists: IEventPanelistToAdd[]) => {
    this.interviewerArray = panelists;
    console.log(this.interviewerArray);
    this.getPanelistAvailableSlots();
    this.documentFormGroup.patchValue({
      interviewer: this.interviewerArray,
    });
  };

  getPanelistAvailableSlots = () => {
    this.panelistSlotDTO = [];
    this.slotTableArray = [];
    this.panelistAvailabilityLoader = true;
    if (this.interviewerArray.length > 0) {
      for (let panelists of this.interviewerArray) {
        this.panelistSlotDTO.push({
          interviewerId: panelists.panelistId,
          emailId: panelists.panelistEmail,
          slotDTO: [
            {
              startTime: new Date(
                this.documentFormGroup.value.panelistDateSlot
              ),
              endTime: null,
            },
          ],
        });
      }
      const panelistSlotForm = {
        panelistSlotDTO: this.panelistSlotDTO,
        eventId: this.eventId,
      };
      console.log(panelistSlotForm);

      this.interviewerService
        .getInterviewerAvailability(panelistSlotForm)
        .subscribe({
          next: (res) => {
            this.slotTableArray = res;
            this.panelistAvailabilityLoader = false;
            console.log(this.slotTableArray);
          },
          error: (err) => {
            this.panelistAvailabilityLoader = false;
          },
        });
    } else {
      this.panelistAvailabilityLoader = false;
    }
  };

  dateChange(event, slotDateChange): void {
    console.log(event);

    this.documentFormGroup.patchValue({
      date: event,
    });
    if (slotDateChange) {
      this.getPanelistAvailableSlots();
    }
  }
  createDocumentFormGroup() {
    this.documentFormGroup = this.formBuilder.group({
      platFormId: [{ value: '', disabled: this.editMode }, Validators.required],
      title: [
        {
          value: '',
          disabled: this.selectedInterviewScheduel && !this.editMode,
        },
        Validators.required,
      ],
      roundType: [
        {
          value: '',
          disabled: this.selectedInterviewScheduel && !this.editMode,
        },
        Validators.required,
      ],
      panelistDateSlot: [{ value: '' }],
      date: [{ value: '', disabled: this.editMode }, Validators.required],
      fromTime: [{ value: '' }, Validators.required],
      toTime: [{ value: '' }, Validators.required],
      interviewer: [{ value: '', disabled: this.editMode }],

      platFormURL: [{ value: '', disabled: this.editMode }],
      jobOpeningId: [
        { value: '', disabled: this.selectedInterviewScheduel },
        Validators.required,
      ],
    });
  }

  setFormInitialValues() {
    let formData = {
      platFormId: '',
      roundType: 'TECHNICAL',
      title: '',
      interviewer: '',
      time: this.time,
      panelistDateSlot: this.getStartDateTime().startDate,
      date: this.getStartDateTime(),
      platFormURL: '',
      jobOpeningId: this.selectedCandidate.jobId
        ? this.selectedCandidate.jobId
        : this.selectedJob?.id,
    };
    console.log(formData.panelistDateSlot);
    if (this.selectedInterviewScheduel) {
      formData.platFormId = this.selectedInterviewScheduel.source.title;
      formData.roundType = this.selectedInterviewScheduel.roundType;
      formData.title = this.selectedInterviewScheduel.title;
      formData.interviewer = this.selectedInterviewScheduel.panelists;
      formData.date = {
        startDate: moment(
          new Date(this.selectedInterviewScheduel.interviewSchedule.startDate)
        ),
        endDate:
          this.showInterviewer === false
            ? ''
            : moment(
                new Date(
                  this.selectedInterviewScheduel.interviewSchedule.endDate
                )
              ),
      };
      formData.platFormURL = this.selectedInterviewScheduel.source.link;
    }

    this.documentFormGroup.patchValue(formData);
    console.log(this.documentFormGroup.value.panelistDateSlot);
  }

  platformIdChangeSubscription() {
    this.documentFormGroup.controls.platFormId.valueChanges.subscribe(
      (platform) => {
        if (platform === 'TELEPHONIC' || platform === 'OFFLINE') {
          this.documentFormGroup.controls.platFormURL.setValue('');
        }
        this.modifyPlatform(platform);
        this.documentFormGroup.controls.platFormURL.updateValueAndValidity();
      }
    );
  }

  jobOpeningIdChangeSubscription() {
    this.documentFormGroup.controls.jobOpeningId.valueChanges.subscribe(
      (jobId) => {
        let candidateJob = this.candidateJobsInEvent.find(
          (element) => element.jobId === jobId
        );
        if (candidateJob) {
          // set cirId here
          this.candidateInterviewRoundId = candidateJob.cirId;
          this.selectedCandidate = {
            ...this.selectedCandidate,
            ...candidateJob,
          };
        } else {
          // call api to generate cirIdhere
          this.loader = true;
          this.candidateService
            .createCirIdForCandidateInJob({
              candidateId: this.candidateId,
              jobOpeningId: jobId,
            })
            .subscribe((res) => {
              this.loader = false;
              let updatedDetails = {
                jobId: res.jobOpeningId,
                cirId: res.id,
                frozen: res.frozen,
                applicationId: res.candidateApplicationDetails?.applicationId,
                unit: res.candidateApplicationDetails?.unit,
                subUnit: res.candidateApplicationDetails?.subUnit,
                technology: res.candidateApplicationDetails?.technology,
              };
              this.candidateJobsInEvent.push(updatedDetails);
              this.candidateInterviewRoundId = res.id;
              this.selectedCandidate = {
                ...this.selectedCandidate,
                ...updatedDetails,
              };
            });
        }
      }
    );
  }

  removeParent() {}

  // add(event: MatChipInputEvent): void {
  //   const input = event.input;
  //   const value = event.value;
  //   if ((value || '').trim()) {
  //     this.interviewerArray.push(value.trim());
  //     this.documentFormGroup.patchValue({
  //       interviewer: this.interviewerArray,
  //     });
  //     if (this.searchControl.hasError('error')) {
  //       this.searchControl.setErrors({ error: null });
  //     }
  //   }

  //   // Reset the input value
  //   if (input) {
  //     input.value = '';
  //   }

  //   this.searchControl.setValue(null);
  // }

  // remove(data: string): void {
  //   const index = this.interviewerArray.indexOf(data);

  //   if (index >= 0) {
  //     this.interviewerArray.splice(index, 1);
  //     this.documentFormGroup.patchValue({
  //       interviewer: this.interviewerArray,
  //     });
  //   }

  //   if (index === 0) {
  //     this.searchControl.setErrors({ error: true });
  //   }
  // }

  // selected(event: MatAutocompleteSelectedEvent): void {
  //   this.interviewerArray.push(event.option.value);
  //   this.interviewerInput.nativeElement.value = '';
  //   this.searchControl.setValue(null);
  // }

  // private _filter(value: string): any[] {
  //   const filterValue = value?.toString()?.toLowerCase();
  //   return this.interviewersList.filter(
  //     (a) =>
  //       Object.values(a).toString().toLowerCase().indexOf(filterValue) !== -1
  //   ) as any[];
  // }

  isInterviewerInputReqd = (): boolean => {
    return this.documentFormGroup.controls.platFormId.value !== 'FACE_TO_FACE';
  };

  showInterviewer: boolean = true;
  modifyPlatform(data: InterviewPlatformEnum) {
    if (['FACE_TO_FACE', 'OFFLINE'].includes(data)) {
      this.documentFormGroup.controls.interviewer.setValidators([]);
    } else {
      this.documentFormGroup.controls.interviewer.setValidators([
        Validators.required,
      ]);
    }

    if (data === 'WEBEX') {
      this.showPlatformURL = true;
      this.showInterviewer = true;

      this.documentFormGroup.controls.platFormURL.setValidators([
        Validators.required,
      ]);
    } else if (data === 'OFFLINE') {
      this.showPlatformURL = false;
      this.showInterviewer = false;
      this.documentFormGroup.controls.platFormURL.setValidators([]);
    } else {
      this.showPlatformURL = false;
      this.showInterviewer = true;
      this.documentFormGroup.controls.platFormURL.setValidators([]);
    }
  }
  getStartDateTime(): any {
    if (this.showInterviewer) {
      const currDate = new Date();
      console.log(currDate);
      const minute = currDate.getMinutes();
      console.log(minute);
      const minuteGap = 15; // must be less than 60
      const minuteToAdd = minuteGap - (minute % minuteGap);
      console.log(minuteToAdd);
      currDate.setMinutes(minute + minuteToAdd);
      console.log(currDate);

      console.log(this.documentFormGroup.value.panelistDateSlot);
      return {
        startDate: moment(currDate),
        endDate: moment(currDate.setHours(currDate.getHours() + 1)),
      };
    } else {
      const currDate = new Date();
      const minute = currDate.getMinutes();
      const minuteGap = 15; // must be less than 60
      const minuteToAdd = minuteGap - (minute % minuteGap);
      currDate.setMinutes(minute + minuteToAdd);
      return {
        startDate: moment(currDate),
        endDate: null,
      };
    }
  }

  edit() {
    this.loader = true;
    if (this.documentFormGroup.valid) {
      const data = {
        id: this.selectedInterviewScheduel.id,
        title: this.documentFormGroup.value.title,
        roundType: this.documentFormGroup.value.roundType,
      };

      this.interviewService.editInterview(data).subscribe(
        (res) => {
          this.sharedService.openSnackBar('Interview Edited Sucessfully', 'Ok');
          this.router.navigate(['candidate-interview-rounds']);
        },
        () => {},
        () => {
          this.loader = false;
        }
      );
    }
  }

  submit() {
    if (this.selectedInterviewScheduel) {
      this.reschedule();
    } else {
      this.schedule();
    }
  }

  findInvalidControls() {
    const invalid = [];
    const controls = this.documentFormGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
  }

  reschedule() {
    this.documentFormGroup.patchValue({
      interviewer: this.interviewerArray.map((a) => a.panelistId),
    });

    if (this.checkValidatePanelist()) {
      this.loader = true;
      // this.findInvalidControls();

      if (this.documentFormGroup.valid) {
        const startDate = new Date(this.documentFormGroup.value.date.startDate);
        const endDate = new Date(this.documentFormGroup.value.date.endDate);
        const data = {
          interviewScheduleId: this.selectedInterviewScheduel?.interviewSchedule
            ?.id,
          sourceId: this.selectedInterviewScheduel?.source?.id,
          jobOpeningId: this.selectedInterviewScheduel?.jobOpening?.id,
          interviewId: this.selectedInterviewScheduel?.id,
          candidateInterviewRoundsId: this.candidateInterviewRoundId,
          interviewTitle: this.documentFormGroup.value.title
            ? this.documentFormGroup.value.title
            : this.selectedInterviewScheduel.title,
          startDate: startDate,
          endDate: endDate,
          interviewers: this.documentFormGroup.value.interviewer,
          sourceTitle: this.documentFormGroup.value.platFormId,
          link: this.documentFormGroup.value.platFormURL,
          roundType: this.documentFormGroup.value.roundType
            ? this.documentFormGroup.value.roundType
            : this.selectedInterviewScheduel.roundType,
        };
        if (data.sourceTitle === 'TEAMS') {
          this.msTeamsAuthentication(data, 'reschedule');
        } else {
          this.reScheduleInterview(data, this.selectedCandidate);
        }
      } else {
        // if (this.interviewerArray.length === 0) {
        //   this.searchControl.setErrors({ error: true });
        // }
        this.documentFormGroup.updateValueAndValidity();
        this.loader = false;
      }
    }
  }

  checkValidatePanelist(): boolean {
    if (
      this.documentFormGroup.value.interviewer.length == 0 &&
      this.documentFormGroup.value.platFormId !== 'FACE_TO_FACE' &&
      this.showInterviewer === true
    ) {
      // this.searchControl.setErrors({ error: true });
      this.sharedService.openSnackBar(
        'Please select valid email from interviewer dropdown',
        'X',
        2000
      );
      return false;
    }

    for (let element of this.documentFormGroup.value.interviewer) {
      if (element == null || element == undefined) {
        // this.searchControl.setErrors({ error: true });
        this.sharedService.openSnackBar(
          'Please select valid email from interviewer dropdown',
          'X',
          2000
        );
        return false;
      }
    }
    return true;
  }

  schedule() {
    this.documentFormGroup.patchValue({
      interviewer: this.interviewerArray.map((a) => a.panelistId),
    });

    if (this.checkValidatePanelist()) {
      this.loader = true;
      if (this.documentFormGroup.valid) {
        const startDate = new Date(this.documentFormGroup.value.date.startDate);
        const endDate = new Date(this.documentFormGroup.value.date.endDate);
        const data = {
          jobOpeningId: this.documentFormGroup.value.jobOpeningId,
          candidateInterviewRoundsId: this.candidateInterviewRoundId,
          interviewTitle: this.documentFormGroup.value.title,
          startDate: startDate,
          endDate: this.showInterviewer === false ? '' : endDate,
          interviewers:
            this.showInterviewer === false
              ? []
              : this.documentFormGroup.value.interviewer,
          sourceTitle: this.documentFormGroup.value.platFormId,
          link:
            this.showInterviewer === false
              ? ''
              : this.documentFormGroup.value.platFormURL,
          roundType: this.documentFormGroup.value.roundType,
        };
        if (data.sourceTitle === 'TEAMS') {
          this.msTeamsAuthentication(data, 'schedule');
        } else {
          this.scheduleInterview(data, this.selectedCandidate);
        }
      } else {
        // if (this.interviewerArray.length === 0) {
        //   this.searchControl.setErrors({ error: true });
        // }
        this.documentFormGroup.updateValueAndValidity();
        this.loader = false;
      }
    }
  }

  scheduleInterview(data, selectedCandidate) {
    this.scheduleService.scheduleEvent(data).subscribe(
      (res) => {
        this.sharedService.openSnackBar(
          'Interview Scheduled Sucessfully',
          'Ok'
        );

        this.candidateService.setSelectedCandidate(selectedCandidate);
        this.router.navigate(['candidate-interview-rounds']);
      },
      (err) => {
        this.loader = false;
        this.sharedService.openSnackBar(
          'Interview Schedule Failed. Please try again.',
          'Ok'
        );
      }
    );
  }

  reScheduleInterview(formData, selectedCandidate) {
    this.scheduleService.reScheduleEvent(formData).subscribe(
      (res) => {
        this.sharedService.openSnackBar(
          'Interview Rescheduled Sucessfully',
          'Ok'
        );
        this.candidateService.setSelectedCandidate(selectedCandidate);
        this.router.navigate(['candidate-interview-rounds']);
      },
      () => {
        this.loader = false;
        this.sharedService.openSnackBar(
          'Interview Reschedule Failed. Please try again.',
          'Ok'
        );
      }
    );
  }

  msTeamsAuthentication(formData, scheduleType) {
    // check if auth required or not
    this.msteamsService.checkAuthorizationRequired().subscribe((res) => {
      // if yes - store form in state and proceed with authorization
      // let res = this.msteamsService.checkAuthorizationRequired();
      if (res.requiresAuthorization) {
        let redirect_uri = environment.msauthRedirectUrl;
        let sessionStorageObj = {
          redirectTo: this.router.url,
          scheduleType: scheduleType,
          formData: formData,
          eventId: this.eventId,
          candidateDetails: this.selectedCandidate,
        };
        window.sessionStorage.setItem(
          'appState',
          JSON.stringify(sessionStorageObj)
        );
        window.open(redirect_uri, '_self');
      } else {
        // if no
        // proceed as usual
        if (scheduleType === 'schedule')
          this.scheduleInterview(formData, this.selectedCandidate);
        else if (scheduleType === 'reschedule')
          this.reScheduleInterview(formData, this.selectedCandidate);
      }
    });
  }
}
