<app-loader [display]="loading"></app-loader>

<div class="container-fluid layout" *ngIf="!loading">
  <div
    *ngIf="
    eventStatus !== 'CLOSED'
    "
    class="row mt-2"
  >
    <div 
    *ngIf = "accountService.hasFeatureAccess(FeatureName.EVENT_PANELIST, PermissionType.CREATE)
       && accountService.hasFeatureAccess(FeatureName.BULK_UPLOAD_PANELISTS, PermissionType.CREATE)"
      class="col-2 nopadR">
      <h2 class="DSA_wb_h2Header web-GreyDarkest padT6">
        Bulk Upload Panelist
      </h2>
    </div>
    <div class="col-2">
      <div
      *ngIf = "accountService.hasFeatureAccess(FeatureName.EVENT_PANELIST, PermissionType.CREATE)
       && accountService.hasFeatureAccess(FeatureName.BULK_UPLOAD_PANELISTS, PermissionType.CREATE)"
       class="col-12 nopadLR">
        <input
          type="file"
          (change)="bulkUpload(bulkUploadElement.files)"
          #bulkUploadElement
          accept=".csv"
          style="display: none"
        />
        <button
          (click)="bulkUploadElement.click()"
          mat-flat-button
          class="DSA_wb_Icon-btn small-screen"
        >
          <span class="icon_btnCont"
            ><i class="icon purple x-16 icon-add"></i
          ></span>
          Add Multiple Panelists
        </button>
        <button
          *ngIf="
            bulkuploadFile
          "
          (click)="uploadBulkCSV()"
          class="ml-2 custom-button"
        >
          Upload
        </button>
      </div>
      <div class="col-12 nopadLR" *ngIf="bulkuploadFile">
        <p class="mat-body">
          <strong>Selected File:</strong> {{ bulkuploadFile.name }}
        </p>
      </div>
    </div>
    <div class="col-2">
      <div class="col-12 nopadLR">
        <input
          type="file"
          (change)="bulkUploadPanelistSlot(bulkUploadSlotsElement.files)"
          #bulkUploadSlotsElement
          accept=".csv"
          style="display: none"
        />
        <button
          (click)="bulkUploadSlotsElement.click()"
          mat-flat-button
          class="DSA_wb_Icon-btn"
          *ngIf="
            lateralDynamic &&
            (accountService.hasFeatureAccess(FeatureName.BULK_UPLOAD_PANELISTS_SLOT, PermissionType.CREATE))
          "
        >
          <span class="icon_btnCont"
            ><i class="icon purple x-16 icon-add"></i
          ></span>
          Add Panelists Slots
        </button>
        <button
          *ngIf="
            bulkUploadSlots
          "
          (click)="uploadSlotsCSV()"
          class="ml-2 custom-button"
        >
          Upload
        </button>
      </div>
      <div class="col-12 nopadLR" *ngIf="bulkUploadSlots">
        <p class="mat-body">
          <strong>Selected File:</strong> {{ bulkUploadSlots.name }}
        </p>
      </div>
    </div>

    <div class="col-6 d-flex justify-content-end">
      <button
        *ngIf = "accountService.hasFeatureAccess(FeatureName.EVENT_PANELIST, PermissionType.CREATE)
        && eventStatus !== 'CLOSED'
        "
        mat-flat-button
        class="DSA_wb_Icon-btn"
        (click)="openAddPanelistDialog()"
      >
        <span class="icon_btnCont">
          <i class="icon purple x-16 icon-add"></i>
        </span>
        Add Panelist
      </button>
    </div>
  </div>
  <data-grid
    (searchKey)="search($event)"
    [columnDefs]="columnDefs"
    [tableName]="tableName"
    [buttonText]="buttonText"
    [levels]="levels"
    [searchCondition]="searchCondition"
    [interface]="interface"
    [refreshAgGrid]="refreshAgGrid.asObservable()"
  ></data-grid>
</div>
