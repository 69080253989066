import { Injectable } from '@angular/core';
import { FILE_SERVER_URL } from 'src/app/app.constants';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// const FILE_SERVER_URL = "http://34.93.13.147:4900/api/fileserver/uploadFile/"

@Injectable({
  providedIn: 'root',
})
export class FileserverService {
  constructor(protected http: HttpClient) {}
  getImageUrl(name: string) {
    return this.getFileURL(name);
  }

  saveFile(file: File, subdir = 'theme'): Observable<HttpResponse<any>> {
    const formData = new FormData();
    formData.append('file', file);
    const URL = FILE_SERVER_URL + 'uploadFile/' + subdir;
    return this.http
      .post<any>(URL, formData, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res.body));
  }

  getFileURL(fileName: string, subdir = 'theme'): string {
    return FILE_SERVER_URL + 'downloadFile/' + subdir + '/' + fileName;
  }

  getFileURLbyPath(path: string): string {
    return FILE_SERVER_URL + 'downloadFile/' + path;
  }
}
