import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { CandidateService } from 'src/app/services/candidate.service';
import { JobService } from 'src/app/services/job.service';
@Component({
  template: `<a
    class="DSA_wb_hyperLink-btn"
    style="text-decoration: none;"
    href="javascript:void(0)"
    (click)="onClick()"
    matTooltip="View Candidates"
    matTooltipClass="DSA_tooltip"
    >{{ value }}</a
  >`,
})
// <a
// mat-tab-link
// *ngFor="let link of links"
// [routerLink]="link.link"
// routerLinkActive
// #rla="routerLinkActive"
// [active]="rla.isActive"
// [disabled]="link.disabled"
// [style.opacity]="rla.isActive ? 1 : 0.7"
// (click)="activeLink = link.link"
// >
// {{ link.label }}
// </a>
export class RecruiterJobsLinkRendererComponent implements AgRendererComponent {
  value: any;
  link: any;
  job: any;

  params: any;
  constructor(private router: Router, private jobService: JobService) {}
  agInit(params: any): void {
    this.value = params.value;
    if (!params.data) this.link = '';
    else {
      this.link = params.valueFormatted;
      if (params.data) {
        this.job = params.data;
      }
    }
  }

  onClick() {
    this.jobService.setSelectedJob(this.job);
    this.router.navigate([this.link]);
  }

  refresh(params: any): boolean {
    return false;
  }
}
