<app-loader [display]="isLoading"></app-loader>

<div class="container-fluid layout marB16" *ngIf="!isLoading">
  <div class="row">
    <div class="col-md-6 d-flex justify-content-start align-items-center">
      <!-- <div class="mr-3" role="button">
        <i class="icon x-24 back-icon" (click)="goBack()"></i>
      </div> -->
      <h3>Evaluation Template</h3>
    </div>
    <div
      class="col-md-6 d-flex justify-content-end align-items-center"
      *ngIf="templateForm.controls.finalized.value === false"
    >
      <span class="icon_btnCont marR8">
        <i class="icon x-24 download-icon"> </i>
      </span>
      <app-search-template-input
        class="w-100"
        placeholder="Import template form"
        (onTemplateSelect)="handleImportedTemplate($event)"
      ></app-search-template-input>
    </div>
  </div>

  <div class="marTB16" *ngIf="!onBoardingForm">
    <div class="row" *ngIf="templateForm.controls.finalized.value">
      <div class="col-12">
        <p
          class="DSA_wb_caption web-SecondaryRed DSA_wb_text-1line marB8 italic"
        >
          This evaluation template cannot be edited as it's finalized.
        </p>
      </div>
    </div>
    <form [formGroup]="templateForm">
      <div class="row">
        <div class="col-md-3">
          <mat-form-field
            class="DSA_Wb_custom-form-field DSA_form_nofloatLabel"
          >
            <mat-label>Form Type</mat-label>
            <mat-select
              panelClass="DSA_wb-custom-select-panel DSA_wb-custom-select-withBg"
              formControlName="formType"
              required
            >
              <mat-option *ngFor="let formType of formTypes" [value]="formType">
                {{ formType }}
              </mat-option>
            </mat-select>
            <mat-error>Required*</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field class="DSA_Wb_custom-form-field">
            <mat-label>Template Name</mat-label>
            <input matInput type="text" formControlName="name" required />
            <mat-error>Required*</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="DSA_Wb_custom-form-field">
            <mat-label>Template Description</mat-label>
            <input matInput type="text" formControlName="description" />
            <mat-error>Required</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="DSA_wb_customTab DSA_navTabWhite DSA_wb_Secondary">
        <mat-tab-group
          (selectedTabChange)="handleTabChange($event)"
          [selectedIndex]="selectedTabIndex"
          disableRipple
          animationDuration="0s"
        >
          <mat-tab *ngIf="!templateForm.controls.finalized.value">
            <ng-template mat-tab-label> Create Form </ng-template>
            <div class="marT16">
              <app-form-builder
                [readOnly]="templateForm.controls.finalized.value"
                [formJSON]="formJSON"
                (formChange)="handleFormChange($event)"
              ></app-form-builder>
              <auto-save
                [storageKey]="storageKey"
                [storeItem]="templateForm.value"
                [saveInDB]="autoSaveInDB"
                [autoSave]="autoSave"
                (onDBSave)="onAutoSaveInDB()"
              ></auto-save>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>Preview</ng-template>
            <div class="marT16 row">
              <div class="col-12">
                <formio
                  [form]="templateForm.controls.formData.value"
                  [options]="options"
                ></formio>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>

  <div *ngIf="!templateForm.controls.finalized.value" class="template-actions">
    <div *ngIf="!isUpdating" class="d-flex flex-column">
      <button
        class="DSA_wb_fabButton"
        aria-label="save template button"
        matTooltip="Save Template"
        matTooltipClass="DSA_tooltip"
        (click)="updateTemplate(templateForm.value)"
      >
        <i *ngIf="!isUpdating" class="icon_save white icon x-24"></i>
      </button>
      <button
        class="DSA_wb_fabButton marT8 web-SecondaryGreenBg finalize-btn"
        aria-label="finalize template button"
        matTooltip="Finalize Template"
        matTooltipClass="DSA_tooltip"
        (click)="handleFinalizeTemplateClick()"
      >
        <i class="icon-tick-dark white icon x-24"></i>
      </button>
    </div>

    <button class="DSA_wb_fabButton" aria-label="loader" *ngIf="isUpdating">
      <mat-spinner diameter="24" class="update-loader"></mat-spinner>
    </button>
  </div>
</div>
