<fieldset [disabled]="disabled">

  <div *ngIf="view==='TWO_COLUMNS';else oneColumn">
    <div class="row">
      <div class="col-6" style="display: flex;flex-direction: column;justify-content:center;">
        <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
            {{questionIndex+1}}.
          </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
            *
          </span></p>
        <br>
        <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">

      </div>
      <div class="col-6">
        <div>

          <div class="row" style="margin-top:10px; margin-bottom: 10px;">
            <div class="col-sm-6 ratings-label ratings-label-start" *ngIf="!media.isActive(['xs'])">
              {{ startText }}
            </div>

            <div class="col-sm-6 pull-right ratings-label ratings-label-end" *ngIf="!media.isActive(['xs'])">
              {{ endText }}
            </div>
            <div class="col-sm-6 ratings-label ratings-label-start" style="text-align: center;"
              *ngIf="media.isActive(['xs'])">
              {{ startText }}
            </div>

          </div>
          <div class="btn-group btn-group-toggle button-ratings" [ngStyle]="getCSSBackground()" style="display: flex;">
            <label class="btn" ngbButtonLabel style="flex: 1;" *ngFor="let option of options;let oi = index"
              [ngStyle]="{ 'background-color': responsePage.sections[section].questions[questionIndex].response[0] === ''+option.value ? '#e83e8c' : 'inherit' }"
              (click)="inputChanged(option)">
              <input type="checkbox" ngbButton> <span
                [ngStyle]="{'color': responsePage.sections[section].questions[questionIndex].response[0] === ''+option.value ? 'white' : fontColor }"
                style="font-weight: bold;">{{ option.value }}</span>
            </label>
          </div>
          <div class="row" style="margin-top:10px; margin-bottom: 10px;">

            <div class="col-sm-6 ratings-label ratings-label-end" style="text-align: center;"
              *ngIf="media.isActive(['xs'])">
              {{ endText }}
            </div>


          </div>
        </div>
        <span *ngIf="!responsePage.sections[section].questions[questionIndex].isValid"
          style="color:red;font-size: 12px;">
          {{responsePage.sections[section].questions[questionIndex].validationMessage}}</span>

      </div>
    </div>
  </div>

  <ng-template #oneColumn>

    <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
        {{questionIndex+1}}.
      </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
        *
      </span></p>
    <br>
    <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">
    <div>

      <div class="row" style="margin-top:10px; margin-bottom: 10px;">
        <div class="col-sm-6 ratings-label ratings-label-start" *ngIf="!media.isActive(['xs'])">
          {{ startText }}
        </div>

        <div class="col-sm-6 pull-right ratings-label ratings-label-end" *ngIf="!media.isActive(['xs'])">
          {{ endText }}
        </div>
        <div class="col-sm-6 ratings-label ratings-label-start" style="text-align: center;"
          *ngIf="media.isActive(['xs'])">
          {{ startText }}
        </div>

      </div>
      <div class="btn-group btn-group-toggle button-ratings" [ngStyle]="getCSSBackground()" style="display: flex;">
        <label class="btn" ngbButtonLabel style="flex: 1;" *ngFor="let option of options;let oi = index"
          [ngStyle]="{ 'background-color': responsePage.sections[section].questions[questionIndex].response[0] === ''+option.value ? '#e83e8c' : 'inherit' }"
          (click)="inputChanged(option)">
          <input type="checkbox" ngbButton> <span
            [ngStyle]="{'color': responsePage.sections[section].questions[questionIndex].response[0] === ''+option.value ? 'white' : fontColor }"
            style="font-weight: bold;">{{ option.value }}</span>
        </label>
      </div>
      <div class="row" style="margin-top:10px; margin-bottom: 10px;">

        <div class="col-sm-6 ratings-label ratings-label-end" style="text-align: center;"
          *ngIf="media.isActive(['xs'])">
          {{ endText }}
        </div>


      </div>
    </div>
    <span *ngIf="!responsePage.sections[section].questions[questionIndex].isValid" style="color:red;font-size: 12px;">
      {{responsePage.sections[section].questions[questionIndex].validationMessage}}</span>
  </ng-template>


</fieldset>
