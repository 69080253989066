import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { MatDialog } from '@angular/material/dialog';
import { InfyCopyEventComponent } from 'src/app/modules/iwp-event/infy-copy-event/infy-copy-event.component';
import { Router } from '@angular/router';
import { CandidateService } from 'src/app/services/candidate.service';
import { AccountService } from 'src/app/services/account.service';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { EmailService } from 'src/app/services/email.service';
import { SharedService } from 'src/app/services/shared.service';
import { InterviewService } from 'src/app/services/interview.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import { ICellRendererParams } from 'ag-grid-community';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
@Component({
  templateUrl: './interview-rounds-action-renderer.component.html',
  styleUrls: ['./interview-rounds-action-renderer.component.scss'],
})
export class InterviewRoundsActionRendererComponent
  implements AgRendererComponent {
  params: ICellRendererParams;
  icon: any;
  eventId: any;
  candidate: any;
  interviewStatus: any;
  val: any;
  data: any;
  FeatureName = FeatureName;
  PermissionType = PermissionType;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private candidateService: CandidateService,
    private accountService: AccountService,
    private emailService: EmailService,
    private sharedService: SharedService,
    private interviewService: InterviewService,
    private scheduleService: ScheduleService
  ) {}
  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (!params.data) {
      this.data = '';
      this.interviewStatus = '';
    } else {
      this.data = params.data;
      this.interviewStatus = params.data.interviewStatus;
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  resendEmail = (interviewId, name) => {
    let text = 'Are you sure you want resend email to Interviewer?';
    if (name === 'candidate') {
      text = 'Are you sure you want resend email to Candidiate?';
    }
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: { text },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Yes') {
        this.emailService.resendEmail(interviewId, name).subscribe(
          () => {
            this.sharedService.openSnackBar('Sent Successfully', '', 3000);
          },
          (err) => {
            this.params.context.componentParent.setGridUI();
            this.sharedService.openSnackBar('Some error occured', '', 3000);
          }
        );
      }
    });
  };
  noShowInterview = (id) => {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        text:
          "Are you sure you want to mark this Interview as 'No Show'?<br/> On <strong class='font-red'>Yes</strong>, the profile will be autosubmitted.",
      },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Yes') {
        this.params.context.componentParent.showLoadingOverlay();
        this.candidateService.getNoShowCandidateDetails(id).subscribe(
          () => {
            this.params.context.componentParent.refreshTable();
            this.sharedService.openSnackBar(
              'Interview Marked as No Show',
              '',
              3000
            );
          },
          (err) => {
            this.params.context.componentParent.setGridUI();
            this.sharedService.openSnackBar('Some error occured', '', 3000);
          }
        );
      }
    });
  };

  cancelInterview = (id) => {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        text:
          "Are you sure you want to cancel this interview?<br/> On <strong class='font-red'>Yes</strong>, the interview will be cancelled",
      },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Yes') {
        this.params.context.componentParent.showLoadingOverlay();
        this.interviewService.getCancelledInterviewDetails(id).subscribe(
          () => {
            this.params.context.componentParent.refreshTable();
            this.sharedService.openSnackBar('Interview Cancelled', '', 3000);
          },
          (err) => {
            this.params.context.componentParent.setGridUI();
            this.sharedService.openSnackBar(
              'Interview could not be cancelled',
              '',
              3000
            );
          }
        );
      }
    });
  };

  editInterview(data) {
    this.scheduleService.setSelectedInterviewSchedule(data);
    this.interviewService.setEditInterviewMode(true);
    this.router.navigate(['/schedule-interview']);
  }
}
