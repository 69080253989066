<app-loader [display]="loader"></app-loader>
<div *ngIf="!loader">
  <h1 mat-dialog-title>Upload Migration Sheet</h1>
  <div mat-dialog-content class="container-fluid padTB8">
    <div class="row">
      <div class="col-12 nopadLR">
        <input
          type="file"
          (change)="bulkUpload(bulkUploadElement.files)"
          #bulkUploadElement
          accept=".csv"
          style="display: none"
        />

        <button
          (click)="bulkUploadElement.click()"
          mat-flat-button
          class="DSA_wb_Icon-btn"
        >
          <span class="icon_btnCont">
            <i class="icon purple x-16 icon-add"></i>
          </span>
          Upload Sheet
        </button>
      </div>
      <div class="col-12 nopadLR marT8" *ngIf="bulkuploadFile">
        <p class="mat-body">
          <strong>Selected File:</strong> {{ bulkuploadFile.name }}
        </p>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-end">
    <button
      mat-button
      [mat-dialog-close]="'No'"
      class="DSA_wb_miniSecondary-btn"
    >
      Cancel
    </button>
    <button
      mat-button
      (click)="uploadBulkCSV()"
      class="DSA_wb_miniPrimary-btn"
      [disabled]="!bulkuploadFile"
    >
      Upload
    </button>
  </div>
</div>
