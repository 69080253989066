<div class="container-fluid layout">
  <div class="row mt-2">
    <div class="col-md-6 d-flex align-items-center">
      <a class="mr-2" (click)="navigateBack(['/dashboard'])">
        <i class="icon x-24 back-icon m-0"> </i>
      </a>
      <h2 class="DSA_wb_h2Header web-GreyDarkest">All Jobs</h2>
    </div>
    <div class="col-md-6">
      <div class="d-flex justify-content-end align-items-center">
        <div
          *ngIf = "accountService.hasFeatureAccess(FeatureName.JOBS, PermissionType.CREATE)">
          <button mat-flat-button class="DSA_wb_Icon-btn" (click)="addNewJob()">
            <span class="icon_btnCont"
              ><i class="icon purple x-16 icon-add"></i
            ></span>
            Create Job
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <data-grid
        [columnDefs]="columnDefs"
        [tableName]="tableName"
        [searchCondition]="searchCondition"
        [interface]="interface"
        [pageNumber]="page"
        [pageSize]="size"
        (pageChangeValue)="onPageChange($event)"
        [refreshAgGrid]="refreshAgGrid.asObservable()"
        [searchValue]="queryJobTitle"
      ></data-grid>
    </div>
  </div>
</div>
