<!-- <p>project-details works!</p> -->

<div *ngIf="spinner">
  <br />
  <img
    src="../../../assets/loader.gif"
    style="margin-left: 30%; width: 200px"
    alt=""
  />
</div>
<div *ngIf="true; else noProjectInfo">
   <div class="m-3 mr-5 " > <!--style="height: 890px !important" -->
    <div class="container-fluid ml-3 mr-3">
    <form
      [formGroup]="projectInfoForm"
      style="font-size: medium"
    >
    <mat-carousel
      color="accent"
      maxWidth="auto"
      maintainAspectRatio="true"
      slideHeight="100%"
      proportion="100"
      touchDrag="false"
      mouseDrag="false"
      [loop]="true"
      [hideArrows]="false"
      [hideIndicators]="false"
      [useKeyboard]="true"
      [useMouseWheel]="false"
      [autoplay]="false"
      orientation="ltr"
      
    >
      <mat-carousel-slide
        #matCarouselSlide
        class="container-fluid"
        overlayColor="#00000040"
        [hideOverlay]="true"
        formArrayName="projectHistoryDTOs"
      *ngFor="let d of getDTOFormControls(); let i = index"
      >
        <div>
          <div class="mt-2 mb-5">
            <div style="margin: 10px 0px 0px 0px" class="text-center">
              <!-- <h3>Project History</h3> -->
            </div>
            <ng-container>
              <!-- <h4 class="marTB16">New Project History</h4> -->
              <ng-container [formGroupName]="i">
                <mat-form-field
                  class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
                  floatLabel="never"
                >
                  <input
                    matInput
                    placeholder="Project Name"
                    formControlName="projectName"
                  />
                </mat-form-field>

                <mat-form-field
                  class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
                >
                  <!-- <mat-label>End Date</mat-label> -->
                  <input
                    matInput
                    [matDatepicker]="endDate"
                    placeholder="Project StartDate"
                    formControlName="projectStartDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="endDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #endDate></mat-datepicker>
                </mat-form-field>

                <mat-form-field
                  class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
                >
                  <!-- <mat-label>End Date</mat-label> -->
                  <input
                    matInput
                    [matDatepicker]="picker"
                    placeholder="Project EndDate"
                    formControlName="projectEndDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field
                  class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
                  floatLabel="never"
                >
                  <input
                    matInput
                    placeholder="Skills"
                    formControlName="skills"
                  />
                </mat-form-field>

                <mat-form-field
                  class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
                  floatLabel="never"
                >
                  <input matInput placeholder="Tools" formControlName="tools" />
                </mat-form-field>

                <mat-form-field
                  class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
                  floatLabel="never"
                >
                  <input
                    matInput
                    placeholder="Technology Used"
                    formControlName="technologyUsed"
                  />
                </mat-form-field>
               
              </ng-container>
            </ng-container>
          </div>
        </div>
      </mat-carousel-slide>
    </mat-carousel>
    </form>
  </div>
    <button
      class="DSA_wb_miniSecondary-btn marTB16"
      (click)="updateExtractedResume()"
      style="float: right;"
    >
      Update
    </button>
    <!-- <button
      class="DSA_wb_miniSecondary-btn "
      (click)="updateExtractedResume()"
      style="float: right; margin-right: 10px !important;margin-bottom: 10px !important;"
    >
      save
    </button> -->
  </div>
</div>
<ng-template #noProjectInfo>
  <div style="height: 860px !important">
    <div
      style="
        margin: 10% 20%;
        color: gray;
        font-size: medium;
        text-align: center;
      "
    >
      No project details available.
    </div>
  </div>
</ng-template>
