import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-panelist-declaration',
  templateUrl: './panelist-declaration.component.html',
  styleUrls: ['./panelist-declaration.component.scss'],
})
export class PanelistDeclarationComponent implements OnInit {
  @Input() isChecked: boolean = false;
  @Input() experienceType: 'FRESHER' | 'LATERAL';
  @Output() onDeclarationChange = new EventEmitter<boolean>();

  declarations: FormGroup;

  constructor(private _formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.declarations = this._formBuilder.group({
      fresherSnapDeclaration: [],
      lateralSnapDeclaration: [], //combined declaration
      // lateralSnapDeclaration1: [],
      // lateralSnapDeclaration2: [],
      referralDeclaration: [],
    });

    this.handleFormValueChange();

    // only one declaration should be accepted out of 2
    // this.declarations.controls.lateralSnapDeclaration1.valueChanges.subscribe(
    //   (val) => {
    //     if (val === true)
    //       this.declarations.controls.lateralSnapDeclaration2.setValue(!val, {
    //         emitEvent: false,
    //       });
    //   }
    // );
    // this.declarations.controls.lateralSnapDeclaration2.valueChanges.subscribe(
    //   (val) => {
    //     if (val === true)
    //       this.declarations.controls.lateralSnapDeclaration1.setValue(!val, {
    //         emitEvent: false,
    //       });
    //   }
    // );

    this.declarations.controls.referralDeclaration.setValue(this.isChecked);

    if (this.experienceType === 'FRESHER') {
      this.declarations.controls.fresherSnapDeclaration.setValue(
        this.isChecked
      );
    } else {
      this.declarations.controls.lateralSnapDeclaration.setValue(
        this.isChecked
      );
    }
  }

  handleFormValueChange = () => {
    this.declarations.valueChanges.subscribe((val) => {
      const referralDeclaration = this.declarations.value.referralDeclaration;
      let isAccepted = false;

      if (referralDeclaration) {
        if (this.experienceType === 'FRESHER') {
          isAccepted =
            this.declarations.value.fresherSnapDeclaration === true &&
            referralDeclaration;
        } else if (this.experienceType === 'LATERAL') {
          isAccepted =
            this.declarations.value.lateralSnapDeclaration === true &&
            referralDeclaration;
        }
      }
      this.onDeclarationChange.emit(isAccepted);
    });
  };
}
