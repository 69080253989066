<h1 mat-dialog-title class="DSA_wb_h1Header">
  <p style="color: #003865; font-weight: bold">Privacy Statement</p>
</h1>
<mat-dialog-content id="privacyPolicyContent" class="mat-typography">
  <!-- <p>
    Infosys Foundation, its associates and affiliates are committed to processing personal data as
    per the laws of your jurisdiction. We hereby notify you the following
    information about your personal data processing.
  </p>

  <h1 class="dialog-content-headings marT16">Personal Data elements:</h1>

  <p>
    In view of the foregoing, Infosys would be required to collect the following
    Personal Data from You for the purposes as specified below:
  </p>
  <div class="padL16">
    <ul>
      <li>
        Personal Information such as Name, DOB, Domicile, Gender, Photograph,
        Identity Proof, Introductory video submitted by you.
      </li>
      <li>
        Family member/Guardian details such as (relationship, first name last
        name), Marital status, declaration on being first generation undergrad
        (if applicable).
      </li>
      <li>
        Contact Information - Email, Contact Number, Communication address
        (including Pin Code), Address Proof, Present residence address,
        Emergency address, Past addresses (and duration of residence), Emergency
        Contact Number, personal mail ID.
      </li>
      <li>
        Education details- School details, year of Passing (for 10th and 12th)
        and related documents such as marksheets and certificates,
        College/University Details including Degree, Marksheets/CGPA details for
        each semester, and Tuition fee structure, Admission Letter (if
        applicable), Education certificates (where applicable).
      </li>
      <li>
        Financial details such as Family income and related certificates, bank
        account details.
      </li>
      <li>
        Education details- School details, year of Passing (for 10th and 12th)
        and related documents such as marksheets and certificates,
        College/University Details including Degree, Marksheets/CGPA details for
        each semester, and Tuition fee structure, Admission Letter (if
        applicable), Education certificates (where applicable).
      </li>
    </ul>
  </div>

  <h1 class="dialog-content-headings marT16">Purpose of Processing:</h1>
  <p>
    Infosys Foundation may hereinafter be required to collect, receive, possess, store,
    deal or handle your Personal Data for the following Purposes:
  </p>

  <div class="padL16">
    <ul>
      <li>
        To enable you to apply and register for scholarship program initiative
        from Infosys Foundation.
      </li>
      <li>
        To identify and shortlist eligible candidates (12th passed girls
        students) for scholarship program.
      </li>
      <li>
        For internal management of the program and sharing updates between
        authorized users.
      </li>
      <li>For audits and reporting purposes.</li>
      <li>
        For complying with any Legal obligations on us, including any applicable
        laws and protecting our legal rights, seeking remedies, and defending
        against claims.
      </li>
    </ul>
  </div>
  <h1 class="dialog-content-headings marT16">Data transfer and Storage:</h1>
  <p>
    If necessary for the above stated purpose, the Personal Data listed above
    may be transferred to our offices, our partner institutions/colleges, and
    such data will be stored on our authorized cloud service providers’ servers
    in India.
  </p>

  <h1 class="dialog-content-headings marT16">Security Measures:</h1>
  <p>
    Infosys Foundation adopts reasonable and appropriate security practices and procedures
    including administrative, physical security, and technical controls in order
    to safeguard your Personal Data.
  </p>
  <h1 class="dialog-content-headings marT16">Retention Period:</h1>
  <p>
    Personal Data will not be retained for a period more than necessary to
    fulfill the purposes outlined in this Privacy Statement, unless a longer
    retention period is required by law or for directly related legitimate
    business purposes.
  </p>
  <h1 class="dialog-content-headings marT16">Your Rights:</h1>
  <p>
    In case of any queries/concerns related to your data processing or for
    exercising your data privacy rights as per applicable DP laws, you may write
    to us at
    <a href="mailto:privacy@infosys.com">privacy@infosys.com</a>.
  </p>

  <h1 class="dialog-content-headings marT16">Consent:</h1>
  <p>
    In pursuance of the foregoing, by choosing the “I Accept” option, you hereby
    acknowledge and understand that your Personal Data may be processed in the
    above-mentioned manner and hereby consent to such processing of Personal
    Data. Additionally, you acknowledge that any personal data/documents of
    other individuals are shared by you only after seeking consent from the
    person to whom the personal data/documents belong. 
  </p>
  <br /> -->
  <ng-container *ngIf="!isLoading; else loaderRef">
    <div [innerHtml]="content?.templateString"></div>
    <div class="d-flex justify-content-start align-items-center">
      <mat-checkbox
        class="DSA_Wb_custom-checkbox nomarLR"
        aria-label="checkbox"
        disableRipple="true"
        [(ngModel)]="isPolicyAccepted"
      >
        Accept
      </mat-checkbox>
      <ng-container *ngIf="data && !data?.is18Plus">
        <span class="ml-2" *ngIf="!isDownloading; else downloadingRef">
          ( Please
          <a
            href="javascript:void()"
            class="alert-link mx-1"
            (click)="downloadFile()"
          >
            download
          </a>
          the privacy policy form and upload the signed document during the
          application process. )
        </span>
        <ng-template #downloadingRef>
          <a href="javascript:void()" class="ml-4">
            <span class="sr-only">Downloading...</span>
            <span
              class="spinner-border"
              role="status"
              aria-hidden="true"
            ></span>
          </a>
        </ng-template>
      </ng-container>
    </div>
    <ng-container *ngIf="currentRole == authorityName.ROLE_CANDIDATE">
      
    </ng-container>
  </ng-container>
  <ng-template #loaderRef>
    <!-- <div class="loading-spinner" style="height: 200px">
      <div class="loading-spinner-inner">
        <div></div>
      </div>
    </div> -->
    <div class="text-center">
      <h5 class="DSA_wb_h5Header">Loading...</h5>
    </div>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <div [matTooltip]="disableProceedPrivacyBtn()" matTooltipClass="DSA_tooltip">
    <button
      [disabled]="disableProceedPrivacyBtn() != ''"
      mat-flat-button
      class="DSA_wb_primary-btn"
      id="proceedBtn"
      (click)="handleProceedClick()"
    >
      <span class="pad8">Proceed</span>
    </button>
  </div>
</mat-dialog-actions>
