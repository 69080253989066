<div class="row my-5" *ngIf="panelistsParticipationStatus.length > 0">
  <div class="col-12">
    <h5>Panelist(s) Participation Status</h5>
    <div class="d-flex flex-wrap my-3">
      <div
        class="my-2 marR32"
        *ngFor="let item of panelistsParticipationStatus"
      >
        <mat-checkbox
          class="DSA_Wb_custom-checkbox my-2 checkbox-lg"
          [checked]="item.attendanceStatus"
          (change)="handleAttendanceStatusChange($event, item.interviewerId)"
          [disabled]="eventStatus === 'CLOSED'"
        >
          {{ item.name }} ({{ item.emailId }})
        </mat-checkbox>
      </div>
    </div>
    <button
      mat-button
      class="DSA_wb_miniSecondary-btn"
      (click)="handleSubmit()"
      [disabled]="eventStatus === 'CLOSED' || loader"
    >
      {{ loader ? "Saving, Please wait..." : "Save Participation Status" }}
    </button>

    <!-- Add Panelists Section -->
    <div class="marT16" *ngIf="showAddPanelist">
      <!-- Add panelist button -->
      <button
        *ngIf="
          accountService.hasFeatureAccess(
            FeatureName.CLOSE_CANDIDATURE,
            PermissionType.CREATE
          ) && eventStatus !== 'CLOSED'
        "
        mat-flat-button
        class="DSA_wb_Icon-btn d-block"
        (click)="handleAddPanelistClick()"
      >
        <span class="icon_btnCont">
          <i class="icon purple x-16 icon-add"></i>
        </span>
        Add Panelist
      </button>
    </div>
  </div>
</div>
