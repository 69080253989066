import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './app-banner.component.html',
  styleUrls: ['./app-banner.component.scss'],
})
export class AppBannerComponent {
  @Input() displaySubHeader: boolean;
  @Input() title: string;
  @Input() location: string;
  @Input() organization: string;
  @Input() bgColor: string = '#00b28f';
  @Input() titleColor: string = '#FFFFFF';

  showSocialIcons: boolean = false;

  toggleSocialIcons() {
    this.showSocialIcons = !this.showSocialIcons;
  }
}
