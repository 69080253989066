import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private headers = new HttpHeaders()
    .set('content-type', 'application/json');
  constructor(private http: HttpClient) {}

  resendEmail = (interviewId, name) => {
    return this.http.get<any[]>(
      environment.interviewServiceUrl + `api/resend/${interviewId}`,
      { headers: this.headers, params: { name } }
    );
  };
}
