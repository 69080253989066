<h1 mat-dialog-title>Mark Panelist Unavailability</h1>
<app-loader [display]="isLoading"></app-loader>
<ng-container *ngIf="!isLoading">
  <div mat-dialog-content class="custom-time-field">
    <mat-form-field class="DSA_Wb_custom-form-field">
      <input
        matInput
        placeholder="Select Unavailability start time"
        aria-label="Enter time in format HH colon MM space AM/PM"
        [ngxTimepicker]="appendedToInput"
        [min]="currentTime"
        [value]="panelistUnavailableFromTime"
        readonly
      />
      <ngx-material-timepicker-toggle [for]="toggleIcon" matSuffix>
        <i class="icon x-24 icon-time" ngxMaterialTimepickerToggleIcon> </i>
      </ngx-material-timepicker-toggle>
      <ngx-material-timepicker
        #appendedToInput
        timepickerClass="DCA_wb_cusTime"
        [appendToInput]="true"
        #toggleIcon
        (timeSet)="setCustomTime($event)"
      ></ngx-material-timepicker>
      <mat-hint *ngIf="panelistUnavailableFromTime">
        Panelist will be unavailable from:
        {{ panelistUnavailableFromTime }}
      </mat-hint>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-end">
    <button
      mat-button
      [mat-dialog-close]="false"
      class="DSA_wb_miniSecondary-btn"
    >
      Cancel
    </button>
    <button
      mat-button
      (click)="handleSubmitClick()"
      class="DSA_wb_miniPrimary-btn"
      [disabled]="!panelistUnavailableFromTime"
    >
      Submit
    </button>
  </div>
</ng-container>
