import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormioForm, FormioOptions } from 'angular-formio';
import { Observable } from 'rxjs';
import {
  formIOPreviewOptions,
  interviewRoundTypes,
} from 'src/app/app.constants';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { FormMsService } from 'src/app/services/form-ms.service';
import { SharedService } from 'src/app/services/shared.service';
import { TemplateEvaluationFormDTO } from 'src/app/shared/model/form-ms.model';

@Component({
  selector: 'app-evaluation-template',
  templateUrl: './evaluation-template.component.html',
  styleUrls: ['./evaluation-template.component.scss'],
})
export class EvaluationTemplateComponent implements OnInit {
  formJSON: FormioForm = {
    display: 'wizard',
    components: [],
  };
  // @Input() inputTemplateId: number;
  // onBoardingForm: boolean = false;
  formTypes = interviewRoundTypes;
  templateId: any;
  isLoading: boolean = false;
  isUpdating: boolean = false;
  templateForm: FormGroup;

  selectedTabIndex: number = 0;

  storageKey: any;
  autoSave = false;

  options: FormioOptions = formIOPreviewOptions;
  constructor(
    private formBuilder: FormBuilder,
    private formMsService: FormMsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
    private dialog: MatDialog,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.templateForm = this.formBuilder.group({
      id: [''],
      name: ['', [Validators.required]],
      description: [''],
      formType: ['', [Validators.required]],
      formData: [''],
      finalized: [''],
    });

    // if (this.inputTemplateId) {
    //   this.templateId = this.inputTemplateId;
    //   this.onBoardingForm = true;
    // } else {
      this.templateId = this.activatedRoute.snapshot.params.id;
    // }

    this.storageKey = this.templateId;
    this.getEvaluationTemplateById(this.templateId);
  }

  getEvaluationTemplateById = (templateId) => {
    this.isLoading = true;
    this.formMsService.getTemplateById(templateId).subscribe({
      next: (res: TemplateEvaluationFormDTO) => {
        this.setTemplateState(res);
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
        this.sharedService.openSnackBar(
          'Some error occurred in fetching template',
          '',
          3000
        );
        this.router.navigate(['evaluation-templates']);
      },
    });
  };

  setTemplateState = (templateData: TemplateEvaluationFormDTO) => {
    let localTemplateData: {
      updatedAt: string;
      data: TemplateEvaluationFormDTO;
    } = JSON.parse(localStorage.getItem(this.storageKey));

    const isLocalSavedDataLatest: boolean =
      new Date(localTemplateData?.updatedAt) >
      new Date(templateData.lastModifiedDate);
    this.formJSON = isLocalSavedDataLatest
      ? localTemplateData?.data?.formData //local data
      : templateData.formData
      ? templateData.formData //api data
      : this.formJSON; //initial data

    templateData.formData = this.formJSON;
    this.templateForm.patchValue(templateData);

    if (templateData.finalized) {
      this.templateForm.controls.formType.disable();
      this.templateForm.controls.name.disable();
      this.templateForm.controls.description.disable();
    } else {
      // if not finalized - start autosave
      this.autoSave = true;
    }
  };

  handleFormChange = (e: FormioForm) => {
    this.templateForm.controls.formData.setValue(e);
  };

  autoSaveInDB = (storeItem: TemplateEvaluationFormDTO): Observable<any> => {
    return this.formMsService.updateEvaluationTemplate(storeItem);
  };

  onAutoSaveInDB = () => {
    this.sharedService.openSnackBar('Template saved', '', 3000);
  };

  updateTemplate = (updatedTemplateData: TemplateEvaluationFormDTO) => {
    this.isUpdating = true;
    this.formMsService.updateEvaluationTemplate(updatedTemplateData).subscribe({
      next: (res) => {
        this.setTemplateState(res);
        this.sharedService.openSnackBar('Saved successfully', '', 3000);
        this.isUpdating = false;
      },
      error: (err) => {
        this.sharedService.openSnackBar('Some error occurred', '', 3000);
        this.isUpdating = false;
      },
    });
  };

  handleFinalizeTemplateClick = () => {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        text:
          "Template <strong class='font-red'>cannot be edited</strong> once finalized.<br/> Are you sure you want to proceed?",
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Yes') {
        let templateData = {
          ...this.templateForm.value,
          finalized: true,
        };
        this.autoSave = false;
        localStorage.removeItem(this.storageKey);
        this.updateTemplate(templateData);
      }
    });
  };

  handleImportedTemplate = (selectedTemplate: TemplateEvaluationFormDTO) => {
    this.formJSON = selectedTemplate.formData;
    this.templateForm.controls.formData.setValue(selectedTemplate.formData);
  };

  handleTabChange = (e) => {
    this.selectedTabIndex = e.index;
  };

  goBack = () => {
    this.location.back();
  };
}
