import { DoCheck, ChangeDetectorRef, KeyValueDiffers } from '@angular/core';
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/tslint/config */
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import * as moment from 'moment';

import { FileserverService } from 'src/app/survey-master/survey/edit-backgroud-color/fileserver.service';
import { Theme, QuestionView } from 'src/app/shared/model/theme.model';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return control && control.invalid;
  }
}

@Component({
  selector: 'jhi-date-time-preview',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
})
export class DateTimePreviewComponent implements OnInit, OnChanges, DoCheck {
  @Input()
  currPage: any;
  @Input()
  question: any;
  @Input()
  questionIndex: any;
  @Input()
  responsePage: any;
  @Input()
  section: any;
  @Input()
  preference: any;
  @Input()
  theme: Theme;

  view = 'NORMAL';
  @Input()
  disabled: Boolean = false;

  matcher = new MyErrorStateMatcher();
  control;
  useDate: boolean;
  useTime: boolean;
  time: NgbTimeStruct = { hour: 13, minute: 30, second: 0 };
  dateValueforModel: Date = new Date();
  diffResponsePage: any;
  constructor(
    public fileserverService: FileserverService,
    changeDetectorRef: ChangeDetectorRef,
    private differs: KeyValueDiffers
  ) {}

  ngOnInit() {
    this.control = new FormControl('');
    if (this.theme.questionView === QuestionView.TWO_COLUMNS) {
      this.view = 'TWO_COLUMNS';
    } else {
      this.view = 'NORMAL';
    }
    this.diffResponsePage = this.differs
      .find(
        this.responsePage.sections[this.section].questions[this.questionIndex]
      )
      .create();
    if (this.getValue(this.question, 'USE_DATE') === 'true') {
      this.useDate = true;
    } else {
      this.useDate = false;
    }

    if (this.getValue(this.question, 'USE_TIME') === 'true') {
      this.useTime = true;
    } else {
      this.useTime = false;
    }
    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response != null &&
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response.length > 0
    ) {
      // already some response present
      if (this.useDate) {
        const mom = moment(
          this.responsePage.sections[this.section].questions[this.questionIndex]
            .response[0],
          'M/D/YYYY'
        );

        this.dateValueforModel = mom.toDate();

        if (this.useTime) {
          this.time.hour = Number(
            this.responsePage.sections[this.section].questions[
              this.questionIndex
            ].response[1].split(':')[0]
          );
          this.time.minute = Number(
            this.responsePage.sections[this.section].questions[
              this.questionIndex
            ].response[1].split(':')[1]
          );
        }
      } else if (this.useTime) {
        this.time.hour = Number(
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].response[0].split(':')[0]
        );
        this.time.minute = Number(
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].response[0].split(':')[1]
        );
      }
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response = [];
      if (this.useDate) {
        if (this.useTime) {
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].response.push('');
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].response.push('');
        } else {
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].response.push('');
        }
      } else if (this.useTime) {
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].response.push('');
      }
    }
  }
  ngDoCheck(): void {
    const responsePageChange = this.diffResponsePage.diff(
      this.responsePage.sections[this.section].questions[this.questionIndex]
    );
    if (responsePageChange) {
      this.ngOnChanges();
    }
  }
  ngOnChanges() {
    this.control = new FormControl('');
    if (this.theme.questionView === QuestionView.TWO_COLUMNS) {
      this.view = 'TWO_COLUMNS';
    } else {
      this.view = 'NORMAL';
    }

    if (this.getValue(this.question, 'USE_DATE') === 'true') {
      this.useDate = true;
    } else {
      this.useDate = false;
    }

    if (this.getValue(this.question, 'USE_TIME') === 'true') {
      this.useTime = true;
    } else {
      this.useTime = false;
    }
    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response != null &&
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response.length > 0
    ) {
      // already some response present
      if (this.useDate) {
        const mom = moment(
          this.responsePage.sections[this.section].questions[this.questionIndex]
            .response[0],
          'M/D/YYYY'
        );

        this.dateValueforModel = mom.toDate();

        if (this.useTime) {
          this.time.hour = Number(
            this.responsePage.sections[this.section].questions[
              this.questionIndex
            ].response[1].split(':')[0]
          );
          this.time.minute = Number(
            this.responsePage.sections[this.section].questions[
              this.questionIndex
            ].response[1].split(':')[1]
          );
        }
      } else if (this.useTime) {
        this.time.hour = Number(
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].response[0].split(':')[0]
        );
        this.time.minute = Number(
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].response[0].split(':')[1]
        );
      }
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response = [];
      if (this.useDate) {
        if (this.useTime) {
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].response.push('');
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].response.push('');
        } else {
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].response.push('');
        }
      } else if (this.useTime) {
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].response.push('');
      }
    }
  }

  downloadImage(path: string) {
    return this.fileserverService.getFileURLbyPath(path);
  }

  timeChanged(event: any) {
    let hour = '';
    let min = '';
    // this.logicPart();
    if (
      (event.hour === '' || event.minute === '') &&
      this.question.preference.isRequired === 1
    ) {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].isValid = false;
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].validationMessage = 'Please select a valid time';
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].isValid = true;
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].validationMessage = '';
    }
    if (this.useDate) {
      if (String(event.hour).length === 1) {
        hour = '0' + event.hour;
      } else hour = event.hour;
      if (String(event.minute).length === 1) {
        min = '0' + event.minute;
      } else min = event.minute;
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response[1] = hour + ':' + min;
    } else {
      if (String(event.hour).length === 1) {
        hour = '0' + event.hour;
      } else hour = event.hour;
      if (String(event.minute).length === 1) {
        min = '0' + event.minute;
      } else min = event.minute;
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response[0] = hour + ':' + min;
    }

    this.logicPart();
  }

  logicPart() {
    if (
      this.question.logics !== null &&
      this.question.logics.length > 0 &&
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .isValid
    ) {
      for (let i = 0; i < this.question.logics.length; i++) {
        let apply = false;
        let condition = 'END';

        for (let r = 0; r < this.question.logics[i].rules.length; r++) {
          let rapply = false;
          let ruleDate = null;
          let dateValue: Date = null;
          let ruleTime = null;
          let ruleMinutes = null;
          let ruleHours = null;
          if (this.useDate) {
            if (this.question.logics[i].rules[r].value === '') {
              dateValue = new Date();
            } else {
              ruleDate = this.question.logics[i].rules[r].value.split('T')[0];

              const mom = moment(ruleDate, 'M/D/YYYY');
              dateValue = mom.toDate();
            }
          }
          if (this.useTime) {
            if (this.question.logics[i].rules[r].value === '') {
              ruleHours = '00';
              ruleMinutes = '00';
            } else {
              ruleTime = this.question.logics[i].rules[r].value.split('T')[1];
              ruleHours = ruleTime.split(':')[0];
              ruleMinutes = ruleTime.split(':')[1];
            }
          }
          if (this.question.logics[i].rules[r].op === 'EQ') {
            if (this.useDate) {
              const mom = moment(
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0],
                'M/D/YYYY'
              );
              const responseDate = mom.toDate();
              if (this.useTime) {
                if (
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[0] !== '' &&
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[1] !== ''
                ) {
                  const responseHours = Number(
                    this.responsePage.sections[this.section].questions[
                      this.questionIndex
                    ].response[1].split(':')[0]
                  );
                  const responseMinutes = Number(
                    this.responsePage.sections[this.section].questions[
                      this.questionIndex
                    ].response[1].split(':')[1]
                  );
                  responseDate.setHours(responseHours);
                  responseDate.setMinutes(responseMinutes);
                }
                dateValue.setHours(Number(ruleHours));
                dateValue.setMinutes(Number(ruleMinutes));
              }

              if (responseDate.getTime() === dateValue.getTime()) {
                rapply = true;
              } else rapply = false;
            } else if (this.useTime) {
              if (
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0].split(':')[0] !== '' &&
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0].split(':')[1] !== ''
              ) {
                const responseHours = Number(
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[0]
                );
                const responseMinutes = Number(
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[1]
                );
                if (
                  Number(ruleHours) === responseHours &&
                  Number(ruleMinutes) === responseMinutes
                ) {
                  rapply = true;
                } else {
                  rapply = false;
                }
              } else {
                rapply = false;
              }
            }
          } else if (this.question.logics[i].rules[r].op === 'NEQ') {
            if (this.useDate) {
              const mom = moment(
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0],
                'M/D/YYYY'
              );
              const responseDate = mom.toDate();
              if (this.useTime) {
                if (
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[0] !== '' &&
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[1] !== ''
                ) {
                  const responseHours = Number(
                    this.responsePage.sections[this.section].questions[
                      this.questionIndex
                    ].response[1].split(':')[0]
                  );
                  const responseMinutes = Number(
                    this.responsePage.sections[this.section].questions[
                      this.questionIndex
                    ].response[1].split(':')[1]
                  );
                  responseDate.setHours(responseHours);
                  responseDate.setMinutes(responseMinutes);
                }
                dateValue.setHours(Number(ruleHours));
                dateValue.setMinutes(Number(ruleMinutes));
              }

              if (responseDate.getTime() !== dateValue.getTime()) {
                rapply = true;
              } else rapply = false;
            } else if (this.useTime) {
              if (
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0].split(':')[0] !== '' &&
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0].split(':')[1] !== ''
              ) {
                const responseHours = Number(
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[0]
                );
                const responseMinutes = Number(
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[1]
                );
                if (
                  Number(ruleHours) !== responseHours ||
                  Number(ruleMinutes) !== responseMinutes
                ) {
                  rapply = true;
                } else {
                  rapply = false;
                }
              } else {
                rapply = false;
              }
            }
          } else if (this.question.logics[i].rules[r].op === 'LT') {
            if (this.useDate) {
              const mom = moment(
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0],
                'M/D/YYYY'
              );
              const responseDate = mom.toDate();
              if (this.useTime) {
                if (
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[0] !== '' &&
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[1] !== ''
                ) {
                  const responseHours = Number(
                    this.responsePage.sections[this.section].questions[
                      this.questionIndex
                    ].response[1].split(':')[0]
                  );
                  const responseMinutes = Number(
                    this.responsePage.sections[this.section].questions[
                      this.questionIndex
                    ].response[1].split(':')[1]
                  );
                  responseDate.setHours(responseHours);
                  responseDate.setMinutes(responseMinutes);
                }
                dateValue.setHours(Number(ruleHours));
                dateValue.setMinutes(Number(ruleMinutes));
              }

              if (responseDate.getTime() < dateValue.getTime()) {
                rapply = true;
              } else rapply = false;
            } else if (this.useTime) {
              if (
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0].split(':')[0] !== '' &&
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0].split(':')[1] !== ''
              ) {
                const responseHours = Number(
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[0]
                );
                const responseMinutes = Number(
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[1]
                );

                if (responseHours < Number(ruleHours)) {
                  rapply = true;
                } else if (responseHours === Number(ruleHours)) {
                  if (responseMinutes < Number(ruleHours)) {
                    rapply = true;
                  } else rapply = false;
                } else {
                  rapply = false;
                }
              } else {
                rapply = false;
              }
            }
          } else if (this.question.logics[i].rules[r].op === 'LTE') {
            if (this.useDate) {
              const mom = moment(
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0],
                'M/D/YYYY'
              );
              const responseDate = mom.toDate();
              if (this.useTime) {
                if (
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[0] !== '' &&
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[1] !== ''
                ) {
                  const responseHours = Number(
                    this.responsePage.sections[this.section].questions[
                      this.questionIndex
                    ].response[1].split(':')[0]
                  );
                  const responseMinutes = Number(
                    this.responsePage.sections[this.section].questions[
                      this.questionIndex
                    ].response[1].split(':')[1]
                  );
                  responseDate.setHours(responseHours);
                  responseDate.setMinutes(responseMinutes);
                }
                dateValue.setHours(Number(ruleHours));
                dateValue.setMinutes(Number(ruleMinutes));
              }

              if (responseDate.getTime() <= dateValue.getTime()) {
                rapply = true;
              } else rapply = false;
            } else if (this.useTime) {
              if (
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0].split(':')[0] !== '' &&
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0].split(':')[1] !== ''
              ) {
                const responseHours = Number(
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[0]
                );
                const responseMinutes = Number(
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[1]
                );

                if (responseHours < Number(ruleHours)) {
                  rapply = true;
                } else if (responseHours === Number(ruleHours)) {
                  if (responseMinutes <= Number(ruleHours)) {
                    rapply = true;
                  } else rapply = false;
                } else {
                  rapply = false;
                }
              } else {
                rapply = false;
              }
            }
          } else if (this.question.logics[i].rules[r].op === 'GT') {
            if (this.useDate) {
              const mom = moment(
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0],
                'M/D/YYYY'
              );
              const responseDate = mom.toDate();
              if (this.useTime) {
                if (
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[0] !== '' &&
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[1] !== ''
                ) {
                  const responseHours = Number(
                    this.responsePage.sections[this.section].questions[
                      this.questionIndex
                    ].response[1].split(':')[0]
                  );
                  const responseMinutes = Number(
                    this.responsePage.sections[this.section].questions[
                      this.questionIndex
                    ].response[1].split(':')[1]
                  );
                  responseDate.setHours(responseHours);
                  responseDate.setMinutes(responseMinutes);
                }
                dateValue.setHours(Number(ruleHours));
                dateValue.setMinutes(Number(ruleMinutes));
              }

              if (responseDate.getTime() > dateValue.getTime()) {
                rapply = true;
              } else rapply = false;
            } else if (this.useTime) {
              if (
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0].split(':')[0] !== '' &&
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0].split(':')[1] !== ''
              ) {
                const responseHours = Number(
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[0]
                );
                const responseMinutes = Number(
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[1]
                );

                if (responseHours > Number(ruleHours)) {
                  rapply = true;
                } else if (responseHours === Number(ruleHours)) {
                  if (responseMinutes > Number(ruleHours)) {
                    rapply = true;
                  } else rapply = false;
                } else {
                  rapply = false;
                }
              } else {
                rapply = false;
              }
            }
          } else if (this.question.logics[i].rules[r].op === 'GTE') {
            if (this.useDate) {
              const mom = moment(
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0],
                'M/D/YYYY'
              );
              const responseDate = mom.toDate();
              if (this.useTime) {
                if (
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[0] !== '' &&
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[1] !== ''
                ) {
                  const responseHours = Number(
                    this.responsePage.sections[this.section].questions[
                      this.questionIndex
                    ].response[1].split(':')[0]
                  );
                  const responseMinutes = Number(
                    this.responsePage.sections[this.section].questions[
                      this.questionIndex
                    ].response[1].split(':')[1]
                  );
                  responseDate.setHours(responseHours);
                  responseDate.setMinutes(responseMinutes);
                }
                dateValue.setHours(Number(ruleHours));
                dateValue.setMinutes(Number(ruleMinutes));
              }

              if (responseDate.getTime() > dateValue.getTime()) {
                rapply = true;
              } else rapply = false;
            } else if (this.useTime) {
              if (
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0].split(':')[0] !== '' &&
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0].split(':')[1] !== ''
              ) {
                const responseHours = Number(
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[0]
                );
                const responseMinutes = Number(
                  this.responsePage.sections[this.section].questions[
                    this.questionIndex
                  ].response[1].split(':')[1]
                );

                if (responseHours > Number(ruleHours)) {
                  rapply = true;
                } else if (responseHours === Number(ruleHours)) {
                  if (responseMinutes >= Number(ruleHours)) {
                    rapply = true;
                  } else rapply = false;
                } else {
                  rapply = false;
                }
              } else {
                rapply = false;
              }
            }
          }

          if (r > 0) {
            if (condition === 'AND') {
              apply = apply && rapply;
            } else if (condition === 'OR') {
              apply = apply || rapply;
            }
          } else {
            apply = rapply;
          }
          condition = this.question.logics[i].rules[r].conjunction;
        }

        for (let a = 0; a < this.question.logics[i].actions.length; a++) {
          let foundIndex: number = null;

          const actionquestion = this.currPage.sections[
            this.section
          ].questions.find((ques, ind) => {
            if (
              ques.identifier === this.question.logics[i].actions[a].identifier
            ) {
              foundIndex = ind;
              return true;
            } else {
              return false;
            }
          });

          if (apply) {
            if (this.question.logics[i].actions[a].name === 'HIDE') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                this.responsePage.sections[this.section].questions[
                  foundIndex
                ].logicallyHidden = true;
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyHiddenBy.find(
                  (item) => item === this.question.id
                );
                if (exists === undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHiddenBy.push(this.question.id);
                }
              }
            } else if (this.question.logics[i].actions[a].name === 'SHOW') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyShownBy.find(
                  (item) => item === this.question.id
                );
                if (exists === undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyShownBy.push(this.question.id);
                }
                if (
                  this.responsePage.sections[this.section].questions[foundIndex]
                    .logicallyHiddenBy.length === 0
                ) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHidden = false;
                } else {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHidden = true;
                }
              }
            }
          } else {
            if (this.question.logics[i].actions[a].name === 'HIDE') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyHiddenBy.find(
                  (item) => item === this.question.id
                );
                if (exists !== undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHiddenBy = this.responsePage.sections[
                    this.section
                  ].questions[foundIndex].logicallyHiddenBy.filter(
                    (item) => item !== this.question.id
                  );
                }
                if (
                  this.responsePage.sections[this.section].questions[foundIndex]
                    .logicallyHiddenBy.length === 0
                ) {
                  if (
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyShownBy.length === 0
                  ) {
                    if (
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].defaultVisibile
                    ) {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = false;
                    } else {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = true;
                    }
                  } else {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = false;
                  }
                }
              }
            } else if (this.question.logics[i].actions[a].name === 'SHOW') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                this.responsePage.sections[this.section].questions[
                  foundIndex
                ].logicallyHidden = true;
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyShownBy.find(
                  (item) => item === this.question.id
                );
                if (exists !== undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyShownBy = this.responsePage.sections[
                    this.section
                  ].questions[foundIndex].logicallyShownBy.filter(
                    (item) => item !== this.question.id
                  );
                }
                if (
                  this.responsePage.sections[this.section].questions[foundIndex]
                    .logicallyShownBy.length === 0
                ) {
                  if (
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHiddenBy.length === 0
                  ) {
                    if (
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].defaultVisibile
                    ) {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = false;
                    } else {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = true;
                    }
                  } else {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = true;
                  }
                } else {
                  if (
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHiddenBy.length === 0
                  ) {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = false;
                  } else {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  inputChanged(event: any) {
    if (
      this.question.preference.isRequired === 1 &&
      (event === null || event === undefined || event === '')
    ) {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].isValid = false;
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].validationMessage = 'Please select a valid date';
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].isValid = true;
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].validationMessage = '';
    }

    this.responsePage.sections[this.section].questions[
      this.questionIndex
    ].response[0] = event;
    const mom = moment(
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response[0],
      'M/D/YYYY'
    );

    this.dateValueforModel = mom.toDate();

    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .isValid
    )
      this.logicPart();
  }

  getValidationValue(question: any, param: string): string {
    const object = question.type.validationValues.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }

  getValue(question: any, param: string): string {
    const object = question.type.values.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }
}
