// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application

import { FormioOptions } from 'angular-formio';

// export const VERSION = process.env.VERSION;
// export const DEBUG_INFO_ENABLED = Boolean(process.env.DEBUG_INFO_ENABLED);
// export const SERVER_API_URL = process.env.SERVER_API_URL;
// export const BUILD_TIMESTAMP = process.env.BUILD_TIMESTAMP;

export const VERSION = 'none';
export const DEBUG_INFO_ENABLED = Boolean(true);
export const SERVER_API_URL = 'http://localhost:3000';
export const BUILD_TIMESTAMP = '1000';
export const FILE_SERVER_URL = 'http://localhost:3000';
export const THUMBOR_URL = 'http://localhost:3000';
export const WEBSITE_URL = 'https://surveymaster.in';

export const filterInterviewsByColumns = [
  { key: 'interviewStatus', value: 'Interview Status' },
  { key: 'unit', value: 'Unit' },
  { key: 'spoc', value: 'SPOC' },
  { key: 'jobRole', value: 'Job role' },
  { key: 'technology', value: 'Technology' },
  { key: 'interviewResult', value: 'Interview Result' },
  { key: 'finalResult', value: 'Final Result' },
];

export const filterCandidatesByColumnsForInterviewer = [
  { key: 'interviewStatus', value: 'Interview Status' },
  { key: 'spoc', value: 'SPOC' },
  { key: 'jobRole', value: 'Job role' },
  { key: 'platform', value: 'Plarform' },
];

export const rejectionReasonList = [
  { key: 'Rejected-Salary', value: 'Rejected-Salary' },
  { key: 'Role Fitment', value: 'Role Fitment' },
  {
    key: 'Behavioral Competencies as per HR',
    value: 'Behavioral Competencies as per HR',
  },
  { key: 'Documents', value: 'Documents' },
  { key: 'Not interested', value: 'Not interested' },
  { key: 'No Hire Organization', value: 'No Hire Organization' },
  { key: 'No Requirement', value: 'No Requirement' },
  { key: 'Acads / Education', value: 'Acads / Education' },
  { key: 'DNH - Organization', value: 'DNH - Organization' },
  { key: 'DNH - University/Institute', value: 'DNH - University/Institute' },
];
export const onHoldReasonList = [
  { key: 'Docs Awaited', value: 'Docs Awaited' },
  { key: 'Exinfy (Fitment)', value: 'Exinfy (Fitment)' },
  { key: 'Candidate Not Responding', value: 'Candidate Not Responding' },
  { key: 'BGC Checks (DNH/Suspect)', value: 'BGC Checks (DNH/Suspect)' },
  { key: 'With the recruiter', value: 'With the recruiter' },
  { key: 'Candidate yet to Revert', value: 'Candidate yet to Revert' },
];

export const candidateRejectionReasonTech = [
  'Fraudulent Activities',
  'Prompting/Lip Syncing',
  'Fake / Impersonation',
];

export const allowedCharactersRegex: RegExp = /^[a-zA-Z0-9 _,.@&()-]*$/;
export const allowedCharactersInMailRegex: RegExp =
  /^[a-zA-Z0-9_][a-zA-Z0-9_.@]*$/;

export const interviewStatus = [
  { value: 'ALL', displayValue: 'ALL' },
  { value: 'UPCOMING', displayValue: 'UPCOMING' },
  { value: 'COMPLETED', displayValue: 'COMPLETED' },
  { value: 'RESCHEDULED', displayValue: 'RESCHEDULED' },
  { value: 'NO_SHOW', displayValue: 'NO SHOW' },
  {
    value: 'SENT_FOR_PANELLIST_REVIEW',
    displayValue: 'SENT FOR PANELLIST REVIEW',
  },
  { value: 'CANCELLED', displayValue: 'CANCELLED' },
  { value: 'FEEDBACK_PENDING', displayValue: 'FEEDBACK PENDING' },
];

export const interviewRoundTypes = ['TECHNICAL', 'MANAGEMENT', 'DESIGN', 'HR','CREATE_OPPORTUNITY','SYNOPSIS','VERIFICATION','ONBOARDING'];

export const lateralJobFeedbackFormTypes = ['SURVEY', 'FORMIO'];

export const formIOPreviewOptions: FormioOptions = {
  alertsPosition: 2, //bottom
};

export const jobApplicantsFilterColumns = [
  // { key: 'opportunityCategory', value: 'Opportunity Category' },
  { key: 'opportunityStatus', value: 'Opportunity Status' },
  { key: 'applicationStatus', value: 'Application Status' },
  { key: 'applicationSubStatus', value: 'Application Sub Status' },
  { key: 'skills', value: 'Skills' },
  // { key: 'educationDetails.degree', value: 'Degree' },
  // { key: 'gender', value: 'Gender' },
  { key: 'domicile', value: 'Domicile' },
  { key: 'careerDetails.jobLocation', value: 'Job Location' },
  { key: 'tags', value: 'Tags' },
];
export const CollegeApplicantsFilters = [
  { key: 'applicationStatus', value: 'Application Status' },
  { key: 'applicationSubStatus', value: 'Application Sub Status' },
  { key: 'tags', value: 'Tags' },
];

export const verifierAdminFilters = [
  {key: 'applicationStatus', value: 'Application Status'},
  {key: 'assignmentStatus', value: 'Assignment Status'},
  {key: 'applicationSubStatus', value: 'Application Sub Status'},
];

export const jobOpeningFilterColumns = [
  { key: 'jobOpeningStatus', value: 'Job Opening Status' },
  { key: 'jobRole.skills', value: 'Skills' },
  { key: 'employmentType', value: 'Employment Type' },
  { key: 'domain', value: 'Domain' },
  { key: 'salaryCurrency', value: 'Salary Currency' },
  { key: 'location', value: 'Location' },
];

export const scholarshipFilterColumns = [
  { key: 'category', value: 'Category' },
  { key: 'subCategory', value: 'Subcategory' },
  { key: 'finance.financeType', value: 'Finance Type' },
  { key: 'finance.financeCategory', value: 'Finance Category' },
  { key: 'locationType', value: 'Location Type' },
  { key: 'location', value: 'Location' },
  { key: 'tags', value: 'Tags' },
  { key: 'status', value: 'Status' },
];

export const verifiedApplicationsColumns = [
  { key: 'applicationStatus', value: 'Application Status' },
]

export const landingPageText = {
  mission: ` <!DOCTYPE html>

  <style>
  div.container {
  background-color: #ffffff;
  }
  div.container p {
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  color: #000000;
  background-color: #ffffff;
  }
  </style>

  <div class="container">
  <p>Our mission is to promote gender equality and empower girls to pursue their dreams in science, technology, engineering, and mathematics fields.</p></div>`,
  impact: `
  <!DOCTYPE html>

  <style>
  div.container {
  background-color: #ffffff;
  }
  div.container p {
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  color: #000000;
  background-color: #ffffff;
  }
  </style>

  <div class="container">
  <p>Infosys Foundation believes that STEM scholarships can have a significant impact on the lives of individuals, as well as on society as a whole. </p></div>`,
  values: `
  <!DOCTYPE html>
  <style>
  div.container {
  background-color: #ffffff;
  }
  div.container p {
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  color: #000000;
  background-color: #ffffff;
  }
  </style>

  <div class="container">
  <p>The foundation is guided by a set of values that reflect our commitment to promoting gender equality, diversity, and inclusion in STEM fields. </p></div>`,
};

export const allDocumentListByCategory = {
  // Personal: [
  ID_PROOF: {
    label: 'Id Proof',
    category: 'Personal',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  PASSPORT_SIZE_PHOTO: {
    label: 'passport size photo',
    category: 'Personal',
    allowedFileTypes: {
      mimeTypes: ['image/jpeg', 'image/png'],
      extensions: ['.jpg', '.jpeg', '.png'],
    },
  },
  RESUME: {
    label: 'RESUME',
    category: 'Personal',
    allowedFileTypes: {
      mimeTypes: [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
      ],
      extensions: ['.doc', '.docx', '.pdf'],
    },
  },
  PROFILE_PIC: {
    label: 'Profile Picture',
    category: 'Personal',
    allowedFileTypes: {
      mimeTypes: ['image/jpeg', 'image/png'],
      extensions: ['.jpg', '.jpeg', '.png'],
    },
  },
  BIRTH_CERTIFICATE: {
    label: 'Birth Certificate',
    category: 'Personal',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  ADDRESS_PROOF: {
    label: 'Address Proof',
    category: 'Personal',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  CATEGORY_CERTIFICATE: {
    label: 'Category Certificate',
    category: 'Personal',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  DISABILITY_CERTIFICATE: {
    label: 'Disability Certificate',
    category: 'Personal',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  PRIVACY_CANDIDATE_UNDER18: {
    label: 'Signed privacy policy document',
    category: 'Personal',
    allowedFileTypes: {
      mimeTypes: [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
      ],
      extensions: ['.doc', '.docx', '.pdf'],
    },
  },
  // ],

  //   Educational: [
  MARKSHEET_10TH: {
    label: '10th Marksheet',
    category: 'Educational',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  PASS_CERTIFICATE_10TH: {
    label: '10th Pass Certificate',
    category: 'Educational',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  MARKSHEET_12TH: {
    label: '12th Marksheet',
    category: 'Educational',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  PASS_CERTIFICATE_12TH: {
    label: '12th Pass Certificate',
    category: 'Educational',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  CONSOLIDATED_MARKSHEET_GRADUATION: {
    label: 'Graduation Consolidated Marksheet',
    category: 'Educational',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  CONSOLIDATED_MARKSHEET_POST_GRADUATION: {
    label: 'Post Graduation Consolidated Marksheet',
    category: 'Educational',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  PROVISIONAL_CERTIFICATE: {
    label: 'Provisional Certificate',
    category: 'Educational',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  DEGREE_CERTIFICATE: {
    label: 'Degree Certificate',
    category: 'Educational',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  TRANSFER_CERTIFICATE: {
    label: 'Transfer Certificate',
    category: 'Educational',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  WORK_EXPERIENCE_CERTIFICATE: {
    label: 'Work Experience certificate',
    category: 'Educational',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  //   ],
  //   Contact: [
  FAMILY_INCOME_PROOF: {
    label: 'Family Income Proof',
    category: 'Contact',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  ELECTRICITY_BILL: {
    label: 'Electricity Bill',
    category: 'Contact',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  //   ],
  //   Scholarship: [
  TUITION_FEE_RECEIPT: {
    label: 'Tution Fee Receipt',
    category: 'Scholarship',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  ADMISSION_LETTER: {
    label: 'Admission Letter',
    category: 'Scholarship',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  SOP: {
    label: 'SOP',
    category: 'Scholarship',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  LETTER_OF_RECOMMENDATION: {
    label: 'Letter Of Recommendation',
    category: 'Scholarship',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  SCORE_CARD: {
    label: 'JEE/CET or NEET Score Card',
    category: 'Scholarship',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  BANK_PROOF: {
    category: 'Scholarship',
  },
  BANK_PASSBOOK: {
    label: 'Bank Passbook',
    category: 'Scholarship',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  CONSOLIDATED_EXPENSES: {
    label: 'Consolidated Expenses',
    category: 'Scholarship',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  CANCELLED_CHEQUE: {
    label: 'Cancelled Cheque',
    category: 'Scholarship',
    allowedFileTypes: {
      mimeTypes: ['application/pdf'],
      extensions: ['.pdf'],
    },
  },
  //   ],
};

export const DATE_FORMAT = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export const allSubStatus = [
  { value: 'FIRST_YEAR' },
  { value: 'SECOND_YEAR' },
  { value: 'THIRD_YEAR' },
  { value: 'FOURTH_YEAR' },
  { value: 'FIFTH_YEAR' },
  { value: 'DOCUMENTS_VERIFIED' },
  { value: 'INTERVIEW_COMPLETED' },
  { value: 'INTERVIEW_PENDING' },
  { value: 'CALL_CANDIDATE_AGAIN' },
  { value: 'DOCUMENT_REVIEW_REQUIRED' },
  { value: 'REVERIFICATION_REQUIRED' },
];


const formioMappings = new Map();
formioMappings.set('ID_PROOF', 'file');
formioMappings.set('PASSPORT_SIZE_PHOTO', 'passportSizePhoto');
formioMappings.set('ADDRESS_PROOF', 'addressProof');
formioMappings.set('FAMILY_INCOME_PROOF', 'familyIncomeProof');
formioMappings.set('ELECTRICITY_BILL', 'electricityBill');
formioMappings.set('BANK_PASSBOOK', 'bankPassbook');
formioMappings.set('CANCELLED_CHEQUE', 'cancelledCheque');
formioMappings.set('MARKSHEET_10TH', 'latestMarksheet');
formioMappings.set('MARKSHEET_12TH', 'ThMarksheet');
formioMappings.set('WA_COLLEGE_VERIFICATION','verificationLetter');
export const formioMappingsMap = formioMappings;
