<app-loader [display]="loading"></app-loader>

<div class="container-fluid layout" *ngIf="!loading">
  <div class="row">
    <div class="col-12">
      <div class="go-back">
        <!-- <div class="col-4"> -->
        <div class="d-flex justify-content-start align-items-center mb-4">
          <div class="ml-3 mr-3" role="button">
            <i class="icon x-24 back-icon ml-1" (click)="_location.back()"> </i>
          </div>
          <div class="h3">Event Settings</div>
        </div>
        <!-- </div> -->
      </div>
    </div>
  </div>

  <div class="row ml-2">
    <div class="col-12">
      <div class="DSA_wb_customTab DSA_navTabWhite DSA_wb_Primary setting-tabs">
        <mat-tab-group
          class="ml-3"
          (selectedTabChange)="changeTab($event)"
          [selectedIndex]="selectedIndex"
          disableRipple
          animationDuration="0s"
        >
          <div class="each-card" *ngFor="let subcategory of subcategories">
            <mat-tab>
              <ng-template mat-tab-label>
                {{ subcategory }}
              </ng-template>
              <div class="container-fluid padTB2">
                <div class="row">
                  <div class="DSA_panel w-100">
                    <div class="DSA_panelBody padLR24">
                      <form (ngSubmit)="onSubmit()" [formGroup]="settingForm">
                        <div
                          *ngFor="
                            let setting of filteredIwpSettings;
                            let last = last
                          "
                          class="col-12 borderBottom"
                          [ngClass]="{ 'last-child': last }"
                        >
                          <app-dynamic-form-field
                            [field]="setting"
                            [form]="settingForm"
                          ></app-dynamic-form-field>
                        </div>
                        <div class="actionBtns">
                          <button
                            mat-button
                            matTooltip="Reset all {{ subcategory }} settings"
                            class="DSA_wb_secondary-btn mr-3"
                            (click)="onReset()"
                            [disabled]="eventStatus === 'CLOSED' || !accountService.hasFeatureAccess(FeatureName.EVENT_SETTINGS, PermissionType.UPDATE)"
                          >
                            Reset
                          </button>
                          <button
                            mat-button
                            matTooltip="Save {{ subcategory }} settings"
                            type="submit"
                            class="DSA_wb_primary-btn"
                            [disabled]="
                              !settingForm.valid || eventStatus === 'CLOSED' || !accountService.hasFeatureAccess(FeatureName.EVENT_SETTINGS, PermissionType.UPDATE)
                            "
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </div>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
