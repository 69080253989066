import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteGuard } from 'src/app/guards/route.guard';
import { InfyCandidateInterviewRoundsComponent } from './infy-candidate-interview-rounds/infy-candidate-interview-rounds.component';
import { InfyCreateEventComponent } from './infy-create-event/infy-create-event.component';
import { InfyDynamicDashboardComponent } from './infy-dynamic-dashboard/infy-dynamic-dashboard.component';
import { InfyEventRecruitersComponent } from './infy-event-recruiters/infy-event-recruiters.component';
import { InfyGlobalSearchComponent } from './infy-global-search/infy-global-search.component';
import { InfyInterviewListComponent } from './infy-interview-list/infy-interview-list.component';
import { InfyJobsComponent } from './infy-jobs/infy-jobs.component';
import { InfyMsauthComponent } from './infy-msauth/infy-msauth.component';
import { InfyPanelistsComponent } from './infy-panelists/infy-panelists.component';
import { InfyScheduleListComponent } from './infy-schedule-list/infy-schedule-list.component';
import { InfySchedulePageComponent } from './infy-schedule-page/infy-schedule-page.component';
import { SettingsComponent } from './settings/settings.component';
import { InfyRecruiterDashboardComponent } from './infy-recruiter-dashboard/infy-recruiter-dashboard.component';

const routes: Routes = [
  // ! Not being used currently, app-routing is used
  {
    path: 'dashboard',
    canActivate: [RouteGuard],
    component: InfyRecruiterDashboardComponent,
  },
  {
    path: 'create-event/:id',
    canActivate: [RouteGuard],
    component: InfyCreateEventComponent,
    children: [
      { path: 'jobs', component: InfyJobsComponent },
      { path: 'panelists', component: InfyPanelistsComponent },
      { path: 'schedule', component: InfyScheduleListComponent },
      { path: 'interviews', component: InfyInterviewListComponent },
      { path: 'recruiters', component: InfyEventRecruitersComponent },
      { path: 'schedule-status', component: InfyDynamicDashboardComponent },
    ],
  },
  {
    path: 'candidate-interview-rounds',
    component: InfyCandidateInterviewRoundsComponent,
  },
  {
    path: 'schedule-interview',
    canActivate: [RouteGuard],
    component: InfySchedulePageComponent,
  },
  {
    path: 'global-search/:searchStr',
    component: InfyGlobalSearchComponent,
  },
  {
    path: 'settings/:category/:categoryId',
    component: SettingsComponent,
  },
  {
    path: 'msauth',
    component: InfyMsauthComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IwpEventRoutingModule {}
