import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScholarshipStateStorageService {
  private disableEditBankProof = true;

  setDisableEditBankProof(value: boolean) {
    this.disableEditBankProof = value;
  }

  getDisableEditBankProof(): boolean {
    return this.disableEditBankProof;
  }
}
