<div class="container-fluid marB32 nopadLR">
  <auto-save
    *ngIf="
      applicationStatus === 'INTERMITTENT' ||
      applicationStatus === 'SENT_BACK_TO_CANDIDATE'
    "
    [storageKey]="localStorageKey"
    [storeItem]="basicDetailsApplicationForm"
    [localTick]="10"
    [dbTick]="60"
    [autoSave]="true"
    [saveInDB]="autoSaveInDB"
    (onDBSave)="onAutoSaveInDB()"
    [candidate]="true"
  ></auto-save>
  <div *ngIf="!formRenderingError; else formRenderingErrorRef">
    <app-loader [display]="isFormLoading"></app-loader>
    <!-- <p *ngIf="!submitting && !isFormLoading" class="red marB4"> Fields marked with * are Mandatory</p> -->
    <!-- <div class="col-12 d-flex download" *ngIf="downloadDisplay">
      <button
        mat-flat-button
        class="DSA_wb_Icon-btn marL8"
        *ngIf="show"
        (click)="handleGenerateClick()"
      >
        <span class="icon_btnCont"
          ><i class="icon x-16 download-icon"></i
        ></span>
        Verification Letter Template
      </button>
      <span class="d-flex" *ngIf="!templateFileId && !spinner">
        <mat-spinner [diameter]="18" class="mat-spinner-color"></mat-spinner>
        <p class="txt">Generating template...</p>
      </span>
      <button
        mat-flat-button
        class="DSA_wb_Icon-btn marL8"
        *ngIf="templateFileId"
        (click)="downloadFile()"
      >
        <span class="icon_btnCont"
          ><i class="icon x-16 download-icon"></i
        ></span>
        Download Template
      </button>
    </div>
    <app-loader [display]="isLoading"></app-loader> -->
    <!-- <ng-container *ngIf="!isLoading"> -->
    <formio
      *ngIf="!isFormLoading"
      [form]="basicForm?.formData"
      [submission]="submission ? submission : { data: null }"
      (ready)="onFormReady($event)"
      (change)="createdFormOnChange($event)"
      (submit)="onSubmit()"
      [refresh]="refreshEmitter"
      [options]="options"
      (nextPage)="handleNextClick($event)"
      (prevPage)="handlePrevClick($event)"
      #formio
    ></formio>
    <p *ngIf="!this.basicDetailsApplicationForm['isValid']" class="red">
      Please make sure all the required fields (marked with *) are filled
    </p>
    <div
      class="d-flex align-items-center marT8"
    >
      <button
        mat-button
        (click)="handleResetClick()"
        class="DSA_wb_secondary-btn text-uppercase marR8 customButton"
        [disabled]="resetDisabled"
      >
        Reset
      </button>
      <button
        mat-button
        class="DSA_wb_primary-btn text-uppercase marL8 customButton"
        (click)="validate()"
        [disabled]="!submitting"
      >         
        {{ displaySubmit ? "Submitting..." :  applicationStatus ==='SUBMITTED' ? 'UPDATE' : 'SUBMIT' }}
      </button>
    </div>
  </div>
  <ng-template #formRenderingErrorRef>
    <app-error-message [errorText]="formRenderingError"></app-error-message>
  </ng-template>
</div>
