import { Attachment, IAttachment } from './attachment.model';

export enum QuestionView {
  TWO_COLUMNS = 'TWO_COLUMNS',
  NORMAL = 'NORMAL',
}
export interface ITheme {
  id?: number;
  identifier?: string;
  bgcolor?: string;
  fontFamily?: string;
  fontStyle?: string;
  fontSize?: string;
  fontWeight?: string;
  opacity?: string;
  zIndex?: string;
  inlineCss?: string;
  background?: IAttachment;
  shadow?: string;
  border?: string;
  color?: string;
  childTheme?: ITheme;
  questionView?: QuestionView;
}

export class Theme implements ITheme {
  constructor(
    public id?: number,
    public identifier?: string,
    public bgcolor?: string,
    public fontFamily?: string,
    public fontStyle?: string,
    public fontSize?: string,
    public fontWeight?: string,
    public opacity?: string,
    public zIndex?: string,
    public inlineCss?: string,
    public background?: IAttachment,
    public shadow?: string,
    public border?: string,
    public color?: string,
    public childTheme?: ITheme,
    public questionView?: QuestionView
  ) {
    this.bgcolor = '#cfe2f3ff';
    this.fontFamily = 'Roboto';
    this.fontSize = '16px';
    this.fontStyle = 'black';
    this.opacity = 'unset';
    this.fontWeight = 'normal';
    this.inlineCss = null;
    this.zIndex = 'unset';
    this.identifier = null;
    this.border = 'none';
    this.background = null;
    this.childTheme = null;
    (this.color = null), (this.shadow = '0px 1px 8px rgba(0, 0, 0, 0.26)');
    this.questionView = QuestionView.NORMAL;
  }
}
