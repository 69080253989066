import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CandidateInfoService } from '../services/candidate-info.service';
import { MailTemplateService } from 'src/app/services/mail-template.service';
import { SharedService } from 'src/app/services/shared.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-provisional-offer-letter',
  templateUrl: './provisional-offer-letter.component.html',
  styleUrls: ['./provisional-offer-letter.component.scss'],
})
export class ProvisionalOfferLetterComponent implements OnInit {
  applicationId: any;
  declarationTemplateId: any;
  declarationData: any;
  isLoading: boolean = false;
  isProvisionalAccepted: boolean = false;
  scholarshipDocumentForm: FormGroup = new FormGroup({});
  documentId: any;
  content;
  messageOnLoading = 'Data is loading, please wait...';
  bankProofType = ['BANK_PASSBOOK', 'CANCELLED_CHEQUE'];
  scholarshipDocumentList = [];
  bankProofControl: FormControl;
  candidateFinancialInfoForm: FormGroup = new FormGroup({});
  candidateFinancialInfo;
  enableEdit = false;
  candidateInfoId;
  opportunityId = 1051;
  fileUploaded = false;
  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private _candidateInfoService: CandidateInfoService,
    private _mailTemplateService: MailTemplateService,
    private _sharedService: SharedService,
    private router: Router,
    public sharedService: SharedService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.fileUploaded = false;
    this.route.params.subscribe((params) => {
      this.applicationId = params['jobid'];
    });
    this.getIssuedDocuments(this.applicationId);

    this.candidateFinancialInfoForm = this.fb.group({
      accountDetails: this.fb.group({
        IFSC: ['', Validators.required],
        // upiId: [''],
        number: ['', [Validators.required, Validators.pattern('^[0-9]{10,}$')]],
      }),
      id: [''],
      isPrimary: [''],
      payeeName: ['', Validators.required],
      paymentMethod: ['BANK_ACCOUNT'],
      personalDetailsId: [''],
    });
    this.bankProofControl = new FormControl('', Validators.required);
    this.getCandidateDetails();
  }

  /**
   *
   * @param applicationId
   * It will gives list of issued document
   */
  getIssuedDocuments(applicationId) {
    this._candidateInfoService.getIssuedDocumentsList(applicationId).subscribe({
      next: (data: any) => {
        this.declarationTemplateId = data[0].declarationTemplateId;
        this.declarationData = data[0].declarationData;
        this.documentId = data[0].id;
        this.getProvisionalOfferLetterTemplate(this.declarationTemplateId);
      },
      error: (err) => {
        this._sharedService.openErrorSnackBar(
          'Something Went Wrong! Please try again!',
          ' '
        );
      },
    });
  }

  getCandidateDetails() {
    this._candidateInfoService
      .getCandidateProfileUIDTOForCandidate()
      .subscribe({
        next: (data) => {
          this.candidateInfoId = data.candidateInfoId;
          this.candidateFinancialInfoForm.patchValue(
            data?.uiScholarshipDetailsDTO?.financialDetailsDTOList[0]
          );

          if (
            this.candidateFinancialInfoForm.value.paymentMethod ===
            'BANK_ACCOUNT'
          ) {
            this.enableEdit = false;
            this.candidateFinancialInfoForm.disable();
          } else {
            this.enableEdit = true;
            this.candidateFinancialInfoForm.patchValue({
              paymentMethod: 'BANK_ACCOUNT',
            });
          }

          this.candidateFinancialInfo = data;
        },
        error: (err) => {
          this.sharedService.openErrorSnackBar(
            'Something Went Wrong! Please try again!',
            ' '
          );
        },
      });
  }

  /**
   *
   * @param declarationTemplateId
   * It will fetch the template structure for provisional offer letter
   */
  getProvisionalOfferLetterTemplate(declarationTemplateId) {
    this._mailTemplateService.getMailTemplate(declarationTemplateId).subscribe({
      next: (data: any) => {
        this.content = data;
        this.content.templateString = this.replaceParameterValues(
          this.content.templateString,
          this.declarationData
        );
        this.isLoading = true;
      },
      error: (err) => {
        this._sharedService.openErrorSnackBar(
          'Something Went Wrong! Please try again!',
          ' '
        );
      },
    });
  }

  /**
   *
   * @param templateString
   * @param parameters
   * @returns It will use a regular expression to find ans replace each parameter with their value
   */
  replaceParameterValues(templateString: string, parameters: any): string {
    for (let key in parameters) {
      if (parameters.hasOwnProperty(key)) {
        let value = parameters[key];
        let regexKey = new RegExp('\\${' + key + '}', 'g');
        templateString = templateString.replace(regexKey, value);
      }
    }
    return templateString;
  }

  acceptValidation() {
    const reqBody = {
      accepted: true,
      documentId: this.documentId,
    };
    this._candidateInfoService
      .acceptProvisioalOfferLetter(this.documentId, reqBody)
      .subscribe({
        next: (data) => {
          this._sharedService.openSuccessSnackBar(
            'Successfully accepted provisional offer letter.',
            ' '
          );
          this.enableEdit = false;
          this.router.navigate(['candidate/problem-statements']);
        },
        error: (err) => {
          this._sharedService.openErrorSnackBar(
            'Something Went Wrong! Please try again!',
            ' '
          );
        },
      });
  }

  handleProceedClick() {
    if (!this.enableEdit) {
      this.acceptValidation();
    } else {
      if (this.candidateFinancialInfoForm.invalid) {
        this.candidateFinancialInfoForm.markAllAsTouched();
        return;
      }
      this._candidateInfoService
        .updateFinancialDetails(this.candidateFinancialInfoForm.value)
        .subscribe({
          next: (data: any) => {
            this.acceptValidation();
          },
          error: (error) => {
            this._sharedService.openErrorSnackBar(
              'Something Went Wrong! Please try again!',
              ' '
            );
          },
        });
    }
  }
  onBankProofSelect(selectedOption) {
    // if (this.bankProofUploaded) {
    //   // We need to remove previous proof if it's there.
    //   this.scholarshipDocumentList = this.scholarshipDocumentList.filter(
    //     (value) => value !== this.bankProofUploaded
    //   );
    //   delete this.fileUploadedHashMap[this.bankProofUploaded];
    //   this.scholarshipDocumentForm.removeControl(this.bankProofUploaded, {
    //     emitEvent: false,
    //   });
    // }
    //this.scholarshipDocumentList.push(selectedOption.value);
    //  pushing it at the 0 the index
    this.scholarshipDocumentList[0] = selectedOption.value;

    this.scholarshipDocumentForm.addControl(
      selectedOption.value,
      new FormControl('Attach Doc', [
        Validators.required,
        this.validateDocuments(selectedOption.value),
      ]),
      { emitEvent: false }
    );
  }
  validateDocuments(document): ValidatorFn {
    return (uploadValue: AbstractControl) => {
      return uploadValue.value === 'Attach Doc'
        ? { errorMessage: 'Please attach and upload a file.' }
        : null;
    };
  }

  handleWithDrawClick() {
    const condirmRef = this.dialog.open(ConfirmationComponent, {
      data: {
        text: 'Are you sure you would like to withdraw your scholarship application? This is a one-time action and cannot be reversed.',
      },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });

    condirmRef.afterClosed().subscribe((val) => {
      if (val !== 'Yes') return;
      const formValue = {
        applicationStatus: 'WITHDRAWN',
      };
      this._candidateInfoService
        .updateJobApplication(this.applicationId, formValue)
        .subscribe({
          next: (data) => {
            this._sharedService.openSuccessSnackBar(
              'Successfully withdrawn scholarship application.',
              ' '
            );
            this.router.navigate(['candidate/problem-statements']);
          },
          error: (err) => {
            this._sharedService.openErrorSnackBar(
              'Something Went Wrong! Please try again!',
              ' '
            );
          },
        });
    });
  }

  handleFileUpload(event) {
    if (event.fileUploaded) this.fileUploaded = true;
    else this.fileUploaded = false;
  }
}
