import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { FileService } from 'src/app/services/file.service';
import * as fileSaver from 'file-saver';

import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { EmailService } from 'src/app/services/email.service';
import { SharedService } from 'src/app/services/shared.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  templateUrl: './mail-status-renderer.html',
})
export class MailStatusActionRendererComponent implements AgRendererComponent {
  params: any;
  data: any;
  recipients: any;
  type: any;
  interviewId: number;
  constructor(
    private fileService: FileService,
    private emailService: EmailService,
    private sharedService: SharedService,
    private dialog: MatDialog
  ) {}

  agInit(params: any): void {
    this.params = params;
    if (params.data) {
      this.recipients = params.data.recipients;
      if (this.recipients.length > 0) {
        this.interviewId = params.data.recipients[0].specId;
      }
    }
    if (params.type) {
      this.type = params.type;
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  resendEmail = (interviewId, name) => {
    let text = 'Are you sure you want resend email to Interviewer?';
    if (name === 'candidate') {
      text = 'Are you sure you want resend email to Candidiate?';
    }
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: { text },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Yes') {
        this.params.context.componentParent.showLoadingOverlay();
        this.emailService.resendEmail(interviewId, name).subscribe(
          () => {
            this.sharedService.openSnackBar('Sent Successfully', '', 3000);
            this.params.context.componentParent.refreshTable();
          },
          (err) => {
            this.params.context.componentParent.setGridUI();
            this.sharedService.openSnackBar('Some error occured', '', 3000);
          }
        );
      }
    });
  };
}
