<header
  class="DSA_Header clearfix ctr_header"
  [ngClass]="{ 'landing-page-header': !isLoggedIn }"
  id="scrollHeader"
>
  <div class="d-flex align-items-center h-100 container-fluid">
    <div class="left-section d-flex align-items-center">
      <!-- <div
      class="left-section align-items-center" style="margin-top: 10px;"
      [ngClass]="{ 'd-flex': isLoggedIn }"
      
    > -->
      <a
        href="javascript:void(0)"
        class="DSA_Hamburger icon x-24 icon-Hamburger white float-left"
        (click)="toggleNavbar()"
        id="sidenav-icon"
        *ngIf="isLoggedIn"
      ></a>
      <a
        routerLink="/"
        href="javascript:void(0)"
        class="marL16 float-left logo margin-right-2"
      >
        <img  class="edit-h-w" [src]="'assets/images/logo-wa.png'" />
      </a>
    </div>

    <ul
      class="d-flex align-items-center nomarT nomarB marL-auto"
      *ngIf="isLoggedIn"
    >
      <li class="marR16" *ngIf="tenantsList.length > 0">
        <mat-form-field
          class="
            DSA_Wb_custom-form-field
            custom-select-withBg
            nav-dropdown
            tenant-select
          "
          [hidden]="role == 'ROLE_CANDIDATE'"
        >
          <mat-label>Organization</mat-label>
          <mat-select
            disableOptionCentering
            panelClass="DSA_wb-custom-select-panel DSA_wb-custom-select-withBg tenant-select"
            [value]="selectedTenantId"
            (selectionChange)="onTenantChange($event)"
          >
            <mat-option
              *ngFor="let tenant of tenantsList"
              [value]="tenant.id"
            >
              {{ tenant.organization }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li>
      <li class="d-none d-md-block">
        <!-- show name and role on medium and large screen along with avatar icon -->
        <p
          href="javascript:void(0)"
          class="
            padTB16
            DSA_mainactiveLink
            font-white
            d-flex
            flex-column
            align-items-end
          "
          style="font-size: 1.4rem"
        >
          <span>{{ profile.firstName }}</span>
          <!-- <span class="text-right">{{
            getUIAuthorityName(AuthorityName[role])
          }}</span> -->
        </p>
      </li>
      <!-- <li class="d-none d-md-block">
       
        <p
          href="javascript:void(0)"
          class="
            padTB16
            DSA_mainactiveLink
            font-white
            d-flex
            flex-column
            align-items-end
          "
          style="font-size: 1.4rem"
        >
          <span>{{ profile.firstName }}</span>
          
        </p>
      </li> -->
      <li>
        <a
          href="javascript:void(0)"
          class="DSA_Profile padTB8 padL16 DSA_mainactiveLink"
        >
          <div
            ngbDropdown
            class="d-inline-block marR8 vMid"
            container="body"
            #lgDropdown="ngbDropdown"
          >
            <span
              href="javascript:void(0);"
              id="OverflowMenu_dd"
              ngbDropdownToggle
              class="DSA_dropdown-iconOnly"
            >
              <!-- show avatar on medium and large screen -->
              <div class="d-none d-md-block">
                <ngx-avatar
                  *ngIf="profile.firstName; else avatarIcon"
                  [name]="profile.firstName"
                  [size]="36"
                  fgColor="#007c64"
                  bgColor="#e5f7f3"
                  textSizeRatio="2"
                ></ngx-avatar>
                <ng-template #avatarIcon>
                  <img
                    class="avatarImageCircle"
                    src="assets/images/avatar-294480_640.png"
                  />
                </ng-template>
              </div>
              <!-- show menu icon on small screen -->
              <i class="icon x-24 v-menu-icon white d-block d-md-none"></i>
            </span>
            <div ngbDropdownMenu aria-labelledby="OverflowMenu_dd">
              <ul
                class="
                  DSA_wb_listItemSingle DSA_wb_sec_listItemSingle DSA_frstLevel
                "
              >
                <li>
                  <div class="clearfix">
                    <h2
                      class="
                        DSA_wb_mainBodyTxt2
                        web-GreyLight
                        menu-withoutIcon
                        padT8
                        padLR16
                      "
                    >
                      {{ profile.firstName }}
                      <!-- <br />
                      {{ getUIAuthorityName(AuthorityName[role]) }} -->
                    </h2>
                  </div>
                  <hr />
                </li>

                <li
                  *ngIf="roleType?.length !== 0"
                  [hidden]="role == 'ROLE_CANDIDATE'"
                >
                  <p
                    class="
                      col-12
                      d-inline-block
                      padT8
                      pl-2
                      pr-2
                      mb-0
                      DSA_mainactiveLink
                      font-white
                    "
                  >
                    <mat-form-field
                      class="custom-form-field custom-select-withBg"
                      appearance="outline"
                      style="padding-left: 8px"
                    >
                      <mat-label
                        style="font-size: 1.4rem !important"
                        class="marB4"
                        >Switch role</mat-label
                      >
                      <mat-select
                        [(value)]="roleSelected"
                        style="font-size: 1.4rem"
                        (selectionChange)="changeRole($event)"
                        disableOptionCentering
                        panelClass="DSA_wb-custom-select"
                      >
                        <mat-option
                          *ngFor="let option of roleTypeUI; let i = index"
                          style="
                            font-size: 1.4rem;
                            max-width: calc(100% + 16px) !important;
                          "
                          [value]="roleType[i]"
                        >
                          {{ option }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </p>
                </li>

                <!-- ! Admin dashboard large screen-->
                <li
                  *ngIf="
                    accountService.hasFeatureAccess(
                      FeatureName.ANALYTICS,
                      PermissionType.READ
                    )
                  "
                  routerLink="/analytics-dashboard"
                >
                  <a
                    href="javascript:void(0);"
                    class="padLR8"
                    (click)="lgDropdown.close()"
                  >
                    <div class="marR16 float-left">
                      <i class="icon x-24 graph-icon"> </i>
                    </div>
                    <h2 class="DSA_wb_mainBodyTxt2 web-GreyLight">
                      Analytics Dashboard
                    </h2>
                  </a>
                </li>
                <!-- <li>
                  <a
                    *ngIf="role === AuthorityName.ROLE_CANDIDATE"
                    [routerLink]="['/candidate/candidate-profile']"
                    [state]="{checkForLocalStepState: true}"
                    (click)="lgDropdown.close()"
                    href="javascript:void(0);"
                    class="padLR8"
                  >
                    <div class="marR16 float-left align-icon">
                      <i class="icon x-24 personal-icon"> </i>
                    </div>

                    <h2 class="DSA_wb_mainBodyTxt2 web-GreyLight">
                      My Profile
                    </h2>
                  </a>
                </li> -->

                <!-- ! Super admin dashboard large screen-->
                <!-- <li *ngIf="role === 'SUPERADMIN'" routerLink="/infy-jobs-admin">
                  <a
                    href="javascript:void(0);"
                    class="padLR8"
                    (click)="lgDropdown.close()"
                  >
                    <div class="marR16 float-left">
                      <i class="icon x-24 personal-icon"> </i>
                    </div>

                    <h2 class="DSA_wb_mainBodyTxt2 web-GreyLight">
                      S.Admin Dashboard
                    </h2>
                  </a>
                </li> -->

                <!-- ! Logout large screen-->
                <li (click)="logout()">
                  <a href="javascript:void(0);" class="padLR8">
                    <div class="marR16 float-left align-icon">
                      <i class="icon x-24 icon_logout"> </i>
                    </div>

                    <h2 class="DSA_wb_mainBodyTxt2 web-GreyLight">Logout</h2>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</header>
<app-sidenav
  [role]="role"
  [roleUI]="getUIAuthorityName(AuthorityName[role])"
></app-sidenav>
<nav
  class="DSA_Appnav DSA_navHide ctr_nav ts_mainNav"
  id="mainNav"
  (click)="$event.stopPropagation()"
>
  <div class="clearfix">
    <a
      href="javascript:void(0)"
      class="
        DSA_Hamburger
        icon
        x-24
        icon-Hamburger
        purple
        marL16
        marT24
        float-left
      "
    ></a>
  </div>

  <ul class="marT32 DSA_wb_listItemSingle DSA_frstLevel">
    <li *ngIf="role === AuthorityName.ROLE_CANDIDATE">
      <a
        href="javascript:void(0)"
        [routerLink]="['candidate/view-applications']"
      >
        <i
          aria-hidden="true"
          class="icon x-24 upload-icon marR16 float-left"
        ></i>
        <h5 class="DSA_h5Header web-GreyDarkest DSA_list-1line">
          Applied Problem Statements
        </h5>
      </a>
    </li>
  </ul>
</nav>
