import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgJhipsterModule } from 'ng-jhipster';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/angular-material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ColorSketchModule } from 'ngx-color/sketch'; // <color-sketch></color-sketch>
import { ClickOutsideModule } from 'ng-click-outside';

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { GatewaySharedModule } from './shared.module';
@NgModule({
  exports: [
    GatewaySharedModule,
    MaterialModule,
    FormsModule,
    CommonModule,
    NgbModule,
    NgJhipsterModule,
    InfiniteScrollModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    ColorSketchModule,
    ClickOutsideModule,
    NgxSpinnerModule,
    Ng2SearchPipeModule,
    TranslateModule,
  ],
})
export class SurveymasterSharedLibsModule {}
