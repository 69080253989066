import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// import { AppMaterialModule } from "../app-material.module";
// import { SharedModule } from "../../shared/shared.module";
import { CandidateComponent } from './candidate.component';
// import { candidateRoute } from "./candidate.route";
import { ViewAllJobsComponent } from './view-all-jobs/view-all-jobs.component';
import { ViewJobDetailsComponent } from './view-job-details/view-job-details.component';
import { CandidateRoutingModule } from './candidate-routing.module';
import { JobCardComponent } from './view-all-jobs/job-card/job-card.component';
import { UploadResumeComponent } from './upload-resume/upload-resume.component';
import { ApplyJobComponent } from './apply-job/apply-job.component';
import { CandidateApplicationsComponent } from './candidate-applications/candidate-applications.component';
import { ApplicationCardComponent } from './candidate-applications/application-card/application-card.component';
import { ViewApplicationDetailsComponent } from './candidate-applications/view-application-details/view-application-details.component';
//import { CoreModule } from "src/app/core";

//import { MaterialModule } from 'src/app/angular-material.module';
import { GatewaySharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { MatCarouselModule } from 'ng-mat-carousel';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ScholarshipInstructionsComponent } from './scholarship-instructions/scholarship-instructions.component';
// import { JobCardComponent } from './view-all-jobs/job-card/job-card.component';
import { EligibilityCriteriaComponent } from './eligibility-criteria/eligibility-criteria.component';
import { SubmitScholarshipComponent } from './submit-scholarship/submit-scholarship.component';
import { CandidateProfileComponent } from './candidate-profile/candidate-profile.component';
import { PersonalDetailsComponent } from './candidate-profile/personal-details/personal-details.component';
import { ApplyStepperComponent } from './apply-stepper/apply-stepper.component';
import { TeamDetailsComponent } from './candidate-profile/team-details/team-details.component';
import { EducationDetailsComponent } from './candidate-profile/education-details/education-details.component';
import { ContactDetailsComponent } from './candidate-profile/contact-details/contact-details.component';
import { ScholarshipDetailsComponent } from './candidate-profile/scholarship-details/scholarship-details.component';
import { ApplicationStepperComponent } from './application-stepper/application-stepper.component';
import { ScholarshipCardV1Component } from './view-all-jobs/scholarship-card-v1/scholarship-card-v1.component';
import { SingleFileUploadComponent } from './application-stepper/single-file-upload/single-file-upload.component';
import { BasicDetailsComponent } from './candidate-profile/basic-details/basic-details.component';
import { ResumeProcessingDialogComponent } from './upload-resume/resume-processing-dialog/resume-processing-dialog.component';
import { MaterialModule } from 'src/app/angular-material.module';
import { ProvisionalOfferLetterComponent } from './provisional-offer-letter/provisional-offer-letter.component';
import { FormioModule } from 'angular-formio';
import { SynopsisTemplateComponent } from './candidate-profile/synopsis-template/synopsis-template.component';
import { TablistTemplateComponent } from './candidate-profile/tablist-template/tablist-template.component';

@NgModule({
  imports: [
    CandidateRoutingModule,
    RouterModule,
    CommonModule,
    // CoreModule,
    GatewaySharedModule,
    MaterialModule,
    FormioModule
  ],
  declarations: [
    CandidateComponent,
    JobCardComponent,
    ViewAllJobsComponent,
    ViewJobDetailsComponent,
    EducationDetailsComponent,
    UploadResumeComponent,
    ApplyJobComponent,
    CandidateApplicationsComponent,
    ApplicationCardComponent,
    ViewApplicationDetailsComponent,
    ScholarshipInstructionsComponent,
    JobCardComponent,
    EligibilityCriteriaComponent,
    SubmitScholarshipComponent,
    CandidateProfileComponent,
    ApplyStepperComponent,
    PersonalDetailsComponent,
    TeamDetailsComponent,
    ContactDetailsComponent,
    ScholarshipDetailsComponent,
    ApplicationStepperComponent,
    ScholarshipCardV1Component,
    SingleFileUploadComponent,
    BasicDetailsComponent,
    ResumeProcessingDialogComponent,
    ProvisionalOfferLetterComponent,
    SynopsisTemplateComponent,
    TablistTemplateComponent,
  ],
  exports: [
    JobCardComponent,
    SubmitScholarshipComponent,
    ScholarshipCardV1Component,
    EligibilityCriteriaComponent,
  ],
  providers: [],
})
export class CandidateModule {}
