import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExtractedResumeDetailComponent } from './components/extracted-resume-detail/extracted-resume-detail.component';
import { SkillDetailsComponent } from './components/skill-details/skill-details.component';
import { PersonalDetailsComponent } from './components/personal-details/personal-details.component';
import { ProjectDetailsComponent } from './components/project-details/project-details.component';
import { ContactDetailsComponent } from './components/contact-details/contact-details.component';
import { TeamDetailsComponent } from './components/education-details/education-details.component';
import { CareerDetailsComponent } from './components/career-details/career-details.component';
import { GatewaySharedModule } from 'src/app/shared/shared.module';
import { ExtractedResumeRoutingModule } from './extracted-resume-routing.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatCarouselModule } from 'ng-mat-carousel';

@NgModule({
  declarations: [
    PersonalDetailsComponent,
    ProjectDetailsComponent,
    ContactDetailsComponent,
    CareerDetailsComponent,
    TeamDetailsComponent,
    SkillDetailsComponent,
    ExtractedResumeDetailComponent,
  ],
  imports: [
    CommonModule,
    GatewaySharedModule,
    ExtractedResumeRoutingModule,
    PdfViewerModule,
    MatCarouselModule,
  ],
  exports: [ExtractedResumeDetailComponent],
})
export class ExtractedResumeModule {}
