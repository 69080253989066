<div style="border-radius: 4px;overflow-y: auto;overflow-x:hidden;height:fit-content;background: white;">
    <div style="min-height: 100%;padding:0px;margin-left:10px;margin-right:10px; ">
        <p style="font-weight: bold;font-size: 20px;">Respondant Details</p>
        <mat-grid-list cols="3" rowHeight="50px">
            <mat-grid-tile>
                <div style="width:100%">
                    <mat-label>First Name</mat-label>
                </div>
            </mat-grid-tile>
            <mat-grid-tile colspan="2">
                <div style="width:100%">
                    <mat-form-field style="width:95%" appearance="fill" >
                    
                        <input matInput readonly [value]="respondant.firstName? respondant.firstName : ''"/>
                        
                      </mat-form-field>
                </div>
            </mat-grid-tile>
            <mat-grid-tile>
                <div style="width:100%">
                    <mat-label>Last Name</mat-label>
                </div>
            </mat-grid-tile>
            <mat-grid-tile colspan="2">
                <div style="width:100%">
                    <mat-form-field style="width:95%" appearance="fill" >
                    
                        <input matInput readonly [value]="respondant.lastName? respondant.lastName : ''"/>
                        
                      </mat-form-field>
                </div>
            </mat-grid-tile>
            <mat-grid-tile>
                <div style="width:100%">
                    <mat-label>Email</mat-label>
                </div>
            </mat-grid-tile>
            <mat-grid-tile colspan="2">
                <div style="width:100%">
                    <mat-form-field style="width:95%" appearance="fill" >
                    
                        <input matInput readonly [value]="respondant.email? respondant.email : ''"/>
                        
                      </mat-form-field>
                </div>
            </mat-grid-tile>
        </mat-grid-list>


    </div>
</div>