import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MigrateCandidateDataComponent } from 'src/app/modules/iwp-platform-actions/migrate-candidate-data/migrate-candidate-data.component';
// import { InfyRaiseFeatureExceptionComponent } from 'src/app/infy-components/infy-raise-feature-exception/infy-raise-feature-exception.component';
import { AccountService } from 'src/app/services/account.service';
import { SharedService } from 'src/app/services/shared.service';
import { AuthorityName } from 'src/app/shared/model/enumerations/authority-name.model';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  AuthorityName = AuthorityName;
  FeatureName = FeatureName;
  PermissionType = PermissionType;

  constructor(
    private accountService: AccountService,
    private dialog: MatDialog
  ) {}
  @Input() role: string = '';
  @Input() roleUI: string = '';

  ngOnInit(): void {}

  sideBarClose() {
    if (
      !document.getElementById('mainleftNav').classList.contains('sidenav-hide')
    ) {
      document.getElementById('mainleftNav').classList.remove('sidenav-hide');
    }
  }

  openMigrationUploadDialog() {
    const dialog = this.dialog.open(MigrateCandidateDataComponent, {
      autoFocus: false,
      disableClose: true,
    });
  }
}
