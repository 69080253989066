<div class="container-fluid marT24">
  <div class="d-flex justify-content-start align-items-center mb-3">
    <div class="ml-3 mr-3" role="button" (click)="goToEventsPage()">
      <i class="icon x-24 back-icon" > </i>
    </div>
    <div class="h3">Complete Event Setup</div>
  </div>
  <div class="row mt-5 pad24">
    <div class="col-lg-12">
      <div class="DSA_wb_customTab event-setup-stepper marT16">
        <!-- <mat-tab-group disableRipple>
          <mat-tab
            *ngFor="let link of eventSetupLinks; let i = index; last as isLast"
          > -->
        <!-- <ng-template mat-tab-label> -->
        <nav mat-tab-nav-bar>
          <ng-container
            *ngFor="let link of eventSetupLinks; let i = index; last as isLast"
          >
            <a
              class="DSA_wb_hyperLink-btn marLR16 d-flex flex-column align-items-center"
              [routerLink]="link.link"
              routerLinkActive
              #rla="routerLinkActive"
              [active]="rla.isActive"
              [hidden]="link.hide"
              [style.opacity]="rla.isActive ? 1 : 0.5"
            >
              <span
                class="DSA_wb_stepCircle"
                [ngClass]="{ 'active-step': rla.isActive }"
              >
                {{ i }}
              </span>
              <span class="DSA_wb_stepTxt">{{ link.label }}</span>
            </a>
            <span *ngIf="!isLast" class="guided-stepper"></span>
          </ng-container>
        </nav>
        <!-- <span *ngIf="!isLast" class="DSA_guidedSeperator"></span> -->
        <!-- </ng-template> -->
        <!-- </mat-tab>
        </mat-tab-group> -->
      </div>
    </div>
    <hr />
    <div class="w-100">
      <router-outlet></router-outlet>
    </div>
    <div class="d-flex ml-auto padR24 marT24">
      <button
        class="DSA_wb_miniPrimary-btn"
        type="submit"
        (click)="navigateNext()"
      >
        Next
      </button>
    </div>
  </div>
</div>
