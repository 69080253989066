<fieldset [disabled]="disabled">
  <div *ngIf="view==='TWO_COLUMNS';else oneColumn">
    <div class="row">
      <div class="col-6" style="display: flex;flex-direction: column;justify-content:center;">
        <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
            {{questionIndex+1}}.
          </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
            *
          </span></p>

        <span style="color: grey; font-style:italic">(Click on the map to select a location.)</span><br>
        <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">

      </div>
      <div class="col-6">
        <div id="map" class="map" (click)="getCoord($event)"></div>


      </div>
    </div>
  </div>

  <ng-template #oneColumn>
    <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
        {{questionIndex+1}}.
      </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
        *
      </span></p>

    <span style="color: grey; font-style:italic">(Click on the map to select a location.)</span><br>
    <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">

    <div id="map" class="map" (click)="getCoord($event)"></div>

  </ng-template>



</fieldset>
