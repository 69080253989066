<div class="row">
    <div class="breadcrumb " *ngIf="!isMobile();else mobileView">
        <a (click)="navigate('design')" [ngClass]="{'active': isActive('design')}">Design</a>
        <mat-icon class="arrow">arrow_right_alt</mat-icon>
        <a  (click)="navigate('links')"  [ngClass]="{'active': isActive('links')}">Links And Invites</a>
        <mat-icon class="arrow">arrow_right_alt</mat-icon>
        <a (click)="navigate('thankyouDesign')"  [ngClass]="{'active': isActive('thankyouDesign')}">Thank you Design</a>
        <mat-icon class="arrow">arrow_right_alt</mat-icon>
        <a (click)="navigate('responses')"  [ngClass]="{'active': isActive('responses')}">Responses</a>
        <mat-icon class="arrow">arrow_right_alt</mat-icon>
        <a (click)="navigate('analysis')"  [ngClass]="{'active': isActive('analysis')}">Analysis</a>
        
    </div>
    <ng-template #mobileView>
            <button style="margin-right:16px; margin-top:auto;margin-bottom: auto;"
                matTooltip="Go To.." [matMenuTriggerFor]="navigateMenu" mat-icon-button>
                <mat-icon >more_horiz</mat-icon>
              </button>

              <mat-menu #navigateMenu="matMenu">
                <mat-list>
                    <div mat-menu-item (click)="navigate('design')">
                            
                        <mat-icon>palette</mat-icon>
                        <mat-label>Design</mat-label>
                        <!-- <mat-icon style="cursor: pointer; font-size: 20px;">palette</mat-icon> -->
                    </div>
                    <!-- </span> -->
                    <!-- <span *ngIf="row.status === 'PUBLISHED'"> -->
                    <div mat-menu-item (click)="navigate('links')">
                        <mat-icon >share</mat-icon>
                        <mat-label>Links</mat-label>
                        <!-- <mat-icon style="cursor: pointer; font-size: 20px;">share</mat-icon> -->
                    </div>
                    <div mat-menu-item (click)="navigate('thankyouDesign')">
                        <mat-icon>exit_to_app</mat-icon>
                        <mat-label>Thank you Design</mat-label>
                        <!-- <mat-icon style="cursor: pointer; font-size: 20px;">reply_all</mat-icon> -->
                    </div>
                    <div mat-menu-item (click)="navigate('responses')">
                        <mat-icon>reply_all</mat-icon>
                        <mat-label>Responses</mat-label>
                        <!-- <mat-icon style="cursor: pointer; font-size: 20px;">reply_all</mat-icon> -->
                    </div>
                    <div mat-menu-item (click)="navigate('analysis')">
                        <mat-icon>score</mat-icon>
                        <mat-label>Analysis</mat-label>
                        <!-- <mat-icon style="cursor: pointer; font-size: 20px;">reply_all</mat-icon> -->
                    </div>
                    
                </mat-list>
              </mat-menu>
    </ng-template>
</div>