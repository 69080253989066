import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'jhi-survey-navigation',
  templateUrl: './survey-navigation.component.html',
  styleUrls: ['./survey-navigation.component.scss']
})
export class SurveyNavigationComponent implements OnInit {
  application: any;
  surveyDefinition: any;

  @Input()
  noMobile: boolean;

  @Output() designValidate = new EventEmitter<any>();
  @Input()
  isDesignValidationRequired: boolean;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private mediaObserver: MediaObserver) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ application }) => {
      this.application = application;
      this.activatedRoute.data.subscribe(({ surveyDefinition }) => {
        this.surveyDefinition = surveyDefinition;
      });
    });
  }

  isMobile(): boolean {
    if (this.noMobile) return false;
    return this.mediaObserver.isActive('xs');
  }

  isActive(page: string) {
    const ranking = {
      design: 1,
      links: 2,
      thankyouDesign: 3,
      responses: 4,
      analysis: 5
    };
    for (const key in ranking) {
      if (ranking[key] != null) {
        const element = ranking[key];
        let urlVal = key;
        if (key === 'thankyouDesign') {
          urlVal = 'thank-you-design';
        }
        if (this.router.isActive(`/${this.application.identifier}/survey/${this.surveyDefinition.identifier}/${urlVal}`, true)) {
          if (element >= ranking[page]) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
  }

  navigate(key: string) {
    let urlVal = key;
    if (key === 'thankyouDesign') {
      urlVal = 'thank-you-design';
    }
    if (this.isDesignValidationRequired) {
      this.designValidate.emit({ navigateUrl: `/${this.application.identifier}/survey/${this.surveyDefinition.identifier}/${urlVal}` });
    } else {
      this.router.navigate([`/${this.application.identifier}/survey/${this.surveyDefinition.identifier}/${urlVal}`]);
    }
  }
}
