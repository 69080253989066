<h2 mat-dialog-title>Add Interviews</h2>
<mat-dialog-content>
  <h3 class="mb-3">Upload CSV</h3>
  <input
    type="file"
    multiple
    files.bind="filesList"
    (change)="onFilesAdded($event)"
    accept=".csv"
    #file
  />
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-flat-button
    mat-dialog-close="true"
    (click)="onSubmit()"
    class="DSA_wb_primary-btn"
  >
    Submit
  </button>
  <button mat-flat-button mat-dialog-close="false" class="DSA_wb_secondary-btn">
    Cancel
  </button>
</mat-dialog-actions>
