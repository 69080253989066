/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/tslint/config */
import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnChanges,
  KeyValueDiffers,
  DoCheck,
  ViewChild,
} from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { Visibility } from 'src/app/shared/model/enumerations/visibility.model';
import { FileserverService } from 'src/app/survey-master/survey/edit-backgroud-color/fileserver.service';
import { Theme, QuestionView } from 'src/app/shared/model/theme.model';
import { MediaMatcher } from '@angular/cdk/layout';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return control && control.invalid;
  }
}

@Component({
  selector: 'jhi-slider-preview',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderPreviewComponent implements OnInit, OnChanges, DoCheck {
  @Input()
  currPage: any;
  @Input()
  question: any;
  @Input()
  questionIndex: any;
  @Input()
  responsePage: any;
  @Input()
  section: any;
  @Input()
  preference: any;
  @Input()
  theme: Theme;

  view = 'NORMAL';
  @Input()
  disabled: Boolean = false;
  @Input()
  nextElement: HTMLElement;

  diffResponsePage: any;

  matcher = new MyErrorStateMatcher();

  control: FormControl;
  minValue = 0;
  maxValue = 100;
  stepValue = 1;
  isNumeric = true;

  @ViewChild('slider') slider;

  // for mobile sidenav
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  //

  constructor(
    public fileserverService: FileserverService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private differs: KeyValueDiffers
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    setTimeout(() => {
      this.control = new FormControl('');

      if (this.theme.questionView === QuestionView.TWO_COLUMNS) {
        this.view = 'TWO_COLUMNS';
      } else {
        this.view = 'NORMAL';
      }
      this.diffResponsePage = this.differs
        .find(
          this.responsePage.sections[this.section].questions[this.questionIndex]
        )
        .create();

      if (this.getValue(this.question, 'IS_NUMERIC') === 'true') {
        this.isNumeric = true;
        this.minValue = Number(
          this.getValidationValue(this.question, 'MIN_VALUE')
        );
        this.maxValue = Number(
          this.getValidationValue(this.question, 'MAX_VALUE')
        );
        this.stepValue = Number(
          this.getValidationValue(this.question, 'SLIDER_JUMP')
        );
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].response = [];
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].response.push(this.minValue);

        if (
          this.responsePage.sections[this.section].questions[this.questionIndex]
            .response != null &&
          this.responsePage.sections[this.section].questions[this.questionIndex]
            .response.length > 0
        ) {
          // already some response present
          this.setSlider();
        } else {
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].response = [];
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].response.push(this.minValue);
        }
      } else {
        this.isNumeric = false;
        this.minValue = 0;
        this.maxValue = this.question.type.options.length - 1;
        if (
          this.responsePage.sections[this.section].questions[this.questionIndex]
            .response != null &&
          this.responsePage.sections[this.section].questions[this.questionIndex]
            .response.length > 0
        ) {
          // already some response present
          this.setSlider();
        } else {
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].response = [];

          if (this.question.type.options.length > 0)
            this.responsePage.sections[this.section].questions[
              this.questionIndex
            ].response.push(this.question.type.options[0].value);
        }
      }
    }, 10);
  }

  ngDoCheck(): void {
    const responsePageChange = this.diffResponsePage?.diff(
      this.responsePage.sections[this.section].questions[this.questionIndex]
    );
    if (responsePageChange) {
      this.ngOnChanges();
    }
  }

  ngOnChanges() {
    setTimeout(() => {
      this.control = new FormControl('');
      if (this.theme.questionView === QuestionView.TWO_COLUMNS) {
        this.view = 'TWO_COLUMNS';
      } else {
        this.view = 'NORMAL';
      }
      if (this.getValue(this.question, 'IS_NUMERIC') === 'true') {
        this.isNumeric = true;
        this.minValue = Number(
          this.getValidationValue(this.question, 'MIN_VALUE')
        );
        this.maxValue = Number(
          this.getValidationValue(this.question, 'MAX_VALUE')
        );
        this.stepValue = Number(
          this.getValidationValue(this.question, 'SLIDER_JUMP')
        );
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].response = [];
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].response.push(this.minValue);
        if (
          this.responsePage.sections[this.section].questions[this.questionIndex]
            .response != null &&
          this.responsePage.sections[this.section].questions[this.questionIndex]
            .response.length > 0
        ) {
          // already some response present
          this.setSlider();
        } else {
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].response = [];
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].response.push(this.minValue);
        }
      } else {
        this.isNumeric = false;

        this.minValue = 0;
        this.maxValue = this.question.type.options.length - 1;

        if (
          this.responsePage.sections[this.section].questions[this.questionIndex]
            .response != null &&
          this.responsePage.sections[this.section].questions[this.questionIndex]
            .response.length > 0
        ) {
          this.setSlider();
          // already some response present
        } else {
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].response = [];

          if (this.question.type.options.length > 0)
            this.responsePage.sections[this.section].questions[
              this.questionIndex
            ].response.push(this.question.type.options[0].value);
        }
      }
    }, 10);
  }

  setSlider() {
    if (this.slider == undefined) {
      return;
    }
    if (this.isNumeric) {
      this.slider.value =
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].response[0];
    } else {
      this.question.type.options.forEach((option, index) => {
        if (
          option.value ==
          this.responsePage.sections[this.section].questions[this.questionIndex]
            .response[0]
        ) {
          this.slider.value = index;
        }
      });
    }
  }
  inputChanged(event: any) {
    if (this.nextElement != null) {
      this.nextElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setTimeout(() => {
        if (this.nextElement.getElementsByTagName('input') != null)
          this.nextElement.getElementsByTagName('input')[0].focus();
      }, 400);
    }
    if (this.isNumeric) {
      this.logicPart();
    } else {
      if (this.isNumeric) {
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].response[0] = String(event.value);
      } else {
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].response[0] = this.question.type.options[event.value].value;
      }

      this.logicPart();
    }
  }

  downloadImage(path: string) {
    return this.fileserverService.getFileURLbyPath(path);
  }

  logicPart() {
    if (this.question.logics !== null && this.question.logics.length > 0) {
      for (let i = 0; i < this.question.logics.length; i++) {
        let apply = false;
        let condition = 'END';

        for (let r = 0; r < this.question.logics[i].rules.length; r++) {
          let rapply = false;

          if (this.question.logics[i].rules[r].op === 'EQ') {
            if (
              String(
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0]
              ) === this.question.logics[i].rules[r].value
            ) {
              rapply = true;
            }
          } else if (this.question.logics[i].rules[r].op === 'NEQ') {
            if (
              String(
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0]
              ) !== this.question.logics[i].rules[r].value
            ) {
              rapply = true;
            }
          }

          if (r > 0) {
            if (condition === 'AND') {
              apply = apply && rapply;
            } else if (condition === 'OR') {
              apply = apply || rapply;
            }
          } else {
            apply = rapply;
          }
          condition = this.question.logics[i].rules[r].conjunction;
        }

        for (let a = 0; a < this.question.logics[i].actions.length; a++) {
          let foundIndex: number = null;

          const actionquestion = this.currPage.sections[
            this.section
          ].questions.find((ques, ind) => {
            if (
              ques.identifier === this.question.logics[i].actions[a].identifier
            ) {
              foundIndex = ind;
              return true;
            } else {
              return false;
            }
          });

          if (apply) {
            if (this.question.logics[i].actions[a].name === 'HIDE') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                this.responsePage.sections[this.section].questions[
                  foundIndex
                ].logicallyHidden = true;
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyHiddenBy.find(
                  (item) => item === this.question.id
                );
                if (exists === undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHiddenBy.push(this.question.id);
                }
              }
            } else if (this.question.logics[i].actions[a].name === 'SHOW') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyShownBy.find(
                  (item) => item === this.question.id
                );
                if (exists === undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyShownBy.push(this.question.id);
                }
                if (
                  this.responsePage.sections[this.section].questions[foundIndex]
                    .logicallyHiddenBy.length === 0
                ) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHidden = false;
                } else {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHidden = true;
                }
              }
            }
          } else {
            if (this.question.logics[i].actions[a].name === 'HIDE') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyHiddenBy.find(
                  (item) => item === this.question.id
                );
                if (exists !== undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHiddenBy = this.responsePage.sections[
                    this.section
                  ].questions[foundIndex].logicallyHiddenBy.filter(
                    (item) => item !== this.question.id
                  );
                }
                if (
                  this.responsePage.sections[this.section].questions[foundIndex]
                    .logicallyHiddenBy.length === 0
                ) {
                  if (
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyShownBy.length === 0
                  ) {
                    if (
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].defaultVisibile
                    ) {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = false;
                    } else {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = true;
                    }
                  } else {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = false;
                  }
                }
              }
            } else if (this.question.logics[i].actions[a].name === 'SHOW') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                this.responsePage.sections[this.section].questions[
                  foundIndex
                ].logicallyHidden = true;
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyShownBy.find(
                  (item) => item === this.question.id
                );
                if (exists !== undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyShownBy = this.responsePage.sections[
                    this.section
                  ].questions[foundIndex].logicallyShownBy.filter(
                    (item) => item !== this.question.id
                  );
                }
                if (
                  this.responsePage.sections[this.section].questions[foundIndex]
                    .logicallyShownBy.length === 0
                ) {
                  if (
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHiddenBy.length === 0
                  ) {
                    if (
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].defaultVisibile
                    ) {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = false;
                    } else {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = true;
                    }
                  } else {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = true;
                  }
                } else {
                  if (
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHiddenBy.length === 0
                  ) {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = false;
                  } else {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  getValidationValue(question: any, param: string): string {
    const object = question.type.validationValues.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }

  getValue(question: any, param: string): string {
    const object = question.type.values.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }
}
