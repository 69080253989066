export class MailTemplate {
  id: number;
  name: string;
  templateString: string;
  templateCategoryId: number;
  templateSubCategoryId: number;
  templateParams: Array<any>;
  metadata: string;
  dateOfCreation: string;
  createdBy: string;
  lastModifiedBy: string;
  authorizedUsers: string;
  authorizedRoles: string;
  isActive: boolean;
  version: string;
  urls: string;
  templateType: TemplateType;
}
export class TemplateAttachment {
  id: number;
  attachmentName: string;
  displayName: string;
  attachmentFileId: string;
  attachmentFileName: string;
}
export class NotificationTemplate {
  id: number;
  name: string;
  subject: string;
  templateString: string;
  templateType: TemplateType;
  isActive: Boolean;
  templateParams: string[];
  templateCategoryId: number;
  templateAttachments: string[];
  fromMail: string;
  fromName: string;
}

export class TemplateCategory {
  id: number;
  name: string;
  templateParams: [];
}

export class TemplateSubCategory {
  id: number;
  name: string;
  status: string;
  templateCategoryId: number;
}
export enum MailTemplateCategory {
  CANDIDATE_SCHEDULE = 'CANDIDATE_SCHEDULE',
  CANDIDATE_RESCHEDULE = 'CANDIDATE_RESCHEDULE',
  CANDIDATE_REMINDER = 'CANDIDATE_REMINDER',
  CANDIDATE_SELECTION = 'CANDIDATE_SELECTION',
  PANELLIST_SCHEDULE = 'PANELLIST_SCHEDULE',
  PANELLIST_RESCHEDULE = 'PANELLIST_RESCHEDULE',
  PANELLIST_REMINDER = 'PANELLIST_REMINDER',
}

export enum TemplateType {
  HTML = 'HTML',
  TEXT = 'TEXT',
  THYMELEAF = 'THYMELEAF',
}

export interface IPagination {
  pageSize: number;
  pageIndex: number;
}
