import { InfyEventRecruitersComponent } from './modules/iwp-event/infy-event-recruiters/infy-event-recruiters.component';
import { InfyCopyEventComponent } from './modules/iwp-event/infy-copy-event/infy-copy-event.component';
import { InfySupersetDashboardComponent } from './modules/iwp-platform-actions/infy-superset-dashboard/infy-superset-dashboard.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';

import { InfyInterviewerDashboardComponent } from './modules/iwp-interviewer/infy-interviewer-dashboard/infy-interviewer-dashboard.component';
import { InfyRecruiterDashboardComponent } from './modules/iwp-event/infy-recruiter-dashboard/infy-recruiter-dashboard.component';
import { InfyCreateEventComponent } from './modules/iwp-event/infy-create-event/infy-create-event.component';
import { InfySchedulePageComponent } from './modules/iwp-event/infy-schedule-page/infy-schedule-page.component';
import { InfyInterviewerEventCandidatesComponent } from './modules/iwp-interviewer/infy-interviewer-event-candidates/infy-interviewer-event-candidates.component';
import { InfyInterviewerEvaluationComponent } from './modules/iwp-interviewer/infy-interviewer-evaluation/infy-interviewer-evaluation.component';
import { InfyJobsComponent } from './modules/iwp-event/infy-jobs/infy-jobs.component';
import { InfyRecruiterCandidateListComponent } from './modules/archive/infy-recruiter-candidate-list/infy-recruiter-candidate-list.component';

import { InfyPanelistsComponent } from './modules/iwp-event/infy-panelists/infy-panelists.component';
import { InfyScheduleListComponent } from './modules/iwp-event/infy-schedule-list/infy-schedule-list.component';
import { InfyRecruiterFeedbackComponent } from './modules/infy-recruiter-feedback/infy-recruiter-feedback.component';
import { InfyCandidateInterviewRoundsComponent } from './modules/iwp-event/infy-candidate-interview-rounds/infy-candidate-interview-rounds.component';
import { InfyJobsAdminComponent } from './modules/iwp-platform-actions/infy-jobs-admin/infy-jobs-admin.component';
import { InfyManageMailTemplatesComponent } from './mail-template/infy-manage-mail-templates/infy-manage-mail-templates.component';
import { SurveyInitViewComponent } from './survey-master/survey/survey-init-view/survey-init-view.component';
import { SettingsComponent } from './modules/iwp-event/settings/settings.component';
import { InfyInterviewListComponent } from './modules/iwp-event/infy-interview-list/infy-interview-list.component';

import { SuperAdminGuard } from './guards/super-admin.guard';
import { RouteGuard } from './guards/route.guard';
import { InterviewerGuardGuard } from './guards/interviewer-guard.guard';
import { InfyMsauthComponent } from './modules/iwp-event/infy-msauth/infy-msauth.component';
import { JobCandidatesComponent } from './modules/archive/job-candidates/job-candidates.component';

import { InfyEventNotificationsComponent } from './modules/iwp-event-notifications/infy-event-notifications/infy-event-notifications.component';
import { InfyKeycloakAdminComponent } from './modules/iwp-platform-actions/infy-keycloak-admin/infy-keycloak-admin.component';
import { AdminGuard } from './guards/admin.guard';
import { InfyGlobalSearchComponent } from './modules/iwp-event/infy-global-search/infy-global-search.component';
import { InfyDynamicDashboardComponent } from './modules/iwp-event/infy-dynamic-dashboard/infy-dynamic-dashboard.component';
import { EvaluationTemplatesListComponent } from './modules/iwp-formio/evaluation-templates/evaluation-templates-list/evaluation-templates-list.component';
import { EvaluationTemplateComponent } from './modules/iwp-formio/evaluation-templates/evaluation-template/evaluation-template.component';
import { InfyJobEvaluationFormComponent } from './modules/iwp-platform-actions/infy-job-evaluation-form/infy-job-evaluation-form.component';
import { InfyJobFormioPageComponent } from './modules/iwp-formio/infy-job-formio-page/infy-job-formio-page.component';
import { TenantsListLandingPageComponent } from './tenants-list-landing-page/tenants-list-landing-page.component';
import { EventSetupComponent } from './infy-components/event-setup/event-setup.component';

import { FeatureName } from './shared/model/enumerations/feature-name.model';
import { PermissionType } from './shared/model/enumerations/permission-type.model';
import { FeatureGuard } from './guards/feature.guard';
import { AppPluginsModule } from './app-plugins.module';
import { MailTemplateSelectionComponent } from './modules/iwp-event/mail-template-selection/mail-template-selection.component';
import { CandidateGuardGuard } from './guards/candidate-guard.guard';
import { CollegesListComponent } from './colleges-list/colleges-list.component';
import { MailTemplateComponent } from './mail-template/mail-template.component';
import { PlatformAdminGuard } from './guards/platform-admin.guard';
import { UsersListComponent } from './modules/hiring-manager/users-list/users-list.component';
import { RoleGuard } from './guards/role.guard';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

// import { MailTemplateSelectionComponent } from './modules/iwp-event/mail-template-selection/mail-template-selection.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'dashboard',
    canActivate: [RouteGuard],
    component: InfyRecruiterDashboardComponent,
  },

  {
    path: 'event-setup/:id',
    component: EventSetupComponent,
    canActivate: [RouteGuard],
    children: [
      { path: 'jobs', component: InfyJobsComponent },
      // { path: 'candidate', component: InfyRecruiterCandidateListComponent },
      { path: 'panelists', component: InfyPanelistsComponent },
      { path: 'recruiters', component: InfyEventRecruitersComponent },
      {
        path: 'mail-templates',
        // canActivate: [FeatureGuard],
        component: MailTemplateSelectionComponent,
      },
      {
        path: 'settings/:category/:categoryId',
        component: SettingsComponent,
      },
    ],
  },
  {
    path: 'create-event/:id',
    canActivate: [RouteGuard],
    component: InfyCreateEventComponent,
    children: [
      { path: 'jobs', component: InfyJobsComponent },
      // { path: 'candidate', component: InfyRecruiterCandidateListComponent },
      { path: 'panelists', component: InfyPanelistsComponent },
      { path: 'schedule', component: InfyScheduleListComponent },
      {
        path: 'candidate-interview-rounds',
        component: InfyCandidateInterviewRoundsComponent,
      },
      // { path: 'evaluation-criteria', component: InfyEvaluationListComponent },
      { path: 'interviews', component: InfyInterviewListComponent },
      { path: 'recruiters', component: InfyEventRecruitersComponent },
      { path: 'schedule-status', component: InfyDynamicDashboardComponent },
    ],
  },
  {
    path: 'scp-manage-mail-templates',
    // canActivate: [FeatureGuard],
    canActivate: [PlatformAdminGuard],
    component: MailTemplateComponent,
  },
  {
    path: 'candidate-interview-rounds',
    component: InfyCandidateInterviewRoundsComponent,
  },
  {
    path: 'schedule-interview',
    canActivate: [RouteGuard],
    component: InfySchedulePageComponent,
  },
  {
    path: 'global-search/:searchStr',
    component: InfyGlobalSearchComponent,
  },
  {
    path: 'list-of-allowed-colleges',
    component: CollegesListComponent,
  },
  {
    path: 'interviewer',
    loadChildren: () =>
      import('./modules/iwp-interviewer/interviewer.module').then(
        (m) => m.InterviewerModule
      ),
  },
  {
    path: 'scp',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./modules/hiring-manager/hiring-manager.module').then(
        (m) => m.HiringManagerModule
      ),
    data: {
      roles: [
        'ROLE_RECRUITER',
        'ROLE_SUPER_ADMIN',
        'ROLE_PLATFORM_EXECUTIVE',
        'ROLE_VERIFIER',
        'ROLE_HIRING_MANAGER',
        'ROLE_VERIFIER_ADMIN',
        'ROLE_SUPPORT_EXECUTIVE'
      ],
    },
  },
  {
    path: 'candidate',
    canActivate: [CandidateGuardGuard],
    loadChildren: () =>
      import('./modules/candidate/candidate.module').then(
        (m) => m.CandidateModule
      ),
  },
  {
    path: 'extracted-resume',
    loadChildren: () =>
      import('./modules/extracted-resume/extracted-resume.module').then(
        (m) => m.ExtractedResumeModule
      ),
  },
  // {
  //   path: 'adm',
  //   loadChildren: () =>
  //     import('./modules/platform-admin/platform-admin.module').then(
  //       (m) => m.PlatformAdminModule
  //     ),
  // },
  // {
  //   path: 'interviewer-dashboard',
  //   canActivate: [InterviewerGuardGuard],
  //   component: InfyInterviewerDashboardComponent,
  // },
  // {
  //   path: 'interviewer-event-candidates/:eventId',
  //   canActivate: [InterviewerGuardGuard],
  //   component: InfyInterviewerEventCandidatesComponent,
  // },
  /*{
    path: 'example',
    component: ExampleComponent,
  },*/

  // {
  //   path: 'event',
  //   component: MainPageComponent,
  //   canActivate: [RouteGuard],
  //   children: [
  //     { path: 'event-details', component: EventDetailsComponent },
  //     { path: 'jobs', component: JobCardComponent },
  //     { path: 'job-details', component: JobDetailsComponent },
  //     { path: 'evaluation-criteria', component: EvaluationCriteriaComponent },
  //     { path: 'candidates', component: CandidatesComponent },
  //     { path: 'candidate', component: CandidateDetailsComponent },
  //     { path: 'table', component: TableComponent },
  //     { path: 'details', component: DetailsCardComponent },
  //     { path: 'card', component: TopDetailBarComponent },
  //     { path: 'schedule-interview', component: ShecduleInterviewPageComponent },
  //     {
  //       path: 'candidate-evaluation/:id',
  //       component: CandidateEvaluationComponent,
  //     },
  //   ],
  // },
  // {
  //   path: 'event',
  //   component: EventPageComponent,
  //   canActivate: [RouteGuard],
  //   children: [
  //     { path: 'event-details', component: EventDetailsComponent },
  //     { path: 'event-linked-job', component: EventLinkJobComponent },
  //   ],
  // },
  // {
  //   path: 'evaluation',
  //   canActivate: [InterviewerGuardGuard],
  //   component: EvaluationComponent,
  // },
  // { path: 'evaluation/:id', component: EvaluationComponent },
  // {
  //   path: 'infy-evaluation/search/:searchStr',
  //   canActivate: [InterviewerGuardGuard],
  //   component: InfyInterviewerEvaluationComponent,
  // },
  // {
  //   path: 'infy-evaluation/:id',
  //   canActivate: [InterviewerGuardGuard],
  //   component: InfyInterviewerEvaluationComponent,
  // },
  {
    path: 'infy-recruiter-feedback/:id/:canId',
    component: InfyRecruiterFeedbackComponent,
  },
  {
    path: 'infy-recruiter-feedback/:id/:canId/:jobRoleId',
    component: InfyRecruiterFeedbackComponent,
  },
  {
    path: 'analytics-dashboard',
    canActivate: [RoleGuard],
    component: InfySupersetDashboardComponent,
    data: {
      roles: ['ROLE_SUPER_ADMIN', 'ROLE_HIRING_MANAGER'],
    },
  },
  {
    path: 'infy-jobs-admin',
    canActivate: [FeatureGuard],
    component: InfyJobsAdminComponent,
    data: {
      featureName: FeatureName.JOBS,
      permissionType: PermissionType.READ,
    },
  },
  {
    path: 'user-access-management',
    canActivate: [SuperAdminGuard],
    component: UsersListComponent,
  },
  {
    path: 'infy-manage-mail-templates',
    canActivate: [FeatureGuard],
    component: InfyManageMailTemplatesComponent,
    data: {
      featureName: FeatureName.MAIL_TEMPLATES,
      permissionType: PermissionType.READ,
    },
  },
  {
    path: 'settings/:category/:categoryId',
    // canActivate: [SuperAdminGuard],
    component: SettingsComponent,
  },
  {
    path: 'event-notifications/:eventId',
    component: InfyEventNotificationsComponent,
  },
  {
    path: 'msauth',
    component: InfyMsauthComponent,
  },
  {
    path: 'evaluation-templates',
    canActivate: [RoleGuard],
    data: { roles: ['ROLE_SUPER_ADMIN'] },
    component: EvaluationTemplatesListComponent,
  },
  {
    path: 'evaluation-template/:id',
    canActivate: [RoleGuard],
    data: { roles: ['ROLE_SUPER_ADMIN'] },
    component: EvaluationTemplateComponent,
  },
  {
    path: 'mail-template-selection/:eventId',
    component: MailTemplateSelectionComponent,
  },
  {
    path: 'job-evaluation-form/:jobId',
    canActivate: [RoleGuard],
    component: InfyJobEvaluationFormComponent,
    data: {
      roles: [
        'ROLE_SUPER_ADMIN',
        'ROLE_PLATFORM_EXECUTIVE',
        'ROLE_HIRING_MANAGER',
      ],
    },
    children: [
      { path: '', redirectTo: 'formio', pathMatch: 'full' },
      { path: 'formio', component: InfyJobFormioPageComponent },
      // { path: 'surveys', component: SurveyInitViewComponent },
    ],
  },
  {
    // keep this route at last
    path: '**',
    redirectTo: '/',
    // ! keep this route at last - NO ROUTE BELOW THIS
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), AppPluginsModule],
  exports: [RouterModule, AppPluginsModule],
})
export class AppRoutingModule {}
