<!-- <div class="container-fluid layout">
  <div class="row mb-3">
    <div class="col-md-12 d-flex">
      <a routerLink="/infy-jobs-admin" class="mr-2"
        ><i class="icon x-24 back-icon m-0"> </i
      ></a>
      <h1 class="DSA_wb_h1Header">Manage Mail Templates</h1>
    </div>
  </div>
</div> -->
<jhi-create-action></jhi-create-action>
