<app-header class="header"></app-header>
<div class="container-fluid college-list-container">
  <div class="d-flex justify-content-between marR8 marB16">
    <div>
      <h2
        class="DSA_wb_h2HeaderRegular font-secondary web-GreyDarkest"
        style="font-weight: bolder; font-size: 24px"
      >
        List of Allowed Colleges
      </h2>
    </div>
  </div>
</div>

<div class="row padB32">
  <div class="col-12">
    <mat-radio-group
      class="DSA_Wb_custom-radio"
      [value]="searchCategory"
      (change)="onSearchOptionChange($event)"
    >
      <mat-radio-button value="" disableRipple="true">
        All
      </mat-radio-button>
      <mat-radio-button value="ENGINEERING" disableRipple="true">
        Engineering
      </mat-radio-button>
      <mat-radio-button value="MEDICAL" disableRipple="true">
        Medical
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="ag-grid row nopadL w-100">
    <div class="col-12 nopadL">
      <data-grid
        [columnDefs]="columnDefs"
        [levels]="levels"
        [interface]="interface"
        [refreshAgGrid]="refreshAgGrid.asObservable()"
        [pageNumber]="page"
        [searchValue]="searchedText"
        [pageSize]="pageSize"
      ></data-grid>
    </div>
  </div>
</div>
<app-footer></app-footer>
