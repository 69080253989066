import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, EMPTY, of } from 'rxjs';
import { EventNotificationsService } from 'src/app/services/event-notifications.service';
import { Location } from '@angular/common';
import { FileService } from 'src/app/services/file.service';
import * as fileSaver from 'file-saver';
import { SharedService } from 'src/app/services/shared.service';
@Component({
  selector: 'app-infy-event-notifications',
  templateUrl: './infy-event-notifications.component.html',
  styleUrls: ['./infy-event-notifications.component.scss'],
})
export class InfyEventNotificationsComponent implements OnInit {
  eventId: string;
  uploadedFiles: any[] = [];
  loading: boolean = false;
  selectedNotification = 'INTERVIEWS';
  customRowHeight = 130;
  page: number = 0;
  message: string = 'No File Uploaded';
  size: number = 10;
  filesAvailable: boolean = false;

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params['eventId']) {
        this.eventId = params['eventId'];
      }
      this.getUploadedFiles();
    });
  }

  refreshAgGrid: Subject<void> = new Subject<void>();

  selectedFile: any;
  statusSelected: string;
  fileStatus: string[] = ['ALL', 'SUCCESS', 'FAILURE', 'PENDING'];
  notificationOptions: string[] = ['INTERVIEWS', 'MAILS', 'PANELISTS'];
  fileName: string;
  fileBlob: Blob;

  constructor(
    private activatedRoute: ActivatedRoute,
    public _location: Location,
    private sharedService: SharedService,
    private eventNotificationsService: EventNotificationsService,
    private fileService: FileService
  ) {}

  getUploadedFiles = () => {
    this.filesAvailable = false;
    this.uploadedFiles = [];
    this.loading = true;
    this.statusSelected = 'ALL';
    this.eventNotificationsService
      .getAllFilesInEvent(this.eventId, this.selectedNotification)
      .subscribe(
        (res: any) => {
          if (res?.length > 0) {
            this.filesAvailable = true;
            res.forEach((data) => {
              this.uploadedFiles.push(data);
            });
            this.selectedFile = this.uploadedFiles[0];
          }
          this.loading = false;
        },
        () => {
          this.message = 'Uh Oh! Something went wrong!';
          this.loading = false;
        }
      );
    this.changeNotificationType();
  };

  sheetRejectionReasons = [
    'Date format might be wrong. Date format has to be DD/MM/YYYY',
    'Time format might be wrong. Time format has to be HH:MM:SS ',
    'Version is case sensitive and has to be LATERAL / FRESHER',
    'Sheet might have extra blank rows at the end',
    'Sheet might have extra columns',
    'Column headers might have been altered',
    'SPOC might not have been added in the Recruiters tab',
  ];

  changeNotificationType = () => {
    if (this.selectedNotification !== 'MAILS') {
      this.fileStatus = this.fileStatus.filter((status) => {
        return status !== 'PENDING';
      });
    } else if (
      this.selectedNotification === 'MAILS' &&
      !this.fileStatus.includes('PENDING')
    ) {
      this.fileStatus.push('PENDING');
    }
  };
  downloadBulkUploadFile = (fileId) => {
    this.fileService.getFile(fileId).subscribe((res: any) => {
      let temp = res.headers
        .get('content-disposition')
        .split('filename=')[1]
        .split(';')[0];
      let temp1 = temp.substring(1, temp.length - 1);
      this.fileName = temp1;
      let blob: any = new Blob([res.body], {
        type: 'application/octet-stream; charset=utf-8',
      });
      this.fileBlob = blob;
      fileSaver.saveAs(this.fileBlob, this.fileName);
    });
  };
}
