import { Component } from '@angular/core';
//import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AgRendererComponent } from 'ag-grid-angular';
import { AccountService } from 'src/app/services/account.service';
import { SharedService } from 'src/app/services/shared.service';
import { CandidateIdDialogBoxComponent } from './candidate-id-dialog-box/candidate-id-dialog-box.component';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';

@Component({
  selector: 'app-candidate-id-action-renderer',
  template: `
    <span
      *ngIf="
        accountService.hasFeatureAccess(
          FeatureName.UPDATE_APPLICATION_DETAILS,
          PermissionType.UPDATE
        ) && intapFlag
      "
      ><i
        class="icon x-24 edit-icon"
        role="button"
        (click)="editCandidateId()"
        matTooltip="Edit Application Id"
        matTooltipClass="DSA_tooltip"
        aria-label="Edit Application Id"
      ></i>
    </span>
  `,
  styles: [],
})
export class CandidateIdActionRendererComponent implements AgRendererComponent {
  FeatureName = FeatureName;
  PermissionType = PermissionType;
  candidateData: any;
  params: any;
  intapFlag = false;
  constructor(
    private dialog: MatDialog,
    private sharedService: SharedService,
    public accountService: AccountService
  ) {}

  refresh(params: any): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
    this.candidateData = params.data;
    if (this.candidateData?.candidateSource === 'INTAP') this.intapFlag = true;
  }
  editCandidateId() {
    const dialogRef = this.dialog.open(CandidateIdDialogBoxComponent, {
      data: { data: this.candidateData },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== false) this.params.context.componentParent.refreshTable();
    });
  }
}
