import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CareerDetails } from 'src/app/shared/model/Profile/careerDetails-model';
import { PersonalInfo } from 'src/app/shared/model/Profile/personalInfo-model';
import { SharedService } from 'src/app/services/shared.service';
import { CandidateInfoService } from '../../../candidate/services/candidate-info.service';

@Component({
  selector: 'app-career-details',
  templateUrl: './career-details.component.html',
  styleUrls: ['./career-details.component.scss'],
})
export class CareerDetailsComponent implements OnInit {
  spinner: boolean = false;
  message: string;
  // i:number=0;
  // candidateInfo: ICandidateInfo;
  // @Input() extractedResume: IExtractedResume;
  @Input() careerDetails: CareerDetails; // [];

  @Output() newItemEvent = new EventEmitter<CareerDetails>();

  updateExtractedResume() {
    if (!this.careerDetails) this.careerDetails = this.careerInfoForm.value;
    else
      this.careerDetails = {
        ...this.careerDetails,
        ...this.careerInfoForm.value,
      };

    this.services.UpdateCareerDeatailsOfProfile(this.careerDetails).subscribe({
      next: (res: any) => {
        this.careerDetails = res;
        this.sharedService.openSuccessSnackBar('data updated successfully', '');
      },
      error: (err: any) => {
        this.sharedService.openErrorSnackBar(
          'data update failed. Please try again',
          ''
        );
      },
    });

    this.newItemEvent.emit(this.careerDetails);
  }

  skillList: any = [];
  skillString: string;
  constructor(
    private fb: FormBuilder,
    private services: CandidateInfoService,
    private sharedService: SharedService //private careerInformationService: CareerInformationService
  ) {}
  careerInfoForm: FormGroup;
  ngOnInit(): void {
    this.careerInfoForm = this.fb.group({
      id: [null, Validators.required],
      totalExperience: [null, Validators.required],
      currentEmployer: [null, Validators.required],
      jobTitle: [null, Validators.required],
      jobLocation: [null, Validators.required],
      employmentHistoryDTOs: this.fb.array([
        this.fb.group({
          id: [null, Validators.required],
          totalExperience: [null, Validators.required],
          jobTitle: [null, Validators.required],
          jobLocation: [null, Validators.required],
          employerName: [null, Validators.required],
          employmentType: [null, Validators.required],
          isCurrent: [null, Validators.required],
          category: [null, Validators.required],
          fromDate: [null, Validators.required],
          toDate: [null, Validators.required],
          jobDescription: [null, Validators.required],
          careerDetailsId: [null, Validators.required],
        }),
      ]),
    });
    // if(this.careerDetails)
    //  this.careerInfoForm.patchValue(this.careerDetails);
    //console.log('Inside CareerDetails Comp');
    this.patchResultList();
    console.log(this.careerInfoForm);
  }

  patchResultList() {
    //console.log(this.data);
    if(this.careerDetails)
    {
    for (
      let i = 0;
      i < this.careerDetails?.employmentHistoryDTOs.length - 1;
      i++
    ) {
      this.addDegree();
    }
 this.careerInfoForm.patchValue(this.careerDetails);
  }
  }

  addDegree(): void {
    (this.careerInfoForm.get('employmentHistoryDTOs') as FormArray).push(
      this.fb.group({
        id: [null, Validators.required],
        totalExperience: [null, Validators.required],
        employerName: [null, Validators.required],
        jobTitle: [null, Validators.required],
        jobLocation: [null, Validators.required],
        employmentType: [null, Validators.required],
        isCurrent: [null, Validators.required],
        category: [null, Validators.required],
        fromDate: [null, Validators.required],
        toDate: [new Date(), Validators.required],
        jobDescription: [null, Validators.required],
        careerDetailsId: [null, Validators.required],
      })
    );
  }
  getDTOFormControls(): AbstractControl[] {
    return (<FormArray>this.careerInfoForm.get('employmentHistoryDTOs'))
      .controls;
  }
  //data:PersonalInfo;
  submitDetails() {
    console.log(this.careerInfoForm);
    this.services
      .UpdateCareerDeatailsOfProfile(this.careerInfoForm.value)
      .subscribe({
        next: (response: any) => {
          //console.log(response);
        },
        error: (err: any) => {
          this.sharedService.openErrorSnackBar(
            'Upload is processing, Please wait',
            ''
          );
        },
      });
  }
}
