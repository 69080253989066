<div class="container-fluid layout">
  <div class="clearfix DSA_mainCtr ctr_main" id="mainCtr">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div>
            <div class="d-flex justify-content-start align-items-center mb-4">
              <div class="ml-3 mr-3" role="button">
                <i class="icon x-24 back-icon ml-1" (click)="_location.back()">
                </i>
              </div>
              <div class="h3">Bulk Upload File Status</div>
            </div>
          </div>
          <div class="container-fluid layout" *ngIf="!loading">
            <div class="DSA_panel padTB16" style="height: 100%">
              <div class="row">
                <div class="col-3">
                  <mat-form-field
                    class="custom-form-field custom-select-withBg"
                    appearance="fill"
                  >
                    <mat-label>Notification For</mat-label>
                    <mat-select
                      [(ngModel)]="selectedNotification"
                      disableOptionCentering
                      panelClass="DSA_wb-custom-select-panel DSA_wb-custom-select-withBg"
                      (selectionChange)="getUploadedFiles()"
                    >
                      <mat-option
                        *ngFor="let status of notificationOptions"
                        [value]="status"
                      >
                        {{ status }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-6" *ngIf="filesAvailable">
                  <mat-form-field
                    class="DSA_Wb_custom-form-field custom-select-withBg"
                    appearance="fill"
                  >
                    <mat-label>File</mat-label>
                    <mat-select
                      [(ngModel)]="selectedFile"
                      panelClass="DSA_wb-custom-select-panel DSA_wb-custom-select-withBg"
                    >
                      <mat-option
                        *ngFor="let file of uploadedFiles"
                        [value]="file"
                        matTooltip="Uploaded on: {{
                          file.createdAt | date: 'MMM d, y, h:mm:ss a'
                        }}"
                        matTooltipClass="DSA_tooltip"
                      >
                        {{ file.sourceName }}
                      </mat-option>
                    </mat-select>
                    <mat-hint
                      ><div class="d-inline-block">
                        <span
                          class="
                            DSA_wb_chipStyle DSA_wb_bluechip
                            text-nowrap
                            marR8
                          "
                          >Uploaded by: {{ selectedFile?.createdBy! }}</span
                        >
                        <span
                          class="DSA_wb_chipStyle DSA_wb_bluechip text-nowrap"
                          >Date: {{ selectedFile?.createdAt! | date }}</span
                        >
                        <span
                          class="
                            DSA_wb_chipStyle DSA_wb_bluechip
                            text-nowrap
                            marLR8
                          "
                          >Time:
                          {{
                            selectedFile?.createdAt! | date: "h:mm:ss a"
                          }}</span
                        >
                      </div></mat-hint
                    >
                  </mat-form-field>
                </div>

                <div class="col-3" *ngIf="filesAvailable">
                  <mat-form-field
                    class="DSA_Wb_custom-form-field custom-select-withBg"
                    appearance="fill"
                  >
                    <mat-label>Status</mat-label>
                    <mat-select
                      [(ngModel)]="statusSelected"
                      disableOptionCentering
                      panelClass="DSA_Wb_custom-form-field custom-select-withBg"
                    >
                      <mat-option
                        *ngFor="let status of fileStatus"
                        [value]="status"
                      >
                        {{ status }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div
                  *ngIf="filesAvailable"
                  class="col-12 d-flex justify-content-end"
                >
                  <button
                    class="DSA_wb_miniSecondary-btn"
                    type="submit"
                    (click)="downloadBulkUploadFile(selectedFile?.id)"
                  >
                    Download Original File
                  </button>
                </div>
              </div>
            </div>

            <div
              class="marT16 grid-wrap"
              *ngIf="filesAvailable && selectedFile.status === 'SUCCESS'"
            >
              <app-infy-event-notifications-interviews-grid
                [selectedFile]="selectedFile"
                [statusSelected]="statusSelected"
                *ngIf="selectedNotification === 'INTERVIEWS' && filesAvailable"
              ></app-infy-event-notifications-interviews-grid>

              <app-infy-event-notifications-mails-grid
                [selectedFile]="selectedFile"
                [statusSelected]="statusSelected"
                *ngIf="selectedNotification === 'MAILS' && filesAvailable"
              ></app-infy-event-notifications-mails-grid>

              <app-infy-event-notifications-panelists-grid
                [selectedFile]="selectedFile"
                [statusSelected]="statusSelected"
                *ngIf="selectedNotification === 'PANELISTS' && filesAvailable"
              ></app-infy-event-notifications-panelists-grid>
            </div>
            <div
              class="DSA_panel padTB16 marTB32"
              style="height: 100%"
              *ngIf="filesAvailable && selectedFile.status === 'FAILURE'"
            >
              <div class="row" *ngIf="uploadedFiles">
                <div class="col-12 marLR32 h3 font-red">
                  ENTIRE SHEET WAS REJECTED
                </div>

                <span class="mar32">
                  <strong>POSSIBLE REASONS: </strong><br />
                  <ul>
                    <li
                      class="marT8"
                      *ngFor="let reason of sheetRejectionReasons"
                    >
                      <h2
                        class="
                          marT8
                          DSA_wb_mainBodyTxt
                          web-GreyDarkest
                          DSA_wb_text-1line
                        "
                      >
                        {{ reason }}
                      </h2>
                    </li>
                  </ul>
                </span>
              </div>
            </div>
            <div class="DSA_panel marT32" *ngIf="!loading && !filesAvailable">
              <div
                class="
                  DSA_panelBody
                  padTB16
                  padLR24
                  d-flex
                  flex-column
                  align-items-center
                "
              >
                <img
                  src="assets/images/no_data_found.svg"
                  alt=""
                  class="no-data-img"
                />
                <h4 class="marT16">{{ message }}</h4>
                <button
                  mat-flat-button
                  class="DSA_wb_miniPrimary-btn marT32"
                  (click)="_location.back()"
                >
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-loader [display]="loading"></app-loader>
