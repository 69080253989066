import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateTemplateDialogComponent } from './evaluation-templates/create-template-dialog/create-template-dialog.component';
import { EvaluationTemplateComponent } from './evaluation-templates/evaluation-template/evaluation-template.component';
import { EvaluationTemplatesListComponent } from './evaluation-templates/evaluation-templates-list/evaluation-templates-list.component';
import { FormBuilderComponent } from './evaluation-templates/form-builder/form-builder.component';
import { SearchTemplateInputComponent } from './evaluation-templates/search-template-input/search-template-input.component';
import { InfyAssociateEvaluationFormComponent } from './infy-job-formio-page/infy-associate-evaluation-form/infy-associate-evaluation-form.component';
import { InfyPreviousEvaluationFormsComponent } from './infy-job-formio-page/infy-previous-evaluation-forms/infy-previous-evaluation-forms.component';
import { InfyJobFormioPageComponent } from './infy-job-formio-page/infy-job-formio-page.component';
import { InfyFormioEvaluationComponent } from './infy-formio-evaluation/infy-formio-evaluation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/angular-material.module';
import { GatewaySharedModule } from 'src/app/shared/shared.module';
import { EvaluationTemplateStatusRendererComponent } from './evaluation-templates/evaluation-templates-list/evaluation-templates-list-renderer/evaluation-template-status-renderer.component';
import { EvaluationTemplateLinkRendererComponent } from './evaluation-templates/evaluation-templates-list/evaluation-templates-list-renderer/evaluation-template-link-renderer.component';
import { FormioModule } from 'angular-formio';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    CreateTemplateDialogComponent,
    EvaluationTemplateComponent,
    EvaluationTemplatesListComponent,
    EvaluationTemplateStatusRendererComponent,
    EvaluationTemplateLinkRendererComponent,
    FormBuilderComponent,
    SearchTemplateInputComponent,
    InfyAssociateEvaluationFormComponent,
    InfyPreviousEvaluationFormsComponent,
    InfyJobFormioPageComponent,
    InfyFormioEvaluationComponent,
  ],
  imports: [
    CommonModule,
    FormioModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    GatewaySharedModule,
    RouterModule,
  ],
  exports: [
    CreateTemplateDialogComponent,
    EvaluationTemplateComponent,
    EvaluationTemplatesListComponent,
    EvaluationTemplateStatusRendererComponent,
    EvaluationTemplateLinkRendererComponent,
    FormBuilderComponent,
    SearchTemplateInputComponent,
    InfyAssociateEvaluationFormComponent,
    InfyPreviousEvaluationFormsComponent,
    InfyJobFormioPageComponent,
    InfyFormioEvaluationComponent,
  ],
})
export class IwpFormioModule {}
