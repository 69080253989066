import { Component, Input, OnInit } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-display-meeting-link',
  template: `<a
    class="DSA_wb_hyperLink-btn"
    role="button"
    (click)="copyLinkToClipBoard()"
    [matTooltip]="displayText"
    matTooltipClass="DSA_tooltip"
  >
    {{ displayText }}
  </a> `,
})
export class DisplayMeetingLinkComponent implements OnInit {
  @Input() meetingLink;
  @Input() displayText;
  constructor(
    private clipboardService: ClipboardService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {}

  copyLinkToClipBoard = () => {
    this.clipboardService.copy(this.meetingLink);

    this.sharedService.openSnackBar(
      'Meeting link copied to clipboard.',
      '',
      1000
    );
  };
}
