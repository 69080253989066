<app-banner
  title="Problem Statement"
  [displaySubHeader]="false"
  bgColor="#00b28f"
>
</app-banner>
<div class="container-fluid marT32">
  <app-loader [display]="isLoadingForAll"></app-loader>
  <ng-container *ngIf="!isLoadingForAll">
    <div *ngIf="isApplicationsPresent">
      <div class="sm-screen row mt-2 marB32 heading-section">
        <div
          class="
            col-md-10 col-sm-8
            padT8
            padL32
            DSA_wb_h3Header
            web-GreyDarkest
          "
        >
          Applied Problem Statements
        </div>
        <div
          class="
            sm-screen-2
            col-md-2 col-sm-4
            text-right-large-screen
            marT8
            small-screen-marT
            text-md-right text-sm-left
          "
          style="white-space: nowrap; margin-left: -8px"
        >
          <a
            matTooltip="View all applications"
            class="marR8"
            [routerLink]="['../view-applications']"
            matTooltipClass="DSA_tooltip"
          >
            <h3 class="DSA_wb_h3Header">View All</h3>
          </a>
        </div>
      </div>
      <div class="row marT8">
        <div class="col-12 padT8 marB32">
          <app-loader [display]="isLoadingForApplied"></app-loader>
          <ng-container *ngIf="!isLoadingForApplied">
            <div class="row justify-content-left">
              <div
                *ngFor="let job of appliedJobs; let i = index"
                class="col-md-12 col-sm-11 sm-screen-pad"
              >
                <app-application-card
                  [job]="job"
                  [isPartofTeam]="isPartofTeam"
                  [role]="role"
                  [teamLead]="teamLead"
                  (cancelJob)="cancelApplication($event)"
                ></app-application-card>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row mt-2 heading-section padLR32">
      <div class="col-md-8 padT16 DSA_wb_h3Header web-GreyDarkest">
        All Problem Statements
      </div>
      <div
        class="col-md-4 d-flex justify-content-end align-items-center nopadR"
      >
        <mat-form-field class="DSA_Wb_custom-form-field padLR16">
          <input
            matInput
            placeholder="Search Problem Statements"
            [(ngModel)]="searchedText"
            (keydown.enter)="searchJob(searchedText)"
          />
          <div matSuffix class="d-flex">
            <span *ngIf="searchedText" class="clear-icon">
              <i
                class="icon x-24 icon-rejected"
                (click)="clearSearch()"
                matTooltip="Clear Search"
                matTooltipClass="DSA_tooltip"
              >
              </i>
            </span>
            <span>
              <i
                class="icon x-24 search-icon"
                role="button"
                (click)="searchJob($event)"
                matTooltip="Search"
                matTooltipClass="DSA_tooltip"
              >
              </i>
            </span>
          </div>
        </mat-form-field>
        <app-filter-btn
          filterDisplayText="Filter"
          [filterState]="filters"
          [filterByColumnsAll]="filterByColumns"
          [filterCriteriaGetApi]="getJobOpeningsFilters"
          (refreshTableEvent)="handleFilterChange($event)"
        ></app-filter-btn>
      </div>
    </div>

    <div class="row">
      <div class="col-12 padLR32">
        <app-filter-ui
          [filterState]="filters"
          [filterByColumnsAll]="filterByColumns"
          (refreshTableEvent)="handleFilterChange($event)"
        ></app-filter-ui>
      </div>
      <div class="col-12 padT8">
        <app-loader [display]="isLoadingForScholarships"></app-loader>
        <ng-container *ngIf="!isLoadingForScholarships">
          <ng-container *ngIf="jobs?.length > 0 && !isError; else showError">
            <div class="row justify-content-center">
              <div
                *ngFor="let job of jobs; let i = index"
                class="col-md-12 col-sm-12 sm-screen-pad"
              >
                <!-- <app-job-card
                  [job]="job"
                  (applyJob)="applyJob($event, job)"
                ></app-job-card> -->

                <app-scholarship-card-v1 [job]="job"></app-scholarship-card-v1>
              </div>
              <app-pagination
                class="col-md-12 col-sm-12"
                *ngIf="totalElements > 0"
                [totalElements]="totalElements"
                [page]="page"
                [pageSize]="pageSize"
                (pageChange)="handlePageChange($event)"
              ></app-pagination>
            </div>
          </ng-container>
          <ng-template #showError>
            <app-error-message
              [errorText]="
                isError
                  ? 'Some error occurred. Please try again.'
                  : 'No records found'
              "
            ></app-error-message>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
