import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DATE_FORMAT } from 'src/app/shared/constants/input.constants';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from 'src/app/app.constants';
import { createRequestOption } from 'src/app/shared/util/request-util';
import { ISurveyDefinition } from 'src/app/shared/model/survey-definition.model';
import { environment } from 'src/environments/environment';

type EntityResponseType = HttpResponse<ISurveyDefinition>;
type EntityArrayResponseType = HttpResponse<ISurveyDefinition[]>;

@Injectable({ providedIn: 'root' })
export class SurveyService {
  api_url = environment.feedbackServiceUrl ; ///remove in dev
  // public resourceUrl = SERVER_API_URL + 'api/survey-definitions';
  public resourceUrl = this.api_url + 'api/survey-definitions';

  public publicResourceUrl = SERVER_API_URL + 'public/links';

  constructor(protected http: HttpClient) {}

  create(surveyDefinition: ISurveyDefinition): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(surveyDefinition);
    return this.http
      .post<ISurveyDefinition>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(surveyDefinition: ISurveyDefinition): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(surveyDefinition);
    return this.http
      .put<ISurveyDefinition>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: any): Observable<EntityResponseType> {
    return this.http
      .get<ISurveyDefinition>(`${this.resourceUrl}/${id}`, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }
  findById(id: any): Observable<EntityResponseType> {
    return this.http
      .get<ISurveyDefinition>(`${this.resourceUrl}/by/id/${id}`, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }
  findByJobId(id: any, jobRoundType: string): Observable<EntityResponseType> {
    return this.http
      .get<ISurveyDefinition>(
        `${this.resourceUrl}/by/job/${id}/jobroundType/${jobRoundType}`,
        {
          observe: 'response',
        }
      )
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }
  // findAllSurvey(): Observable<EntityArrayResponseType> {
  //   return this.http
  //     .get<ISurveyDefinition>(`${this.resourceUrl}/by/user`, { observe: 'response' })
  //     .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  // }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ISurveyDefinition[]>(this.resourceUrl, {
        params: options,
        observe: 'response',
      })
      .pipe(
        map((res: EntityArrayResponseType) =>
          this.convertDateArrayFromServer(res)
        )
      );
  }
  // TODO: API is not ready
  queryByApp(id: number, req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<ISurveyDefinition[]>(`${this.resourceUrl}/by/application/${id}`, {
        params: options,
        observe: 'response',
      })
      .pipe(
        map((res: EntityArrayResponseType) =>
          this.convertDateArrayFromServer(res)
        )
      );
  }

  delete(id: string): Observable<HttpResponse<any>> {
    return this.http.delete<any>(`${this.resourceUrl}/${id}`, {
      observe: 'response',
    });
  }

  protected convertDateFromClient(
    surveyDefinition: ISurveyDefinition
  ): ISurveyDefinition {
    const copy: ISurveyDefinition = Object.assign({}, surveyDefinition, {
      createTime:
        surveyDefinition.createTime != null &&
        surveyDefinition.createTime.isValid()
          ? surveyDefinition.createTime.toJSON()
          : null,
    });
    return copy;
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createTime =
        res.body.createTime != null ? moment(res.body.createTime) : null;
    }
    return res;
  }

  protected convertDateArrayFromServer(
    res: EntityArrayResponseType
  ): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((surveyDefinition: ISurveyDefinition) => {
        surveyDefinition.createTime =
          surveyDefinition.createTime != null
            ? moment(surveyDefinition.createTime)
            : null;
      });
    }
    return res;
  }
}
