<div *ngIf="this.interviewStatus === 'UPCOMING'">
  <a
    class="DSA_wb_hyperLink-btn"
    role="button"
    *ngIf = "accountService.hasFeatureAccess(FeatureName.RESEND_EMAIL,PermissionType.CREATE)"
    (click)="resendEmail(this.data.id, 'interviewer')"
    matTooltip="Resend Email - Interviewer"
    matTooltipClass="DSA_tooltip"
    >Re-Send Email - Interviewer</a
  >
  <br />
  <a
    class="DSA_wb_hyperLink-btn"
    role="button"
    *ngIf = "accountService.hasFeatureAccess(FeatureName.RESEND_EMAIL,PermissionType.CREATE)"
    (click)="resendEmail(this.data.id, 'candidate')"
    matTooltip="Resend Email - Candidate"
    matTooltipClass="DSA_tooltip"
    >Re-Send Email - Candidate</a
  >
  <br />
  <a
    class="DSA_wb_hyperLink-btn"
    role="button"
    (click)="noShowInterview(this.data.id)"
    matTooltip="No Show"
    matTooltipClass="DSA_tooltip"
    *ngIf="
    accountService.hasFeatureAccess(FeatureName.CLOSE_CANDIDATURE,PermissionType.UPDATE)
    "
  >
    No Show
  </a>
  <br />
  <a
    class="DSA_wb_hyperLink-btn"
    *ngIf = "accountService.hasFeatureAccess(FeatureName.INTERVIEW_EDIT,PermissionType.UPDATE)"
    role="button"
    (click)="editInterview(this.data)"
    matTooltip="Edit Interview"
    matTooltipClass="DSA_tooltip"
  >
    Edit Interview
  </a>
  <br />
  <a
    class="DSA_wb_hyperLink-btn"
    *ngIf = "
    accountService.hasFeatureAccess(FeatureName.INTERVIEW_CANCELLATION,PermissionType.UPDATE)
    || accountService.hasFeatureAccess(FeatureName.INTERVIEW_CANCELLATION,PermissionType.CREATE)"
    role="button"
    (click)="cancelInterview(this.data.id)"
    matTooltip="Cancel Interview"
    matTooltipClass="DSA_tooltip"
  >
    Cancel Interview
  </a>
</div>
