import { EventService } from 'src/app/services/event.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
import { AuthorityName } from 'src/app/shared/model/enumerations/authority-name.model';

@Component({
  selector: 'app-event-setup',
  templateUrl: './event-setup.component.html',
  styleUrls: ['./event-setup.component.scss'],
})
export class EventSetupComponent implements OnInit {
  constructor(
    private router: Router,

    private accountService: AccountService,
    private eventService: EventService
  ) {}
  eventId: any;
  selectedStepIndex: number = 0;
  loadingStep: boolean = false;
  eventSetupLinks = [];

  activeLink: number = 0;
  flags = [];
  routeLinkData = [
    {
      link: 'jobs',
      hide: !this.accountService.hasFeatureAccess(
        FeatureName.EVENT_JOBS,
        PermissionType.READ
      ),
    },
    {
      link: 'panelists',
      hide: !this.accountService.hasFeatureAccess(
        FeatureName.EVENT_PANELIST,
        PermissionType.READ
      ),
    },
    {
      link: 'recruiters',
      hide: !this.accountService.hasFeatureAccess(
        FeatureName.EVENT_RECRUITER,
        PermissionType.READ
      ),
    },
    {
      link: 'interviews',
      hide: false,
    },
  ];

  ngOnInit(): void {
    this.eventId = this.eventService.getSelectedEvent().id;
    console.log(this.eventId);
    if (this.eventId == null || this.eventId == undefined) {
      if (
        this.accountService.getCurrentRole() &&
        this.accountService.getCurrentRole() === AuthorityName.ROLE_RECRUITER
      ) {
        this.router.navigate(['dashboard']);
      } else if (
        this.accountService.getCurrentRole() &&
        this.accountService.getCurrentRole() ===
          AuthorityName.ROLE_HIRING_MANAGER
      ) {
        this.router.navigate(['scp/scholarships']);
      }
    }
    this.flags = [
      {
        jobFlag: false,
        panelistFlag: false,
        recruiterFlag: false,
        mailTemplateFlag: false,
      },
    ];

    this.eventSetupLinks = [
      {
        link: 'jobs',
        key: 'jobFlag',
        label: 'Jobs',
        disabled: !(this.activeLink == 0),
        hide: !this.accountService.hasFeatureAccess(
          FeatureName.EVENT_JOBS,
          PermissionType.READ
        ),
      },
      {
        link: 'panelists',
        key: 'panelistFlag',
        label: 'Panelists',
        disabled: !(this.activeLink == 1),
        hide: !this.accountService.hasFeatureAccess(
          FeatureName.EVENT_PANELIST,
          PermissionType.READ
        ),
      },
      {
        link: 'recruiters',
        key: 'recruiterFlag',
        label: 'Recruiters',
        disabled: !(this.activeLink == 2),
        hide: !this.accountService.hasFeatureAccess(
          FeatureName.EVENT_RECRUITER,
          PermissionType.READ
        ),
      },
      {
        link: 'mail-templates',
        label: 'Mail Templates',
        key: 'templateFlag',

        hide: !this.accountService.hasFeatureAccess(
          FeatureName.EVENT_TEMPLATE_MAPPING,
          PermissionType.READ
        ),
      },
    ];

    this.eventService.getEventSetupFlags().subscribe((res: any) => {
      this.eventService.setEventFlags(res);
      let falseIndex = this.eventSetupLinks.findIndex(
        (link) => res[link.key] === false
      );
      this.activeLink = falseIndex;

      console.log(this.eventId);
      console.log(falseIndex);
      this.router.navigate([
        '/event-setup/' +
          this.eventId +
          '/' +
          this.eventSetupLinks[falseIndex].link,
      ]);
    });
  }

  updateFlag = async (link, index) => {
    this.flags = this.eventService.getEventFlags();

    if (this.flags[link.key] == false) {
      this.flags[link.key] = true;

      try {
        let responseFlags = await this.eventService
          .updateEventSetupFlag(this.flags)
          .toPromise();
        this.eventService.setEventFlags(responseFlags);
      } catch (e) {}
    }
  };

  navigateNext = async () => {
    await this.updateFlag(
      this.eventSetupLinks[this.activeLink],
      this.activeLink
    );
    this.activeLink = this.activeLink + 1;
    if (this.activeLink == this.eventSetupLinks.length) {
      let firstEnableLink = this.routeLinkData.find((e) => e.hide == false);
      if (firstEnableLink) {
        this.router.navigate([
          '/create-event/' + this.eventId + '/' + firstEnableLink.link,
        ]);
      }
      this.router.navigate(['']);
    } else {
      this.router.navigate([
        '/event-setup/' +
          this.eventId +
          '/' +
          this.eventSetupLinks[this.activeLink].link,
      ]);
    }
  };
  goToEventsPage = () => {
    this.router.navigate(['dashboard']);
  };
}
