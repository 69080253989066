import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InterviewerService } from 'src/app/services/interviewer.service';
import { SharedService } from 'src/app/services/shared.service';
import { IEventPanelist } from 'src/app/shared/model/event-panelist.model';

@Component({
  selector: 'app-add-panelist-to-interview-dialog',
  templateUrl: './add-panelist-to-interview-dialog.component.html',
  styleUrls: ['./add-panelist-to-interview-dialog.component.scss'],
})
export class AddPanelistToInterviewDialogComponent implements OnInit {
  eventId: number;
  interviewId: number;
  panelistsToAdd: IEventPanelist[] = [];

  isLoading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddPanelistToInterviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      eventId: number;
      interviewId: number;
      emailsToFilter: string[];
    },
    private sharedService: SharedService,
    private interviewerService: InterviewerService
  ) {}

  ngOnInit(): void {
    this.eventId = this.data.eventId;
    this.interviewId = this.data.interviewId;
  }

  handlePanelistChange = (panelists: IEventPanelist[]) => {
    this.panelistsToAdd = panelists;
  };

  addPanelistsToInterview = () => {
    let panelistIds = this.panelistsToAdd.map((a) => a.panelistId);

    for (let panelistId of panelistIds) {
      if (!panelistId) {
        this.sharedService.openSnackBar(
          'Please select valid email from interviewer dropdown',
          'X',
          2000
        );
        return;
      }
    }

    this.isLoading = true;
    this.interviewerService
      .addInterviewersToInterview(this.eventId, this.interviewId, panelistIds)
      .subscribe(
        (res) => {
          this.dialogRef.close(res);
          this.isLoading = false;
        },
        (err) => {
          const errorMessage = err?.error?.title
            ? err.error.title
            : 'Some error occurred. Panelists not added';
          this.sharedService.openSnackBar(errorMessage, 'X', 2000);
          this.isLoading = false;
        }
      );
  };
}
