<app-loader [display]="isLoading"></app-loader>
<div class="container-fluid layout" *ngIf="!isLoading">
  <div class="row">
    <div class="col-12 d-flex align-items-center">
      <a
        href="javascript:void(0);"
        (click)="handleBackClick()"
        style="cursor: pointer"
        class="mr-2"
      >
        <i class="icon x-24 back-icon m-0"> </i>
      </a>
      <h2 class="DSA_wb_h2Header web-GreyDarkest">{{ selectedJob.title }}</h2>
    </div>
    <!-- <div
      class="col-md-4 offset-md-2 d-flex align-items-center justify-content-end"
    >
      <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel">
        <mat-label>Feedback Form</mat-label>
        <mat-select
          disableOptionCentering
          panelClass="DSA_wb-custom-select-panel"
          [formControl]="activeFormTypeControl"
        >
          <mat-option
            *ngFor="let formType of lateralFeedbackFormTypes"
            [value]="formType"
          >
            {{ formType }}
          </mat-option>
        </mat-select>
        <mat-hint>Active Feedback form type for job</mat-hint>
      </mat-form-field>
      <button
        class="DSA_wb_miniSecondary-btn marL8"
        (click)="updateActiveFeedbackFormType()"
        [disabled]="isUpdatingFormType"
      >
        {{ isUpdatingFormType ? "Saving..." : "Save" }}
      </button>
    </div> -->
  </div>

  <div class="row marT16">
    <div class="col-12">
      <div class="DSA_wb_customTab DSA_navTabWhite DSA_wb_Secondary">
        <nav class="custom-bg-white" mat-tab-nav-bar>
          <a
            mat-tab-link
            *ngFor="let link of links"
            [routerLink]="link.link"
            routerLinkActive
            #rla="routerLinkActive"
            [active]="rla.isActive"
            [style.opacity]="rla.isActive ? 1 : 0.7"
            (click)="activeLink = link.link"
          >
            {{ link.label }}
          </a>
        </nav>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
