import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedService } from './services/shared.service';
import { UrlStateStorageService } from './url-state-storage.service';
import { Router } from '@angular/router';
import { AccountService } from './services/account.service';
import { environment } from 'src/environments/environment';
import { KeycloakService } from 'keycloak-angular';
@Injectable()
export class HttpReqInterceptor implements HttpInterceptor {
  count = 0;
  constructor(
    private sharedService: SharedService,
    private urlStateStorageService: UrlStateStorageService,
    private router: Router,
    public accountService: AccountService,
    private keycloakService: KeycloakService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.count++;
    this.sharedService.progressOn();
    // const token = localStorage.getItem('token');
    // const authorizedRequest = request.clone({
    //   headers: request.headers.set('Authorization', `Bearer ${token}`),
    // });

    const tenantId = localStorage.getItem('tenantId');
    let headers: any;
    // send tenant id to all requests, except account api if tenant id not present
    if (!request.url.includes('api/account')) {
      headers = {
        ...(tenantId && { 'X-Tenant-Id': tenantId }),
        "platform":"WA",
      };
    }
    if (request.headers.get('skip')) {
      //console.log('Skipped req interceptor');
      return next.handle(request);
    } else
      return next
        .handle(
          request.clone({
            setHeaders: headers,
          })
        )
        .pipe(
          tap(null, (err: HttpErrorResponse) => {
            console.log('inside error http interceptor');
            console.log(err);
            if (
              err.status === 401 &&
              (!err.url?.includes('api/account') ||
                (err.url?.includes('api/account') && this.router.url !== '/' && this.router.url !== '/privacy-policy'))
            ) {
              console.log('inside 401 check', err);
              console.log('router url', this.router.url);

              localStorage.removeItem('tenantId');

              this.urlStateStorageService.storeUrl(
                this.router.routerState.snapshot.url
              );

              // this.accountService.logout();
              window.location.href = '/';
            } else if (err.status === 403) {
              this.sharedService.openSnackBar(
                'Invalid Organization. Please try again with correct tenant.',
                '',
                3000
              );
              if (environment.production) {
                this.accountService.logout();
              } else this.keycloakService.logout();
            }
          })
        );
    // return next.handle(request).pipe(
    //   map((res) => {
    //     if (res instanceof HttpResponse && --this.count === 0) {
    //       this.sharedService.progressOff();
    //     }
    //     return res;
    //   }),
    //   catchError((error: HttpErrorResponse) => {

    //     if (error.status === 401 && error.url && !error.url.includes('api/account')) {
    //       this.urlStateStorageService.storeUrl(this.router.routerState.snapshot.url);

    //       if(!this.accountService.isAuthenticated())
    //       {
    //         this.accountService.login();

    //       }

    //     }
    //     // let errorMessage = '';
    //     // if (error.error instanceof ErrorEvent) {

    //     //   errorMessage = `Error: ${error.error.message}`;
    //     // } else {

    //     //   errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    //     // }
    //     // this.count--;
    //     // this.sharedService.progressOff(true);

    //     // return throwError(errorMessage);

    //   })
    // );
  }
}
