import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CandidateInfoService } from '../services/candidate-info.service';
import { Location } from '@angular/common';
import { EligibilityCriteriaComponent } from '../eligibility-criteria/eligibility-criteria.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-scholarship-instructions',
  templateUrl: './scholarship-instructions.component.html',
  styleUrls: ['./scholarship-instructions.component.scss']
})
export class ScholarshipInstructionsComponent implements OnInit {

  job;
  jobId;
  public modeselect = 'UG';
  constructor( private route: ActivatedRoute,
    public _location: Location,
    private service: CandidateInfoService,
    private router: Router,
    public dialog: MatDialog,
    ) { }

  ngOnInit(): void {
    this.jobId = this.route.snapshot.paramMap.get('jobid');

    this.service.getJob(this.jobId).subscribe((response) => {
      //console.log(response);
      const obj: any = response;
      if (obj) {
        this.job = obj;
      } 
    });

  }
  navigateToScholarship(){
    this.openDialog();
    //this.router.navigate(['candidate/apply-scholarship', this.jobId]);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(EligibilityCriteriaComponent, {
      width: '700px',
      height: '300px',

    });

    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate(['candidate/apply-scholarship', this.jobId]);
      console.log('The dialog was closed');
    });
  }

}
