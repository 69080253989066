import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Authority } from '../shared/model/authority.model';
import { Feature } from '../shared/model/feature.model';
import { FeatureName } from '../shared/model/enumerations/feature-name.model';
import { PermissionType } from '../shared/model/enumerations/permission-type.model';

import 'rxjs/add/observable/of';
import { shareReplay, tap, catchError } from 'rxjs/operators';
import { UrlStateStorageService } from '../url-state-storage.service';
import { Router } from '@angular/router';
import { Logout } from '../shared/model/logout.model';

import {
  Observable,
  forkJoin,
  Subject,
  BehaviorSubject,
  ReplaySubject,
  of,
  throwError,
} from 'rxjs';
import { Location } from '@angular/common';
import { AccountType, IAccountType } from './../shared/model/account.model';
import { AuthorityName } from '../shared/model/enumerations/authority-name.model';
import { UserInfo } from '../shared/model/userInfo-model';

import { ApmService } from '@elastic/apm-rum-angular';
@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(
    private http: HttpClient,
    private urlStateStorageService: UrlStateStorageService,
    private router: Router,
    private location: Location,
    private apmService: ApmService
  ) {}

  profile: any;
  account: IAccountType;
  _onAccountApiCallComplete = new ReplaySubject<IAccountType>();

  authorityFeatureList: Array<Authority> = [];
  candidate: any;
  exceptionList: Array<Feature> = [];
  private currentRole: string;
  private userIdentity: AccountType | null = null;
  private authenticationState = new ReplaySubject<AccountType | null>(1);
  private accountCache$?: Observable<AccountType | null>;
  tenantId: any = null;
  public tenant$ = new BehaviorSubject(this.tenantId);

  private headers = new HttpHeaders().set('content-type', 'application/json');

  getAccount = () => {
    return this.http.get(environment.profileServiceUrl + 'api/account', {
      headers: this.headers,
    });
  };

  getTenantsList = () => {
    return this.http.get(environment.profileServiceUrl + 'api/tenant');
  };

  getUserPermissionsForRole = (
    role: string,
    userId?: string,
    tenantId?: number
  ) => {
    return this.http.get(
      environment.profileServiceUrl + `api/tenant/user/role/${role}`
    );
  };

  setProfile = (data) => {
    this.profile = data;
  };

  getProfile = () => {
    return this.profile;
  };

  setCandidate(candidate: any) {
    this.candidate = candidate;
  }

  getCandidate() {
    if (this.candidate) return this.candidate;
    else {
      console.log("Candidate Data doesn't exist");
      return '';
    }
  }

  getUserDetails(): Observable<any> {
    return this.http.get<any>(environment.profileServiceUrl + 'api/account', {
      headers: this.headers,
    });
  }

  getCurrentLoggedInUser(): Observable<any> {
    return this.http.get<UserInfo>(
      environment.profileServiceUrl + 'api/profile',
      {
        headers: this.headers,
      }
    );
  }

  getUserId() {
    return this.account?.id;
  }

  // getAccount(){
  //   return this.account;
  // }

  setTenantCurrentRole = () => {
    if (localStorage.getItem('tenantId')) {
      console.log('Inside set account, inside setting role');
      let tenantId = parseInt(localStorage.getItem('tenantId'));

      let roleType = this.account.tenantAuthorities.filter(
        (e) => e.tenantId == tenantId
      )[0].authorities;

      roleType = roleType.filter((e) =>
        Object.values(AuthorityName).includes(e as AuthorityName)
      );

      if (roleType.includes(AuthorityName.ROLE_INTERVIEWER)) {
        roleType.push(
          roleType.splice(
            roleType.indexOf(AuthorityName.ROLE_INTERVIEWER),
            1
          )[0]
        );
      }

      //ADMIN_RECRUITER -- If Role is removed from Keycloack we shoud remove this line
      roleType = roleType.filter((e) => e !== 'ROLE_ADMIN_RECRUITER');
      roleType = roleType.filter((e) => e !== AuthorityName.ROLE_USER);

      if (roleType.length > 0) {
        this.currentRole = roleType[0] as AuthorityName;
        window.localStorage.setItem('currentRole', this.currentRole);
      }
      return this.currentRole;
    }
  };

  setAccount = async (data) => {
    this.account = data;
    if (!this.account) return;

    this.apmService.apm.setUserContext({
      id: this.account.id,
      username: this.account.login,
      email: this.account.email,
    });

    const tenantsId = localStorage.getItem('tenantId')
      ? localStorage.getItem('tenantId')
      : data['tenants'][0]['id'];
    localStorage.setItem('tenantId', tenantsId);

    if (!this.getCurrentRole() && localStorage.getItem('tenantId')) {
      let tenantId = parseInt(localStorage.getItem('tenantId'));

      let roleType = data.tenantAuthorities.filter(
        (e) => e.tenantId == tenantId
      )[0].authorities;

      roleType = roleType.filter((e) =>
        Object.values(AuthorityName).includes(e)
      );

      roleType = roleType.filter((e) => e !== 'ROLE_ADMIN_RECRUITER');
      roleType = roleType.filter((e) => e !== AuthorityName.ROLE_USER);

      if (roleType.length > 0) {
        this.setCurrentRole(roleType[0]);
      }
    }

    if (this.getCurrentRole() && localStorage.getItem('tenantId')) {
      let isRolePresentInTenantAuthorities = data.tenantAuthorities
        .filter((e) => e.tenantId == tenantsId)[0]
        .authorities.includes(this.getCurrentRole());

      if (!isRolePresentInTenantAuthorities) {
        this.setTenantCurrentRole();
      }

      const permissions = await this.getUserPermissionsForRole(
        this.currentRole
      ).toPromise();
      this.populateFeaturePermissions(permissions);
    }

    if (this.getCurrentRole() == 'ROLE_CANDIDATE') {
      this.setCandidate(this.account);
      localStorage.setItem('ROLE', 'ROLE_CANDIDATE');
    } else if (this.getCurrentRole() == 'ROLE_HIRING_MANAGER') {
      this.setCandidate(this.account);
      localStorage.setItem('ROLE', 'ROLE_HIRING_MANAGER');
    }
    // else if (this.getCurrentRole() == 'ROLE_VERIFIER') {
    //   this.setCandidate(this.account);
    //   localStorage.setItem('ROLE', 'ROLE_VERIFIER');
    // }

    this.apmService.apm.addLabels({
      tenantId: tenantsId,
      role: this.getCurrentRole(),
    });
  };

  getAccountApiCallObs = () => {
    return this._onAccountApiCallComplete.asObservable();
  };

  getAuthorityFeatureList = () => {
    return this.authorityFeatureList;
  };

  getAccountLocal = () => {
    return this.account;
  };

  populateFeaturePermissions = (permissions: any) => {
    //console.log("Enter populate permissions")
    let currAuthority: Authority = new Authority();
    let features: Array<Feature> = [];

    currAuthority.setAuthorityName(permissions.authority);
    permissions.features.forEach((f) => {
      features.push(new Feature(f.feature, f.permissions));
    });
    currAuthority.setFeatures(features);
    this.authorityFeatureList.push(currAuthority);
    //console.log("Exit populate permissions")
  };

  setCurrentRole = (role: string): void => {
    window.localStorage.setItem('currentRole', role);
    this.currentRole = role;
    this.getUserPermissionsForRole(this.currentRole).subscribe(
      (permissions) => {
        this.populateFeaturePermissions(permissions);
      }
    );
  };

  getCurrentRole = (): string => {
    if (!this.currentRole && window.localStorage.currentRole) {
      this.currentRole = window.localStorage.currentRole;
    }
    return this.currentRole;
  };

  hasFeatureAccess = (
    feature_name: FeatureName,
    permission_type: PermissionType
  ): boolean => {
    // console.log(this.currentRole);
    if (!this.currentRole) return false;
    // console.log(this.authorityFeatureList);
    var authority: Authority = this.authorityFeatureList.find(
      (a) => a.authority == this.currentRole
    );
    if (authority == undefined) return false;

    var feature: Feature = authority.features.find(
      (f) => f.feature == feature_name
    );
    if (feature && feature.hasPermissionAccess(permission_type)) {
      return true;
    }

    //now checking if the exceptionList has the feature or not.
    var exc_feature: Feature = this.exceptionList.find(
      (f) => f.feature == feature_name
    );
    if (exc_feature && exc_feature.hasPermissionAccess(permission_type)) {
      return true;
    }

    return false;
  };

  // hasExceptionAccess = (feature_name : FeatureName, permission_type : PermissionType) : boolean => {
  //   //now checking if the exceptionList has the feature or not.
  //   var exc_feature : Feature = this.exceptionList.find(f => f.feature == feature_name);
  //   if(exc_feature.hasPermissionAccess(permission_type)){
  //     return true;
  //   }

  //   return false;
  // }

  authenticate(identity: AccountType | null): void {
    this.userIdentity = identity;
    this.authenticationState.next(this.userIdentity);
  }

  hasAnyAuthority(authorities: string[] | string): boolean {
    if (!this.userIdentity || !this.userIdentity.authorities) {
      return false;
    }
    if (!Array.isArray(authorities)) {
      authorities = [authorities];
    }
    return this.userIdentity.authorities.some((authority: string) =>
      authorities.includes(authority)
    );
  }

  identity(force?: boolean): Observable<AccountType | null> {
    if (!this.accountCache$ || force || !this.isAuthenticated()) {
      this.accountCache$ = this.fetch().pipe(
        catchError((err) => {
          return throwError(err);
        }),
        tap((account: AccountType | null) => {
          this.authenticate(account);

          if (account) {
            this.navigateToStoredUrl();
          }
        }),
        shareReplay()
      );
    }
    return this.accountCache$;
  }

  isAuthenticated(): boolean {
    return this.userIdentity !== null;
  }

  getAuthenticationState(): Observable<AccountType | null> {
    return this.authenticationState.asObservable();
  }

  private navigateToStoredUrl(): void {
    // previousState can be set in the authExpiredInterceptor and in the userRouteAccessService
    // if login is successful, go to stored previousState and clear previousState
    const previousUrl = this.urlStateStorageService.getUrl();
    if (previousUrl) {
      this.urlStateStorageService.clearUrl();
      this.router.navigateByUrl(previousUrl);
    }
  }

  private fetch(): Observable<AccountType> {
    return this.http.get<AccountType>(
      environment.profileServiceUrl + 'api/account'
    );
  }
  login(): void {
    // If you have configured multiple OIDC providers, then, you can update this URL to /login.
    // It will show a Spring Security generated login page with links to configured OIDC providers.

    location.href = `${location.origin}${this.location.prepareExternalUrl(
      '/oauth2/authorization/oidc'
    )}`;
  }

  logout(): void {
    this.logoutProfile().subscribe((logout: Logout) => {
      let logoutUrl = logout.logoutUrl;
      let url = location.origin;
      const redirectUri = `${url}${this.location.prepareExternalUrl('/')}`;

      // if Keycloak, uri has protocol/openid-connect/token
      if (logoutUrl.includes('/protocol')) {
        logoutUrl = logoutUrl + '?redirect_uri=' + redirectUri;
      } else {
        // Okta
        logoutUrl =
          logoutUrl +
          '?id_token_hint=' +
          logout.idToken +
          '&post_logout_redirect_uri=' +
          redirectUri;
      }
      window.location.href = logoutUrl;
    });
  }

  logoutProfile(): Observable<Logout> {
    localStorage.removeItem('currentRole');
    return this.http.post<Logout>('api/logout', {});
  }
}
