import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AccountService } from '../services/account.service';
import { FeatureName } from '../shared/model/enumerations/feature-name.model';
import { PermissionType } from '../shared/model/enumerations/permission-type.model';

@Injectable({
  providedIn: 'root'
})
export class FeatureGuard implements CanActivate {
  constructor(private accountService: AccountService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    let featureName:FeatureName = route.data.featureName;
    let permissionType:PermissionType = route.data.permissionType;
    const userAcc = this.accountService.hasFeatureAccess(featureName,permissionType);
    if (userAcc) {
      return true;
    } else {
      console.warn("In feature guard else");
      console.warn("Role in else", this.accountService.getCurrentRole());
      return this.accountService.getUserPermissionsForRole(this.accountService.getCurrentRole()).pipe(
        map((e) => {
          this.accountService.populateFeaturePermissions(e);
          if (this.accountService.hasFeatureAccess(featureName,permissionType)) {
            return true;
          } else {
            this.router.navigate(['/']);
            return false;
          }
        }),
        catchError((err) => {
          return of(false);
        })
      );



  }
  
}
}
