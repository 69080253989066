import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { EventService } from 'src/app/services/event.service';
import { SharedService } from 'src/app/services/shared.service';
import { AccountService } from 'src/app/services/account.service';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
import { AuthorityName } from 'src/app/shared/model/enumerations/authority-name.model';
import { AuthorityNameUI } from 'src/app/shared/model/enumerations/authority-name-ui.model';

@Component({
  template: `<i
      *ngIf="
        params.condition &&
        accountService.hasFeatureAccess(
          FeatureName.EVENT_PANELIST,
          PermissionType.DELETE
        )
      "
      class="icon x-24 {{ icon }}"
      role="button"
      (click)="onClick()"
      matTooltip="Delete Recruiter"
      matTooltipClass="DSA_tooltip"
      aria-label="Delete Recruiter"
    ></i>

    <span *ngIf="type === 'role'"> {{ role }}</span> `,
})
export class eventRecruiterActionRendererComponent
  implements AgRendererComponent {
  params: any;
  icon: any;
  type: any;
  role: String = '';

  FeatureName = FeatureName;
  PermissionType = PermissionType;

  constructor(
    private dialog: MatDialog,
    private eventService: EventService,
    private sharedService: SharedService,
    public accountService: AccountService
  ) {}

  agInit(params: any): void {
    this.params = params;
    if (params.icon === 'delete') {
      this.icon = 'delete-sm-icon';
    }

    if (params.type) {
      this.type = params.type;
      if (this.params.data?.eventRoles) {
        this.role = this.getEventRole(this.params.data?.eventRoles);
      }
      // if (this.params.data?.eventRoles.includes(AuthorityName.ROLE_SCHEDULER)) {
      //   this.role = 'CO-ORDINATOR';
      // } else {
      //   this.role = 'RECRUITER';
      // }
    }
  }

  getEventRole = (eventRoles) => {
    if (
      eventRoles.includes(AuthorityName.ROLE_RECRUITER) ||
      eventRoles.includes(AuthorityName.ROLE_EVENT_ADMIN) ||
      eventRoles.includes(AuthorityName.ROLE_SUPER_ADMIN)
    ) {
      return AuthorityNameUI.ROLE_RECRUITER;
    } else if (eventRoles.includes(AuthorityName.ROLE_SCHEDULER)) {
      return AuthorityNameUI.ROLE_SCHEDULER;
    } else if (eventRoles.includes(AuthorityName.ROLE_SPOC)) {
      return AuthorityNameUI.ROLE_SPOC;
    } else if (eventRoles.includes(AuthorityName.ROLE_PLATFORM_EXECUTIVE)) {
      return AuthorityNameUI.ROLE_PLATFORM_EXECUTIVE;
    } else {
      return AuthorityNameUI.ROLE_SUPPORT_EXECUTIVE;
    }
  };

  refresh(params: any): boolean {
    return false;
  }

  onClick() {
    if (this.params.icon === 'delete') {
      this.removeRecruiter(this.params.data);
    }
  }

  removeRecruiter = (recruiter) => {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        text: `Are you sure you want to remove <b>${recruiter.recruiterEmail}</b>?`,
      },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Yes') {
        this.params.api.showLoadingOverlay();
        this.eventService.deleteRecrutersOfEvent(recruiter).subscribe((res) => {
          this.params.context.componentParent.refreshTable();
          this.sharedService.openSnackBar(
            'Recruiter deleted successfully',
            '',
            3000
          );
        });
      }
    });
  };
}
