import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ProjectDetails } from 'src/app/shared/model/Profile/projectDetails-model';
import { SharedService } from 'src/app/services/shared.service';
import { CandidateInfoService } from '../../../candidate/services/candidate-info.service';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss'],
})
export class ProjectDetailsComponent implements OnInit {
  // candidateInfo: ICandidateInfo;
  spinner: boolean = false;
  message: string;
  @Input() projectDetails: ProjectDetails; //[];

  @Output() newItemEvent = new EventEmitter<ProjectDetails>();

  updateExtractedResume() {
    if (!this.projectDetails) this.projectDetails = this.projectInfoForm.value;
    else
      this.projectDetails = {
        ...this.projectDetails,
        ...this.projectInfoForm.value,
      };

    this.services
      .UpdateProjectDeatailsOfProfile(this.projectDetails)
      .subscribe({
        next: (res: any) => {
          this.projectDetails = res;
          this.sharedService.openSuccessSnackBar(
            'data updated successfully',
            ''
          );
        },
        error: (err: any) => {
          this.sharedService.openErrorSnackBar(
            'data update failed. Please try again',
            ''
          );
        },
      });

    this.newItemEvent.emit(this.projectDetails);
  }
  constructor(
    private fb: FormBuilder,
    private services: CandidateInfoService,
    private sharedService: SharedService // protected projectDetailsService: ProjectDetailsService,
  ) {}
  projectInfoForm: FormGroup;
  ngOnInit(): void {
    this.projectInfoForm = this.fb.group({
      id: [null, Validators.required],
      currentproject: [null, Validators.required],
      description: [null, Validators.required],
      technologyUsed: [null, Validators.required],
      tools: [null, Validators.required],

      projectHistoryDTOs: this.fb.array([
        this.fb.group({
          id: [null, Validators.required],
          projectName: [null, Validators.required],
          projectStartDate: [null, Validators.required],
          projectEndDate: [null, Validators.required],
          skills: [null, Validators.required],
          tools: [null, Validators.required],
          technologyUsed: [null, Validators.required],
          projectDetailsId: [null, Validators.required],
        }),
      ]),
    });
    this.patchResultList();
    //this.projectInfoForm.patchValue(this.projectDetails);
  }

  patchResultList() {
    for (
      let i = 0;
      i < this.projectDetails.projectHistoryDTOs.length - 1;
      i++
    ) {
      this.addDegree();
    }
    this.projectInfoForm.patchValue(this.projectDetails);
  }
  addDegree(): void {
    (this.projectInfoForm.get('projectHistoryDTOs') as FormArray).push(
      this.fb.group({
        projectName: [null, Validators.required],
        projectStartDate: [null, Validators.required],
        projectEndDate: [null, Validators.required],
        skills: [null, Validators.required],
        tools: [null, Validators.required],
        technologyUsed: [null, Validators.required],
        projectDetailsId: [null, Validators.required],
        id: [null, Validators.required],
      })
    );
  }
  getDTOFormControls(): AbstractControl[] {
    return (<FormArray>this.projectInfoForm.get('projectHistoryDTOs')).controls;
  }
  data: ProjectDetails;
  submitDetails() {
    this.data = this.projectInfoForm.value;
    this.services.UpdateProjectDeatailsOfProfile(this.data);
    console.log(this.data);
  }
}
