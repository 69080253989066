import { ConfirmationDialogComponent } from 'src/app/survey-master/confirmation-dialog/confirmation-dialog.dialog.component';
import { FileserverService } from 'src/app/survey-master/survey/edit-backgroud-color/fileserver.service';
import { Theme, QuestionView } from 'src/app/shared/model/theme.model';
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/tslint/config */
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SurveyDefinition } from 'src/app/shared/model/survey-definition.model';
import { IApplication } from 'src/app/shared/model/application.model';
import { SurveyResponsesService } from './survey-responses.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ExportService, SheetDetails, DateFormat } from './export.service';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
// import { IApplication } from 'src/app/shared/model/application.model';
import { NotifierService } from 'angular-notifier';
import { MediaObserver } from '@angular/flex-layout';
import { NgxSpinnerService } from 'ngx-spinner';
import { ImageEditService } from '../survey-details/image-edit.service';

@Component({
  selector: 'jhi-survey-responses',
  templateUrl: './survey.responses.component.html',
  styleUrls: ['./survey.responses.component.scss'],
})
export class SurveyResponsesComponent implements OnInit {
  surveyDefinition: SurveyDefinition;

  application: IApplication;
  pagedQuestions: any = [];

  surveyResponses: any = [];
  publishedSurveys: any = [];
  currentSurvey: any = null;
  currentPublishedSurvey: any = null;
  currPage: any = null;
  currPageIndex: number = null;
  currPaginationNumber = 0;
  totalLength = 0;
  currentPublishedSurveyId: number;

  displayedColumns = [];
  folder: IApplication;
  private readonly notifier: NotifierService;
  popupRespondentWindow = {
    left: 100,
    top: 100,
    defaultLeft: -50,
    defaultTop: 50,
    hidden: true,
  };
  popupRespondent = null;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    protected activatedRoute: ActivatedRoute,
    public surveyResponsesService: SurveyResponsesService,
    public exportService: ExportService,
    notifierService: NotifierService,
    private mediaObserver: MediaObserver,
    public fileserverService: FileserverService,
    private spinner: NgxSpinnerService,
    public imageService: ImageEditService
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ application }) => {
      this.application = application;
      this.folder = application;
      this.activatedRoute.data.subscribe(({ surveyDefinition }) => {
        this.surveyDefinition = surveyDefinition;
        if (
          this.surveyDefinition.status === 'UNPUBLISHED' ||
          this.surveyDefinition.status === null
        ) {
          this.notifier.notify(
            'info',
            'This survey is not published, Please publish the survey First'
          );
          this.router.navigate([
            `/${this.folder.identifier}/survey/${this.surveyDefinition.identifier}/preview`,
          ]);
        }

        this.surveyResponsesService
          .getAllPublishedSurveys(this.surveyDefinition.id)
          .subscribe(
            (res: HttpResponse<any>) => {
              this.publishedSurveys = res.body;
              if (this.publishedSurveys.length > 0) {
                this.selectPublishedSurvey({
                  value: this.publishedSurveys[0].id,
                });
              }
            },
            (res: HttpErrorResponse) => {}
          );
      });
    });
  }

  refresh() {
    this.spinner.show('refreshSpinner');
    this.surveyResponsesService
      .getAllResponses(
        this.currentPublishedSurvey.id,
        `page=${this.currPaginationNumber}&size=5`
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          //
          this.surveyResponses = res.body;
          this.totalLength = Number(res.headers.get('x-total-count'));
          this.spinner.hide('refreshSpinner');
        },
        (res: HttpErrorResponse) => {
          this.spinner.hide('refreshSpinner');
          this.notifier.notify(
            'error',
            'Could not refresh Responses! Please try again'
          );
        }
      );
  }

  pageChange(event: any) {
    this.currPaginationNumber = event.pageIndex;
    this.surveyResponsesService
      .getAllResponses(
        this.currentPublishedSurvey.id,
        `page=${this.currPaginationNumber}&size=5`
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          //
          this.surveyResponses = res.body;
          this.totalLength = Number(res.headers.get('x-total-count'));
        },
        (res: HttpErrorResponse) => {
          console.log(
            'get responses for published survey error',
            JSON.stringify(res)
          );
        }
      );
  }

  selectPage(event: any) {
    this.currPage = this.pagedQuestions.find((item) => item.id === event.value);
  }

  getCss() {
    //
    if (!this.isMobile()) {
      //
      return { display: 'flex' };
    }
    return '';
  }

  isMobile(): boolean {
    return (
      this.mediaObserver.isActive('xs') || this.mediaObserver.isActive('sm')
    );
  }

  selectPublishedSurvey(event: any) {
    const singleSurvey = this.publishedSurveys.find(
      (item) => item.id === event.value
    );
    if (singleSurvey !== undefined) {
      this.currentPublishedSurvey = singleSurvey;
      this.currentPublishedSurveyId = singleSurvey.id;
      this.currentSurvey = JSON.parse(singleSurvey.json);
      // get answers of published survey
      this.pagedQuestions = JSON.parse(singleSurvey.questionsJson);
      this.currPage = this.pagedQuestions[0];

      this.surveyResponsesService
        .getAllResponses(
          this.currentPublishedSurvey.id,
          `page=${this.currPaginationNumber}&size=5`
        )
        .subscribe(
          (res: HttpResponse<any>) => {
            //
            this.surveyResponses = res.body;
            this.totalLength = Number(res.headers.get('x-total-count'));
          },
          (res: HttpErrorResponse) => {
            console.log(
              'get responses for published survey error',
              JSON.stringify(res)
            );
          }
        );
    }
  }

  getQuestionText(id: any) {
    let foundValue = null;
    for (let i = 0; i < this.pagedQuestions.length; i++) {
      for (let j = 0; j < this.pagedQuestions[i].sections.length; j++) {
        for (
          let k = 0;
          k < this.pagedQuestions[i].sections[j].questions.length;
          k++
        ) {
          if (this.pagedQuestions[i].sections[j].questions[k].id === id) {
            foundValue = this.pagedQuestions[i].sections[j].questions[k].text;
            break;
          }
        }
        if (foundValue !== null) {
          break;
        }
      }
      if (foundValue !== null) {
        break;
      }
    }

    if (foundValue === null) {
      return '';
    } else return foundValue;
  }

  downloadData(answersList: any[]) {
    if (this.surveyDefinition === null || this.pagedQuestions === null) {
      this.notifier.notify(
        'warn',
        'Please select a publish version to view responses'
      );
      return;
    }
    const sheet = this.formatAnswerData(answersList);
    this.exportService.exportExcel(sheet, this.surveyDefinition.title);
  }

  formatAnswerData(answersList) {
    const sheet: SheetDetails[] = [];

    for (let i = 0; i < this.pagedQuestions.length; i++) {
      if (sheet[i] == null) {
        sheet[i] = {
          pageName: this.pagedQuestions[i].title
            ? this.pagedQuestions[i].title
            : 'Page No ' + i + 1,
          data: [],
        };
      }
    }

    answersList.forEach((element) => {
      const questionResponses = element.questionResponses;

      let respondant = null;
      if (element.respondent !== undefined) respondant = element.respondent;

      // console.log(JSON.stringify(answers))
      const allPagesData: DateFormat[] = [];
      for (let i = 0; i < this.pagedQuestions.length; i++) {
        allPagesData.push({});
      }
      const singleData: DateFormat = {};
      questionResponses.forEach((response, i) => {
        const questionText = this.getQuestionText(response.questionId);
        const pageIndex = this.getPageIndex(response.questionId);
        // if(allPagesData[pageIndex][questionText]!==null){
        //   questionText += response.questionId;
        // }
        if (this.getQuestionType(response.questionId) === 'File_upload') {
          let stringtoAdd = '';
          for (let j = 0; j < response.answer.length; j++) {
            stringtoAdd += this.downloadImage(response.answer[j]) + ', ';
          }
          allPagesData[pageIndex][questionText] = stringtoAdd;
        } else if (
          this.getQuestionType(response.questionId) === 'Photo_upload'
        ) {
          allPagesData[pageIndex][questionText] = this.downloadImage(
            response.answer[0]
          );
        } else {
          allPagesData[pageIndex][questionText] = response.answer.join();
        }
      });
      for (let i = 0; i < this.pagedQuestions.length; i++) {
        if (
          respondant &&
          (respondant.firstName !== null ||
            respondant.lastName !== null ||
            respondant.email !== null)
        ) {
          allPagesData[i]['Respondant Name'] =
            respondant.firstName + ' ' + respondant.lastName;
          allPagesData[i]['Respondant email'] = respondant.email;
        }
        sheet[i].data.push(allPagesData[i]);
      }
      // sheet[index].data.push(singleData);
    });

    return sheet;
  }

  downloadResponses() {
    if (!this.currentPublishedSurvey) {
      this.notifier.notify('warn', 'Please select a survey version');
      return;
    }
    this.notifier.notify('info', 'Downloading...');

    this.surveyResponsesService
      .getAllResponses(
        this.currentPublishedSurvey.id,
        `page=0&size=${this.totalLength}`
      )
      .subscribe(
        (res: HttpResponse<any>) => {
          //
          this.downloadData(res.body);
        },
        (res: HttpErrorResponse) => {
          console.log(
            'get responses for published survey error',
            JSON.stringify(res)
          );
        }
      );
  }

  getPageIndex(questionId: any) {
    let foundValue: number = null;
    for (let i = 0; i < this.pagedQuestions.length; i++) {
      for (let j = 0; j < this.pagedQuestions[i].sections.length; j++) {
        for (
          let k = 0;
          k < this.pagedQuestions[i].sections[j].questions.length;
          k++
        ) {
          if (
            this.pagedQuestions[i].sections[j].questions[k].id === questionId
          ) {
            foundValue = i;
            break;
          }
        }
        if (foundValue !== null) {
          break;
        }
      }
      if (foundValue !== null) {
        break;
      }
    }

    if (foundValue === null) {
      return -1;
    } else return foundValue;
  }

  getQuestionOptions(question: any) {
    let foundValue: any = null;
    for (let i = 0; i < this.pagedQuestions.length; i++) {
      for (let j = 0; j < this.pagedQuestions[i].sections.length; j++) {
        for (
          let k = 0;
          k < this.pagedQuestions[i].sections[j].questions.length;
          k++
        ) {
          if (
            this.pagedQuestions[i].sections[j].questions[k].id ===
            question.questionId
          ) {
            foundValue =
              this.pagedQuestions[i].sections[j].questions[k].type.options;
            break;
          }
        }
        if (foundValue !== null) {
          break;
        }
      }
      if (foundValue !== null) {
        break;
      }
    }

    if (foundValue === null) {
      return [];
    } else return foundValue;
  }

  getContactOptions(question: any) {
    const contactOptions: any = [];
    let questionFound = null;

    for (let i = 0; i < this.pagedQuestions.length; i++) {
      for (let j = 0; j < this.pagedQuestions[i].sections.length; j++) {
        for (
          let k = 0;
          k < this.pagedQuestions[i].sections[j].questions.length;
          k++
        ) {
          if (
            this.pagedQuestions[i].sections[j].questions[k].id ===
            question.questionId
          ) {
            questionFound = this.pagedQuestions[i].sections[j].questions[k];
            break;
          }
        }
        if (questionFound !== null) {
          break;
        }
      }
      if (questionFound !== null) {
        break;
      }
    }
    if (questionFound !== null) {
      if (this.getValidationValue(questionFound, 'USE_NAME') === 'true') {
        contactOptions.push({
          label: this.getValue(questionFound, 'NAME_LABEL'),
          value: question.answer[0],
        });
      }
      if (this.getValidationValue(questionFound, 'USE_COMPANY') === 'true') {
        contactOptions.push({
          label: this.getValue(questionFound, 'COMPANY_LABEL'),
          value: question.answer[1],
        });
      }
      if (this.getValidationValue(questionFound, 'USE_ADDRESS') === 'true') {
        contactOptions.push({
          label: this.getValue(questionFound, 'ADDRESS_LABEL'),
          value: question.answer[2],
        });
      }
      if (this.getValidationValue(questionFound, 'USE_ADDRESS_2') === 'true') {
        contactOptions.push({
          label: this.getValue(questionFound, 'ADDRESS_2_LABEL'),
          value: question.answer[3],
        });
      }
      if (this.getValidationValue(questionFound, 'USE_CITY') === 'true') {
        contactOptions.push({
          label: this.getValue(questionFound, 'CITY_LABEL'),
          value: question.answer[4],
        });
      }
      if (this.getValidationValue(questionFound, 'USE_STATE') === 'true') {
        contactOptions.push({
          label: this.getValue(questionFound, 'STATE_LABEL'),
          value: question.answer[5],
        });
      }
      if (this.getValidationValue(questionFound, 'USE_ZIP') === 'true') {
        contactOptions.push({
          label: this.getValue(questionFound, 'ZIP_LABEL'),
          value: question.answer[6],
        });
      }
      if (this.getValidationValue(questionFound, 'USE_COUNTRY') === 'true') {
        contactOptions.push({
          label: this.getValue(questionFound, 'COUNTRY_LABEL'),
          value: question.answer[7],
        });
      }
      if (this.getValidationValue(questionFound, 'USE_EMAIL') === 'true') {
        contactOptions.push({
          label: this.getValue(questionFound, 'EMAIL_LABEL'),
          value: question.answer[8],
        });
      }
      if (this.getValidationValue(questionFound, 'USE_PHONE') === 'true') {
        contactOptions.push({
          label: this.getValue(questionFound, 'PHONE_LABEL'),
          value: question.answer[9],
        });
      }
    }

    return contactOptions;
  }

  getValidationValue(question: any, param: string): string {
    const object = question.type.validationValues.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }

  getValue(question: any, param: string): string {
    const object = question.type.values.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }

  getQuestionType(questionId: any) {
    let foundValue: string = null;
    // console.log('question id ',questionId)
    for (let i = 0; i < this.pagedQuestions.length; i++) {
      for (let j = 0; j < this.pagedQuestions[i].sections.length; j++) {
        for (
          let k = 0;
          k < this.pagedQuestions[i].sections[j].questions.length;
          k++
        ) {
          if (
            this.pagedQuestions[i].sections[j].questions[k].id === questionId
          ) {
            //
            foundValue =
              this.pagedQuestions[i].sections[j].questions[k].type
                .typeDefinition.identifier;
            break;
          }
        }
        if (foundValue !== null) {
          break;
        }
      }
      if (foundValue !== null) {
        break;
      }
    }
    //
    if (foundValue === null) {
      return '';
    } else return foundValue;
  }

  downloadImage(path: string) {
    return this.fileserverService.getFileURLbyPath(path);
  }

  deleteResponses() {
    if (this.currentPublishedSurvey === null) {
      this.notifier.notify('warn', 'Please select a survey version');
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: 'Are you Sure?',
        message:
          'This will delete all the responses captured till now for this version of survey. <b>This action is not revertable</b>',
        yesText: 'Delete',
        noText: "Don't Delete",
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (data.confirm) {
          this.surveyResponsesService
            .deleteAllResponses(this.currentPublishedSurvey.id)
            .subscribe(
              (res: HttpResponse<any>) => {
                this.notifier.notify('success', 'Responses Deleted!');
                // this.downloadData(res.body);
                this.surveyResponses = [];
                this.totalLength = 0;
              },
              (res: HttpErrorResponse) => {
                this.notifier.notify(
                  'error',
                  'Sorry! we could not delete the responses, please try again'
                );
              }
            );
        } else {
          // do nothing
        }
      } else {
        // do nothing
      }
    });
  }

  getFilesforDisplay(questionResponse: any) {
    let stringHTML = '';
    for (let i = 0; i < questionResponse.answer.length; i++) {
      stringHTML +=
        "<a href='" + this.downloadImage(questionResponse.answer[i]) + "'>";
      stringHTML += 'File ' + (i + 1);
      stringHTML += '</a><br>';
    }
    return stringHTML;
  }

  getPhotoforDisplay(questionResponse: any) {
    // imageService.getUpscaleUrl(website_url+downloadImage(surveyDefinition.logo.path),'80x80')
    const stringHTML = this.imageService.getUpscaleUrl(
      location.origin + this.downloadImage(questionResponse.answer[0]),
      '80x0'
    );

    return stringHTML;
  }

  getMapLink(questionResponse: any) {
    //
    const url = `https://www.openstreetmap.org/?mlat=${questionResponse.answer[1]}&mlon=${questionResponse.answer[0]}&zoom=8`;
    //
    return url;
  }

  isRespondent(response: any) {
    if (response.respondent && response.respondent.firstName !== null) {
      return true;
    }
    return false;
  }

  getRespondent(response: any) {
    //
    if (response.respondent && response.respondent.firstName !== null) {
      return response.respondent.firstName + ' ' + response.respondent.lastName;
    }
    return '';
  }

  showRespondentDetails(event: any, response: any) {
    this.popupRespondent = response.respondent;
    let x =
      (event.target as HTMLElement).getBoundingClientRect().right -
      this.popupRespondentWindow.defaultLeft;
    let y = event.clientY - this.popupRespondentWindow.defaultTop;
    //
    //
    if (x + 300 > window.innerWidth) x = window.innerWidth - 300;
    if (y + 300 > window.innerHeight && !this.isMobile()) {
      y = window.innerHeight - 300;
      //
    }
    if (this.isMobile()) {
      x = 0;
      y = 700;
    }

    this.popupRespondentWindow.left = x;
    this.popupRespondentWindow.top = y;
    this.popupRespondentWindow.hidden = !this.popupRespondentWindow.hidden;
    event.stopPropagation();
  }

  documentClick() {
    if (!this.popupRespondentWindow.hidden)
      this.popupRespondentWindow.hidden = true;
  }
}
