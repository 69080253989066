<h1 mat-dialog-title>Confirmation</h1>
<div mat-dialog-content>
  <mat-form-field
    class="DSA_Wb_custom-form-field"
    *ngIf="['VERIFIER_REJECTED'].includes(data.finalAction)"
  >
    <input
      matInput
      placeholder="Rejection Reason"
      [(ngModel)]="remarks"
      required
    />
  </mat-form-field>
  <p [innerHTML]="data?.messageToShow"></p>
  <p class="DSA_wb_caption">
    This action is <strong class="font-red">irreversible</strong>
  </p>
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <button mat-button (click)="handleNoClick()" class="DSA_wb_miniSecondary-btn">
    No
  </button>
  <button mat-button (click)="handleYesClick()" class="DSA_wb_miniPrimary-btn">
    Yes
  </button>
</div>


