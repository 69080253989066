<div class="container-fluid layout custom-loader" *ngIf="display">
  <div class="row text-center">
    <div class="loader_cont">
      <!-- <img src="assets/images/caurosel/desktop/pic_01.png" alt="" /> -->
      <div class="loading-spinner">
        <div class="loading-spinner-inner">
          <div></div>
        </div>
      </div>
    </div>
    <p>{{ textToShow }}</p>
  </div>
</div>
