import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ViewAllJobsComponent } from './view-all-jobs/view-all-jobs.component';
import { ViewJobDetailsComponent } from './view-job-details/view-job-details.component';
import { UploadResumeComponent } from './upload-resume/upload-resume.component';
import { ApplyJobComponent } from './apply-job/apply-job.component';
import { CandidateApplicationsComponent } from './candidate-applications/candidate-applications.component';
import { ViewApplicationDetailsComponent } from './candidate-applications/view-application-details/view-application-details.component';
import { ScholarshipInstructionsComponent } from './scholarship-instructions/scholarship-instructions.component';
import { SubmitScholarshipComponent } from './submit-scholarship/submit-scholarship.component';
import { CandidateProfileComponent, componentPath } from './candidate-profile/candidate-profile.component';
import { ApplyStepperComponent } from './apply-stepper/apply-stepper.component';
import { EducationDetailsComponent } from './candidate-profile/education-details/education-details.component';
import { PersonalDetailsComponent } from './candidate-profile/personal-details/personal-details.component';
import { ApplicationStepperComponent } from './application-stepper/application-stepper.component';
import { ScholarshipCardV1Component } from './view-all-jobs/scholarship-card-v1/scholarship-card-v1.component';
import { BasicDetailsComponent } from './candidate-profile/basic-details/basic-details.component';
import { ContactDetailsComponent } from './candidate-profile/contact-details/contact-details.component';
import { ScholarshipDetailsComponent } from './candidate-profile/scholarship-details/scholarship-details.component';
import { CandidateGuardGuard } from 'src/app/guards/candidate-guard.guard';
import { ProvisionalOfferLetterComponent } from './provisional-offer-letter/provisional-offer-letter.component';
import { TeamDetailsComponent } from './candidate-profile/team-details/team-details.component';
import { SynopsisTemplateComponent } from './candidate-profile/synopsis-template/synopsis-template.component';
import { TablistTemplateComponent } from './candidate-profile/tablist-template/tablist-template.component';
// import { TeamDetailsComponent } from './candidate-profile/education-details/education-details.component';
// import { PersonalDetailsComponent } from './candidate-profile/personal-details/personal-details.component';


const candidateRoutes: Routes = [
  {
    path: '',
    redirectTo: 'problem-statements',
    pathMatch: 'full',
  },
  {
    path: 'upload-resume',
    component: UploadResumeComponent,
  },
  {
    path: 'problem-statements',
    component: ViewAllJobsComponent,
  },
  {
    path: 'problem-statements/:jobid',
    component: ViewJobDetailsComponent,
  },
  {
    path : 'application/instructions/:jobid',
    component: ScholarshipInstructionsComponent,
  },
  {
    path: 'apply-scholarship/:jobid',
    component: ApplyJobComponent,
  },
  {
    path: 'view-applications',
    component: CandidateApplicationsComponent,
  },
  {
    path: 'education-details',
    component: TeamDetailsComponent,
  },
  {
    path: 'personal-details',
    component: PersonalDetailsComponent,
  },
  {
    path: 'view-application-details/:applicantId/:jobid',
    component: ViewApplicationDetailsComponent,
  },
  {
    path: 'submit-application',
    component: SubmitScholarshipComponent,
  },
  {
    path: 'apply-stepper',
    component: ApplyStepperComponent,
  },
  {
    path:'provisional-offer-letter/:jobid',
    component: ProvisionalOfferLetterComponent,
  },
  {
    path: 'application/create/:jobid',
    component: ApplicationStepperComponent,
    canActivate: [CandidateGuardGuard],
    canActivateChild: [CandidateGuardGuard],
    // children: [
    //   { path: '', redirectTo: componentPath[0], pathMatch: 'full'},
    //   { path: componentPath[0], component: tab },
    //   // { path: componentPath[1], component: EducationDetailsComponent },
    // ]
  },
  {
    path: 'application/edit/:jobid',
    component: ApplicationStepperComponent,
    canActivate: [CandidateGuardGuard],
    canActivateChild: [CandidateGuardGuard],
  },
  {
    path: 'synopsis-details/:jobid',
    component: SynopsisTemplateComponent,
  },
  {
    path: 'application/:jobid',
    component: TablistTemplateComponent
  },
  {
    path: 'temp',
    component: ScholarshipCardV1Component,
  }
];

export const candidateRouting = RouterModule.forChild(candidateRoutes);
@NgModule({
  declarations: [],
  imports: [CommonModule, candidateRouting],
  exports: [RouterModule],
})
export class CandidateRoutingModule {}

