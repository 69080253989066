import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DATE_FORMAT } from 'src/app/shared/constants/input.constants';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from 'src/app/app.constants';
import { createRequestOption } from 'src/app/shared/util/request-util';
import { ISurveyDefinition } from 'src/app/shared/model/survey-definition.model';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class SurveyDesignService {
  public resourceUrl =
    environment.feedbackServiceUrl  + 'api/questions';
  // public resourceUrl = SERVER_API_URL + 'api/questions';

  constructor(protected http: HttpClient) {}

  savedPagedQuestions(
    surveyId: number,
    pagedQuestionsList: any
  ): Observable<HttpResponse<any>> {
    return this.http.post<any>(
      `${this.resourceUrl}/by/surveys/${surveyId}/grouped`,
      pagedQuestionsList,
      { observe: 'response' }
    );
  }
  getPagedQuestions(surveyId: any): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.resourceUrl}/by/surveys/${surveyId}/grouped`,
      { observe: 'response' }
    );
  }
}
