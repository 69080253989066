import { SelectedRole } from '../../modules/iwp-event/infy-panelists/infy-panelists.component';
import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from 'src/app/services/shared.service';
import { AccountService } from 'src/app/services/account.service';
import { AuthorityName } from 'src/app/shared/model/enumerations/authority-name.model';
import { Authority } from 'src/app/shared/model/authority.model';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
import { AuthorityNameUI } from 'src/app/shared/model/enumerations/authority-name-ui.model';
import { ThisReceiver } from '@angular/compiler';
import { AccountType } from 'src/app/shared/model/account.model';
import { ITenant } from 'src/app/shared/model/tenant.model';
import { environment } from 'src/environments/environment';
import { timer } from 'rxjs';
import { CandidateProfileService } from 'src/app/modules/candidate/candidate-profile/candidate-profile.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isLoggedIn: boolean = false;
  links = [
    // {name: 'Dashboard', link: 'dashboard'},
    // {name: 'Jobs', link: 'jobs'},
    // {name: 'Evaluation', link: 'evaluation'}
  ];
  account: AccountType | null = null;
  profile = {
    firstName: '',
    email: '',
  };
  data: AccountType | null = null;
  progressBar$: Observable<any>;
  progressBarStatus = {
    on: false,
    mode: '',
    color: '',
    error: false,
  };
  role: string = '';
  AuthorityName = AuthorityName;
  FeatureName = FeatureName;
  PermissionType = PermissionType;
  AuthorityNameUI = AuthorityNameUI;

  tenantsList: ITenant[];
  selectedTenantId: number;
  tenantCheck: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private keycloakService: KeycloakService,
    public dialog: MatDialog,
    private sharedService: SharedService,
    public accountService: AccountService,
    private userDetailsService: AccountService,
    private candidateProfileService: CandidateProfileService
  ) {}
  roleType = [];
  roleTypeUI = [];

  roleSelected: string;
  checkHighestRole: string;

  roleTypeToUIMapping: Map<AuthorityName, AuthorityNameUI> = new Map();

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    if (event.target.id !== 'sidenav-icon') {
      document.getElementById('mainleftNav')?.classList.add('sidenav-hide');
    }
  }

  ngOnInit(): void {
    this.isLoggedIn = false;
    document.addEventListener('visibilitychange', () => {
      if (this.isLoggedIn && !document.hidden) {
        // if tenant is changed in a diff tab, refresh current tab to reflect data for latest selected tenant
        const storedTenantId = localStorage.getItem('tenantId');
        if (storedTenantId !== this.selectedTenantId?.toString()) {
          location.reload();
        }
      }
    });

    this.progressBar$ = this.sharedService.progressBarTrigger();
    this.progressBar$.subscribe((d) => {
      this.progressBarStatus = d;
    });

    const accountDetailsSubscription = this.accountService
      .getAuthenticationState()
      .subscribe((accountDetails) => {
        if (accountDetails) {
          if (!localStorage.getItem('tenantId')) {
            this.accountService.tenant$.subscribe((res) => {
              if (res) {
                this.isLoggedIn = true;
                this.handleRoleState(accountDetails);
                accountDetailsSubscription?.unsubscribe();
              }
            });
          } else {
            this.isLoggedIn = true;
            this.handleRoleState(accountDetails);
            accountDetailsSubscription?.unsubscribe();
          }
          //   this.isLoggedIn = true;
          //   this.handleRoleState(accountDetails);
          //   accountDetailsSubscription?.unsubscribe();
        }
      });
    //this.getLoggedInUser(); fix this MP api profile call blocking
  }

  getLoggedInUser = () => {
    this.userDetailsService.getUserDetails().subscribe((data) => {
      this.account = data;
      this.userDetailsService.setAccount(data);

      this.initializeRoleTypeToUIMapping();

      if (data['authorities'].includes(AuthorityName.ROLE_RECRUITER)) {
        this.role = AuthorityName.ROLE_RECRUITER;
      } else if (
        data['authorities'].includes(AuthorityName.ROLE_PLATFORM_EXECUTIVE)
      ) {
        this.role = AuthorityName.ROLE_PLATFORM_EXECUTIVE;
      } else if (data['authorities'].includes(AuthorityName.ROLE_CANDIDATE)) {
        this.role = AuthorityName.ROLE_CANDIDATE;
      } else if (
        data['authorities'].includes(AuthorityName.ROLE_HIRING_MANAGER)
      ) {
        this.role = AuthorityName.ROLE_HIRING_MANAGER;
      }
    });
  };

  handleRoleState = (accountDetails) => {
    this.data = accountDetails;
    this.tenantsList = this.data.tenants;
    this.selectedTenantId = parseInt(localStorage.getItem('tenantId'));

    this.initializeRoleTypeToUIMapping();

    this.profile.firstName = this.data.firstName;
    this.profile.email = this.data.email;

    // this.roleType =  this.data['authorities'];
    //console.log("inside handel role state: {}", this.data, this.selectedTenantId);
    this.roleType = this.data.tenantAuthorities.filter(
      (e) => e.tenantId == this.selectedTenantId
    )[0].authorities;

    //console.log(Object.values(AuthorityName));

    this.roleType = this.roleType.filter((e) =>
      Object.values(AuthorityName).includes(e)
    );

    //ADMIN_RECRUITER -- If Role is removed from Keycloack we shoud remove this line
    this.roleType = this.roleType.filter((e) => e !== 'ROLE_ADMIN_RECRUITER');
    this.roleType = this.roleType.filter((e) => e !== AuthorityName.ROLE_USER);

    if (this.roleType.includes(AuthorityName.ROLE_INTERVIEWER)) {
      this.roleType.push(
        this.roleType.splice(
          this.roleType.indexOf(AuthorityName.ROLE_INTERVIEWER),
          1
        )[0]
      );
    }

    this.roleType.forEach((srole) => {
      const uiRole = this.roleTypeToUIMapping.get(srole);
      if (!this.roleTypeUI.includes(uiRole)) {
        this.roleTypeUI.push(uiRole);
      }
    });

    // to maintain role-state in header on refresh
    let currentRole = window.localStorage.getItem('currentRole');
    //console.log("Current Role on header", currentRole, this.selectedTenantId);
    if (currentRole && this.roleType.includes(currentRole)) {
      this.roleSelected = currentRole;
      this.role = this.roleSelected;
      this.accountService.setCurrentRole(this.roleSelected);
    } else {
      this.roleSelected = this.roleType.length > 0 ? this.roleType[0] : null;
      this.role = this.roleSelected;
      this.accountService.setCurrentRole(this.roleSelected);
    }

    if (this.roleSelected === AuthorityName.ROLE_HIRING_MANAGER) {
      this.router.navigate(['scp/scholarships']);
    } else if (this.roleSelected === AuthorityName.ROLE_CANDIDATE) {
      this.router.navigate(['candidate/problem-statements']);
    }
    if (this.roleSelected === AuthorityName.ROLE_INTERVIEWER) {
      this.router.navigate(['/interviewer/interviewer-dashboard']);
      // this.router.navigate(['/candidate/view-all-job']);
    } else {
      // redirect to dashboard only if interviewer-dashboard is open and selected role is not INTERVIEWER
      if (this.router.url.includes('interviewer/interviewer-dashboard')) {
        this.router.navigate(['/dashboard']);
      }
    }
  };

  onTenantChange = (e) => {
    this.selectedTenantId = e.value;
    localStorage.setItem('tenantId', `${this.selectedTenantId}`);
    //this.accountService.tenant$.next(e.value);
    if (environment.production) {
      this.accountService.login();
    } else {
      this.keycloakService.login();
    }
  };

  login(): void {
    this.accountService.login();
  }

  logout(): void {
    localStorage.removeItem('tenantId');
    sessionStorage.removeItem('seekerId');
    sessionStorage.removeItem(
      this.candidateProfileService.candidateProfileStateKey
    );

    if (environment.production) {
      this.accountService.logout();
    } else {
      this.keycloakService.logout();
      //  this.router.navigate(['/']);
    }
  }
  initializeRoleTypeToUIMapping = () => {
    this.roleTypeToUIMapping.set(
      AuthorityName.ROLE_EVENT_ADMIN,
      AuthorityNameUI.ROLE_EVENT_ADMIN
    );
    this.roleTypeToUIMapping.set(
      AuthorityName.ROLE_INTERVIEWER,
      AuthorityNameUI.ROLE_INTERVIEWER
    );
    this.roleTypeToUIMapping.set(
      AuthorityName.ROLE_PLATFORM_EXECUTIVE,
      AuthorityNameUI.ROLE_PLATFORM_EXECUTIVE
    );
    this.roleTypeToUIMapping.set(
      AuthorityName.ROLE_RECRUITER,
      AuthorityNameUI.ROLE_RECRUITER
    );
    this.roleTypeToUIMapping.set(
      AuthorityName.ROLE_SCHEDULER,
      AuthorityNameUI.ROLE_SCHEDULER
    );
    this.roleTypeToUIMapping.set(
      AuthorityName.ROLE_SPOC,
      AuthorityNameUI.ROLE_SPOC
    );
    this.roleTypeToUIMapping.set(
      AuthorityName.ROLE_SUPER_ADMIN,
      AuthorityNameUI.ROLE_SUPER_ADMIN
    );
    this.roleTypeToUIMapping.set(
      AuthorityName.ROLE_SUPPORT_EXECUTIVE,
      AuthorityNameUI.ROLE_SUPPORT_EXECUTIVE
    );
    this.roleTypeToUIMapping.set(
      AuthorityName.ROLE_HIRING_MANAGER,
      AuthorityNameUI.ROLE_HIRING_MANAGER
    );
    this.roleTypeToUIMapping.set(
      AuthorityName.ROLE_CANDIDATE,
      AuthorityNameUI.ROLE_CANDIDATE
    );
    this.roleTypeToUIMapping.set(
      AuthorityName.ROLE_COLLEGE_ADMIN,
      AuthorityNameUI.ROLE_COLLEGE_ADMIN
    );
    this.roleTypeToUIMapping.set(
      AuthorityName.ROLE_VERIFIER,
      AuthorityNameUI.ROLE_VERIFIER
    );
    this.roleTypeToUIMapping.set(
      AuthorityName.ROLE_VERIFIER_ADMIN,
      AuthorityNameUI.ROLE_VERIFIER_ADMIN
    );
    // console.log(this.roleTypeToUIMapping);
  };

  getUIAuthorityName = (roledb: AuthorityName) => {
    if (this.roleTypeToUIMapping.has(roledb)) {
      return this.roleTypeToUIMapping.get(roledb);
    }
    return roledb;
  };

  changeRole = (e: any) => {
    this.roleSelected = e.value;
    // console.log(this.roleSelected);
    this.role = this.roleSelected;
    this.accountService.setCurrentRole(this.roleSelected);
    if (this.roleSelected === AuthorityName.ROLE_HIRING_MANAGER) {
      //console.log('hiring manager');
      this.router.navigate(['scp/scholarships']);
    } else if (this.roleSelected === AuthorityName.ROLE_CANDIDATE) {
      this.router.navigate(['candidate/problem-statements']);
    } else if (
      this.roleSelected === AuthorityName.ROLE_EVENT_ADMIN ||
      this.roleSelected === AuthorityName.ROLE_RECRUITER ||
      this.roleSelected === AuthorityName.ROLE_SUPER_ADMIN
    ) {
      //  console.log('dashboard');
      this.router.navigate(['/dashboard']);
    } else if (this.roleSelected === AuthorityName.ROLE_INTERVIEWER) {
      this.router.navigate(['/interviewer/interviewer-dashboard']);
    } else if (this.roleSelected === AuthorityName.ROLE_CANDIDATE) {
    } else {
      return true;
    }
  };
  // logout() {
  //   // window.localStorage.removeItem('currentRole');
  //   this.keycloakService.logout();
  // }

  searchOnEnter = (e, searchStr) => {
    if (e.keyCode === 13) {
      this.search(searchStr);
    }
  };

  search = (searchStr) => {
    searchStr = searchStr.trim();
    if (searchStr === '') {
      this.sharedService.openSnackBar('Search cannot be blank', '', 3000);
    } else {
      if (this.role === AuthorityName.ROLE_INTERVIEWER) {
        this.router.navigate([
          '/interviewer/infy-evaluation/search/' + searchStr,
        ]);
      } else {
        this.router.navigate(['/global-search/' + searchStr]);
      }
    }
  };

  toggleNavbar() {
    if (
      document.getElementById('mainleftNav').classList.contains('sidenav-hide')
    ) {
      document.getElementById('mainleftNav').classList.remove('sidenav-hide');
    } else {
      document.getElementById('mainleftNav').classList.add('sidenav-hide');
    }
  }

  navigateToSumbitScholarship(e) {
    this.router.navigate(['/candidate/submit-application/']);
  }
  logIn(e) {
    if (environment.production) {
      this.accountService.login();
    } else {
      this.keycloakService.login();
    }
  }

  createNotification() {}

  // then in another method :
}
