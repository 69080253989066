<fieldset [disabled]="disabled">

  <div *ngIf="view==='TWO_COLUMNS';else oneColumn">
    <div class="row">
      <div class="col-6" style="display: flex;flex-direction: column;justify-content:center;">
        <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
            {{questionIndex+1}}.
          </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
            *
          </span></p>
        <br>
        <br>
        <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">

      </div>
      <div class="col-6">
        <div>
          <div class="row" style="padding-left: 5px;">
            <ngb-rating [rate]="responsePage.sections[section].questions[questionIndex].response[0]" [max]="maxRating"
              (hover)="hovered=$event" (rateChange)="inputChanged($event)">
              <ng-template let-fill="fill" let-index="index">
                <span class="star" [class]="color" [class.filled]="fill === 100">
                  <mat-icon class="rate-icons">{{ icon }}</mat-icon>
                </span>
              </ng-template>
              <!-- <ng-template let-fill="fill">
                <span class="star"  style="color:red"
                  title="{{hovered}}">

                  <mat-icon style="font-size: 28px;padding-right: 14px;">{{ icon }} </mat-icon>
                </span>
              </ng-template> -->
            </ngb-rating>
          </div>
        </div>


      </div>
    </div>
  </div>

  <ng-template #oneColumn>
    <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
        {{questionIndex+1}}.
      </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
        *
      </span></p>
    <br>
    <br>
    <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">
    <div>
      <div class="row" style="padding-left: 5px;">
        <ngb-rating [rate]="responsePage.sections[section].questions[questionIndex].response[0]" [max]="maxRating"
          (hover)="hovered=$event" (rateChange)="inputChanged($event)">
          <ng-template let-fill="fill" let-index="index">
            <span class="star" [class]="color" [class.filled]="fill === 100">
              <mat-icon class="rate-icons">{{ icon }}</mat-icon>
            </span>
          </ng-template>
          <!-- <ng-template let-fill="fill">
          <span class="star"  style="color:red"
            title="{{hovered}}">

            <mat-icon style="font-size: 28px;padding-right: 14px;">{{ icon }} </mat-icon>
          </span>
        </ng-template> -->
        </ngb-rating>
      </div>
    </div>

  </ng-template>

</fieldset>
