<fieldset [disabled]="disabled">

  <div *ngIf="view==='TWO_COLUMNS';else oneColumn">
    <div class="row">
      <div class="col-6" style="display: flex;flex-direction: column;justify-content:center;">

        <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
            {{questionIndex+1}}.
          </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
            *
          </span></p>
        <span style="color: grey; font-style:italic">(This is a stream and is saved as it is being recorded.)</span>
        <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">
        <span *ngIf="!responsePage.sections[section].questions[questionIndex].isValid"
          style="color:red;font-size: 12px;">
          {{responsePage.sections[section].questions[questionIndex].validationMessage}}</span>
      </div>
      <div class="col-6">

        <div style="width:95%;height:auto">
          <!-- <button mat-raised-button (click)="addVideo()" [disabled]="addVideoDisabled">{{addVideoText}}</button> -->
          <div *ngIf="isStream; else UploadFile">
            <div *ngIf="addVideoSuccessfull" style="width:100%">
              <br>
              <button mat-raised-button (click)="start()" [disabled]="startDisabled">Start</button>
              <button mat-raised-button (click)="stop()" [disabled]="stopDisabled">Stop</button>
              <br>
              <br>
              <span *ngIf="recorder.state !== 'inactive'"><img src="/assets/images/icons/default_icons/icon-mic.png">
                Recording..</span>
              <video *ngIf="srcObject!==null && srcObject!== undefined" [srcObject]="srcObject" #cameraVideo autoplay
                style="width:95%;height:inherit" [muted]="true"></video>
              <div #cameraVideoPreview style="width:95%;height:inherit">
              </div>
              <br>

              <div *ngIf="showPreview && allowReupload">
                <button mat-raised-button (click)="addVideo()">Record Again</button>
                <button mat-raised-button (click)="submit()">Upload</button>
              </div>
            </div>
          </div>
          <ng-template #UploadFile>
            <input type="file" [accept]="media.accept" #file (change)="uploadVideo($event)">
            <br>
            <br>
            <div #cameraVideoPreview style="width:95%;height:inherit">
            </div>
            <br>
            <button
              *ngIf="uploadedFile!==null && uploadedFile!== undefined && responsePage.sections[section].questions[questionIndex].isValid"
              mat-raised-button (click)="submit()">
              Upload
            </button>
          </ng-template>
        </div>

        <ngx-spinner name="uploadSpinner" bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="timer"
          [fullScreen]="false">
          <p class="spinner-loader-text"> Uploading... </p>
        </ngx-spinner>
      </div>
    </div>
  </div>

  <ng-template #oneColumn>

    <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
        {{questionIndex+1}}.
      </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
        *
      </span></p>
    <span style="color: grey; font-style:italic">(This is a stream and is saved as it is being recorded.)</span>
    <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">
    <span *ngIf="!responsePage.sections[section].questions[questionIndex].isValid" style="color:red;font-size: 12px;">
      {{responsePage.sections[section].questions[questionIndex].validationMessage}}</span>
    <br>
    <div style="width:95%;height:auto">
      <!-- <button mat-raised-button (click)="addVideo()" [disabled]="addVideoDisabled">{{addVideoText}}</button> -->
      <div *ngIf="isStream; else UploadFile">
        <div *ngIf="addVideoSuccessfull" style="width:100%">
          <br>
          <button mat-raised-button (click)="start()" [disabled]="startDisabled">Start</button>
          <button mat-raised-button (click)="stop()" [disabled]="stopDisabled">Stop</button>
          <br>
          <br>
          <span *ngIf="recorder.state !== 'inactive'"><img src="/assets/images/icons/default_icons/icon-mic.png">
            Recording..</span>
          <video *ngIf="srcObject!==null && srcObject!== undefined" [srcObject]="srcObject" #cameraVideo autoplay
            style="width:95%;height:inherit" [muted]="true"></video>
          <div #cameraVideoPreview style="width:95%;height:inherit">
          </div>
          <br>

          <div *ngIf="showPreview && allowReupload">
            <button mat-raised-button (click)="addVideo()">Record Again</button>
            <button mat-raised-button (click)="submit()">Upload</button>
          </div>
        </div>
      </div>
      <ng-template #UploadFile>
        <input type="file" [accept]="media.accept" #file (change)="uploadVideo($event)">
        <br>
        <br>
        <div #cameraVideoPreview style="width:95%;height:inherit">
        </div>
        <br>
        <button
          *ngIf="uploadedFile!==null && uploadedFile!== undefined && responsePage.sections[section].questions[questionIndex].isValid"
          mat-raised-button (click)="submit()">
          Upload
        </button>
      </ng-template>
    </div>

    <ngx-spinner name="uploadSpinner" bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#fff" type="timer"
      [fullScreen]="false">
      <p class="spinner-loader-text"> Uploading... </p>
    </ngx-spinner>
  </ng-template>


</fieldset>
