<div class="d-flex align-items-center">
  <mat-form-field class="custom-form-field custom-select-withBg padR8">
    <input
      #templateInput
      [formControl]="searchControl"
      [matAutocomplete]="auto"
      [placeholder]="placeholder"
      matInput
    />
    <span matSuffix>
      <mat-spinner
        *ngIf="isFetchingTemplates"
        class="purple-spinner"
        [diameter]="20"
      ></mat-spinner>
      <!-- empty div to accommodate space for spinner when not loading -->
      <div class="marLR8" *ngIf="!isFetchingTemplates"></div>
    </span>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="handleTemplateSelect($event)"
      [displayWith]="displayFn"
    >
      <mat-option
        *ngFor="let template of availableTemplateObs | async"
        [value]="template"
      >
        {{ template.name }}
      </mat-option>
    </mat-autocomplete>
    <mat-hint>{{ hint }}</mat-hint>
  </mat-form-field>
</div>
