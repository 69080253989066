import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfyRecruiterFeedbackComponent } from './infy-recruiter-feedback.component';
import { PanelistParticipationStatusComponent } from './panelist-participation-status/panelist-participation-status.component';
import { SendBackToPanelistComponent } from './send-back-to-panelist/send-back-to-panelist.component';
import { VisualizationComponent } from './visualization/visualization.component';
import { GatewaySharedModule } from 'src/app/shared/shared.module';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { TakeSurveyModule } from 'src/app/survey-master/take-survey/take-survey.module';
import { IwpFormioModule } from '../iwp-formio/iwp-formio.module';

@NgModule({
  declarations: [
    InfyRecruiterFeedbackComponent,
    PanelistParticipationStatusComponent,
    SendBackToPanelistComponent,
    VisualizationComponent,
  ],
  imports: [
    CommonModule,
    GatewaySharedModule,
    NgxGraphModule,
    TakeSurveyModule,
    IwpFormioModule,
  ],
})
export class InfyRecruiterFeedbackModule {}
