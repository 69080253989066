<fieldset [disabled]="disabled">


  <div *ngIf="view==='TWO_COLUMNS';else oneColumn">
    <div class="row">
      <div class="col-6" style="display: flex;flex-direction: column;justify-content:center;">
        <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
            {{questionIndex+1}}.
          </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
            *
          </span></p>
        <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">

      </div>
      <div class="col-6">

        <mat-form-field style="width:95%" appearance="fill" *ngIf="getValidationValue(question,'USE_NAME')==='true'">
          <mat-label>{{nameLabel}}</mat-label>
          <input matInput [placeholder]="nameLabel"
            [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[0]"
            [required]="question.preference.isRequired===1" (change)="inputChanged('NAME')"
            (focusout)="inputChanged('NAME')" />

        </mat-form-field>

        <mat-form-field style="width:95%" appearance="fill" *ngIf="getValidationValue(question,'USE_COMPANY')==='true'">
          <mat-label>{{companyLabel}}</mat-label>
          <input matInput [placeholder]="companyLabel"
            [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[1]"
            [required]="question.preference.isRequired===1" (change)="inputChanged('COMPANY')"
            (focusout)="inputChanged('COMPANY')" />

        </mat-form-field>

        <mat-form-field style="width:95%" appearance="fill" *ngIf="getValidationValue(question,'USE_ADDRESS')==='true'">
          <mat-label>{{addressLabel}}</mat-label>
          <input matInput [placeholder]="addressLabel"
            [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[2]"
            [required]="question.preference.isRequired===1" (change)="inputChanged('ADDRESS')"
            (focusout)="inputChanged('ADDRESS')" />

        </mat-form-field>

        <mat-form-field style="width:95%" appearance="fill"
          *ngIf="getValidationValue(question,'USE_ADDRESS_2')==='true'">
          <mat-label>{{address2Label}}</mat-label>
          <input matInput [placeholder]="address2Label"
            [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[3]"
            [required]="question.preference.isRequired===1" (change)="inputChanged('ADDRESS_2')"
            (focusout)="inputChanged('ADDRESS_2')" />

        </mat-form-field>

        <mat-form-field style="width:95%" appearance="fill" *ngIf="getValidationValue(question,'USE_CITY')==='true'">
          <mat-label>{{cityLabel}}</mat-label>
          <input matInput [placeholder]="cityLabel"
            [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[4]"
            [required]="question.preference.isRequired===1" (change)="inputChanged('CITY')"
            (focusout)="inputChanged('CITY')" />

        </mat-form-field>

        <mat-form-field style="width:95%" appearance="fill" *ngIf="getValidationValue(question,'USE_STATE')==='true'">
          <mat-label>{{stateLabel}}</mat-label>
          <input matInput [placeholder]="stateLabel"
            [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[5]"
            [required]="question.preference.isRequired===1" (change)="inputChanged('STATE')"
            (focusout)="inputChanged('STATE')" />

        </mat-form-field>

        <mat-form-field style="width:95%" appearance="fill" *ngIf="getValidationValue(question,'USE_ZIP')==='true'">
          <mat-label>{{zipLabel}}</mat-label>
          <input matInput [placeholder]="zipLabel"
            [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[6]"
            [required]="question.preference.isRequired===1" (change)="inputChanged('ZIP')"
            (focusout)="inputChanged('ZIP')" />

        </mat-form-field>

        <mat-form-field style="width:95%" appearance="fill" *ngIf="getValidationValue(question,'USE_COUNTRY')==='true'">
          <mat-label>{{countryLabel}}</mat-label>
          <input matInput [placeholder]="countryLabel"
            [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[7]"
            [required]="question.preference.isRequired===1" (change)="inputChanged('COUNTRY')"
            (focusout)="inputChanged('COUNTRY')" />

        </mat-form-field>

        <mat-form-field style="width:95%" appearance="fill" *ngIf="getValidationValue(question,'USE_PHONE')==='true'">
          <mat-label>{{phoneLabel}}</mat-label>
          <input matInput [placeholder]="phoneLabel"
            [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[8]"
            [required]="question.preference.isRequired===1" (change)="inputChanged('PHONE')"
            (focusout)="inputChanged('PHONE')" />

        </mat-form-field>

        <mat-form-field style="width:95%" appearance="fill" *ngIf="getValidationValue(question,'USE_EMAIL')==='true'">
          <mat-label>{{emailLabel}}</mat-label>
          <input matInput [placeholder]="emailLabel"
            [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[9]"
            [required]="question.preference.isRequired===1" (change)="inputChanged('EMAIL')"
            (focusout)="inputChanged('EMAIL')" />

        </mat-form-field>
        <span *ngIf="!responsePage.sections[section].questions[questionIndex].isValid"
          style="color:red;font-size: 12px;">
          {{responsePage.sections[section].questions[questionIndex].validationMessage}}</span>
      </div>
    </div>
  </div>

  <ng-template #oneColumn>

    <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
        {{questionIndex+1}}.
      </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
        *
      </span></p>
    <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">
    <mat-form-field style="width:95%" appearance="fill" *ngIf="getValidationValue(question,'USE_NAME')==='true'">
      <mat-label>{{nameLabel}}</mat-label>
      <input matInput [placeholder]="nameLabel"
        [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[0]"
        [required]="question.preference.isRequired===1" (change)="inputChanged('NAME')"
        (focusout)="inputChanged('NAME')" />

    </mat-form-field>

    <mat-form-field style="width:95%" appearance="fill" *ngIf="getValidationValue(question,'USE_COMPANY')==='true'">
      <mat-label>{{companyLabel}}</mat-label>
      <input matInput [placeholder]="companyLabel"
        [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[1]"
        [required]="question.preference.isRequired===1" (change)="inputChanged('COMPANY')"
        (focusout)="inputChanged('COMPANY')" />

    </mat-form-field>

    <mat-form-field style="width:95%" appearance="fill" *ngIf="getValidationValue(question,'USE_ADDRESS')==='true'">
      <mat-label>{{addressLabel}}</mat-label>
      <input matInput [placeholder]="addressLabel"
        [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[2]"
        [required]="question.preference.isRequired===1" (change)="inputChanged('ADDRESS')"
        (focusout)="inputChanged('ADDRESS')" />

    </mat-form-field>

    <mat-form-field style="width:95%" appearance="fill" *ngIf="getValidationValue(question,'USE_ADDRESS_2')==='true'">
      <mat-label>{{address2Label}}</mat-label>
      <input matInput [placeholder]="address2Label"
        [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[3]"
        [required]="question.preference.isRequired===1" (change)="inputChanged('ADDRESS_2')"
        (focusout)="inputChanged('ADDRESS_2')" />

    </mat-form-field>

    <mat-form-field style="width:95%" appearance="fill" *ngIf="getValidationValue(question,'USE_CITY')==='true'">
      <mat-label>{{cityLabel}}</mat-label>
      <input matInput [placeholder]="cityLabel"
        [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[4]"
        [required]="question.preference.isRequired===1" (change)="inputChanged('CITY')"
        (focusout)="inputChanged('CITY')" />

    </mat-form-field>

    <mat-form-field style="width:95%" appearance="fill" *ngIf="getValidationValue(question,'USE_STATE')==='true'">
      <mat-label>{{stateLabel}}</mat-label>
      <input matInput [placeholder]="stateLabel"
        [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[5]"
        [required]="question.preference.isRequired===1" (change)="inputChanged('STATE')"
        (focusout)="inputChanged('STATE')" />

    </mat-form-field>

    <mat-form-field style="width:95%" appearance="fill" *ngIf="getValidationValue(question,'USE_ZIP')==='true'">
      <mat-label>{{zipLabel}}</mat-label>
      <input matInput [placeholder]="zipLabel"
        [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[6]"
        [required]="question.preference.isRequired===1" (change)="inputChanged('ZIP')"
        (focusout)="inputChanged('ZIP')" />

    </mat-form-field>

    <mat-form-field style="width:95%" appearance="fill" *ngIf="getValidationValue(question,'USE_COUNTRY')==='true'">
      <mat-label>{{countryLabel}}</mat-label>
      <input matInput [placeholder]="countryLabel"
        [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[7]"
        [required]="question.preference.isRequired===1" (change)="inputChanged('COUNTRY')"
        (focusout)="inputChanged('COUNTRY')" />

    </mat-form-field>

    <mat-form-field style="width:95%" appearance="fill" *ngIf="getValidationValue(question,'USE_PHONE')==='true'">
      <mat-label>{{phoneLabel}}</mat-label>
      <input matInput [placeholder]="phoneLabel"
        [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[8]"
        [required]="question.preference.isRequired===1" (change)="inputChanged('PHONE')"
        (focusout)="inputChanged('PHONE')" />

    </mat-form-field>

    <mat-form-field style="width:95%" appearance="fill" *ngIf="getValidationValue(question,'USE_EMAIL')==='true'">
      <mat-label>{{emailLabel}}</mat-label>
      <input matInput [placeholder]="emailLabel"
        [(ngModel)]="responsePage.sections[section].questions[questionIndex].response[9]"
        [required]="question.preference.isRequired===1" (change)="inputChanged('EMAIL')"
        (focusout)="inputChanged('EMAIL')" />

    </mat-form-field>
    <span *ngIf="!responsePage.sections[section].questions[questionIndex].isValid" style="color:red;font-size: 12px;">
      {{responsePage.sections[section].questions[questionIndex].validationMessage}}</span>
  </ng-template>



</fieldset>
