<!-- <p>Dynamic Dashboard</p> -->

<app-loader [display]="loading"></app-loader>
<!-- New Interviewer status starts here -->
<div class="container-fluid layout">

    
    <div style="MARGIN-RIGHT: 21PX;">
        <mat-radio-group (change)="onSelectionChange()" class="DSA_Wb_custom-radio float-right"
            aria-label="Select an option">
            <mat-radio-button value="1" disableRipple="true" checked>Panelist
            </mat-radio-button>
            <mat-radio-button value="0" disableRipple="true">Candidate</mat-radio-button>
        </mat-radio-group>
    </div>
    <div *ngIf="panelistFlag== 1">
        <!-- <h3 class="DSA_wb_h2Header web-GreyDarkest padB8">
            Panelist Status
        </h3> -->
        <div class="col-3 padL50">
            <h2 class="DSA_wb_h2Header web-GreyDarkest padB8 padT6">
                Panelist Status
            </h2>
          </div>
        <!-- panelist grid -->
        <data-grid [columnDefs]="columnDefs" [tableName]="tableName" [buttonText]="buttonText"
            [buttonComponent]="buttonComponent" [levels]="levels" [buttonCondition]="buttonCondition"
            [searchCondition]="searchCondition" [interface]="interface" [refreshAgGrid]="refreshAgGrid.asObservable()"
            (pageChangeValue)="onPageChange($event)"></data-grid>
    </div>
    <div *ngIf="panelistFlag == 0">
        <!-- <div >
        <app-filter-ui
        filterDisplayText="Filter Status"
        [filterState]="filterState"
        [filterByColumnsAll]="filterByColumnsAll"
        [filterCriteriaGetApi]="filterCriteriaGetApi"
        (refreshTableEvent)="refreshTableData($event)"
      ></app-filter-ui>
    </div> -->
        <div class="col-3 padL50">
            <h2 class="DSA_wb_h2Header web-GreyDarkest padB8 padT6">
                Candidates: Panelists Not Assigned
            </h2>
          </div>
        <!-- candidate grid -->
        <data-grid [columnDefs]="columnDefsCandidate" [tableName]="tableNameCandidate"
            [buttonText]="buttonTextCandidate" [buttonComponent]="buttonComponentCandidate" [levels]="levelsCandidate"
            [buttonCondition]="buttonConditionCandidate" [searchCondition]="searchConditionCandidate"
            [interface]="interfaceCandidate" [refreshAgGrid]="refreshAgGridCandidate.asObservable()"
            (pageChangeValue)="onPageChange($event)"></data-grid>
    </div>

</div>
