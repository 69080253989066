<h1 mat-dialog-title>Generate Report</h1>
<div mat-dialog-content>
  <form class="container-fluid" [formGroup]="generateReportForm">
    <div class="row">
      <div class="col-12">
        <p>{{ data.text }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Choose Start Date</mat-label>
          <input
            matInput
            formControlName="startDate"
            [min]="oneWeekBackDate"
            [matDatepicker]="pickerStartDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerStartDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerStartDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100">
          <mat-label>Choose End Date</mat-label>
          <input
            matInput
            formControlName="endDate"
            [min]="generateReportForm.controls.startDate.value"
            [matDatepicker]="pickerEndDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerEndDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerEndDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <button mat-button (click)="onNoClick()" class="DSA_wb_miniSecondary-btn">
    Cancel
  </button>
  <button
    mat-button
    [disabled]="generateButtonDisable()"
    [mat-dialog-close]="generateReportForm.value"
    class="DSA_wb_miniPrimary-btn"
  >
    Generate
  </button>
</div>
