<div class="container-fluid padT16">
  <app-loader [display]="isLoadingAll"></app-loader>
  <div class="row" *ngIf="!isLoadingAll && !errorPage">
    <div class="col-12">
      <mat-stepper linear #stepper labelPosition="bottom" (selectionChange)="stepChange($event)"
        class="candidate-profile-stepper">
        <mat-step label="Team Registration" class="team-registration"  state="tick" completed="false">
        </mat-step>
        <!-- <mat-step label="Upload" class="team-registration"  state="tick" completed="false">
        </mat-step> -->
        <ng-template matStepperIcon="tick">
          <mat-icon>check</mat-icon>
        </ng-template>
      </mat-stepper>
    </div>
    <app-loader class='col-12' [display]="isLoadingSteps"></app-loader>
    <div class="col-12" *ngIf="!isLoadingSteps">
      <router-outlet (activate)="routeChanged(stepper)"></router-outlet>
    </div>
  </div>
</div>
<div class="marT32" *ngIf="errorPage">
  <div class="marT50">
    <app-error-message [errorText]="'Some error occurred. Please try again.'"></app-error-message>
  </div>
</div>
