import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from 'src/app/services/shared.service';
import { ApplicationStatus } from 'src/app/shared/model/job-applicants.model';



@Component({
  selector: 'app-final-action-confirmation-dialog',
  templateUrl: './final-action-confirmation-dialog.component.html',
  styleUrls: ['./final-action-confirmation-dialog.component.scss'],
})
export class FinalActionConfirmationDialogComponent implements OnInit {
  remarks: string = '';

  constructor(
    public dialogRef: MatDialogRef<FinalActionConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      messageToShow: string;
      finalAction: ApplicationStatus;
    },
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {}

  handleYesClick = () => {
    if (this.data.finalAction === 'VERIFIER_REJECTED' && !this.remarks) {
      this.sharedService.openErrorSnackBar('Please enter rejection reason', '');
      return;
    }
    this.dialogRef.close({
      takeAction: true,
      remarks: this.remarks,
    });
  };

  handleNoClick = () => {
    this.dialogRef.close({
      takeAction: false,
      remarks: '',
    });
  };
}
