import { JobService } from '../../../services/job.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from '../../../shared/components/confirmation/confirmation.component';
import * as moment from 'moment';

import { CreateEventStateStorageService } from '../infy-create-event/create-event-state-storage.service';
import { ISelectedCandidate } from '../../../shared/model/candidate.model';
import { CandidateService } from 'src/app/services/candidate.service';
import { EventService } from 'src/app/services/event.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import { EmailService } from 'src/app/services/email.service';
import { AccountService } from 'src/app/services/account.service';
import { SharedService } from 'src/app/services/shared.service';
import { InterviewService } from 'src/app/services/interview.service';

import { Observable, of, Subject } from 'rxjs';
import { RescheduleLinkRendererComponent } from 'src/app/modules/iwp-event/infy-candidate-interview-rounds/candidate-interview-rounds-renderer/reschedule-link-renderer.component';
import { InterviewRoundsActionRendererComponent } from 'src/app/modules/iwp-event/infy-candidate-interview-rounds/candidate-interview-rounds-renderer/candidate-interview-round-action-renderer/interview-rounds-action-renderer.component';
import { MeetingLinkActionRendererComponent } from 'src/app/modules/iwp-event/infy-candidate-interview-rounds/candidate-interview-rounds-renderer/meeting-link-action-renderer/meeting-link-action-renderer.component';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';

@Component({
  selector: 'app-infy-candidate-interview-rounds',
  templateUrl: './infy-candidate-interview-rounds.component.html',
  styleUrls: ['./infy-candidate-interview-rounds.component.scss'],
})
export class InfyCandidateInterviewRoundsComponent implements OnInit {
  locked = false;
  eventId: any;
  candidate: ISelectedCandidate;
  refreshAgGrid: Subject<void> = new Subject<void>();
  tablePageNumber = 0;
  pageSize = 10;
  totalElements = 0;

  eventStatus: any;

  displayColumn = ['id', 'Title', 'Interview Status', 'Platform'];
  candidateDataList = [];

  tableColumns = [];
  tableColumnsDisplayName = [
    'id',
    'Title',
    'Job Role',
    'Round Type',
    'Interview Status',
    // 'Platform',
    'Start Date',
    'End Date',
    'Actions',
  ];

  columnDefs: any = [
    {
      headerName: 'id',
      field: 'id',
      type: 'requiredLink',
      hide: false,
      required: true,
      valueFormatter: this.linkFormatter,
      cellRendererFramework: RescheduleLinkRendererComponent,
    },
    {
      headerName: 'Title',
      field: 'title',
      hide: false,
      required: true,
    },
    {
      headerName: 'Job Role',
      field: 'jobOpening.title',
      hide: false,
      required: true,
      // cellRendererFramework: RecruiterCandidateLinkRendererComponent,
    },
    { headerName: 'Round Type', field: 'roundType', hide: false },
    { headerName: 'Platform', field: 'source.title', hide: false },
    {
      headerName: 'Meeting Link',
      field: 'meetingLink',
      hide: true,
      cellRendererFramework: MeetingLinkActionRendererComponent,
      minWidth: 200,
    },
    { headerName: 'Interview Status', field: 'interviewStatus', hide: false },
    {
      headerName: 'Start Date',
      field: 'interviewSchedule.startDate',
      hide: false,
      valueFormatter: this.getFormattedStartDate,
    },
    {
      headerName: 'End Date',
      field: 'interviewSchedule.endDate',
      hide: false,
      valueFormatter: this.getFormattedEndDate,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      hide: false,
      cellRendererFramework: InterviewRoundsActionRendererComponent,
      cellClass: 'cell-wrap-text',
      autoHeight: true,
      minWidth: 200,
    },
    //applicationId, candidateId, candidateSource, candidateSourceId, cirId, countryCode, email, frozen: false,
    //jobId, jobRoleId, jobType, name, phone, recruiterComments, result, resumeUrl, subUnit, technology, unit
  ];

  linkFormatter(params: any) {
    if (!params.data) return '';
    return '/schedule-interview';
  }
  filterTableColumnsDisplayName = [
    'id',
    'Title',
    'Interview Status',
    'Platform',
  ];
  filterData = [];
  filterPageNo = 1;
  tableFilters = [];
  tableDataFiltered = [];
  tableData = [];
  loader = false;
  customRowHeight = 100;
  count = 0;
  length = 0;
  failed = [];
  prevLink: string;
  candidateJobsInEvent = [];
  jobsInEvent = [];
  gridApi: any;
  gridColumnApi: any;
  jobId: number;
  levels: number = 1;
  FeatureName = FeatureName;
  PermissionType = PermissionType;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private createEventStateStorage: CreateEventStateStorageService,
    private candidateService: CandidateService,
    private eventService: EventService,
    private scheduleService: ScheduleService,
    private emailService: EmailService,
    public accountService: AccountService,
    private sharedService: SharedService,
    private interviewService: InterviewService,
    private jobService: JobService
  ) {}

  ngOnInit(): void {
    this.loader = false;
    this.eventStatus = localStorage.getItem('eventStatus');
    if (this.eventStatus === null) this.router.navigate(['/dashboard']);
    else if (this.eventStatus === 'CLOSED') {
      this.tableColumnsDisplayName.pop();
    }
    // this.eventId = this.eventService.selectedEventId;
    // this.candidate = this.candidateService.getSelectedCandidate();
    // if (!this.eventId || !this.candidate || this.eventStatus === null) {
    //   this.router.navigate(['/dashboard']);
    // }

    let appState = JSON.parse(window.sessionStorage.getItem('appState'));
    if (
      appState &&
      appState.authStatus === 'AUTHENTICATED' &&
      appState.redirectTo === this.router.url
    ) {
      this.candidateService.setSelectedCandidate(appState.candidate);
      this.eventService.selectedEventId = appState.eventId;
      this.createEventStateStorage.activeTabLink = appState.activeTabLink;
      this.eventId = this.eventService.selectedEventId;
      this.candidate = this.candidateService.getSelectedCandidate();
      this.regenerateMeetingLink(appState.interviewIdToRegenerateLink);
      window.sessionStorage.removeItem('appState');
    } else {
      this.eventId = this.eventService.selectedEventId;
      this.candidate = this.candidateService.getSelectedCandidate();
      if (!this.eventId || !this.candidate) {
        this.router.navigate(['/dashboard']);
      }
      // else this.refreshTable();
    }

    this.jobId = this.candidate.jobId;

    // this.refreshTable();
    if (this.createEventStateStorage.activeTabLink) {
      this.prevLink = `/create-event/${this.eventId}/${this.createEventStateStorage.activeTabLink}`;
    } else {
      this.prevLink = `/create-event/${this.eventId}/jobs`;
    }

    this.jobService.getAllJobsByEvent(this.eventId, 0, 100).subscribe((res) => {
      this.jobsInEvent = res.content?.map((job) => {
        return { id: job.id, title: job.title };
      });
    });

    this.candidateService
      .getCandidateJobsInEvent(this.eventId, this.candidate.candidateId)
      .subscribe((res) => {
        this.candidateJobsInEvent = res?.map((job) => {
          return {
            jobId: job.jobOpeningId,
            cirId: job.id,
            frozen: job.frozen,
            applicationId: job.candidateApplicationDetails?.applicationId,
            unit: job.candidateApplicationDetails?.unit,
            subUnit: job.candidateApplicationDetails?.subUnit,
            technology: job.candidateApplicationDetails?.technology,
          };
        });
      });

    if (
      !this.accountService.hasFeatureAccess(
        FeatureName.MEETING_LINK,
        PermissionType.READ
      )
    ) {
      this.columnDefs = this.columnDefs.filter(
        (e) => e.field !== 'meetingLink'
      );
    }
  }

  getCandidateInterviewsList = (jobId) => {
    let candidateJob = this.candidateJobsInEvent.find(
      (element) => element.jobId === jobId
    );
    if (candidateJob) {
      this.candidate = {
        ...this.candidate,
        ...candidateJob,
      };
      this.candidateService.setSelectedCandidate(this.candidate);
      this.refreshAgGrid.next();
    } else {
      if (this.eventStatus === 'CLOSED') {
        this.jobId = this.candidate.jobId;

        this.sharedService.openSnackBar(
          'Candidate currently not added to this job',
          '',
          3000
        );
        this.refreshAgGrid.next();
      } else {
        this.loader = true;
        this.candidateService
          .createCirIdForCandidateInJob({
            candidateId: this.candidate.candidateId,
            jobOpeningId: jobId,
          })
          .subscribe((res) => {
            this.loader = false;
            let newCirDetails = {
              jobId: res.jobOpeningId,
              cirId: res.id,
              frozen: res.frozen,
              applicationId: res.candidateApplicationDetails.applicationId,
              unit: res.candidateApplicationDetails.unit,
              subUnit: res.candidateApplicationDetails.subUnit,
              technology: res.candidateApplicationDetails.technology,
            };
            this.candidateJobsInEvent.push(newCirDetails);
            this.candidate = {
              ...this.candidate,
              ...newCirDetails,
            };
            this.candidateService.setSelectedCandidate(this.candidate);
            this.refreshAgGrid.next();
          });
      }
    }
  };

  interface = (size: number, page: number): Observable<any[]> => {
    // return this.eventService.getAllEvents(size, page, this.queryEventName);
    return this.candidateService.getRounds(page, size);
  };

  getFormattedStartDate(params: any) {
    if (!params.data) return '';
    if(params.data.interviewSchedule.startDate) {
      const _date = new Date(params.data.interviewSchedule.startDate);
      return moment(_date).format('DD/MM/YYYY HH:mm');
    }
    return '';
  }

  getFormattedEndDate(params: any) {
    if (!params.data) return '';
    if(params.data.interviewSchedule.endDate) {
      const _date = new Date(params.data.interviewSchedule.endDate);
      return moment(_date).format('DD/MM/YYYY HH:mm');
    }
    return '';
  }

  // dateFormatter(params: any) {
  //   if (!params.data) return '';
  //   const _date = new Date(params.data.interviewSchedule.startDate);
  //   const day = _date.getDate();

  //   const month = _date.getMonth() + 1;
  //   const year = _date.getFullYear();

  //   const hour = _date.getHours();
  //   const mins = _date.getMinutes();

  //   let date =
  //     ('0' + day).slice(-2) + '/' + ('0' + month).slice(-2) + '/' + year;
  //   let time = ('0' + hour).slice(-2) + ':' + ('0' + mins).slice(-2);
  //   let formattedDate = `${date} ${time}`;
  //   return formattedDate;
  // }

  noShowInterview = (id) => {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        text:
          "Are you sure you want to mark this Interview as 'No Show'?<br/> On <strong class='font-red'>Yes</strong>, the profile will be autosubmitted.",
      },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Yes') {
        this.candidateService.getNoShowCandidateDetails(id).subscribe(() => {
          this.sharedService.openSnackBar(
            'Interview Marked as No Show',
            '',
            3000
          );
          this.refreshTable();
        });
      }
    });
  };

  cancelInterview = (id) => {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        text:
          "Are you sure you want to cancel this interview?<br/> On <strong class='font-red'>Yes</strong>, the interview will be cancelled",
      },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Yes') {
        this.interviewService.getCancelledInterviewDetails(id).subscribe(() => {
          this.sharedService.openSnackBar('Interview Cancelled', '', 3000);
          this.refreshTable();
        });
      }
    });
  };

  scheduleNewInterview() {
    this.scheduleService.nullSelectedInterviewSchedule();
    this.router.navigate(['/schedule-interview']);
  }

  reSchedule(data) {
    this.scheduleService.setSelectedInterviewSchedule(data);
    this.router.navigate(['/schedule-interview']);
  }

  editInterview(data) {
    this.scheduleService.setSelectedInterviewSchedule(data);
    this.interviewService.setEditInterviewMode(true);
    this.router.navigate(['/schedule-interview']);
  }

  pageChange = (e) => {
    this.pageSize = e.pageSize;
    this.tablePageNumber = e.pageIndex;
    this.refreshTable();
  };

  resendEmail = (interviewId, name) => {
    let text = 'Are you sure you want resend email to Interviewer?';
    if (name === 'candidate') {
      text = 'Are you sure you want resend email to Candidiate?';
    }
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: { text },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Yes') {
        this.emailService.resendEmail(interviewId, name).subscribe(
          () => {
            this.sharedService.openSnackBar('Sent Successfully', '', 3000);
          },
          () => {
            this.sharedService.openSnackBar('Request Failed', '', 3000);
          }
        );
      }
    });
  };

  refreshTable() {
    this.loader = true;
    this.count = 0;
    this.length = 0;
    this.failed = [];
    this.tableData = [];
    this.tableFilters = [];
    this.filterData = [];

    this.candidateService
      .getRounds(this.tablePageNumber, this.pageSize)
      .subscribe(
        (res) => {
          this.totalElements = res.totalElements;
          this.candidateDataList = res.content;
          if (res.content.length > 0) {
            this.locked = res.content[0].frozen ? true : false;
          } else {
            this.locked = this.candidate.frozen ? true : false;
          }
          res.content.forEach((data) => {
            this.tableData.push({
              id: data.id,
              title: data.title,
              jobRole: data.jobOpening.title,
              roundType: data?.roundType,
              interviewStatus: data.interviewStatus,
              platform: data?.source?.title,
              data: data,
            });
          });
          if (this.tableData.length > 0) {
            this.tableDataFiltered = this.tableData;
            this.tableColumns = ['id', 'Title', 'Interview Status', 'Platform'];
            let data = {};
            this.tableColumns.forEach((column) => {
              data[column] = new Set();
              this.tableFilters.push({ key: column, data: [] });
            });
            if (this.tableData.length > 0) {
              this.tableDataFiltered = this.tableData;
              this.tableColumns = [
                'id',
                'Title',
                'Interview Status',
                'Platform',
              ];
              let data = {};
              this.tableColumns.forEach((column) => {
                data[column] = new Set();
                this.tableFilters.push({ key: column, data: [] });
              });
              this.tableData.forEach((ele) => {
                this.tableColumns.forEach((column) => {
                  data[column].add(ele[column]);
                });
              });

              this.tableColumns.forEach((ele, index) => {
                this.filterData.push({
                  key: ele,
                  displayName: this.filterTableColumnsDisplayName[index],
                  data: Array.from(data[ele]),
                  filterData: Array.from(data[ele]),
                  pageNo: 1,
                });
              });
            }
          } else {
            this.tableDataFiltered = [];
          }
        },
        () => {},
        () => {
          this.loader = false;
        }
      );
  }

  applyFilter(event: Event, filterIndex) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterData[filterIndex].filterData = this.filterData[
      filterIndex
    ].data.filter(
      (data) => data.toString().toLowerCase().search(filterValue) !== -1
    );
  }

  manageFilters = (e, key, data) => {
    if (e.checked) {
      this.tableFilters.forEach((filter) => {
        if (filter.key === key) {
          filter.data.push(data);
        }
      });
    } else {
      this.tableFilters.forEach((filter) => {
        filter.data = filter.data.filter((ele) => ele !== data);
      });
    }
  };

  applyFiltersToTable = () => {
    this.tableDataFiltered = this.tableData.filter((tableData) => {
      let validData = true;
      this.tableFilters.forEach((filter) => {
        if (
          filter.data.length > 0 &&
          !filter.data.includes(tableData[filter.key])
        ) {
          validData = false;
        }
      });
      return validData;
    });
  };

  regenerateMeetingLink(interviewId) {
    this.loader = true;
    this.interviewService.regenerateInterviewLink(interviewId).subscribe(
      (res) => {
        this.refreshAgGrid.next();
        this.sharedService.openSnackBar(
          'Link Regenerated Successfully',
          '',
          3000
        );
        this.loader = false;
      },
      (err) => {
        this.sharedService.openSnackBar('Some error occured', '', 3000);
        this.loader = false;
      }
    );
  }
}
