import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SharedService } from 'src/app/services/shared.service';
import { HiringManagerService } from '../../services/hiring-manager.service';
import { AuthorityNameUI } from 'src/app/shared/model/enumerations/authority-name-ui.model';
import { AuthorityName } from 'src/app/shared/model/enumerations/authority-name.model';

@Component({
  selector: 'app-userslist-add-user',
  templateUrl: './userslist-add-user.component.html',
  styleUrls: ['./userslist-add-user.component.scss'],
})
export class UserslistAddUserComponent implements OnInit {
  userForm: FormGroup;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  hide: boolean = true;
  organizationId: number;

  allowedRolesToAdd = [
    AuthorityName.ROLE_SUPER_ADMIN,
    AuthorityName.ROLE_PLATFORM_EXECUTIVE,
    AuthorityName.ROLE_SUPPORT_EXECUTIVE,
    AuthorityName.ROLE_HIRING_MANAGER,
    AuthorityName.ROLE_MENTOR,
    AuthorityName.ROLE_VERIFIER,
    AuthorityName.ROLE_CANDIDATE,
  ];
  userRoleOptions: Array<{ name: string; title: string }> = [];

  organizationOptions = [{}];

  organizationInputEnableForRoles = [
    AuthorityName.ROLE_VERIFIER,
    AuthorityName.ROLE_VERIFIER_ADMIN,
    AuthorityName.ROLE_SUPPORT_EXECUTIVE,
    AuthorityName.ROLE_PLATFORM_EXECUTIVE,
  ];

  isLoading: boolean = false;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<UserslistAddUserComponent>,
    private hiringManagerService: HiringManagerService,
    private sharedService: SharedService
  ) {
    this.userForm = fb.group({
      id: [null],
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      userRole: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      // organization: [''],
    });
  }

  ngOnInit(): void {
    this.userRoleOptions = this.allowedRolesToAdd.map((_) => {
      return { name: AuthorityNameUI[_], title: _ };
    });
    // this.userForm.controls.userRole.valueChanges.subscribe((value) => {
    //   if (this.organizationInputEnableForRoles.includes(value)) {
    //     this.userForm.controls.organization.enable();
    //     this.userForm.controls.organization.setValidators([
    //       Validators.required,
    //     ]);
    //     this.userForm.controls.organization.updateValueAndValidity();
    //   } else {
    //     this.userForm.controls.organization.setValue('');
    //     this.userForm.controls.organization.clearValidators();
    //     this.userForm.controls.organization.disable();
    //   }
    // });
    // this.getOrganizationOptions();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  handleAddUserClick = () => {
    this.isLoading = true;
    const value = this.userForm.getRawValue();

    let userDetails = {};
    let userDetailsArr = [];
    let additionalAttributes = {};

    additionalAttributes['firstname'] = value.firstname;
    additionalAttributes['lastname'] = value.lastname;
    additionalAttributes['phone'] = value.phone;

    // if (this.organizationInputEnableForRoles.includes(value.userRole)) {
    //   additionalAttributes['organizationId'] = value.organization;
    // }

    userDetails['email'] = value.email;
    userDetails['specType'] = value.userRole;
    userDetails['additionalAttributes'] = additionalAttributes;
    userDetailsArr.push(userDetails);

    const apiToCall = this.hiringManagerService.addUserWithRole(userDetailsArr);
    const successMessage = 'Successfully added';

    apiToCall.subscribe({
      next: (res) => {
        this.sharedService.openSuccessSnackBar(successMessage, '', 3000);
        this.isLoading = false;
        this.dialogRef.close(res);
      },
      error: (err) => {
        this.sharedService.openErrorSnackBar(
          'Something went wrong. Please try again.',
          '',
          3000
        );
        this.isLoading = false;
      },
    });
  };

  getOrganizationOptions() {
    this.hiringManagerService.getOrganizations().subscribe({
      next: (res) => {
        this.organizationOptions = res;
      },
      error: (err) => {
        this.sharedService.openErrorSnackBar(
          'Something went wrong. Please try again.',
          '',
          3000
        );
        this.isLoading = false;
      },
    });
  }
}
