<app-loader
  [display]="spinner"
  [textToShow]="'Uploading.... Please Wait'"
></app-loader>
<div class="ctr_main padTB32" id="mainCtr" *ngIf="!spinner && !apiFailed">
  <div class="container-fluid">
    <div class="col-12 marTB32">
      <div class="marB32 d-flex align-items-center">
        <a class="mr-2" role="button" (click)="_location.back()">
          <i class="icon x-24 back-icon m-0"> </i>
        </a>
        <h1 class="DSA_wb_h1Header web-GreyDarkest">Apply</h1>
      </div>

      <mat-accordion class="DSA_wb-cus-accordion">
        <mat-expansion-panel [expanded]="expandProfileAccordion">
          <mat-expansion-panel-header
            [collapsedHeight]="'80px'"
            [expandedHeight]="'80px'"
          >
            <i class="icon x-20 edit-icon marR16"> </i>
            <mat-panel-title>
              Verify & Update Profile Details before applying
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-candidate-profile [profiler]="false"></app-candidate-profile>
        </mat-expansion-panel>
      </mat-accordion>

      <div class="container-fluid" *ngIf="!expandDocsAccordion">
        <div class="row justify-content-end mar8">
          <button
            mat-flat-button
            class="DSA_wb_secondary-btn"
            (click)="submitApplication()"
          >
            Apply & Proceed with Document upload
          </button>
        </div>
      </div>

      <mat-accordion class="DSA_wb-cus-accordion" *ngIf="expandDocsAccordion">
        <mat-expansion-panel [expanded]="expandDocsAccordion">
          <mat-expansion-panel-header
            [collapsedHeight]="'80px'"
            [expandedHeight]="'80px'"
          >
            <mat-panel-title>
              Upload Resume and supporting documents
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="container-fluid">
            <div class="DSA_rightMainContent nomarL_mb" id="mainrightCnt">
              <div class="DSA_innerContentWrapper">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="DSA_contentLeftCol DSA_baseLayout_center">
                      <h3 class="DSA_wb_h2Header marB16 marL8">
                        Additional Details (Optional)
                      </h3>
                      <div class="row d-flex">
                        <div class="col-md-6 col-lg-5 col-xl-4 marT8">
                          <mat-card class="DSA_wb_card-widget">
                            <app-attach-files
                              [headingText]="'Upload Updated Resume'"
                              [files]="uploadFiles"
                              [acceptMultipleFiles]="false"
                              [headerClass]="'DSA_wb_h2Header'"
                              (fileChange)="handleFileChange($event)"
                              [additionalDoc]="true"
                            >
                            </app-attach-files>
                          </mat-card>
                        </div>

                        <div class="col-md-6 col-lg-5 col-xl-4 marT8">
                          <mat-card class="DSA_wb_card-widget">
                            <app-attach-files
                              [headingText]="'Upload LOR'"
                              [files]="uploadFilesLOR"
                              [acceptMultipleFiles]="false"
                              [headerClass]="'DSA_wb_h2Header'"
                              (fileChange)="handleFileChangeLOR($event)"
                              [additionalDoc]="true"
                            >
                            </app-attach-files>
                          </mat-card>
                        </div>

                        <div class="col-md-6 col-lg-5 col-xl-4 marT8">
                          <mat-card class="DSA_wb_card-widget">
                            <app-attach-files
                              [headingText]="'Upload SOP'"
                              [files]="uploadFilesSOP"
                              [acceptMultipleFiles]="false"
                              [headerClass]="'DSA_wb_h2Header'"
                              (fileChange)="handleFileChangeSOP($event)"
                              [additionalDoc]="true"
                            >
                            </app-attach-files>
                          </mat-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <div class="container-fluid">
          <div class="row justify-content-end mar8">
            <button
              mat-flat-button
              class="DSA_wb_primary-btn"
              (click)="_location.back()"
            >
              Close
            </button>

            <button
              mat-flat-button
              class="DSA_wb_primary-btn marL8"
              (click)="uploadUserFiles()"
            >
              Upload Additional Documents
            </button>
          </div>
        </div>
      </mat-accordion>
    </div>
  </div>
</div>

<div class="row" *ngIf="!spinner && apiFailed" style="padding-bottom: 20px">
  <div class="col-12 text-center">
    <span class="error-heading"
      >Uh Oh! The Link is already used maximum number of times.</span
    >
  </div>
</div>
