<div style="font-size: 12px header-area" *ngIf="loadRoutes && isLoggedIn">
  <app-header class="header"></app-header>
</div>
<div
  class="position-relative"
  *ngIf="loadRoutes"
  [ngClass]="{ 'route-container': isLoggedIn }"
>
  <router-outlet></router-outlet>
</div>
<footer
  class="DSA_footer ctr_footer"
  id="mainfooter"
  *ngIf="loadRoutes && isLoggedIn"
>
  <div class="container-fluid small-screen-font">Copyright 2023 WAFOUNDATION. All Right Reserved. </div>
</footer>
