import { InterviewListListService } from './interview-list-list.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InterviewListStateStorageService } from './interview-list-state-storage.service';
import { EventService } from 'src/app/services/event.service';
import { AccountService } from 'src/app/services/account.service';
import { linkRendererComponent } from 'src/app/shared/components/ag-grid-renderer/linkRenderer.component';
import { Observable, Subject } from 'rxjs';
import { AddInterviewsComponent } from 'src/app/modules/iwp-event/infy-interview-list/add-interviews/add-interviews.component';
import { PanelistRendererComponent } from 'src/app/shared/components/ag-grid-renderer/panelist-renderer/panelist-renderer.component';
import { InterviewListActionRendererComponent } from 'src/app/modules/iwp-event/infy-interview-list/interview-list-renderer/interview-list-action-renderer.component';
import { filterInterviewsByColumns } from 'src/app/app.constants';
import { InterviewDateSort } from '../../../shared/components/sort-rows/sort-rows.component';
import * as _ from 'lodash';
import * as moment from 'moment';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
import { IEvent } from 'src/app/shared/model/event.model';

@Component({
  selector: 'app-infy-interview-list',
  templateUrl: './infy-interview-list.component.html',
  styleUrls: ['./infy-interview-list.component.scss'],
})
export class InfyInterviewListComponent implements OnInit, OnDestroy {
  sortBy: string = InterviewDateSort.ASC;

  page = 0;
  size = 25;
  frozen = '';
  eventStatus: any;
  totalElements = 0;

  panelists = [];
  columnDefs = [];
  filterState = null;
  filterByColumnsAll = filterInterviewsByColumns;
  filterByColumns: Array<{ key: string; value: string }>;
  refreshAgGrid: Subject<void> = new Subject<void>();

  searchQuery: string;
  FeatureName = FeatureName;
  PermissionType = PermissionType;

  eventDetails: IEvent;

  constructor(
    private router: Router,
    private interviewListStateStorage: InterviewListStateStorageService,
    private InterviewListListService: InterviewListListService,
    private eventService: EventService,
    public accountService: AccountService // private interviewService: InterviewService // private sharedService: SharedService, // private activatedRoute: ActivatedRoute, // private candidateService: CandidateService,
  ) {}
//this components can be deleted
  ngOnInit(): void {
    this.eventStatus = localStorage.getItem('eventStatus');
    if (this.eventStatus === null) {
      this.router.navigate(['/dashboard']);
    }
    this.InterviewListListService.init();

    this.eventDetails = this.eventService.getSelectedEvent();

    const { page, size, filterState, searchQuery } = this.interviewListStateStorage;
    if (page && size) {
      this.page = page;
      this.size = size;
    }
    if (filterState) {
      this.filterState = filterState;
    }
    if (searchQuery) {
      this.searchQuery = searchQuery;
    }
    this.setColumnDefs();
  }

  ngOnDestroy() {
    this.interviewListStateStorage.filterState = this.filterState;
  }

  sortInterviews = (sortOrder) => {
    this.sortBy = sortOrder;
    this.refreshAgGrid.next();
  };

  candidateIdGetter = (params: any) => {
    if (!params.data) return 'Loading...';
    return params.data.candidate.candidateSourceId
      ? params.data.candidate.candidateSourceId
      : params.data.candidate.candidateId;
  };

  setColumnDefs() {
    this.columnDefs = [
      {
        headerName:
          this.eventStatus !== 'CLOSED'
            ? 'Schedule'
            : 'View Scheduled Interviews',
        field: 'schedule',
        hide: false,
        required: true,
        cellRendererFramework: InterviewListActionRendererComponent,
        cellRendererParams: {
          icon: 'schedule',
          condition: true,
          metaData: { eventStatus: this.eventStatus },
        },
      },
      {
        headerName: 'Candidate Id',
        field: 'candidate.candidateSourceId',
        valueGetter: this.candidateIdGetter,
        hide: false,
      },
      {
        headerName: 'Name',
        field: 'candidate.name',
        // hide: false,
        type: 'link',
        valueFormatter: this.linkFormatter,
        cellRendererFramework: linkRendererComponent,
        cellRendererParams: { message: 'View Recruiter Feedback' },
        minWidth: 150,
        required: true,
      },
      {
        headerName: 'Email',
        field: 'candidate.email',
        hide: false,
        minWidth: 150,
      },

      {
        headerName: 'Phone',
        field: 'candidate.phone',
        hide: false,
        minWidth: 150,
      },
      {
        headerName: 'Job Role',
        field: 'jobOpening.title',
        hide: false,
        minWidth: 150,
      },
      {
        headerName: 'Application Id',
        field: 'candidateApplicationDetails.applicationId',
        hide: false,
      },
      {
        headerName: 'Technology',
        field: 'candidateApplicationDetails.technology',
        hide: true,
      },
      {
        headerName: 'Unit',
        field: 'candidateApplicationDetails.unit',
        hide: true,
      },
      {
        headerName: 'Sub Unit',
        field: 'data.candidateApplicationDetails.subUnit',
        hide: true,
      },
      {
        headerName: 'Interview Status',
        field: 'interviewStatus',
        hide: false,
        minWidth: 150,
      },
      {
        headerName: 'Interview Result',
        field: 'interviewResult',
        hide: false,
        minWidth: 150,
      },
      {
        headerName: 'Final Result',
        field: 'candidate.finalCandidateResult',
        hide: false,
        minWidth: 150,
      },
      {
        headerName: 'Remarks',
        field: 'candidate.recruiterComments',
        hide: false,
        minWidth: 200,
      },
      {
        headerName: 'Start Time',
        field: 'interviewSchedule.startDate',
        hide: false,
        valueFormatter: this.getFormattedStartDate,
        minWidth: 150,
      },
      {
        headerName: 'Panelists',
        hide: false,
        field: 'panelists',
        cellRendererFramework: PanelistRendererComponent,
        cellRendererParams: {
          eventDetails: this.eventDetails,
        },
        minWidth: 200,
      },
      { headerName: 'SPOC', field: 'spoc', hide: false, minWidth: 150 },
      {
        headerName: 'Cancel',
        hide: false,
        required: true,
        cellRendererFramework: InterviewListActionRendererComponent,
        cellRendererParams: {
          icon: 'delete',
          condition:
            this.accountService.hasFeatureAccess(
              FeatureName.INTERVIEW_CANCELLATION,
              PermissionType.UPDATE
            ) ||
            this.accountService.hasFeatureAccess(
              FeatureName.INTERVIEW_CANCELLATION,
              PermissionType.CREATE
            ),
        },
        maxWidth: 60,
      },
    ];
  }

  interface = (
    size: number,
    page: number,
    query?: string
  ): Observable<any[]> => {
    return this.eventService.getInterviewsForEvent(
      page,
      size,
      query,
      this.filterState,
      this.sortBy
    );
  };
  tableName: string = 'Interviews';
  buttonText: string = 'Add Interviews';
  buttonComponent: any = AddInterviewsComponent;
  levels: number = 1;
  buttonCondition: boolean = true;
  searchCondition: boolean = this.accountService.hasFeatureAccess(
    FeatureName.SEARCH_INTERVIEWS,
    PermissionType.READ
  );

  linkFormatter(params: any) {
    if (!params.data) return '';
    return (
      '/infy-recruiter-feedback/' +
      params.data.candidate.candidateInterviewRoundId.toString() +
      '/' +
      params.data.candidate.candidateId.toString() +
      '/' +
      params.data.jobOpening.jobRoleId
    );
  }

  onPageChange = (e) => {
    this.interviewListStateStorage.page = e.target.page;
    this.interviewListStateStorage.size = e.target.size;
    this.interviewListStateStorage.searchQuery = e.target.searchQuery;
  };

  getFormattedStartDate(params: any) {
    if (!params.data) return '';
    const _date = new Date(params.data.interviewSchedule.startDate);
    return moment(_date).format('DD/MM/YYYY HH:mm');
  }

  filterCriteriaGetApi = () => {
    return this.eventService.getInterviewsFilterCriteriaForEvent();
  };

  refreshTableData = (filterState) => {
    this.page = 0;
    this.filterState = filterState;
    this.refreshAgGrid.next();
  };
}
