import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { JobService } from 'src/app/services/job.service';
import { CandidateService } from 'src/app/services/candidate.service';
import { Observable, Subject } from 'rxjs';
import { AddCandidateComponent } from '../add-candidate/add-candidate.component';
import { AccountService } from 'src/app/services/account.service';

import { RecruiterCandidateLinkRendererComponent } from 'src/app/modules/iwp-event/infy-candidate-interview-rounds/candidate-interview-rounds-renderer/recruiter-candidate-list/recruiter-candidate-link-renderer.component';
import { actionRendererComponent } from 'src/app/shared/components/ag-grid-renderer/actionRenderer.component';
import { FormGroup } from '@angular/forms';
import { linkRendererComponent } from 'src/app/shared/components/ag-grid-renderer/linkRenderer.component';
import { SharedService } from 'src/app/services/shared.service';
import { CandidateIdActionRendererComponent } from 'src/app/modules/iwp-event/infy-schedule-list/candidate-id-action-renderer/candidate-id-action-renderer.component';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';

@Component({
  selector: 'app-infy-schedule-list',
  templateUrl: './infy-schedule-list.component.html',
  styleUrls: ['./infy-schedule-list.component.scss'],
})
export class InfyScheduleListComponent implements OnInit {
  selectedJob: any;
  users: any;
  candidateDataList: any;
  addCandidateForm: any;
  tablePageNumber = 1;
  refreshAgGrid: Subject<void> = new Subject<void>();
  eventId: number;
  displayColumn = ['Can ID', 'Name', 'Email', 'Phone No.', 'Job Role'];
  jobsInEvent: any[] = [];
  eventStatus: any;
  tableColumns = [];
  tableColumnsDisplayName = [
    'Can ID',
    'Name',
    'Email',
    'Phone No.',
    'Job Role',
  ];

  columnDefs: any = [
    {
      headerName: 'Schedule',
      field: 'schedule',
      hide: false,
      required: true,
      cellRendererFramework: actionRendererComponent,
      cellRendererParams: { icon: 'schedule', condition: true },
      maxWidth: 80,
    },
    {
      headerName: 'Candidate ID',
      field: 'candidateId',
      hide: false,
      required: true,
      valueFormatter: this.idFormatter,
    },
    {
      headerName: 'Name',
      field: 'name',

      hide: false,
      required: true,
      valueFormatter: this.linkFormatter,
      cellRendererFramework: linkRendererComponent,
      cellRendererParams: { message: 'View Recruiter Feedback' },
    },
    { headerName: 'Email', field: 'email', hide: false },
    { headerName: 'Phone', field: 'phone', hide: false },
    {
      headerName: 'Freeze Status',
      field: 'frozen',
      hide: false,
      valueFormatter: this.freezeFormatter,
    },
    { headerName: 'Status', field: 'result', hide: false },
    {
      headerName: 'Application Id',
      field: 'applicationId',
      hide: false,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      hide: false,
      cellRendererFramework: CandidateIdActionRendererComponent,
    },
    //applicationId, candidateId, candidateSource, candidateSourceId, cirId, countryCode, email, frozen: false,
    //jobId, jobRoleId, jobType, name, phone, recruiterComments, result, resumeUrl, subUnit, technology, unit
  ];

  tableName: string = 'Candidates';
  buttonText: string = 'Add Candidate';
  buttonComponent: any = AddCandidateComponent;
  levels: number = 1;
  buttonCondition: boolean =
    this.accountService
      .getAccountLocal()
      .authorities.includes('ROLE_EVENT_ADMIN') ||
    this.accountService
      .getAccountLocal()
      .authorities.includes('ROLE_ADMIN_RECRUITER') ||
    this.accountService
      .getAccountLocal()
      .authorities.includes('ROLE_SCHEDULER');
  searchCondition: boolean = true;

  tableData = [];
  loader = false;
  count = 0;
  length = 0;
  failed = [];

  page = 0;
  size = 10;
  frozen = '';
  interviewResult = '';
  query = '';
  totalElements = 0;
  FeatureName = FeatureName;
  PermissionType = PermissionType;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private jobService: JobService,
    private candidateService: CandidateService,
    public accountService: AccountService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.eventStatus = localStorage.getItem('eventStatus');
    // this.loader = true;
    this.selectedJob = this.jobService.getSelectedJob();
    // console.log(this.selectedJob);
    // this.refreshTable();
  }
  // changeSelectedJob(e) {
  //   this.loader = true;
  //   let job = this.jobsInEvent.find((job) => job.id === e.value);
  //   if (job) this.selectedJob = job;
  //   this.refreshTable();
  // }
  search = (e) => {
    this.query = e.target.value;
    if (e.keyCode === 13) {
      this.refreshTable();
    }
  };
  addCandidate = () => {
    const dialogRef = this.dialog.open(AddCandidateComponent, {
      data: {},
      backdropClass: 'backdrop-background',
      height: '180px',
      width: '350px',
    });

    dialogRef.afterClosed().subscribe((addCandidateForm) => {
      if (addCandidateForm) {
        if (addCandidateForm.id) {
          const cir = {
            jobOpeningId: this.selectedJob.id,
            candidateId: addCandidateForm.id,
            applicationId: addCandidateForm.applicationID,
            subUnit: addCandidateForm.subUnit,
            technology: addCandidateForm.technology,
            unit: addCandidateForm.unit,
          };
          this.jobService.createCandidateInJob(cir).subscribe(
            (res) => {
              this.sharedService.openSnackBar(
                'Candidate added successfully.',
                '',
                3000
              );
              this.refreshAgGrid.next();
            },
            (err) => {
              if (err?.error?.title) {
                this.sharedService.openSnackBar(err?.error.title, '', 3000);
              } else {
                this.sharedService.openSnackBar(
                  'Add candidate failed',
                  '',
                  3000
                );
              }
            }
          );
        } else {
          const form = {
            jobOpeningId: this.selectedJob.id,
            candidateDTO: {
              email: addCandidateForm.email,
              name: addCandidateForm.name,
              phone: addCandidateForm.phone,
              countryCode: addCandidateForm.countryCode,
              candidateId: addCandidateForm.candidateId,
              candidateSource: addCandidateForm.candidateSource,
            },
            applicationId: addCandidateForm.applicationID,
            subUnit: addCandidateForm.subUnit,
            technology: addCandidateForm.technology,
            unit: addCandidateForm.unit,
          };
          this.jobService.createCandidateInJob(form).subscribe(
            (res) => {
              this.refreshAgGrid.next();
            },
            (err) => {
              this.sharedService.openSnackBar('Add candidate failed', '', 3000);
            }
          );
        }
      }
    });
  };
  interface = (
    size: number,
    page: number,
    query?: string
  ): Observable<any[]> => {
    //console.log(this.eventService.getAllEvents(size, page, this.queryEventName));
    return this.candidateService.getJobCandidates(
      this.selectedJob.id,
      page,
      size,
      this.frozen,
      this.interviewResult,
      query
    );
  };

  linkFormatter(params: any) {
    if (!params.data) return '';
    return (
      '/infy-recruiter-feedback/' +
      params.data.cirId.toString() +
      '/' +
      params.data.candidateId.toString() +
      '/' +
      params.data.jobRoleId
    );
  }

  freezeFormatter(params: any) {
    if (!params.data) return;
    return params.data.frozen ? 'Frozen' : 'Not Frozen';
  }

  idFormatter(params: any) {
    if (!params.data) return;
    return params.data.candidateSourceId
      ? params.data.candidateSourceId
      : params.data.candidateId;
  }

  pageChange = (e) => {
    this.size = e.pageSize;
    this.page = e.pageIndex;
    this.refreshTable();
  };
  clearFilters = () => {
    this.frozen = '';
    this.interviewResult = '';
    this.query = '';
    this.refreshTable();
  };

  refreshTable() {
    this.count = 0;
    this.length = 0;
    this.failed = [];
    this.loader = true;

    this.candidateService
      .getJobCandidates(
        this.selectedJob.id,
        this.page,
        this.size,
        this.frozen,
        this.interviewResult,
        this.query
      )
      .subscribe(
        (res: any) => {
          this.tableData = [];
          this.totalElements = res.totalElements;
          res = res.content;
          this.candidateDataList = res;
          res.forEach((data) => {
            this.tableData.push({
              candidateId: data.candidateId,
              candidateSourceId: data.candidateSourceId
                ? data.candidateSourceId
                : '',
              name: data.name,
              email: data.email,
              phone: data.phone,
              data: data,
            });
          });
          this.loader = false;
        },
        () => {},
        () => {
          this.loader = false;
        }
      );
  }

  setCandidate(candidate) {
    this.candidateService.setSelectedCandidate(candidate);
    this.router.navigate(['/candidate-interview-rounds']);
  }
}
