import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MailTemplateListService {
  page = 0;
  size = 10;
 
  constructor() {}
  init = () => {
    this.page = 0;
    this.size = 10;
   
  };
}
