
<div *ngIf="loading">
  <app-loader [display]="loading" [textToShow]="textToShow"></app-loader>
</div>

<div class="marL0 marB16" *ngIf="!loading">
  <mat-card class="marT32 col-md-12" *ngIf="uploadedImagesFlag">
    <mat-card-content>
      <div class="row padL16">
        <span class="mat-h2 font-weight-bold category-text">
          Uploaded Images
        </span>
      </div>
      <div class="row">
        <div
          class="col-md-12"
        >
          <div>
            <div
              class="col-12 col-md-12 nopadLR"
              *ngIf="interviewSnapshots.length > 0"
            >
              <div class="container-fluid nopadLR">
                <div class="row nopadLR">
                  <div
                    class="snapshot-container marR8 marB8"
                    *ngFor="let img of interviewSnapshots"
                  >
                    <div class="position-relative marR16">
                      <img
                        (click)="saveImage(img)"
                        [src]="img.url"
                        class="snapshot"
                      />
                      <a
                        href="javascript:void(0);"
                        class="whiteIcon-Circle delete-snap"
                        matTooltip="Remove snapshot"
                        matTooltipClass="DSA_tooltip"
                        (click)="removeFile(img)"
                      >
                        <i class="icon x-16 close-icon"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="interviewSnapshots.length == 0">
        <p class="d-flex justify-content-center align-items-center">Error occured while getting uploaded images, please try again</p>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
