import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { linkRendererComponent } from '../ag-grid-renderer/linkRenderer.component';
import {
  GridApi,
  GridOptions,
  IDatasource,
  IGetRowsParams,
} from 'ag-grid-community';
import { SharedService } from 'src/app/services/shared.service';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'data-grid',
  templateUrl: './AgGrid.component.html',
  styleUrls: ['./AgGrid.component.scss'],
})
export class AgGridComponent implements OnInit, OnDestroy {
  @Output() searchKey: EventEmitter<any> = new EventEmitter<any>();
  @Output() dialogFormValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageChangeValue: EventEmitter<any> = new EventEmitter<any>();
  @Input() tableName: string = '';
  @Input() columnDefs: Array<object> = [];
  @Input() buttonText: string = '';
  @Input() buttonComponent: any;
  @Input() levels: number = 1;
  @Input() buttonCondition: boolean = true;
  @Input() searchCondition: boolean = true;
  @Input() customRowHeight: number = 50;
  @Input() refreshAgGrid: Observable<void>;
  @Input() pageNumber: number = 0;
  @Input() scrollPosition: number;
  @Input() pageSize: number = 10;
  @Input() showRefresh = true;
  @Input() interface: (
    size: number,
    page: number,
    query?: string
  ) => Observable<any[]> | Object;

  private refreshAgGridSubscription: Subscription;
  private gridApi: GridApi;
  private gridColumnApi: any;

  @ViewChild('gridWrap') gridWrap: any;

  @Input() searchValue!: string;
  searchError: string;
  totalElements: number = 0;
  isLoading: boolean = false;
  isRefreshed: boolean = false;
  filterModel: any;

  gridOptions: GridOptions = {
    rowHeight: this.customRowHeight,
    context: {
      componentParent: this,
    },
    headerHeight: 50,
    multiSortKey: 'ctrl',
    rowStyle: {
      'border-bottom': 'lightgrey 1px solid',
      'background-color': 'transparent',
      'font-family': 'roboto, Arial',
      'font-size': '14px',
    },
    defaultColDef: {
      // sortable: true,
      resizable: true,
      flex: 1,
      minWidth: 100,
    },
    columnTypes: {
      required: { hide: false },
      date: {
        valueFormatter: (params: any) => {
          if (!params.data) return 'Loading...';
          return params.value.substring(0, 10);
          //return new Date(params.value);
        },
        // filter: 'agDateColumnFilter',
      },
      link: {
        cellRendererFramework: linkRendererComponent,
      },
      requiredLink: {
        hide: false,
        cellRendererFramework: linkRendererComponent,
      },
    },
    pagination: true,
    suppressPaginationPanel: true,
    paginationPageSize: this.pageSize,
    animateRows: true,
    overlayLoadingTemplate: `<div class="container-fluid loader-wrap">
      <div class="row text-center">
        <div class="loader_cont">
          <div class="loading-spinner">
            <div class="loading-spinner-inner">
              <div></div>
            </div>
          </div>
        </div>
        <p class="loading-text">Data is loading, please wait...</p>
      </div>
    </div>`,
    overlayNoRowsTemplate: this.customNoRowsTemplate('No records found'),
    rowModelType: 'infinite',
    maxConcurrentDatasourceRequests: 2,
    infiniteInitialRowCount: 1,
  };

  ngOnInit() {
    this.gridOptions.rowHeight = this.customRowHeight;
    this.refreshAgGridSubscription = this.refreshAgGrid?.subscribe(() => {
      this.refreshTable();
    });
  }

  ngOnDestroy() {
    if (this.refreshAgGridSubscription)
      this.refreshAgGridSubscription.unsubscribe();

    this.pageChangeValue.emit({
      target: {
        page: this.pageNumber,
        size: this.pageSize,
        searchQuery: this.searchValue,
      },
    });
  }

  responseProcessing = (response) => {
    this.totalElements = response.totalElements;
    this.gridOptions.paginationPageSize = response.numberOfElements;
    this.setGridUI();
  };

  dataSource: IDatasource = {
    getRows: (params: IGetRowsParams) => {
      this.showLoadingOverlay();

      //Real data passed using observable from API
      if (typeof this.interface === 'function') {
        (
          this.interface(
            this.pageSize,
            this.pageNumber,
            this.searchValue ? this.searchValue.trim() : ''
          ) as Observable<any[]>
        ).subscribe(
          (res: any) => {
            // console.log("From AG",res);
            this.responseProcessing(res);
            params.successCallback(res.content, this.pageSize);
            if (this.scrollPosition) {
              this.gridApi.ensureIndexVisible(Number(this.scrollPosition));
            }
          },
          (err) => {
            params.failCallback();
            if (this.isRefreshed) this.isRefreshed = false;
            this.gridOptions.overlayNoRowsTemplate = this.customNoRowsTemplate(
              'Uh-oh! Request timed out. Please refresh after sometime'
            );
            this.gridApi.showNoRowsOverlay();

            // set no rows template to default
            this.gridOptions.overlayNoRowsTemplate =
              this.customNoRowsTemplate('No records found');
          }
        );
      }
      //Mock data passed as object from Storybook
      else {
        of(this.interface).subscribe((res: any) => {
          this.responseProcessing(res);
          params.successCallback(res.content, this.pageSize);
        });
      }
    },
  };

  refreshTable = () => {
    this.gridApi.setDatasource(this.dataSource);
  };

  handleRefreshClick = () => {
    this.isRefreshed = true;
    this.refreshTable();
  };

  constructor(public dialog: MatDialog, private sharedService: SharedService) {}

  //onColumnResized(params: any) {
  //  params.api.resetRowHeights();
  //}

  //onColumnVisible(params: any) {
  //  params.api.resetRowHeights();
  //}

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setDatasource(this.dataSource);
  }

  search(event: any) {
    if (this.sharedService.validateSearchQuery(this.searchValue)) {
      this.searchError = '';
      if (event.keyCode === 13 || event.type === 'click') {
        this.gridApi.setDatasource(this.dataSource);
        this.pageNumber = 0;
        this.gridApi.paginationGoToPage(this.pageNumber);
      }
    } else {
      this.searchError = `Special characters except '_,.@&()-' are not allowed`;
      this.sharedService.openSnackBar(this.searchError, '', 3000);
    }
  }

  clearSearch = () => {
    this.searchValue = '';
    this.refreshTable();
  };

  toggleVisibility(column: any) {
    this.gridOptions.columnApi.setColumnVisible(column.field, column.hide);
    column.hide = !column.hide;

    if (this.gridOptions.columnApi.getAllDisplayedColumns().length === 0) {
      this.gridOptions.overlayNoRowsTemplate = this.customNoRowsTemplate(
        'Please select at least one column to show.'
      );
      this.gridApi.showNoRowsOverlay();
    } else if (!column.hide) {
      if (this.totalElements !== 0) this.gridApi.hideOverlay();
      else {
        this.gridOptions.overlayNoRowsTemplate =
          this.customNoRowsTemplate('No records found');
        this.gridApi.showNoRowsOverlay();
      }
    }
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    let dialogRef = this.dialog.open(this.buttonComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((form) => {
      if (form) {
        this.dialogFormValue.emit(form);
      }
    });
  }

  showLoadingOverlay() {
    this.gridApi.showLoadingOverlay();
    this.isLoading = true;
    this.gridWrap.nativeElement.style.height = `300px`;
  }

  setGridUI() {
    let height =
      this.gridOptions.paginationPageSize < 10
        ? this.gridOptions.paginationPageSize * this.gridOptions.rowHeight + 70
        : 570;
    this.isLoading = false;
    if (this.isRefreshed) this.isRefreshed = false;
    this.gridApi.hideOverlay();
    if (this.totalElements === 0) {
      this.gridApi.showNoRowsOverlay();
      this.gridApi.paginationSetPageSize(1);
      height = this.gridOptions.rowHeight + 200;
    }
    this.gridWrap.nativeElement.style.height = `${height}px`;
  }

  customNoRowsTemplate(message) {
    return `<div class="noDataOverlay">
      <img src="assets/images/no_data_found.svg" alt="" />
      <h4 class="text-center marT32">${message}</h4>
    </div>`;
  }

  onPageChange(event: { pageSize: number; pageIndex: number }) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageIndex;

    if (this.gridApi) {
      this.gridApi.setDatasource(this.dataSource);
      this.gridApi.paginationGoToPage(this.pageNumber);
      this.gridApi.paginationSetPageSize(this.pageSize);
    }
  }
}
