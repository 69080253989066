<app-loader [display]="loading"></app-loader>

<div class="container-fluid layout" *ngIf="!loading">
  <!-- ! Candidate details on top of page -->
  <div class="row mb-3" *ngIf="candidateData">
    <div class="col-md-12 d-flex">
      <a (click)="_location.back()" style="cursor: pointer" class="mr-2">
        <i class="icon x-24 back-icon m-0"> </i>
      </a>
      <h1 class="DSA_wb_h1Header">{{ candidateData.name }}</h1>
    </div>
  </div>

  <div class="row" *ngIf="candidateData">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12 col-md-12">
          <mat-card class="DSA_wb_card-widget min-height-card">
            <div class="row">
              <!-- !candidate details -->
              <div class="col-md-4 col-sm-12 nopadLR">
                <mat-card-header>
                  <mat-card-title class="DSA_wb_flex clearfix">
                    <div class="DSA_wb_flex80">
                      <h2 class="DSA_wb_h2Header DSA_wb_text-1line">
                        Candidate Details
                      </h2>
                    </div>
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content class="mb-0">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-12 pl-0 pr-0">
                          <mat-form-field class="DSA_Wb_custom-form-field">
                            <input
                              matInput
                              placeholder="Can ID"
                              readonly
                              class="pointer"
                              [value]="
                                candidateData.candidateId
                                  ? candidateData.candidateId
                                  : candidateData.id
                              "
                            />
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 pl-0 pr-0">
                          <mat-form-field class="DSA_Wb_custom-form-field">
                            <input
                              matInput
                              placeholder="Name"
                              readonly
                              class="pointer"
                              [value]="candidateData.name"
                            />
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 pl-0 pr-0">
                          <mat-form-field class="DSA_Wb_custom-form-field">
                            <input
                              matInput
                              placeholder="Email"
                              readonly
                              class="pointer"
                              [value]="candidateData.email"
                            />
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 pl-0 pr-0">
                          <mat-form-field class="DSA_Wb_custom-form-field">
                            <input
                              matInput
                              placeholder="Phone"
                              readonly
                              class="pointer"
                              [value]="candidateData.phone"
                            />
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card-content>
              </div>
              <!-- ! profile pic -->
              <div class="col-md-4 col-sm-12 nopadLR">
                <div class="col-md-12">
                  <mat-card-header>
                    <mat-card-title class="DSA_wb_flex clearfix">
                      <div class="DSA_wb_flex80">
                        <h2
                          class="DSA_wb_h2Header DSA_wb_h2Header_black DSA_wb_text-1line"
                        >
                          Profile Picture
                        </h2>
                      </div>
                    </mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <div class="snapshot-container">
                      <img
                        (click)="saveImage(profileImage)"
                        [src]="
                          profileImage
                            ? profileImage.url
                            : 'assets/images/no_image.png'
                        "
                        class="snapshot-img"
                      />
                    </div>
                  </mat-card-content>
                </div>
              </div>
              <!-- !assessment pic -->
              <div class="col-md-4 col-sm-12 nopadLR">
                <div class="col-md-12">
                  <mat-card-header>
                    <mat-card-title class="DSA_wb_flex clearfix">
                      <div class="DSA_wb_flex80">
                        <h2
                          class="DSA_wb_h2Header DSA_wb_h2Header_black DSA_wb_text-1line"
                        >
                          Assessment Picture
                        </h2>
                      </div>
                    </mat-card-title>
                  </mat-card-header>
                  <div class="snapshot-container">
                    <img
                      (click)="saveImage(iapAssessmentImage)"
                      [src]="
                        iapAssessmentImage
                          ? iapAssessmentImage.url
                          : 'assets/images/no_image.png'
                      "
                      class="snapshot-img"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- !resume and appliction form -->
            <mat-card-actions>
              <button
                *ngIf="resumeBlob && accountService.hasFeatureAccess(FeatureName.VIEW_RESUME, PermissionType.READ)"
                mat-button
                class="DSA_wb_text-btn"
                (click)="openResume()"
              >
                Click here to View Resume
              </button>

              <button
                *ngIf="applicationFormBlob"
                mat-button
                class="DSA_wb_text-btn"
                (click)="openApplicationForm()"
              >
                Click here to View Application Form
              </button>
              <!-- to be completed -->
              <button
                *ngIf="
                  !(resumeBlob || disableButton) &&
                  candidateSource === 'INTAP' &&
                  (accountService.hasFeatureAccess(FeatureName.VIEW_RESUME, PermissionType.READ)) &&
                  eventStatus !== 'CLOSED'
                "
                mat-button
                class="DSA_wb_text-btn"
                (click)="pullResumeFromIntap()"
              >
                Pull Resume From INTAP
              </button>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
    </div>
  </div>

  <!-- ! Candidate Journey Expansion Panel -->
  <div class="row mt-2">
    <div class="col-md-12">
      <mat-accordion class="DSA_wb-cus-accordion">
        <mat-expansion-panel>
          <mat-expansion-panel-header
            [collapsedHeight]="'40px'"
            [expandedHeight]="'40px'"
          >
            <mat-panel-title> Candidate Journey </mat-panel-title>
          </mat-expansion-panel-header>
          <app-visualization
            [data]="data"
            [nodes]="nodes"
            [links]="links"
            [center$]="center$"
            [zoomToFit$]="zoomToFit$"
          ></app-visualization>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <!-- ! Face matching expansion panel -->
  <div class="row mb-2" *ngIf="showFaceMatching">
    <div class="col-md-12">
      <mat-accordion class="DSA_wb-cus-accordion">
        <mat-expansion-panel>
          <mat-expansion-panel-header
            [ngStyle]="faceValidationColor()"
            [collapsedHeight]="'40px'"
            [expandedHeight]="'40px'"
          >
            <mat-panel-title style="color: white">
              Face Matching
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="container">
            <div class="row">
              <div class="col-md-8">
                <app-visualization
                  [data]="faceRecognitionData"
                  [nodes]="faceRecognitionNodes"
                  [links]="faceRecognitionLinks"
                  [center$]="center$"
                  [zoomToFit$]="zoomToFit$"
                ></app-visualization>
              </div>
              <div class="my-4 col-md-4">
                <h4 class="pb-4">Face Matching</h4>
                <div *ngIf="profilePicAvailable">
                  <h6 class="pb-2">Comparison with Profile Picture</h6>
                  <div
                    *ngFor="
                      let feedback of recruiterFaceMatchingFeedback;
                      let i = index
                    "
                  >
                    <div
                      *ngIf="
                        feedback.comparisonType == 'PROFILE_TO_INTERVIEW_SNAP'
                      "
                      class="my-3 d-flex align-items-center flex-wrap"
                    >
                      <label>{{ feedback.interviewTitle }}</label>
                      <mat-radio-group
                        class="DSA_Wb_custom-radio pl-3"
                        [value]="feedback.recruiterFeedback"
                        (change)="radioChange($event, i)"
                        [disabled]="eventStatus === 'CLOSED'"
                      >
                        <mat-radio-button value="YES" disableRipple="true">
                          Yes
                        </mat-radio-button>
                        <mat-radio-button value="NO" disableRipple="true">
                          No
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
                <div *ngIf="assessmentPicAvailable">
                  <h6 class="pb-2">Comparison with Assessment Picture</h6>
                  <div
                    *ngFor="
                      let feedback of recruiterFaceMatchingFeedback;
                      let i = index
                    "
                  >
                    <div
                      *ngIf="
                        feedback.comparisonType ==
                        'IAP_ASSESSMENT_SNAP_TO_INTERVIEW_SNAP'
                      "
                      class="my-3 d-flex align-items-center flex-wrap"
                    >
                      <label>{{ feedback.interviewTitle }}</label>
                      <mat-radio-group
                        class="DSA_Wb_custom-radio pl-3"
                        [value]="feedback.recruiterFeedback"
                        (change)="radioChange($event, i)"
                        [disabled]="eventStatus === 'CLOSED'"
                      >
                        <mat-radio-button value="YES" disableRipple="true">
                          Yes
                        </mat-radio-button>
                        <mat-radio-button value="NO" disableRipple="true">
                          No
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>

                <div class="my-2">
                  <button
                    mat-flat-button
                    class="DSA_wb_secondary-btn"
                    (click)="submitFaceRecognitionFeedback()"
                    [disabled]="eventStatus === 'CLOSED'"
                  >
                    Submit Feedback
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

  <!-- ! Interview Snapshots-->
  <div class="row mt-2 mb-2">
    <div
      class="col-12 col-md-12"
      *ngFor="let feedback of evaluationData; let i = index"
    >
      <mat-card
        class="DSA_wb_card-widget min-height-card mt-2 mb-2 nopadR"
        *ngIf="interviewSnapshots[i].length > 0"
      >
        <mat-card-header>
          <mat-card-title class="DSA_wb_flex clearfix">
            <div class="DSA_wb_flex80">
              <h2 class="DSA_wb_h2Header DSA_wb_text-1line">
                {{ feedback.interview.title + " Snapshots" }}
              </h2>
            </div>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div
            class="col-12 col-md-12 nopadLR"
            *ngIf="interviewSnapshots.length > 0"
          >
            <div class="container-fluid nopadLR">
              <div class="row nopadLR">
                <div
                  class="snapshot-container marR8 marB8"
                  *ngFor="let img of interviewSnapshots[i]"
                >
                  <img
                    (click)="saveImage(img)"
                    [src]="img.url"
                    class="snapshot-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <!-- ! If no evaluation data present -->
  <div class="row mt-2 mb-2" *ngIf="evaluationData?.length === 0">
    <div class="col-md-12">
      <h1 class="DSA_wb_h1Header">No Feedback Data</h1>
    </div>
  </div>

  <!-- ! If evaluation data present -->
  <div class="row mb-3">
    <div class="col-md-12">
      <mat-accordion class="DSA_wb-cus-accordion">
        <mat-expansion-panel
        [disabled] = "!accountService.hasFeatureAccess(FeatureName.REVIEW_FEEDBACK, PermissionType.READ)"
          *ngFor="let feedback of evaluationData; let i = index"
        >
          <mat-expansion-panel-header
            [collapsedHeight]="'40px'"
            [expandedHeight]="'40px'"
          >
            <mat-panel-title>
              {{ feedback.interview.title }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <!-- add loader here -->
          <app-loader [display]="feedbackLoading"></app-loader>

          <!-- ! when jobType is not OTHERS -->
          <div
            class="container-fluid mt-3"
            *ngIf="
              !feedbackLoading &&
              feedback.jobtype &&
              feedback.jobtype !== 'OTHERS'
            "
          >
            <!-- ? Information fields on top of accordion -->
            <div class="row">
              <!-- interviewer name, start date and score fields -->
              <div class="col-md-4">
                <mat-form-field class="DSA_Wb_custom-form-field">
                  <input
                    matInput
                    placeholder="Interviewer Name"
                    readonly
                    class="pointer"
                    [value]="feedback.interviewerName"
                  />
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field class="DSA_Wb_custom-form-field">
                  <input
                    matInput
                    placeholder="Date"
                    readonly
                    class="pointer"
                    [value]="
                      feedback.interview.interviewSchedule.startDate | date
                    "
                  />
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field class="DSA_Wb_custom-form-field">
                  <input
                    matInput
                    placeholder="Total Score"
                    readonly
                    class="pointer"
                    [value]="feedback.score ? feedback.score : 0"
                  />
                </mat-form-field>
              </div>

              <!-- when jobtype is PP_IJP -->
              <div class="col-md-4 p-0" *ngIf="feedback.jobtype === 'PP_IJP'">
                <div *ngFor="let val of feedback.ratings">
                  <div
                    class="col-md-12"
                    *ngIf="val.title === 'Suggested level of candidate'"
                  >
                    <mat-form-field class="DSA_Wb_custom-form-field">
                      <input
                        matInput
                        [placeholder]="val.title"
                        readonly
                        class="pointer"
                        [value]="val.remarks"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-md-4 p-0" *ngIf="feedback.jobtype === 'PP_IJP'">
                <div class="row" *ngFor="let val of feedback.ratings">
                  <div
                    class="col-md-12"
                    *ngIf="val.title === 'PP Training Waive off'"
                  >
                    <mat-form-field class="DSA_Wb_custom-form-field">
                      <input
                        matInput
                        [placeholder]="val.title"
                        readonly
                        class="pointer"
                        [value]="val.remarks"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <!-- Work experience related fields for SE and NEG role (off campus) -->
              <div
                class="col-md-4 p-0"
                *ngIf="['SE', 'NEG'].includes(feedback.jobtype)"
              >
                <div class="row">
                  <div
                    class="col-md-12"
                    *ngIf="workExperienceSEandNEG[feedback.interview.title]"
                  >
                    <mat-form-field class="DSA_Wb_custom-form-field">
                      <input
                        matInput
                        placeholder="Work Experience"
                        readonly
                        class="pointer"
                        [value]="
                          workExperienceSEandNEG[feedback.interview.title]
                        "
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div
                class="col-md-4 p-0"
                *ngIf="
                  ['SE', 'NEG'].includes(feedback.jobtype) &&
                  workExperienceSEandNEG[feedback.interview.title] === 'YES'
                "
              >
                <div class="row" *ngFor="let val of feedback.ratings">
                  <div
                    class="col-md-12"
                    *ngIf="val.title === 'Experience (in months)'"
                  >
                    <mat-form-field class="DSA_Wb_custom-form-field">
                      <input
                        matInput
                        [placeholder]="val.title"
                        readonly
                        class="pointer"
                        [value]="val.remarks"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div
                class="col-md-4 p-0"
                *ngIf="['SE', 'NEG'].includes(feedback.jobtype)"
              >
                <div class="row" *ngFor="let val of feedback.ratings">
                  <div
                    class="col-md-12"
                    *ngIf="val.title === 'Notice Period (in months)'"
                  >
                    <mat-form-field class="DSA_Wb_custom-form-field">
                      <input
                        matInput
                        [placeholder]="val.title"
                        readonly
                        class="pointer"
                        [value]="val.remarks"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div
                class="col-md-4 p-0"
                *ngIf="['SE', 'NEG'].includes(feedback.jobtype)"
              >
                <div class="row" *ngFor="let val of feedback.ratings">
                  <div
                    class="col-md-12"
                    *ngIf="val.title === 'Do you have a laptop/desktop?'"
                  >
                    <mat-form-field class="DSA_Wb_custom-form-field">
                      <input
                        matInput
                        [placeholder]="val.title"
                        readonly
                        class="pointer"
                        [value]="val.remarks"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <!------------------------------------------------>

              <!-- feedback result and comments -->
              <div class="col-md-4">
                <mat-form-field class="DSA_Wb_custom-form-field">
                  <input
                    matInput
                    placeholder="Result"
                    readonly
                    class="pointer"
                    [value]="feedback.result"
                  />
                </mat-form-field>
              </div>

              <!-- extra -->
              <div class="col-md-4">
                <mat-form-field class="DSA_Wb_custom-form-field">
                  <input
                    matInput
                    placeholder="Feedback"
                    readonly
                    class="pointer"
                    [value]="feedback.comments"
                  />
                </mat-form-field>
              </div>
            </div>

            <!-- ? rest of the expansion panel -->
            <div class="row">
              <div class="col-md-12">
                <div class="col-md-12 pt-2 pb-2" *ngIf="dataSource.length > 0">
                  <div class="row mt-5">
                    <span class="mat-h2 font-weight-bold category-text">
                      ID validation
                    </span>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <mat-radio-group
                        [value]="photoVerificationValue[i]"
                        class="DSA_Wb_custom-radio"
                      >
                        <mat-radio-button
                          *ngFor="
                            let photoVerificationOption of photoVerificationOptions
                          "
                          (click)="$event.preventDefault()"
                          [value]="photoVerificationOption"
                          disableRipple="true"
                        >
                          {{ photoVerificationOption }}
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
                <!-- <div
                  class="col-md-12 pt-2 pb-2"
                  *ngIf="
                    dataSource.length > 0 &&
                    !['SE_TQ', 'SE', 'PP', 'SES'].includes(feedback.jobtype)
                  "
                >
                  <div
                    class="container-fluid mat-elevation-z2 pb-2 pt-2"
                    *ngFor="let element of dataSource[i]"
                  >
                    <div class="row">
                      <div class="col-md-2">Criteria:</div>
                      <div class="col-md-10">{{ element.criteria }}</div>
                    </div>
                    <div class="row" *ngIf="element.remark">
                      <div class="col-md-2">Remark:</div>
                      <div class="col-md-10">{{ element.remark }}</div>
                    </div>
                    <div class="row" *ngIf="element.ratingRemarks.length > 0">
                      <div class="col-md-2">Indicators:</div>
                      <div class="col-md-10">
                        <div
                          class="ml-1"
                          *ngFor="let indicator of element.ratingRemarks"
                          [style]="
                            indicator.positive ? 'color : green' : 'color : red'
                          "
                        >
                          {{ indicator.value }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="row"
                      *ngIf="element.rating && element.maxRating"
                    >
                      <div class="col-md-2">Rating:</div>
                      <div
                        class="col-md-10"
                        *ngIf="element.rating && element.maxRating"
                      >
                        {{ element.rating }}/
                        {{ element.maxRating }}
                      </div>
                    </div>
                  </div>
                </div> -->

                <!-- evaluation if job type is not SE,PEGA,NEG (off campus or on campus) -->
                <div
                  class="col-md-12 pt-2 pb-2"
                  *ngIf="
                    !['SE', 'PEGA', 'NEG', 'SE_CAMPUS', 'OE_CAMPUS'].includes(
                      feedback.jobtype
                    ) && evaluationDetailList[i]?.length > 0
                  "
                >
                  <div class="row mt-3">
                    <span class="mat-h2 font-weight-bold category-text">
                      Evaluation Criteria
                    </span>
                  </div>
                  <div class="container-fluid ml-0 mr-0 p-0">
                    <div
                      class="row"
                      *ngFor="
                        let evaluation of evaluationDetailList[i];
                        let eIndex = index
                      "
                    >
                      <div class="col-6">
                        <h3 class="mat-h3 font-weight-bold mb-0">
                          {{ evaluation.name }}
                        </h3>
                        <div
                          class="mat-body"
                          [innerHTML]="evaluation.description"
                        ></div>
                      </div>
                      <div class="col-6">
                        <div class="row">
                          <div class="DSA_wb_customSlider w-75">
                            <ng5-slider
                              [value]="
                                evaluationDataForm[i][
                                  evaluation.name + ' Score'
                                ]
                              "
                              [options]="
                                evaluationDataForm[i][
                                  evaluation.name + ' maxScore'
                                ] == 4
                                  ? {
                                      floor: 1,
                                      ceil: evaluationDataForm[i][
                                        evaluation.name + ' maxScore'
                                      ]
                                        ? evaluationDataForm[i][
                                            evaluation.name + ' maxScore'
                                          ]
                                        : 4,
                                      showTicks: true,
                                      showTicksValues: true,
                                      tickStep: 1,
                                      showSelectionBar: true,
                                      disabled: true,
                                      stepsArray: [
                                        { value: 1, legend: 'Unsatisfactory' },
                                        { value: 2, legend: 'Marginal' },
                                        { value: 3, legend: 'Proficient' },
                                        { value: 4, legend: 'Excellent' }
                                      ]
                                    }
                                  : {
                                      floor: 1,
                                      ceil: evaluationDataForm[i][
                                        evaluation.name + ' maxScore'
                                      ]
                                        ? evaluationDataForm[i][
                                            evaluation.name + ' maxScore'
                                          ]
                                        : 4,
                                      showTicks: true,
                                      showTicksValues: true,
                                      tickStep: 1,
                                      showSelectionBar: true,
                                      disabled: true
                                    }
                              "
                            ></ng5-slider>
                          </div>
                        </div>
                        <div class="row">
                          <mat-form-field
                            appearance="fill"
                            class="DSA_Wb_custom-form-field"
                          >
                            <mat-label>Remark</mat-label>
                            <textarea
                              matInput
                              rows="3"
                              cols="16"
                              [value]="evaluationDataForm[i][evaluation.name]"
                              readonly="true"
                              required
                            ></textarea>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <mat-divider class="mb-3 mt-3"></mat-divider>
                      </div>
                    </div>
                  </div>
                  <div class="container-fluid p-0">
                    <div class="row">
                      <div class="col-md-12">
                        <span class="mat-h2 font-weight-bold category-text">
                          Personal Remarks
                        </span>
                      </div>
                    </div>
                    <div class="row" *ngFor="let remark of personalRemarks">
                      <div class="col-12 col-md-6 d-flex align-items-center">
                        <mat-checkbox
                          class="DSA_Wb_custom-checkbox"
                          (click)="$event.preventDefault()"
                          [checked]="
                            evaluationDataForm[i][remark].startsWith('Yes')
                          "
                        >
                          {{ remark }}
                        </mat-checkbox>
                      </div>
                      <div class="col-12 col-md-6">
                        <mat-form-field
                          appearance="fill"
                          class="DSA_Wb_custom-form-field"
                        >
                          <mat-label>Comments</mat-label>
                          <textarea
                            matInput
                            [value]="evaluationDataForm[i][remark]"
                            rows="1"
                            cols="16"
                            [readonly]="true"
                          ></textarea>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        ** Significant- which impedes communication
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <mat-divider class="mb-3 mt-3"></mat-divider>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- evaluation if job type is SE,PEGA,NEG (on-campus or off-campus)-->
                <div
                  class="col-md-12 pt-2 pb-2"
                  *ngIf="
                    dataSource.length > 0 &&
                    ['SE', 'PEGA', 'NEG', 'SE_CAMPUS', 'OE_CAMPUS'].includes(
                      feedback.jobtype
                    )
                  "
                >
                  <form
                    class="container-fluid mt-3 p-0 d-none d-md-block"
                    [formGroup]="customizableForm[i]"
                  >
                    <div
                      class="container-fluid p-0"
                      *ngFor="let data of questionsData[i]"
                    >
                      <div class="row mb-3">
                        <div class="col-md-12">
                          <span class="mat-h2 font-weight-bold category-text">
                            {{ data.category }}
                          </span>

                          <div class="DSA_wb_customSlider score-slider-se">
                            <ng5-slider
                              *ngIf="data.category !== 'MISCELLANEOUS COMMENTS'"
                              [value]="
                                customizableForm[i].value[
                                  data.category + ' Score'
                                ]
                              "
                              [options]="{
                                floor: 1,
                                ceil: 4,
                                showTicks: true,
                                showTicksValues: true,
                                tickStep: 1,
                                showSelectionBar: true,
                                disabled: true,
                                stepsArray: [
                                  { value: 1, legend: 'Unsatisfactory' },
                                  { value: 2, legend: 'Marginal' },
                                  { value: 3, legend: 'Proficient' },
                                  { value: 4, legend: 'Excellent' }
                                ]
                              }"
                            ></ng5-slider>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <h3 class="mat-h3 mb-0 font-weight-bold">
                            Key Postive Indicator
                          </h3>
                        </div>
                        <div class="col-6">
                          <h3 class="mat-h3 mb-0 font-weight-bold">
                            Key Negative Indicator
                          </h3>
                        </div>
                      </div>
                      <div class="row" *ngFor="let question of data.questions">
                        <div class="col-md-12">
                          <mat-selection-list
                            [formControlName]="
                              data.category + ' ' + question.questionNumber
                            "
                            [disabled]="true"
                            [multiple]="false"
                            class="d-flex"
                          >
                            <div
                              class="col-6 ml-0 mr-0 d-flex"
                              *ngFor="let option of question.options"
                            >
                              <mat-checkbox
                                [checked]="
                                  customizableForm[i].value[
                                    data.category +
                                      ' ' +
                                      question.questionNumber
                                  ]?.length > 0 &&
                                  customizableForm[i].value[
                                    data.category +
                                      ' ' +
                                      question.questionNumber
                                  ][0] === option.value
                                "
                                (click)="$event.preventDefault()"
                                class="custom-checkbox DSA_Wb_custom-checkbox"
                              ></mat-checkbox>

                              <mat-list-option [value]="option">
                                {{ option.title }}
                              </mat-list-option>
                            </div>
                          </mat-selection-list>
                        </div>
                      </div>

                      <mat-divider class="mb-3 mt-3"></mat-divider>
                    </div>
                  </form>

                  <div class="container-fluid p-0">
                    <div class="row">
                      <div class="col-md-12">
                        <span class="mat-h2 font-weight-bold category-text">
                          Personal Remarks
                        </span>
                      </div>
                    </div>
                    <div class="row" *ngFor="let remark of dataSource[i]">
                      <div
                        class="row w-100"
                        *ngIf="
                          remark.criteriaType === 'REMARKS' ||
                          remark.criteriaType === 'PERSONAL_REMARKS'
                        "
                      >
                        <div class="col-12 col-md-6 d-flex align-items-center">
                          <mat-checkbox
                            class="DSA_Wb_custom-checkbox"
                            [checked]="remark.remark.startsWith('Yes')"
                            (click)="$event.preventDefault()"
                          >
                            {{ remark.criteria }}
                          </mat-checkbox>
                        </div>
                        <div class="col-12 col-md-6">
                          <mat-form-field
                            appearance="fill"
                            class="DSA_Wb_custom-form-field"
                          >
                            <mat-label>Comments</mat-label>
                            <textarea
                              matInput
                              [value]="remark.remark"
                              rows="1"
                              cols="16"
                              [readonly]="true"
                            ></textarea>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        ** Significant- which impedes communication
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <mat-divider class="mb-3 mt-3"></mat-divider>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- send back to panelist block -->
                <div class="col-12 mb-2">
                  <send-back-to-panelist
                    [remarks]="
                      sendBackToPanelistRemark.get(feedback.interview?.id)
                    "
                    (remarksChange)="
                      sendBackToPanelistRemark.set(
                        feedback.interview.id,
                        $event
                      )
                    "
                    [disabled]="
                      feedback.interview.interviewStatus ===
                        'SENT_FOR_PANELLIST_REVIEW' ||
                      sendBacktoPanelistStatus.get(feedback.interview?.id) ===
                        true
                    "
                    (onSubmit)="panelistReview($event, feedback.interview?.id)"
                  >
                  </send-back-to-panelist>
                </div>

                <!-- Panelist attendance block -->
                <panelist-participation-status
                  [panelists]="feedback.interview.panelists"
                  [interviewId]="feedback.interview.id"
                  [showAddPanelist]="true"
                >
                </panelist-participation-status>

                <div class="col-12 mb-2"></div>

                <!-- interview snapshots -->
                <div
                  class="col-md-12 pt-2 pb-2"
                  *ngIf="interviewSnapshots.length > 0"
                >
                  <div class="container-fluid">
                    <div class="row">
                      <div
                        class="col-md-4 snapshot-container"
                        *ngFor="let img of interviewSnapshots[i]"
                      >
                        <img
                          (click)="saveImage(img)"
                          [src]="img.url"
                          class="snapshot-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ! -->

          <!-- ! when jobType is null or OTHERS -->
          <div
            class="container-fluid mt-3"
            *ngIf="
              !feedbackLoading &&
              (feedback.jobtype === null || feedback.jobtype === 'OTHERS')
            "
          >
            <!-- ? Details on top of expansion panel (fields) -->
            <div class="row">
              <div class="col-md-4">
                <mat-form-field class="DSA_Wb_custom-form-field">
                  <input
                    matInput
                    placeholder="Interviewer Name"
                    readonly
                    class="pointer"
                    [value]="feedback.interviewerName"
                  />
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field class="DSA_Wb_custom-form-field">
                  <input
                    matInput
                    placeholder="Date"
                    readonly
                    class="pointer"
                    [value]="
                      feedback.interview.interviewSchedule.startDate | date
                    "
                  />
                </mat-form-field>
              </div>

              <!-- different -->
              <div class="col-md-4">
                <mat-form-field class="DSA_Wb_custom-form-field">
                  <input
                    matInput
                    placeholder="Calculated Score"
                    readonly
                    class="pointer"
                    [value]="feedback.score"
                  />
                </mat-form-field>
              </div>

              <div class="col-md-4">
                <mat-form-field class="DSA_Wb_custom-form-field">
                  <input
                    matInput
                    placeholder="Result"
                    readonly
                    class="pointer"
                    [value]="feedback.result"
                  />
                </mat-form-field>
              </div>
              <div
                class="col-md-4"
                *ngIf="
                  ['REJECTED', 'ON_HOLD'].includes(feedback.result) &&
                  feedback.rejectionReason
                "
              >
                <mat-form-field class="DSA_Wb_custom-form-field">
                  <input
                    matInput
                    placeholder="Rejection Reason"
                    readonly
                    class="pointer"
                    [value]="feedback.rejectionReason"
                  />
                </mat-form-field>
              </div>
            </div>

            <!-- ? rest of the expansion panel -->
            <div
              class="row"
              *ngIf="
                feedback.interview?.jobOpening?.jobRoleId &&
                feedback.interview?.id &&
                feedback.interview?.candidate?.candidateInterviewRoundId
              "
            >
              <!-- survey form -->
              <div class="col-md-12">
                <jhi-take-survey
                  *ngIf="
                    feedback?.feedbackFormType === 'SURVEY' ||
                    feedback?.feedbackFormType == null
                  "
                  [jobId]="feedback.interview?.jobOpening?.jobRoleId"
                  [interviewId]="feedback.interview?.id"
                  [cirId]="
                    feedback.interview?.candidate?.candidateInterviewRoundId
                  "
                  [disabled]="true"
                  [jobType]="feedback.interview?.roundType"
                  [surveyDefinitionId]="feedback.surveyId"
                >
                </jhi-take-survey>
                <app-infy-formio-evaluation
                  *ngIf="feedback?.feedbackFormType === 'FORMIO'"
                  [jobId]="feedback.interview?.jobOpening?.jobRoleId"
                  [formType]="feedback.interview?.roundType"
                  [interviewId]="feedback.interview?.id"
                  [cirId]="
                    feedback.interview?.candidate?.candidateInterviewRoundId
                  "
                  [readOnly]="true"
                ></app-infy-formio-evaluation>
              </div>

              <!-- send back to panelist block -->
              <div class="col-12 mt-4 mb-2">
                <send-back-to-panelist
                  [remarks]="
                    sendBackToPanelistRemark.get(feedback.interview?.id)
                  "
                  (remarksChange)="
                    sendBackToPanelistRemark.set(feedback.interview.id, $event)
                  "
                  [disabled]="
                    feedback.interview.interviewStatus ===
                      'SENT_FOR_PANELLIST_REVIEW' ||
                    sendBacktoPanelistStatus.get(feedback.interview?.id) ===
                      true
                  "
                  (onSubmit)="panelistReview($event, feedback.interview?.id)"
                >
                </send-back-to-panelist>
              </div>

              <!-- Panelist attendance block -->
              <panelist-participation-status
                [panelists]="feedback.interview.panelists"
                [interviewId]="feedback.interview.id"
                [showAddPanelist]="true"
              >
              </panelist-participation-status>

              <!-- Interview snapshots -->
              <div
                class="col-md-12 pt-2 pb-2"
                *ngIf="interviewSnapshots.length > 0"
              >
                <div class="container-fluid">
                  <div class="row">
                    <div
                      class="col-md-4 snapshot-container"
                      *ngFor="let img of interviewSnapshots[i]"
                    >
                      <img
                        (click)="saveImage(img)"
                        [src]="img.url"
                        class="snapshot-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ! -->
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <!-- ! -->

  <!-- ! No show Interview Data -->
  <div class="row mt-2 mb-2" *ngIf="noShowInterviews?.length > 0">
    <div class="col-md-12">
      <h1 class="DSA_wb_h1Header">No Show Data</h1>
    </div>
  </div>
  <div class="row mb-2" *ngIf="noShowInterviews?.length > 0">
    <div class="col-md-12">
      <mat-accordion class="DSA_wb-cus-accordion">
        <mat-expansion-panel
          *ngFor="let interview of noShowInterviews; let i = index"
        >
          <mat-expansion-panel-header
            [collapsedHeight]="'40px'"
            [expandedHeight]="'40px'"
          >
            <mat-panel-title>
              {{ interview.title }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="container-fluid mt-3">
            <!-- Fields on top of expansion panel -->
            <div class="row">
              <div class="col-md-4">
                <mat-form-field class="DSA_Wb_custom-form-field">
                  <input
                    matInput
                    placeholder="Date"
                    readonly
                    class="pointer"
                    [value]="interview.interviewSchedule.startDate | date"
                  />
                </mat-form-field>
              </div>

              <div class="col-md-4">
                <mat-form-field class="DSA_Wb_custom-form-field">
                  <input
                    matInput
                    placeholder="Interview Status"
                    readonly
                    class="pointer"
                    [value]="interview.interviewStatus"
                  />
                </mat-form-field>
              </div>
            </div>

            <!-- send back to panelist for review block -->
            <send-back-to-panelist
              [remarks]="sendBackToPanelistRemark.get(interview?.id)"
              (remarksChange)="
                sendBackToPanelistRemark.set(interview.id, $event)
              "
              [disabled]="
                interview.interviewStatus === 'SENT_FOR_PANELLIST_REVIEW' ||
                sendBacktoPanelistStatus.get(interview?.id) === true
              "
              (onSubmit)="panelistReview($event, interview.id)"
            >
            </send-back-to-panelist>

            <!-- panelist attendance block -->
            <panelist-participation-status
              [panelists]="interview.panelists"
              [interviewId]="interview.id"
            >
            </panelist-participation-status>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <!-- ! -->

  <!-- ! Upcoming Interviews -->
  <div class="row mb-2" *ngIf="upcomingInterviews?.length > 0">
    <div class="col-md-12">
      <mat-accordion class="DSA_wb-cus-accordion">
        <mat-expansion-panel
          *ngFor="let data of upcomingInterviews; let i = index"
        >
          <mat-expansion-panel-header
            [collapsedHeight]="'40px'"
            [expandedHeight]="'40px'"
          >
            <mat-panel-title> {{ data.title }} (Pending) </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="container-fluid marT16">
            <div
              class="d-flex justify-content-end"
              *ngIf="
                accountService.hasFeatureAccess(
                  FeatureName.CLOSE_CANDIDATURE,
                  PermissionType.UPDATE
                )
              "
            >
              Candidate didn't show up for interview? Mark as&nbsp;
              <a
                class="DSA_wb_hyperLink-btn"
                role="button"
                (click)="markInterviewAsNoShow(data.id)"
                matTooltip="Mark interview as No Show"
                matTooltipClass="DSA_tooltip"
              >
                <strong>No Show</strong>
              </a>
            </div>

            <panelist-participation-status
              [panelists]="data.panelists"
              [interviewId]="data.id"
            >
            </panelist-participation-status>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <!-- ! -->

  <div
    class="row marB16"
    *ngIf="
      !selectedForAnotherJob &&
      (evaluationData?.length > 0 || noShowInterviews?.length > 0) &&
      showActions
    "
  >
    <div class="col-md-12">
      <mat-form-field class="DSA_Wb_custom-form-field mb-2">
        <input
          matInput
          [(ngModel)]="remarks"
          placeholder="Remarks"
          [disabled]="
            ((interviewData?.result === 'SELECTED' ||
            interviewData?.result == 'REJECTED' ||
            interviewData?.result == 'REJECTION_UNIT_LEVEL' ||
            interviewData?.result == 'REJECTION_INFY_LEVEL') &&
              !accountService.hasFeatureAccess(FeatureName.REVIEW_FEEDBACK, PermissionType.CREATE)) ||
            eventStatus === 'CLOSED'
          "
          required
        />
        <mat-hint *ngIf="interviewData?.result === 'REVIEWED'">
          Reviewed by: {{ interviewData?.lastModifiedBy }}
        </mat-hint>
        <mat-hint
          *ngIf="interviewData?.result && interviewData?.result !== 'REVIEWED'"
        >
          Last modified status by: {{ interviewData?.lastModifiedBy }}
        </mat-hint>
      </mat-form-field>
      <span 
        class="font-red DSA_wb_mainBodyTxt2 DSA_wb_text-1line d-block marB16" 
        *ngIf="rejectionReasonSelectedByRecruiter">
          RAIL/RAUL reason: {{ rejectionReasonSelectedByRecruiter }}
      </span>
    </div>
    <div class="row" *ngIf="candidateSource === 'INTAP'">
      <div
        class="d-inline-block marL16"
        *ngFor="let status of subStatusArray; let index = i"
      >
        <div
          class="d-inline-block padR4 marB16"
          [matTooltip]="substatusDisableReason[status.value]"
          matTooltipClass="DSA_tooltip"
          [matTooltipDisabled]="!checkSubstatusDisabled(status.value)"
        >
          <button
            mat-flat-button
            (click)="markSubStatus(status.value)"
            [ngClass]="{
              'DSA_wb_miniPrimary-btn':
                interviewData?.subStatus === status.value,
              'DSA_wb_miniSecondary-btn':
                interviewData?.subStatus !== status.value
            }"
            [disabled]="checkSubstatusDisabled(status.value)"
            [matTooltip]="status.description"
            matTooltipClass="DSA_tooltip"
          >
            {{ status.label }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-3" *ngIf="recruiterResult">
    <mat-form-field class="DSA_Wb_custom-form-field">
      <input
        matInput
        placeholder="Previous Result"
        readonly
        class="pointer"
        [value]="recruiterResult"
      />
    </mat-form-field>
  </div>
  <div class="row mb-3" *ngIf="!selectedForAnotherJob">
    <div
      class="col-md-12"
      *ngIf="
        (evaluationData?.length > 0 || noShowInterviews?.length > 0) && showActions
      "
    >
      <div class="d-flex nomarL">
        <div class="col-md-3 nopadL">
          <mat-form-field class="DSA_Wb_custom-form-field" appearance="fill">
            <mat-label>Job Role:</mat-label>
            <mat-select
              [(ngModel)]="jobRoleSelectedFor"
              (selectionChange)="changeDropDownValue()"
              [disabled]="
                (!accountService.hasFeatureAccess(FeatureName.REVIEW_FEEDBACK, PermissionType.CREATE) &&
                  ['SELECTED', 'REJECTED','REJECTION_UNIT_LEVEL','REJECTION_INFY_LEVEL'].includes(interviewData?.result)) ||
                eventStatus === 'CLOSED'
              "
            >
              <mat-option *ngFor="let job of jobDropDown" [value]="job.id">
                {{ job.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Declaration -->
      <div class="w-100 marTB8 declaration">
        <mat-checkbox
          (change)="handleDeclarationChange($event)"
          class="DSA_Wb_custom-checkbox nomarLR"
          aria-label="checkbox"
          disableRipple="true"
          [checked]="isDeclarationAccepted"
        >
          {{
            experienceType === "LATERAL"
              ? declaration.lateral
              : declaration.fresher
          }}
        </mat-checkbox>
      </div>

      <button
        mat-flat-button
        (click)="handleFinalActionClick('REVIEWED')"
        [ngClass]="{
          'DSA_wb_primary-btn': interviewData?.result === 'REVIEWED',
          'DSA_wb_secondary-btn': interviewData?.result !== 'REVIEWED'
        }"
        *ngIf="
        accountService.hasFeatureAccess(FeatureName.REVIEW_FEEDBACK, PermissionType.CREATE)
        "
        [disabled]="
          interviewData?.result === 'SELECTED' ||
          interviewData?.result === 'REJECTED' ||
          interviewData?.result == 'REJECTION_UNIT_LEVEL' ||
          interviewData?.result == 'REJECTION_INFY_LEVEL' ||
          eventStatus === 'CLOSED'
        "
        class="marB16"
      >
        Submit
      </button>

      <div class="row marNLR4">
        <div class="d-inline-block">
          <div
            class="d-inline-block padR4 marB16"
            [matTooltip]="finalStatusDisableReason[FinalResultEnum.SELECTED]"
            matTooltipClass="DSA_tooltip"
            [matTooltipDisabled]="!isFinalActionDisabled(FinalResultEnum.SELECTED)"
          >
            <button
              mat-flat-button
              (click)="confirmSelectOrRejectDialog('SELECTED')"
              [ngClass]="{
                'DSA_wb_primary-btn': interviewData?.result === 'SELECTED',
                'DSA_wb_secondary-btn': interviewData?.result !== 'SELECTED'
              }"
              [disabled]="isFinalActionDisabled(FinalResultEnum.SELECTED)"
            >
              Select
            </button>
          </div>

          <!-- if candidate source is not intap or the current result is rejected even if source is intap  -->
          <div
            class="d-inline-block padLR4 marB16"
            *ngIf="candidateSource !== 'INTAP' || interviewData?.result === 'REJECTED'"
            [matTooltip]="finalStatusDisableReason[FinalResultEnum.REJECTED]"
            matTooltipClass="DSA_tooltip"
            [matTooltipDisabled]="!isFinalActionDisabled(FinalResultEnum.REJECTED)"
          >
            <button
              mat-flat-button
              (click)="confirmSelectOrRejectDialog('REJECTED')"
              [ngClass]="{
                'DSA_wb_primary-btn ml-2': interviewData?.result === 'REJECTED',
                'DSA_wb_secondary-btn ml-2':
                  interviewData?.result !== 'REJECTED'
              }"
              [disabled]="isFinalActionDisabled(FinalResultEnum.REJECTED)"
            >
              Reject
            </button>
          </div>
          <!-- if the source is intap and the current result is not rejected then show RAIL and RAUL 
          If there is no hr completed interview yet, then show RAUL, else show RAIL-->
          <div
            class="d-inline-block padLR4 marB16"
            *ngIf="
              candidateSource === 'INTAP' &&
              interviewData?.result !== 'REJECTED' &&
              !hrCompleted
            "
            [matTooltip]="finalStatusDisableReason[FinalResultEnum.REJECTION_UNIT_LEVEL]"
            matTooltipClass="DSA_tooltip"
            [matTooltipDisabled]="!isFinalActionDisabled(FinalResultEnum.REJECTION_UNIT_LEVEL)"
          >
            <button
              mat-flat-button
              (click)="confirmSelectOrRejectDialog('REJECTION_UNIT_LEVEL')"
              [ngClass]="{
                'DSA_wb_primary-btn ml-2':
                  interviewData?.result === 'REJECTION_UNIT_LEVEL',
                'DSA_wb_secondary-btn ml-2':
                  interviewData?.result !== 'REJECTION_UNIT_LEVEL'
              }"
              [disabled]="
                isFinalActionDisabled(FinalResultEnum.REJECTION_UNIT_LEVEL)
              "
            >
              Rejected at Unit Level
            </button>
          </div>
          <div
            class="d-inline-block padLR4 marB16"
            *ngIf="
              candidateSource === 'INTAP' &&
              interviewData?.result !== 'REJECTED' &&
              hrCompleted
            "
            [matTooltip]="finalStatusDisableReason[FinalResultEnum.REJECTION_INFY_LEVEL]"
            matTooltipClass="DSA_tooltip"
            [matTooltipDisabled]="!isFinalActionDisabled(FinalResultEnum.REJECTION_INFY_LEVEL)"
          >
            <button
              mat-flat-button
              (click)="confirmSelectOrRejectDialog('REJECTION_INFY_LEVEL')"
              [ngClass]="{
                'DSA_wb_primary-btn ml-2':
                  interviewData?.result === 'REJECTION_INFY_LEVEL',
                'DSA_wb_secondary-btn ml-2':
                  interviewData?.result !== 'REJECTION_INFY_LEVEL'
              }"
              [disabled]="
                isFinalActionDisabled(FinalResultEnum.REJECTION_INFY_LEVEL)
              "
            >
              Rejected at Infy Level
            </button>
          </div>
          <div
            class="d-inline-block padLR4 marB16"
            *ngIf="jobOpening === 'LATERAL'"
            [matTooltip]="finalStatusDisableReason[FinalResultEnum.ON_HOLD]"
            matTooltipClass="DSA_tooltip"
            [matTooltipDisabled]="!isFinalActionDisabled(FinalResultEnum.ON_HOLD)"
          >
            <button
              mat-flat-button
              (click)="handleFinalActionClick('ON_HOLD')"
              [ngClass]="{
                'DSA_wb_primary-btn ml-2': interviewData?.result === 'ON_HOLD',
                'DSA_wb_secondary-btn ml-2': interviewData?.result !== 'ON_HOLD'
              }"
              [disabled]="isFinalActionDisabled(FinalResultEnum.ON_HOLD)"
            >
              On Hold
            </button>
          </div>
          <div 
            class="d-inline-block padLR4 marB16"
            [matTooltip]="finalStatusDisableReason[FinalResultEnum.TO_BE_REINTERVIEWED]"
            matTooltipClass="DSA_tooltip"
            [matTooltipDisabled]="!isFinalActionDisabled(FinalResultEnum.TO_BE_REINTERVIEWED)"
          >
            <button
              mat-flat-button
              (click)="handleFinalActionClick('TO_BE_REINTERVIEWED')"
              [ngClass]="{
                'DSA_wb_primary-btn ml-2':
                  interviewData?.result === 'TO_BE_REINTERVIEWED',
                'DSA_wb_secondary-btn ml-2':
                  interviewData?.result !== 'TO_BE_REINTERVIEWED'
              }"
              [disabled]="
                isFinalActionDisabled(FinalResultEnum.TO_BE_REINTERVIEWED)
              "
            >
              Re-Interview
            </button>
          </div>
          <!-- ! no show removed as final status - added at interview level -->
          <!-- <div 
              class="d-inline-block padLR4 marB16"
              [matTooltip]="finalStatusDisableReason[FinalResultEnum.NO_SHOW]"
              matTooltipClass="DSA_tooltip"
              [matTooltipDisabled]="!isFinalActionDisabled(FinalResultEnum.NO_SHOW)"
            >
            <button
              mat-flat-button
              (click)="handleFinalActionClick('NO_SHOW')"
              [ngClass]="{
                'DSA_wb_primary-btn ml-2': interviewData?.result === 'NO_SHOW',
                'DSA_wb_secondary-btn ml-2': interviewData?.result !== 'NO_SHOW'
              }"
              [disabled]="isFinalActionDisabled(FinalResultEnum.NO_SHOW)"
            >
              No Show
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
