import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { jobOpeningFilterColumns } from 'src/app/app.constants';
import { Job } from 'src/app/shared/model/Job-model';
import { IPagination } from 'src/app/shared/model/pagination.model';
import { SharedService } from 'src/app/services/shared.service';
import { CandidateInfoService } from '../services/candidate-info.service';

@Component({
  selector: 'app-candidate-applications',
  templateUrl: './candidate-applications.component.html',
  styleUrls: ['./candidate-applications.component.scss'],
})
export class CandidateApplicationsComponent implements OnInit {
  searchedText: string;
  isLoading: boolean = false;
  isError: boolean = false;
  jobs: Job[] = [];
  page: number = 0;
  pageSize: number = 10;
  totalElements: number;
  filters;
  filterByColumns = jobOpeningFilterColumns;
  config: any;
  allJobs: any;
  totalJobs: number;
  emptyJobFilter;
  seekerId;
  jobId: any;
  noDataFound=false;
  tobeCancelledId;

  @ViewChild('secondDialog', { static: true }) secondDialog: TemplateRef<any>;

  constructor(
    private candidateJobsService: CandidateInfoService,
    private router: Router,
    private sharedService: SharedService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.seekerId = +sessionStorage.getItem("seekerId");
    this.getAllJobs();
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }

  viewJob(id) {
    this.router.navigate(['candidate/problem-statements', id]);
  }

  getApplicationsFilters = () => {
    return this.candidateJobsService.getApplicationsFilters();
  };

  getAllJobs = () => {
    this.isLoading = true;
    this.isError = false;
    if (!this.seekerId) {
      this.candidateJobsService.getCandidateProfileUIDTOForCandidate().subscribe({
        next: (res) => {
          if(res?.candidateInfoId){
            this.seekerId = res.candidateInfoId;
            sessionStorage.setItem("seekerId",String(this.seekerId));
            this.getApplications();
          }
          else {
            this.isLoading = false;
          }
        },
        error: (err) => {
          if (err?.status === 404) {
            this.isLoading = false
          }
          else {
            console.log(err);
            this.isError = true;
            this.isLoading = false;
            this.sharedService.openErrorSnackBar(
              'Something Went Wrong! Please try again!',
              ''
            );
          }
        }
      });
    } else this.getApplications();
  };
  getApplications() {
    this.candidateJobsService
      .getAllCandidateApplications(
        this.seekerId,
        this.page,
        this.pageSize,
        this.searchedText,
        this.filters
      )
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          this.jobs = res.content;
          this.totalElements = res.totalElements;
          //console.log(this.jobs);
        },
        error: (err) => {
          this.isError = true;
          this.isLoading = false;
          this.sharedService.openErrorSnackBar(
            'Some error occurred, please try again',
            ''
          );
        },
      });
  }
  handlePageChange = (e: IPagination) => {
    this.page = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getAllJobs();
  };
  clearSearch() {
    this.searchedText = '';
    this.getAllJobs();
  }
  searchRegex = /^[a-zA-Z0-9\s]*$/;
  searchJob(searchedText: string) {
    const isValid = this.searchRegex.test(this.searchedText);

    if (!isValid) {
      return;
    }
    this.page = 0;
    this.getAllJobs();
  }

  handleFilterChange = (e) => {
    console.log(e);
    this.filters = e;
    this.getAllJobs();
  };

  cancelApplication() {
    // console.log("Confirmation");
    this.isLoading = true;
    const formValue = {
      applicationStatus: 'WITHDRAWN',
    };
    this.candidateJobsService
      .updateJobApplication(this.tobeCancelledId, formValue)
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          this.sharedService.openErrorSnackBar(
            'Application has been successfully withdrawn',
            ''
          );
          //this.isLoading = false;
          this.getAllJobs();
        },
        error: (err) => {
          //this.isLoading = false;
          this.isLoading = false;
          this.sharedService.openErrorSnackBar(
            'some error occurred, please try again',
            ''
          );
        },
      });
  }
  cancelJob = (applicantionId) => {
    this.tobeCancelledId = applicantionId;
    console.log('TBC', this.tobeCancelledId);
    this.dialog.open(this.secondDialog);
  };

  // withdraw = (applicantId) => {
  //   const formValue = {
  //     applicationStatus: 'WITHDRAWN',

  //   };
  //   this.withdrawJob.emit(applicantId);

  //   this.candidateJobsService.updateJobApplication(applicantId,formValue).subscribe({
  //     next: (res) => {
  //       this.sharedService.openSuccessSnackBar(
  //         'Application Withdrawn successfully',
  //         ''
  //       );
  //     },
  //     error: (err) => {
  //       this.sharedService.openErrorSnackBar(
  //         'Some error occurred, please try again',
  //         ''
  //       );
  //     },
  // });
  // }
}
