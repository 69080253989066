import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { JobService } from 'src/app/services/job.service';
import { JobRole } from 'src/app/shared/model/job-role.model';
import { SharedService } from 'src/app/services/shared.service';
import { JobsAdminDashboardStateStorageService } from '../infy-jobs-admin/jobs-admin-dashboard-state-storage.service';
import { FormControl } from '@angular/forms';
import { lateralJobFeedbackFormTypes } from 'src/app/app.constants';
import { HiringManagerService } from '../../hiring-manager/services/hiring-manager.service';

@Component({
  selector: 'app-infy-job-evaluation-form',
  templateUrl: './infy-job-evaluation-form.component.html',
  styleUrls: ['./infy-job-evaluation-form.component.scss'],
})
export class InfyJobEvaluationFormComponent implements OnInit {
  selectedJob: JobRole;

  links = [
    { link: 'formio', label: 'FormIO' },
    // { link: 'surveys', label: 'Surveys' },
  ];

  isLoading: boolean = false;

  lateralFeedbackFormTypes = lateralJobFeedbackFormTypes;
  // activeFormTypeControl: FormControl = new FormControl();

  isUpdatingFormType: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public _location: Location,
    private jobService: JobService,
    private sharedService: SharedService,
    private jobsAdminStateStorageService: JobsAdminDashboardStateStorageService,
    private hiringManagerService: HiringManagerService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((parameters) => {
      let selectedJobId = parameters.jobId;
      if (selectedJobId) {
        // if job details are stored in service
        if (
          this.jobsAdminStateStorageService.selectedJob?.id ===
          parseInt(selectedJobId)
        ) {
          this.selectedJob = this.jobsAdminStateStorageService.selectedJob;
          // this.activeFormTypeControl.setValue(
          //   this.jobsAdminStateStorageService.selectedJob.feedbackFormType
          // );
        } else {
          // if job details are not stored in service
          this.isLoading = true;
          this.hiringManagerService
            .getOppurtunityByID(selectedJobId)
            .subscribe({
              next: (res: JobRole) => {
                this.selectedJob = res;
                this.jobsAdminStateStorageService.selectedJob = res;
                // this.activeFormTypeControl.setValue(res.feedbackFormType);
                this.isLoading = false;
              },
              error: (err) => {
                this.sharedService.openSnackBar(
                  'Some error occurred in finding scholarship',
                  '',
                  3000
                );
                this.isLoading = false;
              },
            });
        }
      }
    });
  }

  // updateActiveFeedbackFormType = () => {
  //   this.isUpdatingFormType = true;
  //   const payload = {
  //     ...this.selectedJob,
  //     feedbackFormType: this.activeFormTypeControl.value,
  //   };
  //   this.jobService.updateJobRole(payload).subscribe(
  //     (res: JobRole) => {
  //       this.selectedJob = res;
  //       this.jobsAdminStateStorageService.selectedJob = res;
  //       this.sharedService.openSnackBar('Updated Succesfully', '', 3000);
  //       this.isUpdatingFormType = false;
  //     },
  //     (err) => {
  //       this.sharedService.openSnackBar(
  //         'Some error occurred. Feedback form type not updated',
  //         '',
  //         3000
  //       );
  //       this.isUpdatingFormType = false;
  //     }
  //   );
  // };

  handleBackClick = () => {
    this._location.back();
  };
}
