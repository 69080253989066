<!-- <app-loader [display]="loading"></app-loader> -->

<!-- <div class="container-fluid layout" *ngIf="!loading"> -->
<div class="container-fluid layout">
  <div
    *ngIf="
      eventStatus !== 'CLOSED'
    " class="row mt-2">
    <div class="col-12 d-flex marB24">
      <div
      *ngIf = "accountService.hasFeatureAccess(FeatureName.EVENT_JOBS, PermissionType.CREATE)
       || accountService.hasFeatureAccess(FeatureName.BULK_UPLOAD_INTERVIEWS, PermissionType.CREATE)"
      class="col-3 nopadLR">
        <h2 class="DSA_wb_h2Header web-GreyDarkest padT6">
          Bulk Upload Interview Schedule
        </h2>
      </div>
      <div class="col-6 nopadLR">
        <div 
        *ngIf = "accountService.hasFeatureAccess(FeatureName.EVENT_JOBS, PermissionType.CREATE)
       || accountService.hasFeatureAccess(FeatureName.BULK_UPLOAD_INTERVIEWS, PermissionType.CREATE)" 
        class="col-12 nopadLR">
          <input
            type="file"
            (change)="bulkUpload(bulkUploadElement.files)"
            #bulkUploadElement
            accept=".csv"
            style="display: none"
          />

          <button (click)="confirmMSTeamsIncludedOrNot()" mat-flat-button class="DSA_wb_Icon-btn small-screen-marL">
            <span class="icon_btnCont">
              <i class="icon purple x-16 icon-add"></i>
            </span>
            Create Multiple Interviews
          </button>
          <button *ngIf="bulkuploadFile" (click)="uploadBulkCSV()" class="ml-2 custom-button">
            Upload
          </button>
        </div>
        <div class="col-12 nopadLR" *ngIf="bulkuploadFile">
          <p class="mat-body">
            <strong>Selected File:</strong> {{ bulkuploadFile.name }}
          </p>
        </div>
      </div>
      <div class="col-3 d-flex justify-content-end nopadR">
        <button
          *ngIf="
          (accountService.
          hasFeatureAccess(FeatureName.EVENT_JOBS, PermissionType.CREATE))
          && 
            eventStatus !== 'CLOSED'
          " mat-flat-button class="DSA_wb_Icon-btn" (click)="openAddJobDialog()">
          <span class="icon_btnCont"><i class="icon purple x-16 icon-add"></i></span>
          Add Scholarship
        </button>
      </div>
      <!-- </div> col-12 d-flex marB24 Ends here -->

    </div>
    
  </div> <!--  Authentication div ends here -->
  <data-grid [columnDefs]="columnDefs" [tableName]="tableName" [buttonText]="buttonText"
    [buttonComponent]="buttonComponent" [levels]="levels" [buttonCondition]="buttonCondition"
    [searchCondition]="searchCondition" [interface]="interface" [refreshAgGrid]="refreshAgGrid.asObservable()"
    [pageNumber]="tablePageNumber" [pageSize]="pageSize" (pageChangeValue)="onPageChange($event)"></data-grid>
</div>