import { RecruiterCandidateListStateStorageService } from '../../archive/infy-recruiter-candidate-list/recruiter-candidate-list-state-storage.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { RecruiterDashboardStateStorageService } from './recruiter-dashboard-state-storage.service';
import { FormBuilder } from '@angular/forms';
import { InfyJobsStateStorageService } from '../infy-jobs/infy-jobs-state-storage.service';
import { JobService } from 'src/app/services/job.service';
import { EventService } from 'src/app/services/event.service';
import { AccountService } from 'src/app/services/account.service';
import { linkRendererComponent } from 'src/app/shared/components/ag-grid-renderer/linkRenderer.component';
import { Observable, Subject } from 'rxjs';
import { InfyCopyEventComponent } from '../infy-copy-event/infy-copy-event.component';
import { SharedService } from 'src/app/services/shared.service';
import { GenerateReportComponent } from '../generate-report/generate-report.component';
import { ReportGenerationService } from 'src/app/services/report-generation.service';
import { FileService } from 'src/app/services/file.service';
import * as fileSaver from 'file-saver';
import { actionRendererComponent } from 'src/app/shared/components/ag-grid-renderer/actionRenderer.component';
import { CreateEventComponent } from 'src/app/modules/archive/create-event/create-event.component';
import { RecruiterDashboardRendererComponent } from 'src/app/modules/iwp-event/infy-recruiter-dashboard/recruiter-dashboard/recruiter-dashboard-action-renderer.component';
import { JobsAdminDashboardStateStorageService } from '../../iwp-platform-actions/infy-jobs-admin/jobs-admin-dashboard-state-storage.service';
import { InterviewListStateStorageService } from '../infy-interview-list/interview-list-state-storage.service';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
import { EvaluationTemplateListStateStorageService } from '../../iwp-formio/evaluation-templates/evaluation-templates-list/evaluation-template-list-state-storage.service';
// import { EvaluationTemplateListStateStorageService } from 'src/app/evaluation-templates/evaluation-templates-list/evaluation-template-list-state-storage.service';

@Component({
  selector: 'app-infy-recruiter-dashboard',
  templateUrl: './infy-recruiter-dashboard.component.html',
  styleUrls: ['./infy-recruiter-dashboard.component.scss'],
})
export class InfyRecruiterDashboardComponent implements OnInit {
  links = ['Upcomming', 'Inprogress', 'Completed'];
  activeLink = this.links[0];

  link: string = '';

  bulkuploadFile;
  refreshAgGrid: Subject<void> = new Subject<void>();
  tableData: any;
  tablePageNumber = 1;
  loading = false;
  associateEventString: any = '';
  pageTitle: string = 'Events';
  pageSize: number = 10;
  gridApi: any;
  gridColumnApi: any;
  pageNumber: number = 0;
  requisitionId: any;
  page = 0;
  size = 10;
  queryEventName = '';
  totalElements = 0;

  generateReportLoader = false;
  reportIntervalId;
  downloadReport;
  FeatureName = FeatureName;
  PermissionType = PermissionType;

  routeLinkData = [
    {
      link: 'jobs',
      hide: !this.accountService.hasFeatureAccess(
        FeatureName.EVENT_JOBS,
        PermissionType.READ
      ),
    },
    {
      link: 'panelists',
      hide: !this.accountService.hasFeatureAccess(
        FeatureName.EVENT_PANELIST,
        PermissionType.READ
      ),
    },
    {
      link: 'recruiters',
      hide: !this.accountService.hasFeatureAccess(
        FeatureName.EVENT_RECRUITER,
        PermissionType.READ
      ),
    },
    {
      link: 'interviews',
      hide: false,
    },
  ];
  tableName: string = 'Events';
  buttonText: string = 'Create Event';
  buttonComponent: any = CreateEventComponent;
  levels: number = 1;
  buttonCondition: boolean =
    this.accountService
      .getAccountLocal()
      .authorities.includes('ROLE_EVENT_ADMIN') ||
    this.accountService
      .getAccountLocal()
      .authorities.includes('ROLE_ADMIN_RECRUITER');
  searchCondition: boolean = true;

  columnDefs: any = [
    // {
    //   headerName: 'Status',
    //   field: 'status',
    //   valueGetter: this.statusGetter,
    //   hide: false,
    //   sortable: false,
    // },
    {
      headerName: 'Select',
      field: 'Select',
      required: true,
      hide: false,
      cellRendererFramework: RecruiterDashboardRendererComponent,
      cellRendererParams: { type: 'checkbox', condition: true },
      maxWidth: 60,
    },

    { headerName: 'Start Date', field: 'fromDate', hide: false },
    { headerName: 'End Date', field: 'toDate', hide: false },
    {
      headerName: 'Event Name',
      field: 'name',
      hide: false,
      required: true,
      valueFormatter: this.linkFormatter,
      cellRendererFramework: linkRendererComponent,
      cellRendererParams: {
        message: 'View Event Page',
        data: this.routeLinkData,
      },
      minWidth: 400,
    },
    {
      headerName: 'Create Association',
      hide: false,
      required: true,
      cellRendererFramework: RecruiterDashboardRendererComponent,
      cellRendererParams: {
        type: 'icon-add',
        condition: this.router.url.split('/')[4] == 'all-events',
      },
    },
    {
      headerName: 'Location(s)',
      field: 'locations',
      hide: false,
      cellRendererFramework: RecruiterDashboardRendererComponent,
      cellRendererParams: { type: 'location', condition: true, minWidth: 100 },
    },
    { headerName: 'Created By', field: 'createdBy', hide: true },
    {
      headerName: 'Created Date',
      field: 'createdDate',
      hide: true,
      type: 'date',
    },
    { headerName: 'Event Status', field: 'eventStatus', hide: false },
    {
      headerName: 'Event Type',
      field: 'eventType',
      suppressSizeToFit: false,
      hide: true,
    },
    {
      headerName: 'ID',
      field: 'id',
      hide: true,
      filter: 'agNumberColumnFilter',
    },
    { headerName: 'Last Modified By', field: 'lastModifiedBy', hide: true },
    {
      headerName: 'Last Modified Date',
      field: 'lastModifiedDate',
      hide: true,
      type: 'date',
    },
    {
      headerName: 'Copy Event',
      field: 'copy',
      hide: false,
      cellRendererFramework: actionRendererComponent,
      cellRendererParams: {
        icon: 'copy',
        condition: this.accountService.hasFeatureAccess(
          FeatureName.EVENT,
          PermissionType.CREATE
        ),
      },
      maxWidth: 100,
    },
  ];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private recruiterCandidateListStateStorageService: RecruiterCandidateListStateStorageService,
    private recruiterDashboardStateStorageService: RecruiterDashboardStateStorageService,
    private infyJobsStateStorageService: InfyJobsStateStorageService,
    private jobsAdminDashboardStateStorageService: JobsAdminDashboardStateStorageService,
    private interviewListStateStorage: InterviewListStateStorageService,
    private jobService: JobService,
    private eventService: EventService,
    public accountService: AccountService,
    private sharedService: SharedService,
    private reportGenerationService: ReportGenerationService,
    private fileService: FileService,
    private activatedRoute: ActivatedRoute,
    private evalTemplateListStateStorageService: EvaluationTemplateListStateStorageService
  ) {}

  interface = (
    size: number,
    page: number,
    query?: string
  ): Observable<any[]> => {
    return this.eventService.getEvents(
      size,
      page,
      query,
      this.requisitionId,
      this.associateEventString
    );
  };

  ngOnInit(): void {
    console.log('check string');
    this.associateEventString = this.router.url.split('/')[4];
    console.log(this.associateEventString);

    this.requisitionId = this.router.url.split('/')[3];
    if (this.associateEventString == 'associated-events') {
      console.log('first associated');
      this.pageTitle = 'Associated Events';
    }

    this.recruiterCandidateListStateStorageService.init();
    this.infyJobsStateStorageService.init();
    this.jobsAdminDashboardStateStorageService.init();
    this.interviewListStateStorage.init();
    localStorage.removeItem('eventStatus');
    localStorage.removeItem('eventPagePrevLink');
    this.jobService.clearSelectedJob();
    if (
      this.recruiterDashboardStateStorageService.page &&
      this.recruiterDashboardStateStorageService.size
    ) {
      this.page = this.recruiterDashboardStateStorageService.page;
      this.size = this.recruiterDashboardStateStorageService.size;
    }
    if (this.recruiterDashboardStateStorageService.queryEventName) {
      this.queryEventName = this.recruiterDashboardStateStorageService.queryEventName;
    }
  }

  statusValue(params: any) {
    return params.data.eventStatus ? params.data.eventStatus : '';
  }

  statusGetter(params: any) {
    if (!params.data) return 'Loading...';
    let start = params.data.fromDate;
    let end = params.data.toDate;
    let current = new Date().toISOString().split('T', 1)[0];
    if (end >= current && start <= current) {
      return 'In Progress';
    } else if (end < current) {
      return 'Completed';
    } else if (start > current) {
      return 'Upcoming';
    }
    return current;
  }

  linkFormatter(params: any) {
    // console.log(params);
    if (!params.data) return '';

    // console.log(params.colDef.cellRendererParams.data);

    let firstEnableLink = params.colDef.cellRendererParams.data.find(
      (e) => e.hide == false
    );
    if (firstEnableLink) {
      return '/create-event/' + params.data.id + '/' + firstEnableLink.link;
    }
    // return '/create-event/' + params.data.id ;
    // return '/create-event/' + params.data.id + '/jobs';
  }

  navigate = (url, status) => {
    this.recruiterDashboardStateStorageService.clearAllCheckedEvents();
    localStorage.setItem('eventStatus', status ? status : '');
    this.recruiterDashboardStateStorageService.queryEventName = this.queryEventName;
    this.recruiterDashboardStateStorageService.page = this.page;
    this.recruiterDashboardStateStorageService.size = this.size;
    this.router.navigate(url);
  };

  copyEvent = (eventId) => {
    const dialogRef = this.dialog.open(InfyCopyEventComponent, {
      data: { id: eventId },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });
  };

  pageChange = (e) => {
    this.size = e.pageSize;
    this.page = e.pageIndex;
    this.loading = true;
  };

  onPageChange = (e) => {
    this.recruiterDashboardStateStorageService.page = e.target.page;
    this.recruiterDashboardStateStorageService.size = e.target.size;
    this.recruiterDashboardStateStorageService.queryEventName =
      e.target.searchQuery;
  };

  // ! method to toggle checkbox
  checkboxToggle = (event, id) => {
    if (event.checked) {
      if (!this.recruiterDashboardStateStorageService.getCheckedStatus(id)) {
        this.recruiterDashboardStateStorageService.addCheckedEvent(id);
      }
    } else {
      this.recruiterDashboardStateStorageService.removeCheckedEvent(id);
    }
  };

  getEventCheckedStatus = (id) => {
    return this.recruiterDashboardStateStorageService.getCheckedStatus(id);
  };

  generateReport = () => {
    if (
      this.recruiterDashboardStateStorageService.getCheckedEventsSize() === 0
    ) {
      this.sharedService.openSnackBar(
        'Please select events using the checkboxes to generate consolidated report.',
        '',
        3000
      );
    } else {
      const dialogRef = this.dialog.open(GenerateReportComponent, {
        data: { text: '* Mandatory', reportType: 'CONSOLIDATED' },
        backdropClass: 'backdrop-background',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          // this.loader = true;
          this.reportGenerationService
            .generateReportForEvent(
              this.recruiterDashboardStateStorageService.getAllCheckedEvents(),
              result['startDate'],
              result['endDate']
            )
            .subscribe(
              (res: any) => {
                // this.loader = false;
                this.generateReportLoader = true;
                if (res.status === 'PROCESSING') {
                  this.sharedService.openSnackBar(
                    'Report Generation in Progress',
                    '',
                    3000
                  );
                  this.reportIntervalId = setInterval(() => {
                    this.reportGenerationService
                      .reportStatus(res.reqId)
                      .subscribe((res: any) => {
                        if (res.status === 'COMPLETED') {
                          this.fileService
                            .getFile(res.fileId)
                            .subscribe((res) => {
                              let temp = res.headers
                                .get('content-disposition')
                                .split('filename=')[1]
                                .split(';')[0];
                              let temp1 = temp.substring(1, temp.length - 1);
                              this.downloadReport = {
                                blob: res.body,
                                fileName: temp1,
                              };
                              this.generateReportLoader = false;
                            });
                          this.sharedService.openSnackBar(
                            'Report Generated',
                            '',
                            3000
                          );
                          clearInterval(this.reportIntervalId);
                          this.recruiterDashboardStateStorageService.clearAllCheckedEvents();
                        } else if (res.status === 'FAILED') {
                          this.generateReportLoader = false;
                          this.sharedService.openSnackBar(
                            'Generation Failed',
                            '',
                            3000
                          );
                          clearInterval(this.reportIntervalId);
                        }
                      });
                  }, 20000);
                } else if (res.status === 'COMPLETED') {
                  this.fileService.getFile(res.fileId).subscribe((res) => {
                    let temp = res.headers
                      .get('content-disposition')
                      .split('filename=')[1]
                      .split(';')[0];
                    let temp1 = temp.substring(1, temp.length - 1);
                    this.downloadReport = { blob: res.body, fileName: temp1 };
                    this.generateReportLoader = false;
                  });
                  this.sharedService.openSnackBar(
                    'Generation Completed',
                    '',
                    3000
                  );
                  this.recruiterDashboardStateStorageService.clearAllCheckedEvents();
                } else if (res.status === 'FAILED') {
                  this.generateReportLoader = false;
                  this.sharedService.openSnackBar(
                    'Generation Failed',
                    '',
                    3000
                  );
                }
                // fileSaver.saveAs(
                //   res,
                //   'Report_Job_' + this.selectedJob.id + '.xlsx'
                // );
              },
              () => {
                // this.loader = false;
              }
            );
        }
      });
    }
  };
  saveReport = () => {
    fileSaver.saveAs(this.downloadReport.blob, this.downloadReport.fileName);
  };
}
