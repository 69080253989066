import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataTransferService } from 'src/app/modules/hiring-manager/services/data-transfer.service';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
})
export class FilterDialogComponent implements OnInit {
  showFrequency;
  loader = false;
  filterCriteriaGetApi;
  filterByColumns;
  searchMap: any = {};

  filterData: any = {};
  tags:any;

  filterDataAvailable: boolean = false;
  errorMessage: string = 'No filter criteria available.';
  constructor(
    private dataTransferService: DataTransferService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

  ngOnInit(): void {
    this.showFrequency = this.data.frequencyShow;
    this.tags = this.dataTransferService.tagsData;
    this.loader = true;
    this.filterCriteriaGetApi = this.data.filterCriteriaGetApi;
    this.filterByColumns = this.data.filterByColumns;
    if (this.data.filterState) {
      this.filterData = this.data.filterState;
    }
    this.getFilterCriteria();
  }

  getFilterCriteria() {
    this.filterCriteriaGetApi().subscribe(
      (res: any) => {
        Object.keys(res.searchAggreationMap).forEach((key) => {
          // in filter for verifier only showing the tags for verifier
          if(localStorage.getItem('currentRole')=='ROLE_VERIFIER' && key == 'tags'){
            this.searchMap[key] = [];
                for(let i=0;i<this.tags.length;i++){
                  if(this.tags[i].metadata == 'ROLE_VERIFIER'){
                      for(let j=0;j<res.searchAggreationMap[key].length;j++){
                        if(this.tags[i].value == res.searchAggreationMap[key][j].value){
                          this.searchMap[key].push(res.searchAggreationMap[key][j]);
                        }
                      }
                  }
                }
          }
          else{
              this.searchMap[key] = res.searchAggreationMap[key].filter(
                (_) => _.value !== ''
              );
          }
          if (!this.filterDataAvailable && this.searchMap[key].length > 0) {
            this.filterDataAvailable = true;
          }
        });
        // console.log(this.searchMap)
        this.loader = false;
        // console.log(this.loader);
      },
      (err) => {
        this.loader = false;
        this.errorMessage = 'Some error occurred. Please try again later.';
      }
    );
  }

  manageFilters(e, key, value) {
    if (e.checked) {
      if (this.filterData[key]) {
        this.filterData[key].push(value);
      } else {
        this.filterData[key] = [];
        this.filterData[key].push(value);
      }
    } else {
      let currentFilters = this.filterData[key];
      if (currentFilters?.includes(value)) {
        currentFilters = currentFilters.filter((_) => _ !== value);
      }
      if (currentFilters.length > 0) this.filterData[key] = currentFilters;
      else delete this.filterData[key];
    }
  }
  getToolTip(key: string, value: string, frequency: number) {
    let result = '';
    if (key === 'spoc') {
      result += value;
    } else {
      result += value.split('_').join(' ');
    }
    if (frequency > 0) result += ' (' + frequency + ')';
    return result;
  }
}
