import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { FormMsService } from 'src/app/services/form-ms.service';
import { SharedService } from 'src/app/services/shared.service';
import { TemplateEvaluationFormDTO } from 'src/app/shared/model/form-ms.model';

@Component({
  selector: 'app-search-template-input',
  templateUrl: './search-template-input.component.html',
  styleUrls: ['./search-template-input.component.scss'],
})
export class SearchTemplateInputComponent implements OnInit {
  searchControl = new FormControl();
  availableTemplateObs: Observable<TemplateEvaluationFormDTO[]>;
  selectedTemplate: TemplateEvaluationFormDTO;

  isFetchingTemplates: boolean = false;

  @Input() placeholder: string = 'Enter template name';
  @Input() isFinalized: boolean;
  @Input() formType: string;
  @Input() hint: string = 'Enter template name to search';
  @Output() onTemplateSelect = new EventEmitter<TemplateEvaluationFormDTO>();

  constructor(
    private formMsService: FormMsService,
    private sharedService: SharedService
  ) {}

  subscribeSearchControlValueChange = () => {
    this.availableTemplateObs = this.searchControl.valueChanges.pipe(
      // tslint:disable-next-line: deprecation
      startWith(''),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((template) => {
        if (!this.searchControl.touched && this.searchControl.invalid) {
          this.searchControl.markAsTouched();
        }
        // block API call if input value is not valid
        if (this.searchControl.invalid) {
          return of({});
        }
        this.isFetchingTemplates = true;
        return template.length > 1
          ? this.formMsService
              .getEvaluationTemplates(0, 2000, template, {
                isFinalized: this.isFinalized,
                formType: this.formType,
              })
              .pipe(
                catchError((err) => {
                  this.isFetchingTemplates = false;
                  this.sharedService.openSnackBar(
                    'Some error occurred in finding template. Please try again.',
                    '',
                    3000
                  );
                  return of({});
                })
              )
          : of({});
      }),
      map((result: any) => {
        let searchResults = result.content;
        this.isFetchingTemplates = false;
        return searchResults ? searchResults.slice(0, 10) : [];
      })
    );
  };

  ngOnInit(): void {
    this.subscribeSearchControlValueChange();
  }

  handleTemplateSelect = (event: MatAutocompleteSelectedEvent) => {
    // to get formJSON of selected template
    this.isFetchingTemplates = true;
    this.formMsService.getTemplateById(event.option.value?.id).subscribe({
      next: (res: TemplateEvaluationFormDTO) => {
        this.selectedTemplate = res;
        this.onTemplateSelect.emit(this.selectedTemplate);
        this.isFetchingTemplates = false;
      },
      error: (err) => {
        this.sharedService.openSnackBar(
          'Some error occurred in fetching form',
          '',
          3000
        );
        this.isFetchingTemplates = false;
      },
    });
  };

  displayFn(template: TemplateEvaluationFormDTO): string {
    return template && template.name ? template.name : '';
  }
}
