import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ComponentRef,
  EventEmitter,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CandidateProfileService } from '../candidate-profile.service';
import { CandidateInfoService } from '../../services/candidate-info.service';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FileService } from 'src/app/services/file.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SharedService } from 'src/app/services/shared.service';
import { FormioComponent } from 'angular-formio';
import { ActivatedRoute, Router } from '@angular/router';
import * as fileSaver from 'file-saver';
import { formioMappingsMap } from 'src/app/app.constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-synopsis-template',
  templateUrl: './synopsis-template.component.html',
  styleUrls: ['./synopsis-template.component.scss'],
})
export class SynopsisTemplateComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  options: any;
  formRenderingError: string;
  isFormLoading: boolean = true;
  submission: { data: any };
  submitting: boolean = false;
  basicForm: any;
  feedbackstatus: string = 'PARTIAL';
  basicDetailsForm!: any;
  basicDetailsApplicationForm:any ={};
  evaluationFormId;
  localStorageKey: string;
  displaySubmit: boolean = false;
  refreshEmitter: EventEmitter<any> = new EventEmitter<any>();
  wizard: any;
  observables: Observable<any>[];
  opportunityId: any;
  synposisId: any;
  applicationStatus: any;
  applicationSubStatus: any;
  spinner: Boolean = true;
  show: Boolean = true;
  templateFileId = null;
  docBlob: any;
  inputElement;
  div: any;
  vbutton: any;
  waitSpinner: any;
  dbutton: HTMLDivElement;
  filteredApplicationFiles = [];
  applicationFiles = [];
  formioMappings = formioMappingsMap;
  resetDisabled: boolean =false;
  constructor(
    private candidateProfileService: CandidateProfileService,
    private candidateInfoService: CandidateInfoService,
    private fileservice: FileService,
    public sharedService: SharedService,
    private router: ActivatedRoute,
    private route: Router,
    private http: HttpClient
  ) {
    if (
      !this.candidateProfileService.applicationId ||
      !this.candidateProfileService.opportunityId
    ) {
      this.route.navigate(['/candidate/problem-statements']);
    }
    this.options = {
      fileService: {
        uploadFile: async (
          storage: any,
          file: any,
          fileName: string,
          dir: string,
          evt: any,
          url: string
        ) => {
          let formData = new FormData();
          let FileData = {
            name: file?.name,
            size: file?.size,
            type: file?.type,
            fileId: null,
          };
          let applicationId;
          if(localStorage.getItem('applicationId')!==null){
            applicationId=parseInt(localStorage.getItem('applicationId'));
          }else{
             applicationId =this.candidateProfileService.applicationId;
          }
          let specData = {
            applicationId: applicationId,
            comment: '',
            tenantId: parseInt(localStorage.getItem('tenantId')),
            fileCategory: url,
          };
          if (this.validateUploadFile(url) != -1) {
            specData['applicationFileIdToReplace'] =
              this.validateUploadFile(url);
          }

          formData.append('specData', JSON.stringify(specData));
          formData.append('file', file);

          let dataUrl =
            environment.POSTMS_API +
            '/application-files/application/' +
            applicationId;
          try {
            let result = await this.http.post(dataUrl, formData).toPromise();
            FileData.fileId = result['id'];
            this.sharedService.openSuccessSnackBar('Uploaded Successfully', '');
            return FileData;
          } catch (error) {
            this.sharedService.openErrorSnackBar('Upload Failed', '');
          }
        },
        deleteFile: async (fileInfo: any) =>
          this.fileservice.deleteFile(fileInfo),
        downloadFile: async (fileInfo: any) =>
          this.fileservice.downloadFile(fileInfo),
      },
    };
    this.localStorageKey =
      this.candidateProfileService.applicationId + this.opportunityId+this.candidateProfileService.uniqueKeyValue;
    this.opportunityId = this.router.snapshot.paramMap.get('jobid');
    this.observables = [
      this.candidateInfoService
        .getSynopsisDetailsForm(this.candidateProfileService.opportunityId)
        .pipe(catchError((error) => of(error))),
    ];
    if (this.candidateProfileService.applicationId) {
      this.observables.push(
        this.candidateInfoService
          .getSynopsisForm(
            this.candidateProfileService.opportunityId,
            this.candidateProfileService.applicationId
          )
          .pipe(catchError((error) => of(error)))
      );
      this.observables.push(
        this.candidateProfileService.applicationId
          ? this.candidateInfoService
              .getApplicationStatus(this.candidateProfileService.applicationId)
              .pipe(catchError((error) => of(error)))
          : of([])
      );
    }
  }
  ngAfterViewInit(): void {}

  ngOnInit(): void {
    localStorage.setItem(
      'applicationId',
      JSON.stringify(this.candidateProfileService.applicationId)
    );
    forkJoin(this.observables).subscribe((res) => {
      res?.forEach((f, index) => {
        if (f instanceof HttpErrorResponse) {
          if (index == 0) {
            this.isFormLoading = false;
            this.formRenderingError =
              'Something went wrong while rendering the form. Please try again later.';
            this.sharedService.openErrorSnackBar(this.formRenderingError, '');
          } else if (index == 1) {
            this.isFormLoading = false;
          } else {
            this.isFormLoading = false;
          }
        } else {
          if (index == 0) this.setFormData(f);
          else if (index == 1) this.setSubmission(f);
          else this.setApplicationStatus(f);
        }
      });
    });
  }
  ngOnDestroy(): void {
    localStorage.removeItem('applicationId');
  }
  setFormData(res): void {
    let latest = res.content.length ? res.content?.length : 0;
    if (latest >= 1) {
      latest = res.content?.length - 1;
    }
    if (!res.content[latest]?.templateEvaluationForm?.formData) {
      this.formRenderingError =
        'Something went wrong while rendering the form. Please try again later.';
    }
    // console.log(res);
    this.basicDetailsForm = res.content[latest]?.templateEvaluationForm;
    this.evaluationFormId = res.content[latest]?.id;
    this.basicForm = this.basicDetailsForm;
  }

  setSubmission(res): void {
    if (res) {
      this.synposisId = res?.id;
      this.submission = {
        data: res?.feedbackData?.data,
      };
      this.feedbackstatus = res?.feedbackStatus;
    }
  }
  setApplicationStatus(res) {
    if (res) {
      this.applicationStatus = res?.status;
      this.applicationSubStatus = res?.subStatus;
      this.applicationFiles = res?.applicationFiles;
      this.filteredApplicationFiles = [];
      this.filteredApplicationFiles = this.filterByMaxId(this.applicationFiles);
      this.filteredApplicationFiles.forEach((file) => {
        let val = this.formioMappings.get(file?.category);
        if (
          file?.applicationFileStatus === 'SENT_BACK' &&
          this.submission.data?.val
        ) {
          this.submission.data[val] = [];
        }
      });
    }
    this.isFormLoading = false;
  }

  handleRefresh = () => {
    // console.log('refresh');
    this.refreshEmitter.emit({
      submission: {
        data: this.basicDetailsApplicationForm?.feedbackData?.data,
      },
    });
  };

  onFormReady = (e: FormioComponent) => {
    this.wizard = e.formio;
    const component = this.wizard.getComponent('html');
    this.inputElement = component.getElement();
    this.div = document.createElement('div');
    this.div.className = 'col-12 d-flex download';
    this.vbutton = document.createElement('div');
    this.vbutton.innerHTML = `
          <button
          mat-flat-button
          class="DSA_wb_Icon-btn marL8"
          id="verification-letter"
        >
          <span class="icon_btnCont"
            ><i class="icon x-16 download-icon"></i
          ></span> 
          Verification Letter Template
        </button>
    `;
    this.waitSpinner = document.createElement('span');
    this.waitSpinner.className = 'd-flex gap';
    this.waitSpinner.innerHTML = `
        <div class="spinner-container">
           <div class="spinner"></div>
        </div>
        <p class="txt">Generating template...</p>
    `;
    this.dbutton = document.createElement('div');
    this.dbutton.innerHTML = `
          <button
          mat-flat-button
          class="DSA_wb_Icon-btn marL8"
        >
          <span class="icon_btnCont"
            ><i class="icon x-16 download-icon"></i
          ></span>
          Download 
    `;
    if (this.show) {
      this.div.appendChild(this.vbutton);
    }
    this.vbutton.addEventListener('click', () => {
      this.handleGenerateClick();
    });
    this.dbutton.addEventListener('click', () => {
      this.downloadFile();
    });
    // if (this.applicationSubStatus !== 'SYNOPSIS_SUBMITTED') {
      this.inputElement.appendChild(this.div);
    // }

    if (
      this.applicationStatus === 'SENT_BACK_TO_CANDIDATE' &&
      this.filteredApplicationFiles.length > 0
    ) {
      this.filteredApplicationFiles.forEach((file) => {
        let val = this.formioMappings.get(file?.category);
        const component = this.wizard.getComponent(val);
        let inputElement;
        if (component) inputElement = component.getElement();
        if (inputElement) {
          const div = document.createElement('div');
          const elements = inputElement.getElementsByTagName('label');
          if (
            file?.applicationFileStatus === 'VERIFIED' ||
            file?.applicationFileStatus === 'REJECTED'
          ) {
            div.innerHTML =
              file?.applicationFileStatus === 'VERIFIED'
                ? 'VERIFIED'
                : 'REJECTED';
            inputElement.classList.toggle('disabled');
          } else {
            div.innerHTML =
              file?.applicationFileStatus === 'SENT_BACK'
                ? 'RE-UPLOAD'
                : file?.applicationFileStatus;
            if (file?.applicationFileStatus === 'PENDING') {
              div.innerHTML = 'TO BE VERIFIED';
              inputElement.classList.toggle('disabled');
            }
          }
          let tabClass = this.getTabLabelClass(file?.applicationFileStatus);
          const divWrapper = document.createElement('div');
          divWrapper.classList.toggle('d-flx');
          div.className = `${tabClass} custom-chip`;
          inputElement.appendChild(div);
          elements[0].insertAdjacentElement('afterend', div);
          const parentElement = elements[0].parentNode;
          parentElement.insertBefore(divWrapper, elements[0]);
          divWrapper.appendChild(elements[0]);
          divWrapper.appendChild(div);

          if (file?.applicationFileStatus === 'SENT_BACK') {
            const spanWrapper = document.createElement('div');
            let cnt = 0;
            this.applicationFiles.forEach((f) => {
              if (f?.category === file?.category) {
                cnt++;
              }
            });
            if (cnt >= 1) {
              spanWrapper.innerHTML = ` 
          <p class="form-font-info dynamic-para marT4">${cnt} document(s) are previously uploaded</p>  
          <p class="red form-font-label">Reviewer Comments:</p>
          <p class="marL4 form-font-info">${file?.comments}</p>`;
            } else {
              spanWrapper.innerHTML = ` 
            <p class="red form-font-label">Reviewer Comments:</p>
            <p class="marL4 form-font-info">${file?.comments}</p>`;
            }
            inputElement.appendChild(spanWrapper);
          }
        }
      });
    }
  };

  handleResetClick = () => {
    this.refreshEmitter.emit({
      submission: {
        data: {},
      },
    });
  };

  createdFormOnChange = (event: any) => {
    if (event.data) {
      console.log(this.wizard?.checkValidity(event.data));
      if (!event.isModified) {
        this.submitting = this.wizard
          ? this.wizard?.checkValidity(event.data)
          : false;
        if (
          event.changed &&
          event.changed.instance &&
          event.changed.instance.type === 'file' 
          // &&
          // this.applicationSubStatus !== 'SYNOPSIS_SUBMITTED'
        ) {
          this.autoSaveInDB('').subscribe({
            next: (res) => {},
            error: (error) => {},
          });
        }
        if (this.wizard?.checkValidity(event.data)) {
          this.basicDetailsApplicationForm = {
            feedbackData: {
              data: event.data,
            },
            isValid: this.wizard?.checkValidity(event.data) ? true : false,
          };
        }
      } else {
        this.basicDetailsApplicationForm = {
          feedbackData: {
            data: event.data,
          },
          isValid: this.wizard?.checkValidity(event.data) ? true : false,
        };
        // console.log(this.formIOInstance?.checkValidity(event.data))
        if (this.basicDetailsApplicationForm?.isValid) this.submitting = true;
        else this.submitting = false;
        if (
          event.changed &&
          event.changed.instance &&
          event.changed.instance.type === 'file' 
          // &&
          // this.applicationSubStatus !== 'SYNOPSIS_SUBMITTED'
        ) {
          this.autoSaveInDB('').subscribe({
            next: (res) => {},
            error: (error) => {},
          });
        }
      }
    }
  };

  onAutoSaveInDB = () => {
    this.sharedService.openSuccessSnackBar('Saved Successfully', '');
  };
  autoSaveInDB = (storeItem: any): Observable<any> => {
    // console.log(this.basicDetailsApplicationForm);
    this.basicDetailsApplicationForm.interactionGroupingType = 'SYNOPSIS';
    if (this.candidateProfileService.getPartialId() != null) {
      this.basicDetailsApplicationForm.id =
        this.candidateProfileService.getPartialId();
    }
    if (this.synposisId) {
      this.basicDetailsApplicationForm.id = this.synposisId;
    }
    this.basicDetailsApplicationForm.interactionGroupingId = parseInt(
      this.candidateProfileService.opportunityId
    );
    this.basicDetailsApplicationForm.interactionId =
      this.candidateProfileService.applicationId;
    this.basicDetailsApplicationForm.evaluationForm = {
      id: this.evaluationFormId,
    };
    if(this.applicationSubStatus ==='SYNOPSIS_SUBMITTED'){
      this.basicDetailsApplicationForm.feedbackStatus = 'FINAL';
    }else{
      this.basicDetailsApplicationForm.feedbackStatus = 'PARTIAL';
    }
    if (
      this.candidateProfileService.getPartialId() == null &&
      !this.synposisId
    ) {
      return this.candidateInfoService.submitTeamDetails(
        this.basicDetailsApplicationForm
      );
    } else {
      return this.candidateInfoService.updateTeamDetails(
        this.basicDetailsApplicationForm,
        this.basicDetailsApplicationForm.id
      );
    }
  };

  handleSubmit() {
    // if (
    //   !this.wizard?.checkValidity(
    //     this.basicDetailsApplicationForm?.feedbackData?.data
    //   )
    // ) {
    //   this.sharedService.openErrorSnackBar(
    //     'Please fill all the required information',
    //     ''
    //   );
    //   return;
    // }
    this.displaySubmit = true;
    this.resetDisabled =true;
    this.basicDetailsApplicationForm.interactionGroupingType = 'SYNOPSIS';
    this.basicDetailsApplicationForm.interactionGroupingId = parseInt(
      this.candidateProfileService.opportunityId
    );
    this.basicDetailsApplicationForm.feedbackStatus = 'FINAL';
    this.basicDetailsApplicationForm.interactionId =
      this.candidateProfileService.applicationId;
    this.basicDetailsApplicationForm.evaluationForm = {
      id: this.evaluationFormId,
    };
    delete this.basicDetailsApplicationForm['id'];
    this.basicDetailsApplicationForm.id = this.synposisId;
    // delete this.basicDetailsApplicationForm['isValid'];
    this.submitting = false;

    let submitApi: Observable<any> =
      this.candidateInfoService.submitTeamDetails(
        this.basicDetailsApplicationForm
      );
    // console.log(this.candidateProfileService.getPartialId(), this.synposisId);
    if (this.candidateProfileService.getPartialId() || this.synposisId) {
      if (this.candidateProfileService.getPartialId())
        this.basicDetailsApplicationForm.id =
          this.candidateProfileService.getPartialId();
      if (this.synposisId)
        this.basicDetailsApplicationForm.id = this.synposisId;
      submitApi = this.candidateInfoService.updateTeamDetails(
        this.basicDetailsApplicationForm,
        this.basicDetailsApplicationForm.id
      );
    }
    submitApi.subscribe({
      next: (res) => {
        // console.log(res);
        if(this.applicationSubStatus === 'SYNOPSIS_SUBMITTED'){
          this.submitting = false;
          this.displaySubmit = false;
          this.sharedService.openSuccessSnackBar(
            'Submitted Successfully...!',
            ''
          );
          this.route.navigate([
            '/springboard/candidate/view-all-scholarship-programmes',
          ]);
        }else{
          this.onSubstatusChange();
        }
      },
      error: (err) => {
        this.submitting = true;
        this.displaySubmit = false;
        this.resetDisabled=false;
        this.sharedService.openErrorSnackBar(
          'Something Went Wrong Please try again later..!',
          ''
        );
      },
    });
  }

  onSubstatusChange() {
    const body = {
      applicationSubStatus: 'SYNOPSIS_SUBMITTED',
    };
    this.candidateInfoService
      .updateJobApplication(this.candidateProfileService.applicationId, body)
      .subscribe({
        next: (response: any) => {
          this.submitting = false;
          this.displaySubmit = false;
          this.sharedService.openSuccessSnackBar(
            'Submitted Successfully...!',
            ''
          );
          this.route.navigate([
            '/springboard/candidate/view-all-scholarship-programmes',
          ]);
        },
        error: (err) => {
          this.submitting = true;
          this.displaySubmit = false;
          this.resetDisabled = false;
          this.sharedService.openErrorSnackBar(
            'Something went wrong, please try again later.',
            ''
          );
        },
      });
  }

  handleGenerateClick() {
    this.show = false;
    this.spinner = false;
    this.templateFileId = null;
    // if (this.applicationSubStatus !== 'SYNOPSIS_SUBMITTED') {
      if (!this.templateFileId && !this.spinner) {
        this.div.removeChild(this.vbutton);
        this.div.appendChild(this.waitSpinner);
      }
    // }
    this.getTemplate();
  }
  getTemplate() {
    this.candidateInfoService
      .generateDocument(this.candidateProfileService.applicationId)
      .subscribe({
        next: (res) => {
          setTimeout(() => {
            this.getFileId();
          }, 2000);
        },
        error: (err) => {
          this.spinner = true;
          this.show = true;
          // if (this.applicationSubStatus !== 'SYNOPSIS_SUBMITTED') {
            this.div.appendChild(this.vbutton);
            this.div.removeChild(this.waitSpinner);
          // }
          this.sharedService.openErrorSnackBar(
            'Something went wrong try again later...!',
            ''
          );
        },
      });
  }

  getFileId() {
    this.candidateInfoService
      .getFileId(this.candidateProfileService.applicationId)
      .subscribe({
        next: (res) => {
          // console.log(res?.documentFileId);
          this.templateFileId = res?.documentFileId;
          this.spinner = true;
          if (
            this.templateFileId 
            // &&
            // this.applicationSubStatus !== 'SYNOPSIS_SUBMITTED'
          ) {
            this.div.removeChild(this.waitSpinner);
            this.div.appendChild(this.dbutton);
          }
        },
        error: (err) => {
          this.sharedService.openErrorSnackBar(
            'Something went wrong try again later...!',
            ''
          );
          this.spinner = true;
          this.show = true;
          // if (this.applicationSubStatus !== 'SYNOPSIS_SUBMITTED') {
            this.div.appendChild(this.vbutton);
            this.div.removeChild(this.waitSpinner);
          // }
        },
      });
  }

  downloadFile() {
    if (!this.templateFileId) {
      this.sharedService.openErrorSnackBar(
        'Something went wrong try again later...!',
        ''
      );
      this.show = true;
      this.spinner = false;
      this.templateFileId = null;  
      this.div.appendChild(this.vbutton);
      this.div.removeChild(this.dbutton);
      return;
    }
    this.fileservice.getFile(this.templateFileId).subscribe({
      next: (res) => {
        const fileName = this.templateFileId + '.pdf';
        let blob: any = new Blob([res.body], {
          type: 'application/octet-stream; charset=utf-8',
        });
        this.docBlob = blob;
        const fileNameValues = fileName.split('.');
        if (
          fileNameValues[fileNameValues.length - 1] === 'pdf' ||
          fileNameValues[fileNameValues.length - 1] === 'PDF'
        ) {
          const blobFile = new File([this.docBlob], fileName, {
            type: 'application/pdf',
          });
          let fileURL = URL.createObjectURL(blobFile);
          window.open(fileURL);
        } else {
          fileSaver.saveAs(this.docBlob, fileName);
        }
        this.sharedService.openSuccessSnackBar(
          'Template Downloaded Successfully..!',
          ''
        );
      },
      error: (err) => {
        this.show = true;
        this.spinner = false;
        this.templateFileId = null;
        // if (this.applicationSubStatus !== 'SYNOPSIS_SUBMITTED') {
          this.div.appendChild(this.vbutton);
          this.div.removeChild(this.dbutton);
        // }
        this.sharedService.openErrorSnackBar(
          'Error occured. Please try again',
          ''
        );
      },
    });
  }

  getTabLabelClass = (applicationStatus: string) => {
    switch (applicationStatus) {
      case 'VERIFIED':
        return 'tab_green';
      case 'REPLACED':
        return 'tab_purple';
      case 'REJECTED':
        return 'tab_red';
      case 'PENDING':
        return 'tab_yellow';
      case 'SENT_BACK':
        return 'tab_blue';
      case 'UPLOADING':
        return 'tab_yellow';
      case 'DELETED':
        return 'tab_red';
      default:
        return 'tab_grey';
    }
  };

  validateUploadFile(category) {
    let categoryFiles = [];
    let isFound: Boolean = false;
    categoryFiles = this.applicationFiles.filter(
      (file) => file.category === category
    );
    if (categoryFiles?.length <= 0) {
      return -1;
    }
    categoryFiles.forEach((file) => {
      if (file?.applicationFileStatus === 'SENT_BACK') {
        isFound = true;
      }
    });
    categoryFiles.sort((a, b) => {
      const aId = a?.id;
      const bId = b?.id;
      return bId - aId;
    });
    // console.log(isFound)
    if (!isFound) return -1;
    return categoryFiles[0]?.id;
  }
  filterByMaxId(arr) {
    const categoryMap = new Map();
    for (const obj of arr) {
      const { id, category } = obj;
      if (!categoryMap.has(category) || id > categoryMap.get(category).id) {
        categoryMap.set(category, obj);
      }
    }
    return Array.from(categoryMap.values());
  }
}
