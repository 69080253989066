import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ReplaySubject } from 'rxjs';
import * as AsyncLock from 'async-lock';

export class Timer {
  id = this.interviewId;
  startDate = new Date();
  constructor(public interviewId: number) {}
}

export class ObservableTimer {
  constructor(public interviewId: number, public timer?: Timer) {}
}

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  private headers = new HttpHeaders().set('content-type', 'application/json');
  // .set('Authorization', 'Bearer ' + localStorage.getItem('token'))

  constructor(private http: HttpClient) {}

  private url = environment.assistedScheduleServiceUrl.concat('api/timer');

  private lock = new AsyncLock();
  private key = 'client';

  subject = new ReplaySubject<ObservableTimer>();

  private async getTimer(interviewId) {
    var result = await this.http
      .get<Timer>(this.url.concat(`?interviewId=${interviewId}`), {
        headers: this.headers,
      })
      .toPromise();
    return result ? result : undefined;
  }

  private async postTimer(interviewId) {
    return await this.http
      .post<Timer>(this.url, new Timer(interviewId), { headers: this.headers })
      .toPromise();
  }

  private async deleteTimer(interviewId) {
    return await this.http
      .delete(this.url.concat(`?interviewId=${interviewId}`), {
        headers: this.headers,
        observe: 'response',
        responseType: 'text',
      })
      .toPromise();
  }

  init(interviewId) {
    if (interviewId) {
      this.lock.acquire(this.key, async () => {
        var timer = await this.getTimer(interviewId);
        this.subject.next(new ObservableTimer(interviewId, timer));
      });
    }
  }

  startTimer(interviewId) {
    if (interviewId) {
      this.lock.acquire(this.key, async () => {
        var timer = await this.getTimer(interviewId);
        var result = timer ? timer : await this.postTimer(interviewId);
        this.subject.next(new ObservableTimer(interviewId, result));
      });
    }
  }

  endTimer(interviewId) {
    if (interviewId) {
      this.lock.acquire(this.key, async () => {
        await this.deleteTimer(interviewId);
        this.subject.next(new ObservableTimer(interviewId, undefined));
      });
    }
  }

  startDynamicEventTimer(interview) {
    if (interview?.jobOpening?.eventType === 'LATERAL_DYNAMIC') {
      this.startTimer(interview.id);
    }
  }

  endDynamicEventTimer(interview) {
    if (interview?.jobOpening?.eventType === 'LATERAL_DYNAMIC') {
      this.endTimer(interview.id);
    }
  }
}
