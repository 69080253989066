/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/tslint/config */
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { Visibility } from 'src/app/shared/model/enumerations/visibility.model';
import { FileserverService } from 'src/app/survey-master/survey/edit-backgroud-color/fileserver.service';
import { Theme, QuestionView } from 'src/app/shared/model/theme.model';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return control && control.invalid;
  }
}

@Component({
  selector: 'jhi-file-upload-preview',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadPreviewComponent implements OnInit, OnChanges {
  @Input()
  currPage: any;
  @Input()
  question: any;
  @Input()
  questionIndex: any;
  @Input()
  responsePage: any;
  @Input()
  section: any;
  @Input()
  preference: any;
  @Input()
  theme: Theme;

  view = 'NORMAL';
  @Input()
  disabled: Boolean = false;
  @Input()
  nextElement: HTMLElement;

  matcher = new MyErrorStateMatcher();
  private readonly notifier: NotifierService;
  public files: NgxFileDropEntry[] = [];

  control: FormControl;
  allowedExtensions = [];
  uploadedFiles = [];
  validationMessage = '';
  maxFiles: number = null;

  constructor(
    public fileserverService: FileserverService,
    notifierService: NotifierService
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.control = new FormControl('');
    if (this.theme.questionView === QuestionView.TWO_COLUMNS) {
      this.view = 'TWO_COLUMNS';
    } else {
      this.view = 'NORMAL';
    }
    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response != null &&
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response.length > 0
    ) {
      // already some response present

      if (
        localStorage.getItem(this.currPage.surveyId + '_' + this.question.id) ==
        null
      ) {
        this.uploadedFiles = [];
      } else {
        const stringFIles = JSON.parse(
          localStorage.getItem(this.currPage.surveyId + '_' + this.question.id)
        );

        stringFIles.forEach((element) => {
          this.uploadedFiles.push(JSON.parse(element));
        });
      }
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response = [];
    }

    for (let i = 0; i < this.question.type.validationValues.length; i++) {
      if (this.question.type.validationValues[i].value === 'true') {
        switch (this.question.type.validationValues[i].param.identifier) {
          case 'ALLOW_DOC':
            this.allowedExtensions.push('doc');
            this.allowedExtensions.push('docx');
            break;
          case 'ALLOW_EXCEL':
            this.allowedExtensions.push('xlsx');
            this.allowedExtensions.push('xls');
            this.allowedExtensions.push('csv');
            break;
          case 'ALLOW_JPG':
            this.allowedExtensions.push('jpg');
            this.allowedExtensions.push('jpeg');
            break;
          case 'ALLOW_PNG':
            this.allowedExtensions.push('png');
            break;
          case 'ALLOW_PDF':
            this.allowedExtensions.push('pdf');
            break;
          case 'ALLOW_GIF':
            this.allowedExtensions.push('gif');
            break;
          case 'ALLOW_TXT':
            this.allowedExtensions.push('txt');
            break;
        }
      }
    }

    this.validationMessage = this.getValue(this.question, 'VALIDATION_TEXT');
    this.maxFiles = Number(this.getValue(this.question, 'MAX_FILES'));
    // this.responsePage.sections[this.section].questions[this.questionIndex].response.push('');
  }

  ngOnChanges() {
    this.control = new FormControl('');
    if (this.theme.questionView === QuestionView.TWO_COLUMNS) {
      this.view = 'TWO_COLUMNS';
    } else {
      this.view = 'NORMAL';
    }
    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response != null &&
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response.length > 0
    ) {
      // already some response present

      if (
        localStorage.getItem(this.currPage.surveyId + '_' + this.question.id) ==
        null
      ) {
        this.uploadedFiles = [];
      } else {
        const stringFIles = JSON.parse(
          localStorage.getItem(this.currPage.surveyId + '_' + this.question.id)
        );

        stringFIles.forEach((element) => {
          this.uploadedFiles.push(JSON.parse(element));
        });
      }
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response = [];
    }

    for (let i = 0; i < this.question.type.validationValues.length; i++) {
      if (this.question.type.validationValues[i].value === 'true') {
        switch (this.question.type.validationValues[i].param.identifier) {
          case 'ALLOW_DOC':
            this.allowedExtensions.push('doc');
            this.allowedExtensions.push('docx');
            break;
          case 'ALLOW_EXCEL':
            this.allowedExtensions.push('xlsx');
            this.allowedExtensions.push('xls');
            this.allowedExtensions.push('csv');
            break;
          case 'ALLOW_JPG':
            this.allowedExtensions.push('jpg');
            this.allowedExtensions.push('jpeg');
            break;
          case 'ALLOW_PNG':
            this.allowedExtensions.push('png');
            break;
          case 'ALLOW_PDF':
            this.allowedExtensions.push('pdf');
            break;
          case 'ALLOW_GIF':
            this.allowedExtensions.push('gif');
            break;
          case 'ALLOW_TXT':
            this.allowedExtensions.push('txt');
            break;
        }
      }
    }

    this.validationMessage = this.getValue(this.question, 'VALIDATION_TEXT');
    this.maxFiles = Number(this.getValue(this.question, 'MAX_FILES'));
  }

  downloadImage(path: string) {
    return this.fileserverService.getFileURLbyPath(path);
  }

  public dropped(files: NgxFileDropEntry[]) {
    if (this.uploadedFiles.length + files.length > this.maxFiles) {
      this.notifier.notify(
        'error',
        'Maximum ' + this.maxFiles + ' files are allowed!'
      );
      return;
    }
    this.files = files;
    this.notifier.notify('default', 'Uploading Your Files...');
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file

          const lastIndex = file.name.lastIndexOf('.');

          if (lastIndex === -1) {
            // error
            this.responsePage.sections[this.section].questions[
              this.questionIndex
            ].isValid = false;
            this.responsePage.sections[this.section].questions[
              this.questionIndex
            ].validationMessage = this.validationMessage;
            this.notifier.notify('warn', this.validationMessage);
          } else {
            const ext = file.name.substr(lastIndex + 1).toLowerCase();

            if (this.allowedExtensions.includes(ext)) {
              // upload
              this.responsePage.sections[this.section].questions[
                this.questionIndex
              ].isValid = true;
              this.responsePage.sections[this.section].questions[
                this.questionIndex
              ].validationMessage = '';
              const fileName =
                Date.now().toString(36) +
                Math.random().toString(36).substr(2, 5) +
                '.' +
                file.name.split('.').pop();
              const newFile = this.getNewFile(file, fileName);
              this.fileserverService
                .saveFile(
                  newFile,
                  this.currPage.surveyId + '_' + this.question.id
                )
                .subscribe(
                  (res: any) => {
                    this.responsePage.sections[this.section].questions[
                      this.questionIndex
                    ].response.push(
                      this.currPage.surveyId +
                        '_' +
                        this.question.id +
                        '/' +
                        fileName
                    );
                    this.uploadedFiles.push(file);
                    const storageFiles = [];
                    for (let f = 0; f < this.uploadedFiles.length; f++) {
                      storageFiles.push(
                        JSON.stringify({ name: this.uploadedFiles[f].name })
                      );
                    }
                    localStorage.setItem(
                      this.currPage.surveyId + '_' + this.question.id,
                      JSON.stringify(storageFiles)
                    );
                    this.notifier.notify('success', file.name + ' Uploaded!');
                    if (this.nextElement != null) {
                      this.nextElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                      });
                      setTimeout(() => {
                        if (
                          this.nextElement.getElementsByTagName('input') != null
                        )
                          this.nextElement
                            .getElementsByTagName('input')[0]
                            .focus();
                      }, 400);
                    }
                  },
                  (res: HttpErrorResponse) => {
                    this.notifier.notify(
                      'error',
                      file.name + ' could not be uploaded!'
                    );
                  }
                );
            } else {
              // error
              this.responsePage.sections[this.section].questions[
                this.questionIndex
              ].isValid = false;
              this.responsePage.sections[this.section].questions[
                this.questionIndex
              ].validationMessage = this.validationMessage;
              this.notifier.notify('warn', this.validationMessage);
            }
          }

          /**
          // You could upload it like this:
          const formData = new FormData()
          formData.append('logo', file, relativePath)

          // Headers
          const headers = new HttpHeaders({
            'security-token': 'mytoken'
          })

          this.http.post('https://mybackend.com/api/upload/sanitize-and-save-logo', formData, { headers: headers, responseType: 'blob' })
          .subscribe(data => {
            // Sanitized logo returned from backend
          })
          **/
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  removeFile(index: number) {
    this.uploadedFiles.splice(index, 1);
    const storageFiles = [];
    for (let f = 0; f < this.uploadedFiles.length; f++) {
      storageFiles.push(JSON.stringify({ name: this.uploadedFiles[f].name }));
    }
    localStorage.setItem(
      this.currPage.surveyId + '_' + this.question.id,
      JSON.stringify(storageFiles)
    );
    this.responsePage.sections[this.section].questions[
      this.questionIndex
    ].response.splice(index, 1);
  }

  private getNewFile(file: File, fileName: string): File {
    const blob = file.slice(0, file.size, file.type);
    return new File([blob], fileName, { type: file.type });
  }

  inputChanged() {}

  getValidationValue(question: any, param: string): string {
    const object = question.type.validationValues.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }

  getValue(question: any, param: string): string {
    const object = question.type.values.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }
}
