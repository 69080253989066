import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CandidateInfoService } from '../services/candidate-info.service';
import { Router } from '@angular/router';
import { IPagination } from 'src/app/shared/model/pagination.model';
import { scholarshipFilterColumns } from 'src/app/app.constants';
import { SharedService } from 'src/app/services/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { CandidateProfileService } from '../candidate-profile/candidate-profile.service';
import { ScholarshipStateStorageService } from '../candidate-profile/scholarship-details/scholarship-state-storage.service';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { Observable, forkJoin, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-view-all-jobs',
  templateUrl: './view-all-jobs.component.html',
  styleUrls: ['./view-all-jobs.component.scss'],
})
export class ViewAllJobsComponent implements OnInit {
  searchedText: string;
  isLoadingForAll: boolean = false;
  isLoadingForApplied: boolean = false;
  isLoadingForScholarships: boolean = false;
  isApplicationsPresent: boolean = false;
  isError: boolean = false;
  opportunitySelected: string = 'HACKATHON';
  jobs: any[] = [];
  appliedJobs: any[] = [];
  page: number = 0;
  pageSize: number = 10;
  totalElements: number = 0;
  filters;
  filterByColumns = scholarshipFilterColumns;
  config: any;
  allJobs: any;
  totalJobs: number;
  emptyJobFilter;
  tobeCancelledId;
  seekerId = null;
  applicationLimit = null;
  observables: Observable<any>[] = [];
  isPartofTeam:boolean =false;
  role:string;
  teamLead:string;
  candidateEmail = JSON.parse(localStorage.getItem('ACCOUNT_DETAILS'))?.email;
  @ViewChild('secondDialog', { static: true }) secondDialog: TemplateRef<any>;

  constructor(
    private scholarshipStateStorageService: ScholarshipStateStorageService,
    private candidateJobsService: CandidateInfoService,
    private candidateApplicationService: CandidateProfileService,
    private dialog: MatDialog,
    private router: Router,
    private sharedService: SharedService
  ) {
    this.observables = [
      this.candidateJobsService
        .getAllJobs(
          this.page,
          this.pageSize,
          this.searchedText,
          this.opportunitySelected,
          this.filters
        )
        .pipe(catchError((error) => of(error))),
      this.candidateJobsService
        .getApplicationLimit(
          this.candidateApplicationService.application_limit_key
        )
        .pipe(catchError((error) => of(error))),
      this.candidateJobsService.isPartofTeam()
      .pipe(catchError((error) => of(error)))  
    ];
  }

  ngOnInit(): void {
    this.seekerId = +sessionStorage.getItem('seekerId');
    // this.getApplicationLimit().then(()=> {
    //   this.getJobsAndApplications();
    // });
    forkJoin(this.observables).subscribe((res) => {
      res?.forEach((f, index) => {
        if (f instanceof HttpErrorResponse) {
          if (index == 0) {
            this.isError = true;
            this.isLoadingForAll = false;
            this.sharedService.openErrorSnackBar(
              'Error occurred, please try again',
              ''
            );
          } else if(index==1){
            this.sharedService.openErrorSnackBar(
              'Error occurred, please try again',
              ''
            );
          }else{
            this.sharedService.openErrorSnackBar(
              'Error occurred, please try again',
              ''
            );
          }
        } else {
          if (index == 0) {
            this.setJobsAndApplications(f);
          } else if(index==1) {
            this.setApplicationLimit(f);
          }else{
             this.setTeamApplication(f);
          }
        }
      });
    });
  }
  setTeamApplication(f) {
    if(f) {
      let isteamLeadMember = null;
      let teamLeadMember = null; 
      if (f?.teamMemberUiDTOS) {
        for (const member of f.teamMemberUiDTOS) {
          if (member.email === this.candidateEmail) {
            isteamLeadMember = member;
          }
          if (member.role === 'TEAM_LEAD') {
            teamLeadMember = member;
          }
        }
      }
      this.isPartofTeam =true;
      this.role = isteamLeadMember?.role;
      this.teamLead=teamLeadMember?.email;
    }
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }

  viewJob(id) {
    this.router.navigate(['candidate/view-scholarship', id]);
  }

  getJobOpeningsFilters = () => {
    return this.candidateJobsService.getJobOpeningsFilters();
  };

  // getJobsAndApplications = () => {
  //   this.isLoadingForAll = true;
  //   this.isError = false;
  //   //we will fetch all scholarships.
  //   this.candidateJobsService
  //     .getAllJobs(
  //       this.page,
  //       this.pageSize,
  //       this.searchedText,
  //       this.opportunitySelected,
  //       this.filters
  //     )
  //     .subscribe({
  //       next: (data) => {
  //         this.jobs = data.content;
  //         this.totalElements = data.totalElements;
  //         //we will check if we have candidate id or not
  //         if (!this.seekerId) {
  //           this.getCandidateDetailsToNavigateOrStay(data);
  //           // this.isLoadingForAll = false;
  //         }
  //         //We will check if only one scholarship then we will route it to view application
  //         // or application stepper.
  //         else if (this.jobs.length === 1) {
  //           // this.routingForSingleScholarship();
  //           this.getApplications();
  //           this.isApplicationsPresent = true;
  //           this.isLoadingForAll = false;
  //         } else {
  //           this.isLoadingForAll = false;
  //           this.isApplicationsPresent = true;
  //           this.getApplications();
  //         }
  //       },
  //       error: (err) => {
  //         this.isError = true;
  //         this.isLoadingForAll = false;
  //         this.sharedService.openErrorSnackBar(
  //           'Error occurred, please try again',
  //           ''
  //         );
  //       },
  //     });
  // };
  setJobsAndApplications(data) {
    this.jobs = data.content;
    this.totalElements = data.totalElements;
    //we will check if we have candidate id or not
    if (!this.seekerId) {
      this.getCandidateDetailsToNavigateOrStay(data);
      // this.isLoadingForAll = false;
    }
    //We will check if only one scholarship then we will route it to view application
    // or application stepper.
    else if (this.jobs.length === 1) {
      // this.routingForSingleScholarship();
      this.getApplications();
      this.isApplicationsPresent = true;
      this.isLoadingForAll = false;
    } else {
      this.isLoadingForAll = false;
      this.isApplicationsPresent = true;
      this.getApplications();
    }
  }
  setApplicationLimit(res) {
    this.applicationLimit = res.value;
  }

  // getApplicationLimit(): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     this.candidateJobsService
  //       .getApplicationLimit(
  //         this.candidateApplicationService.application_limit_key
  //       )
  //       .subscribe({
  //         next: (res) => {
  //           this.applicationLimit = res.value;
  //           resolve();
  //         },
  //         error: (err) => {
  //           this.sharedService.openErrorSnackBar(
  //             'Error occurred, please try again',
  //             ''
  //           );
  //           reject();
  //         },
  //       });
  //   });
  // }

  getCandidateDetailsToNavigateOrStay(data) {
    //We will call this api to get candidate details
    this.candidateJobsService.getCandidateProfileUIDTOForCandidate().subscribe({
      next: (res) => {
        this.candidateApplicationService.candidateDetailsFromViewALL = true;
        if (res?.candidateInfoId) {
          this.seekerId = res.candidateInfoId;
          sessionStorage.setItem('seekerId', String(this.seekerId));
          this.candidateApplicationService.setCandidateProfile(res);
          //We will check if only one scholarship then we will route it to view application
          // or application stepper.
          if (this.jobs.length === 1) {
            // this.routingForSingleScholarship();
            this.isApplicationsPresent = true;
            this.getApplications();
            this.isLoadingForAll = false;
          }
          //When more than one scholarship then we will fetch applied and show it with all
          // scholarships.
          else {
            this.isLoadingForAll = false;
            this.isApplicationsPresent = true;
            this.getApplications();
          }
        }
        //When no candidate info and only one scholarship route to stepper.
        else if (this.jobs.length === 1) {
          this.isLoadingForAll = false;
          this.candidateApplicationService.candidateDetailsFromViewALL = false;
          this.candidateApplicationService.setCandidateInfoId(null);
          this.isApplicationsPresent = true;
          this.getApplications();
          // this.router.navigate(
          //   ['candidate/application-stepper', this.jobs[0].id],
          //   { state: { checkForLocalStepState: true } }
          // );
        } else this.isLoadingForAll = false;
      },
      error: (err) => {
        this.isLoadingForAll = false;
        if (err?.status === 404) {
          this.candidateApplicationService.candidateDetailsFromViewALL = false;
          this.candidateApplicationService.setCandidateInfoId(null);
          //When no candidate info and only one scholarship route to stepper.
          // this.router.navigate(
          //   ['candidate/application-stepper', this.jobs[0].id],
          //   { state: { checkForLocalStepState: true } }
          // );
        } else {
          this.isError = true;
          this.sharedService.openErrorSnackBar(
            'Error occurred, please try again',
            ''
          );
        }
      },
    });
  }

  routingForSingleScholarship() {
    //When one scholarship is there check if already applied then to view-applications and if
    // not then to stepper.
    this.candidateJobsService
      .getApplicationByCandidateIdAndOpportunityId(
        this.seekerId,
        this.jobs[0].id
      )
      .subscribe({
        next: (response) => {
          this.isLoadingForAll = false;
          this.candidateApplicationService.applicationAPIResponseFromViewALL =
            response;
          this.scholarshipStateStorageService.setDisableEditBankProof(true);
          // Loop through response.applicationFiles and inside that if category: "BANK_PASSBOOK" or "CANCELLED_CHEQUE" then create Local variable enableEditBankDetails true
          response.applicationFiles.forEach((element) => {
            if (
              element.category === 'BANK_PASSBOOK' ||
              element.category === 'CANCELLED_CHEQUE'
            ) {
              // Enable editing bank details for sentack of bank document
              if (element.applicationFileStatus === 'SENT_BACK')
                this.scholarshipStateStorageService.setDisableEditBankProof(
                  false
                );
            }
          });
          this.router.navigate(['candidate/problem-statements']);
        },
        error: (err) => {
          this.isLoadingForAll = false;
          if (err?.status == 400) {
            this.candidateApplicationService.applicationAPIResponseFromViewALL =
              null;
            // this.router.navigate(
            //   ['candidate/application-stepper', this.jobs[0].id],
            //   { state: { checkForLocalStepState: true } }
            // );
            this.router.navigate(['candidate/problem-statements']);
          } else {
            this.isError = true;
            this.sharedService.openErrorSnackBar(
              'Error occurred, please try again',
              ''
            );
          }
        },
      });
  }

  getAllJobs = () => {
    this.isLoadingForScholarships = true;
    this.isError = false;
    this.candidateJobsService
      .getAllJobs(
        this.page,
        this.pageSize,
        this.searchedText,
        this.opportunitySelected,
        this.filters
      )
      .subscribe({
        next: (res) => {
          //console.log(res);
          //res=res.filter((e) => e.tenantId == 3);
          this.isLoadingForScholarships = false;
          this.jobs = res.content;
          if (this.appliedJobs?.length > 0) {
            //   this.jobs = this.jobs.filter(
            //     (job) =>
            //       !this.appliedJobs //Remove .filter() when API updates.
            //         .some((appliedJob) => appliedJob.opportunity.id == job.id)
            //   );
            //   this.totalElements = this.jobs.length;
          }
          // } else {
          this.totalElements = res.totalElements;
        },
        error: (err) => {
          this.isError = true;
          this.isLoadingForScholarships = false;
          this.sharedService.openErrorSnackBar(
            'Error occurred, please try again',
            ''
          );
        },
      });
  };

  getApplications = () => {
    this.isLoadingForApplied = true;
    this.candidateJobsService
      .getAllCandidateApplications(this.seekerId, this.page, 10, null)
      .subscribe({
        next: (res) => {
          this.isLoadingForApplied = false;
          // this.appliedJobs = res.content
          //   .filter((job) => {job.status != 'WITHDRAWN'})
          //   .slice(0, 2);
          // console.log(this.appliedJobs)  
          // let appliedJobsCount=0;  
          // this.appliedJobs.forEach((job)=>{
          //   if(job.status !='REJECTED') appliedJobsCount++;
          // })
          let appliedJobsCount=0;
          // filtering out withdrawn jobs and counting status other than 'rejected' to get actual count of applied jobs ,
          for (let job of res.content) {
            if (job.status !== 'WITHDRAWN') {
              if (job.status !== 'REJECTED') appliedJobsCount++;
              this.appliedJobs.push(job);
              if (this.appliedJobs.length === 2) break;
            }
          }
          // sets the candidate Application boolean value ,i.e applied>=limit
          this.candidateApplicationService.setCandidateApplicationCount(
            appliedJobsCount >= this.applicationLimit
          );
          if (this.appliedJobs?.length > 0) {
            // this.jobs = this.jobs.filter(
            //   (job) =>
            //     !this.appliedJobs //Remove .filter() when API updates.
            //       .some((appliedJob) => appliedJob.opportunity.id == job.id)
            // );
            // this.totalElements = this.jobs.length;
          } else {
            this.isApplicationsPresent = false;
            this.sharedService.openSnackBar(
              'No Applied Problem Statements!',
              ''
            );
          }
        },
        error: (err) => {
          this.isLoadingForApplied = false;
          this.isApplicationsPresent = false;
          this.sharedService.openErrorSnackBar(
            'Could not fetch Applications, try again later!',
            ''
          );
        },
      });
  };

  handlePageChange = (e: IPagination) => {
    this.page = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getAllJobs();
  };

  clearSearch() {
    this.searchedText = '';
    this.getAllJobs();
  }
  searchJob(searchedText: string) {
    this.page = 0;
    //console.log(this.searchedText);
    this.getAllJobs();
  }

  handleFilterChange = (e) => {
    console.log(e);
    this.filters = e;
    this.getAllJobs();
  };

  cancelApplication(applicantionId: any) {
    this.tobeCancelledId = applicantionId;
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        text: `Do you want to withdraw the application ?`,
      },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Yes') {
        this.isLoadingForApplied = true;
        const formValue = {
          applicationStatus: 'WITHDRAWN',
        };
        this.candidateJobsService
          .updateJobApplication(this.tobeCancelledId, formValue)
          .subscribe({
            next: (res) => {
              this.isLoadingForApplied = false;
              this.sharedService.openErrorSnackBar(
                'Application has been successfully withdrawn',
                ''
              );
              //this.isLoading = false;
              this.getApplications();
            },
            error: (err) => {
              //this.isLoading = false;
              this.isLoadingForApplied = false;
              this.sharedService.openErrorSnackBar(
                'some error occurred, please try again',
                ''
              );
            },
          });
      }
    });
  }

  applyJob = (jobId, job) => {
    this.router.navigate(['candidate/application/instructions/', jobId]);
    //this.router.navigate(['candidate/apply-scholarship', jobId]);
  };
}
