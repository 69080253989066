<app-loader [display]="loader"></app-loader>

<div class="container-fluid layout" *ngIf="!loader">
  <div class="clearfix DSA_mainCtr ctr_main" id="mainCtr">
    <div class="container-fluid">
      <div class="DSA_rightMainContent nomarL_mb" id="mainrightCnt">
        <div class="DSA_innerContentWrapper">
          <div class="row">
            <div class="col-lg-12">
              <div class="DSA_contentLeftCol">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="DSA_panel">
                      <div class="DSA_panelBody padTB16 padLR24">
                        <div
                          class="
                            d-flex
                            justify-content-start
                            align-items-center
                            mb-3
                          "
                        >
                          <div class="ml-3 mr-3" role="button">
                            <i
                              class="icon x-24 back-icon"
                              [routerLink]="[prevLink]"
                            >
                            </i>
                          </div>
                          <div class="h3">
                            {{ candidate.name }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-4 col-md-4">
                            <mat-form-field class="DSA_Wb_custom-form-field">
                              <mat-label>Can ID</mat-label>
                              <input
                                matInput
                                placeholder="Candidate Id"
                                readonly
                                value="{{
                                  candidate.candidateSourceId
                                    ? candidate.candidateSourceId
                                    : candidate.candidateId
                                }}"
                              />
                            </mat-form-field>
                            <!-- </mat-form-field> -->
                          </div>
                          <div class="col-lg-4 col-md-4">
                            <mat-form-field class="DSA_Wb_custom-form-field">
                              <mat-label>Name</mat-label>
                              <input
                                matInput
                                placeholder="Name"
                                readonly
                                value="{{ candidate.name }}"
                              />
                            </mat-form-field>
                          </div>
                          <div class="col-lg-4 col-md-4">
                            <mat-form-field class="DSA_Wb_custom-form-field">
                              <mat-label>Email ID</mat-label>
                              <input
                                matInput
                                placeholder="Email"
                                readonly
                                value="{{ candidate.email }}"
                              />
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <h5 class="col-md-4 d-flex align-items-center">
                            Interview Schedule Details
                          </h5>
                          <div class="col-lg-4 col-md-4 offset-md-4">
                            <mat-form-field
                              class="DSA_Wb_custom-form-field"
                              appearance="fill"
                            >
                              <mat-label>Job Role</mat-label>
                              <mat-select
                                [(value)]="jobId"
                                (selectionChange)="
                                  getCandidateInterviewsList($event.value)
                                "
                              >
                                <mat-option
                                  *ngFor="let job of jobsInEvent"
                                  [value]="job.id"
                                >
                                  {{ job.title }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-md-12">
                            <div class="mb-3">
                              <button
                                *ngIf="eventStatus !== 'CLOSED'"
                                mat-flat-button
                                class="DSA_wb_Icon-btn"
                                [disabled]="locked || !accountService.hasFeatureAccess(FeatureName.INTERVIEW_SCHEDULE, PermissionType.CREATE)"
                                (click)="scheduleNewInterview()"
                              >
                                <span class="icon_btnCont">
                                  <i class="icon purple x-16 icon-add"></i>
                                </span>
                                Schedule New Interview
                              </button>
                            </div>
                          </div>
                          <div class="ag-grid row nopadL w-100">
                            <!-- <div class="col-12 d-flex justify-content-end"></div> -->
                            <div class="col-12 nopadL">
                              <data-grid
                                (searchKey)="search($event)"
                                [columnDefs]="columnDefs"
                                [tableName]="tableName"
                                [buttonText]="buttonText"
                                [buttonComponent]="buttonComponent"
                                [levels]="levels"
                                [buttonCondition]="buttonCondition"
                                [searchCondition]="searchCondition"
                                [interface]="interface"
                                [customRowHeight]="customRowHeight"
                                [refreshAgGrid]="refreshAgGrid.asObservable()"
                              ></data-grid>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
