import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { SERVER_API_URL } from 'src/app/app.constants'; //uncomment in dev
import { HttpClient } from '@angular/common/http';


@Injectable({ providedIn: 'root' })
export class TakeSurveyService {
  public api = environment.feedbackServiceUrl; 
  // public api = environment.feedbackServiceUrl + 'services/survey'; 
  // public resourceUrlPublic = SERVER_API_URL + '/public/pagedSurvey';
  // public resourceUrl = SERVER_API_URL + '/api/pagedSurvey';
  // public resourceUrlResponse = SERVER_API_URL + '/api/response';
  // public resourceUrlResponsePublic = SERVER_API_URL + '/public/response';
  public resourceUrlPublic = this.api + 'public/pagedSurvey';
  public resourceUrl = this.api + 'api/pagedSurvey';
  public resourceUrlResponse = this.api + 'api/evaluation-responses';
  public resourceUrlResponsePublic = this.api + 'public/response';
  resourceGetAllEvals = this.api + 'api/all-evaluation-responses';

  constructor(protected http: HttpClient) {}

  getPublic(hash: string) {
    return this.http.get(`${this.resourceUrlPublic}/${hash}`, {
      observe: 'response',
    });
  }

  getPrivate(hash: string) {
    return this.http.get(`${this.resourceUrl}/${hash}`, {
      observe: 'response',
    });
  }

  submitPublic(hash: string, surveyResponse: any) {
    return this.http.post<any>(
      `${this.resourceUrlResponsePublic}/${hash}`,
      surveyResponse,
      { observe: 'response' }
    );
  }

  submitPrivate(hash: string, surveyResponse: any) {
    return this.http.post<any>(
      `${this.resourceUrlResponse}/${hash}`,
      surveyResponse,
      { observe: 'response' }
    );
  }

  createResponse(surveyId: any, surveyResponse: any) {
    return this.http.post<any>(`${this.resourceUrlResponse}`, surveyResponse, {
      observe: 'response',
    });
  }

  getResponse = (cirId, interviewId) => {
    return this.http.post(
      `${this.resourceGetAllEvals}`,
      { cirId, interviewId },
      {
        observe: 'response',
      }
    );
  };
}
