import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FileService } from 'src/app/services/file.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-migrate-candidate-data',
  templateUrl: './migrate-candidate-data.component.html',
  styleUrls: ['./migrate-candidate-data.component.scss'],
})
export class MigrateCandidateDataComponent implements OnInit {
  bulkuploadFile;
  loader: boolean = false;
  constructor(
    private fileService: FileService,
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<MigrateCandidateDataComponent>
  ) {}

  ngOnInit(): void {}

  bulkUpload = (files) => {
    this.bulkuploadFile = files[0];
  };

  uploadBulkCSV = () => {
    this.loader = true;
    let formData = new FormData();
    formData.append('file', this.bulkuploadFile);
    formData.append('specData', localStorage.getItem('tenantId'));

    this.bulkuploadFile = undefined;
    this.fileService.bulkUploadDataMigration(formData).subscribe(
      (res) => {
        this.sharedService.openSnackBar(
          'Sheet Uploaded Successfully',
          '',
          3000
        );
        this.loader = false;
        this.dialogRef.close();
      },
      () => {
        this.sharedService.openSnackBar('Some Error Occured.', '', 3000);
        this.loader = false;
        this.dialogRef.close();
      }
    );
  };
}
