import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AccountService } from '../services/account.service';
import { KeycloakService } from 'keycloak-angular';
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(  
    private accountService: AccountService,
    private keycloakService: KeycloakService
  ) { }

  ngOnInit(): void {
  }
  login = () => {
    if (environment.production) {
      this.accountService.login();
    } else {
      this.keycloakService.login();
    }
  };
}
