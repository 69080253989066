<div *ngIf="isUpcoming">
  <!-- if WEBEX -->
  <div *ngIf="meetingPlatform === 'WEBEX'">
    <app-display-meeting-link
      [meetingLink]="params.data?.source.link"
      displayText="Copy meeting link"
    >
    </app-display-meeting-link>
  </div>
  <!-- if ENABLEX, TEAMS -->
  <div *ngIf="['ENABLEX', 'TEAMS'].includes(meetingPlatform)">
    <div *ngIf="!meetingDetails && !linkNotPresent && !loading">
      <a
        class="DSA_wb_hyperLink-btn"
        role="button"
        (click)="getMeetingLink()"
        matTooltip="Get Interview Meeting Link"
        matTooltipClass="DSA_tooltip"
      >
        Get Meeting Link
      </a>
    </div>
    <div *ngIf="loading"><mat-spinner diameter="30"></mat-spinner></div>
    <div *ngIf="meetingDetails && !loading">
      <div
        *ngIf="meetingPlatform == 'TEAMS'"
        class="text-overflow d-flex align-items-center"
      >
        <app-display-meeting-link
          [meetingLink]="meetingDetails"
          displayText="Copy meeting link"
        ></app-display-meeting-link>
      </div>
      <div
        *ngIf="meetingPlatform === 'ENABLEX'"
        class="text-overflow d-flex flex-column justify-content-center"
      >
        <div
          class="interviewerLinks"
          *ngIf="meetingDetails.interviewers.length > 0"
        >
          <div *ngFor="let data of meetingDetails.interviewers">
            <app-display-meeting-link
              [meetingLink]="data.meetingUrl"
              displayText="Copy link for {{ data.emailId }}"
            ></app-display-meeting-link>
          </div>
        </div>
        <div class="candidateLink" *ngIf="meetingDetails.candidate as data">
          <app-display-meeting-link
            [meetingLink]="data.meetingUrl"
            displayText="Copy link for {{ data.emailId }}"
          ></app-display-meeting-link>
        </div>
      </div>
    </div>
    <div *ngIf="linkNotPresent && !loading">
      <a
        class="DSA_wb_hyperLink-btn"
        role="button"
        (click)="checkPlatformAndGenerateLink()"
        matTooltip="Re-generate Meeting Link"
        matTooltipClass="DSA_tooltip"
      >
        Regenerate Meeting Link
      </a>
    </div>
  </div>
  <!-- if TELEPHONIC, OFFLINE -->
  <div *ngIf="['TELEPHONIC', 'OFFLINE'].includes(meetingPlatform)">NA</div>
</div>
<div *ngIf="!isUpcoming">-</div>
