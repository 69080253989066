import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from 'src/app/services/account.service';
import { RecruiterService } from 'src/app/services/recruiter.service';
import { SharedService } from 'src/app/services/shared.service';
import { IEventPanelist } from 'src/app/shared/model/event-panelist.model';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
import { AddPanelistToInterviewDialogComponent } from 'src/app/shared/components/add-panelist-to-interview-dialog/add-panelist-to-interview-dialog.component';

@Component({
  selector: 'panelist-participation-status',
  templateUrl: './panelist-participation-status.component.html',
  styleUrls: ['./panelist-participation-status.component.scss'],
})
export class PanelistParticipationStatusComponent implements OnInit {
  @Input() panelists = [];
  @Input() interviewId;
  @Input() showAddPanelist: boolean = false;
  @ViewChild('interviewerInput') interviewerInput: ElementRef<HTMLInputElement>;

  eventStatus: string;
  eventId: number | string;
  panelistsParticipationStatus: Array<{
    interviewerId: number;
    name: string;
    emailId: string;
    attendanceStatus: boolean;
  }> = [];
  loader: boolean = false;

  FeatureName = FeatureName;
  PermissionType = PermissionType;

  showAddPanelistInput: boolean = false;

  constructor(
    private recruiterService: RecruiterService,
    private sharedService: SharedService,
    public accountService: AccountService,
    private _dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.eventStatus = localStorage.getItem('eventStatus');
    this.eventId = localStorage.getItem('eventId');

    this.setPanelistParticipationState();
  }

  setPanelistParticipationState = () => {
    this.panelistsParticipationStatus = [];
    this.panelists.map((panelist) => {
      this.panelistsParticipationStatus.push({
        interviewerId: panelist.id,
        name: panelist.name,
        emailId: panelist.emailId,
        attendanceStatus:
          panelist.participationStatus === 'REJECTED' ? false : true,
      });
    });
  };

  handleAttendanceStatusChange = (event, panelistId) => {
    let tempStatus = this.panelistsParticipationStatus;
    tempStatus.forEach((_, index, arr) => {
      if (_.interviewerId === panelistId) {
        arr[index].attendanceStatus = event.checked;
      }
    });
    this.panelistsParticipationStatus = tempStatus;
  };

  handleSubmit = () => {
    let payload = this.panelistsParticipationStatus.map((_) => {
      return {
        interviewerId: _.interviewerId,
        attendanceStatus: _.attendanceStatus,
      };
    });
    this.loader = true;
    this.recruiterService.setPanelistAttendance(payload).subscribe(
      (res) => {
        this.loader = false;
        this.sharedService.openSnackBar('Status Saved Successfully', '', 3000);
      },
      (err) => {
        this.loader = false;
        this.sharedService.openSnackBar('Some error occurred.', '', 3000);
      }
    );
  };

  handleAddPanelistClick = () => {
    const dialogRef = this._dialog.open(AddPanelistToInterviewDialogComponent, {
      data: {
        eventId: this.eventId,
        interviewId: this.interviewId,
        emailsToFilter: this.panelists.map((_) => _.emailId),
      },
      minWidth: '500px',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.sharedService.openSnackBar(
          'Panelist(s) added successfully',
          '',
          2000
        );
        this.panelists = res;
        this.setPanelistParticipationState();
      }
    });
  };
}
