<div class="row marT16 align-items-center">
  <div class="col-md-4">
    <mat-form-field
      class="DSA_Wb_custom-form-field DSA_form_nofloatLabel"
      appearance="fill"
    >
      <mat-label>Interview Category</mat-label>
      <mat-select
        [formControl]="interviewRoundType"
        (selectionChange)="handleInterviewCategorySelect($event)"
        disableOptionCentering
        panelClass="DSA_wb-custom-select-panel DSA_wb-custom-select-withBg"
      >
        <mat-option *ngFor="let option of interviewType" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-4">
    <button
      mat-flat-button
      class="DSA_wb_Icon-btn"
      (click)="displayAssociateTemplateBlock()"
      *ngIf="!isAssociateTemplateVisible"
    >
      <span class="icon_btnCont">
        <i class="icon purple x-16 icon-add"></i>
      </span>
      <span class="icon-btn-txt">Associate New Template</span>
    </button>
  </div>
  <div class="col-md-4 d-flex justify-content-end">
    <button
      mat-flat-button
      class="DSA_wb_Icon-btn"
      [routerLink]="['/evaluation-templates']"
      matTooltip="Go to Evaluation Templates page to view existing templates"
      matTooltipClass="DSA_tooltip"
    >
      <span class="icon_btnCont">
        <i class="icon purple x-16 link-arw-icon"></i>
      </span>
      <span class="icon-btn-txt">View Templates</span>
    </button>
  </div>
</div>

<div class="row marT16" *ngIf="isAssociateTemplateVisible">
  <div class="col-12">
    <div class="DSA_panel">
      <div class="DSA_panelBody padTB24 padLR16">
        <app-infy-associate-evaluation-form
          [jobRoleId]="jobRoleId"
          [formType]="interviewRoundType.value"
          [activeTemplate]="activeTemplate"
          [inactiveTemplates]="inactiveTemplates"
          (onCancel)="isAssociateTemplateVisible = false"
          (onAssociateTemplate)="handleAssociateTemplate($event)"
        ></app-infy-associate-evaluation-form>
      </div>
    </div>
  </div>
</div>

<div class="row marB16">
  <div class="col-12">
    <mat-accordion class="DSA_wb-cus-accordion">
      <!-- Active form panel -->
      <mat-expansion-panel [expanded]="true" #activeFormPanel>
        <mat-expansion-panel-header
          [collapsedHeight]="'40px'"
          [expandedHeight]="'40px'"
        >
          <mat-panel-title> Currently Active Evaluation Form </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="mar16">
          <app-loader [display]="isFetchingTemplate"></app-loader>
          <form
            [formGroup]="createdEvaluationForm"
            *ngIf="activeTemplate && !isFetchingTemplate"
          >
            <div class="row">
              <div class="col-md-5">
                <mat-form-field class="DSA_Wb_custom-form-field">
                  <mat-label>Form Title</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="title"
                    required
                  />
                  <mat-error>Required*</mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field class="DSA_Wb_custom-form-field">
                  <mat-label>Description</mat-label>
                  <input matInput type="text" formControlName="description" />
                </mat-form-field>
              </div>
              <div class="col-md-1">
                <mat-form-field class="DSA_Wb_custom-form-field">
                  <mat-label>Version</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="version"
                    readonly
                  />
                </mat-form-field>
              </div>
              <div class="col-md-5" formGroupName="templateEvaluationForm">
                <mat-form-field class="DSA_Wb_custom-form-field">
                  <mat-label>Template Name</mat-label>
                  <input matInput type="text" formControlName="name" readOnly />
                </mat-form-field>
              </div>
            </div>

            <div class="col-12 d-flex justify-content-end">
              <button
                class="DSA_wb_miniPrimary-btn"
                (click)="updateEvaluationForm()"
                [disabled]="createdEvaluationForm.invalid || isUpdatingTemplate"
              >
                {{ isUpdatingTemplate ? "Updating ..." : "Update" }}
              </button>
            </div>

            <div class="col-12 marT8">
              <h6 class="marB8">Template Preview:</h6>
              <formio
                *ngIf="
                  createdEvaluationForm.get('templateEvaluationForm.formData')
                    .value
                "
                [form]="
                  createdEvaluationForm.get('templateEvaluationForm.formData')
                    .value
                "
              ></formio>
              <p
                *ngIf="
                  !createdEvaluationForm.get('templateEvaluationForm.formData')
                    .value
                "
              >
                No form present for preview
              </p>
            </div>
          </form>
          <div *ngIf="!activeTemplate && !isFetchingTemplate">
            No active form found.
          </div>
        </div>
      </mat-expansion-panel>

      <!-- Previous Version Panel -->
      <mat-expansion-panel #prevFormsPanel>
        <mat-expansion-panel-header
          [collapsedHeight]="'40px'"
          [expandedHeight]="'40px'"
        >
          <mat-panel-title> Previously Used Evaluation Forms </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="mar16">
          <app-infy-previous-evaluation-forms
            *ngIf="prevFormsPanel.expanded"
            [jobRoleId]="jobRoleId"
            [formType]="interviewRoundType.value"
            (onAssociateTemplate)="handleAssociateTemplate($event)"
          ></app-infy-previous-evaluation-forms>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
