<app-loader [display]="loading"></app-loader>

<div *ngIf="!loading">
  <!-- <div class="row mb-2">
    <div class="col-12">
      <h1 class="DSA_wb_h1Header">Associate Jobs</h1>
    </div>
  </div> -->

  <mat-dialog-content class="m-auto nopadLR">
    <div class="row mb-2">
      <div class="col-12">
        <h1 class="DSA_wb_h1Header">{{ currentJob.title }}</h1>
        <div
          class="mb-2"
          *ngIf="data.associationType === AssociationType.EVENT"
        >
          <strong class="font-red">
            Event level associations will override all global associations(if
            any) for the job
          </strong>
        </div>
      </div>
    </div>
    <div class="row mt-3 mb-3" *ngIf="associatedJobs.length > 0">
      <div class="col-12">
        <h2 class="DSA_wb_h2Header">Associated Jobs</h2>
      </div>
    </div>

    <div class="row" *ngIf="associatedJobs.length > 0">
      <div class="col-md-6 DSA_text-1line" *ngFor="let job of associatedJobs">
        <mat-checkbox
          [disabled] = "checkAssociationAccess()"
          class="DSA_Wb_custom-checkbox nomarLR"
          (change)="unassociateJob($event, job)"
          aria-label="checkbox"
          disableRipple="true"
          [matTooltip]="job.title"
          matTooltipClass="DSA_tooltip"
        >
          {{ job.title }}
        </mat-checkbox>
      </div>
    </div>

    <div class="d-flex justify-content-end" *ngIf="associatedJobs.length > 0">
      <button
      [disabled] ="checkAssociationAccess()"
        class="DSA_wb_miniPrimary-btn mr-3"
        type="submit"
        (click)="onUnAssociateClick()"
      >
        Un-associate
      </button>
    </div>

    <div class="row mt-3 mb-3" *ngIf="unAssociatedJobs.length > 0">
      <div class="col-md-6 d-flex flex-column justify-content-center">
        <h2 class="DSA_wb_h2Header">Unassociated Jobs</h2>
        <span
          class="DSA_wb_caption web-GreyLight nomarB marT8"
          *ngIf="data.associationType === AssociationType.EVENT"
        >
          Globally associated jobs (if present) are selected below by default.
          Please select the jobs to be associated and click on "Associate"
        </span>
      </div>
      <div class="col-md-6 d-flex justify-content-end">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <input
            matInput
            #searchInput
            placeholder="Search Job"
            [formControl]="unassociatedJobSearch"
          />
          <div matSuffix class="d-flex">
            <span class="clear-icon" *ngIf="searchInput.value">
              <i
                class="icon x-24 icon-rejected"
                (click)="unassociatedJobSearch.setValue('')"
                matTooltip="Clear Search"
                matTooltipClass="DSA_tooltip"
              >
              </i>
            </span>
          </div>
        </mat-form-field>
      </div>
    </div>

    <div class="row" *ngIf="unAssociatedJobs.length > 0">
      <div
        class="col-md-6 DSA_text-1line"
        *ngFor="let job of unassociateJobsObservable | async"
      >
        <mat-checkbox
        [disabled] = "
        checkAssociationAccess()"
          class="DSA_Wb_custom-checkbox nomarLR"
          (change)="associateJob($event, job)"
          [checked]="associateList?.includes(job.id)"
          aria-label="checkbox"
          disableRipple="true"
          [matTooltip]="job.title"
          matTooltipClass="DSA_tooltip"
        >
          {{ job.title }}
        </mat-checkbox>
      </div>
    </div>

    <div class="d-flex justify-content-end" *ngIf="unAssociatedJobs.length > 0">
      <button
        mat-button
        mat-dialog-close
        class="DSA_wb_miniSecondary-btn marR8"
      >
        Cancel
      </button>
      <button
      [disabled] = "checkAssociationAccess()"
        class="DSA_wb_miniPrimary-btn mr-3"
        type="submit"
        (click)="onAssociateClick()"
      >
        Associate
      </button>
    </div>
  </mat-dialog-content>
</div>
