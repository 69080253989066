import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { EventService } from 'src/app/services/event.service';
import { FileService } from 'src/app/services/file.service';
import { InterviewerService } from 'src/app/services/interviewer.service';
import { allowedCharactersInMailRegex } from 'src/app/app.constants';
import { SharedService } from 'src/app/services/shared.service';
@Component({
  selector: 'app-add-panelists',
  templateUrl: './add-panelists.component.html',
  styleUrls: ['./add-panelists.component.scss'],
})
export class AddPanelistsComponent implements OnInit {
  availablePanelists: any = [];
  loader = false;
  selectedPanelist: any;
  searchControl = new FormControl();
  availablePanelistObs: Observable<any[]>;
  panelistIdAvailable: any = false;
  allPanelistsInEvent: any = [];
  // @ViewChild(MatSelect, { static: false }) selectRef: MatSelect;

  isFetchingPanelists: boolean = false;
  searchResults = [];

  isError: boolean = false;

  constructor(
    public fileService: FileService,
    private interviewerService: InterviewerService,
    private eventService: EventService,
    private sharedService: SharedService
  ) {
    this.searchControl.setValidators(
      Validators.pattern(allowedCharactersInMailRegex)
    );
    this.availablePanelistObs = this.searchControl.valueChanges.pipe(
      // tslint:disable-next-line: deprecation
      startWith(''),
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap((panelist) => {
        if (!this.searchControl.touched && this.searchControl.invalid) {
          this.searchControl.markAsTouched();
        }
        // block API call if input value is not valid or value equals selected panelist email
        if (
          this.searchControl.invalid ||
          this.selectedPanelist?.emailId === panelist
        ) {
          return of([]);
        }
        this.isFetchingPanelists = true;
        const userId = panelist?.split('@')[0];
        return userId.length > 1
          ? this.interviewerService.searchInterviewer(userId).pipe(
              catchError((err) => {
                this.isFetchingPanelists = false;
                sharedService.openSnackBar(
                  'Some error occurred in finding panelist. Please try again.',
                  '',
                  3000
                );
                return of([]);
              })
            )
          : of([]);
      }),
      map((result: Array<any>) => {
        this.panelistIdAvailable = this.searchResults.find(
          (_) => _.emailId === this.searchControl.value
        );
        this.searchResults = [];
        result.forEach((element) => {
          if (
            !this.checkInclusion(
              element,
              this.allPanelistsInEvent,
              'id',
              'panelistId'
            )
          ) {
            this.searchResults.push(element);
          }
        });
        this.isFetchingPanelists = false;
        return this.searchResults.slice(0, 10);
      })
    );
  }

  ngOnInit(): void {
    this.getEventPanelists();
  }

  getEventPanelists() {
    this.loader = true;

    this.eventService.getInterviewerListWithEvent(20000, 0).subscribe(
      (interviewerList: any) => {
        this.allPanelistsInEvent = interviewerList.content;
        this.loader = false;
      },
      (err) => {
        this.isError = true;
        this.loader = false;
      }
    );
  }

  checkInclusion = (data, dataList, key1, key2) => {
    let included = false;
    dataList.forEach((element) => {
      if (data[key1] === element[key2]) {
        included = true;
      }
    });
    return included;
  };

  selected(event: MatAutocompleteSelectedEvent) {
    this.selectedPanelist = this.searchResults.find(
      (k) => k.emailId === event.option.value
    );
    // this.selectedPanelist = this.selectRef;
  }
}
