import { KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';

export function initializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => {
    // initialize keycloak only for localhost
    if (!environment.production) {
      return new Promise(async (resolve, reject) => {
        try {
          await keycloak.init({
            config: {
              url: environment.keycloak.issuer,
              realm: environment.keycloak.realm,
              clientId: environment.keycloak.clientId,
            },
            initOptions: {
              // onLoad: 'login-required',
              // checkLoginIframe: false,
              onLoad: 'check-sso',
              silentCheckSsoRedirectUri:
                window.location.origin + '/assets/silent-check-sso.html',
            },
            bearerExcludedUrls: [],
          });
          resolve(undefined);
        } catch (error) {
          reject(error);
        }
      });
    } else {
      return new Promise(async (resolve, reject) => {
        resolve(undefined);
      });
    }
  };
}
