<app-loader [display]="loading"></app-loader>

<div class="container-fluid layout" *ngIf="!loading">
  <div class="row marT8">
    <div class="col-12 d-flex justify-content-end">
      <button
        *ngIf="
          (accountService.hasFeatureAccess(FeatureName.EVENT_RECRUITER,PermissionType.CREATE))
          && eventStatus !== 'CLOSED'
        "
        mat-flat-button
        class="DSA_wb_Icon-btn"
        (click)="openAddRecruiterDialog()"
      >
        <span class="icon_btnCont">
          <i class="icon purple x-16 icon-add"></i>
        </span>
        Add Co-ordinator
      </button>
    </div>
  </div>

 
  <div class="ag-grid row">
    <div class="col-12 d-flex justify-content-end"></div>
    <div class="col-12">
      <data-grid
        (searchKey)="search($event)"
        [columnDefs]="columnDefs"
        [tableName]="tableName"
        [buttonText]="buttonText"
        [buttonComponent]="buttonComponent"
        [levels]="levels"
        [buttonCondition]="buttonCondition"
        [searchCondition]="searchCondition"
        [interface]="interface"
        [refreshAgGrid]="refreshAgGrid.asObservable()"
      ></data-grid>
    </div>
  </div>
</div>
