import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'src/app/services/shared.service';
import { CandidateInfoService } from '../../services/candidate-info.service';

@Component({
  selector: 'app-personal-details-new',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss'],
})
export class PersonalDetailsComponent implements OnInit {
  @Input() candidateProfile;
  @Input() readOnly: boolean = false;

  personalDetailsForm: FormGroup;
  maritalStatusList = ['MARRIED', 'UNMARRIED', 'DIVORCED', 'WIDOWED'];

  constructor(
    private fb: FormBuilder,
    public sharedService: SharedService,
    private candidateInfoService: CandidateInfoService
  ) {}

  ngOnInit(): void {
    this.personalDetailsForm = this.fb.group({
      id: [null],
      dob: [null, Validators.required],
      gender: ['FEMALE'],
      maritalStatus: [this.maritalStatusList, Validators.required],
      nationality: [null],
      religion: [null],
      hobby: [null],
      creditHistory: [null],
      achievements: [null],
      criminalRecord: [null],
      spouseEmployer: [null],
      orientation: [null],
      familyAnnualIncome: [null],
      domicile: [null],
    });

    this.personalDetailsForm.patchValue(this.candidateProfile);
    if (this.readOnly) {
      this.personalDetailsForm.disable();
    }
  }

  submitDetails() {
    if (this.personalDetailsForm.invalid) {
      this.sharedService.openErrorSnackBar(
        'Please fill all the required details and try again',
        ''
      );
      this.personalDetailsForm.markAllAsTouched();
      return;
    }

    this.candidateInfoService
      .patchUpdatePersonalDetails(this.personalDetailsForm.getRawValue())
      .subscribe({
        next: (res: any) => {
          this.sharedService.openSuccessSnackBar(
            'Data updated successfully',
            ''
          );
        },
        error: (err: any) => {
          this.sharedService.openErrorSnackBar(
            'Data not updated. Please try again',
            ''
          );
        },
      });
  }
}
