import {
  SimpleChanges,
  ChangeDetectorRef,
  KeyValueDiffers,
  DoCheck,
} from '@angular/core';
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/tslint/config */
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { Visibility } from 'src/app/shared/model/enumerations/visibility.model';
import { FileserverService } from 'src/app/survey-master/survey/edit-backgroud-color/fileserver.service';
import { Theme, QuestionView } from 'src/app/shared/model/theme.model';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return control && control.invalid;
  }
}

@Component({
  selector: 'jhi-yes-no-preview',
  templateUrl: './yes-no.component.html',
  styleUrls: ['./yes-no.component.scss'],
})
export class YesNoPreviewComponent implements OnInit, OnChanges, DoCheck {
  @Input()
  currPage: any;
  @Input()
  question: any;
  @Input()
  questionIndex: any;
  @Input()
  responsePage: any;
  @Input()
  section: any;
  @Input()
  preference: any;
  @Input()
  theme: Theme;

  view = 'NORMAL';
  @Input()
  disabled: Boolean = false;
  @Input()
  nextElement: HTMLElement;
  matcher = new MyErrorStateMatcher();
  control;
  yesText;
  noText;
  responseChecked;
  yesIcon;
  noIcon;
  diffResponsePage: any;
  hover: any = { positive: false, negative: false };
  constructor(
    public fileserverService: FileserverService,
    changeDetectorRef: ChangeDetectorRef,
    private differs: KeyValueDiffers
  ) {}

  ngOnInit() {
    this.control = new FormControl('');
    if (this.theme.questionView === QuestionView.TWO_COLUMNS) {
      this.view = 'TWO_COLUMNS';
    } else {
      this.view = 'NORMAL';
    }
    this.diffResponsePage = this.differs
      .find(
        this.responsePage.sections[this.section].questions[this.questionIndex]
      )
      .create();
    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response != null &&
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response.length > 0
    ) {
      // already some response present
      if (
        this.responsePage.sections[this.section].questions[this.questionIndex]
          .response[0] === 'NO'
      ) {
        this.responseChecked = false;
      } else {
        this.responseChecked = true;
      }
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response = [];
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response.push('NO');
    }
    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response !== undefined &&
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .logicallyHiddenBy !== undefined
    ) {
      this.logicPart();
    }

    this.yesText = this.getValue(this.question, 'YES_TEXT');
    this.noText = this.getValue(this.question, 'NO_TEXT');
    this.yesIcon = this.getValue(this.question, 'YES_ICON');
    this.noIcon = this.getValue(this.question, 'NO_ICON');
  }
  ngDoCheck(): void {
    const responsePageChange = this.diffResponsePage.diff(
      this.responsePage.sections[this.section].questions[this.questionIndex]
    );
    if (responsePageChange) {
      this.ngOnChanges();
    }
  }

  ngOnChanges() {
    this.control = new FormControl('');
    if (this.theme.questionView === QuestionView.TWO_COLUMNS) {
      this.view = 'TWO_COLUMNS';
    } else {
      this.view = 'NORMAL';
    }
    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response != null &&
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response.length > 0
    ) {
      // already some response present

      if (
        this.responsePage.sections[this.section].questions[this.questionIndex]
          .response[0] === 'NO'
      ) {
        this.responseChecked = false;
      } else {
        this.responseChecked = true;
      }
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response = [];
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response.push('NO');
    }
    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response !== undefined &&
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .logicallyHiddenBy !== undefined
    ) {
      this.logicPart();
    }

    this.yesText = this.getValue(this.question, 'YES_TEXT');
    this.noText = this.getValue(this.question, 'NO_TEXT');
    this.yesIcon = this.getValue(this.question, 'YES_ICON');
    this.noIcon = this.getValue(this.question, 'NO_ICON');
  }

  getClassSlide(type = 'negative') {
    if (type === 'negative') {
      if (
        this.hover.negative ||
        this.responsePage.sections[this.section].questions[this.questionIndex]
          .response[0] === 'NO'
      ) {
        //
        return '';
      } else {
        //
        return 'selected';
      }
    } else if (type === 'positive') {
      if (
        this.hover.positive ||
        this.responsePage.sections[this.section].questions[this.questionIndex]
          .response[0] === 'YES'
      ) {
        //
        return '';
      } else {
        //
        return 'selected';
      }
    }

    // let effect = type === 'negative' ? this.hover.negative : this.hover.positive;
    // if (effect) {
    //   return '';
    // }
    // if (this.questions[this.index].value === undefined) {
    //   return 'material-icons-' + this.questions[this.index].options.style;
    // }
    // effect = type === 'negative' ? !this.questions[this.index].value : this.questions[this.index].value;
    // if (effect) {
    //   return '';
    // }
    // return 'material-icons-' + this.questions[this.index].options.style;

    // const valueEffect = type === 'negative' ? !
  }

  logicPart() {
    if (this.question.logics !== null && this.question.logics.length > 0) {
      for (let i = 0; i < this.question.logics.length; i++) {
        let apply = false;
        let condition = 'END';
        for (let r = 0; r < this.question.logics[i].rules.length; r++) {
          let rapply = false;

          if (this.question.logics[i].rules[r].op === 'EQ') {
            if (
              this.responsePage.sections[this.section].questions[
                this.questionIndex
              ].response[0] === this.question.logics[i].rules[r].value
            ) {
              rapply = true;
            }
          } else if (this.question.logics[i].rules[r].op === 'NEQ') {
            if (
              this.responsePage.sections[this.section].questions[
                this.questionIndex
              ].response[0] !== this.question.logics[i].rules[r].value
            ) {
              rapply = true;
            }
          }

          if (r > 0) {
            if (condition === 'AND') {
              apply = apply && rapply;
            } else if (condition === 'OR') {
              apply = apply || rapply;
            }
          } else {
            apply = rapply;
          }
          condition = this.question.logics[i].rules[r].conjunction;
        }

        for (let a = 0; a < this.question.logics[i].actions.length; a++) {
          let foundIndex: number = null;

          const actionquestion = this.currPage.sections[
            this.section
          ].questions.find((ques, ind) => {
            if (
              ques.identifier === this.question.logics[i].actions[a].identifier
            ) {
              foundIndex = ind;
              return true;
            } else {
              return false;
            }
          });

          if (apply) {
            if (this.question.logics[i].actions[a].name === 'HIDE') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                this.responsePage.sections[this.section].questions[
                  foundIndex
                ].logicallyHidden = true;
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyHiddenBy.find(
                  (item) => item === this.question.id
                );
                if (exists === undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHiddenBy.push(this.question.id);
                }
              }
            } else if (this.question.logics[i].actions[a].name === 'SHOW') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyShownBy.find(
                  (item) => item === this.question.id
                );
                if (exists === undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyShownBy.push(this.question.id);
                }
                if (
                  this.responsePage.sections[this.section].questions[foundIndex]
                    .logicallyHiddenBy.length === 0
                ) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHidden = false;
                } else {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHidden = true;
                }
              }
            }
          } else {
            if (this.question.logics[i].actions[a].name === 'HIDE') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyHiddenBy.find(
                  (item) => item === this.question.id
                );
                if (exists !== undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHiddenBy = this.responsePage.sections[
                    this.section
                  ].questions[foundIndex].logicallyHiddenBy.filter(
                    (item) => item !== this.question.id
                  );
                }
                if (
                  this.responsePage.sections[this.section].questions[foundIndex]
                    .logicallyHiddenBy.length === 0
                ) {
                  if (
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyShownBy.length === 0
                  ) {
                    if (
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].defaultVisibile
                    ) {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = false;
                    } else {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = true;
                    }
                  } else {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = false;
                  }
                }
              }
            } else if (this.question.logics[i].actions[a].name === 'SHOW') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                this.responsePage.sections[this.section].questions[
                  foundIndex
                ].logicallyHidden = true;
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyShownBy.find(
                  (item) => item === this.question.id
                );
                if (exists !== undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyShownBy = this.responsePage.sections[
                    this.section
                  ].questions[foundIndex].logicallyShownBy.filter(
                    (item) => item !== this.question.id
                  );
                }
                if (
                  this.responsePage.sections[this.section].questions[foundIndex]
                    .logicallyShownBy.length === 0
                ) {
                  if (
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHiddenBy.length === 0
                  ) {
                    if (
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].defaultVisibile
                    ) {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = false;
                    } else {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = true;
                    }
                  } else {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = true;
                  }
                } else {
                  if (
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHiddenBy.length === 0
                  ) {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = false;
                  } else {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  downloadImage(path: string) {
    return this.fileserverService.getFileURLbyPath(path);
  }

  inputChanged(event: any) {
    if (event.checked) {
      this.responseChecked = event.checked;
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response[0] = 'YES';
    } else {
      this.responseChecked = event.checked;
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response[0] = 'NO';
    }
    if (this.nextElement != null) {
      this.nextElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setTimeout(() => {
        if (this.nextElement.getElementsByTagName('input') != null)
          this.nextElement.getElementsByTagName('input')[0].focus();
      }, 400);
    }
    this.logicPart();
  }

  getValidationValue(question: any, param: string): string {
    const object = question.type.validationValues.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }

  getValue(question: any, param: string): string {
    const object = question.type.values.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }
}
