import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedService } from 'src/app/services/shared.service';


export enum ReportType {
  CONSOLIDATED = 'CONSOLIDATED',
  EVENT = 'EVENT',
}

@Component({
  selector: 'app-generate-report',
  templateUrl: './generate-report.component.html',
  styleUrls: ['./generate-report.component.scss'],
})
export class GenerateReportComponent implements OnInit {
  generateReportForm: FormGroup;
  oneWeekBackDate;
  constructor(
    private fb: FormBuilder,
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<GenerateReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data.reportType === ReportType.CONSOLIDATED) {
      let today = new Date();
      this.oneWeekBackDate = new Date();
      this.oneWeekBackDate.setDate(today.getDate() - 7);

      this.sharedService.openSnackBar(
        'For consolidated reports, start date cannot be older than 1 week from today',
        '',
        5000
      );
      this.generateReportForm = this.fb.group({
        startDate: ['', [Validators.required]],
        endDate: ['', []],
      });
    } else {
      this.generateReportForm = this.fb.group({
        startDate: ['', []],
        endDate: ['', []],
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {}

  generateButtonDisable = () => {
    if (
      this.data.reportType === ReportType.CONSOLIDATED &&
      !this.generateReportForm.valid
    ) {
      return true;
    } else {
      return false;
    }
  };
}
