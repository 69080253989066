import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CandidateService } from 'src/app/services/candidate.service';

@Component({
  selector: 'app-add-candidate',
  templateUrl: './add-candidate.component.html',
  styleUrls: ['./add-candidate.component.scss'],
})
export class AddCandidateComponent implements OnInit {
  addCandidateForm: FormGroup;
  message: string;
  showCandidateForm: boolean = false;
  candidateSources = [
    { value: 'INFYTQ', viewValue: 'InfyTQ' },
    { value: 'HACKWITHINFY', viewValue: 'HackWithInfy' },
    { value: 'IWP', viewValue: 'IWP' },
    { value: 'SURVEY_MASTER', viewValue: 'Survey Master' },
    { value: 'INTAP', viewValue: 'INTAP' },
  ];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddCandidateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private candidateService: CandidateService
  ) {
    this.addCandidateForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/
          ),
        ],
      ],
      name: ['', [Validators.required]],
      countryCode: ['91', [Validators.required]],
      phone: ['', [Validators.required]],
      id: ['', []],
      candidateSource: [{ value: '' }, [Validators.required]],
      candidateId: ['', [Validators.required]],
      applicationID: [''],
      technology: [''],
      subUnit: [''],
      unit: [''],
    });
  }
  enableCandidateForm = () => {
    this.addCandidateForm.get('name').enable();
    this.addCandidateForm.get('countryCode').enable();
    this.addCandidateForm.get('phone').enable();
    this.addCandidateForm.get('candidateId').enable();
    this.addCandidateForm.get('candidateSource').enable();
  };
  disableCandidateForm = () => {
    this.addCandidateForm.get('name').disable();
    this.addCandidateForm.get('countryCode').disable();
    this.addCandidateForm.get('phone').disable();
    this.addCandidateForm.get('candidateId').disable();
    this.addCandidateForm.get('candidateSource').disable();
  };
  resetForm = () => {
    this.addCandidateForm.get('name').setValue('');
    this.addCandidateForm.get('countryCode').setValue('91');
    this.addCandidateForm.get('phone').setValue('');
    this.addCandidateForm.get('candidateId').setValue('');
    this.addCandidateForm.get('candidateSource').setValue('');
  };

  searchCandidate = () => {
    if (!this.addCandidateForm.controls.email.invalid) {
      this.showCandidateForm = false;
      this.resetForm();
      this.message = '';
      this.enableCandidateForm();
      this.candidateService
        .getCandidate(this.addCandidateForm.value.email)
        .subscribe(
          (res) => {
            if (res?.length > 0) {
              this.addCandidateForm.get('name').setValue(res[0]['name']);
              this.addCandidateForm
                .get('countryCode')
                .setValue(res[0]['countryCode']);
              this.addCandidateForm.get('phone').setValue(res[0]['phone']);
              this.addCandidateForm.get('id').setValue(res[0]['id']);
              this.addCandidateForm
                .get('candidateId')
                .setValue(res[0]['candidateId']);
              this.addCandidateForm
                .get('candidateSource')
                .setValue(res[0]['candidateSource']);
              this.disableCandidateForm();
            } else {
              this.message =
                'Candidate not found. Please provide candidate details.';
            }
            this.showCandidateForm = true;
          },
          () => {
            this.message =
              'Candidate not found. Please provide candidate details.';
            this.showCandidateForm = true;
          }
        );
    }
  };

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {}
}
