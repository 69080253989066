import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsteamsService } from 'src/app/services/msteams.service';

@Component({
  selector: 'app-infy-msauth',
  templateUrl: './infy-msauth.component.html',
  styleUrls: ['./infy-msauth.component.scss'],
})
export class InfyMsauthComponent implements OnInit {
  code: string;
  state: string;
  sessionState: string;
  error: string;
  errorDescription: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private msTeamsService: MsteamsService
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((queryParams) => {
      this.code = queryParams.get('code');
      this.error = queryParams.get('error');
      this.errorDescription = queryParams.get('error_description');
      if (this.code) {
        this.msTeamsService.saveAuthCodeForTeams(this.code).subscribe(
          () => {
            let appState = JSON.parse(
              window.sessionStorage.getItem('appState')
            );
            if (appState) {
              appState.authStatus = 'AUTHENTICATED';
              window.sessionStorage.setItem(
                'appState',
                JSON.stringify(appState)
              );
              this.router.navigate([appState.redirectTo]);
            } else
              this.handleError('Unexpected error occured. Please try again.');
          },
          () => {
            this.handleError('Unexpected error occured. Please try again.');
          }
        );
      } else if (this.error) {
        this.handleError();
      }
    });
  }

  handleError = (errorMessage?: string) => {
    window.sessionStorage.removeItem('appState');
    if (errorMessage) {
      this.error = errorMessage;
    }
  };
}
