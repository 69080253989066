import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EvaluationTemplateListStateStorageService {
  page = 0;
  size = 10;
  searchQuery: string = '';

  init = () => {
    this.page = 0;
    this.size = 10;
    this.searchQuery = '';
  };
}
