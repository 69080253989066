import { Component, Inject, OnInit } from '@angular/core';
import { MailTemplateService } from 'src/app/mail-template/mail-template.service';
import { NotificationTemplate } from 'src/app/mail-template/mail-template.model';
import { MailTemplateService as AppMailTemplateService } from '../../../services/mail-template.service';
import { SharedService } from 'src/app/services/shared.service';
import { AccountService } from 'src/app/services/account.service';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { EventService } from 'src/app/services/event.service';
@Component({
  selector: 'app-mail-template-selection',
  templateUrl: './mail-template-selection.component.html',
  styleUrls: ['./mail-template-selection.component.scss'],
})
export class MailTemplateSelectionComponent implements OnInit {
  templates: any = [];
  availableTemplates: any = [];
  eventMailTemplates: any = [];
  templatesByCategory: any = [];
  template: NotificationTemplate;
  categories: any = [];
  availableCategories: any = [];
  selectedCategory = '';
  edit = false;
  id;
  eventStatus: any;
  loader = true;
  FeatureName = FeatureName;
  PermissionType = PermissionType;
  data = {
    eventId: '',
  };
  constructor(
    private mailTemplateService: MailTemplateService,
    private navRouter: Router,
    private appMailTemplateService: AppMailTemplateService,
    private sharedService: SharedService,
    public accountService: AccountService,
    private route: ActivatedRoute,
    public blocation: Location,

    private eventService: EventService
  ) {
    this.eventStatus = localStorage.getItem('eventStatus');
    this.appMailTemplateService.getCategories().subscribe((res) => {
      this.categories = res;
      this.refresh();
    });
  }
  refresh = () => {
    if (
      this.eventService.selectedEventId == undefined ||
      this.eventService.selectedEventId == null
    ) {
      this.navRouter.navigate(['']);
    }
    this.appMailTemplateService
      .getMailTemplatesByEvent(this.eventService.selectedEventId)
      .subscribe((res) => {
        this.eventMailTemplates = res;
        this.getTemplatesForEvent();
        this.filterCategoriesAndTemplates(this.eventMailTemplates);
        this.loader = false;
      });
    // }
  };

  getTemplatesForEvent = () => {
    this.eventMailTemplates.forEach((template) => {
      this.appMailTemplateService
        .getMailTemplate(template.notificationTemplateId)
        .subscribe((res) => {
          this.templates.push(res);
        });
    });
  };

  filterCategoriesAndTemplates = (templates) => {
    this.availableTemplates = this.templatesByCategory.filter(
      (value) => !this.inclusion(templates, value)
    );
    this.availableCategories = this.categories.filter(
      (value) => !this.categoryInclusion(templates, value)
    );
  };

  categoryInclusion = (list, value) => {
    let flag = false;
    list.forEach((element) => {
      if (element.templateCategoryName == value.name) {
        flag = true;
      }
    });
    return flag;
  };

  inclusion = (list, value) => {
    let flag = false;
    list.forEach((element) => {
      if (element.notificationTemplateId == value.id) {
        flag = true;
      }
    });
    return flag;
  };

  enableTemplateForEvent = () => {
    this.loader = true;
    let formBody = {
      templateCategoryName: this.selectedCategory,
      notificationTemplateId: this.template.id,
    };
    if (this.data.eventId) {
      //   formBody['jobOpeningId'] = this.data.jobOpeningId;
      // } else {
      formBody['eventId'] = this.data.eventId;
    }
    if (this.edit) {
      formBody['id'] = this.id;
      this.appMailTemplateService
        .updateMailTemplateToEvent(formBody)
        .subscribe((res) => {
          this.sharedService.openSnackBar('Mapping Updated', '', 3000);
          this.cancelEdit();
          this.template = undefined;
          this.selectedCategory = '';
          this.templatesByCategory = [];
          this.refresh();
          this.loader = false;
        });
    } else {
      this.appMailTemplateService
        .setMailTemplateToEvent(formBody)
        .subscribe((res) => {
          this.sharedService.openSnackBar('Mapping Added', '', 3000);
          this.template = undefined;
          this.selectedCategory = '';
          this.templatesByCategory = [];
          this.refresh();
          this.loader = false;
        });
    }
  };

  editMode = (id, category) => {
    this.selectedCategory = category;
    this.edit = true;
    this.id = id;
    this.getTemplatesByCategory(category);
  };

  cancelEdit = () => {
    this.edit = false;
    this.template = undefined;
    this.selectedCategory = '';
    this.templatesByCategory = [];
    this.id = undefined;
  };

  getTemplateById = (id) => {
    return this.templates.filter((ele) => ele.id == id)[0];
  };

  onNoClick(): void {
    //this.dialogRef.close();
  }

  getTemplatesByCategory = (category) => {
    this.appMailTemplateService
      .getMailTemplatesByCategory(category, 100, 0)
      .subscribe((res: any) => {
        this.templatesByCategory = res.content;
        this.template = undefined;
        this.filterCategoriesAndTemplates(this.eventMailTemplates);
      });
  };

  ngOnInit(): void {
    this.data.eventId = this.route.snapshot.paramMap.get('eventId');
  }
}
