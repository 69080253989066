<ng-container *ngIf="role">
  <nav
    class="DSA_leftFixedNAv fullHeight padding80 sidenav sidenav-hide scrollable-container"
    id="mainleftNav"
  >
    <h6 class="DSA_wb_h2HeaderRegular web-GreyLight marL16 sidenav-heading">
      {{ roleUI }}
    </h6>
    <ul class="DSA_wb_listItemSingle marT8 DSA_frstLevel ">
      <li 
        *ngIf="
          accountService.hasFeatureAccess(
            FeatureName.EVENT,
            PermissionType.READ
          ) && role !== AuthorityName.ROLE_INTERVIEWER
        "
      >
        <a
          href="javascript:void(0);"
          [routerLink]="['/dashboard']"
          routerLinkActive="active-navbar"
          (click)="sideBarClose()"
        >
          <div class="marR16 float-left">
            <i class="icon x-24 services-icon"> </i>
          </div>
          <h2 class="DSA_wb_h2HeaderRegular web-GreyLight DSA_list-1line">
            Events
          </h2>
        </a>
      </li>
      <li
        *ngIf="
          role == AuthorityName.ROLE_HIRING_MANAGER ||
          role == AuthorityName.ROLE_SUPER_ADMIN
        "
      >
        <a
          href="javascript:void(0);"
          [routerLink]="['/scp/scholarships']"
          routerLinkActive="active-navbar"
          (click)="sideBarClose()"
        >
          <div class="marR16 float-left">
            <i class="icon x-24 services-icon"> </i>
          </div>
          <h2 class="DSA_wb_h2HeaderRegular web-GreyLight">Problem Statements</h2>
        </a>
      </li>
      <!-- <li *ngIf="role == AuthorityName.ROLE_HIRING_MANAGER">
        <a href="javascript:void(0)" [routerLink]="['adm/global-programme']">
          <i
            aria-hidden="true"
            class="icon x-24 personal-icon marR16 float-left"
          ></i>
          <h5 class="DSA_wb_h2HeaderRegular web-GreyLight DSA_list-1line">
            Global Scholarship Programme
          </h5>
        </a>
      </li> -->

      <!-- <li *ngIf="role == AuthorityName.ROLE_HIRING_MANAGER || role == AuthorityName.ROLE_SUPER_ADMIN">
        <a href="javascript:void(0)" [routerLink]="['scp/applications']">
          <i
            aria-hidden="true"
            class="icon x-24 upload-icon marR16 float-left"
          ></i>
          <h5 class="DSA_wb_h2HeaderRegular web-GreyLight DSA_list-1line">
            Validate/Upload Applications
          </h5>
        </a>
      </li> -->

      <li *ngIf="role == AuthorityName.ROLE_SUPER_ADMIN">
        <a href="javascript:void(0)" [routerLink]="['scp/manage-access']">
          <i
            aria-hidden="true"
            class="icon x-24 team-icon marR16 float-left"
          ></i>
          <h5 class="DSA_wb_h2HeaderRegular web-GreyLight DSA_list-1line">
            Manage Access
          </h5>
        </a>
      </li>

      <li *ngIf="role == AuthorityName.ROLE_SUPER_ADMIN">
        <a href="javascript:void(0)" [routerLink]="['scp/departments']">
          <i
            aria-hidden="true"
            class="icon x-24 tree-icon marR16 float-left"
          ></i>
          <h5 class="DSA_wb_h2HeaderRegular web-GreyLight DSA_list-1line">
            Departments
          </h5>
        </a>
      </li>

      <li *ngIf="role === AuthorityName.ROLE_CANDIDATE">
        <a
          href="javascript:void(0)"
          [routerLink]="['candidate/view-applications']"
        >
          <i
            aria-hidden="true"
            class="icon x-24 icon_list_view marR16 float-left"
          ></i>
          <h5 class="DSA_wb_h2HeaderRegular web-GreyLight DSA_list-1line">
            Applied Problem Statements
          </h5>
        </a>
      </li>
      <li *ngIf="role === AuthorityName.ROLE_CANDIDATE">
        <a
          href="javascript:void(0)"
          [routerLink]="['candidate/problem-statements']"
        >
          <i
            aria-hidden="true"
            class="icon x-24 upload-icon marR16 float-left"
          ></i>
          <h5 class="DSA_wb_h2HeaderRegular web-GreyLight DSA_list-1line">
            All Problem Statements
          </h5>
        </a>
      </li>
      <li *ngIf="role === AuthorityName.ROLE_INTERVIEWER">
        <a
          href="javascript:void(0);"
          [routerLink]="['/interviewer-dashboard']"
          routerLinkActive="active-navbar"
          (click)="sideBarClose()"
        >
          <div class="marR16 float-left">
            <i class="icon x-24 services-icon"> </i>
          </div>
          <h2 class="DSA_wb_h2HeaderRegular web-GreyLight">Dashboard</h2>
        </a>
      </li>
      <li
        *ngIf="
          accountService.hasFeatureAccess(
            FeatureName.ANALYTICS,
            PermissionType.READ
          )
          || role === AuthorityName.ROLE_SUPER_ADMIN
          || role === AuthorityName.ROLE_HIRING_MANAGER
        "
      >
        <a
          href="javascript:void(0);"
          [routerLink]="['/analytics-dashboard']"
          routerLinkActive="active-navbar"
          (click)="sideBarClose()"
        >
          <div class="marR16 float-left">
            <i class="icon x-24 graph-icon"> </i>
          </div>
          <h2 class="DSA_wb_h2HeaderRegular web-GreyLight">
            Analytics Dashboard
          </h2>
        </a>
      </li>
      <li
        *ngIf="
          accountService.hasFeatureAccess(FeatureName.JOBS, PermissionType.READ)
        "
      >
        <a
          href="javascript:void(0);"
          [routerLink]="['/infy-jobs-admin']"
          routerLinkActive="active-navbar"
          (click)="sideBarClose()"
        >
          <div class="marR16 float-left">
            <i class="icon x-24 team-icon"> </i>
          </div>
          <h2 class="DSA_wb_h2HeaderRegular web-GreyLight">Jobs</h2>
        </a>
      </li>
      <li *ngIf="role == AuthorityName.ROLE_SUPER_ADMIN">
        <a
          href="javascript:void(0);"
          [routerLink]="['/evaluation-templates']"
          routerLinkActive="active-navbar"
          (click)="sideBarClose()"
        >
          <div class="marR16 float-left">
            <i class="icon x-24 icon-form"> </i>
          </div>
          <h2 class="DSA_wb_h2HeaderRegular web-GreyLight">
            Evaluation Templates (FormIO)
          </h2>
        </a>
      </li>
      <li *ngIf="role == AuthorityName.ROLE_SUPER_ADMIN">
        <a
          href="javascript:void(0);"
          [routerLink]="['scp-manage-mail-templates']"
          routerLinkActive="active-navbar"
          (click)="sideBarClose()"
        >
          <div class="marR16 float-left">
            <i class="icon x-24 mail-icon"> </i>
          </div>

          <h2 class="DSA_wb_h2HeaderRegular web-GreyLight">Mail Templates</h2>
        </a>
      </li>

      <li
        *ngIf="
          accountService.hasFeatureAccess(
            FeatureName.DATA_MIGRATION,
            PermissionType.CREATE
          )
        "
        (click)="openMigrationUploadDialog()"
      >
        <a
          href="javascript:void(0);"
          routerLinkActive="active-navbar"
          (click)="sideBarClose()"
        >
          <div class="marR16 float-left">
            <i class="icon x-24 icon_cloud_storage"> </i>
          </div>

          <h2 class="DSA_wb_h2HeaderRegular web-GreyLight">Migrate Data</h2>
        </a>
      </li>

      <li *ngIf="role == AuthorityName.ROLE_SUPER_ADMIN||role === AuthorityName.ROLE_HIRING_MANAGER">
        <a
          href="javascript:void(0);"
          [routerLink]="['/user-access-management']"
          routerLinkActive="active-navbar"
          (click)="sideBarClose()"
        >
          <div class="marR16 float-left">
            <i class="icon x-24 services-icon"> </i>
          </div>
          <h2 class="DSA_wb_h2HeaderRegular web-GreyLight">
            User Access Management
          </h2>
        </a>
      </li>

      <li *ngIf="role == AuthorityName.ROLE_VERIFIER">
        <a href="javascript:void(0)" [routerLink]="['scp/candidates']">
          <i
            aria-hidden="true"
            class="icon x-24 team-icon marR16 float-left"
          ></i>
          <h5 class="DSA_wb_h2HeaderRegular web-GreyLight DSA_list-1line">
             Applications
          </h5>
        </a>
      </li>

      <li *ngIf="role == AuthorityName.ROLE_VERIFIER_ADMIN">
        <a
          href="javascript:void(0);"
          [routerLink]="['/scp/verifier-admin/provisional-approved-candidates']"
          routerLinkActive="active-navbar"
          (click)="sideBarClose()"
        >
          <div class="marR16 float-left">
            <i class="icon x-24 services-icon"> </i>
          </div>
          <h2 class="DSA_wb_h2HeaderRegular web-GreyLight">
            Scholarship Approved Candidates
          </h2>
        </a>
      </li>

      <li *ngIf="role == AuthorityName.ROLE_SUPPORT_EXECUTIVE">
        <a
          href="javascript:void(0);"
          [routerLink]="['/scp/support-executive/assigned-tickets']"
          routerLinkActive="active-navbar"
          (click)="sideBarClose()"
        >
          <div class="marR16 float-left">
            <i class="icon x-24 services-icon"> </i>
          </div>
          <h2 class="DSA_wb_h2HeaderRegular web-GreyLight">
            Assigned Tickets
          </h2>
        </a>
      </li>

      <!-- <li
        (click)="openRaiseExepctionDialog()"
      >
        <a
          href="javascript:void(0);"
          routerLinkActive="active-navbar"
          (click)="sideBarClose()"
        >
          <div class="marR16 float-left">
            <i class="icon x-24 icon_Flag"> </i>
          </div>

          <h2 class="DSA_wb_h2HeaderRegular web-GreyLight">Raise Feature Exception</h2>
        </a>
      </li> -->
    </ul>
  </nav>
</ng-container>
