import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FaceRecognitionService {
  private headers = new HttpHeaders()
    .set('content-type', 'application/json');

  constructor(private http: HttpClient) {}

  getFaceRecognitionData = (cir) => {
    return this.http.get<any[]>(
      environment.interviewServiceUrl +
        `api/face-validation-reports/candidate-interview-rounds/${cir}`,
      { headers: this.headers }
    );
  };

  setFaceRecognitionFeedback = (data) => {
    return this.http.put<any[]>(
      environment.interviewServiceUrl +
        `api/face-validation-reports/update-recruiter-feedback`,
      data,
      { headers: this.headers }
    );
  };
}
