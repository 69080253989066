import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [],
  imports: [NgbModule],
  exports: [NgbModule],
  providers: [],
})
export class AppBootstrapModule {}
