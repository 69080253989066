import { Component } from '@angular/core';
import { id } from '@swimlane/ngx-charts';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
//import { RecruiterDashboardStateStorageService } from 'src/app/infy-components/infy-recruiter-dashboard/recruiter-dashboard-state-storage.service';

@Component({
  templateUrl: './dynamic-dashboard-renderer.component.html',
  styleUrls: ['./dynamic-dashboard-renderer.component.scss'],
})
export class DynamicDashboardRendererComponent
  implements AgRendererComponent
{
  params: any;
  skills_candidate: String;
  skills_panelist: String;
  eventId: String;
  // link: any;

  constructor(
  ) {}

  agInit(params: any): void {
    this.params = params;
    //console.log(params);
    if (params.data) {
      // console.log(params.data);
      if (params.type === 'skill') {
        this.skills_candidate = params.data?.skill;
        // console.log(this.skills_candidate);
      } else if (params.type === 'skills') {
        this.skills_panelist = params.data?.skills;
        //console.log(this.skills_panelist);
      }
      // params.node.setRowHeight(params.data?.panelists.length * 50);
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  checkboxToggle = (event, id) => {
    // if (event.checked) {
    //   if (!this.recruiterDashboardStateStorageService.getCheckedStatus(id)) {
    //     this.recruiterDashboardStateStorageService.addCheckedEvent(id);
    //   }
    // } else {
    //   this.recruiterDashboardStateStorageService.removeCheckedEvent(id);
    // }
    
  };

  getEventCheckedStatus = (id) => {
 // return this.recruiterDashboardStateStorageService.getCheckedStatus(id);
  };
}
