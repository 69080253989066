import { Moment } from 'moment';
import { IPage } from 'src/app/shared/model/page.model';
import { IMetadata } from 'src/app/shared/model/metadata.model';

export interface ISurveyDefinition {
  id?: number;
  jobOpeningId?: number;
  identifier?: string;
  createTime?: Moment;
  title?: string;
  description?: string;
  preferenceId?: number;
  themeId?: number;
  surveyStatusId?: number;
  pages?: IPage[];
  postSubmitViewTemplateId?: number;
  metadata?: IMetadata[];
}

export class SurveyDefinition implements ISurveyDefinition {
  constructor(
    public id?: number,
    public jobOpeningId?: number,
    public identifier?: string,
    public createTime?: Moment,
    public title?: string,
    public description?: string,
    public preferenceId?: number,
    public themeId?: number,
    public pages?: IPage[],
    public postSubmitViewTemplateId?: number,
    public metadata?: IMetadata[],
    public status?: string
  ) {}
}
