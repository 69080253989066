import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
@Component({
  selector: 'send-back-to-panelist',
  templateUrl: './send-back-to-panelist.component.html',
  styleUrls: ['./send-back-to-panelist.component.scss'],
})
export class SendBackToPanelistComponent implements OnInit {
  @Input() remarks: string;
  @Input() disabled: boolean;
  @Output() remarksChange = new EventEmitter();
  @Output() onSubmit = new EventEmitter();
  eventStatus: any;
  FeatureName = FeatureName;
  PermissionType = PermissionType;


  constructor(
    public accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.eventStatus = localStorage.getItem('eventStatus');
  }

  handleRemarksChange = (remark) => {
    this.remarksChange.emit(remark);
  };

  handleSubmit = (e) => {
    this.onSubmit.emit(e);
  };
}
