/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/tslint/config */

/// <reference types="@types/dom-mediacapture-record" />
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  OnChanges,
} from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { Visibility } from 'src/app/shared/model/enumerations/visibility.model';
import { FileserverService } from 'src/app/survey-master/survey/edit-backgroud-color/fileserver.service';
import { Theme, QuestionView } from 'src/app/shared/model/theme.model';

import { S3 } from 'aws-sdk';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { AWSSecurityService } from 'src/app/survey-master/survey-details/aws-security.service';
import { SurveyObject } from 'src/app/shared/model/survey-object.model';
import { HttpErrorResponse } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return control && control.invalid;
  }
}
// declare let MediaRecorder: any;
// declare let ol: any;

@Component({
  selector: 'jhi-video-upload-preview',
  templateUrl: './video-upload.component.html',
  styleUrls: ['./video-upload.component.scss'],
})
export class VideoUploadPreviewComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input()
  currPage: any;
  @Input()
  question: any;
  @Input()
  questionIndex: any;
  @Input()
  responsePage: any;
  @Input()
  section: any;
  @Input()
  preference: any;
  @Input()
  theme: Theme;

  view = 'NORMAL';
  @Input()
  disabled: Boolean = false;
  @Input()
  surveyDefinition: SurveyObject;

  matcher = new MyErrorStateMatcher();

  control: FormControl;

  @ViewChild('cameraVideo', { static: false }) input;
  @ViewChild('cameraVideoPreview', { static: false }) inputPreview;
  startDisabled = true;
  stopDisabled = true;
  srcObject: any;
  addVideoSuccessfull = false;
  showPreview = true;
  recorder: MediaRecorder;
  chunks: any[];
  mediaOptions = {
    video: {
      tag: 'video',
      type: 'video/webm',
      ext: '.mkv',
      allowedExt: '.mkv,.mp4',
      accept: 'video/webm,video/mp4',
      constraint: { video: true, audio: true },
    },
    audio: {
      tag: 'audio',
      type: 'audio/ogg',
      ext: '.ogg',
      allowedExt: '.mkv,.ogg,.mp3',
      accept: 'audio/webm,audio/mp3,audio/ogg',
      constraint: { audio: true },
    },
  };
  media: any;

  stream: any = null;
  addVideoText = 'Add Video/Audio';
  peerConnectionByClientId: any = {};
  s3: S3;
  s3FileName: string;
  addVideoDisabled = false;
  bucketName = 'surveymaster-test';
  muted = 'muted';
  isStream = true;
  allowReupload = false;
  uploadedFile: File;
  uploaded = false;

  private readonly notifier: NotifierService;

  constructor(
    public fileserverService: FileserverService,
    private http: HttpClient,
    private awsSecurityService: AWSSecurityService,
    notifierService: NotifierService,
    private spinner: NgxSpinnerService
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.control = new FormControl('');
    if (this.theme.questionView === QuestionView.TWO_COLUMNS) {
      this.view = 'TWO_COLUMNS';
    } else {
      this.view = 'NORMAL';
    }

    // this.s3 = new S3({ accessKeyId, secretAccessKey, params: { Bucket: this.bucketName } });
    const fileName =
      this.question.id +
      Date.now().toString(36) +
      Math.random().toString(36).substr(2, 5);

    this.s3FileName = this.surveyDefinition.id + '/' + fileName;

    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response != null &&
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response.length > 0
    ) {
      // already some response present
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response = [];
      // this.addVideo();
    }
    if (this.getValue(this.question, 'AUDIO_ONLY') === 'true') {
      this.media = this.mediaOptions.audio;
    } else {
      this.media = this.mediaOptions.video;
    }
    if (this.getValue(this.question, 'RESPONSE_TYPE') === 'stream') {
      this.isStream = true;
    } else if (this.getValue(this.question, 'RESPONSE_TYPE') === 'upload') {
      this.isStream = false;
    }
    if (this.getValue(this.question, 'ALLOW_REUPLOAD') === 'true') {
      this.allowReupload = true;
    } else {
      this.allowReupload = false;
    }
  }
  ngAfterViewInit() {
    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response != null &&
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response.length > 0
    ) {
      // already some response present
    } else {
      // this.responsePage.sections[this.section].questions[this.questionIndex].response = [];

      if (this.isStream) this.addVideo();
    }
  }

  ngOnChanges() {
    if (this.theme.questionView === QuestionView.TWO_COLUMNS) {
      this.view = 'TWO_COLUMNS';
    } else {
      this.view = 'NORMAL';
    }
    const fileName =
      this.question.id +
      Date.now().toString(36) +
      Math.random().toString(36).substr(2, 5);

    this.s3FileName = this.surveyDefinition.id + '/' + fileName;

    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response != null &&
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response.length > 0
    ) {
      // already some response present
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response = [];
      // this.addVideo();
    }
    if (this.getValue(this.question, 'AUDIO_ONLY') === 'true') {
      this.media = this.mediaOptions.audio;
    } else {
      this.media = this.mediaOptions.video;
    }
    if (this.getValue(this.question, 'RESPONSE_TYPE') === 'stream') {
      this.isStream = true;
    } else if (this.getValue(this.question, 'RESPONSE_TYPE') === 'upload') {
      this.isStream = false;
    }
    if (this.getValue(this.question, 'ALLOW_REUPLOAD') === 'true') {
      this.allowReupload = true;
    } else {
      this.allowReupload = false;
    }
  }

  downloadImage(path: string) {
    return this.fileserverService.getFileURLbyPath(path);
  }

  putMedia(data: any, chunkno: number, uploaded: boolean) {
    this.spinner.show('uploadSpinner');
    //
    // const createBucket = await s3.createBucket({Bucket: "surveyResponse"}).promise();

    const chunkName =
      chunkno +
      Date.now().toString(36) +
      Math.random().toString(36).substr(2, 5) +
      this.media.ext;

    if (uploaded) {
      this.awsSecurityService
        .getPresignedUrl(this.s3FileName + '/' + chunkName)
        .subscribe(
          (presignedUrl: HttpResponse<any>) => {
            this.http
              .put<any>(presignedUrl.body, this.uploadedFile, {
                observe: 'response',
              })
              .subscribe(
                (s3response) => {
                  this.spinner.hide('uploadSpinner');
                  this.notifier.notify('success', 'Uploaded Succefully');
                },
                (s3error) => {
                  this.spinner.hide('uploadSpinner');
                  this.notifier.notify(
                    'error',
                    'Could not upload the file! please try again.'
                  );
                }
              );
          },
          (res: HttpErrorResponse) => {
            this.spinner.hide('uploadSpinner');
            this.notifier.notify(
              'error',
              'Something went wrong! Uploading failed.'
            );
          }
        );
      // this.s3
      // .upload({
      //   Bucket: this.bucketName,
      //   Key: this.s3FileName + '/' + chunkName,
      //   Body: this.uploadedFile
      // })
      // .promise()
      // .then(success => {
      //
      //   this.spinner.hide("uploadSpinner");
      //   this.notifier.notify('success','Uploaded Succefully');
      // })
      // .catch(error => {
      //   this.spinner.hide("uploadSpinner");
      //   this.notifier.notify('error',"Could not upload the file! please try again");
      //
      // });
    } else {
      const file = new File([data], chunkName, { type: this.media.type });
      this.awsSecurityService
        .getPresignedUrl(this.s3FileName + '/' + chunkName)
        .subscribe(
          (presignedUrl: HttpResponse<any>) => {
            this.http
              .put<any>(presignedUrl.body, file, { observe: 'response' })
              .subscribe(
                (s3response) => {
                  this.spinner.hide('uploadSpinner');
                  this.notifier.notify('success', 'Uploaded Succefully');
                },
                (s3error) => {
                  this.spinner.hide('uploadSpinner');
                  this.notifier.notify(
                    'error',
                    'Could not upload the file! please try again.'
                  );
                }
              );
          },
          (res: HttpErrorResponse) => {
            this.spinner.hide('uploadSpinner');
            this.notifier.notify(
              'error',
              'Something went wrong! Uploading failed.'
            );
          }
        );
      // this.s3
      // .upload({
      //   Bucket: this.bucketName,
      //   Key: this.s3FileName + '/' + chunkName,
      //   Body: file
      // })
      // .promise()
      // .then(success => {
      //
      //   this.spinner.hide("uploadSpinner");
      //   this.notifier.notify('success','Uploaded Succefully');
      // })
      // .catch(error => {
      //   this.spinner.hide("uploadSpinner");
      //   this.notifier.notify('error',"Could not upload the file! please try again");
      //
      // });
    }

    // this.awsSecurityService.getPresignedUrl(this.s3FileName+'/'+chunkName).subscribe((presignedUrl:HttpResponse<any>)=>{
    //
    //   this.http.put<any>(presignedUrl.body,file,{observe:'response'}).subscribe(s3response=>{
    //
    //   },s3error=>{
    //
    //   })

    // },(res:HttpErrorResponse)=>{
    //
    // } )

    //   const kinesisVideoClient = new KinesisVideo({
    //     region,
    //     accessKeyId,
    //     secretAccessKey,
    //     correctClockSkew: true,
    //   });

    //   const params = {
    //     APIName: "PUT_MEDIA",
    //     StreamARN : "arn:aws:kinesisvideo:us-east-2:413379950444:stream/test_stream_sm/1599720666822"
    //   }
    //
    //   const endpoint = await  kinesisVideoClient.getDataEndpoint(params).promise();
    //
    //   const dateforhead = moment(new Date()).format('YYYYMMDDTHHmmssZ');
    //   const producerdate = String(new Date().getTime());
    //   const opts = {
    //     host: endpoint.DataEndpoint,
    //     path: '/putMedia',
    //     region,
    //     headers: {
    //     "x-amzn-fragment-timecode-type":"ABSOLUTE",
    //     'x-amzn-producer-start-timestamp':producerdate,
    //     'x-amzn-stream-arn':"arn:aws:kinesisvideo:us-east-2:413379950444:stream/test_stream_sm/1599720666822",
    //     'Content-Type':'video/x-matroska;codecs=avc1,opus',
    //     'Content-Length':data.size
    //     },
    //     body: data
    //   }
    //
    //   // const signer = new aws4.RequestSigner({

    //   //   host: endpoint.DataEndpoint,
    //   //   path: '/putMedia',
    //   //   method: 'POST',
    //   //   headers: {
    //   //     "x-amzn-fragment-timecode-type":"ABSOLUTE",
    //   //     'x-amzn-producer-start-timestamp':producerdate,
    //   //     'x-amzn-stream-arn':"arn:aws:kinesisvideo:us-east-2:413379950444:stream/test_stream_sm/1599720666822"
    //   //   }
    //   // })
    //   // const signature = signer.signature();

    //   const signed = aws4.sign(opts, { accessKeyId, secretAccessKey })
    //
    //

    //   //

    //   let headers = new HttpHeaders();
    //   headers = headers.set('x-amzn-stream-arn',"arn:aws:kinesisvideo:us-east-2:413379950444:stream/test_stream_sm/1599720666822");
    //   headers = headers.set('x-amzn-fragment-timecode-type','ABSOLUTE');
    //   headers = headers.set('x-amzn-producer-start-timestamp',producerdate);
    //   headers = headers.set('X-Amz-Date',signed.headers['X-Amz-Date']);
    //   headers = headers.set('Authorization',signed.headers.Authorization);
    //   // headers = headers.set('Content-Length',"13");
    //

    //   // const stream = await kinesisVideoClient.createStream({ StreamName:'test_stream_sm',DataRetentionInHours:48}).promise();
    //   //

    //   this.http.post(endpoint.DataEndpoint+'/putMedia',data,{observe:'response',headers}).subscribe(response =>{
    //
    //   },reserror=>{
    //
    //   })

    // const request = new HttpRequest(new Endpoint(endpoint.DataEndpoint+'/putMedia'),region);
    // request.method = 'POST';
    // request.body = data;
    // request.headers['host'] = endpoint.DataEndpoint;
    // const credentials = new Credentials({accessKeyId,secretAccessKey});

    // const s3 = new S3({

    // });
  }

  addVideo() {
    // const channelARN = 'arn:aws:kinesisvideo:us-east-2:413379950444:channel/test2/1599711999723';

    // const clientId = 'RANDOM_VALUE';

    // const kinesisVideoClient = new KinesisVideo({
    //   region,
    //   accessKeyId,
    //   secretAccessKey,
    //   correctClockSkew: true,
    // });

    // kinesisVideoClient
    //   .describeSignalingChannel({
    //     ChannelName: 'test2',
    //   })
    //   .promise().then(describeSignalingChannelResponse => {
    //     const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;

    //     kinesisVideoClient
    //       .getSignalingChannelEndpoint({
    //         ChannelARN: channelARN,
    //         SingleMasterChannelEndpointConfiguration: {
    //           Protocols: ['WSS', 'HTTPS'],
    //           Role: Role.MASTER,
    //         },
    //       })
    //       .promise().then(getSignalingChannelEndpointResponse => {
    //
    //         const endpointsByProtocol: any = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
    //           endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
    //           return endpoints;
    //         }, {});

    //         const wssep = getSignalingChannelEndpointResponse.ResourceEndpointList.find(item => item.Protocol === 'WSS');
    //         const httpsep = getSignalingChannelEndpointResponse.ResourceEndpointList.find(item => item.Protocol === 'HTTPS');
    //

    //         this.signalingClient = new SignalingClient({
    //           channelARN,
    //           channelEndpoint: wssep.ResourceEndpoint,
    //           role: Role.MASTER,
    //           region,
    //           credentials: {
    //             accessKeyId,
    //             secretAccessKey

    //           },
    //           systemClockOffset: kinesisVideoClient.config.systemClockOffset,
    //         });

    //         const kinesisVideoSignalingChannelsClient = new KinesisVideoSignalingChannels({
    //           region,
    //           accessKeyId,
    //           secretAccessKey,

    //           endpoint: httpsep.ResourceEndpoint,
    //           correctClockSkew: true,
    //         });
    //         kinesisVideoSignalingChannelsClient
    //           .getIceServerConfig({
    //             ChannelARN: channelARN,
    //           })
    //           .promise().then(getIceServerConfigResponse => {
    //
    //             const iceServers = [];
    //             const configuration: RTCConfiguration = {
    //               iceServers,
    //               iceTransportPolicy: 'all',
    //             };

    // iceServers.push({ urls: `stun:stun.kinesisvideo.${region}.amazonaws.com:443` });
    //   getIceServerConfigResponse.IceServerList.forEach(iceServer =>
    //     iceServers.push({
    //         urls: iceServer.Uris,
    //         username: iceServer.Username,
    //         credential: iceServer.Password,
    //     }),
    // );
    //
    //

    //           this.signalingClient.on('open',  () => {
    //
    //           });

    //           this.signalingClient.on('sdpOffer',  (offer, remoteClientId) => {
    //

    //             // Create a new peer connection using the offer from the given client
    //             const peerConnection = new RTCPeerConnection(configuration);
    //             this.peerConnectionByClientId[remoteClientId] = peerConnection;

    //             // Send any ICE candidates to the other peer
    //             peerConnection.addEventListener('icecandidate', ({ candidate }) => {
    //               if (candidate) {
    //                 // nothing
    //               } else {

    //
    //                 this.signalingClient.sendSdpAnswer(peerConnection.localDescription, remoteClientId);

    //               }
    //             });

    //             // As remote tracks are received, add them to the remote view
    //             peerConnection.addEventListener('track', event => {
    //

    //             });

    //             // If there's no video/audio, master.localStream will be null. So, we should skip adding the tracks from it.
    //             if (this.stream) {
    //               this.stream.getTracks().forEach(track => peerConnection.addTrack(track, this.stream));
    //             }
    //             peerConnection.setRemoteDescription(offer).then(async success=>{
    //               // Create an SDP answer to send back to the client
    //
    //               peerConnection.setLocalDescription(
    //                 await peerConnection.createAnswer({
    //                   offerToReceiveAudio: true,
    //                   offerToReceiveVideo: true,
    //                 }),
    //               );
    //             })

    //           });

    //           this.signalingClient.on('iceCandidate',  (candidate, remoteClientId) => {
    //

    //             // Add the ICE candidate received from the client to the peer connection
    //             const peerConnection = this.peerConnectionByClientId[remoteClientId];
    //             peerConnection.addIceCandidate(candidate);
    //           });
    //           this.signalingClient.open();
    //         })

    //     });

    // });

    navigator.mediaDevices
      .getUserMedia(this.media.constraint)
      .then((stream) => {
        this.startDisabled = false;
        this.recorder = new MediaRecorder(stream);

        if (this.input !== undefined) this.input.nativeElement.muted = true;
        this.srcObject = stream;
        this.stream = stream;
        if (this.input !== undefined) this.input.nativeElement.muted = true;
        if (this.showPreview && this.allowReupload) {
          // dont init again
        } else {
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].response.push(this.bucketName + '/' + this.s3FileName);
        }

        this.showPreview = false;
        // this.s3.putObject({ Key: fileName, Bucket: this.bucketName }, function(putObjectErr, putObjectData) {
        //   if (putObjectErr) {
        //
        //   } else {
        //
        //   }
        // });

        this.recorder.ondataavailable = (e) => {
          //
          this.chunks.push(e.data);
          if (!this.allowReupload)
            this.putMedia(e.data, this.chunks.length, false);
          if (this.recorder.state === 'inactive') {
            const blob = new Blob(this.chunks, { type: this.media.type });

            const url = URL.createObjectURL(blob);
            const mt = document.createElement(this.media.tag);
            mt.src = url;
            mt.controls = true;
            mt.autoplay = false;
            mt.style.width = '95%';
            mt.style.height = 'inherit';
            if (this.getValue(this.question, 'AUDIO_ONLY') === 'true') {
              mt.style.height = '70px';
            }
            this.inputPreview.nativeElement.innerHTML = '';
            this.inputPreview.nativeElement.appendChild(mt);
            this.showPreview = true;

            stream.getTracks().forEach((track) => {
              track.stop();
            });
            stream.getAudioTracks().forEach((audioTrack) => {
              audioTrack.stop();
            });
            stream.getVideoTracks().forEach((videoTracks) => {
              videoTracks.stop();
            });
            if (this.stream !== null) {
              this.stream.getTracks().forEach((track) => {
                track.stop();
              });
              this.stream.getAudioTracks().forEach((audioTrack) => {
                audioTrack.stop();
              });
              this.stream.getVideoTracks().forEach((videoTracks) => {
                videoTracks.stop();
              });
              this.stream = null;
            }

            stream = null;

            //
          }
        };
        this.addVideoSuccessfull = true;
      })
      .catch((error) => {
        this.addVideoSuccessfull = false;
        this.notifier.notify(
          'error',
          'Could not fetch camera, Make sure you have a camera and microphone'
        );
      });
  }

  dataAvailableHandler(e) {}

  start() {
    this.startDisabled = true;
    this.stopDisabled = false;
    this.chunks = [];
    this.recorder.start();
    if (this.recorder.state !== 'inactive') {
      // navigator.mediaDevices.getUserMedia(this.media.constraint).then(stream => {
      //   this.srcObject = stream;
      //   this.stream = stream;
      // });
    }
  }

  stop() {
    this.addVideoDisabled = true;
    this.startDisabled = true;
    this.stopDisabled = true;
    this.recorder.stop();
    this.showPreview = true;
    this.addVideoText = 'Change Video/Audio';
    this.stream.getTracks().forEach((track) => {
      //
      track.stop();
    });
    this.stream.getAudioTracks().forEach((audioTrack) => {
      audioTrack.stop();
    });
    this.stream.getVideoTracks().forEach((videoTracks) => {
      videoTracks.stop();
    });

    this.stream = null;
    this.input.nativeElement.pause();
    this.srcObject = null;
    // this.signalingClient.close();
    // this.signalingClient = null;

    // Object.keys(this.peerConnectionByClientId).forEach(clientId => {
    //   this.peerConnectionByClientId[clientId].close();
    // });
    // const blob = new Blob(this.chunks, { type: this.media.type });
    //
    // const url = URL.createObjectURL(blob);
    // const mt = document.createElement(this.media.tag);
    // mt.src = url;
    // mt.controls = true;
    // mt.autoplay = false;
    // this.inputPreview.nativeElement.appendChild(mt);
    // this.showPreview = true;
  }

  submit() {
    if (this.isStream) {
      for (let i = 0; i < this.chunks.length; i++) {
        this.putMedia(this.chunks[i], i + 1, false);
      }
      this.startDisabled = true;
      this.stopDisabled = true;
      this.allowReupload = false;
    } else {
      if (this.uploadedFile !== null && this.uploadedFile !== undefined) {
        const blob = this.uploadedFile.slice(
          0,
          this.uploadedFile.size,
          this.uploadedFile.type
        );
        this.putMedia(blob, 1, true);
        this.uploaded = true;
      } else {
        //notify
      }
    }
  }

  uploadVideo(event: any) {
    const file = event.target.files[0];
    if (!this.uploaded) {
      const lastIndex = file.name.lastIndexOf('.');
      if (lastIndex === -1) {
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].isValid = false;
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].validationMessage =
          'Only ' + this.media.allowedExt + ' files are allowed';
      } else {
        const ext = file.name.substr(lastIndex + 1).toLowerCase();

        if (this.media.allowedExt.includes(ext)) {
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].isValid = true;
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].validationMessage = '';
          this.uploadedFile = file;
          const blob = this.uploadedFile.slice(
            0,
            this.uploadedFile.size,
            this.uploadedFile.type
          );

          const url = URL.createObjectURL(blob);
          const mt = document.createElement(this.media.tag);
          mt.src = url;
          mt.controls = true;
          mt.autoplay = false;
          mt.style.width = '95%';
          mt.style.height = 'inherit';
          if (this.getValue(this.question, 'AUDIO_ONLY') === 'true') {
            mt.style.height = '70px';
          }
          this.inputPreview.nativeElement.innerHTML = '';
          this.inputPreview.nativeElement.appendChild(mt);
        } else {
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].isValid = false;
          this.responsePage.sections[this.section].questions[
            this.questionIndex
          ].validationMessage =
            'Only ' + this.media.allowedExt + ' files are allowed';
        }
      }
    } else {
      //notify
      this.notifier.notify('warn', 'You have already uploaded your response!');
    }
  }

  inputChanged() {}

  getValidationValue(question: any, param: string): string {
    const object = question.type.validationValues.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }

  getValue(question: any, param: string): string {
    const object = question.type.values.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }
}
