import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class InterviewService {
  editInterviewState: boolean;
  eventId: any;

  constructor(private http: HttpClient) {}

  private headers = new HttpHeaders()
    .set('content-type', 'application/json');

  setInterviewEventId = (id) => {
    this.eventId = id;
  };

  getInterviewEventId = () => {
    return this.eventId;
  };

  getInterviewLink = (interviewId: number): Observable<any> => {
    return this.http.get(
      `${environment.meetingServiceUrl}api/meetings/interview/${interviewId}`,
      { headers: this.headers }
    );
  };

  regenerateInterviewLink = (interviewId): Observable<any> => {
    return this.http.get(
      environment.meetingServiceUrl + 'api/meetings/fallback/' + interviewId,
      { headers: this.headers }
    );
  };

  getInterviewDataByEventId(
    page: any,
    size: any,
    id: any,
    sort: any,
    filters?
  ) {
    return this.http.post(
      `${environment.gatewayServiceUrl}api/interviewer/interviews?eventId=${id}`,
      { filters: filters },
      { headers: this.headers, params: { size, page, sort } }
    ) as Observable<any>;
  }

  searchInterviewByEventId(page, size, id, sort: any, searchQuery, filters?) {
    return this.http.post(
      `${environment.gatewayServiceUrl}api/interviewer/interviews/search/${searchQuery}?eventId=${id}`,
      { filters: filters },
      { headers: this.headers, params: { size, page, sort } }
    ) as Observable<any>;
  }

  getCancelledInterviewDetails(id: string) {
    return this.http.get(
      `${environment.interviewServiceUrl}api/interviews/${id}/actions/cancelled`,
      { headers: this.headers }
    ) as Observable<any>;
  }

  setEditInterviewMode(flag: boolean) {
    this.editInterviewState = flag;
  }

  getEditInterviewMode() {
    return this.editInterviewState;
  }

  editInterview(data) {
    return this.http.put(
      environment.interviewServiceUrl + 'api/interviews/update',
      data,
      {
        headers: this.headers,
      }
    );
  }

  getEventCandidatesFilterCriteriaForInterviewer(eventId) {
    return this.http.get(
      environment.gatewayServiceUrl +
        'api/interviewer/interviews/search-aggregations/event/' +
        eventId,
      { headers: this.headers }
    );
  }

  getGlobalSearchInterviews = (query, page, size, data?): Observable<any> => {
    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('size', size);
    params = params.append('sort', 'startDate,desc');
    if (data?.interviewStatus)
      params = params.append('interviewStatus', data.interviewStatus);
    return this.http.get(
      `${environment.gatewayServiceUrl}api/interviews/search/${query}`,
      { headers: this.headers, params }
    );
  };
}
