import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InterviewRoundsService {
  private headers = new HttpHeaders()
    .set('content-type', 'application/json');

  constructor(private http: HttpClient) {}

  createCandidateInterviewRound = (
    candidateInterviewRound
  ): Observable<any> => {
    return this.http.post<any>(
      environment.interviewServiceUrl + 'api/candidate-interview-rounds',
      candidateInterviewRound,
      { headers: this.headers }
    );
  };

  getCandidateInterviewRounds = (cirId): Observable<any> => {
    return this.http.get<any[]>(
      environment.interviewServiceUrl +
        `api/interviews/candidate-interview-rounds/${cirId}`,
      { headers: this.headers }
    );
  };

  getCandidateRound = (cirId) => {
    return this.http.get(
      environment.interviewServiceUrl +
        `api/candidate-interview-rounds/${cirId}`,
      { headers: this.headers }
    );
  };
}
