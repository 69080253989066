<p>scholarship-instructions works!</p>

<div class="container-fluid padTB32">
  <div class="row">
    <div class="col-md-12 mx-auto d-flex justify-content-start padTB16">
      <div
        class="mr-3"
        role="button"
        (click)="_location.back()"
        style="cursor: pointer"
      >
        <i class="icon x-24 back-icon"> </i>
      </div>
      <div class="DSA_wb_h2Header">Back</div>
    </div>
    <div class="col-md-7 fixed-height padT8 mx-auto">
      <div class="DSA_panel">
        <div class="DSA_panelBody padTB16 padLR24">
          <h2 class="DSA_wb_h2Header">Eligibility Criteria</h2>

          <mat-list class="marT16">
            <mat-list-item class="marB8">
              This Scholarship is open to both graduate/law students and
              undergraduate students seeking to go to law school in the future.
            </mat-list-item>
            <mat-list-item class="marB8">
              All applicants must be enrolled in a undergraduate or
              graduate-level program.
            </mat-list-item>
            <mat-list-item class="marB8">
              Annual family income should be less than INR 5 Lakhs
            </mat-list-item>
          </mat-list>
        </div>
      </div>
      <div></div>
    </div>
    <div class="col-md-5 d-flex">
      <mat-card class="DSA_wb_card-widget">
        <mat-card-header>
          <mat-card-title class="text-center">
            <h2 class="DSA_wb_h2Header justify-content-center">Dear XXXX,</h2>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <strong style="font-size: large">
            Now you can proceed to your application, what are you looking for?
          </strong>
          <mat-form-field
            class="DSA_Wb_custom-form-field DSA_form_nofloatLabel"
            floatLabel="never"
          >
            <mat-select
              disableOptionCentering
              [(ngModel)]="modeselect"
              panelClass="DSA_wb-custom-select-panel"
              class="DSA_wb-custom-select"
            >
              <mat-option value="UG">UG Scholarships</mat-option>
              <mat-option value="PG">PG Scholarships</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="float-right">
            <button mat-flat-button class="DSA_wb_miniPrimary-btn" (click)="navigateToScholarship()">
              Apply
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
