<app-loader [display]="showDetailsPage == 0"></app-loader>
<div *ngIf="showDetailsPage == 3">
  <app-banner title="{{ jobDetails.title }}" [displaySubHeader]="false">
  </app-banner>

  <div class="container marT32">
    <div class="row justify-content-center">
      <div class="col-md-12 mx-auto">
        <mat-card class="job-card">
          <mat-card-content>
            <div class="row">
              <div class="col-md-4">
                <p class="header font-oswald">Deadline</p>
                <h3 class="details font-oswald">
                  {{ getDateSimplified(jobDetails.endDate) }}
                </h3>
              </div>
              <div class="col-md-4">
                <div *ngIf="jobDetails.location">
                  <p class="header font-oswald">Location</p>
                  <h3 class="details font-oswald">{{ jobDetails.location }}</h3>
                </div>
              </div>
              <div class="col-md-4">
                <p class="header font-oswald">Problem Statement Title</p>
                <p></p>
                <h3 class="details font-oswald">{{ jobDetails.title }}</h3>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <div class="row marT32 font-secondary">
          <div class="col-md-9 job-card-details">
            <h3>
              <strong>About the Hackathon</strong>
            </h3>
            <p class="marT16" [innerHTML]="jobDetails.about"></p>
            <h3 class="marT16">
              <strong>Description</strong>
            </h3>
            <p class="marT16 editor" [innerHTML]="jobDetails.instructions"></p>
            <div *ngIf="showeligibilityCriteria">
              <h3 class="marT16">
                <strong>Eligibility Criteria</strong>
              </h3>
              <div class="marT16">
                <ul>
                  <li *ngFor="let criteria of jobDetails.eligibilityCriteria">
                    <strong>{{ criteria.displayTitle }}</strong>
                    : {{ criteria.description }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-3 job-card-details card-details-column-2">
            <h3 class="marT16">
              <strong>Technology</strong>
            </h3>
            <div class="marT16">
              <mat-chip-list>
                <mat-chip *ngFor="let tag of jobDetails.tags; let i = index">{{
                  tag
                  }}</mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </div>
      </div>

      <div class="marT32 col-12 d-flex justify-content-start"
        *ngIf="accountService.getCurrentRole() == 'ROLE_CANDIDATE'">
        <button (click)="apply()" class="button">Apply</button>
      </div>
    </div>

    <hr class="marT32 marB32" />
    <div class="row justify-content-center marT32">
      <div class="col-md-12 mx-auto">
        <div class="row">
          <div class="col-md-8">
            <h4 class="font-oswald">Need Help?</h4>
            <p class="marT16 font-secondary">
              If you have any questions, please contact us at
              <span>
                <i class="marL8 marR8 fa fa-envelope"></i>
                <a href="mailto:tpic@wa.foundation">tpic@wa.foundation</a>
              </span>
              <span>
                <i class="marL8 marR8 fa fa-phone"></i>
                <span>+91 7410050674</span>
              </span>
             
            </p>
          </div>
          <!-- <div class="col-md-4">
            <h4 class="font-oswald">Contact Number</h4>
            <p class="marT16 font-secondary">
              <i class="marR8 fa fa-phone"></i>
              <a href="tel:08028520261">080 2852 0261</a> (Monday to Friday)
            </p>
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid padTB32">
    <div class="row" *ngIf="showDetailsPage == 2">
      <div class="col-md-10 fixed-height padT8 mx-auto align-items-center">
        <h2>Invalid Scholarship id</h2>
      </div>
    </div>
  </div>
</div>
