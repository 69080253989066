import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { EventService } from 'src/app/services/event.service';
import { RecruiterService } from 'src/app/services/recruiter.service';

@Component({
  selector: 'app-add-recruiters',
  templateUrl: './add-recruiters.component.html',
  styleUrls: ['./add-recruiters.component.scss'],
})
export class AddRecruitersComponent implements OnInit {
  searchControl = new FormControl();
  availableRecruiterObs: Observable<any[]>;
  selectedRecruiter: any;
  eventRecruiters:any=[];
  allRecruiters: any;
  recruiters: any;
  loader = false;
  eventId:any;
  availableRecruiters = [];
  recruiterIdAvailable: any;
  @ViewChild(MatSelect, { static: false }) selectRef: MatSelect;

  constructor(
    private recruiterService: RecruiterService,
    private eventService:EventService
  ) {
    this.availableRecruiterObs = this.searchControl.valueChanges.pipe(
      // tslint:disable-next-line: deprecation
      startWith(null),
      map((recruiter: string | null) => {
        this.recruiterIdAvailable = this.availableRecruiters.find(
          (_) => _.email === this.searchControl.value
        );
        const userId = recruiter?.split('@')[0];
        return userId
          ? this._filter(userId, this.availableRecruiters).slice(0, 10)
          : [];
      })
    );
  }

  ngOnInit(): void {
    this.eventId=this.eventService.selectedEventId;
    this.getAvailableRecruiter();
  }

  private _filter(value: string, listObj): any[] {
    const filterValue = value?.toString()?.toLowerCase();
    return listObj.filter(
      (a) =>
        Object.values(a).toString().toLowerCase().indexOf(filterValue) !== -1
    ) as any[];
  }

  selected(event: MatAutocompleteSelectedEvent) {
    // console.log("selected");
    this.selectRef = this.availableRecruiters.filter(
      (k) => k.email === event.option.value
    )[0];
    // console.log(this.selectRef);
    this.selectedRecruiter = this.selectRef;
  }

  getAvailableRecruiter() {
    this.loader = true;
    this.availableRecruiters = [];
    this.recruiterService
    .getRecrutersListWithEventId(this.eventId, 20000, 0)
    .subscribe((res: any) => {
      res.content?.forEach((recruiter) => {
      this.eventRecruiters.push(recruiter);
      });
      console.log("call");
    this.recruiterService.getAllCoordinators().subscribe((res) => {
      console.log("inside");
      this.allRecruiters = res;
      this.loader = false;
      // console.log("recruiters",this.allRecruiters);

      this.allRecruiters.forEach((element) => {
        if (
          !this.checkInclusion(
            element,
            this.eventRecruiters,
            'id',
            'recruiterId'
          )
        ) {
          this.availableRecruiters.push(element);
          // console.log("recruiterS",this.availableRecruiters);
        }
      });
    });
  });
  }

  checkInclusion = (data, dataList, key1, key2) => {
    let included = false;
    dataList.forEach((element) => {
      if (data[key1] === element[key2]) {
        included = true;
      }
    });

    return included;
  };
}
