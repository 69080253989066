<!-- <p>education-details works!</p> -->

<div *ngIf="spinner">
  <br />
  <img
    src="../../../assets/loader.gif"
    style="margin-left: 30%; width: 200px"
    alt="Spinner"
  />
</div>
<div class="m-3 mr-5">
  <!--style="height: 890px !important"> -->
  <div class="container-fluid ml-3 mr-3 mb-16" style="height: 890px !important">
    <form
      [formGroup]="educationInfoForm"
      class="mb-3 mt-5 pt-3"
      style="font-size: medium"
    >
      <mat-carousel
        autoplay="false"
        color="accent"
        maxWidth="auto"
        proportion="100"
        [loop]="true"
        [hideArrows]="false"
        [hideIndicators]="false"
        [useKeyboard]="true"
        [autoplay]="false"
        orientation="ltr"
      >
        <mat-carousel-slide
          #matCarouselSlide
          overlayColor="#00000040"
          [hideOverlay]="true"
          formArrayName="educationHistoryDTOs"
          *ngFor="let d of getDTOFormControls(); let i = index"
        >
          <ng-container >
            <div [formGroupName]="i" class="mt-2 mb-5">
              <!-- <h4 class="marTB16">Education History</h4> -->
              <ng-container>
                <mat-form-field
                  class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
                  floatLabel="never"
                >
                  <!-- <input
                  matInput
                  placeholder="Education Type"
                  formControlName="educationType"
                /> -->
                  <mat-label>Education Type</mat-label>
                  <mat-select formControlName="educationType">
                    <mat-option value="POSTGRADUATION"
                      >POSTGRADUATION</mat-option
                    >
                    <mat-option value="GRADUATION">GRADUATION</mat-option>
                    <mat-option value="SCHOOL">SCHOOL</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field
                  class="DSA_Wb_custom-form-field DSA_form_nofloatLabel"
                  floatLabel="never"
                >
                  <input
                    matInput
                    placeholder="Degree"
                    formControlName="degree"
                    value=""
                  />
                </mat-form-field>

                <mat-form-field
                  class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
                  floatLabel="never"
                >
                  <input
                    matInput
                    placeholder="Institute"
                    formControlName="institute"
                  />
                </mat-form-field>

                <mat-form-field
                  class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
                  floatLabel="never"
                >
                  <input
                    matInput
                    placeholder="University"
                    formControlName="university"
                  />
                </mat-form-field>

                <mat-form-field
                  class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
                  floatLabel="never"
                >
                  <input
                    matInput
                    placeholder="Specialization"
                    formControlName="specialization"
                  />
                </mat-form-field>

                <mat-form-field
                  class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
                >
                  <input
                    matInput
                    type="number"
                    min="1920"
                    placeholder="Year Of Passing"
                    formControlName="yearOfPassing"
                  />
                </mat-form-field>

                <mat-form-field
                  class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
                  floatLabel="never"
                >
                  <input matInput placeholder="CGPA" formControlName="cgpa" />
                </mat-form-field>

                <mat-form-field
                  class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
                  floatLabel="never"
                >
                  <input
                    matInput
                    placeholder="Duration"
                    formControlName="duration"
                  />
                </mat-form-field>

                <mat-form-field
                  class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
                  floatLabel="never"
                >
                  <mat-label class="color-dob">From Date</mat-label>
                  <input
                    matInput
                    formControlName="fromDate"
                    [matDatepicker]="picker"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field
                  class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixTxt"
                  floatLabel="never"
                >
                  <mat-label class="color-dob">To Date</mat-label>
                  <input
                    matInput
                    formControlName="toDate"
                    [matDatepicker]="picker2"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker2"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
              </ng-container>
            </div>
          </ng-container>
        </mat-carousel-slide>
      </mat-carousel>
    </form>
    <button
      class="DSA_wb_miniSecondary-btn"
      (click)="updateExtractedResume()"
      style="
        float: right;
        margin-right: 10px !important;
        margin-bottom: 10px !important;
      "
    >
      Update
    </button>
  </div>
</div>
