import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ElementRef,
  ViewChild,
} from '@angular/core';
import * as grapesjs from 'grapesjs';
import 'grapesjs-preset-webpage';
import { MailTemplateService } from '../mail-template.service';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  NotificationTemplate,
  TemplateType,
  TemplateCategory,
  TemplateSubCategory,
  TemplateAttachment,
} from '../mail-template.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GjsExportService } from './gjs-export.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AddTemplateParamsComponent } from 'src/app/mail-template/add-template-params/add-template-params.component';
import { ClipboardService } from 'ngx-clipboard';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'jhi-app-grapesjs-wrapper',
  templateUrl: './grapesjs-wrapper.component.html',
  styleUrls: ['./grapesjs-wrapper.component.scss'],
})
export class GrapesjsWrapperComponent implements OnInit, OnChanges {
  private _editor: any;
  templateName = '';
  visible = true;
  templateForm: FormGroup;
  waterMark = 'Attachment';

  private box: string = 'Box';

  matAutocomplete: MatAutocomplete;
  templateCategory: any = [];
  attachments: any = [];
  attachmentFileName = '';
  attachmentBlob;

  showList: any = [];
  selectedAttachments;

  // tslint:disable-next-line: no-input-rename
  @Input('template') template: NotificationTemplate;
  attachmentCtrl = new FormControl();
  placeholders = [];
  selected = -1;

  onAttachmentRemoved(attachment: string) {
    const attachmentList = this.attachmentCtrl.value as string[];
    this.removeFirst(attachmentList, attachment);
    this.attachmentCtrl.setValue(attachmentList); // To trigger change detection
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  onAttachmentClicked(attachment) {
    if (attachment.attachmentFileId != null) {
      this.gjsExport.openSnackBar(
        'Fetching attachment, please wait...',
        '',
        3000
      );

      this.gjsExport
        .getFile(attachment.attachmentFileId)
        .subscribe((res: any) => {
          let temp = res.headers
            .get('content-disposition')
            .split('filename=')[1]
            .split(';')[0];
          let temp1 = temp.substring(1, temp.length - 1);
          this.attachmentFileName = temp1;
          let blob: any = new Blob([res.body], {
            type: 'application/octet-stream; charset=utf-8',
          });
          this.attachmentBlob = blob;

          if (res) {
            this.openFile();
          } else {
            this.gjsExport.openSnackBar('Something went wrong.', '', 3000);
          }
        });
    } else {
      this.gjsExport.openSnackBar(
        'No preview exists for this attachment.',
        '',
        2000
      );
    }
  }

  openFile = () => {
    if (
      this.attachmentFileName.split('.')[
        this.attachmentFileName.split('.').length - 1
      ] === 'pdf'
    ) {
      const blobFile = new File(
        [this.attachmentBlob],
        this.attachmentFileName,
        {
          type: 'application/pdf',
        }
      );
      let fileURL = URL.createObjectURL(blobFile);
      window.open(fileURL);
    } else {
      fileSaver.saveAs(this.attachmentBlob, this.attachmentFileName);
    }
  };

  openDialog = (data: any): void => {
    this.snackBar.open(
      'Title: ' +
        data.title +
        ' Message: ' +
        data.message +
        ' Type: ' +
        data.type,
      '',
      { duration: 3000, panelClass: ['purple-snackbar'] }
    );
  };

  constructor(
    private mailTemplateService: MailTemplateService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private gjsExport: GjsExportService,
    private snackBar: MatSnackBar,
    private _clipboardService: ClipboardService,
    private _eref: ElementRef,
    private fb: FormBuilder,
    private templateService: MailTemplateService
  ) {
    this.templateForm = this.formBuilder.group({
      templateName: ['', [Validators.required]],
      category: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      fromMail: ['', [Validators.required]],
      fromName: ['', [Validators.required]],
    });
  }

  loadExistingAttachments() {
    if (this.templateService.getCurrentTemplate()) {
      let existingAttachments =
        this.templateService.getCurrentTemplate().templateAttachments;

      const filteredAttachments = this.attachments.filter((attachment) => {
        return existingAttachments.some((existing) => {
          return existing.attachmentName === attachment.attachmentName;
        });
      });

      this.attachmentCtrl.setValue(filteredAttachments);
    }
  }

  get editor() {
    return this._editor;
  }
  getAndLoadAttachments = () => {
    this.gjsExport.getAllAttachments().subscribe((data: any) => {
      this.attachments = data;
      this.loadExistingAttachments();
    });
  };

  createMailTemplate(content, templatePrams) {
    const mailTemplate = new NotificationTemplate();
    // mailTemplate.metadata = JSON.stringify(this.getMetadata(content));
    const oldTemplate = this.template;
    const attachmentList = this.attachmentCtrl.value as string[];

    const formData = this.templateForm.value;

    mailTemplate.templateType = TemplateType.HTML;
    mailTemplate.isActive = true;

    mailTemplate.id = oldTemplate ? oldTemplate.id : null;
    mailTemplate.templateParams = templatePrams;
    mailTemplate.templateString = content;
    mailTemplate.subject = formData.subject;
    mailTemplate.fromMail = formData.fromMail;
    mailTemplate.fromName = formData.fromName;
    mailTemplate.name = formData.templateName;
    mailTemplate.templateCategoryId = formData.category;
    mailTemplate.templateAttachments = attachmentList;
    return mailTemplate;
  }

  getTemplateParameters = () => {
    this.gjsExport.getTemplateParams().subscribe((res: any) => {
      this.placeholders = res;

      if (this.template) {
        this.addWrapperComponents(this.template.templateString);
      }

      if (this.editor && this.editor.RichTextEditor.get('Insert')) {
        this.editor.RichTextEditor.remove('Insert');
      }
      this.addRichTextEditor();
    });
  };
  getCategories = () => {
    this.gjsExport.getCategories().subscribe((res) => {
      this.templateCategory = res;
    });
  };
  getTemplateParams(htmlContent) {
    const resTemplateParams = new Set();
    for (const param of this.placeholders) {
      if (
        htmlContent.includes('$' + param.name) ||
        this.templateForm.value.subject.includes('$' + param.name)
      ) {
        resTemplateParams.add(param);
      }
    }
    return Array.from(resTemplateParams);
  }

  getMetadata(htmlContent) {
    const re = /th\:text="\${([a-zA-Z0-9]+)}"/g;
    let m;
    const keys = {};
    do {
      m = re.exec(htmlContent);
      if (m) {
        keys[m[1]] = 'String';
      }
    } while (m);
    return keys;
  }

  validateForm() {
    const templateName = this.templateForm.get('templateName');
    const category = this.templateForm.get('category');

    let flag = true;
    if (templateName.status !== 'VALID') {
      flag = false;
      templateName.markAsTouched();
      templateName.setErrors(Validators.required);
    }
    if (category.status !== 'VALID') {
      flag = false;
      category.markAsTouched();
      category.setErrors(Validators.required);
    }
    return flag;
  }

  saveTemplate(context) {
    return (content) => {
      if (context.validateForm()) {
        const templatePrams = context.getTemplateParams(content);
        const mailTemplate: NotificationTemplate = context.createMailTemplate(
          content,
          templatePrams
        );
        return mailTemplate;
      }
      return null;
    };
  }
  addTemplateParams = () => {
    const dialogRef = this.dialog.open(AddTemplateParamsComponent, {});

    dialogRef.afterClosed().subscribe((val) => {
      if (val) {
        this.gjsExport.addTemplateParams({ name: val['name'] }).subscribe(
          () => {
            this.openDialog({
              type: 'success',
              message: 'Added successfully',
              title: 'Success',
            });
            this.getTemplateParameters();
          },
          (err) => {
            this.openDialog({
              type: 'error',
              message: 'Error occurred',
              title: 'Error',
            });
          }
        );
      }
    });
  };

  ngOnInit() {
    this.initApp();
    this.getTemplateParameters();
    this.getAndLoadAttachments();
    this.getCategories();
    this.templateForm.get('templateName').valueChanges.subscribe((val) => {
      if (val !== '') {
        this.templateForm.get('templateName').setErrors(null);
      }
    });
  }
  placeholderChange = (e) => {
    this._clipboardService.copyFromContent('$' + e.value);
    this.snackBar.open('Copied To Clipboard!', '', {
      duration: 3000,
      panelClass: ['purple-snackbar'],
    });
  };

  ngOnChanges() {
    if (this.template) {
      const templateName = this.template.name;
      const subject = this.template.subject;
      const category = this.template.templateCategoryId;
      const fromMail = this.template.fromMail;
      const fromName = this.template.fromName;
      this.templateForm.patchValue({
        templateName,
        subject,
        category,
        fromMail,
        fromName,
      });
    }
  }

  addRichTextEditor() {
    const rte = this.editor.RichTextEditor;
    rte.add('Insert', {
      icon:
        `<select style="color:white" class="gjs-field" required=""><option disabled="" selected="">Insert</option>` +
        this.placeholders
          .map(
            (ph) =>
              `<option style="color:white;background:#444444;" value="${ph.name}">` +
              ph.name +
              `</option>`
          )
          .join('') +
        `</select>`,
      event: 'change',
      result: (rtes, action) => {
        rtes.insertHTML(`$${action.btn.firstChild.value}`);
      },
    });
    this.addBlockManager();
  }

  addBlockManager() {
    this.editor.BlockManager.add('my-block-id', {
      // ...
      content: {
        tagName: 'span',
        draggable: false,
        attributes: { 'some-attribute': 'some-value' },
        components: [
          {
            tagName: 'span',
            content: '<span>Some static content</span>',
          },
          {
            tagName: 'span',
            // use `content` for static strings, `components` string will be parsed
            // and transformed in Components
            components: '<span>HTML at some point</span>',
          },
        ],
      },
    });
  }

  addWrapperComponents(templateString: string) {
    const wrapper = this.editor.DomComponents.getWrapper();
    wrapper.components(templateString);
  }

  private initApp() {
    this._editor = this.initializeEditor();
    this.editor.on('asset:add', () => {
      // this.editor.runCommand('open-assets');
    });

    this.editor.BlockManager.add('my-block-id', {
      content: {
        tagName: 'span',
        draggable: false,
        attributes: { 'some-attribute': 'some-value' },
        components: [
          {
            tagName: 'span',
            content: '<span>Some static content</span>',
          },
          {
            tagName: 'span',
            // use `content` for static strings, `components` string will be parsed
            // and transformed in Components
            components: '<span>HTML at some point</span>',
          },
        ],
      },
    });
    const loadingOldTemplate: boolean = this.template
      ? this.template.templateString !== undefined
      : false;
    if (loadingOldTemplate) {
      const wrapper = this.editor.DomComponents.getWrapper();
      wrapper.components(this.template.templateString);
    }
  }

  private initializeEditor(): any {
    const grapesJsInitVar = {
      container: '#gjs',
      autorender: true,
      forceClass: false,
      components: '',
      style: '',
      plugins: [
        'gjs-preset-webpage',
        this.gjsExport.pluginExport(this.saveTemplate(this)),
      ],
      pluginsOpts: {
        'gjs-preset-webpage': {
          navbarOpts: false,
          countdownOpts: false,
          formsOpts: false,
          blocksBasicOpts: {
            blocks: [
              'link-block',
              'quote',
              'image',
              'video',
              'text',
              'column1',
              'column2',
              'column3',
            ],
            flexGrid: false,
            stylePrefix: 'lala-',
          },
          textTypography: 'Typo',
          customStyleManager: [
            {
              name: 'Alternate Font',
              open: false,
              buildProps: [],
              properties: [
                {
                  property: 'font-family',
                  type: 'select',
                  list: [
                    { value: 'Lato', name: 'Lato' },
                    { value: 'Lato Regular', name: 'Lato Regular' },
                    { value: 'Arial', name: 'Arial' },
                  ],
                },
              ],
            },
          ],
        },
      },
      canvas: {
        styles: [
          'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css',
          'https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css',
          'https://fonts.googleapis.com/css?family=Lato',
        ],
        scripts: [
          'https://ajax.googleapis.com/ajax/libs/jquery/3.2.1/jquery.min.js',
        ],
      },
    };

    // grapesJsInitVar['components']= '<h1>Yakhi Akshu!!</h1>';
    // grapesJsInitVar['fromElement']= true;

    return grapesjs.init(grapesJsInitVar);
  }
}
