<div class="container-fluid">
    <div class="row mb-2">
      <div class="col-12">
        <h1 class="DSA_wb_h1Header">
          Add User
        </h1>
      </div>
    </div>
    <app-loader [display]="isLoading"></app-loader>
    <div *ngIf="!isLoading">
      <form class="row" [formGroup]="userForm">
        <div class="col-12">
          <mat-form-field class="DSA_Wb_custom-form-field">
            <input
              matInput
              placeholder="First Name"
              formControlName="firstname"
              value=""
              required
            />
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="DSA_Wb_custom-form-field">
            <input
              matInput
              placeholder="Last Name"
              formControlName="lastname"
              value=""
              required
            />
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="DSA_Wb_custom-form-field">
            <input
              matInput
              placeholder="Phone"
              formControlName="phone"
              value=""
              required
            />
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="DSA_Wb_custom-form-field">
            <input
              matInput
              placeholder="Email"
              formControlName="email"
              value=""
              required
            />
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field class="DSA_Wb_custom-form-field">
            <mat-label>User Role</mat-label>
            <mat-select 
              name="userRole"
              formControlName="userRole"
              required
              disableOptionCentering
              panelClass="DSA_wb-custom-select-panel" 
            >
              <mat-option
                *ngFor="let option of userRoleOptions"
                [value]="option.title"
              >
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- <div class="col-6">
          <mat-form-field class="DSA_Wb_custom-form-field">
            <mat-label>Organization</mat-label>
            <mat-select 
              name="organization"
              formControlName="organization"
              required
              disableOptionCentering
              panelClass="DSA_wb-custom-select-panel"
            >
              <mat-option 
                *ngFor="let option of organizationOptions"
                [value]="option.id"
              >
                {{ option.name  }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
      </form>
      <div class="row mb-3 d-flex justify-content-end">
        <button
          mat-flat-button
          class="DSA_wb_miniSecondary-btn mr-2"
          (click)="onNoClick()"
        >
          Cancel
        </button>
        <button
          mat-flat-button
          class="DSA_wb_miniPrimary-btn"
          [disabled]="userForm.invalid"
          (click)="handleAddUserClick()"
        >
          Add User
        </button>
      </div>
    </div>
  </div>
  