import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, Subject, BehaviorSubject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { JobService } from './job.service';
@Injectable({
  providedIn: 'root',
})
export class EvaluationService {
  private headers = new HttpHeaders()
    .set('content-type', 'application/json');
  constructor(private http: HttpClient, private jobService: JobService) {}

  getAllCandidateEvaluations = (id: string): Observable<any[]> => {
    return this.http.get<any[]>(
      environment.interviewServiceUrl +
        'api/feedbacks/recruiter/candidate-interview-round/' +
        id,
      { headers: this.headers }
    );
  };

  getfileMappingsByInterviewId = (id: string): Observable<any[]> => {
    return this.http.get<any[]>(
      environment.interviewServiceUrl +
        'api/interview-file-mappings/interview/' +
        id,
      { headers: this.headers }
    );
  };


  getEvaluationCriteriaById = (): Observable<any> => {
    const jobId = this.jobService.getSelectedJob().id;
    return this.http.get(
      `${environment.interviewServiceUrl}api/evaluation-criteria/job-opening/${jobId}`,
      { headers: this.headers }
    ) as Observable<any>;
  };

  creteEvaluationCriteria = (data: any): Observable<any> => {
    const job = this.jobService.getSelectedJob();
    return this.http.post(
      `${environment.interviewServiceUrl}api/evaluation-criteria`,
      data,
      { headers: this.headers }
    ) as Observable<any>;
  };

  editEvaluationCriteria = (data: any): Observable<any> => {
    return this.http.put(
      `${environment.interviewServiceUrl}api/evaluation-criteria`,
      data,
      { headers: this.headers }
    ) as Observable<any>;
  };

  getAllEvaluations = (id: string): Observable<any[]> => {
    return this.http.get<any[]>(
      environment.interviewServiceUrl +
        'api/evaluation-criteria/job-opening/' +
        id,
      { headers: this.headers }
    );
  };

  deleteSnapshot = (id: string): Observable<any[]> => {
    return this.http.delete<any[]>(
      environment.interviewServiceUrl +
        'api/interview-file-mappings/'+id,
      { headers: this.headers }
    );
  };
}
