import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { allowedCharactersInMailRegex } from 'src/app/app.constants';
import { EventService } from 'src/app/services/event.service';
import { SharedService } from 'src/app/services/shared.service';
import {
  IEventPanelist,
  IEventPanelistToAdd,
} from 'src/app/shared/model/event-panelist.model';

@Component({
  selector: 'app-event-panelist-select-input',
  templateUrl: './event-panelist-select-input.component.html',
  styleUrls: ['./event-panelist-select-input.component.scss'],
})
export class EventPanelistSelectInputComponent implements OnInit, OnChanges {
  @Input() eventId: number;
  @Input() panelistList: IEventPanelistToAdd[] = [];
  @Input() disabled: boolean = false;
  @Input() label: string = 'Interviewer';
  @Input() isRequired: boolean = false;
  // to filter already added panelists, if any
  @Input() emailToFilter: string[] = [];
  @Output() panelistChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('interviewerInput') interviewerInput: ElementRef<HTMLInputElement>;
  @ViewChild('chipList') chipList;

  searchControl = new FormControl('', [
    Validators.pattern(allowedCharactersInMailRegex),
  ]);

  getPanelistsLoader: boolean = false;

  filteredInterviewers: Observable<any[]>;

  constructor(
    private eventService: EventService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.filteredInterviewers = this.searchControl.valueChanges.pipe(
      // tslint:disable-next-line: deprecation
      startWith(''),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((panelist) => {
        if (
          this.searchControl.value === null ||
          this.searchControl.invalid ||
          this.panelistList.some((_) => _.panelistEmail === panelist)
        ) {
          return of({ content: [] });
        }
        this.getPanelistsLoader = true;
        const userId = panelist?.split('@')[0];
        return userId.length > 1
          ? this.eventService
              .getInterviewerListWithEventAndSearchParam(
                userId,
                10,
                0,
                this.eventId
              )
              .pipe(
                catchError((err) => {
                  this.getPanelistsLoader = false;
                  this.sharedService.openSnackBar(
                    'Some error occurred in finding panelist. Please try again.',
                    '',
                    3000
                  );
                  return of({ content: [] });
                })
              )
          : of({ content: [] });
      }),
      map((result: { content: Array<any> }) => {
        // remove currently added or already mapped panelists from result
        let panelists = result.content
          .filter(
            (_) =>
              !this.panelistList.some(
                (p) => p.panelistEmail === _.panelistEmail
              )
          )
          .filter((_) => !this.emailToFilter?.includes(_.panelistEmail));
        this.getPanelistsLoader = false;
        return panelists;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isRequired) {
      this.isRequired = changes.isRequired.currentValue;
    }
  }

  remove(data: IEventPanelist): void {
    const index = this.panelistList.indexOf(data);

    if (index !== -1) {
      this.panelistList.splice(index, 1);
      this.panelistChange.emit(this.panelistList);
    } else {
      this.chipList.errorState = true;
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.panelistList.push(event.option.value);
    this.interviewerInput.nativeElement.value = '';
    this.searchControl.reset();
    this.panelistChange.emit(this.panelistList);
    if (this.isRequired && this.chipList.errorState) {
      this.chipList.errorState = false;
    }
  }

  onInputBlur = (e) => {
    if (this.isRequired) {
      if (this.panelistList.length === 0) this.chipList.errorState = true;
      else this.chipList.errorState = false;
    }
  };
}
