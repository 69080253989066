import { SurveyService } from 'src/app/survey-master/survey/survey.service';
import { Input, OnChanges } from '@angular/core';
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/tslint/config */
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TypeDefinition } from 'src/app/shared/model/type-definition.model';
import { SurveyObject } from 'src/app/shared/model/survey-object.model';
import { SurveyDesignService } from 'src/app/survey-master/survey-design/design.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Visibility } from 'src/app/shared/model/enumerations/visibility.model';
import { ViewType } from 'src/app/shared/model/enumerations/view-type.model';
import { TakeSurveyService } from './take-survey.service';
import { FileserverService } from 'src/app/survey-master/survey/edit-backgroud-color/fileserver.service';
import { NotifierService } from 'angular-notifier';
import { MediaObserver } from '@angular/flex-layout';
import { DomSanitizer } from '@angular/platform-browser';
import { OptionOrder } from '../../shared/model/enumerations/option-order.model';
import { Theme } from '../../shared/model/theme.model';
import { candidateRejectionReasonTech, SERVER_API_URL } from '../../app.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as fileSaver from 'file-saver';
import { FileService } from 'src/app/services/file.service';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { TimerService } from 'src/app/services/timer.service';
import { rejectionReasonList, onHoldReasonList } from 'src/app/app.constants';
import { ExperienceType } from 'src/app/shared/model/job-role.model';
import { InterviewPlatformEnum } from 'src/app/shared/model/interview-platform.model';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'jhi-take-survey',
  templateUrl: './take-survey.component.html',
  styleUrls: ['./take-survey.component.scss'],
})
export class TakeSurveyComponent implements OnInit, OnChanges {
  @Input() jobId: Number;
  @Input() jobTitle?: string = '';
  @Input() jobType: string;
  @Input() experienceType: ExperienceType = 'LATERAL';
  @Input() interviewId: Number;
  @Input() cirId: Number;
  @Input() disabled = false;
  @Input() surveyDefinitionId;
  @Input() platformId: InterviewPlatformEnum;
  @Input() candidateSource: string;
  @Input() sentBackToPanelist = false;
  rejectionCondition: boolean = false;
  remarks = '';
  @Input() timerInterview: any;
  surveyId = '3106';

  files = [];
  surveyDefinition: SurveyObject = null;
  @ViewChild('surveyCard', { static: false }) surveyCard: ElementRef;

  displaySubmit = false;
  displayNext = true;
  displayPrevious = false;
  isSaving: boolean;
  publishSurvey: any;
  bgColor = 'red';
  pagedQuestions: any = null;

  loading = false;
  errorFetchingEvalForm: boolean = false;
  isSurveyNotFound: boolean = false;

  rejectionReasonList = rejectionReasonList;

  onHoldReasonList = onHoldReasonList;

  currPage: any;
  currIndex: number;

  currentTheme: any;
  currentPreference: any;

  questionTypes: TypeDefinition[] = [];
  backgroundImageUrl = '';

  selected;
  responses: any[];
  isPageValid = true;

  question: any;
  PageErrors = [];

  // used for question per page
  selectedQuestion: any = null;
  currentSectionIndex = null;
  currentQuestionIndex = null;

  //
  errorMessage: string = null;
  thankYou: string = null;
  currentTemplate = {
    name: '',
    templateString: '',
    metadata: '{}',
  };
  private readonly notifier: NotifierService;

  // capture time
  startTime: Date;
  receivedResponses: any = [];
  // polls
  questionIds = [];
  questionsMap: Map<number, any> = new Map<number, any>();
  responseMap: Map<number, any> = new Map<number, any>();
  receivedResponseMap: Map<number, any> = new Map<number, any>(); // questionid, response
  pollResults = null;
  answerquestions = [];
  finalResult: any[] = [
    { id: 1, viewValue: 'SELECTED', code: 'SELECTED' },
    { id: 2, viewValue: 'REJECTED', code: 'REJECTED' },
    { id: 3, viewValue: 'ON HOLD', code: 'ON_HOLD' },
    // {
    //   id: 4,
    //   viewValue: 'REFER TO OTHER POST',
    //   code: 'REFER_FOR_POST',
    // },
  ];
  finalSelection = '';
  resultReason;

  candidateRejectionReasonTech = candidateRejectionReasonTech;

  noUploadedImages = false; // true only when sent back to panel and uploaded images are deleted
  isDeclarationAccepted: boolean = false;

  constructor(
    private _snackBar: MatSnackBar,
    private router: Router,
    protected activatedRoute: ActivatedRoute,
    public pagedQuestionService: SurveyDesignService,
    private surveyService: SurveyService,
    public takeSurveyService: TakeSurveyService,
    public fileserverService: FileserverService,
    notifierService: NotifierService,
    private mediaObserver: MediaObserver,
    private sanitizer: DomSanitizer,
    private fileService: FileService,
    private dialog: MatDialog,
    private timerService: TimerService,
    private sharedService: SharedService
  ) {
    this.notifier = notifierService;
  }

  ngOnChanges(): void {
    this.refreshPage();
    this.isDeclarationAccepted = false;
  }

  processReceivedResponses(receivedResponses: Array<any>) {
    if (receivedResponses && receivedResponses.length > 0) {
      receivedResponses[receivedResponses.length - 1].questionResponses.forEach(
        (response) => {
          this.receivedResponseMap.set(response.questionId, response);
        }
      );
    }
  }
  processSurvey = (surveyDefinitionResponse) => {
    this.surveyDefinition = surveyDefinitionResponse.body;

    // imageService.getUpscaleUrl('https://surveymaster.in'+this.downloadImage(surveyDefinition.logo.path),'20x20')
    // this.imageService.upscale('https://surveymaster.in'+this.downloadImage(surveyDefinition.logo.path),'20x20').subscribe((image)=>{
    //
    //   this.image = image;
    // })
    //

    this.pagedQuestionService
      .getPagedQuestions(this.surveyDefinition.identifier)
      .subscribe((pagedQuestions) => {
        this.pagedQuestions = pagedQuestions.body as [];

        this.startTime = new Date();

        if (this.surveyDefinition !== null && this.pagedQuestions !== null) {
          if (this.pagedQuestions.length > 0) {
            if (this.pagedQuestions.length > 1) {
              this.displaySubmit = false;
              this.displayNext = true;
            } else {
              this.displaySubmit = true;
              this.displayNext = false;
            }
            this.currPage = this.pagedQuestions[0];
            this.currIndex = 0;
            if (this.currPage.theme === null) {
              if (
                this.surveyDefinition.theme.childTheme === null ||
                this.surveyDefinition.theme.childTheme === undefined
              ) {
                this.currentTheme = this.surveyDefinition.theme;
              } else {
                this.currentTheme = this.surveyDefinition.theme.childTheme;
              }
            } else {
              this.currentTheme = this.currPage.theme;
            }
            if (
              this.currentTheme.childTheme === null ||
              this.currentTheme.childTheme === undefined
            ) {
              if (
                this.surveyDefinition.theme.childTheme === null ||
                this.surveyDefinition.theme.childTheme.childTheme === null ||
                this.surveyDefinition.theme.childTheme === undefined ||
                this.surveyDefinition.theme.childTheme.childTheme === undefined
              ) {
                this.currentTheme.childTheme = new Theme();
                this.currentTheme.childTheme.bgcolor = 'white';
              } else {
                this.currentTheme.childTheme = this.surveyDefinition.theme.childTheme.childTheme;
              }
            }

            if (this.currPage.preference === null) {
              this.currentPreference = this.surveyDefinition.preference;
            } else {
              this.currentPreference = this.currPage.preference;
            }
            if (this.currentPreference.optionOrder !== OptionOrder.RANDOM) {
              if (
                this.surveyDefinition.preference.optionOrder ===
                OptionOrder.RANDOM
              ) {
                this.currentPreference.optionOrder = OptionOrder.RANDOM;
              }
            }
          } else {
            this.currPage = null;
          }

          // creating the response object
          this.responses = [];
          for (let i = 0; i < this.pagedQuestions.length; i++) {
            const responsePage: { [k: string]: any } = {};
            responsePage.pageId = this.pagedQuestions[i].id;
            this.pagedQuestions[i].surveyId = this.surveyDefinition.id;
            responsePage.sections = [];

            for (let j = 0; j < this.pagedQuestions[i].sections.length; j++) {
              const responseSection: { [k: string]: any } = {};
              responseSection.id = this.pagedQuestions[i].sections[j].id;

              responseSection.questions = [];

              for (
                let k = 0;
                k < this.pagedQuestions[i].sections[j].questions.length;
                k++
              ) {
                const responseQuestion: { [k: string]: any } = {};

                this.questionIds.push(
                  this.pagedQuestions[i].sections[j].questions[k].id
                );
                this.questionsMap.set(
                  this.pagedQuestions[i].sections[j].questions[k].id,
                  this.pagedQuestions[i].sections[j].questions[k]
                );

                responseQuestion.id = this.pagedQuestions[i].sections[
                  j
                ].questions[k].id;
                responseQuestion.response = [];
                responseQuestion.isValid = true;
                responseQuestion.validationMessage = '';
                if (this.currentPreference.optionOrder === OptionOrder.RANDOM) {
                  this.pagedQuestions[i].sections[j].questions[
                    k
                  ].preference.optionOrder = OptionOrder.RANDOM;
                }
                if (
                  this.pagedQuestions[i].sections[j].questions[k].visibility ===
                  Visibility.HIDDEN
                ) {
                  responseQuestion.logicallyHidden = true;
                  responseQuestion.defaultVisibile = false;
                } else {
                  responseQuestion.logicallyHidden = false;
                  responseQuestion.defaultVisibile = true;
                }
                responseQuestion.showNumbers =
                  this.surveyDefinition.preference.showQuestionNum === 1 ||
                  this.pagedQuestions[i].preference.showQuestionNum === 1;
                this.pagedQuestions[i].sections[j].questions[
                  k
                ].preference.isRequired =
                  this.surveyDefinition.preference.isRequired === 1 ||
                  this.pagedQuestions[i].preference.isRequired === 1 ||
                  this.pagedQuestions[i].sections[j].questions[k].preference
                    .isRequired
                    ? 1
                    : 0;

                responseQuestion.logicallyHiddenBy = []; // array of ids of questions that want to hide this question
                responseQuestion.logicallyShownBy = []; // array of ids of questions that want to show this question
                // we give higher priority to hiding a questions so if question no.1 want to show question no.3 and question no.2 wants to
                // hide question no.3 then question no.3 will be hidden

                // for combined question
                if (
                  this.pagedQuestions[i].sections[j].questions[k].type
                    .typeDefinition.identifier === 'Multi_Question'
                ) {
                  if (
                    this.pagedQuestions[i].sections[j].questions[k]
                      .childQuestions
                  ) {
                    responseQuestion.childResponses = [];
                    for (const fetchedQuestion of this.pagedQuestions[i]
                      .sections[j].questions[k].childQuestions) {
                      // fetch and store the question
                      // const fetchedQuestion:any = {};
                      const childResponse: any = {};
                      childResponse.sections = [];
                      const childSection: any = {};
                      childSection.questions = [];
                      const childresponseQuestion: any = {};
                      childresponseQuestion.id = fetchedQuestion.id;
                      childresponseQuestion.response = [];
                      childresponseQuestion.isValid = true;
                      childresponseQuestion.validationMessage = '';
                      if (
                        this.currentPreference.optionOrder ===
                        OptionOrder.RANDOM
                      ) {
                        this.pagedQuestions[i].sections[j].questions[
                          k
                        ].preference.optionOrder = OptionOrder.RANDOM;
                        fetchedQuestion.preference.optionOrder =
                          OptionOrder.RANDOM;
                      }
                      if (fetchedQuestion.visibility === Visibility.HIDDEN) {
                        childresponseQuestion.logicallyHidden = true;
                        childresponseQuestion.defaultVisibile = false;
                      } else {
                        childresponseQuestion.logicallyHidden = false;
                        childresponseQuestion.defaultVisibile = true;
                      }
                      // // console.log('survey', JSON.stringify(this.surveyDefinition));
                      // // console.log('pagedef ', JSON.stringify(this.pagedQuestions));
                      childresponseQuestion.showNumbers = false;
                      fetchedQuestion.preference.isRequired =
                        this.surveyDefinition.preference.isRequired === 1 ||
                        this.pagedQuestions[i].preference.isRequired === 1 ||
                        fetchedQuestion.preference.isRequired === 1
                          ? 1
                          : 0;

                      childresponseQuestion.logicallyHiddenBy = []; // array of ids of questions that want to hide this question
                      childresponseQuestion.logicallyShownBy = [];
                      childSection.questions.push(childresponseQuestion);
                      childResponse.sections.push(childSection);
                      responseQuestion.childResponses.push(childResponse);
                    }
                  }
                }

                responseSection.questions.push(responseQuestion);
              }

              responsePage.sections.push(responseSection);
            }

            this.responses.push(responsePage);
          }

          this.takeSurveyService
            .getResponse(this.cirId, this.interviewId)
            .subscribe(
              (response) => {
                this.loading = false;
                this.receivedResponses = response.body;
                this.processReceivedResponses(this.receivedResponses);
                // console.log('response map', this.receivedResponseMap);
                let responseNumber = 0;
                for (let i = 0; i < this.pagedQuestions.length; i++) {
                  for (
                    let j = 0;
                    j < this.pagedQuestions[i].sections.length;
                    j++
                  ) {
                    for (
                      let k = 0;
                      k < this.pagedQuestions[i].sections[j].questions.length;
                      k++
                    ) {
                      this.responses[i].sections[j].questions[k].response = [];
                      // if (
                      //   this.pagedQuestions[i].sections[j].questions[k].type.typeDefinition
                      //     .identifier === 'Ranking'
                      // ) {
                      //   if (
                      //     this.responses[i].sections[j].questions[k].logicallyHidden ===
                      //     false
                      //   ) {
                      //     // for (
                      //     //   let p = 0;
                      //     //   p < this.responses[i].sections[j].questions[k].response.length;
                      //     //   p++
                      //     // ) {
                      //     //   this.responses[i].sections[j].questions[k].response[p].value= this.receivedResponses[0].questionResponses[responseNumber].answer[p]

                      //     // }
                      //   }
                      // } else
                      if (
                        this.pagedQuestions[i].sections[j].questions[k].type
                          .typeDefinition.identifier === 'HTML_block'
                      ) {
                      } else if (
                        this.pagedQuestions[i].sections[j].questions[k].type
                          .typeDefinition.identifier === 'Multi_Question'
                      ) {
                        if (
                          this.pagedQuestions[i].sections[j].questions[k]
                            .childQuestions
                        ) {
                          let cri = 0;
                          for (const childQuestion of this.pagedQuestions[i]
                            .sections[j].questions[k].childQuestions) {
                            // console.log(
                            //   'filling for child Question',
                            //   childQuestion,
                            //   this.receivedResponseMap.get(childQuestion.id)
                            // );
                            this.responses[i].sections[j].questions[
                              k
                            ].childResponses[
                              cri
                            ].sections[0].questions[0].response = [];
                            if (
                              this.responses[i].sections[j].questions[k]
                                .logicallyHidden === false &&
                              this.receivedResponseMap.get(childQuestion.id)
                            ) {
                              for (
                                let p = 0;
                                p <
                                this.receivedResponseMap.get(childQuestion.id)
                                  .answer.length;
                                p++
                              ) {
                                this.responses[i].sections[j].questions[
                                  k
                                ].childResponses[
                                  cri
                                ].sections[0].questions[0].response.push(
                                  String(
                                    this.receivedResponseMap.get(
                                      childQuestion.id
                                    ).answer[p]
                                  )
                                );
                              }
                            } else {
                            }
                            cri++;
                          }
                        }
                      } else {
                        if (
                          this.responses[i].sections[j].questions[k]
                            .logicallyHidden === false &&
                          this.receivedResponseMap.get(
                            this.pagedQuestions[i].sections[j].questions[k].id
                          )
                        ) {
                          for (
                            let p = 0;
                            p <
                            this.receivedResponseMap.get(
                              this.pagedQuestions[i].sections[j].questions[k].id
                            ).answer.length;
                            p++
                          ) {
                            this.responses[i].sections[j].questions[
                              k
                            ].response.push(
                              this.receivedResponseMap.get(
                                this.responses[i].sections[j].questions[k].id
                              ).answer[p]
                            );
                          }
                        } else {
                        }
                      }
                      responseNumber += 1;
                    }
                  }
                }

                // console.log('generated responses', this.responses);
              },
              (err) => {
                this.loading = false;
              }
            );

          if (
            this.surveyDefinition.preference.viewType ===
              ViewType.QUESTION_PER_PAGE ||
            (this.isMobile() &&
              this.surveyDefinition.preference.viewType === ViewType.CUSTOM)
          ) {
            if (this.currPage !== null) {
              this.currentSectionIndex = 0;
              this.currentQuestionIndex = 0;
              this.selectedQuestion = this.currPage.sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex];
              if (
                this.currPage.sections[this.currentSectionIndex].questions
                  .length > 1
              ) {
                this.displaySubmit = false;
                this.displayNext = true;
              } else {
                if (
                  this.currPage.sections[this.currentSectionIndex].length > 1
                ) {
                  this.displayNext = true;
                  this.displaySubmit = false;
                } else {
                  if (this.pagedQuestions.length > 1) {
                    this.displayNext = true;
                    this.displaySubmit = false;
                  } else {
                    this.displayNext = false;
                    this.displaySubmit = true;
                  }
                }
              }
            } else {
              this.currentSectionIndex = null;
              this.currentQuestionIndex = null;
              this.selectedQuestion = null;
            }
          }
        }
      });
  };

  handleFileChange = (files: Array<File>) => {
    this.files = files;
  };

  ngOnInit() {
    this.noUploadedImages = false;
    //console.log(this.sentBackToPanelist);
    this.refreshPage();
  }

  refreshPage() {
    this.loading = true;
    this.errorFetchingEvalForm = false;
    this.isSurveyNotFound = false;

    if (this.surveyDefinitionId) {
      this.surveyService.findById(this.surveyDefinitionId).subscribe(
        (surveyDefinitionResponse) => {
          this.processSurvey(surveyDefinitionResponse);
        },
        (err) => {
          this.errorFetchingEvalForm = true;
          this.loading = false;
        }
      );
    } else {
      this.surveyService.findByJobId(this.jobId, this.jobType).subscribe(
        (surveyDefinitionResponse) => {
          this.processSurvey(surveyDefinitionResponse);
        },
        (error) => {
          if (
            (typeof error == 'string' && error.includes('Error Code: 404')) ||
            error?.error?.status === 404
          ) {
            this.isSurveyNotFound = true;
          }
          this.errorFetchingEvalForm = true;
          this.loading = false;
        }
      );
    }
  }

  ngAfterViewInit() {
    // console.log(
    //   'view init completed',
    //   this.surveyCard,
    //   this.surveyCard.nativeElement.offsetHeight,
    //   this.surveyCard.nativeElement.offsetWidth
    // );
    // this.backgroundImageUrl = this.imageService.getUpscaleUrl(
    //   location.origin+this.downloadImage(
    //     this.currentTheme.background.path),
    //     `${this.surveyCard.nativeElement.offsetWidth}x${this.surveyCard.nativeElement.offsetHeight}`);
  }

  widthCSS() {
    if (window.innerWidth > 480) {
      return {
        margin: '0.5% 5%',
      };
    } else {
      return '';
    }
  }

  getHTMLValue() {
    return this.sanitizer.bypassSecurityTrustHtml(
      this.currentTemplate.templateString
    );
  }

  onError(errMsg) {
    // eslint-disable-next-line @typescript-eslint/tslint/config
  }

  validateCurrentQuestion() {
    if (this.selectedQuestion !== null) {
      this.isPageValid = true;
      this.PageErrors = [];
      if (
        !this.responses[this.currIndex].sections[this.currentSectionIndex]
          .questions[this.currentQuestionIndex].isValid
      ) {
        this.isPageValid = false;
        this.PageErrors.push({
          question: this.currPage.sections[this.currentSectionIndex].questions[
            this.currentQuestionIndex
          ],
          error: this.responses[this.currIndex].sections[
            this.currentSectionIndex
          ].questions[this.currentQuestionIndex].validationMessage,
        });
      } else {
        const errorDetails = [];
        switch (
          this.currPage.sections[this.currentSectionIndex].questions[
            this.currentQuestionIndex
          ].type.typeDefinition.identifier
        ) {
          case 'Small_text':
            if (
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].response[0] === '' &&
              this.currPage.sections[this.currentSectionIndex].questions[
                this.currentQuestionIndex
              ].preference.isRequired === 1 &&
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].logicallyHidden === false
            ) {
              this.isPageValid = false;
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].validationMessage =
                'Please provide an input, this field is required';
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].isValid = false;
              this.PageErrors.push({
                question: this.currPage.sections[this.currentSectionIndex]
                  .questions[this.currentQuestionIndex],
                error: this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].validationMessage,
              });
            } else {
              // notjing
            }
            break;
          case 'Long_text':
            if (
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].response[0] === '' &&
              this.currPage.sections[this.currentSectionIndex].questions[
                this.currentQuestionIndex
              ].preference.isRequired === 1 &&
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].logicallyHidden === false
            ) {
              this.isPageValid = false;
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].validationMessage =
                'Please provide an input, this field is required';
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].isValid = false;
              this.PageErrors.push({
                question: this.currPage.sections[this.currentSectionIndex]
                  .questions[this.currentQuestionIndex],
                error: this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].validationMessage,
              });
            }
            break;
          case 'Numeric':
            if (
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].response[0] === '' &&
              this.currPage.sections[this.currentSectionIndex].questions[
                this.currentQuestionIndex
              ].preference.isRequired === 1 &&
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].logicallyHidden === false
            ) {
              this.isPageValid = false;
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].validationMessage =
                'Please provide an input, this field is required';
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].isValid = false;
              this.PageErrors.push({
                question: this.currPage.sections[this.currentSectionIndex]
                  .questions[this.currentQuestionIndex],
                error: this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].validationMessage,
              });
            }
            break;
          case 'Comment':
            if (
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].response[0] === '' &&
              this.currPage.sections[this.currentSectionIndex].questions[
                this.currentQuestionIndex
              ].preference.isRequired === 1 &&
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].logicallyHidden === false
            ) {
              this.isPageValid = false;
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].validationMessage =
                'Please provide an input, this field is required';
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].isValid = false;
              this.PageErrors.push({
                question: this.currPage.sections[this.currentSectionIndex]
                  .questions[this.currentQuestionIndex],
                error: this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].validationMessage,
              });
            }
            break;
          case 'Ranking':
            break;
          case 'Dropdown':
            if (
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].response[0] === '' &&
              this.currPage.sections[this.currentSectionIndex].questions[
                this.currentQuestionIndex
              ].preference.isRequired === 1 &&
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].logicallyHidden === false
            ) {
              this.isPageValid = false;
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].validationMessage =
                'Please provide an input, this field is required';
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].isValid = false;
              this.PageErrors.push({
                question: this.currPage.sections[this.currentSectionIndex]
                  .questions[this.currentQuestionIndex],
                error: this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].validationMessage,
              });
            }
            break;
          case 'Date_Time':
            if (
              this.currPage.sections[this.currentSectionIndex].questions[
                this.currentQuestionIndex
              ].preference.isRequired === 1 &&
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].logicallyHidden === false
            ) {
              if (
                this.getValue(
                  this.currPage.sections[this.currentSectionIndex].questions[
                    this.currentQuestionIndex
                  ],
                  'USE_DATE'
                ) === 'true'
              ) {
                if (
                  this.responses[this.currIndex].sections[
                    this.currentSectionIndex
                  ].questions[this.currentQuestionIndex].response[0] === ''
                ) {
                  this.isPageValid = false;
                  this.responses[this.currIndex].sections[
                    this.currentSectionIndex
                  ].questions[this.currentQuestionIndex].validationMessage =
                    'Please provide a date';
                  this.responses[this.currIndex].sections[
                    this.currentSectionIndex
                  ].questions[this.currentQuestionIndex].isValid = false;
                  this.PageErrors.push({
                    question: this.currPage.sections[this.currentSectionIndex]
                      .questions[this.currentQuestionIndex],
                    error: this.responses[this.currIndex].sections[
                      this.currentSectionIndex
                    ].questions[this.currentQuestionIndex].validationMessage,
                  });
                } else if (
                  this.getValue(
                    this.currPage.sections[this.currentSectionIndex].questions[
                      this.currentQuestionIndex
                    ],
                    'USE_TIME'
                  ) === 'true' &&
                  this.responses[this.currIndex].sections[
                    this.currentSectionIndex
                  ].questions[this.currentQuestionIndex].response[1] === ''
                ) {
                  this.isPageValid = false;
                  this.responses[this.currIndex].sections[
                    this.currentSectionIndex
                  ].questions[this.currentQuestionIndex].validationMessage =
                    'Please provide a time';
                  this.responses[this.currIndex].sections[
                    this.currentSectionIndex
                  ].questions[this.currentQuestionIndex].isValid = false;
                  this.PageErrors.push({
                    question: this.currPage.sections[this.currentSectionIndex]
                      .questions[this.currentQuestionIndex],
                    error: this.responses[this.currIndex].sections[
                      this.currentSectionIndex
                    ].questions[this.currentQuestionIndex].validationMessage,
                  });
                }
              } else if (
                this.getValue(
                  this.currPage.sections[this.currentSectionIndex].questions[
                    this.currentQuestionIndex
                  ],
                  'USE_TIME'
                ) === 'true'
              ) {
                if (
                  this.responses[this.currIndex].sections[
                    this.currentSectionIndex
                  ].questions[this.currentQuestionIndex].response[0] === ''
                ) {
                  this.isPageValid = false;
                  this.responses[this.currIndex].sections[
                    this.currentSectionIndex
                  ].questions[this.currentQuestionIndex].validationMessage =
                    'Please provide a time';
                  this.responses[this.currIndex].sections[
                    this.currentSectionIndex
                  ].questions[this.currentQuestionIndex].isValid = false;
                  this.PageErrors.push({
                    question: this.currPage.sections[this.currentSectionIndex]
                      .questions[this.currentQuestionIndex],
                    error: this.responses[this.currIndex].sections[
                      this.currentSectionIndex
                    ].questions[this.currentQuestionIndex].validationMessage,
                  });
                }
              }
            }
            break;
          case 'Yes_No':
            break;
          case 'Rating':
            break;
          case 'Checkbox':
            if (
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].response.length === 0 &&
              this.currPage.sections[this.currentSectionIndex].questions[
                this.currentQuestionIndex
              ].preference.isRequired === 1 &&
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].logicallyHidden === false
            ) {
              this.isPageValid = false;
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].validationMessage =
                'Please provide an input, this field is required';
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].isValid = false;
              this.PageErrors.push({
                question: this.currPage.sections[this.currentSectionIndex]
                  .questions[this.currentQuestionIndex],
                error: this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].validationMessage,
              });
            }
            break;
          case 'Radio':
            if (
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].response[0] === '' &&
              this.currPage.sections[this.currentSectionIndex].questions[
                this.currentQuestionIndex
              ].preference.isRequired === 1 &&
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].logicallyHidden === false
            ) {
              this.isPageValid = false;
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].validationMessage =
                'Please provide an input this field is required';
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].isValid = false;
              this.PageErrors.push({
                question: this.currPage.sections[this.currentSectionIndex]
                  .questions[this.currentQuestionIndex],
                error: this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].validationMessage,
              });
            }
            break;
          case 'Slider':
            break;
          case 'Number_rating':
            if (
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].response[0] === '' &&
              this.currPage.sections[this.currentSectionIndex].questions[
                this.currentQuestionIndex
              ].preference.isRequired === 1 &&
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].logicallyHidden === false
            ) {
              this.isPageValid = false;
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].validationMessage =
                'Please provide an input this field is required';
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].isValid = false;
              this.PageErrors.push({
                question: this.currPage.sections[this.currentSectionIndex]
                  .questions[this.currentQuestionIndex],
                error: this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].validationMessage,
              });
            }
            break;
          case 'File_upload':
            if (
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].response.length === 0 &&
              this.currPage.sections[this.currentSectionIndex].questions[
                this.currentQuestionIndex
              ].preference.isRequired === 1 &&
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].logicallyHidden === false
            ) {
              this.isPageValid = false;
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].validationMessage =
                'Please provide a File this field is required';
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].isValid = false;
              this.PageErrors.push({
                question: this.currPage.sections[this.currentSectionIndex]
                  .questions[this.currentQuestionIndex],
                error: this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].validationMessage,
              });
            }
            break;
          case 'Photo_upload':
            if (
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].response.length === 0 &&
              this.currPage.sections[this.currentSectionIndex].questions[
                this.currentQuestionIndex
              ].preference.isRequired === 1 &&
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].logicallyHidden === false
            ) {
              this.isPageValid = false;
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].validationMessage =
                'Please provide a File this field is required';
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].isValid = false;
              this.PageErrors.push({
                question: this.currPage.sections[this.currentSectionIndex]
                  .questions[this.currentQuestionIndex],
                error: this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].validationMessage,
              });
            }
            break;
          case 'Contact_Info':
            // const errorDetails = [];
            if (
              this.currPage.sections[this.currentSectionIndex].questions[
                this.currentQuestionIndex
              ].preference.isRequired === 1 &&
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].logicallyHidden === false
            ) {
              if (
                this.getValidationValue(
                  this.currPage.sections[this.currentSectionIndex].questions[
                    this.currentQuestionIndex
                  ],
                  'USE_NAME'
                ) === 'true' &&
                this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].response[0] === ''
              ) {
                errorDetails.push(
                  this.getValue(
                    this.currPage.sections[this.currentSectionIndex].questions[
                      this.currentQuestionIndex
                    ],
                    'NAME_LABEL'
                  )
                );
              }
              if (
                this.getValidationValue(
                  this.currPage.sections[this.currentSectionIndex].questions[
                    this.currentQuestionIndex
                  ],
                  'USE_COMPANY'
                ) === 'true' &&
                this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].response[0] === ''
              ) {
                errorDetails.push(
                  this.getValue(
                    this.currPage.sections[this.currentSectionIndex].questions[
                      this.currentQuestionIndex
                    ],
                    'COMPANY_LABEL'
                  )
                );
              }
              if (
                this.getValidationValue(
                  this.currPage.sections[this.currentSectionIndex].questions[
                    this.currentQuestionIndex
                  ],
                  'USE_ADDRESS'
                ) === 'true' &&
                this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].response[0] === ''
              ) {
                errorDetails.push(
                  this.getValue(
                    this.currPage.sections[this.currentSectionIndex].questions[
                      this.currentQuestionIndex
                    ],
                    'ADDRESS_LABEL'
                  )
                );
              }
              if (
                this.getValidationValue(
                  this.currPage.sections[this.currentSectionIndex].questions[
                    this.currentQuestionIndex
                  ],
                  'USE_ADDRESS_2'
                ) === 'true' &&
                this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].response[0] === ''
              ) {
                errorDetails.push(
                  this.getValue(
                    this.currPage.sections[this.currentSectionIndex].questions[
                      this.currentQuestionIndex
                    ],
                    'ADDRESS_2_LABEL'
                  )
                );
              }
              if (
                this.getValidationValue(
                  this.currPage.sections[this.currentSectionIndex].questions[
                    this.currentQuestionIndex
                  ],
                  'USE_CITY'
                ) === 'true' &&
                this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].response[0] === ''
              ) {
                errorDetails.push(
                  this.getValue(
                    this.currPage.sections[this.currentSectionIndex].questions[
                      this.currentQuestionIndex
                    ],
                    'CITY_LABEL'
                  )
                );
              }
              if (
                this.getValidationValue(
                  this.currPage.sections[this.currentSectionIndex].questions[
                    this.currentQuestionIndex
                  ],
                  'USE_STATE'
                ) === 'true' &&
                this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].response[0] === ''
              ) {
                errorDetails.push(
                  this.getValue(
                    this.currPage.sections[this.currentSectionIndex].questions[
                      this.currentQuestionIndex
                    ],
                    'STATE_LABEL'
                  )
                );
              }
              if (
                this.getValidationValue(
                  this.currPage.sections[this.currentSectionIndex].questions[
                    this.currentQuestionIndex
                  ],
                  'USE_ZIP'
                ) === 'true' &&
                this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].response[0] === ''
              ) {
                errorDetails.push(
                  this.getValue(
                    this.currPage.sections[this.currentSectionIndex].questions[
                      this.currentQuestionIndex
                    ],
                    'ZIP_LABEL'
                  )
                );
              }
              if (
                this.getValidationValue(
                  this.currPage.sections[this.currentSectionIndex].questions[
                    this.currentQuestionIndex
                  ],
                  'USE_COUNTRY'
                ) === 'true' &&
                this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].response[0] === ''
              ) {
                errorDetails.push(
                  this.getValue(
                    this.currPage.sections[this.currentSectionIndex].questions[
                      this.currentQuestionIndex
                    ],
                    'COUNTRY_LABEL'
                  )
                );
              }
              if (
                this.getValidationValue(
                  this.currPage.sections[this.currentSectionIndex].questions[
                    this.currentQuestionIndex
                  ],
                  'USE_EMAIL'
                ) === 'true' &&
                this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].response[0] === ''
              ) {
                errorDetails.push(
                  this.getValue(
                    this.currPage.sections[this.currentSectionIndex].questions[
                      this.currentQuestionIndex
                    ],
                    'EMAIL_LABEL'
                  )
                );
              }
              if (
                this.getValidationValue(
                  this.currPage.sections[this.currentSectionIndex].questions[
                    this.currentQuestionIndex
                  ],
                  'USE_PHONE'
                ) === 'true' &&
                this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].response[0] === ''
              ) {
                errorDetails.push(
                  this.getValue(
                    this.currPage.sections[this.currentSectionIndex].questions[
                      this.currentQuestionIndex
                    ],
                    'PHONE_LABEL'
                  )
                );
              }

              if (errorDetails.length > 0) {
                this.isPageValid = false;
                this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].validationMessage =
                  'Please provide an input for ' +
                  errorDetails.join() +
                  '. This fields are required';
                this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].isValid = false;
                this.PageErrors.push({
                  question: this.currPage.sections[this.currentSectionIndex]
                    .questions[this.currentQuestionIndex],
                  error: this.responses[this.currIndex].sections[
                    this.currentSectionIndex
                  ].questions[this.currentQuestionIndex].validationMessage,
                });
              }
            }

            break;
          case 'Multi_textbox':
            if (
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].response.length === 0 &&
              this.currPage.sections[this.currentSectionIndex].questions[
                this.currentQuestionIndex
              ].preference.isRequired === 1 &&
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].logicallyHidden === false
            ) {
              this.isPageValid = false;
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].validationMessage =
                'Please provide an input this field is required';
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].isValid = false;
              this.PageErrors.push({
                question: this.currPage.sections[this.currentSectionIndex]
                  .questions[this.currentQuestionIndex],
                error: this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].validationMessage,
              });
            } else {
              for (const responseItem of this.responses[this.currIndex]
                .sections[this.currentSectionIndex].questions[
                this.currentQuestionIndex
              ].response) {
                if (
                  responseItem === '' &&
                  this.currPage.sections[this.currentSectionIndex].questions[
                    this.currentQuestionIndex
                  ].preference.isRequired === 1 &&
                  this.responses[this.currIndex].sections[
                    this.currentSectionIndex
                  ].questions[this.currentQuestionIndex].logicallyHidden ===
                    false
                ) {
                  this.isPageValid = false;
                  this.responses[this.currIndex].sections[
                    this.currentSectionIndex
                  ].questions[this.currentQuestionIndex].validationMessage =
                    'Please provide values for all the fields';
                  this.responses[this.currIndex].sections[
                    this.currentSectionIndex
                  ].questions[this.currentQuestionIndex].isValid = false;
                  this.PageErrors.push({
                    question: this.currPage.sections[this.currentSectionIndex]
                      .questions[this.currentQuestionIndex],
                    error: this.responses[this.currIndex].sections[
                      this.currentSectionIndex
                    ].questions[this.currentQuestionIndex].validationMessage,
                  });
                  break;
                }
              }
            }
            break;
          case 'Map_Input':
            if (
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].response.length === 0 &&
              this.currPage.sections[this.currentSectionIndex].questions[
                this.currentQuestionIndex
              ].preference.isRequired === 1 &&
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].logicallyHidden === false
            ) {
              this.isPageValid = false;
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].validationMessage =
                'Please provide a location, this field is required';
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].isValid = false;
              this.PageErrors.push({
                question: this.currPage.sections[this.currentSectionIndex]
                  .questions[this.currentQuestionIndex],
                error: this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].validationMessage,
              });
            }
            break;
          case 'Video_Upload':
            if (
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].response.length === 0 &&
              this.currPage.sections[this.currentSectionIndex].questions[
                this.currentQuestionIndex
              ].preference.isRequired === 1 &&
              this.responses[this.currIndex].sections[this.currentSectionIndex]
                .questions[this.currentQuestionIndex].logicallyHidden === false
            ) {
              this.isPageValid = false;
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].validationMessage =
                'Please provide a video, this field is required';
              this.responses[this.currIndex].sections[
                this.currentSectionIndex
              ].questions[this.currentQuestionIndex].isValid = false;
              this.PageErrors.push({
                question: this.currPage.sections[this.currentSectionIndex]
                  .questions[this.currentQuestionIndex],
                error: this.responses[this.currIndex].sections[
                  this.currentSectionIndex
                ].questions[this.currentQuestionIndex].validationMessage,
              });
            }
            break;
        }
      }
    }
  }

  moveToNextQuestion() {
    this.validateCurrentQuestion();
    if (this.isPageValid) {
      if (
        this.currentQuestionIndex <
        this.currPage.sections[this.currentSectionIndex].questions.length - 1
      ) {
        // this was not the last question of current section of current page
        //
        this.displayNext = true;
        this.displayPrevious = true;
        this.displaySubmit = false;
        this.currentQuestionIndex += 1;
        this.selectedQuestion = this.currPage.sections[
          this.currentSectionIndex
        ].questions[this.currentQuestionIndex];
        // doing the below checks on next question,section, page to determine which buttons to show after going to the next question
        if (
          this.currentQuestionIndex <
          this.currPage.sections[this.currentSectionIndex].questions.length - 1
        ) {
          // not the last question in current section of current page
          this.displayNext = true;
          this.displayPrevious = true;
          this.displaySubmit = false;
        } else {
          // last question of current section of current page
          if (
            this.currentSectionIndex <
            this.currPage.sections[this.currentSectionIndex].length - 1
          ) {
            // not the last section of current page
            this.displayNext = true;
            this.displayPrevious = true;
            this.displaySubmit = false;
          } else {
            // last section of current page
            if (this.currIndex < this.pagedQuestions.length - 1) {
              // not the last page
              this.displayNext = true;
              this.displayPrevious = true;
              this.displaySubmit = false;
            } else {
              // last page
              this.displayNext = false;
              this.displayPrevious = true;
              this.displaySubmit = true;
            }
          }
        }
      } else {
        // this was the last question of current section of current page
        //
        if (
          this.currentSectionIndex <
          this.currPage.sections[this.currentSectionIndex].length - 1
        ) {
          // this was not the last section of current page
          this.currentSectionIndex += 1;
          this.currentQuestionIndex = 0;
          this.selectedQuestion = this.currPage.sections[
            this.currentSectionIndex
          ].questions[this.currentQuestionIndex];
          // doing the below checks on next question,section, page to determine which buttons to show after going to the next question
          if (
            this.currentQuestionIndex <
            this.currPage.sections[this.currentSectionIndex].questions.length -
              1
          ) {
            // not the last question in current section of current page
            this.displayNext = true;
            this.displayPrevious = true;
            this.displaySubmit = false;
          } else {
            // last question of current section of current page
            if (
              this.currentSectionIndex <
              this.currPage.sections[this.currentSectionIndex].length - 1
            ) {
              // not the last section of current page
              this.displayNext = true;
              this.displayPrevious = true;
              this.displaySubmit = false;
            } else {
              // last section of current page
              if (this.currIndex < this.pagedQuestions.length - 1) {
                // not the last page
                this.displayNext = true;
                this.displayPrevious = true;
                this.displaySubmit = false;
              } else {
                // last page
                this.displayNext = false;
                this.displayPrevious = true;
                this.displaySubmit = true;
              }
            }
          }
        } else {
          // this was also the last section of current page
          if (this.currIndex < this.pagedQuestions.length - 1) {
            // not the last page
            this.currentQuestionIndex = 0;
            this.currentSectionIndex = 0;
            this.currIndex += 1;
            this.currPage = this.pagedQuestions[this.currIndex];
            this.selectedQuestion = this.currPage.sections[
              this.currentSectionIndex
            ].questions[this.currentQuestionIndex];
            // doing the below checks on next question,section, page to determine which buttons to show after going to the next question
            if (
              this.currentQuestionIndex <
              this.currPage.sections[this.currentSectionIndex].questions
                .length -
                1
            ) {
              // not the last question in current section of current page
              this.displayNext = true;
              this.displayPrevious = true;
              this.displaySubmit = false;
            } else {
              // last question of current section of current page
              if (
                this.currentSectionIndex <
                this.currPage.sections[this.currentSectionIndex].length - 1
              ) {
                // not the last section of current page
                this.displayNext = true;
                this.displayPrevious = true;
                this.displaySubmit = false;
              } else {
                // last section of current page
                if (this.currIndex < this.pagedQuestions.length - 1) {
                  // not the last page
                  this.displayNext = true;
                  this.displayPrevious = true;
                  this.displaySubmit = false;
                } else {
                  // last page
                  this.displayNext = false;
                  this.displayPrevious = true;
                  this.displaySubmit = true;
                }
              }
            }
          } else {
            // this was also the last page
            // this case should not happen as next would not have been shown in case of very last question
          }
        }
      }
      if (this.currPage.theme === null) {
        if (
          this.surveyDefinition.theme.childTheme === null ||
          this.surveyDefinition.theme.childTheme === undefined
        ) {
          this.currentTheme = this.surveyDefinition.theme;
        } else {
          this.currentTheme = this.surveyDefinition.theme.childTheme;
        }
      } else {
        this.currentTheme = this.currPage.theme;
      }
      if (
        this.currentTheme.childTheme === null ||
        this.currentTheme.childTheme === undefined
      ) {
        if (
          this.surveyDefinition.theme.childTheme === null ||
          this.surveyDefinition.theme.childTheme.childTheme === null ||
          this.surveyDefinition.theme.childTheme === undefined ||
          this.surveyDefinition.theme.childTheme.childTheme === undefined
        ) {
          this.currentTheme.childTheme = new Theme();
          this.currentTheme.childTheme.bgcolor = 'white';
        } else {
          this.currentTheme.childTheme = this.surveyDefinition.theme.childTheme.childTheme;
        }
      }

      if (this.currPage.preference === null) {
        this.currentPreference = this.surveyDefinition.preference;
      } else {
        this.currentPreference = this.currPage.preference;
      }
    }
  }
  downloadImage(path: string) {
    return this.fileserverService.getFileURLbyPath(path);
  }

  moveToPreviousQuestion() {
    if (this.currentQuestionIndex !== 0) {
      // this was not the first question of current section of current page
      this.currentQuestionIndex -= 1;
      this.selectedQuestion = this.currPage.sections[
        this.currentSectionIndex
      ].questions[this.currentQuestionIndex];
      // doing below checks to decide which buttons to show on the previous question page
      if (this.currentQuestionIndex !== 0) {
        // this is not the first question of current section of current page
        this.displayNext = true;
        this.displaySubmit = false;
        this.displayPrevious = true;
      } else {
        // this is the first question of current section of current page
        if (this.currentSectionIndex !== 0) {
          // this is not the first section of current page
          this.displayNext = true;
          this.displaySubmit = false;
          this.displayPrevious = true;
        } else {
          // this is the first section of current page
          if (this.currIndex !== 0) {
            // this is not the first page
            this.displayNext = true;
            this.displaySubmit = false;
            this.displayPrevious = true;
          } else {
            // this is the first page
            this.displayNext = true;
            this.displaySubmit = false;
            this.displayPrevious = false;
          }
        }
      }
    } else {
      // this was the first question of current section of current page
      if (this.currentSectionIndex !== 0) {
        // this was not the first section of current page
        this.currentSectionIndex -= 1;
        this.currentQuestionIndex =
          this.currPage.sections[this.currentSectionIndex].questions.length - 1;
        this.selectedQuestion = this.currPage.sections[
          this.currentSectionIndex
        ].questions[this.currentQuestionIndex];
        // doing below checks to decide which buttons to show on the previous question page
        if (this.currentQuestionIndex !== 0) {
          // this is not the first question of current section of current page
          this.displayNext = true;
          this.displaySubmit = false;
          this.displayPrevious = true;
        } else {
          // this is the first question of current section of current page
          if (this.currentSectionIndex !== 0) {
            // this is not the first section of current page
            this.displayNext = true;
            this.displaySubmit = false;
            this.displayPrevious = true;
          } else {
            // this is the first section of current page
            if (this.currIndex !== 0) {
              // this is not the first page
              this.displayNext = true;
              this.displaySubmit = false;
              this.displayPrevious = true;
            } else {
              // this is the first page
              this.displayNext = true;
              this.displaySubmit = false;
              this.displayPrevious = false;
            }
          }
        }
      } else {
        // this was the first section of current page
        if (this.currIndex !== 0) {
          // this is not the first page
          this.currIndex -= 1;
          this.currPage = this.pagedQuestions[this.currIndex];
          this.currentSectionIndex = this.currPage.sections.length - 1;
          this.currentQuestionIndex =
            this.currPage.sections[this.currentSectionIndex].questions.length -
            1;
          this.selectedQuestion = this.currPage.sections[
            this.currentSectionIndex
          ].questions[this.currentQuestionIndex];
          // doing below checks to decide which buttons to show on the previous question page
          if (this.currentQuestionIndex !== 0) {
            // this is not the first question of current section of current page
            this.displayNext = true;
            this.displaySubmit = false;
            this.displayPrevious = true;
          } else {
            // this is the first question of current section of current page
            if (this.currentSectionIndex !== 0) {
              // this is not the first section of current page
              this.displayNext = true;
              this.displaySubmit = false;
              this.displayPrevious = true;
            } else {
              // this is the first section of current page
              if (this.currIndex !== 0) {
                // this is not the first page
                this.displayNext = true;
                this.displaySubmit = false;
                this.displayPrevious = true;
              } else {
                // this is the first page
                this.displayNext = true;
                this.displaySubmit = false;
                this.displayPrevious = false;
              }
            }
          }
        } else {
          // this was the first page.
          // this case shoul not happen as previous button will not be shown on very first question
        }
      }
    }
    if (this.currPage.theme === null) {
      if (
        this.surveyDefinition.theme.childTheme === null ||
        this.surveyDefinition.theme.childTheme === undefined
      ) {
        this.currentTheme = this.surveyDefinition.theme;
      } else {
        this.currentTheme = this.surveyDefinition.theme.childTheme;
      }
    } else {
      this.currentTheme = this.currPage.theme;
    }
    if (
      this.currentTheme.childTheme === null ||
      this.currentTheme.childTheme === undefined
    ) {
      if (
        this.surveyDefinition.theme.childTheme === null ||
        this.surveyDefinition.theme.childTheme.childTheme === null ||
        this.surveyDefinition.theme.childTheme === undefined ||
        this.surveyDefinition.theme.childTheme.childTheme === undefined
      ) {
        this.currentTheme.childTheme = new Theme();
        this.currentTheme.childTheme.bgcolor = 'white';
      } else {
        this.currentTheme.childTheme = this.surveyDefinition.theme.childTheme.childTheme;
      }
    }

    if (this.currPage.preference === null) {
      this.currentPreference = this.surveyDefinition.preference;
    } else {
      this.currentPreference = this.currPage.preference;
    }
  }

  validateCurrentPage() {
    this.isPageValid = true;
    this.PageErrors = [];
    for (let j = 0; j < this.currPage.sections.length; j++) {
      for (let k = 0; k < this.currPage.sections[j].questions.length; k++) {
        const errorDetails = [];
        if (!this.responses[this.currIndex].sections[j].questions[k].isValid) {
          this.isPageValid = false;
          this.PageErrors.push({
            question: this.currPage.sections[j].questions[k],
            error: this.responses[this.currIndex].sections[j].questions[k]
              .validationMessage,
          });
        } else {
          switch (
            this.currPage.sections[j].questions[k].type.typeDefinition
              .identifier
          ) {
            case 'Small_text':
              if (
                this.responses[this.currIndex].sections[j].questions[k]
                  .response[0] === '' &&
                this.currPage.sections[j].questions[k].preference.isRequired ===
                  1 &&
                this.responses[this.currIndex].sections[j].questions[k]
                  .logicallyHidden === false
              ) {
                this.isPageValid = false;
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].validationMessage =
                  'Please provide an input, this field is required';
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].isValid = false;
                this.PageErrors.push({
                  question: this.currPage.sections[j].questions[k],
                  error: this.responses[this.currIndex].sections[j].questions[k]
                    .validationMessage,
                });
              } else {
                // notjing
              }
              break;
            case 'Long_text':
              if (
                this.responses[this.currIndex].sections[j].questions[k]
                  .response[0] === '' &&
                this.currPage.sections[j].questions[k].preference.isRequired ===
                  1 &&
                this.responses[this.currIndex].sections[j].questions[k]
                  .logicallyHidden === false
              ) {
                this.isPageValid = false;
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].validationMessage =
                  'Please provide an input, this field is required';
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].isValid = false;
                this.PageErrors.push({
                  question: this.currPage.sections[j].questions[k],
                  error: this.responses[this.currIndex].sections[j].questions[k]
                    .validationMessage,
                });
              }
              break;
            case 'Numeric':
              if (
                this.responses[this.currIndex].sections[j].questions[k]
                  .response[0] === '' &&
                this.currPage.sections[j].questions[k].preference.isRequired ===
                  1 &&
                this.responses[this.currIndex].sections[j].questions[k]
                  .logicallyHidden === false
              ) {
                this.isPageValid = false;
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].validationMessage =
                  'Please provide an input, this field is required';
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].isValid = false;
                this.PageErrors.push({
                  question: this.currPage.sections[j].questions[k],
                  error: this.responses[this.currIndex].sections[j].questions[k]
                    .validationMessage,
                });
              }
              break;
            case 'Comment':
              if (
                this.responses[this.currIndex].sections[j].questions[k]
                  .response[0] === '' &&
                this.currPage.sections[j].questions[k].preference.isRequired ===
                  1 &&
                this.responses[this.currIndex].sections[j].questions[k]
                  .logicallyHidden === false
              ) {
                this.isPageValid = false;
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].validationMessage =
                  'Please provide an input, this field is required';
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].isValid = false;
                this.PageErrors.push({
                  question: this.currPage.sections[j].questions[k],
                  error: this.responses[this.currIndex].sections[j].questions[k]
                    .validationMessage,
                });
              }
              break;
            case 'Ranking':
              break;
            case 'Dropdown':
              if (
                this.responses[this.currIndex].sections[j].questions[k]
                  .response[0] === '' &&
                this.currPage.sections[j].questions[k].preference.isRequired ===
                  1 &&
                this.responses[this.currIndex].sections[j].questions[k]
                  .logicallyHidden === false
              ) {
                this.isPageValid = false;
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].validationMessage =
                  'Please provide an input, this field is required';
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].isValid = false;
                this.PageErrors.push({
                  question: this.currPage.sections[j].questions[k],
                  error: this.responses[this.currIndex].sections[j].questions[k]
                    .validationMessage,
                });
              }
              break;
            case 'Date_Time':
              if (
                this.currPage.sections[j].questions[k].preference.isRequired ===
                  1 &&
                this.responses[this.currIndex].sections[j].questions[k]
                  .logicallyHidden === false
              ) {
                if (
                  this.getValue(
                    this.currPage.sections[j].questions[k],
                    'USE_DATE'
                  ) === 'true'
                ) {
                  if (
                    this.responses[this.currIndex].sections[j].questions[k]
                      .response[0] === ''
                  ) {
                    this.isPageValid = false;
                    this.responses[this.currIndex].sections[j].questions[
                      k
                    ].validationMessage = 'Please provide a date';
                    this.responses[this.currIndex].sections[j].questions[
                      k
                    ].isValid = false;
                    this.PageErrors.push({
                      question: this.currPage.sections[j].questions[k],
                      error: this.responses[this.currIndex].sections[j]
                        .questions[k].validationMessage,
                    });
                  } else if (
                    this.getValue(
                      this.currPage.sections[j].questions[k],
                      'USE_TIME'
                    ) === 'true' &&
                    this.responses[this.currIndex].sections[j].questions[k]
                      .response[1] === ''
                  ) {
                    this.isPageValid = false;
                    this.responses[this.currIndex].sections[j].questions[
                      k
                    ].validationMessage = 'Please provide a time';
                    this.responses[this.currIndex].sections[j].questions[
                      k
                    ].isValid = false;
                    this.PageErrors.push({
                      question: this.currPage.sections[j].questions[k],
                      error: this.responses[this.currIndex].sections[j]
                        .questions[k].validationMessage,
                    });
                  }
                } else if (
                  this.getValue(
                    this.currPage.sections[j].questions[k],
                    'USE_TIME'
                  ) === 'true'
                ) {
                  if (
                    this.responses[this.currIndex].sections[j].questions[k]
                      .response[0] === ''
                  ) {
                    this.isPageValid = false;
                    this.responses[this.currIndex].sections[j].questions[
                      k
                    ].validationMessage = 'Please provide a time';
                    this.responses[this.currIndex].sections[j].questions[
                      k
                    ].isValid = false;
                    this.PageErrors.push({
                      question: this.currPage.sections[j].questions[k],
                      error: this.responses[this.currIndex].sections[j]
                        .questions[k].validationMessage,
                    });
                  }
                }
              }
              break;
            case 'Yes_No':
              break;
            case 'Rating':
              break;
            case 'Checkbox':
              if (
                this.responses[this.currIndex].sections[j].questions[k].response
                  .length === 0 &&
                this.currPage.sections[j].questions[k].preference.isRequired ===
                  1 &&
                this.responses[this.currIndex].sections[j].questions[k]
                  .logicallyHidden === false
              ) {
                this.isPageValid = false;
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].validationMessage =
                  'Please provide an input, this field is required';
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].isValid = false;
                this.PageErrors.push({
                  question: this.currPage.sections[j].questions[k],
                  error: this.responses[this.currIndex].sections[j].questions[k]
                    .validationMessage,
                });
              }
              break;
            case 'Radio':
              if (
                this.responses[this.currIndex].sections[j].questions[k]
                  .response[0] === '' &&
                this.currPage.sections[j].questions[k].preference.isRequired ===
                  1 &&
                this.responses[this.currIndex].sections[j].questions[k]
                  .logicallyHidden === false
              ) {
                this.isPageValid = false;
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].validationMessage =
                  'Please provide an input this field is required';
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].isValid = false;
                this.PageErrors.push({
                  question: this.currPage.sections[j].questions[k],
                  error: this.responses[this.currIndex].sections[j].questions[k]
                    .validationMessage,
                });
              }
              break;
            case 'Slider':
              break;
            case 'Number_rating':
              if (
                this.responses[this.currIndex].sections[j].questions[k]
                  .response[0] === '' &&
                this.currPage.sections[j].questions[k].preference.isRequired ===
                  1 &&
                this.responses[this.currIndex].sections[j].questions[k]
                  .logicallyHidden === false
              ) {
                this.isPageValid = false;
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].validationMessage =
                  'Please provide an input this field is required';
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].isValid = false;
                this.PageErrors.push({
                  question: this.currPage.sections[j].questions[k],
                  error: this.responses[this.currIndex].sections[j].questions[k]
                    .validationMessage,
                });
              }
              break;
            case 'File_upload':
              if (
                this.responses[this.currIndex].sections[j].questions[k].response
                  .length === 0 &&
                this.currPage.sections[j].questions[k].preference.isRequired ===
                  1 &&
                this.responses[this.currIndex].sections[j].questions[k]
                  .logicallyHidden === false
              ) {
                this.isPageValid = false;
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].validationMessage =
                  'Please provide a File this field is required';
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].isValid = false;
                this.PageErrors.push({
                  question: this.currPage.sections[j].questions[k],
                  error: this.responses[this.currIndex].sections[j].questions[k]
                    .validationMessage,
                });
              }
              break;
            case 'Photo_upload':
              if (
                this.responses[this.currIndex].sections[j].questions[k].response
                  .length === 0 &&
                this.currPage.sections[j].questions[k].preference.isRequired ===
                  1 &&
                this.responses[this.currIndex].sections[j].questions[k]
                  .logicallyHidden === false
              ) {
                this.isPageValid = false;
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].validationMessage =
                  'Please provide a File this field is required';
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].isValid = false;
                this.PageErrors.push({
                  question: this.currPage.sections[j].questions[k],
                  error: this.responses[this.currIndex].sections[j].questions[k]
                    .validationMessage,
                });
              }
              break;
            case 'Contact_Info':
              // const errorDetails = [];
              if (
                this.currPage.sections[j].questions[k].preference.isRequired ===
                  1 &&
                this.responses[this.currIndex].sections[j].questions[k]
                  .logicallyHidden === false
              ) {
                if (
                  this.getValidationValue(
                    this.currPage.sections[j].questions[k],
                    'USE_NAME'
                  ) === 'true' &&
                  this.responses[this.currIndex].sections[j].questions[k]
                    .response[0] === ''
                ) {
                  errorDetails.push(
                    this.getValue(
                      this.currPage.sections[j].questions[k],
                      'NAME_LABEL'
                    )
                  );
                }
                if (
                  this.getValidationValue(
                    this.currPage.sections[j].questions[k],
                    'USE_COMPANY'
                  ) === 'true' &&
                  this.responses[this.currIndex].sections[j].questions[k]
                    .response[0] === ''
                ) {
                  errorDetails.push(
                    this.getValue(
                      this.currPage.sections[j].questions[k],
                      'COMPANY_LABEL'
                    )
                  );
                }
                if (
                  this.getValidationValue(
                    this.currPage.sections[j].questions[k],
                    'USE_ADDRESS'
                  ) === 'true' &&
                  this.responses[this.currIndex].sections[j].questions[k]
                    .response[0] === ''
                ) {
                  errorDetails.push(
                    this.getValue(
                      this.currPage.sections[j].questions[k],
                      'ADDRESS_LABEL'
                    )
                  );
                }
                if (
                  this.getValidationValue(
                    this.currPage.sections[j].questions[k],
                    'USE_ADDRESS_2'
                  ) === 'true' &&
                  this.responses[this.currIndex].sections[j].questions[k]
                    .response[0] === ''
                ) {
                  errorDetails.push(
                    this.getValue(
                      this.currPage.sections[j].questions[k],
                      'ADDRESS_2_LABEL'
                    )
                  );
                }
                if (
                  this.getValidationValue(
                    this.currPage.sections[j].questions[k],
                    'USE_CITY'
                  ) === 'true' &&
                  this.responses[this.currIndex].sections[j].questions[k]
                    .response[0] === ''
                ) {
                  errorDetails.push(
                    this.getValue(
                      this.currPage.sections[j].questions[k],
                      'CITY_LABEL'
                    )
                  );
                }
                if (
                  this.getValidationValue(
                    this.currPage.sections[j].questions[k],
                    'USE_STATE'
                  ) === 'true' &&
                  this.responses[this.currIndex].sections[j].questions[k]
                    .response[0] === ''
                ) {
                  errorDetails.push(
                    this.getValue(
                      this.currPage.sections[j].questions[k],
                      'STATE_LABEL'
                    )
                  );
                }
                if (
                  this.getValidationValue(
                    this.currPage.sections[j].questions[k],
                    'USE_ZIP'
                  ) === 'true' &&
                  this.responses[this.currIndex].sections[j].questions[k]
                    .response[0] === ''
                ) {
                  errorDetails.push(
                    this.getValue(
                      this.currPage.sections[j].questions[k],
                      'ZIP_LABEL'
                    )
                  );
                }
                if (
                  this.getValidationValue(
                    this.currPage.sections[j].questions[k],
                    'USE_COUNTRY'
                  ) === 'true' &&
                  this.responses[this.currIndex].sections[j].questions[k]
                    .response[0] === ''
                ) {
                  errorDetails.push(
                    this.getValue(
                      this.currPage.sections[j].questions[k],
                      'COUNTRY_LABEL'
                    )
                  );
                }
                if (
                  this.getValidationValue(
                    this.currPage.sections[j].questions[k],
                    'USE_EMAIL'
                  ) === 'true' &&
                  this.responses[this.currIndex].sections[j].questions[k]
                    .response[0] === ''
                ) {
                  errorDetails.push(
                    this.getValue(
                      this.currPage.sections[j].questions[k],
                      'EMAIL_LABEL'
                    )
                  );
                }
                if (
                  this.getValidationValue(
                    this.currPage.sections[j].questions[k],
                    'USE_PHONE'
                  ) === 'true' &&
                  this.responses[this.currIndex].sections[j].questions[k]
                    .response[0] === ''
                ) {
                  errorDetails.push(
                    this.getValue(
                      this.currPage.sections[j].questions[k],
                      'PHONE_LABEL'
                    )
                  );
                }

                if (errorDetails.length > 0) {
                  this.isPageValid = false;
                  this.responses[this.currIndex].sections[j].questions[
                    k
                  ].validationMessage =
                    'Please provide an input for ' +
                    errorDetails.join() +
                    '. This fields are required';
                  this.responses[this.currIndex].sections[j].questions[
                    k
                  ].isValid = false;
                  this.PageErrors.push({
                    question: this.currPage.sections[j].questions[k],
                    error: this.responses[this.currIndex].sections[j].questions[
                      k
                    ].validationMessage,
                  });
                }
              }

              break;
            case 'Multi_textbox':
              if (
                this.responses[this.currIndex].sections[j].questions[k].response
                  .length === 0 &&
                this.currPage.sections[j].questions[k].preference.isRequired ===
                  1 &&
                this.responses[this.currIndex].sections[j].questions[k]
                  .logicallyHidden === false
              ) {
                this.isPageValid = false;
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].validationMessage =
                  'Please provide an input this field is required';
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].isValid = false;
                this.PageErrors.push({
                  question: this.currPage.sections[j].questions[k],
                  error: this.responses[this.currIndex].sections[j].questions[k]
                    .validationMessage,
                });
              } else {
                for (const responseItem of this.responses[this.currIndex]
                  .sections[j].questions[k].response) {
                  if (
                    responseItem === '' &&
                    this.currPage.sections[j].questions[k].preference
                      .isRequired === 1 &&
                    this.responses[this.currIndex].sections[j].questions[k]
                      .logicallyHidden === false
                  ) {
                    this.isPageValid = false;
                    this.responses[this.currIndex].sections[j].questions[
                      k
                    ].validationMessage =
                      'Please provide values for all the fields';
                    this.responses[this.currIndex].sections[j].questions[
                      k
                    ].isValid = false;
                    this.PageErrors.push({
                      question: this.currPage.sections[j].questions[k],
                      error: this.responses[this.currIndex].sections[j]
                        .questions[k].validationMessage,
                    });
                    break;
                  }
                }
              }
              break;
            case 'Map_Input':
              if (
                this.responses[this.currIndex].sections[j].questions[k].response
                  .length === 0 &&
                this.currPage.sections[j].questions[k].preference.isRequired ===
                  1 &&
                this.responses[this.currIndex].sections[j].questions[k]
                  .logicallyHidden === false
              ) {
                this.isPageValid = false;
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].validationMessage =
                  'Please provide a location, this field is required';
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].isValid = false;
                this.PageErrors.push({
                  question: this.currPage.sections[j].questions[k],
                  error: this.responses[this.currIndex].sections[j].questions[k]
                    .validationMessage,
                });
              }
              break;
            case 'Video_Upload':
              if (
                this.responses[this.currIndex].sections[j].questions[k].response
                  .length === 0 &&
                this.currPage.sections[j].questions[k].preference.isRequired ===
                  1 &&
                this.responses[this.currIndex].sections[j].questions[k]
                  .logicallyHidden === false
              ) {
                this.isPageValid = false;
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].validationMessage =
                  'Please provide a video/audio, this field is required';
                this.responses[this.currIndex].sections[j].questions[
                  k
                ].isValid = false;
                this.PageErrors.push({
                  question: this.currPage.sections[j].questions[k],
                  error: this.responses[this.currIndex].sections[j].questions[k]
                    .validationMessage,
                });
              }
              break;
          }
        }
      }
    }
  }

  //   downloadImage(path: string) {
  //     return this.fileserverService.getFileURLbyPath(path);
  //   }

  getQuestionElementById(id): HTMLElement {
    return document.getElementById(id);
  }

  selectPage(event: any) {
    this.currPage = this.pagedQuestions[event];

    this.currIndex = event;
    if (this.currPage.theme === null) {
      if (
        this.surveyDefinition.theme.childTheme === null ||
        this.surveyDefinition.theme.childTheme === undefined
      ) {
        this.currentTheme = this.surveyDefinition.theme;
      } else {
        this.currentTheme = this.surveyDefinition.theme.childTheme;
      }
    } else {
      this.currentTheme = this.currPage.theme;
    }
    if (
      this.currentTheme.childTheme === null ||
      this.currentTheme.childTheme === undefined
    ) {
      if (
        this.surveyDefinition.theme.childTheme === null ||
        this.surveyDefinition.theme.childTheme.childTheme === null ||
        this.surveyDefinition.theme.childTheme === undefined ||
        this.surveyDefinition.theme.childTheme.childTheme === undefined
      ) {
        this.currentTheme.childTheme = new Theme();
        this.currentTheme.childTheme.bgcolor = 'white';
      } else {
        this.currentTheme.childTheme = this.surveyDefinition.theme.childTheme.childTheme;
      }
    }

    if (this.currPage.preference === null) {
      this.currentPreference = this.surveyDefinition.preference;
    } else {
      this.currentPreference = this.currPage.preference;
    }
  }

  moveToPreviousPage() {
    this.selectPage(this.currIndex - 1);

    if (this.currIndex > 0) {
      this.displayPrevious = true;
      this.displaySubmit = false;
      this.displayNext = true;
    } else {
      this.displayPrevious = false;
      this.displaySubmit = false;
      this.displayNext = true;
    }

    // TO Do fetch background color of page from theme
    // if (this.currentPage.themeId) {
    //   this.subscribeToSaveResponse(this.themeService.find(this.currentPage.themeId));
    //   // .subscribe(
    //   //   (subRes: HttpResponse<ITheme>) => (this.res = subRes, this.bgColor = subRes[0].bgColor),
    //   //   (subRes: HttpErrorResponse) => this.onError(subRes.message)
    //   // );
    // }
  }
  moveToNextPage() {
    this.validateCurrentPage();
    if (this.isPageValid) {
      this.selectPage(this.currIndex + 1);

      if (this.pagedQuestions.length - 1 > this.currIndex) {
        this.displayNext = true;
        this.displaySubmit = false;
        this.displayPrevious = true;
      } else {
        this.displayNext = false;
        this.displaySubmit = true;
        this.displayPrevious = true;
      }
    }
    // TO Do fetch background color of page from theme
    // if (this.currentPage.themeId) {
    //   this.subscribeToSaveResponse(this.themeService.find(this.currentPage.themeId));
    //   // .subscribe(
    //   //   (subRes: HttpResponse<ITheme>) => (this.res = subRes, this.bgColor = subRes[0].bgColor),
    //   //   (subRes: HttpErrorResponse) => this.onError(subRes.message)
    //   // );
    // }
  }

  handleDeclarationChange = (isAccepted: boolean) => {
    this.isDeclarationAccepted = isAccepted;
  };

  initialiseResult = () => {
    this.remarks = '';
    if (this.finalSelection == 'REJECTED') {
      this.resultReason = this.rejectionReasonList;
    } else if (this.finalSelection == 'ON_HOLD') {
      this.resultReason = this.onHoldReasonList;
    }
  };

  validateFiles = (): boolean => {
    //! snapshot mandatory for WEBEX ENABLEX TEAMS
    if (['WEBEX', 'ENABLEX', 'TEAMS'].includes(this.platformId)) {
      // if no snapshots attached
      if (this.files.length == 0) {
        // if not sent back to panel (UPCOMING) or sent back and uploaded images are also deleted
        if (this.noUploadedImages || !this.sentBackToPanelist) {
          this.sharedService.openSnackBar('Please Upload Snapshots', '');
          return false;
        }
      }
    }
    return true;
  };

  submitPage() {
    if (
      this.candidateSource == 'INTAP' &&
      this.jobType == 'HR' &&
      (this.finalSelection == 'REJECTED' || this.finalSelection == 'ON_HOLD') &&
      this.remarks == ''
    ) {
      this.sharedService.openSnackBar(
        'Please mention the remarks / reason',
        ''
      );
    } else {
      this.validateCurrentPage();
      if (this.isPageValid) {
        if (this.validateFiles()) {
          this.submitSnapshotAndSendFeedback();
        }
      }
    }

    // alert('submit survey');
  }
  isMobile(): boolean {
    return (
      this.mediaObserver.isActive('xs') || this.mediaObserver.isActive('sm')
    );
  }
  createAndSendResponse() {
    this.notifier.notify('info', 'Submitting Response....');
    const surveyResponse: any = {};
    if (this.receivedResponses && this.receivedResponses.length > 0)
      surveyResponse.id = this.receivedResponses[
        this.receivedResponses.length - 1
      ].id;
    surveyResponse.cirId = this.cirId;
    surveyResponse.interviewId = this.interviewId;
    surveyResponse.questionResponses = [];
    surveyResponse.rejectionReason = this.remarks;
    surveyResponse.result = this.finalSelection;
    surveyResponse.panelistAcceptance = this.isDeclarationAccepted;

    for (let i = 0; i < this.pagedQuestions.length; i++) {
      for (let j = 0; j < this.pagedQuestions[i].sections.length; j++) {
        for (
          let k = 0;
          k < this.pagedQuestions[i].sections[j].questions.length;
          k++
        ) {
          const questionResponse: any = {};
          questionResponse.questionId = this.pagedQuestions[i].sections[
            j
          ].questions[k].id;
          questionResponse.answer = [];

          if (
            this.pagedQuestions[i].sections[j].questions[k].type.typeDefinition
              .identifier === 'Ranking'
          ) {
            if (
              this.responses[i].sections[j].questions[k].logicallyHidden ===
              false
            ) {
              for (
                let p = 0;
                p < this.responses[i].sections[j].questions[k].response.length;
                p++
              ) {
                questionResponse.answer.push(
                  this.responses[i].sections[j].questions[k].response[p].value
                );
              }
            } else {
              questionResponse.answer.push('');
            }
          } else if (
            this.pagedQuestions[i].sections[j].questions[k].type.typeDefinition
              .identifier === 'HTML_block'
          ) {
            questionResponse.answer.push('');
          } else if (
            this.pagedQuestions[i].sections[j].questions[k].type.typeDefinition
              .identifier === 'Multi_Question'
          ) {
            const childQuestions = this.pagedQuestions[i].sections[j].questions[
              k
            ].childQuestions;

            for (const childResponse of this.responses[i].sections[j].questions[
              k
            ].childResponses) {
              const questionChildResponse: any = {};
              questionChildResponse.questionId =
                childResponse.sections[0].questions[0].id;
              questionChildResponse.answer = [];
              const childQuestion = childQuestions.find(
                (item) => item.id === questionChildResponse.questionId
              );
              if (childQuestion) {
                if (
                  this.responses[i].sections[j].questions[k].logicallyHidden ===
                  false
                ) {
                  if (
                    childQuestion.type.typeDefinition.identifier === 'Ranking'
                  ) {
                    for (
                      let cp = 0;
                      cp <
                      childResponse.sections[0].questions[0].response.length;
                      cp++
                    ) {
                      questionChildResponse.answer.push(
                        childResponse.sections[0].questions[0].response[cp]
                          .value
                      );
                    }
                  } else if (
                    childQuestion.type.typeDefinition.identifier ===
                    'HTML_block'
                  ) {
                    questionChildResponse.answer.push('');
                  } else {
                    if (
                      childResponse.sections[0].questions[0].response.length ===
                      0
                    ) {
                      questionChildResponse.answer.push('');
                    } else {
                      for (
                        let cp = 0;
                        cp <
                        childResponse.sections[0].questions[0].response.length;
                        cp++
                      ) {
                        questionChildResponse.answer.push(
                          String(
                            childResponse.sections[0].questions[0].response[cp]
                          )
                        );
                      }
                    }
                  }
                } else {
                  questionChildResponse.answer.push('');
                }
                surveyResponse.questionResponses.push(questionChildResponse);
                this.responseMap.set(childQuestion.id, questionResponse);
              }

              // }
            }
          } else {
            if (
              this.responses[i].sections[j].questions[k].logicallyHidden ===
              false
            ) {
              for (
                let p = 0;
                p < this.responses[i].sections[j].questions[k].response.length;
                p++
              ) {
                questionResponse.answer.push(
                  String(this.responses[i].sections[j].questions[k].response[p])
                );
              }
            } else {
              questionResponse.answer.push('');
            }
          }
          if (
            this.pagedQuestions[i].sections[j].questions[k].type.typeDefinition
              .identifier !== 'Multi_Question'
          ) {
            this.responseMap.set(questionResponse.questionId, questionResponse);
            surveyResponse.questionResponses.push(questionResponse);
          }
        }
      }
    }
    const endTime = new Date();

    const seconds = (endTime.getTime() - this.startTime.getTime()) / 1000;
    surveyResponse.timeElapsed = seconds;

    surveyResponse.surveyDefinitionId = this.surveyDefinition.id;
    // this.loading = true;
    this.takeSurveyService
      .createResponse(this.surveyDefinition.id, surveyResponse)
      .subscribe(
        (res: HttpResponse<any>) => {
          if (this.surveyDefinition.surveyType === 'POLL')
            this.thankYou = 'Thank you';
          this.sharedService.openSnackBar(
            'Feedback submitted successfully!',
            ''
          );
          this.router.navigate(['/interviewer-dashboard']);
          this.loading = false;
          // this.notifier.notify('success', 'Your Response is recoreded! Thank you for taking the Survey!');
        },
        (res: HttpErrorResponse) => {
          this.thankYou = null;
          this.sharedService.openSnackBar(
            'Some Error Occured. Please try again.',
            ''
          );
          // this.router.navigate(['/interviewer-dashboard']);
          this.loading = false;
        }
      );
  }

  submitQuestion() {
    this.validateCurrentQuestion();
    if (this.isPageValid) {
      this.createAndSendResponse();
    }
  }

  //   openPublishSurveyDialog(surveyDefinition: SurveyDefinition): void {
  //     const dialogRef = this.dialog.open(SurveyPublishDialogContentsComponent, {
  //       width: '650px',
  //       data: {
  //         ...this.surveyDefinition
  //       }
  //       // height: '500px',this.publishSurvey = data
  //       // position: {
  //       //   'top': '0',
  //       //   'left': '0'
  //       // }
  //     });

  //     // Do i need to subscribe the data after create ???
  //     dialogRef.afterClosed().subscribe(data => {
  //       this.publishSurvey = data;
  //     });
  //   }
  //   protected subscribeToSaveResponse(result: Observable<HttpResponse<ITheme>>) {
  //     result.subscribe(() => this.onSaveSuccess(result), () => this.onSaveError(result));
  //   }

  getValidationValue(question: any, param: string): string {
    const object = question.type.validationValues.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }

  getValue(question: any, param: string): string {
    const object = question.type.values.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }

  submitSnapshotAndSendFeedback() {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        title: `Submit Feedback Confirmation`,
        text: this.jobTitle
          ? `Are you sure you want to submit the feedback for <b>${this.jobTitle}</b> role for the candidate?`
          : `Are you sure you want to submit the feedback?`,
      },
      autoFocus: false,
      backdropClass: 'backdrop-background',
      maxWidth: '550px',
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'Yes') {
        this.loading = true;
        this.timerService.endDynamicEventTimer(this.timerInterview);
        if (this.files.length > 0) {
          let formData = new FormData();
          const specData = {
            interviewId: this.interviewId,
            tenantId: localStorage.getItem('tenantId'),
          };
          formData.append('specData', JSON.stringify(specData));
          this.files.forEach((file) => {
            formData.append('files', file);
          });
          this.fileService.uploadSnapshotBulk(formData).subscribe(
            () => {
              this.createAndSendResponse();
            },
            () => {
              this.loading = false;
              this.sharedService.openSnackBar('Snapshot upload failed!', '');
            }
          );
        } else {
          // for OFFLINE, TELEPHONIC cases
          this.createAndSendResponse();
        }
      }
    });
  }

  handleNoImagesChange = (noUploadedImages: boolean) => {
    // Sents True flag for  No images
    this.noUploadedImages = noUploadedImages;
  };
}
