import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MaterialModule } from 'src/app/angular-material.module';
import { GatewaySharedModule } from 'src/app/shared/shared.module';

import { CoreModule } from 'keycloak-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MailStatusActionRendererComponent } from './event-notifications-renderer/mail-status-renderer';
import { InfyEventNotificationsComponent } from './infy-event-notifications/infy-event-notifications.component';
import { InfyEventNotificationsInterviewsGridComponent } from './infy-event-notifications-interviews-grid/infy-event-notifications-interviews-grid.component';
import { InfyEventNotificationsMailsGridComponent } from './infy-event-notifications-mails-grid/infy-event-notifications-mails-grid.component';
import { InfyEventNotificationsPanelistsGridComponent } from './infy-event-notifications-panelists-grid/infy-event-notifications-panelists-grid.component';
import { IwpEventNotificationsRoutingModule } from './iwp-event-notifications-routing.module';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    GatewaySharedModule,
    // IwpEventNotificationsRoutingModule,
    MaterialModule,
    CarouselModule,
    CoreModule,
    NgbModule,
  ],
  declarations: [
    MailStatusActionRendererComponent,
    InfyEventNotificationsComponent,
    InfyEventNotificationsInterviewsGridComponent,
    InfyEventNotificationsMailsGridComponent,
    InfyEventNotificationsPanelistsGridComponent,
  ],
  providers: [],
})
export class IwpEventNotificationsModule {}
