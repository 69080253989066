import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  template: `<a
    class="DSA_wb_hyperLink-btn"
    style="text-decoration: none;"
    href="javascript:void(0)"
    (click)="onClick()"
    matTooltip="View Template"
    matTooltipClass="DSA_tooltip"
  >
    {{ value }}
  </a>`,
})
export class EvaluationTemplateLinkRendererComponent
  implements AgRendererComponent {
  value: any;
  link: any;
  job: any;

  params: any;
  constructor(private router: Router) {}
  agInit(params: any): void {
    this.value = params.value;
    if (!params.data) this.link = '';
    else {
      this.link = params.valueFormatted;
    }
  }

  onClick() {
    this.router.navigate([this.link]);
  }

  refresh(params: any): boolean {
    return false;
  }
}
