<div class="container-fluid">
  <div class="row mb-2">
    <div class="col-12">
      <h1 class="DSA_wb_h1Header">
        {{ !data.jobs ? "Create Job" : "Edit Job" }}
      </h1>
    </div>
  </div>
  <app-loader [display]="isLoading"></app-loader>
  <div *ngIf="!isLoading">
    <form class="row" [formGroup]="jobForm">
      <div class="col-12">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <input
            matInput
            placeholder="Title"
            formControlName="title"
            value=""
            required
          />
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <input
            matInput
            placeholder="Description"
            formControlName="description"
            value=""
            required
          />
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <input
            matInput
            placeholder="Domain"
            formControlName="domain"
            value=""
            required
          />
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <input
            matInput
            placeholder="Category"
            formControlName="category"
            value=""
            required
          />
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <mat-label>Tags</mat-label>
          <mat-chip-list #chipList formControlName="tags" required>
            <mat-chip
              class="DSA_wb_chipStyle-Normal DSA_wb_chip-input marR16"
              disableRipple="true"
              *ngFor="let tag of tags.value"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(tag)"
            >
              {{ tag }}
              <i
                matChipRemove
                class="icon x-16 icon-close-mini DSA_wb_chipIconRight"
                *ngIf="removable"
              ></i>
            </mat-chip>
            <input
              placeholder="New tags..."
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="add($event)"
            />
          </mat-chip-list>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <input
            matInput
            type="number"
            min="1"
            placeholder="Rating Scale"
            formControlName="ratingScale"
            value=""
            required
          />
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel">
          <mat-label>Experience Type</mat-label>
          <mat-select
            name="experienceType"
            formControlName="experienceType"
            required
            disableOptionCentering
            panelClass="DSA_wb-custom-select-panel" class="DSA_wb-custom-select"
          >
            <mat-option
              *ngFor="let option of experienceTypeOptions"
              [value]="option.value"
            >
              {{ option.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <input
            matInput
            placeholder="Organization"
            formControlName="organization"
            value=""
            required
          />
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <input
            matInput
            placeholder="Job Level"
            formControlName="jobLevel"
            value=""
            required
          />
          <mat-error
            *ngIf="
              jobForm.controls['jobLevel'].dirty &&
              jobForm.controls['jobLevel'].hasError('pattern')
            "
            >Invalid Job Level. i.e 1/1A</mat-error
          >
        </mat-form-field>
      </div>

      <div class="col-6">
        <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel">
          <mat-label>Job Hiring Type</mat-label>
          <mat-select disableOptionCentering name="type" formControlName="type" required 
          panelClass="DSA_wb-custom-select-panel" class="DSA_wb-custom-select">
            <mat-option
              *ngFor="let options of keys(jobTypeOptions)"
              [value]="options"
            >
              {{ options }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel">
          <mat-label>Feedback Form Type</mat-label>
          <mat-select
            name="feedbackFormType"
            formControlName="feedbackFormType"
            required
            disableOptionCentering  panelClass="DSA_wb-custom-select-panel" class="DSA_wb-custom-select"
          >
            <mat-option
              *ngFor="let formType of feedbackFormTypeOptions"
              [value]="formType"
            >
              {{ formType }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <div class="row mb-3 d-flex justify-content-end">
      <button
        mat-flat-button
        class="DSA_wb_miniSecondary-btn mr-2"
        (click)="onNoClick()"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        class="DSA_wb_miniPrimary-btn"
        [disabled]="jobForm.invalid"
        (click)="handleCreateUpdateClick()"
      >
        {{ !data.jobs ? "Create" : "Update" }}
      </button>
    </div>
  </div>
</div>
