<fieldset [disabled]="disabled">
  <div *ngIf="view==='TWO_COLUMNS';else oneColumn">
    <div class="row">
      <div class="col-6" style="display: flex;flex-direction: column;justify-content:center;">
        <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
            {{questionIndex+1}}.
          </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
            *
          </span></p>
        <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">

      </div>
      <div class="col-6">

        <mat-form-field *ngIf="getValue(question,'USE_DATE')">
          <mat-label>Date</mat-label>
          <input matInput #date [matDatepicker]="picker" [required]="question.preference.isRequired==='1'"
            (focus)="picker.open()" [(ngModel)]="dateValueforModel" (blur)="inputChanged(date.value)"
            placeholder="question.hint.text" />
          <mat-datepicker-toggle panelclass="DSA_wb_dateClass" [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker>
            <mat-icon matDatepickerToggleIcon>calender</mat-icon>
          </mat-datepicker>

        </mat-form-field>

        <div *ngIf="useTime">
          <ngb-timepicker [(ngModel)]="time" (ngModelChange)="timeChanged($event)"></ngb-timepicker>
        </div>
        <span *ngIf="!responsePage.sections[section].questions[questionIndex].isValid"
          style="color:red;font-size: 12px;">
          {{responsePage.sections[section].questions[questionIndex].validationMessage}}</span>
      </div>
    </div>
  </div>

  <ng-template #oneColumn>

    <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
        {{questionIndex+1}}.
      </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
        *
      </span></p>
    <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">

    <mat-form-field *ngIf="getValue(question,'USE_DATE')">
      <mat-label>Date</mat-label>
      <input matInput #date [matDatepicker]="picker" [required]="question.preference.isRequired==='1'"
        (focus)="picker.open()" [(ngModel)]="dateValueforModel" (blur)="inputChanged(date.value)"
        placeholder="question.hint.text" />
      <mat-datepicker-toggle panelclass="DSA_wb_dateClass" [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker>
        <mat-icon matDatepickerToggleIcon>calender</mat-icon>
      </mat-datepicker>

    </mat-form-field>

    <div *ngIf="useTime">
      <ngb-timepicker [(ngModel)]="time" (ngModelChange)="timeChanged($event)"></ngb-timepicker>
    </div>
    <span *ngIf="!responsePage.sections[section].questions[questionIndex].isValid" style="color:red;font-size: 12px;">
      {{responsePage.sections[section].questions[questionIndex].validationMessage}}</span>
  </ng-template>



</fieldset>
