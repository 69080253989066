<app-loader [display]="loader"></app-loader>
<div *ngIf="!loader" class="container-fluid layout">
  <div class="d-flex">
  <div class="ml-3 mr-3" role="button">
    <i class="icon x-24 back-icon" (click)="blocation.back()">
    </i>
  </div>
  <h3>
    {{ eventStatus !== "CLOSED" ? "Select Mail Template" : "Mail Templates" }}
  </h3>
</div>
  <div class="marT32">
    <div class="container-fluid">
      <div class="row" *ngIf="eventMailTemplates.length === 0">
        <div class="col-12">No Templates Currently In place, Please Choose From Below</div>
      </div>

      <div class="row mt-3" *ngIf="eventStatus !== 'CLOSED'">
        <div class="col-6" *ngIf="!edit">
          <mat-form-field
            class="DSA_Wb_custom-form-field"
            appearance="fill"
            matTooltip="Select category"
            matTooltipPosition="after"
          >
            <mat-label>Category *</mat-label>
            <mat-select
              [(ngModel)]="selectedCategory"
              (selectionChange)="getTemplatesByCategory($event.value)"
              [disabled] = "!accountService.hasFeatureAccess(FeatureName.EVENT_TEMPLATE_MAPPING, PermissionType.CREATE)"
            >
              <mat-option
                *ngFor="let category of availableCategories"
                [value]="category.name"
              >
                {{ category.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field
            class="DSA_Wb_custom-form-field"
            appearance="fill"
            matTooltip="Select template"
            matTooltipPosition="after"
          >
            <mat-label>Template</mat-label>
            <mat-select name="selectedTemplate" [(ngModel)]="template"
             [disabled] = "!accountService.hasFeatureAccess(FeatureName.EVENT_TEMPLATE_MAPPING, PermissionType.CREATE)">
              <mat-option
                *ngFor="let template of availableTemplates"
                [value]="template"
              >
                {{ template?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
     
      <div class="row" *ngIf="template">
        <div class="col-12">
          <mat-card class="template-card overflow-hidden">
            <mat-card-header class="template-card-body">
              <mat-card-title>{{ template.name }}</mat-card-title>
              <mat-card-subtitle>
                <span>{{ template.name.split("__")[1] }}</span
                ><br />
                <span *ngIf="template.isActive" class="badge badge-success"
                  >Active</span
                >
                <span *ngIf="!template.isActive" class="badge badge-danger"
                  >Inactive</span
                >
                <p>
                  From: {{ template.fromName }} &lt;{{ template.fromMail }}&gt;
                </p>
                <p>Subject: {{ template.subject }}</p>
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <div class="thumbnail-container">
                <div class="thumbnail" style="background-color: white">
                  <iframe
                    height="100%"
                    style="opacity: 1"
                    width="100%"
                    srcdoc="{{ template.templateString }}"
                    frameborder="0"
                  ></iframe>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end marT16 marR16">
    <button
      mat-button
      *ngIf="!edit"
      (click)="onNoClick()"
      class="DSA_wb_miniSecondary-btn"
      [hidden]="true"
    >
      Close
    </button>
    <button 
      mat-button
      class="DSA_wb_miniSecondary-btn marR16"
      *ngIf="edit && eventStatus !== 'CLOSED'"
      (click)="cancelEdit()"
    >
      Cancel Edit
    </button>
    <button
      mat-button
      class="DSA_wb_miniPrimary-btn"
      *ngIf="eventStatus !== 'CLOSED'"
      [disabled]="!template"
      cdkFocusInitial
      (click)="enableTemplateForEvent()"
    >
      {{ edit ? "Update" : "Add" }}
    </button>
  </div>

  <div class="row marL16" *ngIf="eventMailTemplates.length > 0 && !edit">
    <div class="col-12">Existing Templates</div>
    <div class="col-6 mt-3" *ngFor="let template of eventMailTemplates">
      <div class="col-12 mat-elevation-z2 p-3">
        <p>{{ template.templateCategoryName }}</p>
        <div
          class="row"
          *ngIf="getTemplateById(template.notificationTemplateId)"
        >
          <div class="col-12">
            <mat-card class="template-card overflow-hidden">
              <mat-card-header class="template-card-body">
                <mat-card-title>{{
                  getTemplateById(template.notificationTemplateId).name
                }}</mat-card-title>
                <mat-card-subtitle>
                  <span>{{
                    getTemplateById(
                      template.notificationTemplateId
                    ).name.split("__")[1]
                  }}</span
                  ><br />
                  <span
                    *ngIf="
                      getTemplateById(template.notificationTemplateId)
                        .isActive
                    "
                    class="badge badge-success"
                    >Active</span
                  >
                  <span
                    *ngIf="
                      !getTemplateById(template.notificationTemplateId)
                        .isActive
                    "
                    class="badge badge-danger"
                    >Inactive</span
                  >
                  <p>
                    From:
                    {{
                      getTemplateById(template.notificationTemplateId)
                        .fromName
                    }}
                    &lt;{{
                      getTemplateById(template.notificationTemplateId)
                        .fromMail
                    }}&gt;
                  </p>
                  <p>
                    Subject:
                    {{
                      getTemplateById(template.notificationTemplateId)
                        .subject
                    }}
                  </p>
                </mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <div class="thumbnail-container">
                  <div class="thumbnail" style="background-color: white">
                    <iframe
                      height="100%"
                      style="opacity: 1"
                      width="100%"
                      srcdoc="{{
                        getTemplateById(template.notificationTemplateId)
                          .templateString
                      }}"
                      frameborder="0"
                    ></iframe>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="w-100 d-flex flex-row-reverse">
          <button
            *ngIf="accountService.hasFeatureAccess(FeatureName.EVENT_TEMPLATE_MAPPING, PermissionType.UPDATE) && eventStatus !== 'CLOSED'"
            (click)="editMode(template.id, template.templateCategoryName)"
            mat-icon-button
            color="primary"
            matTooltip="Edit"
            matTooltipPosition="after"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
