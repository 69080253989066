<app-loader [display]="loader"></app-loader>
<div *ngIf="!loader">
  <h3 mat-dialog-title>Add Scholarship</h3>
  <mat-dialog-content>
    <mat-form-field class="DSA_Wb_custom-form-field">
      <input
        #jobInput
        [formControl]="jobSearchControl"
        [matAutocomplete]="auto"
        placeholder="Enter Scholarship Name"
        matInput
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selected($event)"
        [displayWith]="displayFn"
      >
        <mat-option
          *ngFor="let job of availableJobsObservable | async"
          [value]="job"
        >
          {{ job.title }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div *ngIf="selectedRole">
      <p><b>Title:</b> {{ selectedRole.title }}</p>
      <p
        class="DSA_wb_mainBodyTxt DSA_wb_text-1line"
        [matTooltip]="selectedRole.description"
        matTooltipClass="DSA_tooltip"
      >
        <b>Description:</b>
        {{ selectedRole.description }}
      </p>
      <!-- <p><b>Scholarship Level:</b> {{ selectedRole.jobLevel }}</p> -->
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-end">
    <button
      mat-flat-button
      [mat-dialog-close]="false"
      class="DSA_wb_miniSecondary-btn"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      [mat-dialog-close]="selectedRole"
      class="DSA_wb_miniPrimary-btn"
      [disabled]="!selectedRole"
    >
      Add
    </button>
  </mat-dialog-actions>
</div>
