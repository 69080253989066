import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import { PrivacyPolicyComponent } from './modules/iwp-platform-actions/privacy-policy/privacy-policy.component';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from './services/account.service';
import { SharedService } from './services/shared.service';
import { environment } from 'src/environments/environment';
import { KeycloakService } from 'keycloak-angular';
import { AgeConfirmationComponent } from './modules/iwp-platform-actions/age-confirmation/age-confirmation.component';
import { AuthorityName } from './shared/model/enumerations/authority-name.model';
import { CandidateProfileService } from './modules/candidate/candidate-profile/candidate-profile.service';
// import {MatCardModule} from '@angular/material/card' //

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loadRoutes: boolean = false; // load routes only if user is not logged in, or logged in and declaration is accepted
  isLoggedIn: boolean = false;
  constructor(
    public router: Router,
    public dialog: MatDialog,
    private accountService: AccountService,
    private sharedService: SharedService,
    private _keycloakService: KeycloakService,
    private candidateProfileService:CandidateProfileService
  ) {}
  ngOnInit() {
    this.accountService.identity().subscribe(
      (data) => {
        this.isLoggedIn = true;
        const storedata={
          firstName: data?.firstName,
          email: data?.email,
          lastName: data?.lastName,
        }
        localStorage.setItem('ACCOUNT_DETAILS',  JSON.stringify(storedata));
        this.accountService.setAccount(data).then(() => {
          this.loadRoutes = data['declarationFormAccepted'];
          const currentRole = this.accountService.getCurrentRole();
          if (
            !data['declarationFormAccepted'] &&
            (currentRole == AuthorityName.ROLE_CANDIDATE ||
              currentRole == AuthorityName.ROLE_VERIFIER ||
              currentRole == AuthorityName.ROLE_VERIFIER_ADMIN)
          ) {
            if (
              this.accountService.getCurrentRole() ==
              AuthorityName.ROLE_CANDIDATE
            ) {
              const ageDialogRef = this.dialog.open(AgeConfirmationComponent, {
                disableClose: true,
                autoFocus: false,
                width: '400px',
              });
              // const is18Plus = await ageDialogRef.afterClosed();

              ageDialogRef.afterClosed().subscribe((is18Plus) => {
                if(is18Plus==false){
                  this.loadRoutes=false;
                  this.sharedService.openWarningSnackBar('You should be 18 years or above ','',8000)
                  this.logout();
                  return;
                }
                else{
                const dialogRef = this.dialog.open(PrivacyPolicyComponent, {
                  disableClose: true,
                  autoFocus: false,
                  data: { is18Plus },
                });

                dialogRef.afterClosed().subscribe((result) => {
                  // this.sharedService.acceptPolicy().subscribe(() => {
                  this.loadRoutes = true;
                  this.accountService._onAccountApiCallComplete.next(data);
                  // });
                });
                }
              });
              
            } else {
              const dialogRef = this.dialog.open(PrivacyPolicyComponent, {
                disableClose: true,
                autoFocus: false,
              });

              dialogRef.afterClosed().subscribe((result) => {
                // this.sharedService.acceptPolicy().subscribe(() => {
                this.loadRoutes = true;
                this.accountService._onAccountApiCallComplete.next(data);
                // });
              });
            }
          } else {
            this.loadRoutes = true;
            this.accountService._onAccountApiCallComplete.next(data);
          }
          const tenantId = localStorage.getItem('tenantId');
          if (!tenantId) this.router.navigate(['/']);
        });
      },
      (err) => {
        // if (err.status === 401) {
        //   if (environment.production) {
        //     this.accountService.login();
        //   } else {
        //     this.keycloakService.login();
        //   }
        // }
        // this.accountService._onAccountApiCallComplete.error(err);
        this.loadRoutes = true;
      }
    );
  }
  logout(): void {
    localStorage.removeItem('tenantId');
    sessionStorage.removeItem('seekerId');
    sessionStorage.removeItem(
      this.candidateProfileService.candidateProfileStateKey
    );

    if (environment.production) {
      this.accountService.logout();
    } else {
      this._keycloakService.logout();
      //  this.router.navigate(['/']);
    }
  }
}
