<div class="container-fluid layout" *ngIf="!loading">
  <div class="row">
    <div class="col-md-12">
      <div class="d-flex justify-content-between align-items-center">
        <h4>{{ pageTitle }}</h4>
        <div class="d-flex ml-auto padR16">
          <div
            class="marL8"
            *ngIf="
              accountService.hasFeatureAccess(
                FeatureName.GENERATE_BULK_REPORTS,
                PermissionType.CREATE
              ) && !generateReportLoader
            "
          >
            <a
              role="button"
              matTooltip="Generate Consolidated Report"
              matTooltipClass="DSA_tooltip"
              [disabled]="generateReportLoader"
              (click)="generateReport()"
              class="icon-wrap"
            >
              <i class="icon x-30 grey document-icon"> </i>
            </a>
          </div>
          <div
            class="marL14"
            *ngIf="
              accountService.hasFeatureAccess(
                FeatureName.GENERATE_REPORT,
                PermissionType.CREATE
              ) && generateReportLoader
            "
          >
            <a
              class="d-flex justify-content-center align-items-center padT4 icon-wrap"
              role="button"
              matTooltip="Generating Consolidated Report..."
              matTooltipClass="DSA_tooltip"
              [disabled]="generateReportLoader"
            >
              <mat-spinner diameter="24"></mat-spinner>
            </a>
          </div>

          <div
            class="marL8"
            *ngIf="
              accountService.hasFeatureAccess(
                FeatureName.GENERATE_REPORT,
                PermissionType.CREATE
              ) &&
              downloadReport &&
              !generateReportLoader
            "
          >
            <a
              role="button"
              matTooltip="Download Report"
              matTooltipClass="DSA_tooltip"
              (click)="saveReport()"
              class="icon-wrap"
            >
              <i class="icon x-30 grey download-icon"> </i>
            </a>
          </div>
        </div>

        <div>
          <button
            *ngIf="
              accountService.hasFeatureAccess(
                FeatureName.EVENT,
                PermissionType.CREATE
              )
            "
            class="custom-button"
            [routerLink]="['/create-event', 0]"
          >
            Create Event
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- <a routerLink="/formbuilder">Form Builder</a> -->

  <data-grid
    [columnDefs]="columnDefs"
    [tableName]="tableName"
    [buttonText]="buttonText"
    [buttonComponent]="buttonComponent"
    [levels]="levels"
    [buttonCondition]="buttonCondition"
    [searchCondition]="searchCondition"
    [interface]="interface"
    [refreshAgGrid]="refreshAgGrid.asObservable()"
    [pageNumber]="page"
    [pageSize]="size"
    (pageChangeValue)="onPageChange($event)"
    [searchValue]="queryEventName"
  ></data-grid>
</div>
