<app-loader [display]="loader"></app-loader>
<div *ngIf="!loader">
  <div *ngIf="!isError; else showError">
    <h2 mat-dialog-title>Add Panelist</h2>
    <mat-dialog-content>
      <div class="d-flex align-items-center marT8">
        <mat-form-field class="marT8 DSA_Wb_custom-form-field DSA_form_nofloatLabel padR8">
          <input
            #panelistInput
            [formControl]="searchControl"
            [matAutocomplete]="auto"
            placeholder="Enter email"
            matInput
          />
          <mat-label>Email</mat-label>  
          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="selected($event)"
          >
            <mat-option
              *ngFor="let panelist of availablePanelistObs | async"
              [value]="panelist.emailId"
            >
              {{ panelist.emailId }}
            </mat-option>
          </mat-autocomplete>
          <mat-error>Please enter valid value</mat-error>
        </mat-form-field>
        <mat-spinner
          class="panelist-search-loader"
          [diameter]="25"
          *ngIf="isFetchingPanelists"
        >
        </mat-spinner>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-end">
      <button
        mat-flat-button
        [mat-dialog-close]="false"
        class="DSA_wb_miniSecondary-btn"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        [mat-dialog-close]="selectedPanelist"
        class="DSA_wb_miniPrimary-btn"
        [disabled]="!selectedPanelist"
      >
        Add
      </button>
    </mat-dialog-actions>
  </div>
  <ng-template #showError>
    <h2 mat-dialog-title>Add Panelist</h2>
    <mat-dialog-content>
      <p>Some error occurred. Please try later.</p>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-end">
      <button
        mat-flat-button
        [mat-dialog-close]="false"
        class="DSA_wb_miniSecondary-btn"
      >
        Close
      </button>
    </mat-dialog-actions>
  </ng-template>
</div>
