import { Injectable } from '@angular/core';
import { JobRole } from 'src/app/shared/model/job-role.model';

@Injectable({
  providedIn: 'root',
})
export class JobsAdminDashboardStateStorageService {
  page = 0;
  size = 25;
  searchQuery: string = '';

  selectedJob: JobRole;

  init = () => {
    this.page = 0;
    this.size = 25;
    this.searchQuery = '';
    this.selectedJob = null;
  };

  constructor() {}
}
