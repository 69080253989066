import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IwpSetting } from 'src/app/shared/model/iwp-setting.model';

@Component({
  selector: 'app-dynamic-form-field',
  templateUrl: './dynamic-form-field.component.html',
  styleUrls: ['./dynamic-form-field.component.scss'],
})
export class DynamicFormFieldComponent implements OnInit {
  @Input() field!: IwpSetting;
  @Input() form!: FormGroup;
  constructor() {}

  ngOnInit(): void {}
}
