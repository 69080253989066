const placeholders = [
  {
    title: 'First Name',
    value: 'firstName'
  },
  {
    title: 'Survey Name',
    value: 'surveyName'
  },
  {
    title: 'New Joinee Name',
    value: 'newJoineeName'
  },
  {
    title: 'Points',
    value: 'points'
  },
  {
    title: 'Voucher',
    value: 'voucher'
  },
  {
    title: 'Claim Code',
    value: 'claimCode'
  },
  {
    title: 'Currency',
    value: 'currency'
  },
  {
    title: 'Amount',
    value: 'amount'
  },
  {
    title: 'Transaction Type',
    value: 'transactionType'
  },
  {
    title: 'Admim Draft Content',
    value: 'admimDraftContent'
  }
];

export { placeholders };
