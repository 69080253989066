import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { JobsAdminDashboardStateStorageService } from 'src/app/modules/iwp-platform-actions/infy-jobs-admin/jobs-admin-dashboard-state-storage.service';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { FormMsService } from 'src/app/services/form-ms.service';
import { SharedService } from 'src/app/services/shared.service';
import {
  JobEvaluationFormDTO,
  TemplateEvaluationFormDTO,
} from 'src/app/shared/model/form-ms.model';

@Component({
  selector: 'app-infy-associate-evaluation-form',
  templateUrl: './infy-associate-evaluation-form.component.html',
  styleUrls: ['./infy-associate-evaluation-form.component.scss'],
})
export class InfyAssociateEvaluationFormComponent implements OnInit {
  @Input() jobRoleId: number;
  @Input() formType: string;
  @Input() activeTemplate: JobEvaluationFormDTO;
  @Input() inactiveTemplates: JobEvaluationFormDTO[];

  @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  onAssociateTemplate: EventEmitter<JobEvaluationFormDTO> = new EventEmitter<JobEvaluationFormDTO>();

  associateEvaluationForm: FormGroup;
  selectedTemplate: TemplateEvaluationFormDTO;
  selectedTemplateIsInactive: boolean = false;
  selectedTemplateIsActive: boolean = false;
  isLoading: boolean = false;
  constructor(
    private formsService: FormMsService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private jobsAdminStateStorageService: JobsAdminDashboardStateStorageService
  ) {}

  ngOnInit(): void {
    this.associateEvaluationForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      description: [''],
      evaluationEventId: ['', [Validators.required]],
      formType: ['', [Validators.required]],
    });

    // default title for Evaluation Form Input
    const title = this.jobsAdminStateStorageService.selectedJob
      ? `${
          this.jobsAdminStateStorageService.selectedJob.title
        } - ${this.sharedService.toTitleCase(this.formType)} Evaluation Form`
      : `${this.formType} Evaluation Form`;

    this.associateEvaluationForm.patchValue({
      evaluationEventId: this.jobRoleId,
      formType: this.formType,
      title: title,
    });
  }

  handleTemplateSelect = (template: TemplateEvaluationFormDTO) => {
    this.selectedTemplate = template;
    this.selectedTemplateIsInactive = false;
    this.inactiveTemplates.forEach((template) => {
      if (template?.templateEvaluationForm?.id === this.selectedTemplate.id) {
        this.selectedTemplateIsInactive = true;
      }
    });
    this.selectedTemplateIsActive =
      this.activeTemplate?.templateEvaluationForm?.id ===
      this.selectedTemplate?.id;
  };

  handleAssociateFormClick = () => {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        text: `Are you sure you want to associate <b>${this.selectedTemplate.name}</b> template with this job? `,
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Yes') {
        var evaluationForm: JobEvaluationFormDTO = {
          ...this.associateEvaluationForm.getRawValue(),
          templateEvaluationForm: {
            id: this.selectedTemplate.id,
          },
        };
        console.log(this.selectedTemplate.formData)
        if (this.selectedTemplateIsInactive) {
          evaluationForm = {
            id: this.inactiveTemplates.find(
              (evForm) =>
                evForm.templateEvaluationForm?.id === this.selectedTemplate.id
            ).id,
            isActive: true,
            title: this.associateEvaluationForm.get('title').value,
            description: this.associateEvaluationForm.get('description').value,
          };
        }

        this.isLoading = true;
        (this.selectedTemplateIsInactive
          ? this.formsService.updateEvaluationForm(evaluationForm)
          : this.formsService.associateEvaluationFormToJob(evaluationForm)
        ).subscribe({
          next: (res) => {
            this.sharedService.openSnackBar(
              'Template associated successfully',
              '',
              3000
            );
            this.onAssociateTemplate.emit(res);
            this.isLoading = false;
          },
          error: (err) => {
            this.sharedService.openSnackBar('Some error occurred', '', 3000);
            this.isLoading = false;
          },
        });
      }
    });
  };

  handleCancelClick = () => {
    this.onCancel.emit();
  };
}
