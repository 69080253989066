import { PermissionType } from "./enumerations/permission-type.model"; 

export interface IFeature {
    feature? : string,
    permissions? : string,
    hasCreateAccess() : boolean, 
    hasReadAccess() : boolean, 
    hasUpdateAccess() : boolean, 
    hasDeleteAccess() : boolean,
    hasPermissionAccess(permission : PermissionType) : boolean 
}

export class Feature implements IFeature {
  constructor(
    public feature? : string,
    public permissions? : string
  ) {}

  public hasCreateAccess = () =>{
    return this.permissions.includes('C');
  }
  public hasReadAccess = () =>{
    return this.permissions.includes('R');
  }
  public hasUpdateAccess = () =>{
    return this.permissions.includes('U');
  }
  public hasDeleteAccess = () =>{
    return this.permissions.includes('D');
  }
  public hasPermissionAccess = (permission : PermissionType) => {
    return this.permissions.includes(permission);
  }
}
