export enum SubStatusEnum {
  TECHNICAL_PENDING = 'TECHNICAL_PENDING',
  TECHNICAL_COMPLETED = 'TECHNICAL_COMPLETED',
  HR_PENDING = 'HR_PENDING',
  HR_COMPLETED = 'HR_COMPLETED',
}

export enum SubStatusUI {
  TECHNICAL_PENDING = 'Technical Pending',
  TECHNICAL_COMPLETED = 'Technical Completed',
  HR_PENDING = 'HR Pending',
  HR_COMPLETED = 'HR Completed',
}
