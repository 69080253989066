<div class="container-fluid marT32">
  <div class="row pd clr">
    <div class="row col-md-6">
      <!-- <mat-icon style="margin-right: 20px;">arrow_back</mat-icon> -->
      <h2 class="font-weight-bold">WA foundation Hackathon</h2>
    </div>
    <div class="col-md-6 end d-flex">
      <button mat-flat-button class="DSA_wb_Icon-btn" (click)="addNewUser()">
        <span class="icon_btnCont"
          ><i class="icon purple x-16 icon-add"></i
        ></span>
        Add New User
      </button>

      <input
        type="file"
        (change)="bulkUpload(bulkUploadElement.files)"
        #bulkUploadElement
        accept=".csv"
        style="display: none"
      />

      <button
        (click)="bulkUploadElement.click()"
        mat-flat-button
        class="DSA_wb_Icon-btn"
      >
        <span class="icon_btnCont">
          <i class="icon purple x-16 upload-icon"></i>
        </span>
        Add File
      </button>

      <button
        *ngIf="bulkuploadFile"
        mat-button
        class="btn-dark"
        (click)="uploadBulkCSV()"
      >
        Upload
      </button>

      <p *ngIf="bulkuploadFile" class="mar mat-body">
        <strong>Selected File:</strong> {{ bulkuploadFile.name }}
      </p>
    </div>
  </div>

  <div class="ag-grid row nopadL w-100">
    <div class="col-12 nopadL">
      <data-grid
        [columnDefs]="columnDefs"
        [levels]="levels"
        [searchCondition]="searchCondition"
        [interface]="interface"
        [refreshAgGrid]="refreshAgGrid.asObservable()"
        [pageNumber]="page"
        [pageSize]="pageSize"
        (pageChangeValue)="onPageChange($event)"
        [searchValue]="searchedText"
      ></data-grid>
    </div>
  </div>
</div>
