import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { EventService } from 'src/app/services/event.service';
import { FileService } from 'src/app/services/file.service';
import { JobService } from 'src/app/services/job.service';
import { SharedService } from 'src/app/services/shared.service';
import { JobRole } from 'src/app/shared/model/job-role.model';

@Component({
  selector: 'app-add-jobs',
  templateUrl: './add-jobs.component.html',
  styleUrls: ['./add-jobs.component.scss'],
})
export class AddJobsComponent implements OnInit {
  jobRoles: any;
  selectedRole: any;
  tableData: Array<any> = [];
  eventId: number;
  loader: boolean = false;

  availableJobsObservable: Observable<JobRole[]>;
  jobSearchControl: FormControl = new FormControl();
  constructor(
    private eventService: EventService,
    public fileService: FileService,
    private jobService: JobService,
    private sharedService: SharedService
  ) {}

  jobSearchControlValChangeSubscribe = () => {
    this.availableJobsObservable = this.jobSearchControl.valueChanges.pipe(
      // tslint:disable-next-line: deprecation
      startWith(null),
      map((job: string | null) => {
        return job
          ? this._filter(job.trim(), this.jobRoles, 'title').slice(0, 10)
          : this.jobRoles;
      })
    );
  };

  ngOnInit(): void {
    this.eventId = this.eventService.selectedEventId;
    this.getAllJobsByEvent();
  }

  getJobRoles = () => {
    this.loader = true;
    this.jobService.getJobRoles(5000, 0).subscribe(
      (res: any) => {
        let job = res.content;
        this.jobRoles = job.filter(
          (jobs) =>
            !this.tableData.some((jobPresent) => jobs.title == jobPresent.title)
        );
        this.jobSearchControlValChangeSubscribe();
        this.loader = false;
      },
      () => {
        this.sharedService.openSnackBar('Some Error Occured', '', 3000);
        this.loader = false;
      }
    );
  };

  getAllJobsByEvent() {
    this.loader = true;
    this.jobService.getAllJobsByEvent(this.eventId, 0, 100).subscribe(
      (res) => {
        res.content?.forEach((job) => {
          this.tableData.push(job);
        });
        this.getJobRoles();
        this.loader = false;
      },
      () => {},
      () => {
        this.loader = false;
      }
    );
  }

  private _filter(value: string, listObj, key?): any[] {
    const filterValue = value?.toString()?.toLowerCase();
    return listObj.filter((a) => {
      if (key)
        return a[key].toString().toLowerCase().indexOf(filterValue) !== -1;
      else
        return (
          Object.values(a).toString().toLowerCase().indexOf(filterValue) !== -1
        );
    }) as any[];
  }

  selected(event: MatAutocompleteSelectedEvent) {
    const selectedRole = this.jobRoles.find(
      (k) => k.id === event.option.value.id
    );
    if (selectedRole) this.selectedRole = selectedRole;
  }

  displayFn(job: JobRole): string {
    return job && job.title ? job.title : '';
  }
}
