import { Component, EventEmitter, OnInit, Output } from '@angular/core';

export enum InterviewDateSort {
  ASC = 'startDate,asc',
  DESC = 'startDate,desc',
}

export enum ArrowIcon {
  UP = 'icon x-24 up-arw-icon',
  DOWN = 'icon x-24 down-arw-icon',
}

@Component({
  selector: 'app-sort-rows',
  templateUrl: './sort-rows.component.html',
  styleUrls: ['./sort-rows.component.scss'],
})
export class SortRowsComponent implements OnInit {
  sortDateDescending: boolean = false;
  sortDateOrder = InterviewDateSort.ASC;
  sortIconClass = ArrowIcon.UP;
  @Output() onSortClick = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  sortByDate = () => {
    if (this.sortDateDescending) {
      this.sortDateOrder = InterviewDateSort.ASC;
      this.sortIconClass = ArrowIcon.UP;
    } else {
      this.sortDateOrder = InterviewDateSort.DESC;
      this.sortIconClass = ArrowIcon.DOWN;
    }
    this.sortDateDescending = !this.sortDateDescending;
    this.onSortClick.emit(this.sortDateOrder);
  };
}
