import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService {
  selectedInterviewSchedule: any;

  private headers = new HttpHeaders()
    .set('content-type', 'application/json');

  constructor(private http: HttpClient) {}

  // ! refactoring spelling of shedule to schedule (ref: service.service.ts)
  // ? repeated in recruiter.service
  scheduleEvent = (data) => {
    return this.http.post(
      environment.interviewServiceUrl + 'api/interview/schedule',
      data,
      { headers: this.headers }
    );
  };

  // ! refactoring spelling of reshedule to reschedule (ref: service.service.ts)
  // ? repeated in recruiter.service
  reScheduleEvent = (data) => {
    return this.http.post(
      environment.interviewServiceUrl + 'api/interview/reschedule',
      data,
      { headers: this.headers }
    );
  };

  nullSelectedInterviewSchedule = () => {
    this.selectedInterviewSchedule = null;
  };

  setSelectedInterviewSchedule = (data) => {
    this.selectedInterviewSchedule = data;
  };

  getSelectedInterviewSchedule = () => {
    return this.selectedInterviewSchedule;
  };
}
