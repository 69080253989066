import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-eligibility-criteria',
  templateUrl: './eligibility-criteria.component.html',
  styleUrls: ['./eligibility-criteria.component.scss']
})
export class EligibilityCriteriaComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EligibilityCriteriaComponent>,
    ) { }

  ngOnInit(): void {
  }

}
