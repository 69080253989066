<!-- <app-loader [display]="loader"></app-loader> -->

<!-- <div class="container-fluid layout" *ngIf="!loader">
  <div class="col-md-12">
    <div>
     
      <div class="row">
        <div class="col-md-12 d-flex justify-content-end">
          <div class="h1 DSA_wb_h1Header w-100" *ngIf="tableData.length === 0">
            No records found
          </div>
          <div
            class="table-titleArea clearfix float-right"
            ngbDropdown
            container="body"
          >
            <a
              href="javascript:void(0);"
              id="filter_dd"
              class="icon-cont DSA_dropdown-iconOnly"
              [matMenuTriggerFor]="filterMenu"
              ><i class="icon x-24 filter-icon"> </i
            ></a>
            <mat-menu
              #filterMenu="matMenu"
              aria-labelledby="filter_dd"
              class="p-2"
            >
              <h2 class="DSA_wb_h2Header">
                Filter by<a
                  href="javascript:void(0)"
                  class="icon x-16 close-icon float-right"
                ></a>
              </h2>
              <mat-accordion
                class="DSA_wb-cus-accordion"
                (click)="$event.stopPropagation()"
              >
                <mat-expansion-panel>
                  <mat-expansion-panel-header
                    [collapsedHeight]="'40px'"
                    [expandedHeight]="'40px'"
                  >
                    <mat-panel-title>Freeze Status</mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="mar16">
                    <mat-radio-group
                      [(ngModel)]="frozen"
                      class="DSA_Wb_custom-radio"
                    >
                      <mat-radio-button [value]="'true'" disableRipple="true"
                        >FROZEN</mat-radio-button
                      >
                      <mat-radio-button [value]="'false'" disableRipple="true"
                        >NOT FROZEN</mat-radio-button
                      >
                    </mat-radio-group>
                  </div>
                </mat-expansion-panel>
                <mat-expansion-panel>
                  <mat-expansion-panel-header
                    [collapsedHeight]="'40px'"
                    [expandedHeight]="'40px'"
                  >
                    <mat-panel-title>Interview Result</mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="mar16">
                    <mat-radio-group
                      [(ngModel)]="interviewResult"
                      class="DSA_Wb_custom-radio"
                    >
                      <mat-radio-button
                        [value]="'SELECTED'"
                        disableRipple="true"
                        >SELECTED</mat-radio-button
                      >
                      <mat-radio-button
                        [value]="'REJECTED'"
                        disableRipple="true"
                        >REJECTED</mat-radio-button
                      >
                    </mat-radio-group>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <div class="DSA_wb-bottomUtil">
                <div class="row nomarLR">
                  <div class="col-12">
                    <button
                      mat-flat-button
                      (click)="refreshTable()"
                      class="DSA_wb_miniPrimary-btn DSA_btnfullWdth"
                    >
                      Apply
                    </button>
                  </div>
                </div>
                <div class="row nomarLR">
                  <div class="col-12">
                    <button
                      mat-flat-button
                      (click)="clearFilters()"
                      class="DSA_wb_miniSecondary-btn DSA_btnfullWdth"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </mat-menu>
          </div>
        </div>
      </div>
      <div class="row">
      
        <div class="col-12 offset-md-8 col-md-4 mb-3">
          <mat-form-field class="DSA_Wb_custom-form-field">
            <input matInput placeholder="Search" (keyup)="search($event)" />
            <span matSuffix (click)="refreshTable()"
              ><i class="icon x-24 search-icon"> </i
            ></span>
          </mat-form-field>
        </div>
      </div>
    </div>
    <table class="DSA_wb_custom-table" *ngIf="tableData.length !== 0">
      <thead>
        <tr>
          <th *ngFor="let column of tableColumnsDisplayName">{{ column }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let candidate of tableData">
          <td>
            <a
              class="DSA_wb_hyperLink-btn"
              role="button"
              (click)="setCandidate(candidate.data)"
              >{{
                candidate.candidateSourceId
                  ? candidate.candidateSourceId
                  : candidate.candidateId
              }}</a
            >
          </td>
          <td>{{ candidate.name }}</td>
          <td>{{ candidate.email }}</td>
          <td>{{ candidate.phone }}</td>
          <td>{{ selectedJob.title }}</td>
        </tr>
      </tbody>
    </table>

    <app-pagination
      [totalElements]="totalElements"
      [page]="page"
      [pageSize]="size"
      (pageChange)="pageChange($event)"
    ></app-pagination>
  </div>
</div> -->

<div class="container-fluid layout" *ngIf="!loader">
  <div class="row">
    <div class="col-md-12">
      <button
        *ngIf="
          (accountService.hasFeatureAccess(FeatureName.INTERVIEW_SCHEDULE, PermissionType.CREATE)) &&
          eventStatus !== 'CLOSED'
        "
        mat-flat-button
        class="DSA_wb_Icon-btn float-right"
        (click)="addCandidate()"
      >
        <span class="icon_btnCont">
          <i class="icon purple x-16 icon-add"> </i
        ></span>
        Add Candidate
      </button>
    </div>
  </div>
</div>

<data-grid
  (searchKey)="search($event)"
  [columnDefs]="columnDefs"
  [tableName]="tableName"
  [buttonText]="buttonText"
  [buttonComponent]="buttonComponent"
  [levels]="levels"
  [searchCondition]="searchCondition"
  [interface]="interface"
  [refreshAgGrid]="refreshAgGrid.asObservable()"
></data-grid>
