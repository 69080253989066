import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MsteamsService {
  constructor(private http: HttpClient) {}

  private headers = new HttpHeaders()
    .set('content-type', 'application/json');

  checkAuthorizationRequired = (): Observable<any> => {
    return this.http.get<any[]>(
      environment.profileServiceUrl +
        'api/ms-access-tokens/requiresauthorization',
      { headers: this.headers }
    );
    // return {
    //   requiresAuthorization: false,
    //   upn: '',
    // };
  };

  saveAuthCodeForTeams = (authCode) => {
    return this.http.post(
      environment.profileServiceUrl + 'api/ms-access-tokens',
      { authCode: authCode },
      { headers: this.headers }
    );
  };
}
