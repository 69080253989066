import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AgRendererComponent } from 'ag-grid-angular';
import { InfyAssociateJobsComponent } from 'src/app/shared/components/infy-associate-jobs/infy-associate-jobs.component';
import { InfyCreateJobComponent } from 'src/app/modules/iwp-platform-actions/infy-create-job/infy-create-job.component';
import { JobsAdminDashboardStateStorageService } from 'src/app/modules/iwp-platform-actions/infy-jobs-admin/jobs-admin-dashboard-state-storage.service';
import { JobService } from 'src/app/services/job.service';
import { SharedService } from 'src/app/services/shared.service';
import { AccountService } from 'src/app/services/account.service';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
@Component({
  selector: 'app-jobs-admin-action-renderer',
  templateUrl: './jobs-admin-action-renderer.component.html',
})
export class JobsAdminActionRendererComponent implements AgRendererComponent {
  params: any;
  job;
  FeatureName = FeatureName;
  PermissionType = PermissionType;
  constructor(
    private dialog: MatDialog,
    private jobService: JobService,
    private sharedService: SharedService,
    private jobsAdminStateStorageService: JobsAdminDashboardStateStorageService,
    private router: Router,
    public accountService: AccountService
  ) {}

  agInit(params: any): void {
    this.params = params;
    this.job = params.data;
  }

  refresh(params: any): boolean {
    return false;
  }

  editJob() {
    const dialogRef = this.dialog.open(InfyCreateJobComponent, {
      disableClose: true,
      backdropClass: 'backdrop-background',
      data: { jobs: this.params.data },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value) {
        this.params.context.componentParent.refreshTable();
      }
    });
  }

  associateJobs() {
    const dialogRef = this.dialog.open(InfyAssociateJobsComponent, {
      disableClose: false,
      backdropClass: 'backdrop-background',
      data: { jobs: this.job, associationType: 'CENTRAL' },
    });
  }

  handleDesignEvalClick = () => {
    this.jobsAdminStateStorageService.selectedJob = this.job;
    this.router.navigate(['/job-evaluation-form/' + this.job.id]);
  };
}
