import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FormioForm } from 'angular-formio';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss'],
})
export class FormBuilderComponent implements OnInit {
  displayFormTypes = [
    { value: 'wizard', displayValue: 'Multi-paged' },
    { value: 'form', displayValue: 'Single Page' },
  ];
  @Input() formJSON: FormioForm;
  @Output() formChange = new EventEmitter<FormioForm>();

  formDisplayType: FormControl = new FormControl();

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  handleDisplayTypeChange = (e) => {
    const selectedVal = e.value;

    // if fields are added to form, prompt user for confirmation that data will be deleted
    if (
      (this.formJSON.display === 'wizard' &&
        this.formJSON.components[0].components?.length > 0) ||
      (this.formJSON.display === 'form' &&
        this.formJSON.components.length > 0 &&
        this.formJSON.components[0].key !== 'submit')
    ) {
      const dialog = this.dialog.open(ConfirmationComponent, {
        data: {
          text:
            "Currently added fields will be <strong class='font-red'>deleted</strong> on changing display type.<br/> Are you sure you want to proceed?",
        },
        backdropClass: 'backdrop-background',
        autoFocus: false,
      });
      dialog.afterClosed().subscribe((res) => {
        if (res === 'Yes') {
          this.formJSON = { display: selectedVal, components: [] };
          this.formChange.emit(this.formJSON);
        } else {
          // if user selects 'No', set previous value again
          const prevVal = selectedVal === 'form' ? 'wizard' : 'form';
          this.formDisplayType.setValue(prevVal, { emitEvent: false });
        }
      });
    } else {
      // if no fields are added to form, change form json
      this.formJSON = { display: selectedVal, components: [] };
      this.formChange.emit(this.formJSON);
    }
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formJSON?.currentValue) {
      this.formJSON = changes.formJSON.currentValue;
      this.formDisplayType.setValue(this.formJSON.display);
    } else {
      this.formJSON = {
        display: this.displayFormTypes[0].value,
        components: [],
      };
      this.formDisplayType.setValue(this.displayFormTypes[0].value);
    }
  }

  createdFormOnChange = (event) => {
    if (event.form) {
      this.formChange.emit(event.form);
    }
  };
}
