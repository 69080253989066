<!-- <app-loader [display]="loader"></app-loader> -->
<app-loader [display]="loader"></app-loader>
<div *ngIf="!loader">
  <div *ngIf="availableRecruiters.length > 0; else showError">
    <h2 mat-dialog-title>Add Recruiter</h2>
    <mat-dialog-content>
      <mat-form-field class="marT8 DSA_Wb_custom-form-field DSA_form_nofloatLabel marT8">
        <input
          #recruiterInput
          [formControl]="searchControl"
          [matAutocomplete]="auto"
          placeholder="Enter email"
          matInput
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="selected($event)"
        >
          <mat-option
            *ngFor="let recruiter of availableRecruiterObs | async"
            [value]="recruiter.email"
          >
            {{ recruiter.email }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-end">
      <button
        mat-flat-button
        [mat-dialog-close]="false"
        class="DSA_wb_miniSecondary-btn"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        [mat-dialog-close]="selectedRecruiter"
        class="DSA_wb_miniPrimary-btn"
        [disabled]="!selectedRecruiter"
      >
        Add
      </button>
    </mat-dialog-actions>
  </div>
  <ng-template #showError>
    <h2 mat-dialog-title>Add Recruiter</h2>
    <mat-dialog-content>
      <p>No Recruiters available.</p>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-end">
      <button
        mat-flat-button
        [mat-dialog-close]="false"
        class="DSA_wb_miniPrimary-btn"
      >
        Close
      </button>
    </mat-dialog-actions>
  </ng-template>
</div>
