import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedService } from 'src/app/services/shared.service';
import { ResumeUploadService } from '../../services/resume-upload.service';
import { CandidateInfoService } from '../../services/candidate-info.service';
import { AccountService } from 'src/app/services/account.service';
import { CandidateProfileService } from '../candidate-profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, take } from 'rxjs/operators';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { DATE_FORMAT } from 'src/app/app.constants';
import { Observable, Subscription, forkJoin, observable, of } from 'rxjs';
import * as _ from 'lodash';
import { MatCheckbox } from '@angular/material/checkbox';
import { IAccountType } from 'src/app/shared/model/account.model';
import { componentPath } from '../candidate-profile.component';
import { FileService } from 'src/app/services/file.service';
import * as fileSaver from 'file-saver';
import { MailTemplateService } from 'src/app/services/mail-template.service';
import { IEducationHistory } from 'src/app/shared/model/education-history.model';
import { FormioComponent, FormioOptions, FormioService } from 'angular-formio';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { formioMappingsMap } from 'src/app/app.constants';

@Component({
  selector: 'app-basic-details',
  templateUrl: './basic-details.component.html',
  styleUrls: ['./basic-details.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})
export class BasicDetailsComponent implements OnInit, OnDestroy {
  // @Input()
  candidateInfo = null;
  // @Input()
  candidateInfoId: number = null;

  personalDocumentList: string[];
  unsavedFormData = null;
  unsavedFormData1 = null;

  // @Output() nextStep = new EventEmitter<any>();

  @ViewChild('profileImage') profileImage!: ElementRef<HTMLImageElement>;
  @ViewChild('addressCheckbox') addressCheckbox: MatCheckbox;
  @ViewChild(FormioComponent) formioComponent: FormioComponent;
  userAccDetails: IAccountType;
  localStorageKey: string;
  locallyStoredData = null;
  applicationFormData: any;
  fileUploadedHashMap = {};
  profilePicUrl;
  basicDetailsForm!: any;
  basicDetailsApplicationForm: any = {};

  refreshEmitter: EventEmitter<any> = new EventEmitter<any>();
  profilePicExist = false;
  isLoading: boolean = false;
  candidateInfoForm: FormGroup;
  personalDocumentForm: FormGroup;
  currentAddressChanges$: Subscription = null;
  jobId: number = null;
  setAddressCheckbox: boolean = false;
  invalidPincodeCurrent: boolean = null;
  invalidPincodePermanent: boolean = null;
  spinnerCurrent: boolean = false;
  spinnerPermanent: boolean = false;
  disableEditing: boolean = false;
  autoSaveLocally: boolean = false;
  changeIndex: number = 0;
  content;
  fileid: any;
  passingYearArray = [];
  allDegree = ['B.Tech', 'BE', 'BCA', 'MCA', 'M.Tech'];
  valueType: string = 'DEGREE';
  instituteDetails: any;
  scholarshipDetailsForm: FormGroup;
  evaluationFormId;
  options: FormioOptions;

  refresh: boolean;
  isRefreshed: boolean = false;
  formRenderingError: string;
  isFormLoading: boolean = true;
  submission: { data: any };
  submitting: boolean = false;
  basicForm: any;
  feedbackstatus: string = 'PARTIAL';
  sentBackFiles = [];
  applicationFiles = [];
  filteredApplicationFiles = [];

  observables: Observable<any>[] = [
    this.candidateInfoService
      .getBasicDetailsForm(this.candidateProfileService.opportunityId)
      .pipe(catchError((error) => of(error))),
  ];
  downloadDisplay: boolean = false;
  templateFileId: any;
  docBlob: any;
  readonlyPages: boolean[] = [false, false];
  applicationStatus: any;
  show: boolean;
  spinner: boolean;
  wizard: any;
  check: boolean = false;
  next: boolean = true;
  displaySubmit: boolean = false;
  steps: boolean = true;
  idProof: any;
  presentation: any;
  formioMappings = formioMappingsMap;
  verification: any;
  submissionId: any;
  resetDisabled: boolean = false;
  constructor(
    public sharedService: SharedService,
    private candidateInfoService: CandidateInfoService,
    private accountService: AccountService,
    private candidateProfileService: CandidateProfileService,
    private router: Router,
    private route: ActivatedRoute,
    private fileservice: FileService,
    private dialog: MatDialog,
    private http: HttpClient
  ) {
    if (
      !this.candidateProfileService.applicationId ||
      !this.candidateProfileService.opportunityId
    ) {
      this.router.navigate(['/candidate/problem-statements']);
    }
    if (this.candidateProfileService.applicationId) {
      this.observables.push(
        this.candidateInfoService
          .getTeamDetailsForm(
            this.candidateProfileService.opportunityId,
            this.candidateProfileService.applicationId
          )
          .pipe(catchError((error) => of(error)))
      );
      this.observables.push(
        this.candidateProfileService.applicationId
          ? this.candidateInfoService
              .getApplicationStatus(this.candidateProfileService.applicationId)
              .pipe(catchError((error) => of(error)))
          : of([])
      );
    }
    this.options = {
      fileService: {
        uploadFile: async (
          storage: any,
          file: any,
          fileName: string,
          dir: string,
          evt: any,
          url: string
        ) => {
          let formData = new FormData();
          let FileData = {
            name: file?.name,
            size: file?.size,
            type: file?.type,
            fileId: null,
          };
          let applicationId;
          if (localStorage.getItem('applicationId') !== null) {
            applicationId = parseInt(localStorage.getItem('applicationId'));
          } else {
            applicationId = this.candidateProfileService.applicationId;
          }
          let specData = {
            applicationId: applicationId,
            comment: '',
            tenantId: parseInt(localStorage.getItem('tenantId')),
            fileCategory: url,
          };
          if (this.validateUploadFile(url) != -1) {
            specData['applicationFileIdToReplace'] =
              this.validateUploadFile(url);
          }

          formData.append('specData', JSON.stringify(specData));
          formData.append('file', file);

          let dataUrl =
            environment.POSTMS_API +
            '/application-files/application/' +
            applicationId;
          try {
            let result = await this.http.post(dataUrl, formData).toPromise();
            FileData.fileId = result['id'];
            this.sharedService.openSuccessSnackBar('Uploaded Successfully', '');
            return FileData;
          } catch (error) {
            this.sharedService.openErrorSnackBar('Upload Failed', '');
          }
        },
        deleteFile: async (fileInfo: any) =>
          this.fileservice.deleteFile(fileInfo),
        downloadFile: async (fileInfo: any) =>
          this.fileservice.downloadFile(fileInfo),
      },
    };
  }
  // TODO: refactor is needed to manage state in UI
  ngOnInit(): void {
    // Components.components.select.editForm();
    console.log(this.candidateProfileService.applicationId);
    localStorage.setItem(
      'applicationId',
      JSON.stringify(this.candidateProfileService.applicationId)
    );
    forkJoin(this.observables).subscribe((res) => {
      res?.forEach((f, index) => {
        if (f instanceof HttpErrorResponse) {
          if (index == 0) {
            this.isFormLoading = false;
            this.formRenderingError =
              'Something went wrong while rendering the form. Please try again later.';
            this.sharedService.openErrorSnackBar(this.formRenderingError, '');
          } else if (index == 1) {
            this.isFormLoading = false;
            this.formRenderingError =
              'Something went wrong while rendering the form. Please try again later.';
            this.sharedService.openErrorSnackBar(this.formRenderingError, '');
          }else{
            this.isFormLoading = false;
            this.formRenderingError =
              'Something went wrong while rendering the form. Please try again later.';
            this.sharedService.openErrorSnackBar(this.formRenderingError, '');
          }
        } else {
          if (index == 0) this.setFormData(f);
          else if (index == 1) this.setSubmission(f);
          else this.setApplicationStatus(f);
        }
      });
    });
    this.userAccDetails = this.accountService.getAccountLocal();
    let year = new Date().getFullYear();
    for (let i = year; i <= year + 5; i++) {
      this.passingYearArray.push(i.toString());
    }
    this.getCandidateDetails();
    // this.changeComponentStateInitially();
    // console.log(this.candidateProfileService.applicationId)
  }
  ngOnDestroy(): void {
    localStorage.removeItem('applicationId');
  }
  handleNextClick = () => {
    this.next = false;
    this.submitting = this.wizard
      ? this.wizard?.checkValidity(
          this.basicDetailsApplicationForm?.feedbackData?.data
        )
      : false;
    if (this.applicationStatus !== 'SUBMITTED') {
      this.validate();
    }
  };
  onFormReady = (e: FormioComponent) => {
    this.wizard = e.formio;
    if (
      this.applicationStatus === 'SENT_BACK_TO_CANDIDATE' &&
      this.filteredApplicationFiles.length > 0
    ) {
      this.filteredApplicationFiles.forEach((file) => {
        let val = this.formioMappings.get(file?.category);
        const component = this.wizard.getComponent(val);
        let inputElement;
        if (component) inputElement = component.getElement();
        if (inputElement) {
          const div = document.createElement('div');
          const elements = inputElement.getElementsByTagName('label');
          if (
            file?.applicationFileStatus === 'VERIFIED' ||
            file?.applicationFileStatus === 'REJECTED'
          ) {
            div.innerHTML =
              file?.applicationFileStatus === 'VERIFIED'
                ? 'VERIFIED'
                : 'REJECTED';
            inputElement.classList.toggle('disabled');
          } else {
            div.innerHTML =
              file?.applicationFileStatus === 'SENT_BACK'
                ? 'RE-UPLOAD'
                : file?.applicationFileStatus;
            if (file?.applicationFileStatus === 'PENDING') {
              div.innerHTML = 'TO BE VERIFIED';
              inputElement.classList.toggle('disabled');
            }
          }
          let tabClass = this.getTabLabelClass(file?.applicationFileStatus);
          const divWrapper = document.createElement('div');
          divWrapper.classList.toggle('d-flx');
          div.className = `${tabClass} custom-chip`;
          inputElement.appendChild(div);
          elements[0].insertAdjacentElement('afterend', div);
          const parentElement = elements[0].parentNode;
          parentElement.insertBefore(divWrapper, elements[0]);
          divWrapper.appendChild(elements[0]);
          divWrapper.appendChild(div);

          if (file?.applicationFileStatus === 'SENT_BACK') {
            const spanWrapper = document.createElement('div');
            let cnt = 0;
            this.applicationFiles.forEach((f) => {
              if (f?.category === file?.category) {
                cnt++;
              }
            });
            if (cnt >= 1) {
              spanWrapper.innerHTML = ` 
          <p class="form-font-info dynamic-para marT4">${cnt} document(s) are previously uploaded</p>  
          <p class="red form-font-label">Reviewer Comments:</p>
          <p class="marL4 form-font-info">${file?.comments}</p>`;
            } else {
              spanWrapper.innerHTML = ` 
            <p class="red form-font-label">Reviewer Comments:</p>
            <p class="marL4 form-font-info">${file?.comments}</p>`;
            }
            inputElement.appendChild(spanWrapper);
          }
        }
      });
    }
  };

  handleGenerateClick() {
    this.show = false;
    this.spinner = false;
    this.templateFileId = null;
    this.getTemplate();
  }
  getTemplate() {
    this.candidateInfoService
      .generateDocument(this.candidateProfileService.applicationId)
      .subscribe({
        next: (res) => {
          setTimeout(() => {
            this.getFileId();
          }, 2000);
        },
        error: (err) => {
          this.spinner = true;
          this.show = true;
          this.sharedService.openErrorSnackBar(
            'Something went wrong try again later...!',
            ''
          );
        },
      });
  }

  getFileId() {
    this.candidateInfoService
      .getFileId(this.candidateProfileService.applicationId)
      .subscribe({
        next: (res) => {
          // console.log(res?.documentFileId);
          this.templateFileId = res?.documentFileId;
          this.spinner = true;
        },
        error: (err) => {
          this.sharedService.openErrorSnackBar(
            'Something went wrong try again later...!',
            ''
          );
          this.spinner = true;
          this.show = true;
        },
      });
  }

  downloadFile() {
    if (!this.templateFileId) {
      this.sharedService.openErrorSnackBar(
        'Something went wrong try again later...!',
        ''
      );
      return;
    }
    this.fileservice.getFile(this.templateFileId).subscribe({
      next: (res) => {
        const fileName = this.templateFileId + '.pdf';
        let blob: any = new Blob([res.body], {
          type: 'application/octet-stream; charset=utf-8',
        });
        this.docBlob = blob;
        const fileNameValues = fileName.split('.');
        if (
          fileNameValues[fileNameValues.length - 1] === 'pdf' ||
          fileNameValues[fileNameValues.length - 1] === 'PDF'
        ) {
          const blobFile = new File([this.docBlob], fileName, {
            type: 'application/pdf',
          });
          let fileURL = URL.createObjectURL(blobFile);
          window.open(fileURL);
        } else {
          fileSaver.saveAs(this.docBlob, fileName);
        }
        this.sharedService.openSuccessSnackBar(
          'Template Downloaded Successfully..!',
          ''
        );
      },
      error: (err) => {
        this.show = true;
        this.spinner = false;
        this.templateFileId = null;
        this.sharedService.openErrorSnackBar(
          'Error occured. Please try again',
          ''
        );
      },
    });
  }

  handlePrevClick = (e) => {
    this.downloadDisplay = false;
    this.check = false;
    this.show = false;
    this.templateFileId = null;
    this.spinner = true;
    this.next = true;
    this.submitting = false;
    // console.log(e);
  };

  subStatusChange() {
    const body = {
      applicationSubStatus: 'SYNOPSIS_REQUESTED',
    };
    this.candidateInfoService
      .changeApplicationStatus(this.candidateProfileService.applicationId, body)
      .subscribe({
        next: (res) => {
          // this.candidateProfileService.markComponentAsComplete();
          localStorage.removeItem(this.localStorageKey);
          this.displaySubmit = false;
          this.submitting = false;
          let dialogRef = this.displayConfirmation(
            'Information',
            ' <span>Your changes have been saved successfully. Please submit Synopsis</span>',
            'okay'
          );
          dialogRef.afterClosed().subscribe((result) => {
            if (result === 'Yes') {
              this.sharedService.openSuccessSnackBar(
                'Submitted Successfully...!',
                ''
              );
              this.router.navigate(['/candidate/problem-statements']);
            } else {
              this.sharedService.openSuccessSnackBar(
                'Submitted Successfully...!',
                ''
              );
              this.router.navigate(['/candidate/problem-statements']);
            }
          });
        },
        error: (err) => {
          this.resetDisabled = false;
          this.submitting = this.wizard
            ? this.wizard?.checkValidity(
                this.basicDetailsApplicationForm?.feedbackData?.data
              )
            : false;
          this.displaySubmit = false;
          this.sharedService.openErrorSnackBar(
            'Something went wrong try again later...!',
            ''
          );
        },
      });
  }

  disableFormFields(keys): void {
    let form = this.basicDetailsForm?.formData?.components;
    form.map((item) =>
      item['components'].map((component) => {
        if (keys.includes(component['key'])) component['disabled'] = true;
      })
    );
    return this.basicDetailsForm;
  }

  getCandidateDetails() {
    this.candidateInfoId = this.candidateProfileService.getCandidateInfoId();
    this.jobId = this.candidateProfileService.opportunityId;
    this.candidateInfo = this.candidateProfileService.getCandidateProfile();
    if (this.jobId) {
      this.localStorageKey =
        componentPath[0] + this.candidateProfileService.userId + this.jobId;
    } else {
      this.localStorageKey =
        componentPath[0] + this.candidateProfileService.uniqueKeyValue;
    }
    this.locallyStoredData = JSON.parse(
      localStorage.getItem(this.localStorageKey)
    );
  }
  validate(): void {
    this.submitting = false;
    this.displaySubmit = true;
    this.resetDisabled = true;
    if(this.applicationStatus === 'WITHDRAWN'){
      let text = `<b>The application has been withdrawn by you. In case if you want to work on it, please contact us at </b>
              <span>
                <i class="marL8 marR8 fa fa-envelope"></i>
                <a href="mailto:tpic@wa.foundation">tpic@wa.foundation</a>
              </span>
      `;
      this.displayConfirmation('Information',text,'Understood');
      this.submitting = true;
      this.displaySubmit = false;
      this.resetDisabled = false;
      return;
    }
    this.candidateInfoService
      .checkTeamName(
        this.basicDetailsApplicationForm.feedbackData.data?.teamName,
        this.candidateProfileService.applicationId,
        this.basicDetailsApplicationForm.feedbackData.data?.teamMembers
      )
      .subscribe({
        next: (res) => {
          if (
            res?.isValidTeam === 'valid' &&
            res?.isValidTeamMembers === 'valid'
          ) {
            if (this.applicationStatus === 'SUBMITTED') {
              this.updateDetails(true);
            } else {
               this.updateDetails(false);
            }
          } else if (
            res?.isValidTeam === 'invalid' &&
            res?.isValidTeamMembers === 'valid'
          ) {
            this.submitting = true;
            this.displaySubmit = false;
            this.resetDisabled = false;
            let text = `<b>Team name already exists!! Please choose a different name</b>`;
            this.displayConfirmation('Warning', text, 'Understood');
          } else if (
            res?.isValidTeam === 'valid' &&
            res?.isValidTeamMembers === 'invalid'
          ) {
            this.submitting = true;
            this.displaySubmit = false;
            this.resetDisabled = false;
            let invalidTeamMembers;
            invalidTeamMembers = res?.invalidTeamMembers
              ?.slice(1, -1)
              ?.split(', ');
            invalidTeamMembers = invalidTeamMembers.join('\n');
            let text = `
            <b>Below team member(s) have already applied for another problem statement. Kindly choose a different member.</b><br/> 
                  <div class="marL8">
                     <span class="white-space">${invalidTeamMembers}</span>
                  </div>
            `;
            this.displayConfirmation('Warning', text, 'Understood');
          } else {
            this.submitting = true;
            this.displaySubmit = false;
            this.resetDisabled = false;
            let invalidTeamMembers;
            invalidTeamMembers = res?.invalidTeamMembers
              ?.slice(1, -1)
              ?.split(', ');
            invalidTeamMembers = invalidTeamMembers.join('\n');
            let text = `
            <b>Team name already exists and below team member(s) have already applied for another problem statement.Kindly choose a different team name and member(s).</b><br/> 
            <b>Team name:</b>  <span> ${this.basicDetailsApplicationForm.feedbackData.data?.teamName} </span><br/>
            <b>Team members:</b> <br/>  
            <div class="marL8">
              <span class="white-space">${invalidTeamMembers}</span>
            </div>
            `;
            this.displayConfirmation('Warning', text, 'Understood');
          }
        },
        error: (err) => {
          this.submitting = true;
          this.displaySubmit = false;
          this.resetDisabled = false;
          this.sharedService.openErrorSnackBar(
            'Something Went Wrong Please try again later..!!',
            ''
          );
        },
      });
  }
  changeComponentStateInitially() {
    if (this.candidateProfileService.stepperSetupStatus) {
      this.candidateProfileService.markComponentAsChanged();
    } else {
      this.candidateProfileService.stepperSetupStatus$
        .pipe(take(1))
        .subscribe((status) => {
          this.candidateProfileService.markComponentAsChanged();
        });
    }
    // this.unsavedFormData = this.candidateInfoForm.getRawValue();
    // this.autoSaveLocally = true;
    // this.changeIndex++;
  }
  nextStep() {
    this.steps = false;
    this.wizard.setPage(1);
  }
  // prevStep() {
  //   this.fetchTeamSubmission();
  //   this.downloadDisplay = false;
  //   this.check = false;
  //   this.show = false;
  //   this.templateFileId = null;
  //   this.spinner = true;
  //   this.next = true;
  //   this.submitting = false;
  //   this.wizard.setPage(0);
  // }
  previousStep() {
    this.steps = true;
    this.wizard.setPage(0);
  }
  updateDetails(update: Boolean): void {
    // console.log(this.candidateProfileService.applicationId);
    //   if(check){
    // if (!this.basicDetailsApplicationForm.isValid) {
    //   this.sharedService.openErrorSnackBar(
    //     'Please fill all the required information',
    //     ''
    //   );
    //   return;
    // }
    this.basicDetailsApplicationForm.interactionGroupingType = 'OPPORTUNITY';
    this.basicDetailsApplicationForm.interactionGroupingId = parseInt(
      this.candidateProfileService.opportunityId
    );
    this.basicDetailsApplicationForm.feedbackStatus = 'FINAL';
    this.basicDetailsApplicationForm.interactionId =
      this.candidateProfileService.applicationId;
    this.basicDetailsApplicationForm.evaluationForm = {
      id: this.evaluationFormId,
    };
    // delete this.basicDetailsApplicationForm['isValid'];
    delete this.basicDetailsApplicationForm.feedbackData.data['check'];
    delete this.basicDetailsApplicationForm['id'];

    // console.log(this.basicDetailsApplicationForm);
    this.submitting = false;
    this.displaySubmit = true;
    let submitApi: Observable<any> =
      this.candidateInfoService.submitTeamDetails(
        this.basicDetailsApplicationForm
      );
    if (
      this.applicationStatus === 'SUBMITTED' ||
      this.candidateProfileService.getPartialId() ||
      this.submissionId
    ) {
      if (this.candidateProfileService.getPartialId())
        this.basicDetailsApplicationForm.id =
          this.candidateProfileService.getPartialId();
      if (this.submissionId)
        this.basicDetailsApplicationForm.id = this.submissionId;
      submitApi = this.candidateInfoService.updateTeamDetails(
        this.basicDetailsApplicationForm,
        this.basicDetailsApplicationForm.id
      );
    }
    submitApi.subscribe({
      next: (res) => {
        if (update) {
          localStorage.removeItem(this.localStorageKey);
          this.displaySubmit = false;
          this.submitting = false;
          this.resetDisabled = false;
          let dialogRef = this.displayConfirmation(
            'Information',
            '<span>Your changes have been saved successfully. Please download the verification letter template in synopis page and re-upload it</span>',
            'okay'
          );
          dialogRef.afterClosed().subscribe((result) => {
            if (result === 'Yes') {
              this.sharedService.openSuccessSnackBar(
                'Submitted Successfully...!',
                ''
              );
              this.router.navigate(['/candidate/problem-statements']);
            } else {
              this.sharedService.openSuccessSnackBar(
                'Submitted Successfully...!',
                ''
              );
              this.router.navigate(['/candidate/problem-statements']);
            }
          });
        } else {
          this.subStatusChange();
        }
        // localStorage.removeItem(this.localStorageKey);
        // this.displaySubmit = false;
        // this.submitting = false;
        // this.router.navigate(['/candidate/problem-statements']);
      },
      error: (err) => {
        this.resetDisabled = false;
        this.submitting = this.wizard
          ? this.wizard?.checkValidity(
              this.basicDetailsApplicationForm?.feedbackData?.data
            )
          : false;
        this.displaySubmit = false;
        this.sharedService.openErrorSnackBar(
          'Something Went Wrong Please try again later..!',
          ''
        );
      },
    });
  }
  updateBasicDetails(): void {
    // this.payloadConvert(this.basicDetailsApplicationForm.feedbackData.data);
    this.applicationFormData =
      this.basicDetailsApplicationForm.feedbackData.data;
    this.basicDetailsApplicationForm.interactionGroupingType = 'OPPORTUNITY';
    this.basicDetailsApplicationForm.interactionGroupingId =
      this.candidateProfileService.opportunityId;
    this.basicDetailsApplicationForm.evaluationForm.id = this.evaluationFormId;

    // console.log(this.basicDetailsApplicationForm);
    //   if(this.basicDetailsApplicationForm.isValid===false){
    //     this.sharedService.openErrorSnackBar('Please fill all the required information','');
    //     return;
    //  }
    let apiToCall = this.candidateInfoId
      ? this.candidateInfoService.patchUpdateCandidateProfileUIDTO(
          this.candidateInfoId,
          this.applicationFormData
        )
      : this.candidateInfoService.createCandidateProfileForFirstTime(
          this.applicationFormData
        );

    apiToCall.subscribe({
      next: (response: any) => {
        if (!this.candidateInfoId) {
          this.candidateProfileService.setCandidateProfile(response);
          this.candidateProfileService.candidateInfoId$.next(
            response.candidateInfoId
          );
        } else {
          this.candidateProfileService.setUiPersonalInfoDTO(
            response.uiPersonalInfoDTO
          );
          this.candidateProfileService.setUiScholarshipDetailsDTO(
            response.uiScholarshipDetailsDTO
          );
        }
        this.isLoading = false;
        this.autoSaveLocally = false;
        this.sharedService.openSuccessSnackBar(
          'Personal Info updated successfully!',
          ''
        );
        this.changeIndex = 0;
        localStorage.removeItem(this.localStorageKey);
        this.candidateProfileService.markComponentAsComplete();
        this.router.navigate(['../education-info'], { relativeTo: this.route });
      },
      error: (err: any) => {
        this.isLoading = false;
        this.changeIndex--;
        this.sharedService.openErrorSnackBar(
          'Personal Info updation failed. Please try again!',
          ''
        );
      },
    });
  }

  createdFormOnChange = (event: any) => {
    // console.log(event.data?.collegePostalCode?.toString().length)
    if (event.data?.collegePostalCode?.toString().length == 6) {
      this.refresh = true;
    }
    if (this.refresh && this.isRefreshed) {
      this.getPincode(event.data?.collegePostalCode?.toString(), event);
      this.isRefreshed = false;
      this.refresh = false;
      return;
    }
    if (event.data) {
      if (!event.isModified) {
        this.submitting = this.wizard
          ? this.wizard?.checkValidity(event.data)
          : false;
      }
      // console.log(
      //   event
      // );
      // While uploading a file, event.isModified is not working fine, added a temporary FIX
      // if (event.data?.file?.length > 0) {

      // if (event.data?.highLevelDesignPresentation?.length > 0) {

      // if (event.data?.verificationLetter?.length > 0) {

      this.basicDetailsApplicationForm = {
        feedbackData: {
          data: event.data,
        },
        isValid: this.wizard?.checkValidity(event.data) ? true : false,
      };
      if (
        event.changed &&
        event.changed.instance &&
        event.changed.instance.type === 'file' 
        // &&
        // this.applicationStatus !== 'SUBMITTED'
      ) {
        this.autoSaveInDB('').subscribe({
          next: (res) => {},
          error: (error) => {},
        });
      }
      // console.log(this.basicDetailsApplicationForm);
      if (event.data?.collegePostalCode?.toString().length < 6)
        this.isRefreshed = true;
      // if (this.basicDetailsApplicationForm.isValid) this.submitting = true;
      // else this.submitting = false;
      this.submitting = this.wizard
        ? this.wizard?.checkValidity(
            this.basicDetailsApplicationForm?.feedbackData?.data
          )
        : false;
    }
  };

  callApi() {
    if (this.changeIndex === 1) {
      this.isLoading = false;
      this.candidateProfileService.markComponentAsComplete();
      this.sharedService.openSuccessSnackBar(
        'Personal Info updated successfully!',
        ''
      );
      this.router.navigate(['../education-info'], { relativeTo: this.route });
      return;
    }
    const payload = {
      candidateInfoId: this.candidateInfoId,
      uiPersonalInfoDTO: this.candidateInfoForm
        .get('uiPersonalInfoDTO')
        .getRawValue(),
      uiScholarshipDetailsDTO: this.candidateInfoForm
        .get('uiScholarshipDetailsDTO')
        .getRawValue(),
    };
    let apiToCall;
    // let apiToCall = this.candidateInfoId
    //   ? this.candidateInfoService.patchUpdateCandidateProfileUIDTO(
    //     this.candidateInfoId,

    //   )
    //   : this.candidateInfoService.createCandidateProfileForFirstTime(payload);
    apiToCall.subscribe({
      next: (response: any) => {
        if (!this.candidateInfoId) {
          this.candidateProfileService.setCandidateProfile(response);
          this.candidateProfileService.candidateInfoId$.next(
            response.candidateInfoId
          );
        } else {
          this.candidateProfileService.setUiPersonalInfoDTO(
            response.uiPersonalInfoDTO
          );
          this.candidateProfileService.setUiScholarshipDetailsDTO(
            response.uiScholarshipDetailsDTO
          );
        }
        this.isLoading = false;
        this.autoSaveLocally = false;
        this.sharedService.openSuccessSnackBar(
          'Personal Info updated successfully!',
          ''
        );
        this.changeIndex = 0;
        localStorage.removeItem(this.localStorageKey);
        this.candidateProfileService.markComponentAsComplete();
        this.router.navigate(['../education-info'], { relativeTo: this.route });
      },
      error: (err: any) => {
        this.isLoading = false;
        this.changeIndex--;
        this.sharedService.openErrorSnackBar(
          'Personal Info updation failed. Please try again!',
          ''
        );
      },
    });
  }
  handleRefresh = () => {
    // console.log('refresh');
    this.refreshEmitter.emit({
      submission: {
        data: this.basicDetailsApplicationForm?.feedbackData?.data,
      },
    });
  };
  handleResetClick = () => {
    this.refreshEmitter.emit({
      submission: {
        data: {},
      },
    });
  };
  setFormData(res): void {
    let latest = res.content.length ? res.content?.length : 0;
    if (latest >= 1) {
      latest = res.content?.length - 1;
    }
    if (!res.content[latest]?.templateEvaluationForm?.formData) {
      this.formRenderingError =
        'Something went wrong while rendering the form. Please try again later.';
    }
    // console.log(res);
    this.basicDetailsForm = res.content[latest]?.templateEvaluationForm;
    this.evaluationFormId = res.content[latest]?.id;
    this.isFormLoading = false;
    this.basicForm = this.basicDetailsForm;
  }

  setSubmission(res): void {
    if (res) {
      this.submissionId = res?.id;
      this.submission = {
        data: res?.feedbackData?.data,
      };
      this.feedbackstatus = res?.feedbackStatus;
    }
  }

  setApplicationStatus(res) {
    if (res) {
      this.applicationStatus = res?.status;
      this.applicationFiles = res?.applicationFiles;
      this.filteredApplicationFiles = [];
      this.filteredApplicationFiles = this.filterByMaxId(this.applicationFiles);
      this.filteredApplicationFiles.forEach((file) => {
        let val = this.formioMappings.get(file?.category);
        if (
          file?.applicationFileStatus === 'SENT_BACK' &&
          this.submission.data?.val
        ) {
          this.submission.data[val] = [];
        }
      });
    }
  }

  onAutoSaveInDB = () => {
    // show toaster to indicate partial save of data in DB
    // toaster used because timer in dynamic events uses snackbar, only one snackbar visible at a time
    this.sharedService.openSuccessSnackBar('Saved Successfully', '');
  };
  autoSaveInDB = (storeItem: any): Observable<any> => {
    // console.log(storeItem)
    this.basicDetailsApplicationForm.interactionGroupingType = 'OPPORTUNITY';
    if (this.candidateProfileService.getPartialId() != null) {
      this.basicDetailsApplicationForm.id =
        this.candidateProfileService.getPartialId();
    }
    if (this.submissionId) {
      this.basicDetailsApplicationForm.id = this.submissionId;
    }
    this.basicDetailsApplicationForm.interactionGroupingId = parseInt(
      this.candidateProfileService.opportunityId
    );
    this.basicDetailsApplicationForm.interactionId =
      this.candidateProfileService.applicationId;
    this.basicDetailsApplicationForm.evaluationForm = {
      id: this.evaluationFormId,
    };
    if(this.applicationStatus ==='SUBMITTED'){
      this.basicDetailsApplicationForm.feedbackStatus = 'FINAL';
    }else{
      this.basicDetailsApplicationForm.feedbackStatus = 'PARTIAL';
    }
    delete this.basicDetailsApplicationForm.feedbackData.data['check'];
    if (
      this.candidateProfileService.getPartialId() == null &&
      !this.submissionId
    ) {
      return this.candidateInfoService.submitTeamDetails(
        this.basicDetailsApplicationForm
      );
    } else {
      return this.candidateInfoService.updateTeamDetails(
        this.basicDetailsApplicationForm,
        this.basicDetailsApplicationForm.id
      );
    }
  };
  // fetchTeamSubmission(): void {
  //   this.candidateInfoService
  //     .getTeamDetailsForm(this.candidateProfileService.opportunityId)
  //     .subscribe({
  //       next: (res) => {
  //         this.submission = {
  //           data: res?.feedbackData?.data,
  //         };
  //       },
  //       error: (err) => {
  //         this.sharedService.openErrorSnackBar(
  //           'Error Occured While Fetching the details...',
  //           ''
  //         );
  //       },
  //     });
  // }
  getPincode(value: any, e: any) {
    if (value?.length == 6) {
      this.candidateInfoService.getPostalDetailsByPostalCode(value).subscribe({
        next: (res) => {
          if (res?.status === 'Error') {
            this.sharedService.openErrorSnackBar('Enter a Valid Pincode', '');
            return;
          }
          if (e.data) {
            e.data.collegeState = res?.state;
            e.data.collegeDistrict = res?.district;
          }
          this.basicDetailsApplicationForm = {
            feedbackData: {
              data: e.data,
            },
          };
        },
        error: (err) => {
          this.sharedService.openErrorSnackBar(
            'Unable to fetch pincode, please try again later!',
            ''
          );
          return;
        },
      });
    }
    setTimeout(() => {
      this.handleRefresh();
    }, 2000);
  }
  getTabLabelClass = (applicationStatus: string) => {
    switch (applicationStatus) {
      case 'VERIFIED':
        return 'tab_green';
      case 'REPLACED':
        return 'tab_purple';
      case 'REJECTED':
        return 'tab_red';
      case 'PENDING':
        return 'tab_yellow';
      case 'SENT_BACK':
        return 'tab_blue';
      case 'UPLOADING':
        return 'tab_yellow';
      case 'DELETED':
        return 'tab_red';
      default:
        return 'tab_grey';
    }
  };

  validateUploadFile(category) {
    let categoryFiles = [];
    let isFound: Boolean = false;
    categoryFiles = this.applicationFiles.filter(
      (file) => file.category === category
    );
    if (categoryFiles?.length <= 0) {
      return -1;
    }
    categoryFiles.forEach((file) => {
      if (file?.applicationFileStatus === 'SENT_BACK') {
        isFound = true;
      }
    });
    categoryFiles.sort((a, b) => {
      const aId = a?.id;
      const bId = b?.id;
      return bId - aId;
    });
    // console.log(isFound)
    if (!isFound) return -1;
    return categoryFiles[0]?.id;
  }
  filterByMaxId(arr) {
    const categoryMap = new Map();
    for (const obj of arr) {
      const { id, category } = obj;
      if (!categoryMap.has(category) || id > categoryMap.get(category).id) {
        categoryMap.set(category, obj);
      }
    }
    return Array.from(categoryMap.values());
  }

  displayConfirmation(title, text, secondary) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        title: title,
        text: text,
        secondary: secondary,
        fShow: false,
      },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });
    return dialogRef;
  }
}
