<app-loader [display]="loader"></app-loader>

<div *ngIf="!loader">
  <h1 mat-dialog-title>Duplicate Event</h1>
  <form [formGroup]="createEvent">
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel">
          <mat-label>Event Name</mat-label>

          <input
            matInput
            placeholder="Event Name"
            formControlName="eventName"
          />
          <mat-error
            *ngIf="
              createEvent.controls['eventName'].dirty &&
              createEvent.controls['eventName'].hasError('pattern')
            "
            >Special characters except '_,.@&()-' are not allowed</mat-error
          >
        </mat-form-field>
      </div>

      <div class="col-lg-6 col-md-6">
        <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixIcon">
          <mat-label>Start Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            (click)="picker.open()"
            formControlName="fromDate"
          />
          <mat-datepicker-toggle class="DSA_wb_mat-suffix" [for]="picker">
            <i class="icon x-24 calendar-icon" matDatepickerToggleIcon></i>
          </mat-datepicker-toggle>
          <mat-datepicker
            panelclass="DSA_wb_dateClass"
            #picker
          ></mat-datepicker>
        </mat-form-field>
      </div>
      </div>
      <div class="row">
      <div class="col-lg-6 col-md-6">
        <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixIcon">
          <mat-label>End Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker1"
            (click)="picker1.open()"
            formControlName="toDate"
          />
          <mat-datepicker-toggle class="DSA_wb_mat-suffix" [for]="picker1">
            <i class="icon x-24 calendar-icon" matDatepickerToggleIcon></i>
          </mat-datepicker-toggle>
          <mat-datepicker
            panelclass="DSA_wb_dateClass"
            #picker1
          ></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-6">
        <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel">
          <mat-label disableOptionCentering  panelClass="DSA_wb-custom-select-panel" class="DSA_wb-custom-select">Event Type</mat-label>
          <mat-select
            formControlName="eventType"
            panelClass="DSA_wb-custom-select"
          >
            <mat-option *ngFor="let option of eventTypeArray" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel">
          <mat-label>Location</mat-label>
          <mat-chip-list #chipList>
            <mat-chip
              class="DSA_wb_chipStyle-Normal DSA_wb_chip-input marR16"
              disableRipple="true"
              *ngFor="let location of locationList"
              [selectable]="selectable"
              [removable]="removable"
              (removed)="remove(location)"
            >
              {{ location.name }}
              <i
                matChipRemove
                class="icon x-16 icon-close-mini DSA_wb_chipIconRight"
                *ngIf="removable"
              ></i>
            </mat-chip>
            <input
              placeholder="New location..."
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="add($event)"
              [disabled]="
              !accountService.hasFeatureAccess(FeatureName.EVENT, PermissionType.CREATE)
              "
            />
          </mat-chip-list>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-6"></div>
    </div>
    <div class="row marTB8">
      <div
        class="d-inline-flex align-items-center col-lg-4 col-md-6 marT8 nopadL"
      >
        <mat-label class="col-8">Duplicate Templates</mat-label>
        <mat-slide-toggle
          class="col-4 nopadL"
          formControlName="copyTemplates"
          disableRipple="true"
          checked="true"
          labelPosition="before"
        ></mat-slide-toggle>
      </div>

      <div
        class="d-inline-flex align-items-center col-lg-4 col-md-6 marT8 nopadL"
      >
        <mat-label class="col-8">Duplicate Settings</mat-label>
        <mat-slide-toggle
          class="col-4 nopadL"
          formControlName="copySettings"
          disableRipple="true"
          checked="true"
          labelPosition="before"
        ></mat-slide-toggle>
      </div>

      <div
        class="d-inline-flex align-items-center col-lg-4 col-md-6 marT8 nopadL"
      >
        <mat-label class="col-8">Duplicate Jobs</mat-label>
        <mat-slide-toggle
          checked="true"
          class="col-4 nopadL"
          formControlName="copyJob"
          disableRipple="true"
          labelPosition="before"
        ></mat-slide-toggle>
      </div>

      <div
        class="d-inline-flex align-items-center col-lg-4 col-md-6 marT8 nopadL"
      >
        <mat-label class="col-8">Duplicate Recruiters</mat-label>
        <mat-slide-toggle
          class="col-4 nopadL"
          formControlName="copyRecruiters"
          checked="true"
          disableRipple="true"
          labelPosition="before"
        ></mat-slide-toggle>
      </div>

      <div
        class="d-inline-flex align-items-center col-lg-4 col-md-6 marT8 nopadL"
      >
        <mat-label class="col-8">Duplicate Panelists</mat-label>
        <mat-slide-toggle
          class="col-4 nopadL"
          formControlName="copyPanel"
          checked="true"
          disableRipple="true"
          labelPosition="before"
        ></mat-slide-toggle>
      </div>
    </div>
  </form>

  <div mat-dialog-actions class="d-flex justify-content-end">
    <button
      class="DSA_wb_miniSecondary-btn mr-2"
      type="reset"
      [mat-dialog-close]="false"
    >
      Cancel
    </button>
    <button
      class="DSA_wb_miniPrimary-btn"
      type="submit"
      (click)="addEvent()"
      [disabled]="createEvent.invalid"
    >
      Create
    </button>
  </div>
</div>
