import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Job } from 'src/app/shared/model/Job-model';
import { JobStatus } from 'src/app/shared/model/jobStatus-enum';
import { JobStatusMapper } from 'src/app/shared/model/jobStatus-mapper';
import { JobType } from 'src/app/shared/model/jobType-enum';
import { PaymentCurrency } from 'src/app/shared/model/paymentCurrency-enum';
import { PaymentMode } from 'src/app/shared/model/paymentMode-enum';
import { PaymentModeMapper } from 'src/app/shared/model/paymentMode-mapper';
import { CandidateInfoService } from '../services/candidate-info.service';
import { Location, ViewportScroller } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';
import { CandidateProfileService } from '../candidate-profile/candidate-profile.service';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { Observable, forkJoin, of } from 'rxjs';

@Component({
  selector: 'app-view-job-details',
  templateUrl: './view-job-details.component.html',
  styleUrls: ['./view-job-details.component.scss'],
})
export class ViewJobDetailsComponent implements OnInit {
  job;
  showDetailsPage: number = 0;
  jobDetails;
  showeligibilityCriteria: boolean = false;
  applicationFullstatus: boolean = false;

  observables: Observable<any>[] = [];
  isPartOfTeam: boolean = false;
  teamLeadEmail: string = '';
  candidateEmail = JSON.parse(localStorage.getItem('ACCOUNT_DETAILS'))?.email;

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private service: CandidateInfoService,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public accountService: AccountService,
    public _location: Location,
    private router: Router,
    private scroller: ViewportScroller,
    private candidateProfileService: CandidateProfileService
  ) {}

  ngOnInit(): void {
    let jobId = this.route.snapshot.paramMap.get('jobid');
    this.candidateProfileService.candidateApplicationFull.subscribe((value) => {
      this.applicationFullstatus = value;
    });
    this.observables.push(
      this.service.getJobByID(jobId).pipe(catchError((error) => of(error))),
      this.service
        .isPartofTeam()
        .pipe(catchError((error) => of(error)))
    );

    forkJoin(this.observables).subscribe((res) => {
      res?.forEach((f, index) => {
        if (f instanceof HttpErrorResponse) {
          if (index == 0) {
            // error to be handled for jobId
          } else if (index == 1) {
            // error to be handled for part of team
          }
        } else {
          if (index == 0) {
            this.setJobIdResponse(f);
          } else {
            this.setPartOfTeam(f);
          }
        }
      });
    });
  }

  ScrollIntoView(elem: string) {
    this.router.navigate([], { fragment: elem });
    // console.log(elem);
    // this.scroller.scrollToAnchor(elem);
  }

  apply() {
    if (this.applicationFullstatus || this.isPartOfTeam) {
      if (this.isPartOfTeam && this.candidateEmail !== this.teamLeadEmail) {
        this.setInfo(true);
      } else {
        this.setInfo(false);
      }
    } else {
      this.router.navigate(
        [
          `/candidate/application/create/${this.route.snapshot.paramMap.get(
            'jobid'
          )}`,
        ],
        { state: { checkForLocalStepState: true } }
      );
    }
  }

  setInfo(partOfTeam) {
    if (partOfTeam) {
      let text = `<b>You are already part of a team. <br/> For any queries :</b><br/> <b>Please Contact your Team Lead : </b> ${this.teamLeadEmail}`;
      this.handleApplicationFull(text);
    } else {
      let text = '<b>You Have Submitted Maximum Allowed Application</b>';
      this.handleApplicationFull(text);
    }
  }

  handleApplicationFull(text): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        title: 'Information',
        text: text,
        secondary: `Understood`,
        fShow: false,
      },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });
  }

  getDateSimplified = (dateString: string) => {
    let date = new Date(dateString);
    return date
      .toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
      .replace(/\//g, '-');
  };

  setJobIdResponse(response) {
    const obj: any = response;
    if (obj) {
      this.showDetailsPage = 3;
      this.jobDetails = obj;
      //console.log(this.jobDetails.eligibilityCriteria !== undefined);
      if (
        this.jobDetails.eligibilityCriteria !== undefined &&
        this.jobDetails.eligibilityCriteria.length > 0
      ) {
        this.showeligibilityCriteria = true;
      }
    } else {
      this.showDetailsPage = 2; //not working, tried using this in ui
    }
    this.spinnerService.hide();
  }

  setPartOfTeam(res) {
    if (res) {
      this.isPartOfTeam = true;
      const teamLeadMember = res?.teamMemberUiDTOS?.find(
        (member) => member?.role === 'TEAM_LEAD'
      );
      if (teamLeadMember) {
        this.teamLeadEmail = teamLeadMember.email;
      }
    }
  }
}
