<div *ngIf="spinner">
  <br />
  <img
    src="../../../assets/loader.gif"
    style="margin-left: 30%; width: 200px"
    alt="Spinner"
  />
</div>
<div class="m-3 mr-5">
<div class="container-fluid ml-3 mr-3">
  <div class="mb-3 pt-3">
    <div class="row">
      <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel">
        <mat-label class="color-name">Name </mat-label>
        <input matInput [(ngModel)]="contactDetails && contactDetails.name" />
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel">
        <mat-label class="color-name">Email </mat-label>
        <input matInput [(ngModel)]="contactDetails && contactDetails.email" />
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel">
        <mat-label class="color-name">Phone </mat-label>
        <input matInput [(ngModel)]="contactDetails && contactDetails.phone" />
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel">
        <mat-label class="color-name">Current Address </mat-label>
        <input
          matInput
          [(ngModel)]="contactDetails && contactDetails.currentAddress"
        />
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel">
        <mat-label class="color-name">Current Address Postcode </mat-label>
        <input
          type="number"
          matInput
          [(ngModel)]="contactDetails && contactDetails.currentAddressPostcode"
        />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel">
        <mat-label class="color-name">Permanent Address </mat-label>
        <input
          matInput
          [(ngModel)]="contactDetails && contactDetails.permanentAddress"
        />
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel">
        <mat-label class="color-name">Permanent Address Postcode </mat-label>
        <input
          type="number"
          matInput
          [(ngModel)]="
            contactDetails && contactDetails.permanentAddressPostcode
          "
        />
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel">
        <mat-label class="color-name">Alternate Phone </mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="contactDetails && contactDetails.alternatePhones"
        />
      </mat-form-field>
    </div>

  </div>
  
</div>
<button
    class="DSA_wb_miniSecondary-btn"
    (click)="updateExtractedResume()"
    style="
      float: right;
      margin-right: 10px !important;
      margin-bottom: 10px !important;
    "
  >
    Update
  </button>
</div>