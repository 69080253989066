import { map, startWith } from 'rxjs/operators';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JobService } from 'src/app/services/job.service';
import { SharedService } from 'src/app/services/shared.service';
import { JobRole } from 'src/app/shared/model/job-role.model';
import { FormControl } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
import { Router } from '@angular/router';
@Component({
  selector: 'app-infy-associate-jobs',
  templateUrl: './infy-associate-jobs.component.html',
  styleUrls: ['./infy-associate-jobs.component.scss'],
})
export class InfyAssociateJobsComponent implements OnInit {
  loading: boolean = false;
  currentJob: any;
  currentJobId = '';
  currentEventId = '';
  associatedJobs: any = [];
  unAssociatedJobs: any = [];
  allJobs: JobRole[] = [];
  unassociateList: number[] = [];
  associateList: number[] = [];


  AssociationType = {
    CENTRAL: 'CENTRAL',
    EVENT: 'EVENT',
  };

  unassociatedJobSearch: FormControl = new FormControl();
  unassociateJobsObservable;
  FeatureName = FeatureName;
  PermissionType = PermissionType;

  constructor(
    private jobService: JobService,
    private sharedService: SharedService,
    public accountService: AccountService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.unassociateJobsObservable = this.unassociatedJobSearch.valueChanges.pipe(
      // tslint:disable-next-line: deprecation
      startWith(null),
      map((job: string | null) => {
        return job
          ? this._filter(job.trim(), this.allJobs, 'title')
          : this.allJobs;
      })
    );
    this.loading = true;
    this.currentJob = this.data.jobs;
    // console.log(this.currentJob);
    this.currentJobId =
      this.data.associationType === this.AssociationType.CENTRAL
        ? this.currentJob.id
        : this.currentJob.jobRoleId;

    this.currentEventId = this.data.eventId;

    // if (this.data.associationType === AssociationType.CENTRAL) {
    this.jobService.getJobRoles(500, 0).subscribe(
      (res: any) => {
        this.allJobs = res.content.filter((job) => job.type === 'OTHERS');
        this.refreshData();
      }
      // () => {
      //   this.loading = false;
      // }
    );
    // }
    // else if (this.data.associationType === AssociationType.EVENT) {
    //   console.log('GETTING ALL JOBS IN EVENT');
    //   // ! add event level job association
    //   this.currentEventId = this.data.eventId;
    //   this.jobService
    //     .getAllJobsByEvent(this.currentEventId, 0, 500)
    //     .subscribe((res: any) => {
    //       console.log('RES::', res);
    //       this.allJobs = res.content.filter((job) => job.jobType === 'OTHERS');
    //       console.log('ALL::', this.allJobs);
    //       this.refreshData();
    //     });
    // }
  }

  private _filter(value: string, listObj, key?): any[] {
    const filterValue = value?.toString()?.toLowerCase();
    return listObj.filter((a) => {
      if (key)
        return a[key].toString().toLowerCase().indexOf(filterValue) !== -1;
      else
        return (
          Object.values(a).toString().toLowerCase().indexOf(filterValue) !== -1
        );
    }) as any[];
  }

  sortArrayOfObjects = (array, key) => {
    return array.sort((a, b) =>
      a[key].toLowerCase() > b[key].toLowerCase()
        ? 1
        : b[key].toLowerCase() > a[key].toLowerCase()
        ? -1
        : 0
    );
  };

  refreshData() {
    this.loading = true;

    if (this.data.associationType === this.AssociationType.CENTRAL) {
      this.jobService.getAssociatedJobs(this.currentJobId).subscribe(
        (res) => {
          this.associatedJobs = this.sortArrayOfObjects(res, 'title');
          this.segregateAssociation();
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    } else if (this.data.associationType === this.AssociationType.EVENT) {
      this.jobService.getAssociatedJobs(this.currentJobId).subscribe((res) => {
        this.associateList = res.map((_) => _.id);

        this.jobService
          .getAssociatedJobsForEvent(this.currentEventId, this.currentJobId)
          .subscribe(
            (res) => {
              if (res.isEventLevelAssociation) {
                this.associatedJobs = this.sortArrayOfObjects(
                  res.associatedJobRoles,
                  'title'
                );
                // remove job from associateList if job is already associated at event level
                for (let item of this.associatedJobs) {
                  const index = this.associateList.indexOf(item.id);
                  if (index > -1) {
                    this.associateList.splice(index, 1);
                  }
                }
              } else {
                this.associatedJobs = [];
              }
              this.segregateAssociation();
              this.loading = false;
            },
            () => {
              // this.segregateAssociation();
              this.loading = false;
            }
          );
      });
    }
  }

  segregateAssociation() {
    this.unAssociatedJobs = this.allJobs.filter((job) => {
      return !this.associatedJobs.some((associated) => {
        return associated.id === job.id;
      });
    });

    const currentJobIndex = this.unAssociatedJobs
      .map(function (job) {
        return job.id;
      })
      .indexOf(this.currentJobId);

    this.unAssociatedJobs.splice(currentJobIndex, 1);
  }

  unassociateJob(event, job) {
    if (event.checked) {
      this.unassociateList.push(job.id);
    } else {
      let index = this.unassociateList.indexOf(job.id);
      if (index > -1) {
        this.unassociateList.splice(index, 1);
      }
    }
  }

  checkAssociationAccess = () =>{
    if(this.router.url === '/infy-jobs-admin'){

      return !this.accountService.hasFeatureAccess(FeatureName.ASSOCIATE_JOBS,PermissionType.UPDATE);

    } else {
      return !this.accountService.hasFeatureAccess(FeatureName.EVENT_ASSOCIATE_JOBS,PermissionType.UPDATE);
    }
  }

  associateJob(event, job) {
    if (event.checked) {
      this.associateList.push(job.id);
    } else {
      let index = this.associateList.indexOf(job.id);
      if (index > -1) {
        this.associateList.splice(index, 1);
      }
    }
  }

  onUnAssociateClick() {
    if (this.unassociateList.length > 0) {
      this.loading = true;
      if (this.data.associationType === this.AssociationType.CENTRAL) {
        const data = {
          jobRoleId: this.currentJob.id,
          associatedJobs: this.unassociateList,
        };

        this.unassociateList = [];

        this.jobService.deleteAssociatedJobs(data).subscribe((res) => {
          this.refreshData();
        });
      } else if (this.data.associationType === this.AssociationType.EVENT) {
        const data = this.unassociateList;
        this.unassociateList = [];

        this.jobService
          .deleteAssociatedJobsForEvent(
            this.currentEventId,
            this.currentJobId,
            data
          )
          .subscribe((res) => {
            this.refreshData();
          });
      }
    } else {
      this.sharedService.openSnackBar(
        `Please select job role(s) to un-associate with ${this.currentJob.title}`,
        '',
        3000
      );
    }
  }
  onAssociateClick() {
    if (this.associateList.length > 0) {
      this.loading = true;
      if (this.data.associationType === this.AssociationType.CENTRAL) {
        const data = {
          jobRoleId: this.currentJob.id,
          associatedJobs: this.associateList,
        };

        this.associateList = [];

        this.jobService.addAssociatedJobs(data).subscribe((res) => {
          this.refreshData();
        });
      } else if (this.data.associationType === this.AssociationType.EVENT) {
        const data = this.associateList;

        this.associateList = [];

        this.jobService
          .addAssociatedJobsForEvent(
            this.currentEventId,
            this.currentJobId,
            data
          )
          .subscribe((res) => {
            this.refreshData();
          });
      }
    } else {
      this.sharedService.openSnackBar(
        `Please select job role(s) to associate with ${this.currentJob.title}`,
        '',
        3000
      );
    }
  }
}
