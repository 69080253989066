import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PersonalInfo } from 'src/app/shared/model/Profile/personalInfo-model';
import { SharedService } from 'src/app/services/shared.service';
import { CandidateInfoService } from '../../../candidate/services/candidate-info.service';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss'],
})
export class PersonalDetailsComponent implements OnInit {
  // candidateInfo: ICandidateInfo;
  spinner: boolean = false;
  message: string;
  // @Input() extractedResume: IExtractedResume;
  @Input() personalDetails: PersonalInfo;
  @Output() newItemEvent = new EventEmitter<PersonalInfo>();
  updateExtractedResume() {
    //console.log(this.personalDetails)

    this.services
      .UpdatePersonalDeatailsOfProfile(this.personalDetails)
      .subscribe({
        next: (res: any) => {
          this.sharedService.openSuccessSnackBar(
            'data updated successfully',
            ''
          );
        },
        error: (err: any) => {
          this.sharedService.openErrorSnackBar(
            'data update failed. Please try again',
            ''
          );
        },
      });
    this.newItemEvent.emit(this.personalDetails);
  }
  constructor(
    private services: CandidateInfoService,
    public sharedService: SharedService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {}

  save() {
    this.spinner = true;
    console.log(this.personalDetails);
    this.services
      .UpdatePersonalDeatailsOfProfile(this.personalDetails)
      .subscribe(
        (response) => {
          console.log('value updated');
          this.message = 'value updated';
          this.spinner = false;
        },
        (err: HttpErrorResponse) => {
          // const dialogRef = this.dialog.open(DialogboxComponent, {
          //   data: { message: "Some Error Occured While Saving", error: "true" },
          // });
          console.log('updation failed');
          this.message = 'updation failed';
          this.spinner = false;
        }
      );
    this.snackBar.open(this.message, 'Dismiss');
  }
}
