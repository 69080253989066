import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
// import { RecruiterService } from '../recruiter.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Observable, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { startWith, map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MailTemplateSelectionComponent } from '../mail-template-selection/mail-template-selection.component';
import { SettingsComponent } from '../settings/settings.component';
import { ConfirmationComponent } from '../../../shared/components/confirmation/confirmation.component';
import { GenerateReportComponent } from '../generate-report/generate-report.component';
import * as fileSaver from 'file-saver';
// import { CreateEventStateStorageService } from './create-event-state-storage.service';
import { AccountService } from 'src/app/services/account.service';
import { ReportGenerationService } from 'src/app/services/report-generation.service';
import { FileService } from 'src/app/services/file.service';
import { EventService } from 'src/app/services/event.service';
import { JobService } from 'src/app/services/job.service';
import { RoutesService } from 'src/app/services/routes.service';
import { SharedService } from 'src/app/services/shared.service';
import { RecruiterService } from '../../../services/recruiter.service';
import { AddRecruitersComponent } from 'src/app/modules/iwp-event/add-recruiters/add-recruiters.component';
import { eventRecruiterActionRendererComponent } from 'src/app/modules/iwp-event/infy-event-recruiters/event-recruiter-action-renderer';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
@Component({
  selector: 'app-infy-event-recruiters',
  templateUrl: './infy-event-recruiters.component.html',
  styleUrls: ['./infy-event-recruiters.component.scss'],
})
export class InfyEventRecruitersComponent implements OnInit {
  availableRecruiterObs: Observable<any[]>;
  eventId: number;
  recruiters = [];
  @ViewChild(MatSelect, { static: false }) selectRef: MatSelect;
  searchControl = new FormControl();
  newRecruiter: any;
  size = 10;
  page = 0;
  selectedRecruiter: any;
  refreshAgGrid: Subject<void> = new Subject<void>();
  availableRecruiters = [];

  eventStatus: any = localStorage.getItem('eventStatus');
  allRecruiters: any;
  displayColumn = ['Id', 'Name', 'Email'];

  searchCondition: boolean = true;
  FeatureName = FeatureName;
  PermissionType = PermissionType;

  loading = false;
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private recruiterService: RecruiterService,
    public accountService: AccountService,
    private eventService: EventService,
    private router: Router
  ) {}

  interface = (
    size: number,
    page: number,
    query?: string
  ): Observable<any[]> => {
    if (query) {
      query = query.split('@')[0];
      return this.eventService.getRecruitersListWithEventAndSearchParam(
        query,
        size,
        page
      );
    } else {
      return this.recruiterService.getRecrutersListWithEventId(
        this.eventId,
        size,
        page
      );
    }
  };
  tableName: string = 'Recruiters';

  levels: number = 1;
  buttonCondition: boolean = false;
  actionCondition: boolean =
    this.accountService.hasFeatureAccess(
      FeatureName.EVENT_RECRUITER,
      PermissionType.UPDATE
    ) && this.eventStatus !== 'CLOSED';

  columnDefs: any = [
    { headerName: 'ID', field: 'id', hide: true },
    {
      headerName: 'Name',
      field: 'recruiterName',
      type: 'required',
      hide: false,
    },
    { headerName: 'Email', field: 'recruiterEmail', hide: false },
    // { headerName: 'Event ID', field: 'eventId', hide: true },
    {
      headerName: 'Role',
      field: 'role',
      hide: false,
      cellRendererFramework: eventRecruiterActionRendererComponent,
      cellRendererParams: { type: 'role' },
    },

    {
      headerName: 'Delete',
      field: 'delete',
      hide: false,
      cellRendererFramework: eventRecruiterActionRendererComponent,
      cellRendererParams: { icon: 'delete', condition: this.actionCondition },
    },
  ];
  ngOnInit(): void {
    this.eventId = this.eventService.selectedEventId;
    this.eventStatus = localStorage.getItem('eventStatus');
    if (this.eventStatus === null || !this.eventService.selectedEventId)
      this.router.navigate(['/dashboard']);
  }

  openAddRecruiterDialog() {
    const dialog = this.dialog.open(AddRecruitersComponent, {
      // data: {
      //   recruiters: this.recruiters,
      // },
      panelClass: 'add-recruiters',
      backdropClass: 'backdrop-background',
    });
    dialog.afterClosed().subscribe((value) => {
      if (value) this.addRecruiterToEvent(value);
    });
  }

  addRecruiterToEvent(selectedRecruiter) {
    this.loading = true;
    // this.recruiters.push(selectedRecruiter);
    // console.log("id",selectedRecruiter.id);
    const data = {
      eventId: this.eventId,
      recruiterId: selectedRecruiter.id,
      eventRoles: ['RECRUITER'],
    };
    if (selectedRecruiter.id) {
      this.eventService.addRecrutersToEvent([data]).subscribe((res) => {
        this.refreshAgGrid.next();
        this.loading = false;
        this.sharedService.openSnackBar('Added successfully', '', 3000);

        // this.searchControl.patchValue('');
      });
    }
    this.newRecruiter = false;
  }
}
