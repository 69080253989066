<data-grid
  [columnDefs]="columnDefs"
  [tableName]="tableName"
  [buttonText]="buttonText"
  [levels]="levels"
  [customRowHeight]="customRowHeight"
  [buttonCondition]="buttonCondition"
  [searchCondition]="searchCondition"
  [interface]="interface"
  [refreshAgGrid]="refreshAgGrid.asObservable()"
  [pageNumber]="page"
  [pageSize]="size"
></data-grid>
<a
  href="javascript:void(0);"
  class="padLR8 refresh-btn"
  (click)="applyFiltersToTable()"
  matTooltip="Refresh"
  matTooltipClass="DSA_tooltip"
>
  <span class="material-icons-outlined">refresh</span>
</a>
