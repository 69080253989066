export enum AuthorityName {
  ROLE_INTERVIEWER = 'ROLE_INTERVIEWER',
  ROLE_SPOC = 'ROLE_SPOC',
  ROLE_SCHEDULER = 'ROLE_SCHEDULER',
  ROLE_RECRUITER = 'ROLE_RECRUITER',
  ROLE_SUPPORT_EXECUTIVE = 'ROLE_SUPPORT_EXECUTIVE',
  ROLE_PLATFORM_EXECUTIVE = 'ROLE_PLATFORM_EXECUTIVE',
  ROLE_EVENT_ADMIN = 'ROLE_EVENT_ADMIN',
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_USER = 'ROLE_USER',
  ROLE_CANDIDATE = 'ROLE_CANDIDATE',
  ROLE_HIRING_MANAGER = 'ROLE_HIRING_MANAGER',
  ROLE_COLLEGE_ADMIN = 'ROLE_COLLEGE_ADMIN',
  ROLE_VERIFIER = 'ROLE_VERIFIER',
  ROLE_VERIFIER_ADMIN = 'ROLE_VERIFIER_ADMIN',
  ROLE_MENTOR = 'ROLE_MENTOR',
}
