import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component, Inject, OnInit, ViewChild
} from '@angular/core';
import {
  FormBuilder, FormControl, FormGroup, Validators
} from '@angular/forms';
// import { RecruiterService } from '../recruiter.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { allowedCharactersRegex } from 'src/app/app.constants';
import { AccountService } from 'src/app/services/account.service';
import { EventService } from 'src/app/services/event.service';
import { SharedService } from 'src/app/services/shared.service';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
export interface Location {
  name: string;
}
@Component({
  selector: 'app-infy-copy-event',
  templateUrl: './infy-copy-event.component.html',
  styleUrls: ['./infy-copy-event.component.scss'],
})
export class InfyCopyEventComponent implements OnInit {
  @ViewChild(MatSelect, { static: false }) selectRef: MatSelect;
  searchControl = new FormControl();
  createEvent: FormGroup;

  id: any;
  loading: boolean = true;
  showTable: boolean = false;
  disableCreate: boolean;
  event: any;
  visible = true;
  selectable = true;
  loader = false;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  locationList: Location[] = [];
  RoleList = ['HEAD_RECRUITER', 'RECRUITER', 'SPOC'];
  recruiters = [];
  availableRecruiters = [];
  RoleControl = new FormControl();
  selectedRecruiter: any;
  availableRecruitersObs: Observable<any[]>;
  selectedJob: any;
  generateReportLoader = false;
  allRecruiters: any;
  eventTypeArray = ['FRESHER', 'LATERAL', 'IJP', 'LATERAL_DYNAMIC'];
  originalName: any;
  activeLink: string;
  reStructure$: Observable<boolean>;
  category: string;
  reportIntervalId;
  downloadReport;
  categoryId: number;
  FeatureName = FeatureName;
  PermissionType = PermissionType;

  constructor(
    private fb: FormBuilder,
    private navRouter: Router,
    public accountService: AccountService,
    private eventService: EventService,
    public dialogRef: MatDialogRef<InfyCopyEventComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: any },
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.refreshPage();
  }

  refreshPage() {
    this.createEvent = this.fb.group({
      eventName: [
        '',
        [Validators.required, Validators.pattern(allowedCharactersRegex)],
      ],
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      eventType: ['', Validators.required],
      copyJob: [true, Validators.required],
      copyRecruiters: [true, Validators.required],
      copySettings: [true, Validators.required],
      copyTemplates: [true, Validators.required],
      copyPanel: [true, Validators.required],
    });

    this.id = this.data.id;

    // let isRoleScheduler = this.accountService
    //   .getAccountLocal()
    //   .authorities.includes('ROLE_SCHEDULER');
    this.loadPage();
  }

  loadPage() {
    this.eventService.getEventById(this.id).subscribe(
      (event) => {
        this.locationList = [];
        this.event = event;

        // let isRoleScheduler = this.accountService
        //   .getAccountLocal()
        //   .authorities.includes('ROLE_SCHEDULER');

        this.createEvent.patchValue({
          eventName: this.event.name,
          fromDate: this.event.fromDate,
          toDate: this.event.toDate,
          eventType: this.event.eventType,
        });

        let locations = this.event.locations.split(',');
        for (let location of locations) {
          this.locationList.push({ name: location });
        }
      },
      () => {},
      () => {
        this.loading = false;
      }
    );
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our Location
    if ((value || '').trim()) {
      this.locationList.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }
  remove(location: Location): void {
    const index = this.locationList.indexOf(location);

    if (index >= 0) {
      this.locationList.splice(index, 1);
    }
  }

  addEvent() {
    let locationString: string = '';
    for (let location of this.locationList) {
      locationString = locationString + ',' + location.name.toString();
    }
    locationString = locationString.slice(1);

    const data = {
      originalEventId: this.id,
      fromDate: this.formatDate(this.createEvent.controls.fromDate.value),
      toDate: this.formatDate(this.createEvent.controls.toDate.value),
      eventType: this.createEvent.controls.eventType.value
        ? this.createEvent.controls.eventType.value
        : '',
      locations: locationString,
      name: this.createEvent.controls.eventName.value,
      copyJob: this.createEvent.controls.copyJob.value,
      copyRecruiters: this.createEvent.controls.copyRecruiters.value,
      copyPanel: this.createEvent.controls.copyPanel.value,
      copySettings: this.createEvent.controls.copySettings.value,
      copyTemplates: this.createEvent.controls.copyTemplates.value,
    };

    if (data.name != '' && data.locations != '' && data.eventType != '') {
      this.loader = true;
      this.eventService.duplicateEvent(data).subscribe(
        (res: any) => {
          this.loader = false;
          this.sharedService.openSnackBar(
            ` ${this.event.name} Duplicate Created Sucessfully`,
            'Ok'
          );
          localStorage.setItem('eventStatus', res.eventStatus);
          this.navRouter.navigate([`/create-event/${res.id}/jobs`]);
          this.dialogRef.close(true);
        },
        () => {
          this.loader = false;
          this.sharedService.openSnackBar('Request Failed', 'Ok');
        }
      );
    } else {
      if (data.name === '') {
        this.sharedService.openSnackBar('Event name cannot be empty', 'Ok');
      } else if (data.locations === '') {
        this.sharedService.openSnackBar('Location cannot be empty', 'Ok');
      } else if (data.eventType === '') {
        this.sharedService.openSnackBar('Event Type cannot be empty', 'Ok');
      }
    }
  }
  formatDate(date): string {
    const _date = new Date(date);
    const day = _date.getDate();

    const month = _date.getMonth() + 1;
    const year = _date.getFullYear();

    let formattedDate =
      year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);
    return formattedDate;
  }

}
