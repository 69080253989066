import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InterviewerService } from 'src/app/services/interviewer.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-panelist-unavailability-dialog',
  templateUrl: './panelist-unavailability-dialog.component.html',
  styleUrls: ['./panelist-unavailability-dialog.component.scss'],
})
export class PanelistUnavailabilityDialogComponent implements OnInit {
  currentTime: string;
  panelistUnavailableFromTime: string;

  isLoading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PanelistUnavailabilityDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { eventId: number; userMailId: string },
    private interviewerService: InterviewerService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.initializeTimePicker();
  }

  initializeTimePicker = () => {
    let currentDate = new Date();

    let hour = currentDate.getHours();
    let minutes = currentDate.getMinutes();
    let period: string = hour >= 12 ? 'PM' : 'AM';

    hour = period === 'PM' ? hour - 12 : hour;

    this.currentTime = `${hour}:${minutes} ${period}`;
  };

  setCustomTime = (time) => {
    this.panelistUnavailableFromTime = time;
  };

  parseSelectedStartTime = () => {
    const time = this.panelistUnavailableFromTime;
    let hours: number = parseInt(time.split(':')[0]);
    let minutes: number = parseInt(time.split(':')[1].split(' ')[0]);
    let period = time.split(' ')[1];

    if (period === 'PM') {
      hours += 12;
    }

    let startTime = new Date();
    startTime.setHours(hours);
    startTime.setMinutes(minutes);

    return startTime;
  };

  handleSubmitClick = () => {
    const startTime = this.parseSelectedStartTime();

    this.isLoading = true;
    const availabilityDetails = {
      email: this.data.userMailId,
      eventId: this.data.eventId,
      availabilityType: 'NOT_AVAILABLE',
      startTime,
    };
    this.interviewerService
      .setPanelistAvailabilityStatus(availabilityDetails)
      .subscribe(
        (res) => {
          this.sharedService.openSnackBar(
            'Unavailability time updated successfully!',
            '',
            3000
          );
          this.isLoading = false;
          this.dialogRef.close();
        },
        (err) => {
          this.isLoading = false;
          this.sharedService.openSnackBar(
            'Some error occurred, please try again later',
            '',
            3000
          );
        }
      );
  };
}
