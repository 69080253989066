import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ResumeUploadService {
  constructor(protected http: HttpClient) {}

  private headers = new HttpHeaders();
  // .set(
  //   'Authorization',
  //   'Bearer ' + localStorage.getItem('access_token')
  // );

  checkForexistingResumes(id: any): Observable<any> {
    let params = new HttpParams();
    // id= "5b1e4f59-3509-4a00-9d82-3e50900bc747";
    // params = params.append('userId', id);
    const opts = {
      headers: this.headers,
      params,
    };
    return this.http.get<any>(
      environment.CANDIDATEMS_API + '/candidate-infos/user',
      opts
    );
  }

  upload(formdata: FormData, docType: string = 'RP_RESUME'): Observable<any> {
    //console.log("Form data is "+formdata);
    let params = new HttpParams();
    params = params.append('doc_type', docType);

    const opts = {
      headers: this.headers,
      params,
    };

    return this.http.post<any>(
      environment.FILEMS_API + '/file/upload',
      formdata,
      opts
    );
  }

  uploadApplicationFile(formdata: FormData, applicationId: number): Observable<any> {
    const opts = {
      headers: this.headers,
    };

    return this.http.post<any>(
      environment.POSTMS_API + `/application-files/application/${applicationId}`,
      formdata,
      opts
    );
  }

  deleteApplicationFile(applicationFileId: number): Observable<any> {
    const opts = {
      headers: this.headers,
    };

    return this.http.delete(
      environment.POSTMS_API + `/application-files/${applicationFileId}`,
      opts
    );
  }

  getFile = (fileId) => {
    return this.http.get(environment.FILEMS_API + '/file/download/' + fileId, {
      headers: this.headers,
      observe: 'response',
      responseType: 'blob'
    });
  };
  getFileDetails = (fileId) => {
    return this.http.get(environment.FILEMS_API + '/getFileDetail/' + fileId, {
      headers: this.headers,
      observe: 'response',
      //responseType: 'blob',
    });
  };
}
