import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
// import { RecruiterService } from '../recruiter.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { startWith, map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MailTemplateSelectionComponent } from '../mail-template-selection/mail-template-selection.component';
import { SettingsComponent } from './../settings/settings.component';
import { ConfirmationComponent } from '../../../shared/components/confirmation/confirmation.component';
import { GenerateReportComponent } from '../generate-report/generate-report.component';
import * as fileSaver from 'file-saver';
import { CreateEventStateStorageService } from './create-event-state-storage.service';
import { AccountService } from 'src/app/services/account.service';
import { ReportGenerationService } from 'src/app/services/report-generation.service';
import { FileService } from 'src/app/services/file.service';
import { EventService } from 'src/app/services/event.service';
import { JobService } from 'src/app/services/job.service';
import { RoutesService } from 'src/app/services/routes.service';
import { SharedService } from 'src/app/services/shared.service';
import { RecruiterService } from '../../../services/recruiter.service';
import { allowedCharactersRegex } from 'src/app/app.constants';
import { InfyDynamicDashboardComponent } from '../infy-dynamic-dashboard/infy-dynamic-dashboard.component';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
import { EventTypeEnum } from 'src/app/shared/model/event.model';
export interface Location {
  name: string;
}
@Component({
  selector: 'app-infy-create-event',
  templateUrl: './infy-create-event.component.html',
  styleUrls: ['./infy-create-event.component.scss'],
})
export class InfyCreateEventComponent implements OnInit, OnDestroy {
  @ViewChild(MatSelect, { static: false }) selectRef: MatSelect;
  @ViewChild('locationChipList') locationChipList;

  searchControl = new FormControl();
  createEvent: FormGroup;
  updateEvent: FormGroup;
  id: any;
  eventStatus: any;
  loading: boolean = true;
  error: boolean = false;
  showTable: boolean = false;
  event: any;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  locationList: Location[] = [];
  RoleList = ['HEAD_RECRUITER', 'RECRUITER', 'SPOC'];
  recruiters = [];
  availableRecruiters = [];
  RoleControl = new FormControl();
  selectedRecruiter: any;
  availableRecruitersObs: Observable<any[]>;
  selectedJob: any;
  disableFields = false;
  generateReportLoader = false;
  allRecruiters: any;
  eventTypeArray = Object.values(EventTypeEnum);

  links = [
    {
      link: 'jobs',
      label: 'Scholarships',
      disabled: false,
      hide: !this.accountService.hasFeatureAccess(
        FeatureName.EVENT_JOBS,
        PermissionType.READ
      ),
    },
    {
      link: 'panelists',
      label: 'Panelists',
      disabled: false,
      hide: !this.accountService.hasFeatureAccess(
        FeatureName.EVENT_PANELIST,
        PermissionType.READ
      ),
    },
    {
      link: 'recruiters',
      label: 'Recruiters',
      disabled: false,
      hide: !this.accountService.hasFeatureAccess(
        FeatureName.EVENT_RECRUITER,
        PermissionType.READ
      ),
    },
    { link: 'schedule', label: 'Schedule', disabled: true, hide: false },
    {
      link: 'interviews',
      label: 'Interviews',
      disabled: false,
      hide: false,
    },
    {
      link: 'schedule-status',
      label: 'Schedule Status',
      disabled: false,
      hide: true,
    },
  ];

  activeLink: string;
  reStructure$: Observable<boolean>;
  category: string;
  reportIntervalId;
  downloadReport;
  categoryId: number;
  locationError: boolean = false;
  FeatureName = FeatureName;
  PermissionType = PermissionType;

  constructor(
    private fb: FormBuilder,
    private router: ActivatedRoute,
    private navRouter: Router,
    private dialog: MatDialog,
    private createEventStateStorage: CreateEventStateStorageService,
    private recruiterService: RecruiterService,
    public accountService: AccountService,
    private reportGenerationService: ReportGenerationService,
    private fileService: FileService,
    private eventService: EventService,
    private jobService: JobService,

    private routesService: RoutesService,
    private sharedService: SharedService
  ) {
    this.availableRecruitersObs = this.searchControl.valueChanges.pipe(
      // tslint:disable-next-line: deprecation
      startWith(null),
      map((recruiter: string | null) =>
        recruiter
          ? this._filter(recruiter, this.availableRecruiters)
          : this.availableRecruiters?.slice()
      )
    );
  }
  // hide Condition For Dynamic DashBoard
  hideCondition: boolean = this.accountService.hasFeatureAccess(
    FeatureName.DYNAMIC_DASHBOARD,
    PermissionType.READ
  );

  ngOnInit(): void {
    //this.eventService.selectedEventId=localStorage.getItem('eventId');

    this.router.params.subscribe((params) => {
      this.refreshPage();
    });
  }

  ngOnDestroy() {
    if (this.activeLink)
      this.createEventStateStorage.activeTabLink = this.activeLink;
  }

  goToPrevPage = () => {
    const prevLink = localStorage.getItem('eventPagePrevLink');
    if (prevLink) this.navRouter.navigate([prevLink]);
    else this.navRouter.navigate(['/dashboard']);
  };

  refreshPage() {
    this.reStructure$ = this.routesService.reStructureRoutesTrigger();
    this.reStructure$.subscribe((d) => {
      if (d) {
        this.enableDisableRoutes(d);
      }
    });
    this.id = parseInt(this.router.snapshot.params.id);
    //console.log(this.id);
    this.eventStatus = localStorage.getItem('eventStatus');
    if (this.eventStatus === null && this.navRouter.url !== '/create-event/0')
      this.navRouter.navigate(['/dashboard']);
    if (this.eventStatus === 'CLOSED') this.searchControl.disable();
    this.createEvent = this.fb.group({
      eventName: [
        '',
        [Validators.required, Validators.pattern(allowedCharactersRegex)],
      ],
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      eventType: ['FRESHER', Validators.required],
    });

    this.eventService.selectedEventId = this.id;
    localStorage.setItem('eventId', this.id);

    this.category = 'EVENT';
    this.categoryId = this.id;
    this.selectedJob = this.jobService.getSelectedJob();
    this.disableFields = this.eventStatus === 'CLOSED';
    this.updateEvent = this.fb.group({
      eventName: [
        { value: '', disabled: this.disableFields },
        [Validators.required, Validators.pattern(allowedCharactersRegex)],
      ],
      fromDate: [
        { value: '', disabled: this.disableFields },
        Validators.required,
      ],
      toDate: [
        { value: '', disabled: this.disableFields },
        Validators.required,
      ],
      eventType: [{ value: '', disabled: this.disableFields }],
    });

    this.loadPage();
  }

  closeEvent = (id) => {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        text:
          "Are you sure you want to close this event? No further actions can be taken once an event is closed. <br/> On <strong class='font-red'>Yes</strong>, the Event will be closed",
      },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'Yes') {
        this.eventService.closeEvent(id).subscribe(
          (res: any) => {
            this.sharedService.openSnackBar('Event Closed', '', 3000);
            this.navRouter.navigate(['/dashboard']);
          },
          (error: any) => {
            this.sharedService.openSnackBar(
              "The event couldn't be closed, Event might have active interviews or candidates",
              '',
              4000
            );
          }
        );
      }
    });
  };
  enableDisableRoutes(val) {
    if (val) {
      this.selectedJob = this.jobService.getSelectedJob();
      if (this.selectedJob) {
        this.links.filter((a) => a.link === 'schedule')[0].disabled = false;
      } else {
        this.links.filter((a) => a.link === 'schedule')[0].disabled = true;
      }
    }
  }

  removeParent(thisElement) {}

  loadPage() {
    if (this.id == 0) {
      this.showTable = false;
      this.loading = false;
    } else {
      this.eventService.getEventById(this.id).subscribe(
        (event: any) => {
          if (event?.eventType === 'LATERAL_DYNAMIC' && this.hideCondition) {
            this.links[5]['hide'] = false;
          }
          this.locationList = [];
          this.event = event;
          this.eventService.setSelectedEvent(this.event);
          if (this.event.setupDone == false) {
            this.setupEventRedirect();
          }
          this.loading = false;

          this.updateEvent = this.fb.group({
            eventName: [
              { value: this.event.name, disabled: this.disableFields },
              [Validators.required, Validators.pattern(allowedCharactersRegex)],
            ],
            fromDate: [
              { value: this.event.fromDate, disabled: this.disableFields },
              Validators.required,
            ],
            toDate: [
              { value: this.event.toDate, disabled: this.disableFields },
              Validators.required,
            ],
            eventType: [
              {
                value: this.event.eventType ? this.event.eventType : '',
                disabled: this.disableFields,
              },
            ],
          });

          let locations = this.event.locations.split(',');

          for (let location of locations) {
            this.locationList.push({ name: location });
          }

          this.showTable = true;
        },
        () => {
          this.loading = false;
          this.error = true;
        },
        () => {
          this.loading = false;
        }
      );
    }
  }
  setupEventRedirect = () => {
    this.navRouter.navigate(['/event-setup/' + this.id]);
  };

  addEvent() {
    let locationString: string = '';
    for (let location of this.locationList) {
      locationString = locationString + ',' + location.name.toString();
    }
    locationString = locationString.slice(1);
    const data = {
      fromDate: this.formatDate(this.createEvent.controls.fromDate.value),
      toDate: this.formatDate(this.createEvent.controls.toDate.value),
      locations: locationString,
      name: this.createEvent.controls.eventName.value,
      eventType: this.createEvent.controls.eventType.value,
    };
    if (data.name != '' && data.locations != '') {
      this.loading = true;
      this.eventService.createEvent(data).subscribe(
        (res: any) => {
          // this.loading = false;
          this.sharedService.openSnackBar('Event Created Sucessfully', 'Ok');
          localStorage.setItem('eventStatus', res.eventStatus);
          this.navRouter.navigate([`/create-event/${res.id}/jobs`]);
        },
        () => {
          this.loading = false;
          this.sharedService.openSnackBar('Some error occured', '', 3000);
        }
      );
    } else {
      if (data.name === '') {
        this.sharedService.openSnackBar('Event name cannot be empty', 'Ok');
      } else if (data.locations === '') {
        this.sharedService.openSnackBar('Location cannot be empty', 'Ok');
      }
    }
  }

  modifyEvent = () => {
    let locationString: string = '';
    for (let location of this.locationList) {
      locationString = locationString + ',' + location.name.toString();
    }
    locationString = locationString.slice(1);

    const data = {
      id: this.event.id,
      fromDate: this.formatDate(this.updateEvent.controls.fromDate.value),
      toDate: this.formatDate(this.updateEvent.controls.toDate.value),
      locations: locationString,
      name: this.updateEvent.controls.eventName.value,
      eventType: this.updateEvent.controls.eventType.value,
    };
    //api call
    if (data.name != '' && data.locations != '') {
      this.eventService.updateEvent(data).subscribe((res) => {
        this.sharedService.openSnackBar('Event Updated Sucessfully', 'Ok');
        // this.navRouter.navigate(['/dashboard']);
        this.refreshPage();
      });
    } else {
      if (data.name === '') {
        this.sharedService.openSnackBar('Event name cannot be empty', 'Ok');
      } else if (data.locations === '') {
        this.sharedService.openSnackBar('Location cannot be empty', 'Ok');
      }
    }
  };

  formatDate(date): string {
    const _date = new Date(date);
    const day = _date.getDate();

    const month = _date.getMonth() + 1;
    const year = _date.getFullYear();

    let formattedDate =
      year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);
    return formattedDate;
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value.trim();

    // Add our Location
    if (value && value.match(allowedCharactersRegex)) {
      this.locationError = false;
      this.locationChipList.errorState = false;
      this.locationList.push({ name: value });
      if (input) {
        input.value = '';
      }
    } else {
      this.locationError = true;
      this.locationChipList.errorState = true;
    }
  }
  onInputBlur = (e) => {
    this.locationChipList.errorState = false;
  };

  onKeyUp = (e) => {
    if (!e.target.value) {
      this.locationChipList.errorState = false;
    }
  };

  remove(location: Location): void {
    const index = this.locationList.indexOf(location);

    if (index >= 0) {
      this.locationList.splice(index, 1);
    }
  }

  private _filter(value: string, listObj): any[] {
    const filterValue = value?.toString()?.toLowerCase();
    return listObj.filter(
      (a) =>
        Object.values(a).toString().toLowerCase().indexOf(filterValue) !== -1
    ) as any[];
  }

  checkInclusion = (data, dataList, key1, key2) => {
    let included = false;
    dataList.forEach((element) => {
      if (data[key1] === element[key2]) {
        included = true;
      }
    });

    return included;
  };

  selected(event: MatAutocompleteSelectedEvent) {
    this.selectRef = this.availableRecruiters.filter(
      (k) => k.email === event.option.value
    )[0];
    this.selectedRecruiter = this.selectRef;
  }

  // getRecruiterOfEvent() {
  //   this.recruiterService
  //     .getRecrutersListWithEventId(this.event.id)
  //     .subscribe((res) => {
  //       this.recruiters = res;
  //       this.getAvailableRecruiter();
  //     });
  // }

  generateReport = () => {
    const dialogRef = this.dialog.open(GenerateReportComponent, {
      data: { text: '* Optional', reportType: 'EVENT' },
      backdropClass: 'backdrop-background',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.loader = true;
        this.reportGenerationService
          .generateReportForEvent(
            [this.id],
            result['startDate'],
            result['endDate']
          )
          .subscribe(
            (res: any) => {
              // this.loader = false;
              this.generateReportLoader = true;
              if (res.status === 'PROCESSING') {
                this.sharedService.openSnackBar(
                  'Report Generation in Progress',
                  '',
                  3000
                );
                this.reportIntervalId = setInterval(() => {
                  this.reportGenerationService
                    .reportStatus(res.reqId)
                    .subscribe((res: any) => {
                      if (res.status === 'COMPLETED') {
                        this.fileService
                          .getFile(res.fileId)
                          .subscribe((res) => {
                            let temp = res.headers
                              .get('content-disposition')
                              .split('filename=')[1]
                              .split(';')[0];
                            let temp1 = temp.substring(1, temp.length - 1);
                            this.downloadReport = {
                              blob: res.body,
                              fileName: temp1,
                            };
                            this.generateReportLoader = false;
                          });
                        this.sharedService.openSnackBar(
                          'Report Generated',
                          '',
                          3000
                        );
                        clearInterval(this.reportIntervalId);
                      } else if (res.status === 'FAILED') {
                        this.generateReportLoader = false;
                        this.sharedService.openSnackBar(
                          'Generation Failed',
                          '',
                          3000
                        );
                        clearInterval(this.reportIntervalId);
                      }
                    });
                }, 20000);
              } else if (res.status === 'COMPLETED') {
                this.fileService.getFile(res.fileId).subscribe((res) => {
                  let temp = res.headers
                    .get('content-disposition')
                    .split('filename=')[1]
                    .split(';')[0];
                  let temp1 = temp.substring(1, temp.length - 1);
                  this.downloadReport = { blob: res.body, fileName: temp1 };
                  this.generateReportLoader = false;
                });
                this.sharedService.openSnackBar(
                  'Generation Completed',
                  '',
                  3000
                );
              } else if (res.status === 'FAILED') {
                this.generateReportLoader = false;
                this.sharedService.openSnackBar('Generation Failed', '', 3000);
              }
              // fileSaver.saveAs(
              //   res,
              //   'Report_Job_' + this.selectedJob.id + '.xlsx'
              // );
            },
            () => {
              // this.loader = false;
            }
          );
      }
    });
  };
  saveReport = () => {
    fileSaver.saveAs(this.downloadReport.blob, this.downloadReport.fileName);
  };

  manageTemplatesForEvent = () => {
    this.navRouter.navigate([
      `/mail-template-selection/${this.router.snapshot.params.id}`,
    ]);
  };

  onDynamicScheduleStatusClick = () => {
    this.dialog.open(InfyDynamicDashboardComponent, {
      disableClose: false,
      backdropClass: 'backdrop-background',
    });
  };
}
