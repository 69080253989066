import { OnChanges, SimpleChanges } from '@angular/core';
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/tslint/config */
import { Component, OnInit, Input } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { Visibility } from 'src/app/shared/model/enumerations/visibility.model';
import { FileserverService } from 'src/app/survey-master/survey/edit-backgroud-color/fileserver.service';
import { MediaObserver } from '@angular/flex-layout';
import { Theme, QuestionView } from 'src/app/shared/model/theme.model';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return control && control.invalid;
  }
}

@Component({
  selector: 'jhi-number-rating-preview',
  templateUrl: './number-rating.component.html',
  styleUrls: ['./number-rating.component.scss'],
})
export class NumberRatingPreviewComponent implements OnInit, OnChanges {
  @Input()
  currPage: any;
  @Input()
  question: any;
  @Input()
  questionIndex: any;
  @Input()
  responsePage: any;
  @Input()
  section: any;
  @Input()
  preference: any;
  @Input()
  theme: Theme;

  view = 'NORMAL';
  @Input()
  disabled: Boolean = false;
  @Input()
  nextElement: HTMLElement;

  matcher = new MyErrorStateMatcher();

  control: FormControl;

  startText: string;
  endText: string;
  fontColor: string;
  minValue: string;
  maxValue: string;
  useGradient: boolean;
  startColor: string;
  endColor: string;
  scale: string;
  options = [];
  constructor(
    public fileserverService: FileserverService,
    public media: MediaObserver
  ) {}

  ngOnInit() {
    this.control = new FormControl('');
    if (this.theme.questionView === QuestionView.TWO_COLUMNS) {
      this.view = 'TWO_COLUMNS';
    } else {
      this.view = 'NORMAL';
    }
    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response != null &&
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response.length > 0
    ) {
      // already some response present
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response = [];
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response.push('');
    }

    if (this.question.type.values.length > 0) {
      this.fontColor = this.getValue(this.question, 'TEXT_COLOR');
      this.startText = this.getValue(this.question, 'START_TEXT');
      this.endText = this.getValue(this.question, 'END_TEXT');
      this.minValue = this.getValue(this.question, 'MIN_VALUE');
      this.maxValue = this.getValue(this.question, 'MAX_VALUE');
      this.scale = this.getValue(this.question, 'SCALE');
      //
      if (this.getValue(this.question, 'USE_GRADIENT') === 'true') {
        this.useGradient = true;
      } else this.useGradient = false;
    }

    if (this.useGradient) {
      if (this.question.type.validationValues.length > 0) {
        this.startColor = this.getValidationValue(this.question, 'START_COLOR');
        this.endColor = this.getValidationValue(this.question, 'END_COLOR');
      } else {
        this.useGradient = false;
      }
    }
    this.options = [];
    for (
      let i = Number(this.maxValue) / Number(this.scale);
      i <= Number(this.maxValue);
      i += Number(this.maxValue) / Number(this.scale)
    ) {
      this.options.push({ value: i });
    }
    //
  }

  ngOnChanges(changes: SimpleChanges) {
    this.control = new FormControl('');
    if (this.theme.questionView === QuestionView.TWO_COLUMNS) {
      this.view = 'TWO_COLUMNS';
    } else {
      this.view = 'NORMAL';
    }
    if (
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response != null &&
      this.responsePage.sections[this.section].questions[this.questionIndex]
        .response.length > 0
    ) {
      // already some response present
    } else {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response = [];
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response.push('');
    }

    if (this.question.type.values.length > 0) {
      this.fontColor = this.getValue(this.question, 'TEXT_COLOR');
      this.startText = this.getValue(this.question, 'START_TEXT');
      this.endText = this.getValue(this.question, 'END_TEXT');
      this.minValue = this.getValue(this.question, 'MIN_VALUE');
      this.maxValue = this.getValue(this.question, 'MAX_VALUE');
      this.scale = this.getValue(this.question, 'SCALE');
      //
      if (this.getValue(this.question, 'USE_GRADIENT') === 'true') {
        this.useGradient = true;
      } else this.useGradient = false;
    }

    if (this.useGradient) {
      if (this.question.type.validationValues.length > 0) {
        this.startColor = this.getValidationValue(this.question, 'START_COLOR');
        this.endColor = this.getValidationValue(this.question, 'END_COLOR');
      } else {
        this.useGradient = false;
      }
    }
    this.options = [];
    for (
      let i = Number(this.maxValue) / Number(this.scale);
      i <= Number(this.maxValue);
      i += Number(this.maxValue) / Number(this.scale)
    ) {
      this.options.push({ value: i });
    }
  }

  downloadImage(path: string) {
    return this.fileserverService.getFileURLbyPath(path);
  }

  getCSSBackground() {
    const startColor =
      this.startColor === null || this.startColor === ''
        ? 'white'
        : this.startColor;
    const endColor =
      this.endColor === null || this.endColor === '' ? 'white' : this.endColor;
    //
    const colorScheme = startColor + ' , ' + endColor;
    if (this.media.isActive(['xs'])) {
      return {
        'background-image': 'linear-gradient( ' + colorScheme + ')',
      };
    }
    return {
      'background-image': 'linear-gradient( to right,' + colorScheme + ')',
    };
    // }
  }

  inputChanged(option: any) {
    if (!this.disabled) {
      this.responsePage.sections[this.section].questions[
        this.questionIndex
      ].response[0] = String(option.value);
      if (
        this.question.preference.isRequired === 1 &&
        (this.responsePage.sections[this.section].questions[this.questionIndex]
          .response[0] === '' ||
          this.responsePage.sections[this.section].questions[this.questionIndex]
            .response === null ||
          this.responsePage.sections[this.section].questions[this.questionIndex]
            .response === undefined)
      ) {
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].isValid = false;
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].validationMessage = 'This field value is required';
      } else {
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].isValid = true;
        this.responsePage.sections[this.section].questions[
          this.questionIndex
        ].validationMessage = '';
        if (this.nextElement != null) {
          this.nextElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          setTimeout(() => {
            if (this.nextElement.getElementsByTagName('input') != null)
              this.nextElement.getElementsByTagName('input')[0].focus();
          }, 400);
        }
      }

      this.logicPart();
    }
  }

  logicPart() {
    if (this.question.logics !== null && this.question.logics.length > 0) {
      for (let i = 0; i < this.question.logics.length; i++) {
        let apply = false;
        let condition = 'END';
        for (let r = 0; r < this.question.logics[i].rules.length; r++) {
          let rapply = false;

          if (this.question.logics[i].rules[r].op === 'EQ') {
            if (
              this.responsePage.sections[this.section].questions[
                this.questionIndex
              ].response[0] === this.question.logics[i].rules[r].value
            ) {
              rapply = true;
            }
          } else if (this.question.logics[i].rules[r].op === 'NEQ') {
            if (
              this.responsePage.sections[this.section].questions[
                this.questionIndex
              ].response[0] !== this.question.logics[i].rules[r].value
            ) {
              rapply = true;
            }
          } else if (this.question.logics[i].rules[r].op === 'LT') {
            if (
              Number(
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0]
              ) < Number(this.question.logics[i].rules[r].value)
            ) {
              rapply = true;
            }
          } else if (this.question.logics[i].rules[r].op === 'LTE') {
            if (
              Number(
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0]
              ) <= Number(this.question.logics[i].rules[r].value)
            ) {
              rapply = true;
            }
          } else if (this.question.logics[i].rules[r].op === 'GT') {
            if (
              Number(
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0]
              ) > Number(this.question.logics[i].rules[r].value)
            ) {
              rapply = true;
            }
          } else if (this.question.logics[i].rules[r].op === 'GTE') {
            if (
              Number(
                this.responsePage.sections[this.section].questions[
                  this.questionIndex
                ].response[0]
              ) >= Number(this.question.logics[i].rules[r].value)
            ) {
              rapply = true;
            }
          }

          if (r > 0) {
            if (condition === 'AND') {
              apply = apply && rapply;
            } else if (condition === 'OR') {
              apply = apply || rapply;
            }
          } else {
            apply = rapply;
          }
          condition = this.question.logics[i].rules[r].conjunction;
        }

        for (let a = 0; a < this.question.logics[i].actions.length; a++) {
          let foundIndex: number = null;

          const actionquestion = this.currPage.sections[
            this.section
          ].questions.find((ques, ind) => {
            if (
              ques.identifier === this.question.logics[i].actions[a].identifier
            ) {
              foundIndex = ind;
              return true;
            } else {
              return false;
            }
          });

          if (apply) {
            if (this.question.logics[i].actions[a].name === 'HIDE') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                this.responsePage.sections[this.section].questions[
                  foundIndex
                ].logicallyHidden = true;
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyHiddenBy.find(
                  (item) => item === this.question.id
                );
                if (exists === undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHiddenBy.push(this.question.id);
                }
              }
            } else if (this.question.logics[i].actions[a].name === 'SHOW') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyShownBy.find(
                  (item) => item === this.question.id
                );
                if (exists === undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyShownBy.push(this.question.id);
                }
                if (
                  this.responsePage.sections[this.section].questions[foundIndex]
                    .logicallyHiddenBy.length === 0
                ) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHidden = false;
                } else {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHidden = true;
                }
              }
            }
          } else {
            if (this.question.logics[i].actions[a].name === 'HIDE') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyHiddenBy.find(
                  (item) => item === this.question.id
                );
                if (exists !== undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyHiddenBy = this.responsePage.sections[
                    this.section
                  ].questions[foundIndex].logicallyHiddenBy.filter(
                    (item) => item !== this.question.id
                  );
                }
                if (
                  this.responsePage.sections[this.section].questions[foundIndex]
                    .logicallyHiddenBy.length === 0
                ) {
                  if (
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyShownBy.length === 0
                  ) {
                    if (
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].defaultVisibile
                    ) {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = false;
                    } else {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = true;
                    }
                  } else {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = false;
                  }
                }
              }
            } else if (this.question.logics[i].actions[a].name === 'SHOW') {
              if (actionquestion === undefined || actionquestion === null) {
                // nothing to hide show
              } else {
                this.responsePage.sections[this.section].questions[
                  foundIndex
                ].logicallyHidden = true;
                const exists = this.responsePage.sections[
                  this.section
                ].questions[foundIndex].logicallyShownBy.find(
                  (item) => item === this.question.id
                );
                if (exists !== undefined) {
                  this.responsePage.sections[this.section].questions[
                    foundIndex
                  ].logicallyShownBy = this.responsePage.sections[
                    this.section
                  ].questions[foundIndex].logicallyShownBy.filter(
                    (item) => item !== this.question.id
                  );
                }
                if (
                  this.responsePage.sections[this.section].questions[foundIndex]
                    .logicallyShownBy.length === 0
                ) {
                  if (
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHiddenBy.length === 0
                  ) {
                    if (
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].defaultVisibile
                    ) {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = false;
                    } else {
                      this.responsePage.sections[this.section].questions[
                        foundIndex
                      ].logicallyHidden = true;
                    }
                  } else {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = true;
                  }
                } else {
                  if (
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHiddenBy.length === 0
                  ) {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = false;
                  } else {
                    this.responsePage.sections[this.section].questions[
                      foundIndex
                    ].logicallyHidden = true;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  getValidationValue(question: any, param: string): string {
    const object = question.type.validationValues.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }

  getValue(question: any, param: string): string {
    const object = question.type.values.find(
      (item) => item.param.identifier === param
    );
    if (object === undefined) {
      return '';
    } else {
      return object.value;
    }
  }
}
