import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { JobRole } from 'src/app/shared/model/job-role.model';
import { JobService } from 'src/app/services/job.service';
import { SharedService } from 'src/app/services/shared.service';
export interface Tag {
  name: string;
}

export enum JobTypeOption {
  PP = 'PP',
  SES = 'SES',
  SE = 'SE',
  SE_TQ = 'SE_TQ',
  PP_IJP = 'PP_IJP',
  SES_IJP = 'SES_IJP',
  SE_CAMPUS = 'SE_CAMPUS',
  OE_CAMPUS = 'OE_CAMPUS',
  ST = 'ST',
  NEG = 'NEG',
  PEGA = 'PEGA',
  OTHERS = 'OTHERS',
}

export type FeedbackFormType = 'FRESHER' | 'SURVEY' | 'FORMIO';

@Component({
  selector: 'app-infy-create-job',
  templateUrl: './infy-create-job.component.html',
  styleUrls: ['./infy-create-job.component.scss'],
})
export class InfyCreateJobComponent implements OnInit {
  jobForm: FormGroup;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  job: any;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  experienceTypeOptions = [
    { value: 'FRESHER', title: 'Fresher' },
    { value: 'LATERAL', title: 'Lateral' },
  ];

  feedbackFormTypes = {
    OTHERS: ['SURVEY', 'FORMIO'],
    FRESHER: ['FRESHER'],
  };

  feedbackFormTypeOptions;

  keys = Object.keys;
  jobTypeOptions = JobTypeOption;
  // jobTypeOptions = [
  //   { value: 'PP', title: 'PP' },
  //   { value: 'SES', title: 'SES' },
  //   { value: 'SE', title: 'SE' },
  //   { value: 'SE_TQ', title: 'SE_TQ' },
  //   { value: 'PP_IJP', title: 'PP_IJP' },
  //   { value: 'NEG', title: 'NEG' },
  //   { value: 'PEGA', title: 'PEGA' },
  //   { value: 'OTHERS', title: 'OTHERS' },
  // ];

  isLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<InfyCreateJobComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { jobs: JobRole },
    private jobService: JobService,
    private sharedService: SharedService
  ) {
    this.jobForm = fb.group({
      id: [null],
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      domain: ['', [Validators.required]],
      category: ['', [Validators.required]],
      tags: [[], Validators.required],
      ratingScale: ['', [Validators.required]],
      experienceType: ['', [Validators.required]],
      organization: ['', [Validators.required]],
      jobLevel: [
        '',
        [Validators.required, Validators.pattern(/^[1-9][A-Z]?$/)],
      ],
      type: ['', [Validators.required]],
      feedbackFormType: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.job = this.data.jobs;

    this.jobForm.controls.type.valueChanges.subscribe((val) => {
      this.jobForm.controls.feedbackFormType.setValue('');
      if (val === 'OTHERS') {
        this.feedbackFormTypeOptions = this.feedbackFormTypes.OTHERS;
      } else {
        this.feedbackFormTypeOptions = this.feedbackFormTypes.FRESHER;
      }
    });

    this.jobForm.patchValue({
      ...this.data.jobs,
      type: this.data.jobs.type ? this.data.jobs.type : 'OTHERS',
      tags: this.data.jobs.tags
        ? this.data.jobs.tags.split(',').filter((a) => a)
        : [],
    });
  }

  get tags() {
    return this.jobForm.get('tags');
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our Location
    if ((value || '').trim()) {
      this.tags.setValue([...this.tags.value, value.trim()]);
      this.tags.updateValueAndValidity();
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(tag: string): void {
    const index = this.tags.value.indexOf(tag);

    if (index >= 0) {
      this.tags.value.splice(index, 1);
      this.tags.updateValueAndValidity();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  handleCreateUpdateClick = () => {
    this.isLoading = true;
    const value = this.jobForm.getRawValue();
    const data = {
      ...value,
      tags: value.tags?.join(','),
    };

    const apiToCall = data.id
      ? this.jobService.updateJobRole(data)
      : this.jobService.postJobRole(data);

    const successMessage = data.id
      ? 'Succesfully Updated'
      : 'Succesfully Created';

    apiToCall.subscribe({
      next: (res) => {
        this.sharedService.openSnackBar(successMessage, '', 3000);
        this.isLoading = false;
        this.dialogRef.close(res);
      },
      error: (err) => {
        this.sharedService.openSnackBar(
          'Something went wrong. Please try again.',
          '',
          3000
        );
        this.isLoading = false;
      },
    });
  };
}
