import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MailTemplateStateStorageService {
  page = 0;
  size = 10;
  constructor() {}  
}
