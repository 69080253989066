import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CandidateService } from 'src/app/services/candidate.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-candidate-id-dialog-box',
  templateUrl: './candidate-id-dialog-box.component.html',
  styleUrls: ['./candidate-id-dialog-box.component.scss']
})
export class CandidateIdDialogBoxComponent implements OnInit {
  applicationId : number;
  candidateData:any;
  isLoading=true;
  candidateForm: FormGroup;
  candidateDataFromApi:any;
  cirId:number;
  constructor(
    public dialogRef: MatDialogRef<CandidateIdDialogBoxComponent>,
    private fb: FormBuilder,
    private candidateService: CandidateService,
    private sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }
  
  ngOnInit(): void {
    this.candidateData=this.data.data;
    this.applicationId=this.candidateData.applicationId;
    this.cirId=this.candidateData.cirId;
    this.candidateService.getCandidateApplicationId(this.cirId).subscribe(
      {
        next: (res) => {
          this.candidateDataFromApi=res;
          this.isLoading=false;
        },
        error: (err) => {
         console.log("error occured");
        },
      }
    );
  }
  UpdateCandidateId(){
    this.isLoading=true;
    this.candidateDataFromApi.applicationId=this.applicationId;
    this.candidateService.updateCandidateApplicationId(this.candidateDataFromApi).subscribe(
      {
        next: (res) => {
          this.isLoading=false;
          this.sharedService.openSnackBar(
            " Data updated successfully, please refresh to see updated value",
            ""
          );
          this.dialogRef.close();
        },
        error: (err) => {
          this.isLoading=false;
          this.sharedService.openSnackBar(
            " Error occured, please try again.",
            ""
          );
         
        },
      }
    );
  }
}
