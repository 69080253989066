<div class="container-fluid marB32">
  <form [formGroup]="personalDetailsForm">
    <div class="row marB16">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <h2 class="DSA_wb_h2Header web-GreyDarkest DSA_wb_text-1line">
          Personal Details
        </h2>
        <a
          *ngIf="!readOnly"
          href="javascript:void(0);"
          class="whiteIcon-Circle mustard-icon"
          matTooltip="Save"
          matTooltipClass="DSA_tooltip"
          (click)="submitDetails()"
        >
          <i class="icon x-24 icon_save"></i>
        </a>
      </div>
    </div>
    <div class="row marT4">
      <div class="col-md-4">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <mat-label>Marital Status</mat-label>
          <mat-select formControlName="maritalStatus">
            <mat-option
              *ngFor="let maritalStatus of maritalStatusList"
              [value]="maritalStatus"
            >
              {{ maritalStatus }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <input
            matInput
            placeholder="Nationality"
            formControlName="nationality"
          />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <input
            matInput
            [matDatepicker]="dob"
            placeholder="DOB"
            formControlName="dob"
          />
          <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
          <mat-datepicker #dob></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <input
            matInput
            type="number"
            placeholder="Annual Family Income"
            formControlName="familyAnnualIncome"
          />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <input matInput placeholder="Domicile" formControlName="domicile" />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <input matInput placeholder="Religion" formControlName="religion" />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <input matInput placeholder="Hobby" formControlName="hobby" />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <input
            matInput
            placeholder="Achievements"
            formControlName="achievements"
          />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <input
            matInput
            placeholder="Orientation"
            formControlName="orientation"
          />
        </mat-form-field>
      </div>
      <!-- <div class="col-md-4">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <input
            matInput
            placeholder="Spouse Employer"
            formControlName="spouseEmployer"
          />
        </mat-form-field>
      </div> -->
      <div class="col-md-4">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <input
            matInput
            placeholder="Credit History"
            formControlName="creditHistory"
          />
        </mat-form-field>
      </div>
      <!-- <div class="col-md-4">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <input
            matInput
            placeholder="Criminal Record"
            formControlName="criminalRecord"
          />
        </mat-form-field>
      </div> -->
    </div>
  </form>
</div>
