import { Injectable } from '@angular/core';
import { SERVER_API_URL } from 'src/app/app.constants';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class SurveyResponsesService {
  public resourceUrlPublishedSurvey = SERVER_API_URL + '/services/answers/api/published-surveys/survey';
  public resourceUrlAnswers = SERVER_API_URL + '/services/answers/api/survey-responses/published-survey';

  constructor(protected http: HttpClient) {}

  getAllPublishedSurveys(surveyId: any, queryString = '') {
    // querystring for pagination
    return this.http.get(`${this.resourceUrlPublishedSurvey}?id=${surveyId}&${queryString}`, { observe: 'response' });
  }

  findPublishedSurvey(id: any) {}

  getAllResponses(publishedSurveyId: any, queryString = '') {
    return this.http.get(`${this.resourceUrlAnswers}?id=${publishedSurveyId}&${queryString}`, { observe: 'response' });
  }

  deleteAllResponses(publishedSurveyId: any, queryString = '') {
    return this.http.delete(`${this.resourceUrlAnswers}/${publishedSurveyId}?${queryString}`, { observe: 'response' });
  }
}
