<app-loader [display]="loader"></app-loader>

<div class="container-fluid layout" *ngIf="!loader && !error">
  <div class="clearfix DSA_mainCtr ctr_main" id="mainCtr">
    <div class="container-fluid">
      <div class="DSA_rightMainContent nomarL_mb" id="mainrightCnt">
        <div class="DSA_innerContentWrapper">
          <div class="row">
            <form [formGroup]="documentFormGroup">
              <div class="col-lg-12">
                <div class="DSA_contentLeftCol">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="DSA_panel">
                        <div class="DSA_panelBody padTB16 padLR24">
                          <div
                            class="d-flex justify-content-start align-items-center mb-3"
                          >
                            <div class="ml-3 mr-3" role="button">
                              <i
                                class="icon x-24 back-icon"
                                [routerLink]="['/candidate-interview-rounds']"
                              >
                              </i>
                            </div>
                            <div class="h3">
                              {{ selectedCandidate.name }}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-4 col-md-4">
                              <mat-form-field class="DSA_Wb_custom-form-field">
                                <mat-label>Can ID</mat-label>
                                <input
                                  matInput
                                  placeholder="Candidate Id"
                                  readonly
                                  value="{{
                                    selectedCandidate.candidateSourceId
                                      ? selectedCandidate.candidateSourceId
                                      : selectedCandidate.candidateId
                                  }}"
                                />
                              </mat-form-field>
                              <!-- </mat-form-field> -->
                            </div>
                            <div class="col-lg-4 col-md-4">
                              <mat-form-field class="DSA_Wb_custom-form-field">
                                <mat-label>Name</mat-label>
                                <input
                                  matInput
                                  placeholder="Name"
                                  readonly
                                  value="{{ selectedCandidate.name }}"
                                />
                              </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-4">
                              <mat-form-field class="DSA_Wb_custom-form-field">
                                <mat-label>Email ID</mat-label>
                                <input
                                  matInput
                                  placeholder="Email"
                                  readonly
                                  value="{{ selectedCandidate.email }}"
                                />
                              </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-4">
                              <mat-form-field class="DSA_Wb_custom-form-field">
                                <mat-label>Phone</mat-label>
                                <input
                                  matInput
                                  placeholder="Mobile Number"
                                  readonly
                                  value="{{ selectedCandidate.phone }}"
                                />
                              </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-4">
                              <mat-form-field class="DSA_Wb_custom-form-field">
                                <mat-label>Application Id</mat-label>
                                <input
                                  matInput
                                  placeholder="Application id"
                                  readonly
                                  value="{{
                                    selectedCandidate.applicationId
                                      ? selectedCandidate.applicationId
                                      : ''
                                  }}"
                                />
                              </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-4">
                              <mat-form-field class="DSA_Wb_custom-form-field">
                                <mat-label>Unit</mat-label>
                                <input
                                  matInput
                                  placeholder="Unit"
                                  readonly
                                  value="{{
                                    selectedCandidate.unit
                                      ? selectedCandidate.unit
                                      : ''
                                  }}"
                                />
                              </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-4">
                              <mat-form-field class="DSA_Wb_custom-form-field">
                                <mat-label>Sub Unit</mat-label>
                                <input
                                  matInput
                                  placeholder="Sub Unit"
                                  readonly
                                  value="{{
                                    selectedCandidate.subUnit
                                      ? selectedCandidate.subUnit
                                      : ''
                                  }}"
                                />
                              </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-4">
                              <mat-form-field class="DSA_Wb_custom-form-field">
                                <mat-label>Technology</mat-label>
                                <input
                                  matInput
                                  placeholder="Technology"
                                  readonly
                                  value="{{
                                    selectedCandidate.technology
                                      ? selectedCandidate.technology
                                      : ''
                                  }}"
                                />
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-12">
                              <mat-form-field
                                class="DSA_Wb_custom-form-field"
                                appearance="fill"
                              >
                                <mat-label>Job Opening</mat-label>
                                <mat-select formControlName="jobOpeningId">
                                  <mat-option
                                    *ngFor="let job of jobsInEvent"
                                    [value]="job.id"
                                  >
                                    {{ job.title }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-lg-4 col-md-4">
                              <mat-form-field
                                class="DSA_Wb_custom-form-field"
                                appearance="fill"
                              >
                                <mat-label>Round Type</mat-label>
                                <mat-select formControlName="roundType">
                                  <mat-option [value]="'TECHNICAL'">
                                    Technical
                                  </mat-option>
                                  <mat-option [value]="'HR'"> HR </mat-option>
                                  <mat-option [value]="'DESIGN'">
                                    Design
                                  </mat-option>
                                  <mat-option [value]="'MANAGEMENT'">
                                    Management
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="col-lg-8 col-md-8">
                              <mat-form-field class="DSA_Wb_custom-form-field">
                                <mat-label> Round Title </mat-label>
                                <input matInput formControlName="title" />
                              </mat-form-field>
                            </div>

                            <div class="col-lg-4 col-md-4">
                              <mat-form-field
                                class="DSA_Wb_custom-form-field  DSA_form_nofloatLabel "
                                appearance="fill"
                                
                              >
                                <mat-label> Platform Id </mat-label>
                                <mat-select formControlName="platFormId">
                                  <mat-option
                                    *ngFor="let platform of interviewPlatforms"
                                    [value]="platform"
                                  >
                                    {{ platform }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="col-8">
                              <app-event-panelist-select-input
                                *ngIf="showInterviewer"
                                [eventId]="eventId"
                                [panelistList]="interviewerArray"
                                [disabled]="editMode"
                                [label]="
                                  isInterviewerInputReqd()
                                    ? 'Interviewer'
                                    : 'Interviewer (Optional)'
                                "
                                [isRequired]="isInterviewerInputReqd()"
                                (panelistChange)="handlePanelistChange($event)"
                              >
                              </app-event-panelist-select-input>
                            </div>
                            <div class="w-100 padLR16">
                              <mat-card *ngIf="showInterviewer">
                                <mat-card-header>
                                  <mat-card-title
                                    >Find Panelist Availability</mat-card-title
                                  >
                                </mat-card-header>
                                <div class="row">
                                  <div class="col-4">
                                    <mat-form-field
                                      class="DSA_Wb_custom-form-field"
                                    >
                                      <mat-label
                                        >Select Interview Date</mat-label
                                      >
                                      <input
                                        *ngIf="showInterviewer"
                                        matInput
                                        ngxDaterangepickerMd
                                        placeholder="Choose date"
                                        formControlName="panelistDateSlot"
                                        [opens]="
                                          dateTimeRangePickerOptions.opens
                                        "
                                        [locale]="
                                          dateTimeRangePickerOptions.localeOffline
                                        "
                                        [showWeekNumbers]="
                                          dateTimeRangePickerOptions.showWeekNumbers
                                        "
                                        [autoApply]="
                                          dateTimeRangePickerOptions.autoApply
                                        "
                                        [closeOnAutoApply]="
                                          dateTimeRangePickerOptions.closeOnAutoApply
                                        "
                                        [singleDatePicker]="true"
                                        (change)="dateChange($event,true)"
                                      />
                                    </mat-form-field>
                                  </div>
                                </div>
                                <app-loader
                                  [display]="panelistAvailabilityLoader"
                                ></app-loader>
                                <div *ngIf="!panelistAvailabilityLoader">
                                  <table
                                    class="DSA_wb_custom-table marT8 marL8"
                                    *ngIf="slotTableArray.length !== 0"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Panelist email</th>
                                        <th>Available Slots</th>
                                      </tr>
                                    </thead>
                                    <tbody
                                      *ngIf="slotTableArray.panelistSlotDTO"
                                    >
                                      <tr
                                        *ngFor="
                                          let panelist of slotTableArray.panelistSlotDTO
                                        "
                                      >
                                        <td>{{ panelist.emailId }}</td>
                                        <td>
                                          <span
                                            *ngFor="
                                              let slots of panelist.slotDTO;
                                              last as isLast
                                            "
                                          >
                                            <mat-chip
                                              class="DSA_wb_chipStyle-Normal DSA_choiseChip DSA_wb_bluechip marL8"
                                              disableRipple="true"
                                              >{{
                                                slots.startTime | date: "h:mm a"
                                              }}
                                              -
                                              {{
                                                slots.endTime | date: "h:mm a"
                                              }}</mat-chip
                                            >
                                            <!-- <span *ngIf="!isLast"> ,</span> -->
                                          </span>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <div
                                    class="marL16"
                                    *ngIf="
                                      interviewerArray.length !== 0 &&
                                      slotTableArray.length === 0
                                    "
                                  >
                                    <h2
                                      class="DSA_wb_h2Header web-Me-PrimaryDark"
                                    >
                                      No Records found
                                    </h2>
                                  </div>
                                  <div
                                    class="marL16"
                                    *ngIf="
                                      interviewerArray.length == 0 &&
                                      slotTableArray.length === 0
                                    "
                                  >
                                    <h2
                                      class="DSA_wb_mainBodyTxt web-Me-PrimaryDark"
                                    >
                                      Select a Panelist to view Availability
                                    </h2>
                                  </div>
                                </div>
                                <!-- <div *ngIf="slotTableArray.length == 0">
                                No Panelist availability found
                              </div> -->
                              </mat-card>
                            </div>
                            <div class="col-lg-12 col-md-12 marT16">
                              <mat-form-field
                                *ngIf="
                                  ['WEBEX', 'FACE_TO_FACE'].includes(
                                    documentFormGroup?.get('platFormId')?.value
                                  )
                                "
                                class="DSA_Wb_custom-form-field"
                              >
                                <mat-label>{{
                                  documentFormGroup?.get("platFormId")
                                    ?.value === "WEBEX"
                                    ? "Platform URL"
                                    : "Address"
                                }}</mat-label>
                                <input matInput formControlName="platFormURL" />
                              </mat-form-field>
                            </div>

                            <div class="col-lg-12 col-md-12 marT16">
                              <mat-form-field class="DSA_Wb_custom-form-field">
                                <mat-label>Time</mat-label>
                                <input
                                  *ngIf="showInterviewer"
                                  matInput
                                  ngxDaterangepickerMd
                                  placeholder="Choose date"
                                  formControlName="date"
                                  [timePicker24Hour]="
                                    dateTimeRangePickerOptions.timePicker24Hour
                                  "
                                  [timePicker]="
                                    dateTimeRangePickerOptions.timePicker
                                  "
                                  [timePickerSeconds]="
                                    dateTimeRangePickerOptions.timePickerSeconds
                                  "
                                  [timePickerIncrement]="
                                    dateTimeRangePickerOptions.timePickerIncrement
                                  "
                                  [drops]="dateTimeRangePickerOptions.drops"
                                  [opens]="dateTimeRangePickerOptions.opens"
                                  [showDropdowns]="
                                    dateTimeRangePickerOptions.showDropdowns
                                  "
                                  [locale]="dateTimeRangePickerOptions.locale"
                                  [showWeekNumbers]="
                                    dateTimeRangePickerOptions.showWeekNumbers
                                  "
                                  [autoApply]="
                                    dateTimeRangePickerOptions.autoApply
                                  "
                                  [closeOnAutoApply]="
                                    dateTimeRangePickerOptions.closeOnAutoApply
                                  "
                                  (change)="dateChange($event,false)"
                                  readonly
                                />
                                <input
                                  *ngIf="!showInterviewer"
                                  matInput
                                  ngxDaterangepickerMd
                                  placeholder="Choose date"
                                  formControlName="date"
                                  [opens]="dateTimeRangePickerOptions.opens"
                                  [locale]="
                                    dateTimeRangePickerOptions.localeOffline
                                  "
                                  [showWeekNumbers]="
                                    dateTimeRangePickerOptions.showWeekNumbers
                                  "
                                  [autoApply]="
                                    dateTimeRangePickerOptions.autoApply
                                  "
                                  [closeOnAutoApply]="
                                    dateTimeRangePickerOptions.closeOnAutoApply
                                  "
                                  [singleDatePicker]="true"
                                  (change)="dateChange($event,false)"
                                />
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 mt-4">
                      <div>
                        <button
                          *ngIf="!editMode"
                          mat-flat-button
                          class="DSA_wb_primary-btn"
                          (click)="submit()"
                        >
                          Send
                        </button>
                        <button
                          *ngIf="editMode"
                          mat-flat-button
                          class="DSA_wb_primary-btn"
                          (click)="edit()"
                        >
                          Edit
                        </button>
                        <button
                          mat-flat-button
                          class="DSA_wb_secondary-btn marL32"
                          [routerLink]="['/candidate-interview-rounds']"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid layout" *ngIf="!loader && error">
  <div class="row">
    <div class="col-12">
      <div class="DSA_panel">
        <div
          class="DSA_panelBody padTB16 padLR24 d-flex flex-column align-items-center"
        >
          <img
            src="assets/images/no_data_found.svg"
            alt=""
            class="no-data-img"
          />
          <h4 class="marT16">
            Request timed out. Please try again after sometime.
          </h4>
          <button
            mat-flat-button
            class="DSA_wb_miniPrimary-btn marT32"
            [routerLink]="['/candidate-interview-rounds']"
          >
            Go Back
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
