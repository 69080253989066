import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { EventService } from '../../../services/event.service';
@Component({
  template: `<a
    class="DSA_wb_hyperLink-btn"
    style="text-decoration: none;"
    href="javascript:void(0)"
    [matTooltip]="message"
    matTooltipClass="DSA_tooltip"
    (click)="onClick()"
    >{{ value }}</a
  >`,
})
export class linkRendererComponent implements AgRendererComponent {
  value: any;
  link: any;
  message: any;
  eventStatus: any;
  params: any;
  eventId;
  constructor(private router: Router, private eventService: EventService) {}
  agInit(params: any): void {
    this.params = params;
    if (params.message) {
      this.message = params.message;
    } else {
      this.message = '';
    }
    this.value = params.value;
    if (!params.data) this.link = '';
    else {
      this.link = params.valueFormatted;
      if (params.data.eventStatus !== undefined) {
        this.eventStatus = params.data.eventStatus;
      }
      this.eventId = params.data.eventId;
    }
  }

  onClick() {
    if (this.eventStatus !== undefined) {
      localStorage.setItem(
        'eventStatus',
        this.eventStatus ? this.eventStatus : ''
      );
    }
    this.router.navigate([this.link]);
  }

  refresh(params: any): boolean {
    return false;
  }
}
