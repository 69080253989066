import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { FilterDialogComponent } from '../../filter-dialog/filter-dialog.component';

@Component({
  selector: 'app-filter-btn',
  templateUrl: './filter-btn.component.html',
  styleUrls: ['./filter-btn.component.scss'],
})
export class FilterBtnComponent implements OnInit {
  // this is the text that is passed (Filter)
  @Input() filterDisplayText = '';
  // this is the filter state that is passed initially undefined
  @Input() filterState = null;
  // this is the filter by columns that is passed initially an array of constants of size 6
  @Input() filterByColumnsAll: any;
  // this is the filter criteria get api that is passed initially a function
  @Input() filterCriteriaGetApi: any;
  @Input() showFrequency = true;
  @Output() refreshTableEvent = new EventEmitter<any>();

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
  }

  resetFilters() {
    this.filterState = null;
    this.refreshTableEvent.emit(this.filterState);
    // this.refreshAgGrid.next();
  }

  showResetFilters(): boolean {
    if (this.filterState) {
      if (Object.keys(this.filterState).length > 0) return true;
    }
    return false;
  }

  openFilterDialog() {
    const dialog = this.dialog.open(FilterDialogComponent, {
      data: {
        filterState: _.cloneDeep(this.filterState),
        filterByColumns: this.filterByColumnsAll,
        filterCriteriaGetApi: this.filterCriteriaGetApi,
        frequencyShow: this.showFrequency
      },
      autoFocus: false,
      disableClose: true,
      backdropClass: 'backdrop-background',
      panelClass: 'filter-panel',
    });

    dialog.afterClosed().subscribe((res) => {
      if (res !== false) {
        // this.page = 0;
        this.filterState = res;
        // console.log(this.filterState);
        // this.setFilterState(res);
        // this.refreshAgGrid.next();
        this.refreshTableEvent.emit(this.filterState);
      }
    });
  }
}
