<app-loader [display]="showDetailsPage == 0"></app-loader>
<div class="container-fluid padTB32">
  <div class="row" *ngIf="showDetailsPage == 1">
    <div
      class="col-md-10 mx-auto d-flex justify-content-start align-items-center padTB16"
    >
      <div
        class="mr-3"
        role="button"
        (click)="_location.back()"
        style="cursor: pointer"
      >
        <i class="icon x-24 back-icon"> </i>
      </div>
      <div class="DSA_wb_h2Header">Back</div>
    </div>
    <div [hidden]="true" class="col-md-10 fixed-height padT8 mx-auto">
      <!-- <app-loader [display]="isLoading"></app-loader> -->
      <app-application-card
        [job]="job"
        [ApplicationDetailFlag]="true"
        (cancelJob)="cancelJob($event, job)"
      ></app-application-card>

      <div class="DSA_panel rectangle marT32">
        <div class="DSA_panelBody padTB16 padLR24">
          <app-job-details-description-card
            [heading]="'Problem Statement Description'"
            [content]="job.opportunity?.about"
          >
          </app-job-details-description-card>
          <!-- <app-job-details-description-card
            [heading]="'Responsibilities'"
            [content]="job?.jobOpening?.responsibilities"
          >
          </app-job-details-description-card> -->
          <app-job-details-chip-card
            [heading]="'Skills'"
            [content]="job?.opportunity?.tags"
          >
          </app-job-details-chip-card>
          <!-- <app-job-details-description-card
            [heading]="'Requirements'"
            [content]="job?.jobOpening.requirement"
          ></app-job-details-description-card>
          <app-job-details-chip-card
            [heading]="'Tags'"
            [content]="job?.jobOpening.requisition.jobRole?.tags"
          >
          </app-job-details-chip-card> -->
        </div>
      </div>
      <!-- Add Uplaod Option for files in Future  -->
      <mat-accordion
        [hidden]="true"
        class="DSA_wb-cus-accordion"
        *ngIf="expandDocsAccordion"
      >
        <mat-expansion-panel [expanded]="expandDocsAccordion">
          <mat-expansion-panel-header
            [collapsedHeight]="'80px'"
            [expandedHeight]="'80px'"
          >
            <mat-panel-title>
              Upload Resume and supporting documents
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="container-fluid">
            <div class="DSA_rightMainContent nomarL_mb" id="mainrightCnt">
              <div class="DSA_innerContentWrapper">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="DSA_contentLeftCol DSA_baseLayout_center">
                      <h3 class="DSA_wb_h2Header marB16 marL8">
                        Add Additional Details
                      </h3>
                      <div class="row d-flex">
                        <div class="col-md-6 col-lg-5 col-xl-4 marT8">
                          <mat-card class="DSA_wb_card-widget">
                            <app-attach-files
                              [headingText]="'Upload Updated Resume'"
                              [files]="uploadFiles"
                              [acceptMultipleFiles]="false"
                              [headerClass]="'DSA_wb_h2Header'"
                              (fileChange)="handleFileChange($event)"
                            >
                            </app-attach-files>
                          </mat-card>
                        </div>

                        <div class="col-md-6 col-lg-5 col-xl-4 marT8">
                          <mat-card class="DSA_wb_card-widget">
                            <app-attach-files
                              [headingText]="'Upload LOR'"
                              [files]="uploadFilesLOR"
                              [acceptMultipleFiles]="false"
                              [headerClass]="'DSA_wb_h2Header'"
                              (fileChange)="handleFileChangeLOR($event)"
                            >
                            </app-attach-files>
                          </mat-card>
                        </div>

                        <div class="col-md-6 col-lg-5 col-xl-4 marT8">
                          <mat-card class="DSA_wb_card-widget">
                            <app-attach-files
                              [headingText]="'Upload SOP'"
                              [files]="uploadFilesSOP"
                              [acceptMultipleFiles]="false"
                              [headerClass]="'DSA_wb_h2Header'"
                              (fileChange)="handleFileChangeSOP($event)"
                            >
                            </app-attach-files>
                          </mat-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <div
          class="container-fluid"
          *ngIf="
            uploadFiles.length > 0 ||
            uploadFilesLOR.length > 0 ||
            uploadFilesSOP.length > 0
          "
        >
          <div class="row justify-content-end mar8">
            <button
              mat-flat-button
              class="DSA_wb_primary-btn"
              (click)="uploadUserFiles()"
            >
              Upload
            </button>
          </div>
        </div>
      </mat-accordion>
    </div>

    <div class="col-md-10 fixed-height padT8 mx-auto">
      <mat-accordion
        [hidden]="!this.files"
        class="DSA_wb-cus-accordion"
        *ngIf="expandDocsAccordion"
      >
        <mat-expansion-panel [expanded]="!expandDocsAccordion">
          <mat-expansion-panel-header
            [collapsedHeight]="'80px'"
            [expandedHeight]="'80px'"
          >
            <mat-panel-title> Download Documents (If any) </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="container">
            <div class="row align-items-center marTB32">
              <div
                *ngIf="true"
                class="justify-content-center text-center col-4"
              >
              
                <button
                  mat-flat-button
                  class="DSA_wb_miniSecondary-btn"
                  matTooltip="Download resume"
                  matTooltipClass="DSA_tooltip"
                  (click)="downloadFiles('resume')"
                  [disabled]="!resumeData"
                >
                <i class="icon x-24 download-icon" [hidden]="!resumeData" aria-label="download"></i> Resume
                </button>
              </div>

              <div *ngIf="true" class="col-4 text-center">
                <button
                  mat-flat-button
                  class="DSA_wb_miniSecondary-btn"
                  matTooltip="Download letter of recommendation"
                  matTooltipClass="DSA_tooltip"
                  (click)="downloadFiles('LOR')"
                  [disabled]="!lorData"
                >
                <i class="icon x-24 download-icon" [hidden]="!lorData" aria-label="download"></i> LOR
                </button>
              </div>
              <div *ngIf="true" class="col-4 text-center">
                <button
                  mat-flat-button
                  class="DSA_wb_miniSecondary-btn"
                  matTooltip="Download SOP"
                  matTooltipClass="DSA_tooltip"
                  (click)="downloadFiles('SOP')"
                  [disabled]="!sopData"
                >
                <i class="icon x-24 download-icon" [hidden]="!sopData" aria-label="download"></i> SOP
                </button>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion [hidden]="uploadDisable" class="DSA_wb-cus-accordion" *ngIf="expandDocsAccordion">
        <mat-expansion-panel [expanded]="expandDocsAccordion">
          <mat-expansion-panel-header
            [collapsedHeight]="'80px'"
            [expandedHeight]="'80px'"
          >
            <mat-panel-title>
              Upload Resume and supporting documents
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="container-fluid">
            <div class="DSA_rightMainContent nomarL_mb" id="mainrightCnt">
              <div class="DSA_innerContentWrapper">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="DSA_contentLeftCol DSA_baseLayout_center">
                      <h3 class="DSA_wb_h2Header marB16 marL8">
                        Add Additional Details
                      </h3>
                      <div class="row d-flex">
                        <div class="col-md-6 col-lg-5 col-xl-4 marT8">
                          <mat-card class="DSA_wb_card-widget">
                            <app-attach-files
                              [headingText]="'Upload Updated Resume'"
                              [files]="uploadFiles"
                              [acceptMultipleFiles]="false"
                              [headerClass]="'DSA_wb_h2Header'"
                              (fileChange)="handleFileChange($event)"
                            >
                            </app-attach-files>
                          </mat-card>
                        </div>

                        <div class="col-md-6 col-lg-5 col-xl-4 marT8">
                          <mat-card class="DSA_wb_card-widget">
                            <app-attach-files
                              [headingText]="'Upload LOR'"
                              [files]="uploadFilesLOR"
                              [acceptMultipleFiles]="false"
                              [headerClass]="'DSA_wb_h2Header'"
                              (fileChange)="handleFileChangeLOR($event)"
                            >
                            </app-attach-files>
                          </mat-card>
                        </div>

                        <div class="col-md-6 col-lg-5 col-xl-4 marT8">
                          <mat-card class="DSA_wb_card-widget">
                            <app-attach-files
                              [headingText]="'Upload SOP'"
                              [files]="uploadFilesSOP"
                              [acceptMultipleFiles]="false"
                              [headerClass]="'DSA_wb_h2Header'"
                              (fileChange)="handleFileChangeSOP($event)"
                            >
                            </app-attach-files>
                          </mat-card>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <div class="container-fluid">
          <div class="row justify-content-end mar8">
            <button
              mat-flat-button
              class="DSA_wb_primary-btn"
              (click)="uploadUserFiles()"
              [disabled]="uploadDisable"
            >
              Upload
            </button>
          </div>
        </div>
      </mat-accordion>
    </div>
  </div>

  <div class="row" *ngIf="showDetailsPage == 2">
    <div class="col-md-10 fixed-height padT8 mx-auto align-items-center">
      <h2>Invalid job application id</h2>
    </div>
  </div>

  <ng-template #secondDialog>
    <h2 matDialogTitle>Cancel Application</h2>
    <mat-dialog-content>
      <p>Do you want to withdraw the application?</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button
        class="DSA_wb_miniSecondary-btn marR8"
        mat-flat-button
        matDialogClose
      >
        Cancel
      </button>
      <button
        class="DSA_wb_miniPrimary-btn"
        mat-flat-button
        (click)="cancelApplication()"
        mat-dialog-close
      >
        Confirm
      </button>
    </mat-dialog-actions>
  </ng-template>
</div>
