
<app-loader [display]="isLoading"></app-loader>
<div *ngIf="!isLoading" class="fluid-container">
  <h3 matDialogTitle>Edit Application Id</h3>
  <div mat-dialog-content>
    <mat-form-field class="DSA_Wb_custom-form-field" appearance="fill">
      <mat-label>Application Id</mat-label>
      <input matInput type="tel" [(ngModel)]="applicationId" required placeholder="Application Id" />
      <mat-error>Application Id can't be blank</mat-error>
    </mat-form-field>
  </div>
  <mat-dialog-actions align="end" class="mt-2">
    <button mat-button class="DSA_wb_miniSecondary-btn" [mat-dialog-close]="false" >
      Cancel
    </button>
    <button mat-button class="DSA_wb_miniPrimary-btn" [disabled]="!applicationId" (click)="UpdateCandidateId()">Update</button>
  </mat-dialog-actions>
</div>
