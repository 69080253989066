import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/services/shared.service';

// type EntityResponseType = HttpResponse<IState>;
//create a new service for new component

@Injectable({ providedIn: 'root' })
export class HiringManagerService {
  private headers = new HttpHeaders().set('content-type', 'application/json');
  constructor(
    protected http: HttpClient,
    private sharedService: SharedService
  ) {}
  public getOppurtunityByID(id) {
    // return of(onejobDummy);
    return this.http.get(
      environment.POSTMS_API + '/opportunities/' + id,
      // body.toString(),
      //opts
      //{ headers: { skip: 'true' } }
    );
  }
  public getOpportunities(
    page: number,
    size: number,
    query: string,
    category: string,
    filters?,
    subCategory?: string,
    status?: string,
    
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);
    params = params.append('category', `${category}`);
    if (query) {
      params = params.append('s', `${query}`);
    }
     const body = this.sharedService.getUrlSearchParamsFromObject(filters);
     const urlEncodedHeader = new HttpHeaders()
     .set('content-type', 'application/x-www-form-urlencoded')
    //  .set('tenantId', '5');
    return this.http.post<any>(
      environment.SEARCHMS_API + '/posts/search',
      body,
      {
        headers: urlEncodedHeader ,
        params,
      }
    );
    // return this.http.get<any>(environment.POSTMS_API +'/opportunities');
  }

  public getEvaluationDetails(
   opportunityId
    
  ): Observable<any> {
    return this.http.get(
      environment.POSTMS_API + '/posts/' + opportunityId,
    );
  }

  getBaseCriterias(): Observable<any> {
    return this.http.get(environment.POSTMS_API + '/base-criteria');
  }

  postEligibilityCriteria= (formData): Observable<any> => {
    return this.http.post<any>(environment.POSTMS_API + '/eligibility-criteria', formData);
  }

  deleteEligibilityCriteria(id): Observable<any> {
    return this.http.delete(environment.POSTMS_API + `/eligibility-criteria/${id}`);
  }

  deleteProblemStatement(opportunityId : any): Observable<any> {
    return this.http.delete(environment.POSTMS_API + `/opportunities/${opportunityId}`);
  }

  updateEligibilityCriteria(id, formData): Observable<any> {
    return this.http.put(environment.POSTMS_API + `/eligibility-criteria/${id}`, formData);
  }
  // uploadUsersList(formData): Observable<any> {
  //   return this.http.post(environment.UploadUsersListUrl, formData);
  // }
  getUsersList(
    page: number,
    size: number,
    userName?: string
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);

    if (userName) {
      params = params.append('s', `${userName}`);
    }
    
    return this.http.get(environment.profileServiceUrl + 'api/user-role-infos?' + params);
  }
  addUserWithRole(data): Observable<any> {
    return this.http.post(environment.profileServiceUrl + 'api/user-role-infos/bulk?createUserIfMissing=true', data);
  }

  getOrganizations(): Observable<any>{
    return this.http.get(environment.ASSIGNMENTMS_API+'/organizations');
  }
}
