import { Injectable } from '@angular/core';
import { SERVER_API_URL } from 'src/app/app.constants';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AWSSecurityService {
  public presignedUrl = SERVER_API_URL + '/api/aws/presigned/upload';

  constructor(protected http: HttpClient) {}

  getPresignedUrl(ObjectKey: any) {
    const requestOption: any = {
      observe: 'response',
      responseType: 'text'
    };
    return this.http.get<any>(`${this.presignedUrl}/${ObjectKey}`, requestOption);
  }
}
