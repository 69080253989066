import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EducationDetails } from 'src/app/shared/model/Profile/educationDetails-model';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SharedService } from 'src/app/services/shared.service';
import { CandidateInfoService } from '../../../candidate/services/candidate-info.service';

@Component({
  selector: 'app-education-details',
  templateUrl: './education-details.component.html',
  styleUrls: ['./education-details.component.scss'],
})
export class TeamDetailsComponent implements OnInit {
  spinner: boolean = false;
  educationInfoForm: FormGroup;
  message: string;
  @Input() educationDetails: EducationDetails;
  @Output() newItemEvent = new EventEmitter<EducationDetails>();

 
  constructor(
    private fb: FormBuilder,
    private services: CandidateInfoService,
    private sharedService: SharedService //useful save only private educationInformationService: EducationDetailsService
  ) {}

  getDTOFormControls(): AbstractControl[] {
    return (<FormArray>this.educationInfoForm.get('educationHistoryDTOs'))
      .controls;
  }
  ngOnInit(): void {
    this.educationInfoForm = this.fb.group({
      id: [null, Validators.required],
      degree: [null, Validators.required],
      institute: [null, Validators.required],
      university: [null, Validators.required],
      specialization: [null, Validators.required],
      yearOfPassing: [null, Validators.required],
      educationHistoryDTOs: this.fb.array([
        this.fb.group({
          id: [null, Validators.required],
          degree: [null, Validators.required],
          institute: [null, Validators.required],
          university: [null, Validators.required],
          specialization: [null, Validators.required],
          yearOfPassing: [null, Validators.required],
          educationType: [null, Validators.required],
          cgpa: [null, Validators.required],
          fromDate: [null, Validators.required],
          toDate: [null, Validators.required],
          duration: [null, Validators.required],
          educationDetailsId: [null, Validators.required],
        }),
      ]),
    });
    this.patchResultList();
    // this.educationInfoForm.patchValue(this.educationDetails);
    console.log(this.educationInfoForm);
  }
  updateExtractedResume() {
    if (!this.educationDetails)
      this.educationDetails = this.educationInfoForm.value;
    else
      this.educationDetails = {
        ...this.educationDetails,
        ...this.educationInfoForm.value,
      };

    this.services
      .UpdateEducationDeatailsOfProfile(this.educationDetails)
      .subscribe({
        next: (res: any) => {
          this.educationDetails = res;
          this.sharedService.openSuccessSnackBar(
            'data updated successfully',
            ''
          );
        },
        error: (err: any) => {
          this.sharedService.openErrorSnackBar(
            'data update failed. Please try again',
            ''
          );
        },
      });

    this.newItemEvent.emit(this.educationDetails);
  }
  patchResultList() {
    for (
      let i = 0;
      i < this.educationDetails.educationHistoryDTOs.length - 1;
      i++
    ) {
      this.addDegree();
    }
    this.educationInfoForm.patchValue(this.educationDetails);
  }

  addDegree(): void {
    (this.educationInfoForm.get('educationHistoryDTOs') as FormArray).push(
      this.fb.group({
        id: [null, Validators.required],
        degree: [null, Validators.required],
        institute: [null, Validators.required],
        university: [null, Validators.required],
        specialization: [null, Validators.required],
        yearOfPassing: [null, Validators.required],
        educationType: [null, Validators.required],
        cgpa: [null, Validators.required],
        fromDate: [null, Validators.required],
        toDate: [null, Validators.required],
        duration: [null, Validators.required],
        educationDetailsId: [null, Validators.required],
      })
    );
  }
  save() {}
}
