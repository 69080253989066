<app-loader [display]="isLoading"></app-loader>
<div *ngIf="!isLoading">
  <form [formGroup]="evaluationFeedbackForm">
    <div class="DSA_panel" *ngIf="evaluationForm">
      <div class="DSA_panelBody padTB16 padLR24">
        <div class="text-center marB24" *ngIf="showTitle">
          <h4>{{ evaluationForm.title }}</h4>
          <p class="DSA_wb_mainBodyTxt2 web-GreyLight">
            {{ evaluationForm.description }}
          </p>
        </div>
        <formio
          *ngIf="evaluationForm?.templateEvaluationForm.formData"
          (ready)="onFormReady($event)"
          [form]="evaluationForm?.templateEvaluationForm.formData"
          [submission]="
            savedOrPrevFeedbackJSON ? savedOrPrevFeedbackJSON : { data: null }
          "
          (change)="onFeedbackChange($event)"
          [options]="options"
          (nextPage)="handleNextClick($event)"
          (prevPage)="handlePrevClick($event)"
          [readOnly]="
            readOnly ||
            evaluationFeedbackForm.controls.feedbackStatus.value === 'FINAL'
          "
        ></formio>

        <auto-save
          *ngIf="evaluationFeedbackForm.get('id').value"
          [storageKey]="storageKey"
          [storeItem]="evaluationFeedbackForm.getRawValue()"
          [saveInDB]="autoSaveInDB"
          [autoSave]="autoSave"
          (onDBSave)="onAutoSaveInDB()"
        ></auto-save>

        <!-- add attachment -->
        <div *ngIf="isFinalPage && !readOnly">
          <!-- <div *ngIf="sentBackToPanelist" class="marTB16">
            <app-infy-delete-snapshots
              [interviewId]="interviewId"
              (noUploadedImages)="handleNoImagesChange($event)"
            ></app-infy-delete-snapshots>
          </div>
          <div *ngIf="platformId != 'OFFLINE'" class="marTB16">
            <mat-card>
              <app-attach-files
                [files]="files"
                (fileChange)="handleFileChange($event)"
              ></app-attach-files>
            </mat-card>
          </div> -->
          <div class="row">
            <!-- <div class="col-md-6 offset-md-3 col-12">
              <mat-form-field
                appearance="fill"
                class="DSA_Wb_custom-form-field"
              >
                <mat-label>Result</mat-label>
                <mat-select
                  name="result"
                  formControlName="finalResult"
                  required
                  panelClass="DSA_wb-custom-select-panel"
                  class="DSA_wb-custom-select"
                >
                  <mat-option
                    *ngFor="let result of finalResultOptions"
                    [value]="result.code"
                  >
                    {{ result.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div
                *ngIf="
                  candidateSource == 'INTAP' &&
                  formType == 'HR' &&
                  ['REJECTED', 'ON_HOLD'].includes(
                    evaluationFeedbackForm.get('finalResult').value
                  )
                "
              >
                <mat-form-field
                  appearance="fill"
                  class="DSA_Wb_custom-form-field"
                >
                  <mat-label>Reason</mat-label>
                  <mat-select
                    name="reason"
                    formControlName="rejectionReason"
                    panelClass="DSA_wb-custom-select-panel"
                    class="DSA_wb-custom-select"
                  >
                    <mat-option
                      *ngFor="let reason of resultReason"
                      [value]="reason.key"
                    >
                      {{ reason.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div
                *ngIf="
                  candidateSource == 'INTAP' &&
                  formType != 'HR' &&
                  ['REJECTED'].includes(
                    evaluationFeedbackForm.get('finalResult').value
                  )
                "
              >
                <mat-form-field
                  appearance="outline"
                  class="DSA_Wb_custom-form-field"
                >
                  <mat-label>Rejection Reason (Optional)</mat-label>
                  <mat-select name="reason" formControlName="rejectionReason">
                    <mat-option>None</mat-option>
                    <mat-option
                      *ngFor="let reason of candidateRejectionReasonTech"
                      [value]="reason"
                    >
                      {{ reason }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="col-12">
              <app-panelist-declaration
                class="marT8"
                [isChecked]="
                  evaluationFeedbackForm.controls['panelistAcceptance'].value
                "
                (onDeclarationChange)="handleDeclarationChange($event)"
                [experienceType]="experienceType"
              ></app-panelist-declaration>
            </div> -->

            <div
              class="
                col-12
                marT16
                d-flex
                flex-column
                justify-content-center
                nopadLR
              "
            >
              <p
                class="DSA_wb_caption web-SecondaryRed DSA_wb_text-1line marB8"
                *ngIf="!isFormIOResponseValid"
              >
                Please make sure all the required fields (marked with *) are
                filled
              </p>
              <!-- <button
                mat-button
                class="DSA_primary-btn"
                [disabled]="evaluationFeedbackForm.invalid"
                (click)="submitFeedback()"
              >
                Submit
              </button> -->
              <div class="d-flex" *ngIf="showActions">
                <button
                  mat-flat-button
                  class="DSA_wb_miniPrimary-btn"
                  matTooltip="Approve Application"
                  matTooltipClass="DSA_tooltip"
                  (click)="handleFinalActionClick('VERIFIER_APPROVED')"
                  [disabled]="!this.isFormIOResponseValid"
                >
                  Approve
                </button>
                <button
                  mat-flat-button
                  class="DSA_wb_miniSecondary-btn marL8"
                  matTooltip="Reject Application"
                  matTooltipClass="DSA_tooltip"
                  (click)="handleFinalActionClick('VERIFIER_REJECTED')"
                  [disabled]="!this.isFormIOResponseValid"
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <app-error-message
    [display]="!evaluationForm"
    [errorText]="
      readOnly && isFeedbackNotPresent
        ? feedback
        : 'Uh-Oh! Some error occurred. Please try again.'
    "
  ></app-error-message>
</div>
