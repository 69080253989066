import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ResumeUploadService } from '../../services/resume-upload.service';
import { SharedService } from 'src/app/services/shared.service';
import * as fileSaver from 'file-saver';
import { CandidateInfoService } from '../../services/candidate-info.service';
import { of } from 'rxjs';
import { VerifyScholarshipDisbursedComponent } from 'src/app/shared/components/verify-scholarship-disbursed/verify-scholarship-disbursed.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { MpJobService } from 'src/app/services/mp-job.service';
import { DatePipe } from '@angular/common';
import { CandidateProfileService } from '../../candidate-profile/candidate-profile.service';
@Component({
  selector: 'app-application-card',
  templateUrl: './application-card.component.html',
  styleUrls: ['./application-card.component.scss'],
})
export class ApplicationCardComponent implements OnInit {
  applicationDetails = false;
  displayAttachment = false;
  @Input() ApplicationDetailFlag: string;
  @Input() job: any;
  @Input() role: string = '';
  @Input() teamLead: string = '';
  @Input() isPartofTeam: boolean = false;
  @Output() cancelJob = new EventEmitter();
  @Output() withdrawJob = new EventEmitter();

  constructor(
    private router: Router,
    private resumeUploadService: ResumeUploadService,
    private sharedService: SharedService,
    private candidateInfoService: CandidateInfoService,
    private dialog: MatDialog,
    private jobService: MpJobService,
    private candidateProfileService: CandidateProfileService
  ) {}

  ngOnInit(): void {
    if (this.ApplicationDetailFlag) this.applicationDetails = true;
    if (this.router.url === '/candidate/view-applications')
      this.displayAttachment = true;
  }

  getJobStatusBadgeClass = (jobStatus: String) => {
    switch (jobStatus) {
      case 'OPEN':
        return 'DSA_wb_closedchip';
      case 'CLOSE':
        return 'DSA_wb_errorchip';
      default:
        return 'DSA_wb_defaultchip';
    }
  };
  getApplicationStatusBadgeClass = (jobStatus: String) => {
    switch (jobStatus) {
      case 'ACCEPTED':
        return 'success-bg';
      case 'INTERVIEW_SCHEDULED':
        return 'inprogress-bg';
      case 'REJECTED':
        return 'danger-bg';
      case 'SUBMITTED':
        return 'info-bg';
      case 'WITHDRAWN':
        return 'warning-bg';
      default:
        return 'default-bg';
    }
  };

  apply = () => {
    this.cancelJob.emit(this.job.id);
  };
  viewJobdetails = (applicantId: number, jobId: number) => {
    this.router.navigate([
      '/candidate/view-application-details',
      applicantId,
      jobId,
    ]);
  };
  downloadFile = (f) => {
    //fileSaver.saveAs(img.blob, 'file.pdf');
  };

  getLatestUniqueCategories(applicationFiles) {
    if (!applicationFiles) return;
    const latestObjects = {};
    const sortedFiles = applicationFiles.sort((a, b) => {
      return (
        new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
      );
    });

    sortedFiles.forEach((file) => {
      if (!latestObjects[file.category]) {
        latestObjects[file.category] = file;
      }
    });

    return Object.values(latestObjects);
  }

  //download file function
  downloadFileCloud = (f, c) => {
    this.resumeUploadService.getFile(f).subscribe(
      (data) => {
        //wait till get content disposition header and then set name variable: TODO

        let fileBlob: any = new Blob([data.body], {
          type: data.headers.get('Content-Type'),
        });
        // console.log(fileBlob);
        console.log(data.headers);
        const contentDispositionHeader = data.headers.get(
          'content-disposition'
        );
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDispositionHeader);
        const filename =
          matches != null && matches[1] ? matches[1].replace(/['"]/g, '') : c;
        fileSaver.saveAs(fileBlob, filename);
      },
      (error) => {
        this.sharedService.openErrorSnackBar('Some Error Occured', '');
      }
    );
  };

  applicationResubmit = (jobid, jobStatus, subStatus, applicantId) => {
    // sets applicationID and jobId in candidate service to load the application and form data
    this.candidateProfileService.applicationId = applicantId;
    this.candidateProfileService.opportunityId = jobid;
    // if((jobStatus === 'SUBMITTED') && (subStatus === 'SYNOPSIS_REQUESTED'|| subStatus === 'SYNOPSIS_SUBMITTED')){
    //   this.router.navigate([`/candidate/application/${jobid}`]);
    // }else{
    //   this.router.navigate([`/candidate/application-stepper/${jobid}`], {
    //     state: { checkForLocalStepState: true },
    //   });
    // }
    if (this.isPartofTeam && this.role !== 'TEAM_LEAD') {
      // console.log(this.isPartofTeam,this.role)
      let text = `<b>You are already part of a team. <br/> For any queries :</b><br/> <b>Please Contact your Team Lead : </b> ${this.teamLead}`;
      this.displayConfirmation('Information', text, 'Understood');
      return;
    }
    this.router.navigate([`/candidate/application/edit/${jobid}`], {
      state: { checkForLocalStepState: true },
    });
  };

  withdraw = (applicationId) => {
    this.cancelJob.emit(applicationId);
  };

  acceptProvisionalOfferLetter() {
    this.router.navigate([
      `/candidate/provisional-offer-letter/${this.job.id}`,
    ]);
  }

  downloadProvisionalOfferLetter() {
    let documentId = this.job?.issuedDocuments?.[0]?.id;
    this.candidateInfoService.fileDownload(documentId).subscribe({
      next: (data) => {
        let documentFileId = data.documentFileId;
        this.fileDetailsToDownload(documentFileId);
      },
      error: (err) => {
        this.sharedService.openErrorSnackBar(
          'Something Went Wrong! Please try again!',
          ' '
        );
      },
    });
  }

  fileDetailsToDownload(documentFileId) {
    this.resumeUploadService.getFileDetails(documentFileId).subscribe({
      next: (data: any) => {
        let fileName = data.body.sourceName;
        this.downloadFileCloud(documentFileId, fileName);
      },
      error: (err) => {
        this.sharedService.openErrorSnackBar(
          'Something Went Wrong! Please try again!',
          ' '
        );
      },
    });
  }

  verifyScholarshipDisbursed() {
    const formattedDate = new DatePipe('en-US').transform(
      this.job.scholarshipDisbursements[0].transactionDate,
      'yyyy-MM-dd'
    );
    const dialogRef = this.dialog.open(VerifyScholarshipDisbursedComponent, {
      data: {
        title: `Confirm Scholarship Payment`,
        transactionDate: formattedDate,
        transactionAmount:
          this.job.scholarshipDisbursements[0].transactionAmount,
      },
      autoFocus: false,
      backdropClass: 'backdrop-background',
      minWidth: '500px',
      maxWidth: '555px',
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        console.log('inside application-card-component');
        console.log(res);

        const d_ID = this.job.scholarshipDisbursements[0].id;

        console.log(d_ID);

        this.candidateInfoService
          .verifyScholarshipDisbursed(res, d_ID)
          .subscribe({
            next: (res) => {
              if (res.transactionStatus === 'RECEIVED') {
                const updateStatus = {};
                this.sharedService.openSuccessSnackBar('Verified', '');
                updateStatus[this.job?.id] = {
                  applicationStatus: 'SCHOLARSHIP_RECEIVED',
                  actionComments: 'The scholarship has been received.',
                };
                this.jobService.updateJobApplication(updateStatus).subscribe({
                  next: (res) => {
                    this.sharedService.openSuccessSnackBar(
                      'Status updated to scholarship received',
                      ''
                    );
                  },
                  error: (err) => {
                    this.sharedService.openErrorSnackBar(
                      'Failed to Update the Status to scholarship received',
                      ''
                    );
                  },
                });
              } else {
                this.sharedService.openErrorSnackBar(
                  'Transaction Id is not valid',
                  ''
                );
              }
            },
            error: (err) => {
              this.sharedService.openErrorSnackBar(
                'Something went wrong, please try again later.',
                ''
              );
            },
          });

        // this.jobService.updateDisbursedAmount(this.disbursementId,this.disbursementAmount).subscribe({
        //   next: (res)=>{

        //     this.sharedService.openSuccessSnackBar('Updated successfully','')

        //   },error: (err)=>{
        //     this.sharedService.openErrorSnackBar(
        //       'Something went wrong, please try again later.',
        //       ''
        //     );
        //   }

        // })
      } else {
      }
    });
  }

  displayConfirmation(title, text, secondary) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        title: title,
        text: text,
        secondary: secondary,
        fShow: false,
      },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });
    return dialogRef;
  }
}
