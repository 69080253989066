import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IPagination } from 'src/app/shared/model/pagination.model';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() totalElements;
  @Input() page;
  @Input() pageSize;

  ngbPaginationPage: number;
  maxPageNoSize: number = 5;

  @Output()
  pageChange: EventEmitter<IPagination> = new EventEmitter<IPagination>();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.page?.currentValue >= 0) {
      this.ngbPaginationPage = changes.page.currentValue + 1;
    }
  }

  ngbPaginationChange(pageIndex) {
    this.onPageChange({ pageSize: this.pageSize, pageIndex: pageIndex - 1 });
  }

  onPageChange(e) {
    this.pageChange.emit({ pageSize: e.pageSize, pageIndex: e.pageIndex });
  }
}
