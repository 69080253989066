import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AccountService } from '../services/account.service';
import { AuthorityName } from '../shared/model/enumerations/authority-name.model';

@Injectable({
  providedIn: 'root',
})
export class RouteGuard
  implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad
{
  constructor(private accountService: AccountService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (
      this.accountService.getCurrentRole() &&
      this.accountService.getCurrentRole() !== AuthorityName.ROLE_INTERVIEWER &&
      this.accountService.getCurrentRole() !== AuthorityName.ROLE_CANDIDATE
    ) {
      
      return true;
    } else {
      
      this.router.navigate(['/']);
      return false;
      // return this.accountService.getAccount().pipe(
      //   map((e) => {
      //     if (
      //       e['authorities'].includes('ROLE_RECRUITER') ||
      //       e['authorities'].includes('ROLE_SCHEDULER')
      //     ) {
      //       return true;
      //     } else {
      //       this.router.navigate(['/']);
      //       return false;
      //     }
      //   }),
      //   catchError((err) => {
      //     return of(false);
      //   })
      // );
    }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return false;
  }
}
