import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-infy-manage-mail-templates',
  templateUrl: './infy-manage-mail-templates.component.html',
  styleUrls: ['./infy-manage-mail-templates.component.scss'],
})
export class InfyManageMailTemplatesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
