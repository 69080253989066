<div class="d-flex align-items-center">
  <mat-form-field
    class="DSA_Wb_custom-form-field"
    [ngClass]="{
      'mat-form-field-invalid ng-invalid': searchControl.hasError('error')
    }"
  >
    <mat-label> {{ label }} </mat-label>
    <mat-chip-list
      #chipList
      aria-label="Interviewer selection"
      [required]="isRequired"
    >
      <mat-chip
        *ngFor="let data of panelistList"
        [selectable]="!disabled"
        [removable]="!disabled"
        (removed)="remove(data)"
        class="DSA_wb_chipStyle-Normal DSA_wb_chip-input marR16"
      >
        {{ data.panelistEmail }}

        <i
          matChipRemove
          class="icon x-16 icon-close-mini DSA_wb_chipIconRight"
        ></i>
      </mat-chip>
      <input
        #interviewerInput
        matInput
        [formControl]="searchControl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [readonly]="disabled"
        (blur)="onInputBlur($event)"
      />
    </mat-chip-list>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      <mat-option
        *ngFor="let data of filteredInterviewers | async"
        [value]="data"
      >
        {{ data.panelistEmail }}
      </mat-option>
    </mat-autocomplete>
    <mat-error>Please enter valid value</mat-error>
  </mat-form-field>
  <mat-spinner
    class="panelist-search-loader"
    [diameter]="25"
    *ngIf="getPanelistsLoader"
  >
  </mat-spinner>
</div>
