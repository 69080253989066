import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ResumeUploadService } from '../../services/resume-upload.service';
import { SharedService } from '../../../../services/shared.service';
import {
  FormBuilder,
  FormControlName,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { allDocumentListByCategory } from 'src/app/app.constants';
import { ActivatedRoute } from '@angular/router';
import { CandidateInfoService } from '../../services/candidate-info.service';
import { CandidateProfileService } from '../../candidate-profile/candidate-profile.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-single-file-upload',
  templateUrl: './single-file-upload.component.html',
  styleUrls: ['./single-file-upload.component.scss'],
})
export class SingleFileUploadComponent implements OnInit {
  @Input() doc_type = 'RESUME';
  @Input() doc_type_ui;
  @Input() individualFileMaxSizeInMB: number = 5;
  @Input() controlName: FormControlName;
  @Output() isFileUploaded: EventEmitter<any> = new EventEmitter<any>();
  @Input() applicantId = null;
  @ViewChild('fileSelect') fileSelector: ElementRef;
  @Input() bankValidation = false;
  @Input() bankProofJobId;
  consolidatedDocumentType = false;
  allDocumentListByCategory = allDocumentListByCategory;
  allUploadedDocumentListByCategory = [];
  uploadedDocumentStatus: string = null;
  previousFileName: string = null;
  lastUploadedDocument: any = null;
  fileName: string = 'Attach Doc';
  fileNameRegex = /^[\w\- ]+$/;
  fileErrorMessage: string = '';
  selectedChipFileId = null;
  // isDocumentSentBack: boolean = false;
  isFileEditable: boolean = true;
  sentBackComment: string = '';
  allowedFileTypes = {
    mimeTypes: [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
    ],
    extensions: ['.doc', '.docx', '.pdf'],
  };

  loaderDisplayText = 'Uploading....';
  spinner = false;
  form: FormGroup;
  jobId = null;

  base64File: string = null;
  fileSave: File = null;
  bankValidationFileId;

  constructor(
    private resumeUploadService: ResumeUploadService,
    private sharedService: SharedService,
    private fb: FormBuilder,
    private rootFormGroup: FormGroupDirective,
    private route: ActivatedRoute,
    private candidateInfoService: CandidateInfoService,
    private candidateProfileService: CandidateProfileService
  ) {}

  ngOnInit(): void {
    if (this.doc_type === 'CONSOLIDATED_EXPENSES') {
      this.consolidatedDocumentType = true;
    }
    const fileTypeAllowed =
      allDocumentListByCategory?.[this.doc_type]?.allowedFileTypes;
    if (fileTypeAllowed) this.allowedFileTypes = fileTypeAllowed;
    this.form = this.rootFormGroup.control;
    if (!this.bankValidation)
      this.jobId = this.candidateProfileService.opportunityId;
    else this.jobId = this.bankProofJobId;

    if (this.candidateProfileService.applicationDetailsFetched) {
      this.setFormValidators();
    } else {
      this.candidateProfileService.uploadedDocument$
        .pipe(take(1))
        .subscribe((docList) => {
          this.candidateProfileService.applicationDetailsFetched = true;
          this.setFormValidators();
        });
    }
    this.candidateProfileService.resetDocument$.subscribe((docList) => {
      if (docList.includes(this.doc_type)) {
        this.base64File = null;
        this.fileSave = null;
        this.fileName = 'Attach Doc';
        this.fileSelector.nativeElement.value = '';
        this.uploadedDocumentStatus = null;
        this.allUploadedDocumentListByCategory = [];
      }
    });
  }

  setFormValidators() {
    //initialize doc type array if not present
    if (!this.candidateProfileService.uploadedDoc[this.doc_type]) {
      this.candidateProfileService.uploadedDoc[this.doc_type] = [];
      this.allUploadedDocumentListByCategory = [];
    } else {
      //sort all documents
      this.allUploadedDocumentListByCategory =
        this.candidateProfileService.uploadedDoc[this.doc_type]
          ?.sort(
            (o1, o2) => Date.parse(o1.createdDate) - Date.parse(o2.createdDate)
          )
          .slice();

      //check if documents any documents are uploaded in this category
      if (this.allUploadedDocumentListByCategory?.length > 0) {
        this.lastUploadedDocument =
          this.allUploadedDocumentListByCategory[
            this.allUploadedDocumentListByCategory.length - 1
          ];
        //check last uploaded documents which has not been replaced
        if (this.lastUploadedDocument?.applicationFileStatus !== 'SENT_BACK') {
          this.fileName = this.lastUploadedDocument.fileName;
          this.uploadedDocumentStatus =
            this.lastUploadedDocument.applicationFileStatus;
          this.uploadedDocumentChanged(this.lastUploadedDocument);
          this.selectedChipFileId = this.lastUploadedDocument.id;
        } else {
          this.uploadedDocumentStatus =
            this.lastUploadedDocument?.applicationFileStatus;
          this.sentBackComment = this.lastUploadedDocument?.comments;
          this.selectedChipFileId = this.lastUploadedDocument?.id;
        }

        if (
          this.candidateProfileService?.applicationStatus ===
          'SENT_BACK_TO_CANDIDATE'
        ) {
          this.isEditableForSentBack();
        } else {
          this.isEditable();
        }

        if (
          this.candidateProfileService.lastUploadedDocumentByCategory[
            this.doc_type
          ]
        ) {
          this.lastUploadedDocument =
            this.candidateProfileService.lastUploadedDocumentByCategory[
              this.doc_type
            ];
        }
      }
    }
  }

  validateFile = (file: File) => {
    const fileName = file.name.substring(0, file.name.lastIndexOf('.'));
    // filetype
    if (!this.allowedFileTypes.mimeTypes.includes(file.type)) {
      this.fileErrorMessage = `Only ${this.allowedFileTypes.extensions.join(
        ', '
      )} files are accepted for ${this.doc_type.split('_').join(' ')}.`;
      return false;
    }
    //file size
    if (!(file.size / 1024 / 1024 < this.individualFileMaxSizeInMB)) {
      this.fileErrorMessage = `File size should be less than ${this.individualFileMaxSizeInMB}MB. Please reduce size of file ${fileName} and upload again`;
      return false;
    }

    // filename
    const fileNameRegex = /^[a-zA-Z0-9]*$/;
    if (!fileNameRegex.test(fileName)) {
      this.fileErrorMessage = `File name should only contain alphanumeric characters. Please rename the file ${fileName} and upload again`;
      return false;
    }
    return true;
  };

  onFileCancel() {
    if (this.uploadedDocumentStatus === 'SENT_BACK') {
      this.fileName = 'Attach Doc';
    } else {
      this.fileName = this.previousFileName
        ? this.previousFileName
        : this.lastUploadedDocument?.fileName;
      this.previousFileName = null;
    }
  }

  onFileInputClick() {
    this.previousFileName = this.fileName;
    this.fileName = 'Select File';
  }

  onFileSelected(event) {
    this.spinner = true;
    const files = event.target.files;
    if (files.length === 0) {
      this.spinner = false;
      return;
    }
    this.fileSave = files[0];
    const fReader = new FileReader();
    fReader.readAsDataURL(this.fileSave);
    if (this.validateFile(this.fileSave)) {
      fReader.onloadend = (_event: any) => {
        this.base64File = _event.target.result;
        if (!this.bankValidation)
          this.candidateProfileService.markComponentAsChanged();
        this.spinner = false;
        this.createApplicationAndApplicant();
      };
    } else {
      this.onFileCancel();
      this.fileSave = null;
      this.base64File = null;
      this.fileSelector.nativeElement.value = '';
      this.spinner = false;
      this.candidateProfileService.markComponentAsChanged();
      this.sharedService.openErrorSnackBar(this.fileErrorMessage, '', 4000);
    }
  }

  removeFile(file?) {
    this.fileSelector.nativeElement.value = '';
    this.spinner = true;
    if (this.bankValidation) {
      this.resumeUploadService
        .deleteApplicationFile(this.bankValidationFileId)
        .subscribe({
          next: (res: any) => {
            this.removeFileFromUI(file);
          },
          error: (err: any) => {
            this.sharedService.openErrorSnackBar(
              'File deletion failed. Please try again',
              ''
            );
            this.spinner = false;
          },
        });
    } else if (
      this.candidateProfileService?.applicationStatus !== 'SUBMITTED'
    ) {
      this.resumeUploadService.deleteApplicationFile(file.id).subscribe({
        next: (res: any) => {
          this.removeFileFromUI(file);
          this.lastUploadedDocument =
            this.allUploadedDocumentListByCategory[
              this.allUploadedDocumentListByCategory.length - 1
            ];
        },
        error: (err: any) => {
          this.sharedService.openErrorSnackBar(
            'File deletion failed. Please try again',
            ''
          );
          this.spinner = false;
        },
      });
    } else {
      this.removeFileFromUI(file);
    }
  }

  removeFileFromUI(file) {
    this.sharedService.openSuccessSnackBar('File removed', '');
    this.form.get(this.doc_type).reset('Attach Doc');
    this.fileName = 'Attach Doc';
    this.base64File = null;
    this.fileSave = null;
    if (this.candidateProfileService?.applicationStatus === 'SUBMITTED') {
      this.candidateProfileService.lastUploadedDocumentByCategory[
        this.doc_type
      ] = this.allUploadedDocumentListByCategory.pop();
    } else {
      this.allUploadedDocumentListByCategory.pop();
    }
    if (this.bankValidation) {
      this.uploadedDocumentStatus = null;
      this.isFileUploaded.emit({ fileUploaded: false });
      this.spinner = false;
      return;
    }
    const index =
      this.candidateProfileService.uploadedDoc[this.doc_type].indexOf(file);
    this.candidateProfileService.uploadedDoc[this.doc_type].splice(index, 1);
    if (this.allUploadedDocumentListByCategory.length > 0) {
      this.uploadedDocumentStatus = 'SENT_BACK';
      this.documentChipSelectionChange(
        this.allUploadedDocumentListByCategory[
          this.allUploadedDocumentListByCategory.length - 1
        ]
      );
    } else this.uploadedDocumentStatus = null;
    this.isFileUploaded.emit({ docType: this.doc_type });
    this.spinner = false;
    this.candidateProfileService.markComponentAsChanged();
  }

  isEditable() {
    if (this.selectedChipFileId) {
      this.selectedChipFileId = null;
    }
    if (this.candidateProfileService.isApplicationEditable()) {
      this.form.get(this.doc_type)?.enable();
      this.isFileEditable = true;
    } else {
      this.form.get(this.doc_type)?.disable();
      this.isFileEditable = false;
    }
  }

  isEditableForSentBack() {
    if (this.lastUploadedDocument.applicationFileStatus !== 'SENT_BACK') {
      if (this.isDocumentEditable()) {
        this.form.get(this.doc_type)?.enable();
        this.isFileEditable = true;
        this.checkForBankProof(false);
      } else {
        this.form.get(this.doc_type)?.disable();
        this.isFileEditable = false;
        this.checkForBankProof(true);
      }
      if (this.allUploadedDocumentListByCategory.length === 4) {
        this.candidateProfileService.sentBackLastChance = true;
      }
      return;
    } else if (this.allUploadedDocumentListByCategory.length === 3) {
      this.candidateProfileService.sentBackLastChance = true;
    }
    this.checkForBankProof(false);
  }

  checkForBankProof(state) {
    if (
      this.doc_type === 'BANK_PASSBOOK' ||
      this.doc_type === 'CANCELLED_CHEQUE'
    ) {
      this.candidateProfileService.bankProofDisable$.next(state);
    }
  }

  isDocumentEditable() {
    const modifiedDate = Date.parse(this.lastUploadedDocument.lastModifiedDate);
    return (
      this.lastUploadedDocument.replacementOf &&
      modifiedDate - this.candidateProfileService.applicationModifiedDate > 0
    );
  }

  uploadedDocumentChanged(changedDocument) {
    if (this.bankValidation) this.isFileUploaded.emit({ fileUploaded: true });
    else
      this.isFileUploaded.emit({
        file: changedDocument,
        docType: changedDocument?.category,
      });
  }

  documentChipSelectionChange(changedDocument) {
    //this.isFileUploaded.emit({file: changedDocument, docType: doc_type});
    if (this.uploadedDocumentStatus === 'SENT_BACK') {
      this.sentBackComment =
        changedDocument?.comments !== ''
          ? changedDocument?.comments
          : this.sentBackComment;
      this.selectedChipFileId = changedDocument?.id;
    }
  }

  //call api to upload the file
  uploadFile() {
    this.spinner = true;
    if (this.fileSave === null) {
      this.onFileCancel();
      this.sharedService.openErrorSnackBar('Please select a file', '');
      this.spinner = false;
    } else {
      let formData = new FormData();
      const specData = {
        applicationId: this.candidateProfileService.applicationId,
        comment: '',
        tenantId: parseInt(localStorage.getItem('tenantId')),
        fileCategory: this.doc_type,
      };
      //since we currently accept 1doc/doc_type in ui, replace last lastUploadedDocument
      if (this.lastUploadedDocument?.id && !this.bankValidation) {
        specData['applicationFileIdToReplace'] = this.lastUploadedDocument.id;
      }
      formData.append('specData', JSON.stringify(specData));
      formData.append('file', this.fileSave);
      this.resumeUploadService
        .uploadApplicationFile(formData, specData.applicationId)
        .subscribe({
          next: (res: any) => {
            this.sharedService.openSuccessSnackBar('File uploaded', '');
            if (this.bankValidation) {
              // If The Bank Validation for UPI to Bank then return
              this.allUploadedDocumentListByCategory.push(res);
              // this.candidateProfileService.updateUploadedDoc(res, 0);
              this.uploadedDocumentStatus = res.applicationFileStatus;
              this.lastUploadedDocument = res;
              this.base64File = null;
              this.fileSave = null;
              this.fileName = res.fileName;
              this.bankValidationFileId = res.id;
              this.spinner = false;
              this.isFileEditable = true;
              this.uploadedDocumentChanged(res);
              return;
            }
            this.uploadedDocumentChanged(res);
            let index = null;

            if (
              this.candidateProfileService.applicationStatus ===
              'SENT_BACK_TO_CANDIDATE'
            ) {
              this.selectedChipFileId = res.id;
            }

            if (this.lastUploadedDocument?.id) {
              if (
                this.lastUploadedDocument.applicationFileStatus === 'PENDING' ||
                this.lastUploadedDocument.applicationFileStatus === 'UPLOADING'
              ) {
                //pending documents are auto removed from backend when they are replaced
                index = this.candidateProfileService.uploadedDoc[
                  this.doc_type
                ].indexOf(this.lastUploadedDocument);
              }
            }
            if (
              this.candidateProfileService.lastUploadedDocumentByCategory[
                this.doc_type
              ]
            ) {
              this.candidateProfileService.lastUploadedDocumentByCategory[
                this.doc_type
              ] = null;
            } else if (
              this.lastUploadedDocument?.id &&
              this.uploadedDocumentStatus !== 'SENT_BACK'
            ) {
              this.allUploadedDocumentListByCategory?.pop();
            }
            this.allUploadedDocumentListByCategory.push(res);
            this.candidateProfileService.updateUploadedDoc(res, index);
            this.uploadedDocumentStatus = res.applicationFileStatus;
            this.lastUploadedDocument = res;
            this.base64File = null;
            this.fileSave = null;
            this.fileName = res.fileName;
            this.spinner = false;
            this.candidateProfileService.markComponentAsChanged();
          },
          error: (err: any) => {
            this.onFileCancel();
            this.sharedService.openErrorSnackBar(
              'File upload failed. Please try again',
              ''
            );
            this.spinner = false;
          },
        });
    }
  }

  createApplicationAndApplicant() {
    if (!this.candidateProfileService.applicationId) {
      this.candidateInfoService
        .createApplicationAndCandidate(this.jobId)
        .subscribe(
          (res) => {
            this.candidateProfileService.applicationId = res?.id;
            this.candidateProfileService.applicationStatus = res?.status;
            this.applicantId = res?.applicantId;
            if (!this.applicantId)
              this.candidateProfileService.candidateInfoId$.next(
                res?.applicantId
              );
            this.uploadFile();
          },
          (err) => {
            this.onFileCancel();
            this.sharedService.openErrorSnackBar(
              'Something Went Wrong! Please try again!',
              ''
            );
          }
        );
    } else this.uploadFile();
  }
}
