import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as axios from 'axios';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';

@Injectable({
  providedIn: 'root',
})
export class GjsExportService {
  config: any = {};
  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private http: HttpClient
  ) {}
  private headers = new HttpHeaders().set('content-type', 'application/json');
  openDialog = (data: any): void => {
    this.snackBar.open(
      'Title: ' +
        data.title +
        ' Message: ' +
        data.message +
        ' Type: ' +
        data.type,
      '',
      { duration: 3000, panelClass: ['purple-snackbar'] }
    );
  };
  getTemplateParams = () => {
    return this.http.get(
      environment.notificationServiceUrl + 'api/template-params',
      {
        headers: this.headers,
      }
    );
  };
  getCategories = () => {
    return this.http.get(
      environment.notificationServiceUrl +
        'api/template-categories/category-type/EMAIL',
      {
        headers: this.headers,
      }
    );
  };
  getAllAttachments() {
    return this.http.get(
      environment.notificationServiceUrl + 'api/template-attachments',
      { headers: this.headers }
    );
  }
  getFile = (fileId) => {
    return this.http.get(
      environment.fileServiceUrl + 'api/file/download/' + fileId,
      {
        headers: this.headers,
        observe: 'response',
        responseType: 'blob',
      }
    );
  };
  addTemplateParams = (param) => {
    return this.http.post(
      environment.notificationServiceUrl + 'api/template-params',
      param,
      {
        headers: this.headers,
      }
    );
  };
  openConfirm = (data: any, paramData: any): void => {
    const confirmRef = this.dialog.open(ConfirmationComponent, {
      data: { text: 'Confirm?' },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });

    confirmRef.afterClosed().subscribe((val) => {
      if (val === 'Yes') {
        this.openSnackBar('Editing, please wait...', '', 9000);
        this.http
          .put(
            environment.notificationServiceUrl + 'api/notification-templates',
            paramData.mailTemplate,
            {
              headers: paramData.headers,
            }
          )
          .subscribe(
            () => {
              this.openSnackBar('Template edited successfully', '', 3000);
            },
            (err) => {
              this.openSnackBar(
                'Error occurred in saving of Template',
                '',
                3000
              );
            }
          );
      }
    });
  };
  createFile = (name, content, callback, editor) => {
    const opts = { binary: false };
    const ext = name.split('.')[1];
    const isBinary = this.config.isBinary
      ? this.config.isBinary(content, name)
      : !(ext && ['html'].indexOf(ext) >= 0) && !/^[\x00-\x7F]*$/.test(content);

    if (isBinary) {
      opts.binary = true;
    }

    editor.log(['Create file', { name, content, opts }], {
      ns: 'plugin-export',
    });
    // let content = config.root

    const filename = name;
    const file = new File([content], filename, {
      type: 'text/html;charset=utf-8',
    });
    // FileSaver.saveAs(file);

    // const mailTemplate = createMailTemplate(content);
    const mailTemplate = callback(content);

    if (mailTemplate === null) {
      this.openSnackBar(
        'Please check your Template form. Template form is invalid.',
        '',
        3000
      );
      return;
    }

    // FIXME

    if (mailTemplate.id === undefined || mailTemplate.id === null) {
      this.openSnackBar('Saving, please wait...', '', 9000);
      this.http
        .post(
          environment.notificationServiceUrl + 'api/notification-templates',
          mailTemplate,
          {
            headers: this.headers,
          }
        )
        .subscribe(
          () => {
            this.openSnackBar('Template saved successfully', '', 3000);
          },
          () => {
            this.openSnackBar('Error occurred in saving of Template', '', 3000);
          }
        );
    } else {
      const confirmationData: any = {
        title: 'Are you sure to edit Template?',
        message: `You want to edit ${mailTemplate.name} template`,
        type: 'success',
        cancelButton: 'No',
        submitButton: 'Yes. I am Sure',
      };
      const val = {
        mailTemplate,
        headers: this.headers,
      };
      this.openConfirm(confirmationData, val);
    }
  };

  createDirectory = (root, editor, callback) => {
    root = typeof root === 'function' ? root(editor) : root;

    for (const name in root) {
      if (root.hasOwnProperty(name)) {
        let content = root[name];
        content = typeof content === 'function' ? content(editor) : content;
        const typeOf = typeof content;

        if (typeOf === 'string') {
          this.createFile(name, content, callback, editor);
        } else if (typeOf === 'object') {
          this.createDirectory(content, editor, callback);
        }
      }
    }
  };
  openSnackBar(message: string, action: string, duration1 = 3000) {
    this.snackBar.open(message, action, {
      duration: duration1,
      panelClass: ['purple-snackbar'],
    });
  }
  pluginExport = (callback) => {
    return (editor, opt = {}) => {
      this.config = {
        addExportBtn: 1,
        btnLabel: 'Save Template',
        filenamePfx: 'grapesjs_template',
        filename: null,
        root: {
          'index.html': (ed) =>
            `<!doctype html><html lang="en"><head><style type="text/css">${ed.getCss()}</style></head><body>${ed.getHtml()}</body></html>`,
        },
        isBinary: null,
        ...opt,
      };
      const pfx = editor.getConfig('stylePrefix');
      const btnExp = document.createElement('button');
      // btnExp.style.marginLeft = '10px';
      const commandName = 'gjs-export-html';

      // dialog box, uses when there is empty data or error occur

      btnExp.innerHTML = this.config.btnLabel;
      btnExp.className = `${pfx}btn-prim`;

      // Add command
      editor.Commands.add(commandName, (editor) => {
        this.createDirectory(this.config.root, editor, callback);
      });

      // Add button inside export dialog
      if (this.config.addExportBtn) {
        editor.on('run:export-template', () => {
          editor.Modal.getContentEl().removeChild(
            editor.Modal.getContentEl().childNodes[
              editor.Modal.getContentEl().childNodes.length - 1
            ]
          );
          editor.Modal.getContentEl().appendChild(btnExp);
          btnExp.onclick = () => {
            editor.runCommand(commandName);
          };
        });
      }
    };
  };
}
