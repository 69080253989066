import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InterviewListStateStorageService {
  page = 0;
  size = 25;
  filterState: any;
  searchQuery: string;

  constructor() {}

  init = () => {
    this.page = 0;
    this.size = 25;
    this.filterState = null;
    this.searchQuery = '';
  };
}
