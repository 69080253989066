import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { InfyCreateJobComponent } from '../infy-create-job/infy-create-job.component';
import { JobsAdminDashboardStateStorageService } from './jobs-admin-dashboard-state-storage.service';
import { JobService } from 'src/app/services/job.service';
import { SharedService } from 'src/app/services/shared.service';
import { Observable, Subject } from 'rxjs';
import { JobsAdminActionRendererComponent } from 'src/app/modules/iwp-platform-actions/infy-jobs-admin/jobs-admin-action-renderer/jobs-admin-action-renderer.component';
import { AccountService } from 'src/app/services/account.service';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
@Component({
  selector: 'app-infy-jobs-admin',
  templateUrl: './infy-jobs-admin.component.html',
  styleUrls: ['./infy-jobs-admin.component.scss'],
})
export class InfyJobsAdminComponent implements OnInit {
  tableName: string = 'All Jobs';
  searchCondition: boolean = true;

  page = 0;
  size = 25;
  totalElements = 0;

  queryJobTitle = '';

  refreshAgGrid: Subject<void> = new Subject<void>();

  FeatureName = FeatureName;
  PermissionType = PermissionType;

  constructor(
    private dialog: MatDialog,
    private jobsAdminDashboardStateStorageService: JobsAdminDashboardStateStorageService,
    private router: Router,
    private jobService: JobService,
    public accountService: AccountService
  ) {}

  ngOnInit(): void {
    const {
      page,
      size,
      searchQuery,
    } = this.jobsAdminDashboardStateStorageService;

    if (page && size) {
      this.page = page;
      this.size = size;
    }
    if (searchQuery) {
      this.queryJobTitle = searchQuery;
    }
  }

  interface = (size: number, page: number, query?: string): Observable<any> => {
    return this.jobService.getJobRoles(size, page, query);
  };

  columnDefs: any = [
    {
      headerName: 'Job ID',
      field: 'id',
      required: true,
      hide: false,
      maxWidth: 90,
    },
    { headerName: 'Job Title', field: 'title', hide: false, minWidth: 220 },
    {
      headerName: 'Rating Scale',
      field: 'ratingScale',
      hide: false,
      maxWidth: 80,
    },
    { headerName: 'Job Description', field: 'description', hide: false },
    { headerName: 'Experience Type', field: 'experienceType', hide: false },
    { headerName: 'Organization', field: 'organization', hide: false },
    { headerName: 'Job Level', field: 'jobLevel', hide: false, maxWidth: 80 },
    { headerName: 'Job Hiring Type', field: 'type', hide: false },
    {
      headerName: 'Feedback Form Type',
      field: 'feedbackFormType',
      hide: false,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      hide: false,
      cellRendererFramework: JobsAdminActionRendererComponent,
      minWidth: 260,
    },
  ];

  onPageChange = (e) => {
    this.jobsAdminDashboardStateStorageService.page = e.target.page;
    this.jobsAdminDashboardStateStorageService.size = e.target.size;
    this.jobsAdminDashboardStateStorageService.searchQuery =
      e.target.searchQuery;
  };

  navigateBack = (url) => {
    this.router.navigate(url);
  };

  addNewJob() {
    const dialogRef = this.dialog.open(InfyCreateJobComponent, {
      disableClose: true,
      backdropClass: 'backdrop-background',
      data: { jobs: '' },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value) {
        this.refreshAgGrid.next();
      }
    });
  }
}
