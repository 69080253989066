import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { interviewStatus } from 'src/app/app.constants';
import { InterviewService } from 'src/app/services/interview.service';
import { SharedService } from 'src/app/services/shared.service';
import { ElasticInterviewDTO } from 'src/app/shared/model/elastic-interview.model';
import { IPagination } from 'src/app/shared/model/pagination.model';
import { InterviewListStateStorageService } from '../infy-interview-list/interview-list-state-storage.service';

@Component({
  selector: 'app-infy-global-search',
  templateUrl: './infy-global-search.component.html',
  styleUrls: ['./infy-global-search.component.scss'],
})
export class InfyGlobalSearchComponent implements OnInit {
  searchQuery: string;
  page: number = 0;
  size: number = 10;
  totalElements: number = 0;
  isLoading: boolean = false;

  interviewStatusFilter: FormControl = new FormControl();
  interviewStatuses = interviewStatus;

  searchResults: ElasticInterviewDTO[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private interviewService: InterviewService,
    private location: Location,
    private router: Router,
    private interviewListStateStorage: InterviewListStateStorageService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.interviewListStateStorage.init();
    this.interviewStatusFilter.setValue(interviewStatus[0].value);
    this.activatedRoute.params.subscribe((params) => {
      if (params['searchStr']) {
        this.searchQuery = params['searchStr'];
        this.getSearchResults(0, this.size);
      } else {
        this.location.back();
      }
    });

    this.interviewStatusFilter.valueChanges.subscribe((val) => {
      this.getSearchResults(0, this.size);
    });
  }

  getSearchResults = (page, size) => {
    this.page = page;
    this.size = size;
    this.isLoading = true;
    this.interviewService
      .getGlobalSearchInterviews(this.searchQuery, this.page, this.size, {
        interviewStatus:
          this.interviewStatusFilter.value === 'ALL'
            ? null
            : this.interviewStatusFilter.value,
      })
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          this.searchResults = res.content;
          this.totalElements = res.totalElements;
        },
        error: (err) => {
          this.isLoading = false;
          this.sharedService.openSnackBar('Some error occurred', '', 3000);
        },
      });
  };

  pageChange = (e: IPagination) => {
    this.getSearchResults(e.pageIndex, e.pageSize);
  };

  goToFeedbackPage = (data: ElasticInterviewDTO) => {
    localStorage.setItem(
      'eventStatus',
      data.eventStatus ? data.eventStatus : ''
    );
    localStorage.setItem('eventId', `${data.eventId}`);
    this.router.navigate([
      '/infy-recruiter-feedback/',
      data.candidateInterviewRoundId,
      data.candidateId,
      data.jobRoleId,
    ]);
  };

  goToEventPage = (data: ElasticInterviewDTO) => {
    localStorage.setItem(
      'eventPagePrevLink',
      `/global-search/${this.searchQuery}`
    );
    localStorage.setItem(
      'eventStatus',
      data.eventStatus ? data.eventStatus : ''
    );
    this.interviewListStateStorage.searchQuery = this.searchQuery;
    this.router.navigate(['create-event', data.eventId, 'interviews']);
  };

  goBack = () => {
    this.router.navigate(['/dashboard']);
  };

  getChipClassByResult = (result: string): string => {
    let chipClass = 'DSA_wb_greychip';
    switch (result) {
      case 'SELECTED':
        chipClass = 'DSA_wb_closedchip';
        break;
      case 'REJECTED':
        chipClass = 'DSA_wb_openchip';
        break;
      // case 'REFER_FOR_POST':
      //   chipClass = 'DSA_wb_bluechip';
      //   break;
      // case 'ON_HOLD':
      //   chipClass = 'DSA_wb_inprogresschip';
      //   break;
    }
    return chipClass;
  };
}
