import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AgRendererComponent } from 'ag-grid-angular';
import { JobsAdminDashboardStateStorageService } from 'src/app/modules/iwp-platform-actions/infy-jobs-admin/jobs-admin-dashboard-state-storage.service';
import { SharedService } from 'src/app/services/shared.service';
import { AccountService } from 'src/app/services/account.service';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
@Component({
  selector: 'app-users-list-action-render',
  templateUrl: './users-list-action-render.component.html',
})
export class UsersListActionRenderComponent implements AgRendererComponent {
  params: any;
  job;
  FeatureName = FeatureName;
  PermissionType = PermissionType;
  constructor(
    private dialog: MatDialog,
    private sharedService: SharedService,
    private jobsAdminStateStorageService: JobsAdminDashboardStateStorageService,
    private router: Router,
    public accountService: AccountService
  ) {}

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }
}

