import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FileService } from 'src/app/services/file.service';
import { JobService } from 'src/app/services/job.service';

@Component({
  selector: 'app-add-interviews',
  templateUrl: './add-interviews.component.html',
  styleUrls: ['./add-interviews.component.scss'],
})
export class AddInterviewsComponent implements OnInit {
  constructor(
    public fileService: FileService,
    private jobService: JobService
  ) {}

  interview: any = undefined;
  filesList: object = undefined;
  interface: Observable<any> = undefined;
  eventId: number = 0;

  ngOnInit(): void {}

  onFilesAdded(event: any) {
    this.filesList = event.target.files;
    this.interface = this.fileService.bulkUpload(this.filesList);
    //this.interview = undefined;
  }

  /*onInterviewAdded(event: any){
    this.interview = event.value;   //TODO
    this.interface = this.interviewService.createIntervew(
      {
        tags: this.interview.tags.toString(),
        interviewId: this.interview.id,
        eventId: this.eventId,
    });
    this.filesList = undefined;
  }*/

  onSubmit() {
    if (this.interface) {
      this.interface.subscribe(
        (response) => {
          // console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      console.log('No data to submit');
    }
  }
}
