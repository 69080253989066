<ng-container [formGroup]="templateForm">
  <div class="row">
    <!-- Template Name -->
    <div class="col-4">
      <mat-form-field
        class="block"
        matTooltip="Template Name"
        matTooltipPosition="after"
      >
        <input
          matInput
          type="text"
          placeholder="Template Name"
          formControlName="templateName"
          maxlength="125"
          name="templateName"
          id="templateName"
          #templateName
        />
        <mat-error> TemplateName must not be empty </mat-error>
        <mat-hint>TemplateName of the action</mat-hint>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field
        class="block"
        matTooltip="Category"
        matTooltipPosition="after"
      >
        <mat-label>Category</mat-label>
        <mat-select formControlName="category" id="category" #category>
          <mat-option
            *ngFor="let category of templateCategory"
            [value]="category.id"
          >
            {{ category.name }}
          </mat-option>
        </mat-select>
        <mat-error>You have to select one category</mat-error>
        <mat-hint>Select a category</mat-hint>
      </mat-form-field>
    </div>

    <div class="col-lg-4 col-md-3 col-sm-3 col-12 align-items-center d-flex">
      <button
        mat-flat-button
        class="DSA_wb_primary-btn"
        (click)="addTemplateParams()"
      >
        Add Template Parameter
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <mat-form-field
        class="block"
        matTooltip="From Mail"
        matTooltipPosition="after"
      >
        <input
          matInput
          type="text"
          placeholder="From Mail"
          formControlName="fromMail"
          maxlength="125"
          name="fromMail"
          id="fromMail"
        />
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field
        class="block"
        matTooltip="From Name"
        matTooltipPosition="after"
      >
        <input
          matInput
          type="text"
          placeholder="From Name"
          formControlName="fromName"
          maxlength="125"
          name="fromName"
          id="fromName"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-8">
      <mat-form-field
        class="block"
        matTooltip="Subject"
        matTooltipPosition="after"
      >
        <input
          matInput
          type="text"
          placeholder="Subject"
          formControlName="subject"
          maxlength="125"
          name="subject"
          id="subject"
          #subject
        />
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field
        class="block"
        matTooltip="Placeholders"
        matTooltipPosition="after"
      >
        <mat-label>Placeholders</mat-label>
        <mat-select (selectionChange)="placeholderChange($event)">
          <mat-option
            *ngFor="let placeholder of placeholders"
            [value]="placeholder.name"
          >
            {{ placeholder.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-form-field class="DSA_Wb_custom-form-field">
        <mat-label>Attachments</mat-label>
        <mat-select
          [formControl]="attachmentCtrl"
          multiple
          disableOptionCentering
          panelClass="DSA_wb-custom-select"
        >
          <mat-select-trigger>
            <mat-chip-list>
              <mat-chip
                class="DSA_wb_chipStyle-Normal DSA_wb_chip-input marL8 marR16 marB16"
                *ngFor="let attachment of attachmentCtrl.value"
                [removable]="true"
                (removed)="onAttachmentRemoved(attachment)"
                (click)="onAttachmentClicked(attachment)"
                matTooltip="Preview"
                matTooltipPosition="above"
              >
                {{ attachment.displayName }}

                <i
                  matChipRemove
                  class="icon x-16 icon-close-mini DSA_wb_chipIconRight"
                ></i>
              </mat-chip>
            </mat-chip-list>
          </mat-select-trigger>

          <mat-option
            *ngFor="let attachment of attachments"
            [value]="attachment"
            >{{ attachment.displayName }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</ng-container>
<div id="gjs"></div>
