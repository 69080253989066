<app-banner 
  title="My Application" [displaySubHeader]="false">
</app-banner>

<div class="container-fluid marTB32">
    <div class="DSA_wb_customTab DSA_navTab noTAB_border nopadTB">
        <nav mat-tab-nav-bar>
            <a class="pageLink" mat-tab-link *ngFor="let page_name of stepper_page_names; let ind = index"
                (click)="activeIndex = ind" [active]="ind == activeIndex"
                [ngClass]="ind <= activeIndex ? 'my_class1' : 'my_class2'"> {{page_name}}
            </a>
        </nav>
    </div>
</div>

<div class="container marB32">
    <div class="row justify-content-center">
        <div class="tab-pane col-sm-9 col-md-9 col-xs-9 temp" role="tabpanel" *ngIf="activeIndex == 0">
            <!-- Column content -->
            Hello
            <!-- <button (click)="activeIndex = 1">Next</button> -->
        </div>
        <div class="tab-pane col-sm-9 col-md-9 col-xs-9 temp" role="tabpanel" *ngIf="activeIndex == 1">
            <!-- Column content -->
            Hello2
        </div>
        <div class="col-sm-9 col-md-9 col-xs-9 temp" *ngIf="activeIndex == 2">
            <!-- Column content -->
            Hello3
        </div>
        <div class="col-sm-9 col-md-9 col-xs-9 temp" *ngIf="activeIndex == 3">
            <!-- Column content -->
            Hello4
        </div>
    </div>
</div>
  

