import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { EventService } from 'src/app/services/event.service';
import { FormsModule } from '@angular/forms';
import * as moment from 'moment';
//import { InfyDynamicDashboardStateStorageService } from './infy-dynamic-dashboard-state-storage.service';
import { filter, map } from 'rxjs/operators';
import { DynamicDashboardRendererComponent } from 'src/app/modules/iwp-event/infy-dynamic-dashboard/dynamic-dashboard-renderer/dynamic-dashboard-renderer.component';
import { DynamicDashboardStateStorageService } from './dynamic-dashboard-state-storage.service';

export interface PanelistStatusItem {
  id: number;
  email: string;
  eventId: number;
  availabilityType: string;
  startTime: string;
  endTime: string;
  skills: string;
  jobLevel: string;
}

@Component({
  selector: 'app-infy-dynamic-dashboard',
  templateUrl: './infy-dynamic-dashboard.component.html',
  styleUrls: ['./infy-dynamic-dashboard.component.scss'],
})
export class InfyDynamicDashboardComponent implements OnInit {
  sh: any;
  panelistFlag = 1;
  candidateFlag = false;
  loading = true;
  lateralDynamic: boolean = false;
  tableName: string = 'Panelists';
  buttonText: string = 'Panelist Status(s)';
  buttonComponent: any;
  columnDefs: any;
  levels: number = 1;
  pageSize = 10;
  buttonCondition: boolean = true;
  searchCondition: boolean = false;

  refreshAgGrid: Subject<void> = new Subject<void>();
  tablePageNumber = 0;

  constructor(
    private eventService: EventService,
    private dynamicDashboardStateStorageService: DynamicDashboardStateStorageService,
    private router: Router
  ) {}

  responce: any;
  interface = (
    size: number,
    page: number,
    query?: string
  ): Observable<any[]> => {
    if (query) {
      query = query.split('@')[0];
      console.log(
        this.eventService.getInterviewersStatusWithEvent(
          (size = 10),
          page,
          this.eventService.selectedEventId
        )
      );
      return this.eventService
        .getInterviewersStatusWithEvent(
          (size = 10),
          page,
          this.eventService.selectedEventId
        )
        .pipe(map((items) => items.filter((item) => item.skills === query)));
      // return this.eventService.getInterviewerStatusWithSearchParam(
      //   query,
      //   size,
      //   page
      // );
    }
    return this.eventService.getInterviewersStatusWithEvent(
      size,
      page,
      this.eventService.selectedEventId
    );
  };
  searchData(searchValue: any) {
    return searchValue.filter((item) => {
      return item.skills.toLowerCase().includes(searchValue.toLowerCase());
    });
  }
  setColumnDefs() {
    this.columnDefs = [
      {
        headerName: 'Job Level',
        field: 'jobLevel',
        type: 'required',
        hide: false,
        maxWidth: 100,
      },
      {
        headerName: 'Email',
        field: 'email',
        type: 'required',
        hide: false,
        // valueFormatter: this.linkFormatter,
        // cellRendererFramework: RecruiterJobsLinkRendererComponent,
        minWidth: 200,
      },
      {
        headerName: 'Skills',
        field: 'skills',
        cellRendererFramework: DynamicDashboardRendererComponent,
        cellRendererParams: { type: 'skills', condition: true, minWidth: 100 },
        valueFormatter: this.getFormattedSkill,
        hide: false,
      },
      {
        headerName: 'Availability Type',
        field: 'availabilityType',
        valueFormatter: this.getFormattedStatus,
        hide: false,
        minWidth: 150,
      },
      {
        headerName: 'Start Time',
        field: 'startTime',
        valueFormatter: this.getFormattedDate,
        hide: false,
        minWidth: 150,
      },
      {
        headerName: 'End Time',
        field: 'endTime',
        valueFormatter: this.getFormattedDate,
        hide: false,
        minWidth: 150,
      },
    ];
  }
  onPageChange = (e) => {
    // To save prev Page details in local storage
    // this.infyDynamicDashboardStateStorageService.page = e.target.page;
    // this.infyDynamicDashboardStateStorageService.size = e.target.size;
  };

  ngOnInit(): void {
    this.getDynamicScheduleStatus();
    this.checkEventLateralDynamic();
    if (
      this.dynamicDashboardStateStorageService.page &&
      this.dynamicDashboardStateStorageService.size
    ) {
      this.page = this.dynamicDashboardStateStorageService.page;
      this.size = this.dynamicDashboardStateStorageService.size;
    }
    if (this.dynamicDashboardStateStorageService.filterState) {
      this.filterState = this.dynamicDashboardStateStorageService.filterState;
    }

    this.loading = false;
  }

  checkEventLateralDynamic = () => {
    this.loading = true;

    this.eventService.getEventById(this.eventService.selectedEventId).subscribe(
      (res: any) => {
        if (res?.eventType === 'LATERAL_DYNAMIC') {
          this.lateralDynamic = true;
          // refresh column def to pass latest lateralDynamic value in actions column
          this.setColumnDefs();
          this.setColumnDefsCandidate();
        }
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    );
  };

  getDynamicScheduleStatus = () => {
    // this.eventService.getDynamicScheduleStatus(1234).subscribe(
    //   (res) => {
    //     console.log(res);
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
  };

  // For Candidate Status
  filterState = null;
  filterByColumnsAll = '';
  filterByColumns: Array<{ key: string; value: string }>;

  tableNameCandidate: string = 'Candidate';
  buttonTextCandidate: string = 'Candidate Status(s)';
  buttonComponentCandidate: any;
  columnDefsCandidate: any;
  levelsCandidate: number = 1;
  pageSizeCandidate = 10;
  buttonConditionCandidate: boolean = true;
  searchConditionCandidate: boolean = false;
  page = 0;
  size = 10;
  refreshAgGridCandidate: Subject<void> = new Subject<void>();
  tablePageNumberCandidate = 0;

  interfaceCandidate = (
    size: number,
    page: number,
    query?: string
  ): Observable<any[]> => {
    return this.eventService.getCandidatesStatusWithEvent(
      size,
      page,
      this.eventService.selectedEventId
    );
  };

  setColumnDefsCandidate() {
    this.columnDefsCandidate = [
      // {
      //   headerName: 'id',
      //   field: 'id',
      //   type: 'required',
      //   hide: false,
      //   maxWidth: 150,
      // },
      {
        headerName: 'Email',
        field: 'email',
        type: 'required',
        hide: false,
        // valueFormatter: this.linkFormatter,
        // cellRendererFramework: RecruiterJobsLinkRendererComponent,
        minWidth: 200,
      },

      {
        headerName: 'Skill',
        field: 'skill',
        cellRendererFramework: DynamicDashboardRendererComponent,
        cellRendererParams: { type: 'skill', condition: true, minWidth: 100 },
        valueFormatter: this.getFormattedSkill,
        hide: false,
      },
      {
        headerName: 'Sign in Time',
        field: 'createdAt',
        valueFormatter: this.getFormattedDate,
        hide: false,
        minWidth: 150,
      },
    ];
  }
  onPageChangeCandidate = (e) => {
    this.dynamicDashboardStateStorageService.page = e.target.page;
    this.dynamicDashboardStateStorageService.size = e.target.size;
  };
  // Radio button change event
  onSelectionChange() {
    this.panelistFlag = this.panelistFlag ^ 1;
  }
  getFormattedDate(params: any) {
    //console.log(params);
    if (!params.data) return '';
    const _date = new Date(params.value);
    return moment(_date).format('DD/MM/YYYY HH:mm');
  }
  //changing the status to Proper Format
  getFormattedStatus(params: any) {
    if (!params.data) return '';
    var words = params.value.toLowerCase().split('_');
    var CapitalizedWords = [];
    words.forEach((element) => {
      CapitalizedWords.push(
        element[0].toUpperCase() + element.slice(1, element.length)
      );
    });
    return CapitalizedWords.join(' ');
  }

  getFormattedSkill(params: any) {
    if (!params.data) return '';
    if (params.value == '') {
      params.value = 'N/A';
    }
    return params.value;
  }

  filterCriteriaGetApi = () => {
    // Change this after Temporary use for testing
    //return this.eventService.getInterviewsFilterCriteriaForEvent();
  };

  refreshTableData = (filterState) => {
    this.page = 0;
    this.filterState = filterState;
    this.refreshAgGrid.next();
  };
}
