<div class="container-fluid layout pb-2">
  <mat-card class="m-4 rounded-0">
    <div class="container-fluid">
      <div
        class="
          row
          justify-content-lg-left
          justify-content-md-left
          justify-content-sm-left
          justify-content-xs-left
        "
        style="margin: 10px 0px"
      >
        <div *ngIf="!shouldCreateTemplate">
          <a routerLink="/opportunity/scholarships"
            ><i
              class="icon x-24 back-icon m-0"
              style="display: flex; align-items: center; justify-content: left"
            >
            </i
          ></a>
        </div>

        <div *ngIf="shouldCreateTemplate">
          <a
            (click)="
              shouldCreateTemplate
                ? returnToTemplateHome()
                : enableTemplateCreation('HTML', null)
            "
            ><i
              class="icon x-24 back-icon m-0"
              style="display: flex; align-items: center; justify-content: left"
            >
            </i
          ></a>
        </div>
        <div
          class="col-xs-10 col-sm-10 col-md-10 col-lg-10"
          style="
            font-size: 1.25rem;
            display: flex;
            align-items: center;
            justify-content: left;
          "
        >
          <h4 *ngIf="!shouldCreateTemplate" style="margin-bottom: auto">
            {{ metadataRowVals.label }}
          </h4>
          <h4 *ngIf="shouldCreateTemplate">{{ metadataRowVals.label }}</h4>
        </div>
        <div
          class="col-xs-1 col-sm-1 col-md-1 col-lg-1"
          *ngIf="!shouldCreateTemplate"
        >
          <button
            mat-fab
            color="basic"
            (click)="enableTemplateCreation('HTML', null)"
          >
            <mat-icon>{{ "add" }}</mat-icon>
          </button>
        </div>
      </div>
      <app-loader [display]="loading"></app-loader>
      <div
        class="
          row
          justify-content-lg-center
          justify-content-md-center
          justify-content-sm-center
          justify-content-xs-center
        "
        *ngIf="templates != undefined && !shouldCreateTemplate && !loading"
      >
        <div
          class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
          *ngFor="let template of templates"
        >
          <mat-card class="template-card overflow-hidden">
            <mat-card-header class="template-card-body">
              <mat-card-title>{{ template.name }}</mat-card-title>
              <mat-card-subtitle>
                <span>{{ template.name.split("__")[1] }}</span
                ><br />
                <span *ngIf="template.isActive" class="badge badge-success"
                  >Active</span
                >
                <span *ngIf="!template.isActive" class="badge badge-danger"
                  >Inactive</span
                >
                <p>
                  From: {{ template.fromName }} &lt;{{ template.fromMail }}&gt;
                </p>
                <p>Subject: {{ template.subject }}</p>
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <div class="thumbnail-container">
                <div class="thumbnail" style="background-color: white">
                  <iframe
                    height="100%"
                    style="opacity: 1"
                    width="100%"
                    srcdoc="{{ template.templateString }}"
                    frameborder="0"
                  ></iframe>
                </div>
              </div>
            </mat-card-content>
            <mat-card-actions align="end">
              <button
                
                mat-button
                (click)="enableTemplateCreation(null, template)"
                title="Edit Template"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                mat-button
                (click)="deleteTemplate(template)"
                title="Delete Tempalte"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>

      <jhi-app-grapesjs-wrapper
        *ngIf="shouldCreateTemplate && templateType == 'HTML'"
        [template]="currTemplate"
      >
      </jhi-app-grapesjs-wrapper>
      <mail-template-pagination
        *ngIf="!shouldCreateTemplate"
        [totalElements]="totalElements"
        [page]="page"
        [pageSize]="size"
        (pageChange)="pageChange($event)"
      ></mail-template-pagination>
    </div>
  </mat-card>
</div>
