<app-loader [display]="isLoadingAll"></app-loader>
<div class="row" *ngIf="!isLoadingAll && !errorPage">
  <div class="container-fluid nopadLR mar8">
    <div class="DSA_wb_customTab DSA_navTabWhite">
      <mat-tab-group
        animationDuration="0ms"
        class="col-lg-12"
        disableRipple
        (selectedTabChange)="tabChanged($event)"
        [selectedIndex]="activeTabIndex"
      >
        <mat-tab label="Team Details">
          <div class="marT16">
            <app-basic-details></app-basic-details>
          </div>
        </mat-tab>
        <mat-tab label="Synposis">
          <ng-template matTabContent>
            <div class="marT16">
              <app-synopsis-template></app-synopsis-template>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
<div class="marT32" *ngIf="errorPage">
  <div class="marT50">
    <app-error-message
      [errorText]="'Some error occurred. Please try again.'"
    ></app-error-message>
  </div>
</div>
