import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-infy-superset-dashboard',
  templateUrl: './infy-superset-dashboard.component.html',
  styleUrls: ['./infy-superset-dashboard.component.scss'],
})
export class InfySupersetDashboardComponent implements OnInit {
  environment = environment;
  url;

  dashboards = [];
  selectedDashboard;
  selectedIndex: number = 0;

  constructor(
    private sanitizer: DomSanitizer
  ) {
    let currentRole = window.localStorage.getItem('currentRole');
    this.resolveAvailableDashboards(currentRole)
  }

  ngOnInit(): void {
    this.selectedDashboard = this.dashboards[0];
    this.setDashboardUrl(this.selectedDashboard.url);
  }

  changeDashboard = (e) => {
    this.selectedDashboard = this.dashboards[e.index];
    this.setDashboardUrl(this.selectedDashboard.url);
    this.selectedIndex = e.index;
  };

  setDashboardUrl = (url) => {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  };

  resolveAvailableDashboards = (role) => {
    switch(role) {
      case 'ROLE_SUPER_ADMIN':
        this.dashboards.push({ name: 'Vitals Dashboard', url: environment.scpVitalsDashboardUrl });
        this.dashboards.push({ name: 'Insights Dashboard', url: environment.scpInsightsDashboardUrl });
        break;
      case 'ROLE_HIRING_MANAGER':
        this.dashboards.push({ name: 'Vitals Dashboard', url: environment.scpVitalsDashboardUrl });
        break;
    }
  };
}
