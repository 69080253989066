<div id="gridContainer" class="ag-grid-container">
  <div class="row marB16">
    <!-- <div class="col-md-6 d-flex align-items-center">
      <div class="DSA_wb_h2Header web-GreyDarkest">{{ tableName }}</div>
    </div> -->
  </div>
  <div class="row">
    <div
      class="marB16"
      [ngClass]="{
        hide: isLoading,
        'col-md-8': searchCondition,
        'col-12': !searchCondition
      }"
    >
      <ng-container *ngIf="levels == 1">
        <div style="min-height: 0px">
          <strong>Show: </strong>
          <span *ngFor="let column of columnDefs">
            <mat-checkbox
              *ngIf="column.type !== 'required' && !column.required"
              class="DSA_Wb_custom-checkbox DSA_wb_mainBodyTxt2 ml-2 mr-1"
              [checked]="!column.hide"
              (change)="toggleVisibility(column)"
              >{{ column.headerName }}
            </mat-checkbox>
          </span>
        </div>
        <br />
      </ng-container>
    </div>
    <div
      class="col-md-4 d-flex"
      [ngClass]="{ 'offset-md-8': isLoading }"
      *ngIf="searchCondition"
    >
      <div
        class="search-input-wrap marB8 w-100"
        [ngClass]="{
          'border-radius-4': !showRefresh,
          'border-radius-left-4': showRefresh
        }"
      >
        <input
          [(ngModel)]="searchValue"
          (keyup)="search($event)"
          matInput
          placeholder="Search"
          class="search-input"
        />
        <div matSuffix class="d-flex ml-auto">
          <span class="clear-icon" *ngIf="searchValue">
            <i
              class="icon x-16 icon-rejected"
              (click)="clearSearch()"
              matTooltip="Clear Search"
              matTooltipClass="DSA_tooltip"
            >
            </i>
          </span>
          <span>
            <i
              class="icon x-16 search-icon"
              role="button"
              (click)="search($event)"
              matTooltip="Search"
              matTooltipClass="DSA_tooltip"
            >
            </i>
          </span>
        </div>
      </div>
      <div *ngIf="showRefresh" class="grid-refresh">
        <a
          *ngIf="!isRefreshed"
          (click)="handleRefreshClick()"
          matTooltip="Refresh"
          matTooltipClass="DSA_tooltip"
          role="button"
          class="grid-refresh-btn"
        >
          <span class="material-icons-outlined">refresh</span>
        </a>
        <mat-spinner
          *ngIf="isRefreshed"
          class="grey-spinner"
          diameter="20"
        ></mat-spinner>
      </div>
    </div>

    <div class="col-md-12">
      <ng-container *ngIf="levels == 2">
        <mat-card
          *ngFor="let category of columnDefs"
          class="DSA_wb_card-widget"
          style="min-height: 0px"
        >
          <strong>{{ category.headerName }}: </strong>
          <span *ngFor="let column of category.children">
            <mat-checkbox
              *ngIf="column.type !== 'required' && !column.required"
              class="DSA_Wb_custom-checkbox ml-2 mr-1"
              [checked]="!column.hide"
              (change)="toggleVisibility(column)"
              >{{ column.headerName }}
            </mat-checkbox>
          </span>
        </mat-card>
        <br />
      </ng-container>
    </div>

    <div class="col-md-12">
      <div id="grid-card" class="DSA_wb_card-widget" #gridWrap>
        <ag-grid-angular
          id="grid"
          style="width: 100%; margin: auto"
          class="ag-theme-balham"
          [gridOptions]="gridOptions"
          [columnDefs]="columnDefs"
          (gridReady)="onGridReady($event)"
          enableCellTextSelection="true"
        ></ag-grid-angular>
      </div>
    </div>

    <div class="col-md-12" [ngClass]="{ hide: isLoading }">
      <app-pagination
        [totalElements]="totalElements"
        [page]="pageNumber"
        [pageSize]="pageSize"
        (pageChange)="onPageChange($event)"
      ></app-pagination>
    </div>
  </div>
</div>
