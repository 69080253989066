import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { MatDialog } from '@angular/material/dialog';
import { InfyCopyEventComponent } from 'src/app/modules/iwp-event/infy-copy-event/infy-copy-event.component';
import { Router } from '@angular/router';
import { CandidateService } from 'src/app/services/candidate.service';

@Component({
  template: `<i
    *ngIf="params.condition"
    class="icon x-24 {{ icon }}"
    role="button"
    (click)="onClick()"
    [matTooltip]="tooltipMessage"
    matTooltipClass="DSA_tooltip"
    aria-label="Copy Event"
  ></i>`,
})
export class actionRendererComponent implements AgRendererComponent {
  params: any;
  icon: any;
  eventId: any;
  candidate: any;
  val: any;
  tooltipMessage: String;
  eventStatus: String;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private candidateService: CandidateService
  ) {}

  agInit(params: any): void {
    this.params = params;
    this.eventStatus = localStorage.getItem('eventStatus');
    if (params.icon === 'delete') {
      this.tooltipMessage = 'Cancel interview';
      this.icon = 'delete-sm-icon';
    } else if (params.icon === 'schedule') {
      this.icon = 'calendar-icon';
      this.tooltipMessage =
        this.eventStatus !== 'CLOSED'
          ? 'Schedule'
          : 'View Scheduled Interviews';
      if (params.data) {
        this.candidate = params.data;
      }
    } else if (params.icon === 'copy') {
      this.icon = 'copy-icon';
      this.tooltipMessage = 'Copy Event';
      if (params.data) {
        this.eventId = params.data.id;
        // params.refreshCells();
      }
    } else if (params.icon === 'no-show') this.icon = 'personal-icon';
    //Change
    else if (params.icon === 'associate') {
      this.icon = 'icon_link';
      this.tooltipMessage = 'Associate Template';
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  onClick() {
    if (this.params.icon === 'copy') {
      //   console.log('copy icon');

      this.copyEvent(this.eventId);
    } else if (this.params.icon === 'schedule') {
      //   console.log('schedule');
      this.candidateService.setSelectedCandidate(this.candidate);
      this.router.navigate(['/candidate-interview-rounds']);
    } else if (this.params.icon === 'no-show') {
      //   console.log('no show');
      //Dialog
    } else if (this.params.icon === 'associate') {
      this.params.click(this.params);
    }
  }

  copyEvent = (eventId) => {
    // console.log(eventId);
    const dialogRef = this.dialog.open(InfyCopyEventComponent, {
      data: { id: eventId },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((form) => {
      //   console.log('check');
      if(form)
      this.params.context.componentParent.refreshTable();
    });
  };
}
