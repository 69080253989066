import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { EvaluationService } from 'src/app/services/evaluation.service';
import { FileService } from 'src/app/services/file.service';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';

@Component({
  selector: 'app-infy-delete-snapshots',
  templateUrl: './infy-delete-snapshots.component.html',
  styleUrls: ['./infy-delete-snapshots.component.scss'],
})
export class InfyDeleteSnapshotsComponent implements OnInit {
  loading = true;
  evaluationData;
  data = {};
  interviewSnapshots = [];
  uploadedImagesFlag;
  @Input() interviewId;
  cirId;
  count = 0;
  textToShow = 'Loading uploaded snapshots, please wait';
  @Output() noUploadedImages = new EventEmitter<boolean>();
  constructor(
    private dialog: MatDialog,
    private evaluationService: EvaluationService,
    private snackBar: MatSnackBar,
    private fileService: FileService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.reloadTheComponent();
  }
  reloadTheComponent() {
    this.loading = true;
    this.count = 0;
    this.uploadedImagesFlag = false;
    if (this.interviewId) this.getInterviewSnapshots();
    //this.loading=false;
  }
  removeFile(img) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '500px',
      data: {
        title: `Delete Snapshot Confirmation`,
        text: 'Are you sure? This will delete the screenshot permanently.',
      },
      backdropClass: 'backdrop-background',
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data === 'Yes') {
        this.loading = true;
        this.evaluationService.deleteSnapshot(img.Id).subscribe({
          next: (res) => {
            this.snackBar.open('Snapshot deleted successfully', '', {
              duration: 2000,
              panelClass: ['purple-snackbar'],
            });
            if (this.interviewSnapshots?.length == 1) {
              this.uploadedImagesFlag = false;
              this.noUploadedImages.emit(true);
            }
            this.interviewSnapshots = this.interviewSnapshots.filter(
              (each) => each.Id !== img.Id
            );

            this.loading = false;
            //this.reloadTheComponent();
          },
          error: (err) => {
            this.snackBar.open('Error occured, please try again', '', {
              duration: 2000,
              panelClass: ['red-snackbar'],
            });
          },
        });
      }
    });
  }

  getInterviewSnapshots() {
    this.loading = true;
    this.evaluationService
      .getfileMappingsByInterviewId(this.interviewId)
      .subscribe({
        next: (res) => {
          let interviewFileMappings = res.filter(
            (file) => file.fileCategory == 'INTERVIEW_SNAP'
          );
          //console.log(res);
          if (interviewFileMappings.length == 0) {
            this.noUploadedImages.emit(true);
            this.loading = false;
          }

          interviewFileMappings?.forEach((file, index2) => {
            this.count = +1;

            this.fileService.getFile(file.fileId).subscribe({
              next: (res) => {
                this.uploadedImagesFlag = true;
                this.interviewSnapshots.push({
                  url: this.sanitizer.bypassSecurityTrustUrl(
                    window.URL.createObjectURL(res.body)
                  ),
                  blob: res.body,
                  Id: file.id,
                });

                if (index2 == interviewFileMappings?.length - 1) {
                  this.loading = false;
                }
              },
              error: (err) => {
                if (index2 == interviewFileMappings?.length - 1)
                  this.loading = false;
                this.snackBar.open(
                  'Error occured while getting image file, please try again',
                  '',
                  {
                    duration: 2000,
                    panelClass: ['red-snackbar'],
                  }
                );
              },
            });
          });
        },
        error: (err) => {
          this.loading = false;
          if (err.status !== 404) {
            this.snackBar.open(
              'Error occured while getting uploaded interview snapshots',
              '',
              {
                duration: 3000,
                panelClass: ['red-snackbar'],
              }
            );
          } else {
            this.noUploadedImages.emit(true);
          }
        },
      });
  }
}
