/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/tslint/config */
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './confirmation-dialog.dialog.component.html',
  styleUrls: ['./confirmation-dialog.dialog.component.scss']
})
export class ConfirmationDialogComponent {
  titleData: any;
  messageData: any;
  yesText: any;
  noText: any;

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public appData: any) {
    this.titleData = appData.title;
    this.messageData = appData.message;
    this.yesText = appData.yesText;
    this.noText = appData.noText;
  }

  cancelClick(): void {
    if (this.dialogRef) {
      this.dialogRef.close({ confirm: false });
    }
  }

  confirm() {
    if (this.dialogRef) {
      this.dialogRef.close({ confirm: true });
    }
  }

  close(): void {
    this.dialogRef.close(null);
  }
}
