<h1 mat-dialog-title>{{ data.title ? data.title : "Confirmation" }}</h1>
<div mat-dialog-content>
  <p [innerHTML]="data.text"></p>
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <button mat-button [mat-dialog-close]="'No'" class="DSA_wb_miniSecondary-btn" *ngIf="data.fShow">
    {{data.primary ? data.primary : 'No'}}
  </button>
  <button mat-button [mat-dialog-close]="'Yes'" class="DSA_wb_miniPrimary-btn">
    {{data.secondary ? data.secondary : 'Yes'}}
  </button>
</div>
