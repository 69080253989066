<app-loader [display]="loading"></app-loader>

<div class="container-fluid layout" *ngIf="!loading && !error">
  <div class="clearfix DSA_mainCtr ctr_main" id="mainCtr">
    <div class="container-fluid">
      <div class="DSA_rightMainContent nomarL_mb" id="mainrightCnt">
        <div class="DSA_innerContentWrapper">
          <div class="row">
            <div class="col-lg-12">
              <div class="DSA_contentLeftCol">
                <div
                  class="d-flex justify-content-start align-items-center mb-3"
                >
                  <!-- <div class="ml-3 mr-3" role="button">
                    <i class="icon x-24 back-icon" (click)="goToPrevPage()">
                    </i>
                  </div> -->
                  <div class="h3" *ngIf="showTable">
                    {{ event.name }}
                    <span
                      *ngIf="eventStatus === 'CLOSED'"
                      class="verticalCenter"
                    >
                      &nbsp;
                      <span class="DSA_wb_chipStyle customBadge">
                        {{ eventStatus }}
                      </span>
                    </span>
                  </div>
                  <div *ngIf="showTable" class="d-flex ml-auto padR16">
                    <!-- <div
                    
                    *ngIf="
                      !accountService
                        .getAccountLocal()
                        .authorities.includes('ROLE_SCHEDULER') &&
                      this.eventStatus !== 'CLOSED'
                    ">
                    
                      <a class="purpleIconActive-Circle " (click)="modifyEvent()"  role="button"
                      matTooltip="Update Event"
                      matTooltipClass="DSA_tooltip"><i class="icon x-24  white  edit-icon"> </i> </a>
                    </div> -->

                    <div
                      *ngIf="
                      accountService.hasFeatureAccess(FeatureName.BULK_UPLOAD_STATUS, PermissionType.READ)
                      "
                    >
                      <a
                        role="button"
                        matTooltip="Bulk Upload File Status"
                        matTooltipClass="DSA_tooltip"
                        class="icon-wrap"
                        [routerLink]="['/event-notifications/', this.id]"
                      >
                        <i class="icon x-30 grey notification-icon"> </i>
                      </a>
                    </div>

                    <div
                      class="marL8"
                      *ngIf="
                      accountService.hasFeatureAccess(FeatureName.EVENT_TEMPLATE_MAPPING, PermissionType.READ)
                      "
                    >
                      <a
                        (click)="manageTemplatesForEvent()"
                        role="button"
                        matTooltip="Mail Templates"
                        matTooltipClass="DSA_tooltip"
                        class="icon-wrap"
                      >
                        <i class="icon x-30 grey mail-icon"> </i>
                      </a>
                    </div>
                    <div
                      class="marL8"
                      *ngIf="
                      accountService.hasFeatureAccess(FeatureName.EVENT_SETTINGS, PermissionType.READ)
                      "
                    >
                      <a
                        role="button"
                        matTooltip="Settings"
                        matTooltipClass="DSA_tooltip"
                        [routerLink]="[
                          '/settings',
                          this.category,
                          this.categoryId
                        ]"
                        class="icon-wrap"
                      >
                        <i class="icon x-30 grey icon_setting"> </i>
                      </a>
                    </div>

                    <!-- NEEDS TO BE INTEGRATED -->
                    <!-- <div
                      class="marL8"
                      *ngIf="
                        accountService
                          .getAccountLocal()
                          .authorities.includes('ROLE_SUPER_ADMIN') ||
                        accountService
                          .getAccountLocal()
                          .authorities.includes('ROLE_ADMIN_RECRUITER') ||
                        accountService
                          .getAccountLocal()
                          .authorities.includes('ROLE_EVENT_ADMIN')
                      "
                    >
                      <a
                        role="button"
                        matTooltip="Dynamic Schedule Status"
                        matTooltipClass="DSA_tooltip"
                        (click)="onDynamicScheduleStatusClick()"
                        class="icon-wrap"
                      >
                        <i class="icon x-30 grey team-icon"> </i>
                      </a>
                    </div> -->

                    <div
                      class="marL8"
                      *ngIf="
                      accountService.hasFeatureAccess(FeatureName.CLOSE_EVENT, PermissionType.UPDATE) &&
                        eventStatus != 'CLOSED'
                      "
                    >
                      <a
                        class="icon-color icon-wrap"
                        role="button"
                        matTooltip="Close Event"
                        matTooltipClass="DSA_tooltip"
                        (click)="closeEvent(id)"
                      >
                        <!-- <i class="icon x-30 white close-icon"> </i> -->
                        <span class="material-icons-outlined grey-icon">
                          power_settings_new
                        </span>
                      </a>
                    </div>
                    <div
                      class="marL8"
                      *ngIf="
                      accountService.hasFeatureAccess(FeatureName.GENERATE_REPORT, PermissionType.CREATE) &&
                        !generateReportLoader
                      "
                    >
                      <a
                        role="button"
                        matTooltip="Generate Report"
                        matTooltipClass="DSA_tooltip"
                        [disabled]="generateReportLoader"
                        (click)="generateReport()"
                        class="icon-wrap"
                      >
                        <i class="icon x-30 grey document-icon"> </i>
                      </a>
                    </div>
                    <div
                      class="marL14"
                      *ngIf="
                      accountService.hasFeatureAccess(FeatureName.GENERATE_REPORT, PermissionType.CREATE) &&
                        generateReportLoader
                      "
                    >
                      <a
                        class="d-flex justify-content-center align-items-center padT4 icon-wrap"
                        role="button"
                        matTooltip="Generating Report..."
                        matTooltipClass="DSA_tooltip"
                        [disabled]="generateReportLoader"
                      >
                        <mat-spinner diameter="24"></mat-spinner>
                      </a>
                    </div>

                    <div
                      class="marL8"
                      *ngIf="
                      accountService.hasFeatureAccess(FeatureName.GENERATE_REPORT, PermissionType.CREATE) &&
                        downloadReport &&
                        !generateReportLoader
                      "
                    >
                      <a
                        role="button"
                        matTooltip="Download Report"
                        matTooltipClass="DSA_tooltip"
                        (click)="saveReport()"
                        class="icon-wrap"
                      >
                        <i class="icon x-30 grey download-icon"> </i>
                      </a>
                    </div>
                  </div>

                  <div class="ml-3 mr-3 h3" *ngIf="!showTable">Create Event</div>
                </div>
                <div class="row mt-3">
                  <!-- SHOW EVENT DETAILS -->
                  <div class="col-12" *ngIf="showTable">
                    <div class="DSA_panel" style="height: 100%">
                      <form [formGroup]="updateEvent">
                        <div
                          class="DSA_panelBody padTB16 padLR24"
                          style="height: 100%"
                        >
                          <div class="row align-items-end" style="height: 100%">
                            <div class="col-lg-6 col-md-6">
                              <mat-form-field class="DSA_Wb_custom-form-field">
                                <mat-label>Event Name</mat-label>

                                <input
                                  matInput
                                  placeholder="Event Name"
                                  formControlName="eventName"
                                  [readonly] = "!accountService.hasFeatureAccess(FeatureName.EVENT, PermissionType.UPDATE)"
                                />
                                <mat-error
                                  *ngIf="
                                    updateEvent.controls['eventName'].dirty &&
                                    updateEvent.controls['eventName'].hasError(
                                      'pattern'
                                    )
                                  "
                                  >Special characters except '_,.@&()-' are not
                                  allowed</mat-error
                                >
                              </mat-form-field>
                            </div>

                            <div class="col-lg-6 col-md-6 marB16">
                              <mat-form-field class="DSA_Wb_custom-form-field  DSA_form_nofloatLabel marL8">
                                <mat-label>Event Type</mat-label>
                                <mat-select
                                  [disabled] = "!accountService.hasFeatureAccess(FeatureName.EVENT, PermissionType.UPDATE)"
                                  formControlName="eventType"
                                  panelClass="DSA_wb-custom-select-panel" class="DSA_wb-custom-select"
                                >
                                  <mat-option
                                    *ngFor="let option of eventTypeArray"
                                    [value]="option"
                                  >
                                    {{ option }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <div class="col-lg-3 col-md-6">
                              <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixIcon" floatLabel="never">
                                <mat-label>Start Date</mat-label>

                                <input
                                  [readonly] = "!accountService.hasFeatureAccess(FeatureName.EVENT, PermissionType.UPDATE)"
                                  matInput
                                  [matDatepicker]="picker"
                                  (click)="picker.open()"
                                  formControlName="fromDate"
                                />
                                <mat-datepicker-toggle 
                                [disabled] = "!accountService.hasFeatureAccess(FeatureName.EVENT, PermissionType.UPDATE)"
                                  class="DSA_wb_mat-suffix" [for]="picker">
                                  <i
                                    class="icon x-24 calendar-icon"
                                    matDatepickerToggleIcon
                                  ></i>
                                </mat-datepicker-toggle>
                                <mat-datepicker
                                  [disabled] = "!accountService.hasFeatureAccess(FeatureName.EVENT, PermissionType.UPDATE)"
                                  panelclass="DSA_wb_dateClass"
                                  #picker
                                ></mat-datepicker>
                              </mat-form-field>
                            </div>
                            <div class="col-lg-3 col-md-6">
                              <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel DSA_form_suffixIcon" floatLabel="never">
                                <mat-label>End Date</mat-label>

                                <input
                                  [readonly] = "!accountService.hasFeatureAccess(FeatureName.EVENT, PermissionType.UPDATE)"
                                  matInput
                                  [matDatepicker]="picker1"
                                  (click)="picker1.open()"
                                  formControlName="toDate"
                                />
                                <mat-datepicker-toggle
                                  [disabled] = "!accountService.hasFeatureAccess(FeatureName.EVENT, PermissionType.UPDATE)"
                                  class="DSA_wb_mat-suffix"
                                  [for]="picker1"
                                >
                                  <i
                                    class="icon x-24 calendar-icon"
                                    matDatepickerToggleIcon
                                  ></i>
                                </mat-datepicker-toggle>
                                <mat-datepicker
                                  [disabled] = "!accountService.hasFeatureAccess(FeatureName.EVENT, PermissionType.UPDATE)"
                                  panelclass="DSA_wb_dateClass"
                                  #picker1
                                ></mat-datepicker>
                              </mat-form-field>
                            </div>

                            <div class="col-lg-6 col-md-6">
                              <mat-form-field class="DSA_Wb_custom-form-field">
                                <mat-label>Location</mat-label>
                                <mat-chip-list #locationChipList>
                                  <mat-chip
                                    class="DSA_wb_chipStyle-Normal DSA_wb_chip-input marR16"
                                    disableRipple="true"
                                    *ngFor="let location of locationList"
                                    [selectable]="selectable"
                                    [removable]="removable"
                                    (removed)="remove(location)"
                                    [disabled]="
                                    !accountService.hasFeatureAccess(FeatureName.EVENT, PermissionType.UPDATE) 
                                    || eventStatus === 'CLOSED'
                                    "
                                  >
                                    {{ location.name }}
                                    <i
                                      matChipRemove
                                      class="icon x-16 icon-close-mini DSA_wb_chipIconRight"
                                      *ngIf="removable"
                                    ></i>
                                  </mat-chip>
                                  <input
                                    placeholder="New location..."
                                    [matChipInputFor]="locationChipList"
                                    [matChipInputSeparatorKeyCodes]="
                                      separatorKeysCodes
                                    "
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    (matChipInputTokenEnd)="add($event)"
                                    (blur)="onInputBlur($event)"
                                    (keyup)="onKeyUp($event)"
                                    [disabled]="
                                    !accountService.hasFeatureAccess(FeatureName.EVENT, PermissionType.UPDATE)  
                                    || eventStatus === 'CLOSED'
                                    "
                                  />
                                </mat-chip-list>
                                <mat-error *ngIf="locationChipList.errorState"
                                  >Special characters except '_,.@&()-' are not
                                  allowed</mat-error
                                >
                              </mat-form-field>
                            </div>

                            <div class="col-12 d-flex justify-content-end">
                              <button
                                class="DSA_wb_miniPrimary-btn"
                                type="submit"
                                (click)="modifyEvent()"
                                *ngIf="
                                  accountService.hasFeatureAccess(FeatureName.EVENT, PermissionType.UPDATE) &&
                                  this.eventStatus !== 'CLOSED'
                                "
                                [disabled]="updateEvent.invalid"
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <!-- CREATE EVENT -->
                  <div class="col-lg-12" *ngIf="!showTable">
                    <div class="DSA_panel">
                      <form [formGroup]="createEvent">
                        <div class="DSA_panelBody padTB16 padLR24">
                          <div class="row">
                            <div class="col-lg-6 col-md-6">
                              <mat-form-field class="DSA_Wb_custom-form-field">
                                <mat-label>Event Name</mat-label>
                                <input
                                  matInput
                                  placeholder="Event Name"
                                  formControlName="eventName"
                                />
                                <mat-error
                                  *ngIf="
                                    createEvent.controls['eventName'].dirty &&
                                    createEvent.controls['eventName'].hasError(
                                      'pattern'
                                    )
                                  "
                                  >Special characters except '_,.@&()-' are not
                                  allowed</mat-error
                                >
                              </mat-form-field>
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <mat-form-field class="DSA_Wb_custom-form-field DSA_form_nofloatLabel">
                                <mat-label>Event Type</mat-label>
                                <mat-select
                                  formControlName="eventType"
                                  disableOptionCentering  panelClass="DSA_wb-custom-select-panel" class="DSA_wb-custom-select"
                                >
                                  <mat-option
                                    *ngFor="let option of eventTypeArray"
                                    [value]="option"
                                  >
                                    {{ option }}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>

                            <div class="col-lg-6 col-md-6">
                              <mat-form-field class="DSA_Wb_custom-form-field">
                                <input
                                  matInput
                                  [matDatepicker]="picker"
                                  placeholder="From Date"
                                  (click)="picker.open()"
                                  formControlName="fromDate"
                                />
                                <mat-datepicker-toggle class="DSA_wb_mat-suffix" [for]="picker">
                                  <i
                                    class="icon x-24 calendar-icon"
                                    matDatepickerToggleIcon
                                  ></i>
                                </mat-datepicker-toggle>
                                <mat-datepicker
                                  panelclass="DSA_wb_dateClass"
                                  #picker
                                ></mat-datepicker>
                              </mat-form-field>
                            </div>
                            <div class="col-lg-6 col-md-6">
                              <mat-form-field class="DSA_Wb_custom-form-field">
                                <input
                                  matInput
                                  [matDatepicker]="picker1"
                                  placeholder="To Date"
                                  (click)="picker1.open()"
                                  formControlName="toDate"
                                />
                                <mat-datepicker-toggle
                                  class="DSA_wb_mat-suffix"
                                  [for]="picker1"
                                >
                                  <i
                                    class="icon x-24 calendar-icon"
                                    matDatepickerToggleIcon
                                  ></i>
                                </mat-datepicker-toggle>
                                <mat-datepicker
                                  panelclass="DSA_wb_dateClass"
                                  #picker1
                                ></mat-datepicker>
                              </mat-form-field>
                            </div>

                            <div class="col-lg-6 col-md-6">
                              <mat-form-field class="DSA_Wb_custom-form-field">
                                <mat-label>Location</mat-label>
                                <mat-chip-list #locationChipList>
                                  <mat-chip
                                    class="DSA_wb_chipStyle-Normal DSA_wb_chip-input marR16"
                                    disableRipple="true"
                                    *ngFor="let location of locationList"
                                    [selectable]="selectable"
                                    [removable]="removable"
                                    (removed)="remove(location)"
                                    [disabled]="
                                      eventStatus === 'CLOSED'
                                    "
                                  >
                                    {{ location.name }}
                                    <i
                                      matChipRemove
                                      class="icon x-16 icon-close-mini DSA_wb_chipIconRight"
                                      *ngIf="removable"
                                    ></i>
                                  </mat-chip>
                                  <input
                                    placeholder="New location..."
                                    [matChipInputFor]="locationChipList"
                                    [matChipInputSeparatorKeyCodes]="
                                      separatorKeysCodes
                                    "
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    (matChipInputTokenEnd)="add($event)"
                                    (blur)="onInputBlur($event)"
                                    (keyup)="onKeyUp($event)"
                                    [disabled]="
                                      eventStatus === 'CLOSED'
                                    "
                                  />
                                </mat-chip-list>
                                <mat-error *ngIf="locationChipList.errorState"
                                  >Special characters except '_,.@&()-' are not
                                  allowed</mat-error
                                >
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="d-flex justify-content-end">
                            <button
                              class="DSA_wb_miniSecondary-btn mr-3"
                              type="reset"
                              [routerLink]="['/dashboard']"
                            >
                              Cancel
                            </button>
                            <button
                              class="DSA_wb_miniPrimary-btn"
                              type="submit"
                              (click)="addEvent()"
                              [disabled]="createEvent.invalid"
                            >
                              Create
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <!-- create event btn -->
                  <!-- <div class="row" *ngIf="showTable">
                  <div
                    class="col-lg-12"
                    *ngIf="
                      accountService
                        .getAccountLocal()
                        .authorities.includes('ROLE_EVENT_ADMIN') ||
                      accountService
                        .getAccountLocal()
                        .authorities.includes('ROLE_ADMIN_RECRUITER')
                    "
                  >
                    <div class="">
                      <hr class="marB24 marT24 DSA_horSeperator" />
                      <div>
                        <button
                          mat-flat-button
                          class="DSA_wb_primary-btn"
                          (click)="showTable = false"
                        >
                          Create Event
                        </button>
                      </div>
                    </div>
                  </div>
                </div> -->
                  <div class="row mt-5 w-100" *ngIf="showTable">
                    <div class="col-lg-12">
                      <div
                        class="DSA_wb_customTab DSA_navTabWhite DSA_wb_Secondary"
                      >
                        <nav class="custom-bg-white" mat-tab-nav-bar>
                          <a
                            mat-tab-link
                            *ngFor="let link of links"
                            [hidden]="link.hide" 
                            [routerLink]="link.link"
                            routerLinkActive
                            #rla="routerLinkActive"
                            [active]="rla.isActive"
                            [disabled]="link.disabled"
                            [style.opacity]="rla.isActive ? 1 : 0.7"
                            (click)="activeLink = link.link"
                          >
                            {{ link.label }}
                          </a>
                        </nav>
                        <div class="DSA_breadcrumb mt-3" *ngIf="selectedJob">
                          <a> Job: {{ selectedJob.title }} </a>
                        </div>
                      </div>
                    </div>
                    <div class="w-100">
                      <router-outlet></router-outlet>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="!loading && error"
  class="marT32 padTB16 padLR24 d-flex flex-column align-items-center"
>
  <img src="assets/images/no_data_found.svg" alt="" class="no-data-img" />
  <h4 class="marT16">Request timed out. Please try again after sometime.</h4>
</div>
