<app-loader [display]="loader"></app-loader>
<div *ngIf="!loader" class="filter-dialog">
  <div *ngIf="filterDataAvailable; else showError">
    <h3 mat-dialog-title class="marB8">Filter By</h3>
    <mat-dialog-content class="container-fluid">
      <div *ngFor="let filter of filterByColumns">
        <div *ngIf="searchMap && filter && searchMap[filter.key]?.length > 0">
          <div class="row padTB8 filter-row">
            <div
              class="col-md-2 d-flex align-items-center nopadLR filter-criteria"
            >
              <span [ngClass]="filter.key" class="filter-key">
                {{ filter.value }}
              </span>
            </div>
            <div class="col-md-10 d-flex flex-wrap">
              <div class="row w-100">
                <mat-checkbox
                  *ngFor="let data of searchMap[filter.key]"
                  (change)="manageFilters($event, filter.key, data.value)"
                  class="
                    DSA_Wb_custom-checkbox
                    col-md-6 col-lg-4
                    nomarLR
                    DSA_text-1line
                  "
                  aria-label="checkbox"
                  disableRipple="true"
                  [checked]="filterData[filter.key]?.includes(data.value)"
                  [matTooltip]="
                    getToolTip(filter.key, data.value, data.frequency)
                  "
                  matTooltipClass="DSA_tooltip"
                >
                  {{
                    filter.key === "spoc"
                      ? data.value
                      : data.value.split("_").join(" ")
                  }}
                  <span *ngIf="showFrequency && data.frequency > 0">
                    ({{ data.frequency }})
                  </span>
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-between">
      <button
        mat-flat-button
        [mat-dialog-close]="null"
        class="DSA_wb_text-btn font-secondary"
      >
        Clear filters
      </button>
      <div>
        <button
          mat-flat-button
          [mat-dialog-close]="false"
          class="DSA_wb_miniSecondary-btn"
        >
          Cancel
        </button>
        <button
          mat-flat-button
          [mat-dialog-close]="filterData"
          class="DSA_wb_miniPrimary-btn"
        >
          Apply Filters
        </button>
      </div>
    </mat-dialog-actions>
  </div>
  <ng-template #showError>
    <h3 mat-dialog-title class="marB8">Message</h3>
    <mat-dialog-content class="container-fluid">
      {{ errorMessage }}
    </mat-dialog-content>
    <mat-dialog-actions class="justify-content-end">
      <button
        mat-flat-button
        [mat-dialog-close]="false"
        class="DSA_wb_miniSecondary-btn"
      >
        Close
      </button>
    </mat-dialog-actions>
  </ng-template>
</div>
