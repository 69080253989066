<div *ngIf="params.type === 'skill'">
  <span *ngFor="let skill of skills_candidate?.split(',')">
    <mat-chip
      class="DSA_wb_chipStyle-Normal DSA_choiseChip marL8"
      disableRipple="true"
      >{{ skill }}</mat-chip
    >
  </span>
</div>

<div *ngIf="params.type === 'skills'">
  <span *ngFor="let skill_p of skills_panelist?.split(',')">
    <mat-chip
      class="DSA_wb_chipStyle-Normal DSA_choiseChip marL8"
      disableRipple="true"
      >{{ skill_p }}</mat-chip
    >
  </span>
</div>

<!-- <div *ngIf="params.type === 'checkbox'">
  <mat-checkbox
    class="DSA_Wb_custom-checkbox"
    (change)="checkboxToggle($event, eventId)"
    aria-label="checkbox"
    disableRipple="true"
    [checked]="getEventCheckedStatus(eventId)"
  ></mat-checkbox>
</div> -->
