import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { IPagination } from 'src/app/shared/model/pagination.model';
import { scholarshipFilterColumns } from 'src/app/app.constants';

import { SharedService } from 'src/app/services/shared.service';
import { HiringManagerService } from '../services/hiring-manager.service';
import { Observable, Subject } from 'rxjs';
import { SafeHtml } from '@angular/platform-browser';
import { UsersListActionRenderComponent } from './users-list-action-render/users-list-action-render.component';
import { FileService } from 'src/app/services/file.service';
import { MatDialog } from '@angular/material/dialog';
import { UserslistAddUserComponent } from './userslist-add-user/userslist-add-user.component';
import { JobsAdminDashboardStateStorageService } from '../../iwp-platform-actions/infy-jobs-admin/jobs-admin-dashboard-state-storage.service';
import { UsersListDashboardStateStorageService } from './users-list-dashboard-state-storage.service';
import { AuthorityNameUI } from 'src/app/shared/model/enumerations/authority-name-ui.model';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements OnInit {
  bulkuploadFile: any;
  bulkUploadSlots: any;
  searchedText: string;
  isLoading: boolean = false;
  isError: boolean = false;
  page: number = 0;
  size = 25;
  pageSize: number = 10;
  searchCondition: boolean = true;
  totalElements: number;
  filters;
  filterByColumns = scholarshipFilterColumns;
  config: any;
  levels: number = 0;
  // tableName= 'Scholarships';
  buttonText: string = 'Event Status(s)';
  rowData;
  refreshAgGrid: Subject<void> = new Subject<void>();
  sanitizedHtml: SafeHtml;
  columnDefs: any = [
    {
      headerName: 'First Name',
      field: 'additionalAttributes.firstname',
      hide: false,
      required: true,
      minWidth: 250,
    },
    {
      headerName: 'Last Name',
      field: 'additionalAttributes.lastname',
      hide: false,
      required: true,
      minWidth: 80,
    },
    {
      headerName: 'Role',
      field: 'specType',
      hide: false,
      required: true,
      minWidth: 80,
      valueFormatter: this.roleFormatter,
    },
    {
      headerName: 'Phone',
      field: 'additionalAttributes.phone',
      hide: false,
      minWidth: 80,
    },
    {
      headerName: 'Email',
      field: 'email',
      hide: false,
      minWidth: 100,
    },
    {
      headerName: 'Actions',
      field: 'action',
      cellRendererFramework: UsersListActionRenderComponent,
      hide: false,
      minWidth: 50,
    },
  ];

  constructor(
    private dialog: MatDialog,
    private hiringManagerService: HiringManagerService,
    private userListDashboardStateService: UsersListDashboardStateStorageService,
    private router: Router,
    private fileService: FileService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    const { page, size, searchQuery } = this.userListDashboardStateService;

    if (page && size) {
      this.page = page;
      this.size = size;
    }
    if (searchQuery) {
      this.searchedText = searchQuery;
    }
  }

  htmlFormatter(params) {
    if (!params.data) return '';

    const html = params.data.about;
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(html, 'text/html');
    return parsedHtml.body.textContent || '';
  }

  roleFormatter(params: any) {
    if (!params.data) return;
    const role = params.data.specType;
    return AuthorityNameUI[role];
  }

  pageChanged(event) {
    this.config.currentPage = event;
  }

  getAllUsers = () => {
    this.isLoading = true;
    this.isError = false;
    this.hiringManagerService.getUsersList(this.page, this.pageSize).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.totalElements = res.totalElements;
        this.rowData = res.content;
      },
      error: (err) => {
        this.isError = true;
        this.isLoading = false;
        this.sharedService.openErrorSnackBar(
          'Error occurred, please try again',
          ''
        );
      },
    });
  };

  onPageChange = (e) => {
    this.userListDashboardStateService.page = e.target.page;
    this.userListDashboardStateService.size = e.target.size;
    this.userListDashboardStateService.searchQuery = e.target.searchQuery;
  };

  handlePageChange = (e: IPagination) => {
    this.page = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getAllUsers();
  };

  handleFilterChange = (e) => {
    this.filters = e;
    this.getAllUsers();
    this.refreshAgGrid.next();
  };

  interface = (
    size: number,
    page: number,
    query?: string
  ): Observable<any[]> => {
    return this.hiringManagerService.getUsersList(page, size, query);
  };

  addNewUser() {
    const dialogRef = this.dialog.open(UserslistAddUserComponent, {
      disableClose: true,
      backdropClass: 'backdrop-background',
      data: { users: '' },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value) {
        this.refreshAgGrid.next();
      }
    });
  }

  bulkUpload = (files) => {
    this.bulkuploadFile = files[0];
  };

  uploadBulkCSV = () => {
    let formData = new FormData();
    const specData = {
      tenantId: localStorage.getItem('tenantId'),
    };
    formData.append('file', this.bulkuploadFile);
    formData.append('specData', JSON.stringify(specData));

    this.bulkuploadFile = undefined;

    this.fileService.bulkUploadUserWithRoles(formData).subscribe(
      (res) => {
        this.sharedService.openSnackBar('Uploaded Successfully', '', 3000);
        this.refreshAgGrid.next();
      },
      () => {
        this.sharedService.openSnackBar('Some Error Occured', '', 3000);
      }
    );
  };

  // getFormattedDate(params: any) {
  //   if (!params.data) return '';
  //   const _date = new Date(params.value);
  //   return moment(_date).format('DD/MM/YYYY');
  // }
  // linkFormatter(params: any) {
  //   if (this.accountService.getCurrentRole() == 'ROLE_HIRING_MANAGER') {
  //     this.router.navigate(['scp/scholarship-data/'+ params.value.id+'/applicants']);
  //   }
  // }
}
