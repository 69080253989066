import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-age-confirmation',
  templateUrl: './age-confirmation.component.html',
  styleUrls: ['./age-confirmation.component.scss'],
})
export class AgeConfirmationComponent implements OnInit {
  is18Plus: boolean = false;
  constructor() {}

  ngOnInit(): void {}
}
