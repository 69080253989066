import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-template-params',
  templateUrl: './add-template-params.component.html',
  styleUrls: ['./add-template-params.component.scss'],
})
export class AddTemplateParamsComponent implements OnInit {
  mailTemplateParamForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddTemplateParamsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.mailTemplateParamForm = this.fb.group({
      name: ['', [Validators.required]],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {}
}
