<button
  mat-flat-button
  class="DSA_wb_Icon-btn marL16 sort-button"
  (click)="sortByDate()"
  [matTooltip]="
    sortDateDescending ? 'Sort in Ascending order' : 'Sort in Descending Order'
  "
  matTooltipClass="DSA_tooltip"
>
  <span class="icon_btnCont">
    <i [ngClass]="sortIconClass" aria-label="sort"></i>
  </span>
  Sort By Start Time
</button>
