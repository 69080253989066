import { Component, Input, OnInit } from '@angular/core';
import { IwpSetting } from 'src/app/shared/model/iwp-setting.model';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '../../../services/settings.service';
import { faGlasses } from '@fortawesome/free-solid-svg-icons';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'src/app/services/shared.service';
import { AccountService } from 'src/app/services/account.service';
import { FeatureName } from 'src/app/shared/model/enumerations/feature-name.model';
import { PermissionType } from 'src/app/shared/model/enumerations/permission-type.model';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  selectedIndex: number;
  eventStatus: string;

  rsvpResponseSelected = 'SPOC';
  mailGroupingSelected = 'GROUP_PANELLISTS';
  val: any;
  mode: string = '';
  firstDisplay: '';
  firstDec: '';
  firstValueType: '';
  criteriaformnull = null;

  loading = false;

  subcategories = [];

  cardArray = [];

  filteredIwpSettings: Array<IwpSetting> = [];

  onMail(): void {}
  category: string;
  categoryId: string;

  iwpSettingsData: Map<String, Array<IwpSetting>> = new Map<
    String,
    Array<IwpSetting>
  >();

  settingForm: FormGroup = new FormGroup({});
  initialValues = {};

  FeatureName = FeatureName;
  PermissionType = PermissionType;

  constructor(
    public _location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private settingService: SettingsService,
    private sharedService: SharedService,
    public accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.eventStatus = localStorage.getItem('eventStatus');
    if (this.eventStatus === null) {
      this.router.navigate(['/dashboard']);
    }
    this.activatedRoute.params.subscribe((params) => {
      this.category = params.category;
      this.categoryId = params.categoryId;

      this.getData();
    });
  }

  filterData = () => {
    this.filteredIwpSettings = this.iwpSettingsData[this.mode];
    this.createDynamicForm();
  };

  createDynamicForm = () => {
    let group = {};
    this.filteredIwpSettings.forEach((setting) => {
      if (setting.valueType === 'BOOLEAN') {
        group[setting.id] = new FormControl({
          value:
            setting.value === null
              ? setting.defaultValue === 'true'
                ? true
                : false
              : setting.value === 'false'
              ? false
              : true,
          disabled:
            this.eventStatus === 'CLOSED' ||
            !this.accountService.hasFeatureAccess(
              FeatureName.EVENT_SETTINGS,
              PermissionType.UPDATE
            ),
        });
      } else if (setting.valueType === 'DURATION') {
        group[setting.id] = new FormControl(
          {
            value:
              setting.value === null ? setting.defaultValue : setting.value,
            disabled:
              this.eventStatus === 'CLOSED' ||
              !this.accountService.hasFeatureAccess(
                FeatureName.EVENT_SETTINGS,
                PermissionType.UPDATE
              ),
          },
          [Validators.pattern(/^[1-9][0-9]*[m|h|s]$/), Validators.required]
        );
      } else {
        group[setting.id] = new FormControl({
          value: setting.value ? setting.value : setting.defaultValue,
          disabled:
            this.eventStatus === 'CLOSED' ||
            !this.accountService.hasFeatureAccess(
              FeatureName.EVENT_SETTINGS,
              PermissionType.UPDATE
            ),
        });
      }
    });
    this.settingForm = new FormGroup(group);
    this.initialValues = this.settingForm.value;
  };

  onReset() {
    this.settingForm.reset(this.initialValues);
    return false;
  }

  onSubmit() {
    let updatedSettings = [];
    Object.keys(this.settingForm.value).forEach((key) => {
      // push only if the value of setting is changed
      if (this.settingForm.value[key] !== this.initialValues[key]) {
        updatedSettings.push({
          id: key,
          value: this.settingForm.value[key],
        });
      }
    });

    if (updatedSettings.length > 0) {
      // call api to save settings
      this.settingService.setSettings(updatedSettings).subscribe(
        (data: IwpSetting) => {
          this.getData();
          this.sharedService.openSnackBar(
            `${this.mode} settings saved successfully`,
            '',
            3000
          );
        },
        (err) => {
          this.sharedService.openSnackBar(
            `There was some error. Please try again`,
            '',
            3000
          );
        }
      );
    } else {
      this.sharedService.openSnackBar('No settings modified', '', 3000);
    }
  }

  getData = () => {
    this.loading = true;
    this.settingService
      .getSettings(this.category, this.categoryId)

      .subscribe((data: Map<String, Map<String, IwpSetting>>) => {
        this.subcategories = Object.keys(data);
        Object.keys(data).forEach((key) => {
          this.iwpSettingsData[key] = Object.values(data[key]);
        });
        if (!this.mode)
          this.mode = this.subcategories[0] ? this.subcategories[0] : '';
        this.loading = false;
        this.filterData();
      });
  };

  changeTab = (e) => {
    this.mode = this.subcategories[e.index];
    this.selectedIndex = e.index;
    this.filterData();
  };
}
