import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apply-stepper',
  templateUrl: './apply-stepper.component.html',
  styleUrls: ['./apply-stepper.component.scss']
})
export class ApplyStepperComponent implements OnInit {

  constructor() { }
  stepper_page_names = ["Personal Info", "Education Info", "Family or Guardian Details", "Problem Statement Details"];
  activeIndex = 0;
  
  ngOnInit(): void {
    console.log("Hello")
  }

}
