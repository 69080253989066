import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccountService } from 'src/app/services/account.service';
import { SharedService } from 'src/app/services/shared.service';
import { ResumeUploadService } from '../services/resume-upload.service';
import { CandidateInfoService } from '../services/candidate-info.service';
import { ResumeProcessingDialogComponent } from './resume-processing-dialog/resume-processing-dialog.component';

@Component({
  selector: 'app-upload-resume',
  templateUrl: './upload-resume.component.html',
  styleUrls: ['./upload-resume.component.scss'],
})
export class UploadResumeComponent implements OnInit {
  attachedFile;
  private intervalId: any;

  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;

  allowedFileTypes = {
    mimeTypes: [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
    ],
    extensions: ['.doc', '.docx', '.pdf'],
  };
  fileErrorMessage: string = '';
  individualFileMaxSizeInMB = 2;

  fileNameRegex = /^[\w\- ]+$/;

  @Output() onResumeUploadComplete = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    private accountService: AccountService,
    private sharedService: SharedService,
    private resumeUploadService: ResumeUploadService,
    private candidateInfoService: CandidateInfoService
  ) {}

  ngOnInit(): void {}

  onBrowseFilesClick() {
    if (this.fileUpload) this.fileUpload.nativeElement.click();
  }

  onFileSelected(event) {
    if(event.target.files.length === 0) {
      return;
    }
    let file = event.target.files.item(0);
    let isFileValid = true;
    if (this.validateFile(file)) {
      this.attachedFile = file;
    } else {
      isFileValid = false;
    }

    if (isFileValid) this.fileErrorMessage = null;
  }

  validateFile = (file: File) => {
    const fileName = file.name.substring(0, file.name.lastIndexOf('.'));
    // filetype
    if (!this.allowedFileTypes.mimeTypes.includes(file.type)) {
      this.fileErrorMessage = `Only ${this.allowedFileTypes.extensions.join(
        ', '
      )} files with size less than ${
        this.individualFileMaxSizeInMB
      }MB can be uploaded.`;
      return false;
    }

    //file size
    if (!(file.size / 1024 / 1024 < this.individualFileMaxSizeInMB)) {
      this.fileErrorMessage = `File size should be less than 2MB. Please reduce size of file <u>${fileName}</u> and upload again`;
      return false;
    }

    // filename
    if (!this.fileNameRegex.test(fileName)) {
      this.fileErrorMessage = `File name should not contain any special characters except underscore(_) and hyphen(-). Please rename the file <u>${fileName}</u> and upload again`;
      return false;
    }
    return true;
  };

  UploadResumeFnUtil() {
    if (!this.attachedFile) {
      this.sharedService.openErrorSnackBar(
        'Please select a file to upload',
        ''
      );
    } else {
      this.uploadResume();
    }
  }

  uploadResume() {
    const dialogRef = this.dialog.open(ResumeProcessingDialogComponent, {
      maxWidth: 424,
      disableClose: true,
    });
    let formData = new FormData();
    const specData = {
      userId: this.accountService.getUserId(),
      comment: 'This is resume',
      uploadedBy: 'CANDIDATE',
      tenantId: parseInt(localStorage.getItem('tenantId')),
    };
    formData.append('specData', JSON.stringify(specData));
    formData.append('file', this.attachedFile);

    this.resumeUploadService.upload(formData).subscribe({
      next: (res: any) => {
        this.checkIfResumeProcessingFinishedSuccessfully(dialogRef);
      },
      error: (err: any) => {
        this.sharedService.openErrorSnackBar(
          'File upload failed. Please try again',
          ''
        );
        dialogRef.close();
      },
    });
  }

  handleFileChange = (files: Array<File>) => {
    this.attachedFile = files;
  };

  checkIfResumeProcessingFinishedSuccessfully(
    dialogRef?: MatDialogRef<any>
  ): void {
    let userId = this.accountService.getUserId();
    this.intervalId = setInterval(() => {
      this.candidateInfoService.getFileStatus(userId).subscribe({
        next: (res: any) => {
          if (res != null) {
            const obj: any = res;
            if (obj.content.length > 0) {
              if (obj.content[0].resumeStage == 'PROCESSED') {
                clearInterval(this.intervalId);
                dialogRef?.close();
                this.onResumeUploadComplete.emit();
              } else if (obj.content[0].resumeStage == 'FAILED') {
                clearInterval(this.intervalId);
                this.sharedService.openErrorSnackBar(
                  'File Extraction failed. Please try again',
                  ''
                );
                dialogRef?.close();
              }
            }
          }
        },
        error: (err: any) => {
          this.sharedService.openErrorSnackBar(
            'Server error while polling extracted resume status',
            ''
          );
          dialogRef?.close();
          clearInterval(this.intervalId);
        },
      });
    }, 4000);
  }
}
