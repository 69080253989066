import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { BreakDetail } from '../modules/iwp-interviewer/infy-panelist-break-slots/infy-panelist-break-slots.component';

@Injectable({
  providedIn: 'root',
})
export class InterviewerService {
  constructor(private http: HttpClient) {}

  private headers = new HttpHeaders().set('content-type', 'application/json');
  // .set('Authorization', 'Bearer ' + localStorage.getItem('token'))

  getAllEventsForInterviewer = (size, page, eventName?) => {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);
    if (eventName) {
      params = params.append('eventName', `${eventName}`);
    }
    return this.http.get<any[]>(
      environment.interviewServiceUrl + 'api/events/interviewer',
      { headers: this.headers, params }
    );
  };

  submitSlotForPanelist = (data: any) => {
    return this.http.post(
      environment.assistedScheduleServiceUrl + 'api/status/setPanelistStatus',
      data,
      { headers: this.headers }
    );
  };

  getPanelistSelectedTimeSlots = (email: string, eventId: number) => {
    return this.http.get<any[]>(
      `${environment.assistedScheduleServiceUrl}api/status/setPanelistStatus?email=${email}&eventId=${eventId}`,
      { headers: this.headers }
    );
  };

  getPanelistBreakDetails = (
    email: string,
    eventId: number
  ): Observable<any> => {
    let params = new HttpParams();
    params = params.append('email', `${email}`);
    params = params.append('eventId', `${eventId}`);

    // return of({
    //   eventId: 0,
    //   email: 'string',
    //   breaktype: 'LUNCH',
    //   breakCount: 2,
    //   onBreak: true,
    // });

    return this.http.get<any[]>(
      `${environment.assistedScheduleServiceUrl}api/breakCount`,
      { headers: this.headers, params }
    );
  };

  setPanelistBreakDetails = (breakDetail: BreakDetail) => {
    return this.http.post(
      `${environment.assistedScheduleServiceUrl}api/breakCount`,
      breakDetail,
      { headers: this.headers }
    );
  };

  setPanelistAvailabilityStatus = (availabilityDetails) => {
    return this.http.post(
      `${environment.assistedScheduleServiceUrl}api/panelistStatus`,
      availabilityDetails
    );
  };

  getInterviewerList = () => {
    let params = new HttpParams();
    params = params.append('size', `${20000}`);

    const opts = {
      headers: this.headers,
      params,
    };
    return this.http.get<any[]>(
      environment.profileServiceUrl + 'api/interviewers',
      opts
    );
  };

  searchInterviewer = (query: string) => {
    return this.http.get(
      `${environment.profileServiceUrl}api/interviewers/search/v2/${query}`
    );
  };

  // ! exactly same in service.service and recruiter.service
  // getInterviewerList = () => {
  //   let params = new HttpParams();
  //   params = params.append('size', `${20000}`);

  //   const opts = {
  //     headers: this.headers,
  //     params,
  //   };
  //   return this.http.get<any[]>(
  //     environment.profileServiceUrl + 'api/interviewers',
  //     opts
  //   );
  // }

  getInterviewData = () => {
    let params = new HttpParams();
    params = params.append('size', `${20000}`);
    params = params.append('page', `${0}`);

    return this.http.get(
      `${environment.interviewServiceUrl}api/interviewer/interviews`,
      { headers: this.headers, params }
    ) as Observable<any>;
  };

  getInterviewerAvailability = (data): Observable<any> => {
    return this.http.post(
      `${environment.interviewServiceUrl}api/timeslot/interviewers`,
      data,
      { headers: this.headers }
    );
  };
  
  getInterviewById = (interviewId: any) => {
    return this.http.get(
      `${environment.interviewServiceUrl}api/interviews/${interviewId}/candidate-interview-round`,
      { headers: this.headers }
    ) as Observable<any>;
  };

  addInterviewersToInterview = (
    eventId,
    interviewId,
    panellistIds
  ): Observable<any> => {
    return this.http.post(
      `${environment.interviewServiceUrl}api/interviews/${interviewId}/actions/add-panellists`,
      { eventId, panellistIds },
      { headers: this.headers }
    );
  };
}
