<h1 mat-dialog-title>Add Template Paramter</h1>
<div mat-dialog-content>
  <form class="container-fluid" [formGroup]="mailTemplateParamForm">
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="fill" class="DSA_Wb_custom-form-field">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <button mat-button (click)="onNoClick()" class="DSA_wb_miniSecondary-btn">
    Close
  </button>
  <button
    mat-button
    class="DSA_wb_miniPrimary-btn"
    [disabled]="mailTemplateParamForm.invalid"
    [mat-dialog-close]="mailTemplateParamForm.value"
    cdkFocusInitial
  >
    Add
  </button>
</div>
