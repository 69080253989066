<h1 mat-dialog-title>Add Panelist(s)</h1>
<app-loader [display]="isLoading"></app-loader>
<ng-container *ngIf="!isLoading">
  <div mat-dialog-content>
    <app-event-panelist-select-input
      [eventId]="eventId"
      [panelistList]="panelistsToAdd"
      [emailToFilter]="data.emailsToFilter"
      (panelistChange)="handlePanelistChange($event)"
    ></app-event-panelist-select-input>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-end">
    <button
      mat-button
      [mat-dialog-close]="false"
      class="DSA_wb_miniSecondary-btn"
    >
      Cancel
    </button>
    <button
      mat-button
      class="DSA_wb_miniPrimary-btn"
      [disabled]="panelistsToAdd?.length == 0"
      (click)="addPanelistsToInterview()"
    >
      Add
    </button>
  </div>
</ng-container>
