import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, Subject, BehaviorSubject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CandidateService } from './candidate.service';
import { InterviewRoundsService } from './interview-rounds.service';
import { RoutesService } from './routes.service';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  selectedJob;

  constructor(
    private http: HttpClient,
    private candidateService: CandidateService,
    private interviewRoundsService: InterviewRoundsService,
    private routesService: RoutesService
  ) {}

  private headers = new HttpHeaders()
    .set('content-type', 'application/json');

  // ! not used
  // getJobRoles = () => {
  //   let params = new HttpParams();
  //   params = params.append('size', `${20000}`);

  //   const opts = {
  //     headers: this.headers,
  //     params,
  //   };
  //   return this.http.get<any[]>(
  //     environment.interviewServiceUrl + 'api/job-roles',
  //     opts
  //   );
  // }

  getJobRoles = (size, page, jobTitle?) => {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);
    params = params.append('sort', `title,asc`);

    if (jobTitle) {
      params = params.append('jobTitle', `${jobTitle}`);
    }

    const opts = {
      headers: this.headers,
      params,
    };
    return this.http.get<any[]>(
      environment.interviewServiceUrl + 'api/job-roles',
      opts
    );
  };

  postJobRole = (formData) => {
    return this.http.post(
      environment.interviewServiceUrl + 'api/job-roles',
      formData,
      { headers: this.headers }
    );
  };

  updateJobRole = (formData) => {
    return this.http.put(
      environment.interviewServiceUrl + 'api/job-roles',
      formData,
      { headers: this.headers }
    );
  };

  deleteJobRole = (id) => {
    return this.http.delete(
      environment.interviewServiceUrl + 'api/job-roles/' + id,
      { headers: this.headers }
    );
  };

  setSelectedJob = (job: any) => {
    this.selectedJob = job;
    this.routesService.reStructureRoutesOn(true);
  };

  getSelectedJob = () => {
    return this.selectedJob;
  };

  clearSelectedJob = () => {
    this.selectedJob = null;
  };

  createJob = (data): Observable<any> => {
    return this.http.post(
      environment.interviewServiceUrl + 'api/job-openings',
      data,
      { headers: this.headers }
    );
  };

  updateJob = (data): Observable<any> => {
    return this.http.put(
      environment.interviewServiceUrl + 'api/job-openings',
      data,
      { headers: this.headers }
    );
  };

  getAllJobs = (): Observable<any[]> => {
    return this.http.get<any[]>(
      environment.interviewServiceUrl + 'api/job-openings',
      { headers: this.headers }
    );
  };

  getAllJobsByEvent = (id, page, size): Observable<any> => {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);

    return this.http.get<any[]>(
      environment.interviewServiceUrl + 'api/job-openings/event/' + id,
      { headers: this.headers, params }
    );
  };

  deleteJobInEvent = (jobId, eventId): Observable<any> => {
    return this.http.delete(
      environment.interviewServiceUrl +
        `/api/job-openings/event/${jobId}/${eventId}`,
      { headers: this.headers }
    );
  };

  getJobRoleById = (id) => {
    return this.http.get(
      environment.interviewServiceUrl + 'api/job-roles/' + id,
      { headers: this.headers }
    );
  };

  getAssociatedJobs = (jobRoleId) => {
    return this.http.get<any[]>(
      environment.interviewServiceUrl +
        'api/associated-jobs/job-role/' +
        jobRoleId,
      { headers: this.headers }
    );
  };

  addAssociatedJobs = (jobs) => {
    return this.http.post<any>(
      environment.interviewServiceUrl + 'api/associated-jobs/add-jobs',
      jobs,
      { headers: this.headers }
    );
  };

  deleteAssociatedJobs = (jobs) => {
    return this.http.put<any>(
      environment.interviewServiceUrl + 'api/associated-jobs/remove-jobs',
      jobs,
      { headers: this.headers }
    );
  };

  // add associations for event level
  getAssociatedJobsForEvent = (eventId, jobRoleId) => {
    return this.http.get<any>(
      `${environment.interviewServiceUrl}api/events/${eventId}/jobroles/${jobRoleId}/associatedjobroles`,
      { headers: this.headers }
    );
  };

  // add associations for event level
  addAssociatedJobsForEvent = (eventId, jobRoleId, jobs) => {
    return this.http.post<any>(
      `${environment.interviewServiceUrl}api/events/${eventId}/jobroles/${jobRoleId}/associatedjobroles`,
      jobs,
      { headers: this.headers }
    );
  };

  // remove associations for event level
  deleteAssociatedJobsForEvent = (eventId, jobRoleId, jobs) => {
    return this.http.post<any>(
      `${environment.interviewServiceUrl}api/events/${eventId}/jobroles/${jobRoleId}/associatedjobroles/removeassociation`,
      jobs,
      { headers: this.headers }
    );
  };

  getJobCandidates = (): Observable<any[]> => {
    const opts = {
      headers: this.headers,
    };
    return this.http
      .get<any[]>(
        `${environment.interviewServiceUrl}api/candidate-interview-rounds/job-opening/${this.selectedJob?.id}`,
        opts
      )
      .pipe(
        mergeMap((cirs) => {
          return forkJoin(
            cirs.map((cir) => {
              return this.candidateService
                .getCandidateById(cir.candidateId)
                .pipe(
                  map((candidate) => {
                    const { id, candidateId, ...candt } = candidate;
                    return { ...cir, candId: candidateId, ...candt };
                  })
                );
            })
          );
        })
      );
  };

  addCandidatesToJob = (candidates): Observable<any[]> => {
    return forkJoin(
      candidates.map((candidate) => {
        const cir = {
          jobOpeningId: this.selectedJob?.id,
          candidateId: candidate.id,
        };
        return this.interviewRoundsService.createCandidateInterviewRound(cir);
      })
    );
  };

  // ! not used
  // addCandidatesToJob = (candidates, id): Observable<any[]> => {
  //   return forkJoin(
  //     candidates.map((candidate) => {
  //       const cir = {
  //         jobOpeningId: id,
  //         candidateId: candidate.id,
  //       };
  //       return this.createCandidateInterviewRound(cir);
  //     })
  //   );
  // }

  createCandidateInJob = (formData): Observable<any> => {
    return this.http.post<any>(
      environment.interviewServiceUrl + 'api/candidate/job-opening',
      formData,
      { headers: this.headers }
    );
  };
}
