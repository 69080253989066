<div class="container-fluid">
  <div [formGroup]="form" class="row align-items-center pad32">
    <div class="col-md-6 col-12">
      <h5 class="DSA_wb_text-1line">
        {{ field.displayName }}
      </h5>
      <span class="DSA_wb_caption web-GreyLight d-block marT8">
        {{ field.description }}
      </span>
    </div>
    <div class="col-md-3 offset-md-1 col-12">
      <div [ngSwitch]="field.valueType">
        <mat-slide-toggle
          *ngSwitchCase="'BOOLEAN'"
          [formControlName]="field.id"
          [id]="field.id"
          disableRipple="true"
          labelPosition="before"
        ></mat-slide-toggle>

        <mat-form-field
          class="DSA_Wb_custom-form-field custom-select-withBg"
          *ngSwitchCase="'STRING'"
        >
          <!-- If allowed values are present -->
          <mat-select
            [formControlName]="field.id"
            [id]="field.id"
            disableOptionCentering
            panelClass="DSA_wb-custom-select-panel DSA_wb-custom-select-withBg"
            *ngIf="field.allowedValues"
          >
            <mat-option
              *ngFor="let data of field.allowedValues.split(',')"
              [value]="data"
            >
              {{ data }}
            </mat-option>
          </mat-select>
          <!-- If allowed values is null -->
          <mat-select
            [formControlName]="field.id"
            [id]="field.id"
            disableOptionCentering
            panelClass="DSA_wb-custom-select"
            *ngIf="!field.allowedValues"
            class="DSA_wb-custom-select"
          >
            <mat-option [value]="field.defaultValue">
              {{ field.defaultValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          *ngSwitchCase="'DURATION'"
          style="width: 50%"
          class="DSA_Wb_custom-form-field duration"
        >
          <input
            [formControlName]="field.id"
            [id]="field.id"
            style="padding-left: 10px"
            maxlength="6"
            matInput
          />
          <mat-error
            *ngIf="
              form.controls[field.id].dirty &&
              form.controls[field.id].hasError('pattern')
            "
          >
            Format: 10m/h/s
          </mat-error>
          <mat-error
            *ngIf="
              form.controls[field.id].dirty &&
              form.controls[field.id].hasError('required')
            "
          >
            Required*
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
