import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-attach-files',
  templateUrl: './attach-files.component.html',
  styleUrls: ['./attach-files.component.scss'],
})
export class AttachFilesComponent implements OnInit {
  @Input() files: Array<File> = [];
  @Input() headingText = 'Upload';
  @Input() maxFileSizeInMB: number = 5;
  @Input() acceptMultipleFiles: boolean = true;
  @Input() headerClass: string = 'DSA_wb_h1Header';
  @Input() combinedFileMaxSizeInMB: number = 5;
  @Input() individualFileMaxSizeInMB: number = 2;
  @Input() scholarshipCandidate=true;
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;

  @Output() fileChange = new EventEmitter<Array<File>>();

  fileErrorMessage: string = '';

  fileNameRegex = /^[\w\- ]+$/;
  @Input() additionalDoc = false;

  allowedFileTypes = {
    mimeTypes: [
      'image/jpeg',
      'image/png',
      'application/zip',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
    ],
    extensions: ['.zip', '.doc', '.docx', '.pdf', '.jpg', '.jpeg', '.png'],
  };

  constructor(
    private sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    if (this.additionalDoc) {
      this.allowedFileTypes = {
        mimeTypes: [
          'application/zip',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/pdf',
        ],
        extensions: ['.zip', '.doc', '.docx', '.pdf'],
      };
    }
  }

  validateFile = (file: File) => {
    const fileName = file.name.substring(0, file.name.lastIndexOf('.'));
    // filetype
    if (!this.allowedFileTypes.mimeTypes.includes(file.type)) {
      this.fileErrorMessage = `Only ${this.allowedFileTypes.extensions.join(
        ', '
      )} files with size less than ${
        this.individualFileMaxSizeInMB
      }MB can be uploaded.`;
      return false;
    }

    //file size
    if (!(file.size / 1024 / 1024 < this.individualFileMaxSizeInMB)) {
      this.fileErrorMessage = `Individual file size should be less than 2MB. Please reduce size of file <u>${fileName}</u> and upload again`;
      return false;
    }

    // filename
    if (!this.fileNameRegex.test(fileName)) {
      this.fileErrorMessage = `File name should not contain any special characters except underscore(_) and hyphen(-). Please rename the file <u>${fileName}</u> and upload again`;
      return false;
    }
    return true;
  };

  addFiles = (files: Array<File>) => {
    let isValidFiles = true;
    let totalFileSize: number = 0;
    // get total file size of files already added
    for (const file of this.files) {
      totalFileSize += file.size;
    }
    // add new files if size, type, name and combined file size is accepted
    for (const file of files) {
      if (this.validateFile(file)) {
        totalFileSize += file.size;
        if (totalFileSize / 1024 / 1024 < this.combinedFileMaxSizeInMB) {
          this.files.push(file);
        } else {
          isValidFiles = false;
          this.fileErrorMessage = `Total size (all files combined) should be less than ${this.combinedFileMaxSizeInMB}MB.`;
        }
      } else {
        isValidFiles = false;
      }
    }

    // reset file upload input so that same file can be uploaded again if deleted from this.files
    if (this.fileUpload) this.fileUpload.nativeElement.value = '';

    if (isValidFiles) this.fileErrorMessage = null;
    this.fileChange.emit(this.files);
  };

  getImageUrl = (file) => {
    return this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(file)
    );
  };

  onFileSelected(event) {
    let files = event.target.files;
    this.addFiles(files);
  }

  removeFile(file) {
    let ix;
    if (this.files?.length > 0 && -1 !== (ix = this.files.indexOf(file))) {
      this.files.splice(ix, 1);
      this.fileChange.emit(this.files);
    }
  }

  onBrowseFilesClick(event) {
    if (this.fileUpload) this.fileUpload.nativeElement.click();
  }

  formatBytes = (bytes, decimals = 2) => {
    return this.sharedService.formatBytes(bytes, decimals);
  };
}
