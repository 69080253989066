import { Injectable } from '@angular/core';
import { componentPath } from './candidate-profile.component';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { ICandidateProfileUIDTO } from 'src/app/shared/model/candidate-profile-uidto.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';

@Injectable({
  providedIn: 'root',
})
export class CandidateProfileService {

  candidateApplicationFull = new BehaviorSubject(false);
  setCandidateApplicationCount(status : boolean) {
    this.candidateApplicationFull.next(status)
  }
  uniqueKeyValue: string = null;
  userId: string = null;
  candidateProfileStateKey: string = null;
  candidateProfile: ICandidateProfileUIDTO = { candidateInfoId: null };
  applicationAPIResponseFromViewALL = undefined;
  candidateDetailsFromViewALL: boolean = false;
  currentPath: string = null;
  parentPath: string = null;
  documentListByCategory = null; //To be used in candidate profile child components
  uploadedDoc = []; //To be used in single-file-upload component
  applicationDetailsFetched = false; //To be used in single-file-upload component
  stepperSetupStatus = false; //To be used in 4 stepper components
  applicationId = null;
  opportunityId = null;
  application_limit_key = "application_limit";
  applicationStatus = null;
  applicationModifiedDate = null;
  instituteDetails = null;
  allDegreesList = null;
  maxFamilyAnnualIncome = null;
  sentBackLastChance = false;
  lastUploadedDocumentByCategory = [];
  restrictedNavigationOnStatus = [
    null,
    'INTERMITTENT',
    'SENT_BACK_TO_CANDIDATE',
  ];
  applicationEditableOnStatus = [null, 'INTERMITTENT', 'SUBMITTED'];
  uploadedDocument$ = new Subject<any>();
  candidateInfoId$ = new Subject<number>();
  resetDocument$ = new Subject<string[]>();
  bankProofDisable$ = new Subject<boolean>();
  stepperSetupStatus$ = new Subject<boolean>();
  fileBlob = null;
  stepper: MatStepper;
  partialId=null;

  resetValues() {
    this.applicationAPIResponseFromViewALL = undefined;
    this.candidateDetailsFromViewALL = false;
    this.currentPath = null;
    this.parentPath = null;
    this.applicationDetailsFetched = false;
    this.stepperSetupStatus = false;
    this.applicationId = null;
    this.applicationStatus = null;
    this.applicationModifiedDate = null;
    this.maxFamilyAnnualIncome = null;
    this.uploadedDoc = [];
    this.documentListByCategory = null;
    this.candidateProfile = { candidateInfoId: null };
    this.opportunityId = null;
    this.stepper = null;
    this.fileBlob = null;
    this.lastUploadedDocumentByCategory = [];
    this.sentBackLastChance = false;
    this.partialId=null;
  }

  updateUploadedDoc(doc: any, index?: number) {
    this.uploadedDoc[doc.category].push(doc);
    if (index) {
      this.uploadedDoc[doc.category].splice(index, 1);
    }
  }

  populateKeys() {
    this.userId = this.accountService.getUserId();
    this.uniqueKeyValue = localStorage.getItem('tenantId') + this.userId;
    this.candidateProfileStateKey =
      'candidateProfileState' + this.uniqueKeyValue;
  }

  markComponentAsComplete() {
    const key = this.opportunityId
      ? 'Job' + this.opportunityId
      : 'candidateProfile';
    let flagMap = JSON.parse(
      sessionStorage.getItem(this.candidateProfileStateKey)
    );
    let jobFlagMap = flagMap[key];

    jobFlagMap.stepperState[componentPath[this.currentPath]] = 'completed';
    jobFlagMap.lastModifiedDate = +new Date();

    //Check if there is any changed or pending step, if not then null.
    jobFlagMap.lastModifiedStep = null;
    if (componentPath[this.currentPath] < 3) {
      const index = jobFlagMap.stepperState.findIndex(
        (value) => value !== 'completed'
      );
      if (index !== -1) {
        jobFlagMap.lastModifiedStep = index;
      }
    }
    flagMap[key] = jobFlagMap;
    sessionStorage.setItem(
      this.candidateProfileStateKey,
      JSON.stringify(flagMap)
    );
    this.stepper.selected.completed = true;
  }

  markComponentAsChanged() {
    const key = this.opportunityId
      ? 'Job' + this.opportunityId
      : 'candidateProfile';
    let flagMap = JSON.parse(
      sessionStorage.getItem(this.candidateProfileStateKey)
    );
    let jobFlagMap = flagMap[key];

    jobFlagMap.stepperState[componentPath[this.currentPath]] = 'changed';
    jobFlagMap.lastModifiedDate = +new Date();
    jobFlagMap.lastModifiedStep = componentPath[this.currentPath];

    flagMap[key] = jobFlagMap;
    sessionStorage.setItem(
      this.candidateProfileStateKey,
      JSON.stringify(flagMap)
    );
    this.stepper.selected.completed = false;
    this.stepper.selected.state = 'tick';
  }

  isApplicationEditable() {
    return this.applicationEditableOnStatus.includes(this.applicationStatus);
  }

  bankProofDisable() {
    console.log('The last uploaded document is', this.sentBackLastChance);
    return (
      this.applicationStatus === 'SENT_BACK_TO_CANDIDATE' &&
      this.sentBackLastChance
    );
  }

  checkUnrestrictedNavigation() {
    return !this.restrictedNavigationOnStatus.includes(this.applicationStatus);
  }

  getRequiredDocLength() {
    let length = 0;
    for (let key in this.documentListByCategory) {
      length += this.documentListByCategory[key]?.length;
    }
    return length;
  }

  getCandidateProfile() {
    return this.candidateProfile;
  }

  setCandidateProfile(candidateProfile: ICandidateProfileUIDTO) {
    this.candidateProfile = candidateProfile;
  }
  setPartialId(id:any){
    this.partialId=id;
  }
  getPartialId(){
    return this.partialId;
  }

  getCandidateInfoId() {
    return this.candidateProfile?.candidateInfoId;
  }

  setCandidateInfoId(candidateInfoId) {
    this.candidateProfile.candidateInfoId = candidateInfoId;
  }

  getUiPersonalInfoDTO() {
    return this.candidateProfile?.uiPersonalInfoDTO;
  }

  setUiPersonalInfoDTO(uiPersonalInfoDTO) {
    this.candidateProfile.uiPersonalInfoDTO = uiPersonalInfoDTO;
  }

  getUiEducationInfoDTO() {
    return this.candidateProfile?.uiEducationInfoDTO;
  }

  setUiEducationInfoDTO(uiEducationInfoDTO) {
    this.candidateProfile.uiEducationInfoDTO = uiEducationInfoDTO;
  }

  deleteEducationInfoDTO(index) {
    this.markComponentAsChanged();
    this.candidateProfile.uiEducationInfoDTO.educationDetailsDTOList =
      this.candidateProfile.uiEducationInfoDTO.educationDetailsDTOList.filter(
        (value, i) => {
          return i !== index;
        }
      );
  }

  getUiGuardianDTO() {
    return this.candidateProfile?.uiGuardianDTO;
  }

  setUiGuardianDTO(uiGuardianDTO) {
    this.candidateProfile.uiGuardianDTO = uiGuardianDTO;
  }

  getModifiedDateOfStep(step: number) {
    let date: number = null;
    switch (step) {
      case 0:
        date = Date.parse(
          this.candidateProfile.uiPersonalInfoDTO?.selfContactDetailsDTO
            ?.lastModifiedDate
        );
        break;
      case 1:
        date = Date.parse(
          this.candidateProfile.uiEducationInfoDTO?.educationDetailsDTOList?.[0]
            ?.lastModifiedDate
        );
        break;
      case 2:
        date = Date.parse(
          this.candidateProfile.uiGuardianDTO?.contactDetailsDTOList?.[0]
            ?.lastModifiedDate
        );
        break;
      case 3:
        date = Date.parse(
          this.candidateProfile.uiScholarshipDetailsDTO?.educationDetailsDTO
            ?.lastModifiedDate
        );
        break;
    }
    return Number.isNaN(date) ? 0 : date;
  }

  deleteContactInfoDTO(index) {
    this.markComponentAsChanged();
    this.candidateProfile.uiGuardianDTO.contactDetailsDTOList =
      this.candidateProfile.uiGuardianDTO.contactDetailsDTOList.filter(
        (value, i) => {
          return i !== index;
        }
      );
  }

  getUiScholarshipDetailsDTO() {
    return this.candidateProfile?.uiScholarshipDetailsDTO;
  }

  setUiScholarshipDetailsDTO(uiScholarshipDetailsDTO) {
    this.candidateProfile.uiScholarshipDetailsDTO = uiScholarshipDetailsDTO;
  }

  deleteBankInfoDTO(index) {
    this.markComponentAsChanged();
    this.candidateProfile.uiScholarshipDetailsDTO.financialDetailsDTOList =
      this.candidateProfile.uiScholarshipDetailsDTO.financialDetailsDTOList.filter(
        (value, i) => {
          return i !== index;
        }
      );
  }

  getPersonalDetailsId() {
    return this.candidateProfile?.uiPersonalInfoDTO?.personalDetailsId;
  }

  getDocumentListByCategory(category: string) {
    return this.documentListByCategory[category];
  }
  //***Candidate Steps State and Routing Functioning *****//

  //  ->We store the state of steps in stepper of candidate profile and applications in sessionStorage
  //    with key as candidateProfileState + uniqueKeyValue ( which contains userId and tenantId).

  //  ->It contains an object which will have states with keys as 'candidateProfile','Job234'(234 is jobId),
  //    'Job7362' and so on.

  //  ->Each individual state will have stepperState, lastModifiedDate and lastModifiedStep.

  //  ->stepperState will contain an array of status for each step, i.e., 0 step will have status at 0 index,
  //    and a status will have 3 possible values- null, 'changed' and 'completed'.

  //Candidate Application Stepper and candidate Profile Guard Function.
  checkCandidateProfileState(jobId: string) {
    // *First If statement will check if candidateProfileStateKey is null or not, if null then
    //  populateKeys() will be called to populate the keys.
    if (!this.candidateProfileStateKey) {
      this.populateKeys();
    }
    // *Second If statement will check if the key is valid (without NaN, null or undefined) or not,
    //  if valid then checks for state of profile and jobIds is there or not and if not then create them.
    if (
      !this.candidateProfileStateKey.includes('NaN') &&
      !this.candidateProfileStateKey.includes('null') &&
      !this.candidateProfileStateKey.includes('undefined')
    ) {
      let flagMap = JSON.parse(
        sessionStorage.getItem(this.candidateProfileStateKey)
      );
      // *If key is valid then, Third If will check if the key exists in sessionStorage or not, if not
      //  then it will create candidateProfileState with new key and set its values to null.
      if (!flagMap) {
        flagMap = {};
        flagMap['candidateProfile'] = {
          stepperState: [null, null, null, null],
          lastModifiedDate: null,
          lastModifiedStep: null,
        };
        // If jobId is present then create state for that jobId and set its values to null.
        if (jobId) {
          flagMap['Job' + jobId] = {
            stepperState: [null, null, null, null],
            lastModifiedDate: null,
            lastModifiedStep: null,
          };
        }
        sessionStorage.setItem(
          this.candidateProfileStateKey,
          JSON.stringify(flagMap)
        );
      }
      // *If the key exists in sessionStorage then, Fourth else If and subsequent If will check if the
      //  candidateProfileState at the key contains state for that jobId or not, if not then it will
      //  create state for that jobId and set its values to null.
      else if (jobId) {
        if (!flagMap['Job' + jobId]) {
          flagMap['Job' + jobId] = {
            stepperState: [null, null, null, null],
            lastModifiedDate: null,
            lastModifiedStep: null,
          };
          sessionStorage.setItem(
            this.candidateProfileStateKey,
            JSON.stringify(flagMap)
          );
        }
      }
      return true;
    }
    // *If the key is invalid then it will empty the invalid key and redirect to home page.
    else {
      this.candidateProfileStateKey = null;
      this.router.navigate(['/']);
      return false;
    }
  }

  //***Rules of Navigation***

  //  *For Navigation to a step -

  //  *For 1st step -
  //  ->We can always navigate to first step.

  //  *For steps other than 1st step -
  //  ->We can navigate to a step whose previous steps are having completed status.
  //  ->Otherwise we will be redirected to the first incomplete step (null,'changed').

  //  *For Navigation from a step -
  //  ->We can navigate to any previous step from the current step.
  //  ->We can only navigate to steps ahead of current step if the current one is having status completed.

  //Candidate Child Guard Function
  canNavigate(path: string, parentPath: string, jobId: string): boolean {
    //*This If will set current path to null if we navigate between application and profile which we
    // distinguish using parent path from service(from) and guard(to).
    if (this.parentPath && this.parentPath !== parentPath) {
      this.currentPath = null;
    }
    // *ComponentPath is an Enum of child component path name with index 0-3 in candidate-profile,
    //  which makes them accessible by string and index both.

    let flagMap = JSON.parse(
      sessionStorage.getItem(this.candidateProfileStateKey)
    );
    flagMap = flagMap[jobId ? 'Job' + jobId : 'candidateProfile'];
    flagMap = flagMap.stepperState;
    // For Directly Accessing Child Components through search bar.
    // *First If will check current path exists or not, current path will not exist if paths
    //  are accessed outside of candidate-profile, and second will always allow to navigate to
    //  personal-info(first component).
    if (!this.currentPath) {
      if (componentPath[path] === 0) {
        return true;
      }
      // *Third & Fourth If statements will check if there is any incomplete(unsubmitted) or unattended
      // step is left before it if yes then redirect to it instead.
      const index = flagMap.findIndex((value) => value !== 'completed');
      if (index !== -1 && index < componentPath[path]) {
        this.router.navigate([parentPath + componentPath[index]]);
        return false;
      }
      return true;
    }

    // For Navigation within steps of stepper
    // *First If statement will prevent navigation to the same path.
    //  *Second If statement will let you navigate to first child component, i.e., basic details
    //  irrespective of its status in sessionStorage and also let you navigate to previous components.
    // if (componentPath[path] === componentPath[this.currentPath]) {
    //   return false;
    // }
    if (
      componentPath[path] === 0 ||
      componentPath[path] <= componentPath[this.currentPath]
    ) {
      return true;
    }

    // *Third If statement will check if current component is not completed then will not allow
    //  the navigation to components after it and return false.

    if (flagMap[componentPath[this.currentPath]] === 'completed') {
      // *Fourth & Fifth If statements will check if there is any incomplete or unattended step
      //  left before it, if yes then redirect to it instead.
      const index = flagMap.findIndex((value) => value !== 'completed');
      if (index !== -1 && index < componentPath[path]) {
        this.router.navigate([parentPath + componentPath[index]]);
        return false;
      }
      return true;
    }
    return false;
  }

  constructor(private router: Router, private accountService: AccountService) {}
}
