import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MailTemplateService {
  private headers = new HttpHeaders().set('content-type', 'application/json');

  constructor(private http: HttpClient) {}

  setMailTemplateToEvent = (formBody) => {
    return this.http.post(
      environment.interviewServiceUrl + 'api/email-template-mappings',
      formBody,
      { headers: this.headers }
    );
  };

  updateMailTemplateToEvent = (formBody) => {
    return this.http.put(
      environment.interviewServiceUrl + 'api/email-template-mappings',
      formBody,
      { headers: this.headers }
    );
  };

  getMailTemplatesByEvent = (id) => {
    return this.http.get<any[]>(
      environment.interviewServiceUrl +
        'api/email-template-mappings/event/' +
        id,
      { headers: this.headers }
    );
  };

  getMailTemplatesByJob = (id) => {
    return this.http.get<any[]>(
      environment.interviewServiceUrl + 'api/email-template-mappings/job/' + id,
      { headers: this.headers }
    );
  };

  getMailTemplate(id) {
    return this.http.get(
      environment.notificationServiceUrl + 'api/notification-templates/' + id,
      { headers: this.headers }
    );
  }

  getMailTemplatesByCategory = (category, size, page) => {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);

    const opts = {
      headers: this.headers,
      params,
    };

    return this.http.get<any[]>(
      environment.notificationServiceUrl +
        'api/notification-templates/category/' +
        category,
      opts
    );
  };

  getCategories = () => {
    return this.http.get(
      environment.notificationServiceUrl +
        'api/template-categories/category-type/EMAIL',
      {
        headers: this.headers,
      }
    );
  };

  getTemplatesByCategory = (category, size = 5, page = 0) => {
    let params = new HttpParams();
    params = params.append('size', `${size}`);
    params = params.append('page', `${page}`);

    const opts = {
      headers: this.headers,
      params,
    };

    return this.http.get<any>(
      environment.notificationServiceUrl +
        'api/notification-templates/category/' +
        category,
      opts
    );
  };
}
