/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/tslint/config */
import { Injectable } from '@angular/core';
import { THUMBOR_URL } from 'src/app/app.constants';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// const FILE_SERVER_URL = "http://34.93.13.147:4900/api/fileserver/uploadFile/"

@Injectable({
  providedIn: 'root',
})
export class ImageEditService {
  public resourceUrl = THUMBOR_URL + '';

  constructor(protected http: HttpClient) {}

  proportion(
    fileUrl: string,
    fraction = '1.0',
    sec = 'unsafe'
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.resourceUrl}/filters:proportion(${fraction})/${encodeURI(
        fileUrl
      )}`,
      { observe: 'body' }
    );
  }

  getUpscaleUrl(
    fileUrl: string,
    dimension = '600x500',
    sec = 'unsafe'
  ): string {
    return `${
      this.resourceUrl
    }/fit-in/${dimension}/filters:upscale()/${encodeURI(fileUrl)}`;
  }

  upscale(
    fileUrl: string,
    dimension = '600x500',
    sec = 'unsafe'
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.resourceUrl}/fit-in/${dimension}/filters:upscale()/${encodeURI(
        fileUrl
      )}`,
      { observe: 'body' }
    );
  }

  trim(
    fileUrl: string,
    side = 'top-left',
    sec = 'unsafe'
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.resourceUrl}/trim:${side}/${encodeURI(fileUrl)}`,
      { observe: 'body' }
    );
  }

  crop(
    fileUrl: string,
    dimension = '300x100',
    sec = 'unsafe'
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.resourceUrl}/${dimension}/${encodeURI(fileUrl)}`,
      { observe: 'body' }
    );
  }
}
