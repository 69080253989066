<div *ngIf="!additionalDoc">
  <div>
    <h2 class="DSA_wb_h2Header web-GreyDarkest DSA_wb_text-1line">
      {{ headingText }}
    </h2>
    <span class="DSA_wb_caption web-GreyLight">
      Individual file size up to
      <strong>{{ individualFileMaxSizeInMB }} MB</strong>, Total file size (all
      files combined) up to {{ combinedFileMaxSizeInMB }} MB and type
      <strong>{{ allowedFileTypes.extensions.join(", ") }}</strong> are
      accepted.
    </span>
    <span class="DSA_wb_caption web-GreyLight">
      File name should not contain any special characters except underscore(_)
      and hyphen(-)
    </span>
  </div>
  <div class="marT24">
    <input
      #fileUpload
      type="file"
      [multiple]="acceptMultipleFiles"
      (change)="onFileSelected($event)"
      [accept]="allowedFileTypes.extensions.join(',')"
      hidden
    />

    <!-- <mat-chip-list>
      <mat-chip class="DSA_wb_chipStyle-Normal marR16" *ngFor="let f of files">
        <a
          href="javascript:void();"
          class="icon x-16 icon-close-mini DSA_wb_chipIconRight"
          (click)="removeFile(f)"
        ></a>
        {{ f.name }}
      </mat-chip>
    </mat-chip-list> -->

    <div
      class="d-flex flex-wrap drop-section"
      appDragDropAttachment
      (fileDropped)="addFiles($event)"
    >
      <div *ngFor="let file of files" class="text-center mar8">
        <div class="position-relative">
          <img [src]="getImageUrl(file)" class="snapshot" />
          <a
            href="javascript:void(0);"
            class="whiteIcon-Circle delete-snap"
            matTooltip="Remove snapshot"
            matTooltipClass="DSA_tooltip"
            (click)="removeFile(file)"
          >
            <i class="icon x-16 close-icon"></i>
          </a>
        </div>
        <p class="marT8 DSA_wb_caption web-GreyLight nomarB filename">
          {{ file.name }} ({{ formatBytes(file.size) }})
        </p>
      </div>
      <div class="d-flex align-items-center marT16">
        <div><i class="icon x-24 upload-icon"></i></div>
        <div class="web-GreyLight marL8 marT8">
          Drag and drop attachments here
        </div>
      </div>
      <div class="marT8">
        <p
          *ngIf="fileErrorMessage"
          class="DSA_wb_mainBodyTxt2 web-SecondaryRed marT8"
        >
          <strong [innerHTML]="fileErrorMessage"></strong>
        </p>
        <button
          mat-flat-button
          type="button"
          class="DSA_wb_miniSecondary-btn marTB16"
          (click)="onBrowseFilesClick($event)"
        >
          Browse Files
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="additionalDoc">
  <div>
    <h2
      [ngClass]="headerClass"
      class="DSA_wb_text-1line web-Me-PrimaryDark marB8"
    >
      {{ headingText }}
    </h2>
    <span class="DSA_wb_caption web-GreyLight">
      File size up to {{ maxFileSizeInMB }} MB and type
      {{ allowedFileTypes.extensions.join(", ") }} are accepted.
    </span>
  </div>
  <div
    class="d-flex flex-wrap marT24 drop-section"
    appDragDropAttachment
    (fileDropped)="addFiles($event)"
  >
    <input
      #fileUpload
      type="file"
      [multiple]="acceptMultipleFiles"
      (change)="onFileSelected($event)"
      [accept]="allowedFileTypes.extensions.join(',')"
      hidden
    />

    <mat-chip-list>
      <mat-chip class="DSA_wb_chipStyle-Normal marR16" *ngFor="let f of files">
        <a
          href="javascript:void();"
          class="icon x-16 icon-close-mini DSA_wb_chipIconRight"
          (click)="removeFile(f)"
        ></a>
        {{ f.name }}
      </mat-chip>
    </mat-chip-list>

    <!-- <div class="d-flex flex-wrap">
      <div *ngFor="let file of files" class="text-center mar8">
        <div class="position-relative">
          <img [src]="getImageUrl(file)" class="snapshot" />
          <a
            href="javascript:void(0);"
            class="whiteIcon-Circle delete-snap"
            matTooltip="Remove snapshot"
            matTooltipClass="DSA_tooltip"
            (click)="removeFile(file)"
          >
            <i class="icon x-16 close-icon"></i>
          </a>
        </div>
        <p class="marT8 DSA_wb_caption web-GreyLight nomarB filename">
          {{ file.name }}
        </p>
      </div>
    </div>
  </div> -->
    <div class="d-flex align-items-center marT16">
      <div><i class="icon x-24 upload-icon"></i></div>
      <div class="web-GreyLight marL8 marT8">
        Drag and drop attachments here
      </div>
    </div>
    <div class="marT8">
      <button
        mat-flat-button
        type="button"
        class="DSA_wb_miniSecondary-btn marTB16"
        (click)="onBrowseFilesClick($event)"
      >
        Browse Files
      </button>
      <p
        *ngIf="fileErrorMessage"
        class="DSA_wb_mainBodyTxt2 web-SecondaryRed DSA_wb_text-1line marT16"
      >
        <strong>{{ fileErrorMessage }}</strong>
      </p>
    </div>
  </div>
</div>

<!-- <div *ngIf="scholarshipCandidate">
  <div  appDragDropAttachment (fileDropped)="addFiles($event)">

    <div class="d-flex align-items-center marT16">
      <div class="web-GreyLight marR8 marT8">Upload attachments </div>
      <span  (click)="onBrowseFilesClick($event)">
        <i class="icon x-24 upload-icon"></i>
      </span>
    </div>
    <div class="marT2">
      <input
        #fileUpload
        type="file"
        [multiple]="false"
        (change)="onFileSelected($event)"
        [accept]="allowedFileTypes.extensions.join(',')"
        hidden
        class="marB4"
      />
      <mat-divider ></mat-divider>
      <div >
      <span class="DSA_wb_caption web-GreyLight">
        File size up to {{ maxFileSizeInMB }} MB and type doc, docx, pdf, jpg
         are accepted.
      </span>
    </div>
  
      <div class="d-flex flex-wrap">
        <div *ngFor="let file of files" class="text-center mar8">
          <div class="position-relative">
            <a
              href="javascript:void(0);"
              class="whiteIcon-Circle delete-snap"
              matTooltip="Remove "
              matTooltipClass="DSA_tooltip"
              (click)="removeFile(file)"
            >
              <i class="icon x-8 close-icon"></i>
            </a>
          </div>
          <p class="marT8 DSA_wb_caption web-GreyLight nomarB filename">
            {{ file.name }} ({{ formatBytes(file.size) }})
          </p>
        </div>
      </div>
    </div>
    <div class="marT8">
      <p
        *ngIf="fileErrorMessage"
        class="DSA_wb_mainBodyTxt2 web-SecondaryRed marT8"
      >
        <strong [innerHTML]="fileErrorMessage"></strong>
      </p>
    </div>
</div>
</div> -->
