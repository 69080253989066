<fieldset [disabled]="disabled">
  <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
      {{questionIndex+1}}.
    </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
      *
    </span></p>
  <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">

  <div *ngFor="let childQuestion of childQuestions; let childquestionIndex = index">
    <br>
    <mat-divider></mat-divider>
    <br>
    <div name="dynamicFields" style="display: flex;justify-content: flex-start;">
      <!-- <img *ngIf="question.logo!==null"
  [src]="downloadImage(question.logo.path)" width="300px" height="350px"> -->
      <div *ngIf="childQuestion.type.typeDefinition?.identifier==='Small_text'"
        style="display: flex;justify-content: flex-start;width: 100%;">

        <jhi-small-text-preview class="w-100"[disabled]="disabled" [currPage]="currPage" [question]="childQuestion"
          [responsePage]="responsePage.sections[section].questions[questionIndex].childResponses[childquestionIndex]"
          [questionIndex]="0" [section]="0" [preference]="preference" [theme]="theme">
        </jhi-small-text-preview>

      </div>
      <div *ngIf="childQuestion.type.typeDefinition?.identifier==='Long_text'"
        style="display: flex;justify-content: flex-start;width: 100%;">

        <jhi-long-text-preview class="w-100" [disabled]="disabled" [currPage]="currPage" [question]="childQuestion"
          [responsePage]="responsePage.sections[section].questions[questionIndex].childResponses[childquestionIndex]"
          [questionIndex]="0" [section]="0" [preference]="preference" [theme]="theme">
        </jhi-long-text-preview>


      </div>
      <div *ngIf="childQuestion.type.typeDefinition?.identifier==='Numeric'"
        style="display: flex;justify-content: flex-start;width: 100%;">

        <jhi-numeric-preview class="w-100" [disabled]="disabled" [currPage]="currPage" [question]="childQuestion"
          [responsePage]="responsePage.sections[section].questions[questionIndex].childResponses[childquestionIndex]"
          [questionIndex]="0" [section]="0" [preference]="preference" [theme]="theme">
        </jhi-numeric-preview>

      </div>
      <div *ngIf="childQuestion.type.typeDefinition?.identifier==='Comment'"
        style="display: flex;justify-content: flex-start;width: 100%;">

        <jhi-comment-preview class="w-100" [disabled]="disabled" [currPage]="currPage" [question]="childQuestion"
          [responsePage]="responsePage.sections[section].questions[questionIndex].childResponses[childquestionIndex]"
          [questionIndex]="0" [section]="0" [preference]="preference" [theme]="theme">
        </jhi-comment-preview>

      </div>
      <div *ngIf="childQuestion.type.typeDefinition?.identifier==='Ranking'"
        style="display: flex;justify-content: flex-start;width: 100%;">

        <jhi-ranking-preview class="w-100" [disabled]="disabled" [currPage]="currPage" [question]="childQuestion"
          [responsePage]="responsePage.sections[section].questions[questionIndex].childResponses[childquestionIndex]"
          [questionIndex]="0" [section]="0" [preference]="preference" [theme]="theme">
        </jhi-ranking-preview>

      </div>
      <div *ngIf="childQuestion.type.typeDefinition?.identifier==='Dropdown'"
        style="display: flex;justify-content: flex-start;width: 100%;">

        <jhi-dropdown-preview class="w-100" [disabled]="disabled" [currPage]="currPage" [question]="childQuestion"
          [responsePage]="responsePage.sections[section].questions[questionIndex].childResponses[childquestionIndex]"
          [questionIndex]="0" [section]="0" [preference]="preference" [theme]="theme">

        </jhi-dropdown-preview>

      </div>
      <div *ngIf="childQuestion.type.typeDefinition?.identifier==='Date_Time'"
        style="display: flex;justify-content: flex-start;width: 100%;">

        <jhi-date-time-preview class="w-100" [disabled]="disabled" [currPage]="currPage" [question]="childQuestion"
          [responsePage]="responsePage.sections[section].questions[questionIndex].childResponses[childquestionIndex]"
          [questionIndex]="0" [section]="0" [preference]="preference" [theme]="theme">
        </jhi-date-time-preview>

      </div>
      <div *ngIf="childQuestion.type.typeDefinition?.identifier==='Yes_No'"
        style="display: flex;justify-content: flex-start;width: 100%;">

        <jhi-yes-no-preview class="w-100" [disabled]="disabled" [currPage]="currPage" [question]="childQuestion"
          [responsePage]="responsePage.sections[section].questions[questionIndex].childResponses[childquestionIndex]"
          [questionIndex]="0" [section]="0" [preference]="preference" [theme]="theme"></jhi-yes-no-preview>

      </div>
      <div *ngIf="childQuestion.type.typeDefinition?.identifier==='Rating'"
        style="display: flex;justify-content: flex-start;width: 100%;">

        <jhi-rating-preview class="w-100" [disabled]="disabled" [currPage]="currPage" [question]="childQuestion"
          [responsePage]="responsePage.sections[section].questions[questionIndex].childResponses[childquestionIndex]"
          [questionIndex]="0" [section]="0" [preference]="preference" [theme]="theme">
        </jhi-rating-preview>

      </div>
      <div *ngIf="childQuestion.type.typeDefinition?.identifier==='Checkbox'"
        style="display: flex;justify-content: flex-start;width: 100%;">

        <jhi-checkbox-preview class="w-100" [disabled]="disabled" [currPage]="currPage" [question]="childQuestion"
          [responsePage]="responsePage.sections[section].questions[questionIndex].childResponses[childquestionIndex]"
          [questionIndex]="0" [section]="0" [preference]="preference" [theme]="theme">
        </jhi-checkbox-preview>

      </div>
      <div *ngIf="childQuestion.type.typeDefinition?.identifier==='Radio'"
        style="display: flex;justify-content: flex-start;width: 100%;">

        <jhi-radio-buttons-preview class="w-100" [disabled]="disabled" [currPage]="currPage" [question]="childQuestion"
          [responsePage]="responsePage.sections[section].questions[questionIndex].childResponses[childquestionIndex]"
          [questionIndex]="0" [section]="0" [preference]="preference" [theme]="theme">
        </jhi-radio-buttons-preview>


      </div>
      <div *ngIf="childQuestion.type.typeDefinition?.identifier==='Slider'"
        style="display: flex;justify-content: flex-start;width: 100%;">

        <jhi-slider-preview class="w-100" [disabled]="disabled" [currPage]="currPage" [question]="childQuestion"
          [responsePage]="responsePage.sections[section].questions[questionIndex].childResponses[childquestionIndex]"
          [questionIndex]="0" [section]="0" [preference]="preference" [theme]="theme"></jhi-slider-preview>

      </div>
      <div *ngIf="childQuestion.type.typeDefinition?.identifier==='Number_rating'"
        style="display: flex;justify-content: flex-start;width: 100%;">

        <jhi-number-rating-preview class="w-100" [disabled]="disabled" [currPage]="currPage" [question]="childQuestion"
          [responsePage]="responsePage.sections[section].questions[questionIndex].childResponses[childquestionIndex]"
          [questionIndex]="0" [section]="0" [preference]="preference" [theme]="theme">
        </jhi-number-rating-preview>

      </div>
      <div *ngIf="childQuestion.type.typeDefinition?.identifier==='HTML_block'"
        style="display: flex;justify-content: flex-start;width: 100%;">

        <jhi-html-block-preview class="w-100" [disabled]="disabled" [currPage]="currPage" [question]="childQuestion"
          [responsePage]="responsePage.sections[section].questions[questionIndex].childResponses[childquestionIndex]"
          [questionIndex]="0" [section]="0" [preference]="preference" [theme]="theme">
        </jhi-html-block-preview>

      </div>
      <div *ngIf="childQuestion.type.typeDefinition?.identifier==='File_upload'"
        style="display: flex;justify-content: flex-start;width: 100%;">

        <jhi-file-upload-preview class="w-100" [disabled]="disabled" [currPage]="currPage" [question]="childQuestion"
          [responsePage]="responsePage.sections[section].questions[questionIndex].childResponses[childquestionIndex]"
          [questionIndex]="0" [section]="0" [preference]="preference" [theme]="theme">
        </jhi-file-upload-preview>

      </div>
      <div *ngIf="childQuestion.type.typeDefinition?.identifier==='Photo_upload'"
        style="display: flex;justify-content: flex-start;width: 100%;">

        <jhi-photo-upload-preview class="w-100" [disabled]="disabled" [currPage]="currPage" [question]="childQuestion"
          [responsePage]="responsePage.sections[section].questions[questionIndex].childResponses[childquestionIndex]"
          [questionIndex]="0" [section]="0" [preference]="preference" [theme]="theme">
        </jhi-photo-upload-preview>

      </div>
      <div *ngIf="childQuestion.type.typeDefinition?.identifier==='Contact_Info'"
        style="display: flex;justify-content: flex-start;width: 100%;">

        <jhi-contact-info-preview class="w-100" [disabled]="disabled" [currPage]="currPage" [question]="childQuestion"
          [responsePage]="responsePage.sections[section].questions[questionIndex].childResponses[childquestionIndex]"
          [questionIndex]="0" [section]="0" [preference]="preference" [theme]="theme">
        </jhi-contact-info-preview>

      </div>
      <div *ngIf="childQuestion.type.typeDefinition?.identifier==='Multi_textbox'"
        style="display: flex;justify-content: flex-start;width: 100%;">

        <jhi-multi-textbox-preview class="w-100" [disabled]="disabled" [currPage]="currPage" [question]="childQuestion"
          [responsePage]="responsePage.sections[section].questions[questionIndex].childResponses[childquestionIndex]"
          [questionIndex]="0" [section]="0" [preference]="preference" [theme]="theme">
        </jhi-multi-textbox-preview>

      </div>
      <div *ngIf="childQuestion.type.typeDefinition?.identifier==='Map_Input'"
        style="display: flex;justify-content: flex-start;width: 100%;">

        <jhi-map-input-preview class="w-100" [disabled]="disabled" [currPage]="currPage" [question]="childQuestion"
          [responsePage]="responsePage.sections[section].questions[questionIndex].childResponses[childquestionIndex]"
          [questionIndex]="0" [section]="0" [preference]="preference" [theme]="theme"></jhi-map-input-preview>

      </div>
      <div *ngIf="childQuestion.type.typeDefinition?.identifier==='Video_Upload'"
        style="display: flex;justify-content: flex-start;width: 100%;">

        <jhi-video-upload-preview class="w-100" [disabled]="disabled" [currPage]="currPage" [question]="childQuestion"
          [responsePage]="responsePage.sections[section].questions[questionIndex].childResponses[childquestionIndex]"
          [questionIndex]="0" [section]="0" [preference]="preference" [theme]="theme"
          [surveyDefinition]="surveyDefinition"></jhi-video-upload-preview>

      </div>

    </div>
  </div>
</fieldset>
