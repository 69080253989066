<fieldset [disabled]="disabled">
  <div *ngIf="view==='TWO_COLUMNS';else oneColumn">
    <div class="row">
      <div class="col-6" style="display: flex;flex-direction: column;justify-content:center;">
        <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
            {{questionIndex+1}}.
          </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
            *
          </span></p>
        <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">

      </div>
      <div class="col-6">
        <span *ngIf="!responsePage.sections[section].questions[questionIndex].isValid"
          style="color:red;font-size: 12px;">
          {{responsePage.sections[section].questions[questionIndex].validationMessage}}</span>
        <mat-selection-list aria-label="Select an option" [(ngModel)]="selectedOptions"
          (selectionChange)="inputChanged($event)">

          <mat-list-option *ngFor="let option of options" [value]="option.value" checkboxPosition="before"
            [style.fontFamily]="theme.fontFamily" [style.color]="theme.fontStyle" [style.opacity]="theme.opacity"
            [style.fontWeight]="theme.fontWeight" [style.fontSize]="theme.fontSize">
            {{option.value}}
          </mat-list-option>
          <mat-list-option value="other" *ngIf="includeOthers" (keydown)="keyUpOthers($event)"
            checkboxPosition="before">

            <input matInput placeholder="{{question.othersHint}}" [(ngModel)]="customGoalName"
              style="border-bottom: 1px solid black;width:200px" (keyup)="changeOther()">

          </mat-list-option>
        </mat-selection-list>


      </div>
    </div>
  </div>

  <ng-template #oneColumn>
    <p><span *ngIf="responsePage.sections[section].questions[questionIndex].showNumbers">
        {{questionIndex+1}}.
      </span> {{question.text}}<span *ngIf="question.preference.isRequired===1" style="margin-left:2px;color: red;">
        *
      </span></p>
    <img *ngIf="question.logo!==null" [src]="downloadImage(question.logo.path)" class="question-image">
    <span *ngIf="!responsePage.sections[section].questions[questionIndex].isValid" style="color:red;font-size: 12px;">
      {{responsePage.sections[section].questions[questionIndex].validationMessage}}</span>
    <mat-selection-list aria-label="Select an option" [(ngModel)]="selectedOptions"
      (selectionChange)="inputChanged($event)">

      <mat-list-option *ngFor="let option of options" [value]="option.value" checkboxPosition="before"
        [style.fontFamily]="theme.fontFamily" [style.color]="theme.fontStyle" [style.opacity]="theme.opacity"
        [style.fontWeight]="theme.fontWeight" [style.fontSize]="theme.fontSize">
        {{option.value}}
      </mat-list-option>
      <mat-list-option value="other" *ngIf="includeOthers" (keydown)="keyUpOthers($event)" checkboxPosition="before">

        <input matInput placeholder="{{question.othersHint}}" [(ngModel)]="customGoalName"
          style="border-bottom: 1px solid black;width:200px" (keyup)="changeOther()">

      </mat-list-option>
    </mat-selection-list>

  </ng-template>

</fieldset>
