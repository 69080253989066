<app-loader [display]="isLoading"></app-loader>
<div *ngIf="!isLoading">
  <div class="row">
    <div class="col-12 d-flex justify-content-between flex-wrap">
      <h6 class="marB8">Associate New Template</h6>
      <p class="DSA_wb_mainBodyTxt2 web-GreyDarkest DSA_wb_text-1line">
        Template not present?
        <a
          class="DSA_wb_hyperLink-btn"
          role="button"
          [routerLink]="['/evaluation-templates']"
        >
          Create a new template
        </a>
      </p>
    </div>
  </div>
  <form [formGroup]="associateEvaluationForm">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <mat-label>Form Title</mat-label>
          <input matInput type="text" formControlName="title" required />
          <mat-error>Required*</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-6">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <mat-label>Description</mat-label>
          <input matInput type="text" formControlName="description" />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field class="DSA_Wb_custom-form-field">
          <mat-label>Form Type</mat-label>
          <input
            matInput
            type="text"
            formControlName="formType"
            required
            readonly
          />
          <mat-error>Required*</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <app-search-template-input
          (onTemplateSelect)="handleTemplateSelect($event)"
          placeholder="Select template"
          [isFinalized]="true"
          [formType]="formType"
          hint="Enter existing finalized template name to search"
        ></app-search-template-input>
      </div>
    </div>

    <div class="row d-flex justify-content-end">
      <p
        class="DSA_wb_caption web-SecondaryRed DSA_wb_text-1line marB8"
        *ngIf="selectedTemplateIsInactive || selectedTemplateIsActive"
      >
        {{
          selectedTemplateIsActive
            ? "This evaluation template is already associated and is active."
            : "This evaluation template was previously associated."
        }}
      </p>
      <div class="col-12 d-flex justify-content-end">
        <button
          mat-flat-button
          type="button"
          class="DSA_wb_miniSecondary-btn"
          (click)="handleCancelClick()"
        >
          Cancel
        </button>
        <button
          class="DSA_wb_miniPrimary-btn marL8"
          (click)="handleAssociateFormClick()"
          [disabled]="
            associateEvaluationForm.invalid ||
            !selectedTemplate ||
            selectedTemplateIsActive
          "
        >
          {{ selectedTemplateIsInactive ? "Reassociate" : "Associate" }}
        </button>
      </div>
    </div>

    <div class="col-12 marT8">
      <h6 class="marB8">Template Preview:</h6>
      <formio
        *ngIf="selectedTemplate?.formData"
        [form]="selectedTemplate.formData"
      ></formio>
      <p *ngIf="!selectedTemplate">No template selected.</p>
      <!-- when formData in selected template is null -->
      <p *ngIf="selectedTemplate && !selectedTemplate?.formData">
        Form not created.
      </p>
    </div>
  </form>
</div>
